<template>
  <div
    v-if="!productsQueryIsLoading"
    class="flex flex-col items-center p-4 w-full bg-lk-green md:p-10"
  >
    <p class="mb-3 w-full max-w-7xl font-serif text-xl text-left text-white md:text-2xl">
      Tendencias
    </p>
    <div class="flex items-center w-full max-w-7xl">
      <button
        v-if="!isMobile"
        class="mr-2"
        :class="{ 'opacity-50': !canScrollLeft }"
        @click="scrollLeft"
      >
        <ArrowLeftCircleIcon class="shrink-0 w-5 h-5 text-white md:w-6 md:h-6" />
      </button>
      <div
        ref="scrollContainer"
        class="flex overflow-auto space-x-3 w-full md:space-x-5"
      >
        <a
          v-for="trendProduct in trendProducts"
          :key="`trend-products-${trendProduct.id}`"
          class="flex flex-col mb-3"
          :href="`/products/${trendProduct.id}`"
        >
          <div
            class="flex overflow-hidden relative justify-center items-center mb-2 w-36 h-40 md:w-44 md:h-52"
            style="transform-origin: center center"
          >
            <div
              :style="{ 'background-image': `url(${getProductImage(trendProduct)})` }"
              class="object-cover absolute w-full h-full bg-center bg-cover duration-300 ease-in-out hover:scale-105"
            />
          </div>
          <p class="text-xs text-white">
            {{ trendProduct.name }}
          </p>
        </a>
      </div>
      <button
        v-if="!isMobile"
        class="ml-2"
        :class="{ 'opacity-50': !canScrollRight }"
        @click="scrollRight"
      >
        <ArrowRightCircleIcon class="shrink-0 w-5 h-5 text-white md:w-6 md:h-6" />
      </button>
    </div>
    <div
      v-if="isMobile"
      class="flex items-center"
    >
      <button
        class="mr-2"
        :class="{ 'opacity-50': !canScrollLeft }"
        @click="scrollLeft"
      >
        <ArrowLeftCircleIcon class="shrink-0 w-5 h-5 text-white md:w-6 md:h-6" />
      </button>
      <button
        class="ml-2"
        :class="{ 'opacity-50': !canScrollRight }"
        @click="scrollRight"
      >
        <ArrowRightCircleIcon class="shrink-0 w-5 h-5 text-white md:w-6 md:h-6" />
      </button>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, computed, type Ref, inject, watch } from 'vue';
import { useQuery } from 'vue-query';
import { ArrowLeftCircleIcon, ArrowRightCircleIcon } from '@heroicons/vue/24/outline';
import productsApi from 'api/products';
import useProductImage from './use/product-image';

const isMobile = inject('isMobile') as Ref<boolean>;

const currentPage = ref(1);
const queryParams = computed(() => 'q[trend_eq]=true&q[s]=priority+asc');
const productsQuery = useQuery(['trend-products', currentPage, queryParams], () =>
  productsApi.newIndex(currentPage.value, queryParams.value), { keepPreviousData: true },
);
const productsQueryIsLoading = computed(() => productsQuery.isLoading.value);
const trendProducts = computed(() => productsQuery.data.value?.data.products as Product[]);

function getProductImage(product: Product): string {
  const { productImageToShow } = useProductImage(product);

  return productImageToShow.value;
}

const scrollContainer: Ref<HTMLDivElement | null> = ref(null);
const canScrollLeft = ref(false);
const canScrollRight = ref(false);

function checkScroll() {
  if (scrollContainer.value) {
    const container = scrollContainer.value;
    canScrollLeft.value = container.scrollLeft > 0;
    canScrollRight.value = container.scrollLeft < container.scrollWidth - container.clientWidth;
  }
}

const scrollAmount = 200;

function scrollLeft() {
  if (scrollContainer.value) {
    scrollContainer.value.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
    checkScroll();
  }
}

function scrollRight() {
  if (scrollContainer.value) {
    scrollContainer.value.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    checkScroll();
  }
}

watch(() => scrollContainer.value?.scrollLeft, () => {
  checkScroll();
});
</script>
