<template>
  <div
    class="flex p-2 w-full rounded-md border border-lk-light-gray md:p-3"
  >
    <div class="flex flex-col mb-1 w-full text-sm text-gray-700 md:text-base">
      <p
        v-if="showFollowableName"
        class="flex items-center mb-1 text-white bg-lk-light-green rounded-md md:mr-2 md:mb-0"
      >
        {{ followable.name }}
      </p>
      <div class="flex items-center py-1 mb-1">
        <div class="flex items-center">
          <inline-svg-wrapper
            v-if="showFollowableName"
            name="store"
            class="shrink-0 mr-2 w-5 h-5 text-gray-700"
          />
          <UserCircleIcon class="shrink-0 mr-2 w-4 h-4 text-gray-700 md:w-5 md:h-5" />
          <p
            class="flex items-center font-semibold rounded-md"
          >
            {{ followUp.user.firstName }} {{ followUp.user.lastName }}
          </p>
        </div>
        <p class="self-start ml-auto font-light">
          {{ timeToDate(followUp.createdAt) }}
        </p>
      </div>
      <div class="flex flex-col items-start mr-1 text-sm font-light md:flex-row md:items-center md:mr-2 md:text-base">
        <p class="md:mr-2">
          {{ showableContent }}
        </p>
        <button
          v-if="followUp.content.length > 85"
          class="shrink-0 hover:underline hover:cursor-pointer"
          :class="{ 'md:self-end': showCompleteContent }"
          @click="showCompleteContent = !showCompleteContent"
        >
          {{ showCompleteContent ? 'Ver menos' : 'Ver más' }}
        </button>
      </div>
      <p
        v-if="followUp.reminderDate"
        class="shrink-0 text-xs font-light md:text-sm"
      >
        Fecha de recordatorio: {{ timeToDate(followUp.reminderDate) }}
      </p>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue';
import { UserCircleIcon } from '@heroicons/vue/24/outline';
import inlineSvgWrapper from './inline-svg-wrapper.vue';

const MAX_LENGTH = 85;

interface MakerFollowUpCardProps {
  followUp: FollowUp
  showFollowableName?: boolean
}

const props = withDefaults(defineProps<MakerFollowUpCardProps>(), {
  showFollowableName: true,
});

const showCompleteContent = ref(false);
const followable = computed(() => props.followUp.followable as Shop);
const showableContent = computed(() => {
  if (showCompleteContent.value || props.followUp.content.length <= MAX_LENGTH) {
    return props.followUp.content;
  }

  return `${props.followUp.content.slice(0, MAX_LENGTH)}...`;
});

function timeToDate(time: string) {
  return new Date(time).toLocaleDateString();
}
</script>
