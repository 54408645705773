import { csrfToken } from '@rails/ujs';
import { ref } from 'vue';
import api from './index';

const token = ref(csrfToken());
let headers = {};
if (token.value) {
  headers = { 'X-CSRF-Token': token.value };
}

export default {
  index() {
    return api({
      method: 'get',
      url: '/api/internal/tutorials',
      headers,
    });
  },
};
