<template>
  <form
    class="flex flex-col h-full"
    @submit.prevent="onSubmit"
  >
    <div class="flex flex-col mb-1">
      <p class="text-sm md:text-base">
        Número de factura
      </p>
      <Field
        v-model.number="invoiceNumber"
        class="p-2 text-xs rounded-md border focus:border-lk-green focus:outline-none md:text-sm"
        name="invoiceNumber"
        label="Número de factura"
        :rules="{ required: true, min_value: 1 }"
      />
      <ErrorMessage
        name="invoiceNumber"
        class="text-xs text-red-500 md:text-sm"
      />
    </div>
    <p class="text-sm md:text-base">
      Factura
    </p>
    <FileUploadInput
      v-model="fileValue"
      label-name="Factura"
      class=""
    />
    <span class="mb-3 text-sm">Debes subir un archivo .pdf</span>
    <p
      v-if="invoiceExists"
      class="mb-5 text-sm md:text-base"
    >
      <span class="font-light">
        El nombre de la factura actual es: <br>
      </span>
      <span class="">
        {{ subOrderInvoice?.invoiceData?.metadata?.filename }}.
      </span>
    </p>
    <lokal-loading
      v-if="isLoading"
      :loading="isLoading"
      class="self-center"
    />
    <button
      v-else
      class="self-center p-2 mt-auto w-full text-sm text-white bg-lk-green hover:bg-lk-green-deep rounded-md md:text-base"
    >
      {{ subOrderInvoice?.id ? 'Actualizar factura' : 'Crear factura' }}
    </button>
  </form>
</template>
<script setup lang="ts">
import { ref, computed, type Ref } from 'vue';
import { useForm, Field, ErrorMessage } from 'vee-validate';
import { useMutation } from 'vue-query';
import subOrderInvoicesApi from 'api/sub-order-invoices';
import FileUploadInput from './shared/file-upload-input.vue';

interface UploadBillDialogProps {
  subOrder: SubOrder
  subOrderInvoice?: SubOrderInvoice
}

const props = withDefaults(defineProps<UploadBillDialogProps>(), {
  subOrderInvoice: undefined,
});

const invoiceNumber = ref(props.subOrderInvoice?.invoiceNumber) as Ref<number>;
const fileValue: Ref<File | undefined> = ref(undefined);
const emit = defineEmits<{(e: 'upload-invoices'): void}>();

const updateSubOrderInvoiceMutation = useMutation(
  'updateSubOrderInvoide',
  () => {
    const data = { invoice: fileValue.value, invoiceNumber: invoiceNumber.value };

    return subOrderInvoicesApi.update(props.subOrderInvoice.id, data);
  },
  {
    onSuccess: () => {
      emit('upload-invoices');
    },
  },
);
const createSubOrderInvoiceMutation = useMutation(
  'createSubOrderInvoice',
  () => {
    const data = { invoice: fileValue.value, subOrderId: props.subOrder.id, invoiceNumber: invoiceNumber.value };

    return subOrderInvoicesApi.create(data);
  },
  {
    onSuccess: () => {
      emit('upload-invoices');
    },
  },
);
const isLoading = computed(() => createSubOrderInvoiceMutation.isLoading.value ||
  updateSubOrderInvoiceMutation.isLoading.value);

const invoiceExists = ref(!!props.subOrderInvoice?.id);
const { handleSubmit } = useForm();
const onSubmit = handleSubmit(() => {
  if (fileValue.value) {
    if (invoiceExists.value && props.subOrderInvoice?.id) {
      updateSubOrderInvoiceMutation.mutate();
    } else {
      createSubOrderInvoiceMutation.mutate();
    }
  }
});
</script>
