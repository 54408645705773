<template>
  <lokal-loading
    v-if="paymentMethodIsLoading"
    v-model="paymentMethodIsLoading"
  />
  <div
    v-else
    class="flex flex-col w-full rounded-t-md"
  >
    <div
      for=""
      class="text-gray-700 rounded-md border"
    >
      <p class="p-3 text-sm bg-gray-50 rounded-t-md md:p-4 md:text-base">
        Método de pago
      </p>
      <div class="flex flex-col">
        <template
          v-for="paymentOption in paymentOptionRecords"
          :key="`payment-option-${paymentOption.key}`"
        >
          <button
            v-if="paymentOption.value"
            :id="`payment-option-${paymentOption.key}`"
            class="flex gap-x-2 p-3 border-t first:border-t-0 md:p-4"
            type="button"
            @click="(payment = paymentOption.key as string)"
          >
            <div class="flex shrink-0 justify-center items-center self-start mt-1 w-[14px] h-[14px] rounded-full border-2 border-gray-700 cursor-pointer md:mt-[6px] md:w-4 md:h-4">
              <div
                v-if="payment === paymentOption.key"
                class="w-[6px] h-[6px] bg-gray-700 rounded-full md:w-2 md:h-2"
              />
            </div>
            <p class="flex flex-col text-left">
              <span class="font-semibold">{{ paymentOption.title }}</span>
              <span class="font-light">
                {{ paymentOption.description }}
              </span>
            </p>
          </button>
        </template>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { useQuery } from 'vue-query';
import paymentMethodsApi from 'api/payment-methods';

const props = defineProps<{
  modelValue: string
  selectedAddress: Address
  shopId: number
}>();

const emit = defineEmits<{(e: 'update:modelValue', value: string): void}>();
const paymentMethodQuery = useQuery(
  ['payment-methods', props.shopId],
  () => paymentMethodsApi.show(props.shopId),
);
const paymentMethodIsLoading = computed(() => paymentMethodQuery.isLoading.value);
const paymentMethod = computed(() => paymentMethodQuery.data.value?.data.paymentMethod as PaymentMethod);

const paymentOptionRecords = computed(() => {
  const records = [] as Record<string, string | boolean>[];
  records.push({ key: 'transferNow', value: paymentMethod.value?.transferNow, title: 'Pagar hoy con transferencia',
    description: 'Deberás pagar mediante una transferencia bancaria el total de la compra para confirmar la orden.' });
  records.push({ key: 'cardNow', value: paymentMethod.value?.cardNow, title: 'Pagar hoy con tarjeta',
    description: 'Te envíaremos un link para que puedas realizar el pago con tus tarjetas bancarias.' });
  records.push({
    key: 'payIn15Days', value: paymentMethod.value?.payIn15Days, title: 'Pagar a 15 días',
    description: 'Podrás pagar hasta 15 días después de que recibas los productos. Solo válido para tiendas físicas.',
  });
  records.push({
    key: 'payIn30Days', value: paymentMethod.value?.payIn30Days, title: 'Pagar a 30 días',
    description: 'Podrás pagar hasta 30 días después de que recibas los productos. Solo válido para tiendas físicas.',
  });
  records.push({
    key: 'transferLater', value: paymentMethod.value?.payIn60Days, title: 'Pagar a 60 días',
    description: 'Podrás pagar hasta 60 días después de que recibas los productos. Solo válido para tiendas físicas.',
  });

  return records;
});

const payment = ref('');

watch(payment, () => {
  emit('update:modelValue', payment.value);
});
</script>
