<template>
  <button
    @click="copyToClipBoard"
  >
    <ClipboardDocumentCheckIcon
      v-if="copiedLink"
      class="text-lk-green"
      :class="[iconClasses]"
    />
    <ClipboardDocumentIcon
      v-else
      :class="[iconClasses]"
    />
  </button>
</template>
<script lang="ts" setup>
import { ref } from 'vue';
import { ClipboardDocumentIcon, ClipboardDocumentCheckIcon } from '@heroicons/vue/24/outline';
import useMessageStore from 'stores/message-store';

const props = withDefaults(defineProps<{
  text: string
  textName: string
  iconClasses?: string
}>(), {
  iconClasses: 'w-4 md:w-5',
});

const copiedLink = ref(false);
const messageStore = useMessageStore();

function copyToClipBoard() {
  navigator.clipboard.writeText(props.text).then(() => {
    copiedLink.value = true;
    messageStore.showMessage(`"${props.textName}" copiado al portapapeles`, 'success');
  });
}
</script>
