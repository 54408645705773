import { computed } from 'vue';
import { useQuery } from 'vue-query';
import addressesApi from 'api/addresses';

export default function useRegionsQuery(enabled = computed(() => true)) {
  const regionsQuery = useQuery('regions', () => addressesApi.regions(), { enabled });
  const regions = computed(() => regionsQuery.data.value?.data.regions as Region[]);

  return { regions, regionsQuery };
}
