<template>
  <component
    :is="tag"
    class="text-sm text-center rounded-md md:text-base"
    :class="[sizeClasses[size], actionClasses[action]]"
  >
    <slot name="icon" />
    {{ actionName }}
  </component>
</template>
<script lang="ts" setup>
interface LokalButtonProps {
  actionName: string
  tag?: 'a' | 'button'
  size?: 'small' | 'normal' | 'big'
  action?: 'confirm' | 'cancel' | 'secondary' | 'primary'
}

withDefaults(defineProps<LokalButtonProps>(), {
  size: 'small',
  tag: 'button',
  action: 'confirm',
});

const sizeClasses = {
  'small': 'py-1 px-2',
  'normal': 'py-2 px-4',
  'big': 'py-3 px-8 text-lg md:text-xl',
};

const actionClasses = {
  'confirm': 'bg-lk-light-green hover:bg-lk-green text-white',
  'primary': 'bg-lk-green hover:bg-lk-green-deep text-white',
  'secondary': 'bg-white text-lk-green hover:bg-lk-green hover:text-white border border-lk-green',
  'cancel': 'bg-red-500 text-white',
};

</script>
