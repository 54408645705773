<template>
  <Dialog
    :open="modelValue"
    as="div"
    class="relative z-50"
    @close="emit('update:modelValue', false)"
  >
    <div
      class="fixed inset-0 bg-black/30"
      aria-hidden="true"
    />
    <div class="flex fixed inset-0 justify-center items-center p-2">
      <DialogPanel class="flex overflow-y-auto flex-col p-2 w-full max-w-md bg-gray-50 rounded md:p-4">
        <button
          class="mb-2 ml-auto focus:outline-none"
          @click="emit('update:modelValue', false)"
        >
          <XMarkIcon class="w-6 h-6 fill-gray-700" />
        </button>
        <DialogTitle class="mb-3 font-serif text-xl text-center text-gray-700 md:text-3xl">
          Información de pago
        </DialogTitle>
        <DialogDescription
          class="flex flex-col mb-5 w-full text-sm font-light text-gray-700 md:text-base"
          as="div"
        >
          <p
            v-if="order?.paymentMethod === 'register_card'"
            class="p-2 mb-4 w-full text-white bg-yellow-500 rounded-md"
          >
            Esta orden se cobrará automáticamente a tu tarjeta después de 60 días que hayas recibido
            tus productos. Si deseas adelantar el puedes usar los datos a continuación.
          </p>
          <p class="mb-4 w-full">
            Para pagar una orden debes depositar el monto descrito en la factura a la siguiente cuenta:
          </p>
          <lokal-transfer-payment-information />
        </DialogDescription>
        <lokal-button
          action-name="Aceptar"
          size="normal"
          class="self-center w-3/4"
          @click="emit('update:modelValue', false)"
        />
      </DialogPanel>
    </div>
  </dialog>
</template>
<script lang="ts" setup>
import { XMarkIcon } from '@heroicons/vue/24/solid';
import { computed } from 'vue';
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  DialogDescription,
} from '@headlessui/vue';
import LokalButton from './lokal-button.vue';
import LokalTransferPaymentInformation from './lokal-transfer-payment-information.vue';

interface LokalPaymentInformationProps {
  modelValue: boolean
  order?: Order
}

const props = withDefaults(defineProps<LokalPaymentInformationProps>(), {
  modelValue: false,
  order: undefined,
});

const emit = defineEmits<{(e: 'update:modelValue', value: boolean): void}>();
const contactNumber = computed(() => process.env.CONTACT_NUMBER);
const whatsappPaymentMessage = computed(() => (
  `https://wa.me/${contactNumber.value}?text=Hola%20quiero%20pagar%20la%20orden%20${props.order?.id}%20con%20tarjeta%20`
));
</script>
