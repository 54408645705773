import { computed, type ComputedRef } from 'vue';
import { orderBy, round } from 'lodash';
import useCartStore from 'stores/cart';
import useActiveProductDiscounts from './active-product-discounts';

const TOTAL_PORCENTAGE = 100;

// eslint-disable-next-line max-statements
export default function useProductWholesalePriceMethods(product: Product, quantity: ComputedRef<number>) {
  const { currentDiscountAmount } = useActiveProductDiscounts(product);
  const cartStore = useCartStore();
  const wholesalePrices = computed(() => product.wholesalePrices);
  const filteredWholesalePrices = computed(() => wholesalePrices.value?.filter((wholesalePrice) =>
    wholesalePrice.minimumQuantity <= quantity.value || quantity.value === 0));

  // we use this function to get the wholesale price of a product when the quantity is changing
  function getProductWholesalePrice(newQuantity: number) {
    const prices = wholesalePrices.value?.filter((wholesalePrice) =>
      wholesalePrice.minimumQuantity <= newQuantity || newQuantity === 0);
    const price = orderBy(prices, ['minimumQuantity'], ['desc']);

    return round(price?.[0]?.amountCents * (1 - (currentDiscountAmount.value / TOTAL_PORCENTAGE)));
  }

  const orderedPrices = computed(() => {
    if (quantity.value <= product.minimumPurchaseQuantity) {
      // when quantity is less tah minimum purchase quantity prices are not filtered, so we pick the smallest one
      return orderBy(filteredWholesalePrices.value, ['minimumQuantity'], ['asc']);
    }

    return orderBy(filteredWholesalePrices.value, ['minimumQuantity'], ['desc']);
  });
  const productWholesalePriceWithoutDiscount = computed(() => round(orderedPrices.value?.[0]?.amountCents));
  const productWholesalePrice = computed(() =>
    round(orderedPrices.value?.[0]?.amountCents * (1 - (currentDiscountAmount.value / TOTAL_PORCENTAGE))));
  const discountAmount = computed(() =>
    product.wholesalePriceCents - productWholesalePrice.value);

  const codeDiscountAmount = computed(() => {
    const makerDiscount = cartStore.discountCodes[product.makerId];
    const makerDiscountPercentage = makerDiscount.totalDiscount / TOTAL_PORCENTAGE;

    return round(productWholesalePriceWithoutDiscount.value * makerDiscountPercentage);
  });
  const productWholesalePriceWithoutDiscountAndDiscountCode = computed(() =>
    productWholesalePriceWithoutDiscount.value - codeDiscountAmount.value);

  // this price does not consider volume discount
  const productWholesalePriceWithVolumeDiscount = computed(() => productWholesalePriceWithoutDiscount.value);

  return {
    productWholesalePrice, orderedPrices, filteredWholesalePrices, wholesalePrices,
    getProductWholesalePrice, productWholesalePriceWithoutDiscount, discountAmount,
    productWholesalePriceWithVolumeDiscount,
    codeDiscountAmount,
    productWholesalePriceWithoutDiscountAndDiscountCode,
  };
}
