<template>
  <div class="flex flex-col items-center py-8 px-4 w-full h-full bg-stone-100 sm:px-8 md:py-12 lg:pr-0 lg:pl-[128px] xl:pr-0">
    <div class="flex flex-col items-center w-full max-w-6xl h-full lg:pr-[96px]">
      <p class="mb-4 font-serif-noto text-2xl font-light text-center md:text-4xl">
        Encuentra las mejores marcas para cada categoría
      </p>
      <div class="flex flex-wrap gap-1 justify-center items-center mb-5 w-full font-sans md:gap-2">
        <button
          v-for="category in shownCategories"
          :key="`category-bp-${category.id}`"
          class="p-2 text-sm font-light rounded-full border md:text-base"
          :class="{ 'bg-lk-green-deep text-white border-0': selectedCategory.id === category.id,
                    'text-gray-700 hover:border-lk-green-deep': selectedCategory.id !== category.id}"
          @click="selectedCategory = category"
        >
          {{ category.name }}
        </button>
        <a
          v-if="isMobile"
          class="p-2 text-sm font-light text-gray-700 rounded-full border hover:border-lk-green-deep md:text-base"
          href="/categories"
        >
          Ver todas
        </a>
      </div>
      <div
        v-if="makersQueryIsLoading"
        class="flex shrink-0 justify-center items-center w-full h-44 sm:h-52 md:h-72"
      >
        <lokal-loading v-model="makersQueryIsLoading" />
      </div>
      <transition
        enter-active-class="transform duration-500"
        enter-from-class="-translate-y-20 opacity-50"
        enter-to-class="translate-y-0 transform opacity-100"
      >
        <div
          v-if="!makersQueryIsLoading && !changingCategory"
          class="grid grid-cols-2 gap-3 place-items-center w-full sm:grid-cols-4 md:gap-4 lg:gap-y-0"
        >
          <div
            v-for="maker in currentMakers"
            :key="maker.id"
            class="flex flex-col mb-4 w-full"
          >
            <a
              :href="`/makers/${maker.slug}`"
              class="flex flex-col mb-1 w-full hover:shadow-md cursor-pointer"
            >
              <div class="flex overflow-hidden relative shrink-0 justify-center items-center w-full h-40 sm:h-48 md:h-60">
                <div
                  class="object-cover absolute w-full h-full bg-center bg-cover duration-150 ease-linear hover:scale-105"
                  :style="{ 'background-image': `url(${maker.image?.derivatives?.jpgMd?.url})` }"
                />
              </div>
            </a>
            <div class="w-full text-gray-700">
              <p class="text-sm hover:underline md:text-base">
                {{ startCase(maker.name.toLocaleLowerCase()) }}
              </p>
            </div>
          </div>
          <a
            :href="`/makers?category_ids=${selectedCategory.id}`"
            class="col-span-2 p-2 text-sm text-lk-green-deep hover:text-white bg-white hover:bg-lk-green-deep rounded-md border border-lk-green-deep md:col-start-2 md:p-3 md:text-base"
          >
            Revisar todo {{ selectedCategory.name }}
          </a>
        </div>
      </transition>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, inject, ref, onMounted, type Ref } from 'vue';
import { startCase } from 'lodash';
import { useQueryClient } from 'vue-query';
import makersApi from 'api/makers';
import useActiveMakersQuery from './queries/active-makers-query';

const MAX_MAKERS = 4;
const MAX_SHOWN_CATEGORIES = 8;
const TIMEOUT = 5000;
const SMALL_TIMEOUT = 20;

interface TheLandingBestProductsByCategoriesProps {
  categories: Category[]
}

const props = defineProps<TheLandingBestProductsByCategoriesProps>();

const isMobile = inject('isMobile') as Ref<boolean>;

const selectedCategory = ref(props.categories[0]);
const currentCategoryIndex = computed(() =>
  props.categories.findIndex((category) => category.id === selectedCategory.value.id));
const nextCategory = computed(() => {
  if (currentCategoryIndex.value === props.categories.length - 1) return props.categories[0];

  return props.categories[(currentCategoryIndex.value + 1) % props.categories.length];
});
const shownCategories = computed(() => {
  if (isMobile.value) return props.categories.slice(0, MAX_SHOWN_CATEGORIES);

  return props.categories;
});
const page = ref(1);
const queryParams = computed(() => `q[products_categories_id_in][]=${selectedCategory.value.id}&`);
const { makers, makersQueryIsLoading } = useActiveMakersQuery(page, queryParams, computed(() => true));
const currentMakers = computed(() => makers.value.slice(0, MAX_MAKERS));

const queryClient = useQueryClient();
function preFetchCategoy(category: Category) {
  const internalQueryParams = `q[products_categories_id_in][]=${category.id}&`;

  return queryClient.prefetchQuery(
    ['makers', page, internalQueryParams],
    () => makersApi.active(page.value, internalQueryParams),
  );
}

function preFetchNextCategory(category: Category) {
  return preFetchCategoy(category);
}

const changingCategory = ref(false);
function updateCategory() {
  changingCategory.value = true;
  if (currentCategoryIndex.value === props.categories.length - 1) {
    selectedCategory.value = props.categories[0];
  } else {
    selectedCategory.value = props.categories[(currentCategoryIndex.value + 1) % props.categories.length];
  }
  preFetchNextCategory(nextCategory.value);
  setTimeout(() => {
    changingCategory.value = false;
  }, SMALL_TIMEOUT);
}

onMounted(() => {
  preFetchNextCategory(nextCategory.value);
  setInterval(updateCategory, TIMEOUT);
});
</script>
