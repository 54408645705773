<template>
  <img
    v-if="file.preview"
    :src="file.preview"
    class="object-contain w-14 h-16 md:w-20"
  >
  <p
    v-else
    class="flex object-contain shrink-0 justify-center items-center w-16 h-16 rounded-md border"
  >
    {{ upperCase(file.extension) }}
  </p>
  <p class="flex flex-col col-span-1 self-center place-self-start w-full text-xs text-gray-700 truncate md:col-span-2 md:text-sm">
    <span class="mb-1 truncate">{{ file.name }}</span>
    <span class="text-gray-500">{{ round(file.size / BYTES_TO_KB) }} Kb</span>
  </p>
  <button
    v-if="!loading"
    type="button"
    class="flex items-center place-self-center p-1 space-x-1 text-xs text-white bg-red-500 rounded-md md:p-2 md:space-x-2 md:text-sm"
    @click="showConfirmationModal = !showConfirmationModal"
  >
    <span v-if="!isMobile">Eliminar</span>
    <XCircleIcon
      class="w-4 h-4 text-white"
    />
  </button>
  <lokal-loading
    v-else
    size="size"
  />
  <div class="last:hidden col-span-4 w-full border-b border-gray-300 md:col-span-5" />
  <lokal-confirmation-dialog
    v-if="showConfirmationModal"
    v-model="showConfirmationModal"
    title="Eliminar archivo"
    description="¿Deseas eliminar este archivo?, esta acción es permanente."
    confirm-action-text="Eliminar archivo"
    event-name="remove-file"
    :src="file.preview"
    @remove-file="uppy.removeFile(file.id)"
  />
</template>
<script lang="ts" setup>
import type { UppyFile, Uppy } from '@uppy/core';
import { ref, inject } from 'vue';
import { round, upperCase } from 'lodash';
import { XCircleIcon } from '@heroicons/vue/24/outline';
import lokalConfirmationDialog from './lokal-confirmation-dialog.vue';

const BYTES_TO_KB = 1024;

defineProps<{
  file: UppyFile
  uppy: Uppy
}>();

const isMobile = inject('isMobile');
const loading = ref(false);
const showConfirmationModal = ref(false);
</script>
