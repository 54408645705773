<template>
  <div class="flex flex-col items-center py-8 px-4 w-full h-full bg-stone-100 md:py-12 md:pr-0 md:pl-[128px] xl:pr-0">
    <div class="flex flex-col items-center w-full max-w-6xl h-full md:pr-[96px]">
      <p class="mb-5 w-full font-serif-noto text-lg text-left text-gray-700 sm:text-xl md:mb-10 lg:text-2xl">
        Preguntas frecuentes
      </p>
      <Disclosure
        v-for="frequentQuestion in frequentQuestionsOrderByPriority.slice(0, 4)"
        v-slot="{ open }"
        :key="frequentQuestion.id"
        as="div"
        class="flex flex-col py-2 mb-2 w-full bg-white rounded-md"
      >
        <DisclosureButton
          class="flex justify-between py-1 px-4 w-full text-sm text-left text-gray-700 md:py-2 md:text-base"
        >
          <span>
            {{ frequentQuestion.question }} ({{ $t(`landing.frequentQuestions.kind.${frequentQuestion.kind}`) }})
          </span>
          <ChevronUpIcon
            class="w-5 h-5 text-gray-700"
            :class="{ 'transform rotate-180 duration-150': open, 'transform duration-150': !open }"
          />
        </DisclosureButton>
        <transition
          enter-active-class="transition duration-150"
          enter-from-class="-translate-y-2 opacity-50"
          enter-to-class="translate-y-0 transform opacity-100"
          leave-active-class="transition duration-150"
          leave-from-class="translate-y-0"
          leave-to-class="-translate-y-2 transform opacity-0"
        >
          <DisclosurePanel
            class="px-4 pt-4 pb-2 w-full text-sm font-light text-gray-700 md:text-base"
            v-html="frequentQuestion.answer"
          />
        </transition>
      </Disclosure>
      <a
        class="py-2 px-4 text-sm text-lk-green-deep hover:text-white bg-white hover:bg-lk-green-deep rounded-md border border-lk-green-deep hover:transition-all hover:duration-150 hover:ease-linear hover:scale-105 md:py-3 md:px-6 md:text-base"
        href="/frequent_questions"
      >
        Revisar todas
      </a>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';
import { ChevronUpIcon } from '@heroicons/vue/24/solid';
import { orderBy } from 'lodash';

const props = defineProps<{
  frequentQuestions: FrequentQuestion[],
}>();

const frequentQuestionsOrderByPriority = orderBy(props.frequentQuestions, 'priority', 'asc');
</script>

