<template>
  <Listbox
    :model-value="modelValue"
    @update:model-value="(value) => emit('update:modelValue', value)"
  >
    <div class="relative">
      <ListboxButton
        v-slot="{ open }"
        class="w-full rounded-md"
      >
        <div
          class="flex justify-between items-center w-full rounded-md focus:outline-none"
          :class="[
            listBoxButtonClasses,
            {
              [activeOrSelectedColor(modelValue)]: modelValue[keyOption] !== undefined,
              'border border-lk-light-gray': modelValue[keyOption] === undefined,
            }
          ]"
          @mouseover="hover = true"
          @mouseleave="hover = false"
        >
          <span
            class="mr-1"
            :class="{ 'text-center w-full': size === 'sm' }"
          >
            {{ label }}
          </span>
          <ArrowDownCircleIcon
            v-if="size !== 'sm'"
            class="shrink-0"
            :class="{
              'rotate-180': open, 'h-4 md:h-5 w-4 md:w-5': size === 'md',
              'fill-lk-light-gray': modelValue[keyOption] === undefined,
            }"
          />
        </div>
      </ListboxButton>
      <ListboxOptions
        class="overflow-y-auto absolute z-10 mt-2 w-full max-h-96 bg-white rounded-md border border-lk-light-gray focus:outline-none shadow-md"
      >
        <ListboxOption
          v-for="option in options"
          v-slot="{ active, selected }"
          :key="option[keyOption]"
          :value="option"
          as="template"
        >
          <li
            class="flex justify-between items-center cursor-pointer"
            :class="[
              listBoxOptionClasses,
              { [activeOrSelectedColor(option)]: active || selected }
            ]"
          >
            <span>
              {{ option[optionLabel] }}
            </span>
            <span v-if="selected">
              <CheckIcon
                class="w-5 h-5 fill-white"
              />
            </span>
          </li>
        </ListboxOption>
      </ListboxOptions>
    </div>
  </Listbox>
</template>
<script lang="ts" setup>
import { ref, computed } from 'vue';
import { CheckIcon, ArrowDownCircleIcon } from '@heroicons/vue/24/solid';
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from '@headlessui/vue';

export type OptionRecord = Record<string | number, string | number>;

interface LokalSelectorProps {
  options: OptionRecord[],
  optionsLabel: string,
  optionLabel: string,
  keyOption: string,
  modelValue: OptionRecord,
  size?: 'sm' | 'md',
  colorKey?: string,
  listBoxOptionClasses?: string,
  listBoxButtonClasses?: string
  listBoxDisplayOptionClasses?: string
}

const props = withDefaults(defineProps<LokalSelectorProps>(), {
  size: 'md',
  colorKey: undefined,
  listBoxOptionClasses: '',
  listBoxButtonClasses: '',
  listBoxDisplayOptionClasses: '',
});

const emit = defineEmits<{(e: 'update:modelValue', value: OptionRecord): void }>();
const hover = ref(false);

const label = computed(() => {
  if (!props.modelValue[props.optionLabel]) {
    return props.optionsLabel;
  }

  return props.modelValue[props.optionLabel];
});

function activeOrSelectedColor(option: OptionRecord) {
  if (!!props.colorKey) return option[props.colorKey];

  return 'bg-lk-green text-white';
}

const listBoxOptionClasses = computed(() => {
  if (!!props.listBoxOptionClasses) return props.listBoxOptionClasses;
  if (props.size === 'sm') {
    return 'py-1 px-2 text-xs md:text-sm';
  }

  return 'py-2 px-3 text-sm md:text-base';
});

const listBoxButtonClasses = computed(() => {
  if (!!props.listBoxButtonClasses) return props.listBoxButtonClasses;
  if (props.size === 'sm') {
    return 'py-1 px-2 text-xs md:text-sm';
  }

  return 'py-2 px-3 text-sm md:text-base';
});
</script>
