// eslint-disable-next-line no-magic-numbers
const MILLISECONDS_TO_DAYS = 1000 * 60 * 60 * 24;

export default function useDateTools() {
  function stringToDate(dateString: string | Date) {
    const date = new Date(dateString);
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset());

    return date;
  }

  function newDateWithOffset() {
    const date = new Date();
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset());

    return date;
  }

  function timeToDate(time: Date | string) {
    const date = new Date(time);
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset());

    return date.toLocaleDateString();
  }

  function differenceBetweenDates(firstDate: Date | string, secondDate: Date | string): number {
    const first = new Date(firstDate);
    const second = new Date(secondDate);
    const differenceInMilliseconds = first.getTime() - second.getTime();
    const differenceInDays = Math.ceil(differenceInMilliseconds / MILLISECONDS_TO_DAYS);

    return differenceInDays;
  }

  function dateWithoutTimeToDate(date: string) {
    const [year, month, day] = date.split('-');
    const defaultTime = 13;
    // transform month to number
    const correctDate = new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10), defaultTime);

    return correctDate;
  }

  return { stringToDate, newDateWithOffset, timeToDate, differenceBetweenDates, dateWithoutTimeToDate };
}
