<template>
  <form
    class="flex flex-col w-full text-sm text-gray-700"
    @submit="onSubmit"
  >
    <p class="mb-3 text-2xl">
      Agrega una nueva dirección
    </p>
    <p class="">
      Nombre de referencia
    </p>
    <Field
      v-model="nameReference"
      placeholder="Ej: Tienda de Providencia"
      name="name"
      :rules="{ required: true }"
      class="p-2 mb-1 rounded-md border border-lk-light-gray"
    />
    <ErrorMessage
      name="name"
      class="mb-1 text-red-500"
    />
    <div class="flex justify-between mb-1 space-x-5 w-full">
      <div class="flex flex-col w-full">
        <lokal-loading
          v-if="isLoading"
          :loading="isLoading"
          size="xs"
          class="self-center"
        />
        <lokal-single-combobox
          v-else
          v-model="selectedRegion"
          :options="regionRecords"
          options-label="Regiones"
          option-label="name"
          key-option="id"
          text-size="text-sm"
        />
        <Field
          v-model="selectedRegionId"
          :rules="{ required: true }"
          hidden
          name="region"
          label="región"
        />
        <ErrorMessage
          name="region"
          class="text-red-500"
        />
      </div>
      <div class="flex flex-col w-full">
        <lokal-loading
          v-if="isLoading"
          :loading="isLoading"
          size="xs"
          class="self-center"
        />
        <lokal-single-combobox
          v-else
          v-model="selectedCommune"
          :options="communeRecords"
          options-label="Comunas"
          option-label="name"
          key-option="id"
          text-size="text-sm"
        />
        <Field
          v-model="selectedCommuneId"
          :rules="{ required: true }"
          name="commune"
          hidden
          label="comuna"
        />
        <ErrorMessage
          name="commune"
          class="text-red-500"
        />
      </div>
    </div>
    <p class="text-sm">
      Dirección
    </p>
    <Field
      v-model="address"
      name="address"
      :rules="{ required: true }"
      class="p-2 mb-1 rounded-md border border-lk-light-gray"
    />
    <ErrorMessage
      name="address"
      class="mb-1 text-red-500"
    />
    <p
      class="text-sm"
      hidden
    >
      Código postal
    </p>
    <Field
      v-model="postalCode"
      hidden
      name="postalCode"
      class="p-2 mb-1 rounded-md border border-lk-light-gray"
    />
    <ErrorMessage
      hidden
      name="postalCode"
      class="mb-1 text-red-500"
    />
    <p class="text-sm">
      Observaciones
    </p>
    <div class="flex flex-col mb-2 w-full">
      <Field
        v-model="observations"
        name="observations"
        placeholder="Ej: Horario de atención, días abiertos, quién recibe, etc."
        class="p-2 rounded-md border border-lk-light-gray"
        type="text"
      />
      <ErrorMessage
        name="observations"
        class="mt-1 text-red-500"
      />
    </div>
    <lokal-loading
      v-if="loading"
      class="self-center"
      :loading="loading"
    />
    <button
      v-else
      class="flex justify-center items-center py-2 px-4 space-x-2 w-full text-lk-green hover:text-white bg-white hover:bg-lk-green rounded-md border border-lk-green"
      type="submit"
    >
      <PlusIcon class="w-4 h-4 fill-white" />
      <span>Agregar</span>
    </button>
  </form>
</template>
<script lang="ts" setup>
import { PlusIcon } from '@heroicons/vue/24/outline';
import { computed, onMounted, ref, watch } from 'vue';
import { Field, ErrorMessage, useForm } from 'vee-validate';
import { useMutation } from 'vue-query';
import addressesApi from 'api/addresses';
import communesApi from 'api/communes';
import type { OptionRecord } from './shared/lokal-single-selector.vue';
import LokalSingleCombobox from './shared/lokal-single-combobox.vue';

interface AddressNewProps {
  addressableId: number,
  addressableType: string,
  initialRegionId?: number,
  initialCommuneId?: number,
}

const props = withDefaults(defineProps<AddressNewProps>(), {
  initialRegionId: undefined,
  initialCommuneId: undefined,
});

const regions = ref([] as Region[]);
const communes = ref([] as Commune[]);
const selectedRegion = ref({} as OptionRecord);
const selectedCommune = ref({} as OptionRecord);
const selectedRegionId = computed(() => selectedRegion.value.id);
const selectedCommuneId = computed(() => selectedCommune.value.id);
const regionRecords = computed(() => regions.value?.map((region) => ({ id: region.id, name: region.name })));
const filterCommunes = computed(() =>
  (communes.value?.filter((commune) => commune.regionId === selectedRegion.value.id)));
const communeRecords = computed(() => filterCommunes.value?.map((commune) => ({ id: commune.id, name: commune.name })));
const isLoading = ref(false);

onMounted(async () => {
  isLoading.value = true;
  const [regionsData, communesData] = await Promise.all([addressesApi.regions(), communesApi.index()]);
  regions.value = regionsData.data.regions;
  communes.value = communesData.data.communes;
  if (props.initialRegionId) {
    selectedRegion.value = regionRecords.value?.find((region) => region.id === props.initialRegionId) as OptionRecord;
  }
  if (props.initialCommuneId) {
    selectedCommune.value = communeRecords.value?.find(
      (commune) => commune.id === props.initialCommuneId) as OptionRecord;
  }
  isLoading.value = false;
});

const emit = defineEmits<{(e: 'address-created', value: number): void}>();

const nameReference = ref('');
const address = ref('');
const postalCode = ref('');
const observations = ref('');

const addressMutation = useMutation(() => {
  const data: Partial<Address> = {
    nameReference: nameReference.value,
    address: address.value,
    postalCode: postalCode.value,
    observations: observations.value,
    regionId: selectedRegionId.value as number,
    communeId: selectedCommuneId.value as number,
    addressableId: props.addressableId,
    addressableType: props.addressableType,
  };

  return addressesApi.create(data);
}, {
  onSuccess: (response) => {
    emit('address-created', response.data.address.id);
  },
});

watch(selectedRegion, () => {
  if (selectedRegion.value.id !== props.initialRegionId) {
    selectedCommune.value = {} as OptionRecord;
  }
});

const loading = computed(() => addressMutation.isLoading.value);
const { handleSubmit } = useForm();
const onSubmit = handleSubmit(async () => {
  addressMutation.mutate();
});
</script>
