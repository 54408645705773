<template>
  <div class="flex flex-col items-center mb-10 w-full h-full md:px-5 md:max-w-7xl lg:px-10">
    <div class="flex items-center px-2 mb-3 w-full md:px-0">
      <p class="flex flex-col self-end text-xl text-gray-700 md:text-2xl lg:text-3xl">
        <span>Productos</span>
        <a
          :href="`/makers/${maker.slug}`"
          class="text-sm font-light md:text-base"
          target="_blank"
        >
          Ver perfil público
        </a>
      </p>
      <maker-products-add
        :maker="maker"
        class="ml-auto"
      />
    </div>
    <div class="flex flex-col items-center py-3 px-2 w-full h-full bg-white rounded-md md:py-5 md:px-4 lg:px-10">
      <template
        v-for="message, index in showMessages"
        :key="message"
      >
        <lokal-banner-message-dialog
          v-if="message[1]"
          v-model="showBanners[index]"
          :class="{ 'mb-5': index === showMessages.length - 1 }"
          :show-dialog="message[0].includes('dialog')"
          :color-key="(message[0] as ColorKeyType)"
          :message="message[1]"
          title="Información importante"
        />
      </template>
      <div class="flex flex-col mb-3 w-full">
        <div class="flex justify-between items-center mb-1">
          <p class="text-sm md:text-base">
            Busca por nombre o sku:
          </p>
          <p class="ml-auto text-xs md:text-sm">
            {{ totalResources }} productos
          </p>
        </div>
        <Field
          v-model="shadowSearchText"
          name="shadowSearchText"
          class="p-2 mb-2 text-sm rounded-md border border-lk-light-gray focus:outline-none md:text-base"
        />
        <p class="text-sm md:text-base">
          Estado:
        </p>
        <lokal-generic-combobox
          v-model="selectedStatus"
          class="w-full"
          :get-option-key="(option) => option.id"
          :get-option-label="(option) => option.value"
          :options="statuses"
          :options-label="`Selecciona un estado`"
          :multiple="false"
          :local-search="false"
        />
      </div>
      <div
        v-if="!isMobile"
        class="grid grid-cols-7 gap-x-2 items-center place-items-center p-3 mb-2 text-xs text-center text-white bg-lk-green rounded-md lg:grid-cols-8 lg:gap-x-4 lg:p-3 lg:text-base"
      >
        <p>
          Imagen referencial
        </p>
        <p class="flex flex-col lg:col-span-2">
          <span>Nombre producto</span>
          <span class="text-xs lg:text-sm">SKU</span>
        </p>
        <p class="flex flex-col">
          <span>Público (c/iva) /</span>
          <span>Mayorista neto</span>
        </p>
        <lokal-tooltip
          main-label="Prioridad"
          class="z-0"
          :information="priorityInformation"
          button-classes="flex-col items-center flex"
          position-options="center"
        />
        <p
          v-if="maker.inventoryEnabled"
          class=""
        >
          Inventario
        </p>
        <p
          v-else
          class=""
        >
          Unidades Vendidas
        </p>
        <p class="">
          Estado
        </p>
        <p class="">
          Edición
        </p>
      </div>
      <lokal-loading
        v-if="isLoading"
        :loading="isLoading"
        class="self-center mt-2"
      />
      <makers-product-list-card
        v-for="product in newProducts"
        v-else
        :key="product.id"
        :product="product"
        :maker="maker"
        @reload-products="async () => await productQuery.refetch.value()"
      />
      <pagination-component
        v-if="!isLoading && newProducts?.length"
        :total-pages="totalPages"
        :current-page="currentPage"
        @set-new-page="(newPage: number) => currentPage = newPage"
      />
      <upload-products-from-file-dialog
        v-model="showFileUploaderDialog"
        :maker="maker"
        @add-message="(message: string[]) => messages.push(message)"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, inject, onMounted, onUnmounted, ref, watch } from 'vue';
import { useQuery } from 'vue-query';
import { Field } from 'vee-validate';
import { debounce } from 'lodash';
import productsApi from 'api/products';
import useUrlParamStore from 'stores/url-param-store';
import MakersProductListCard from './makers-product-list-card.vue';
import UploadProductsFromFileDialog from './shared/upload-products-from-file-dialog.vue';
import type { ColorKeyType } from './shared/lokal-banner.vue';
import PaginationComponent from './shared/pagination-component.vue';
import useCustomParseInt from './use/custom-parse-int';
import LokalTooltip from './shared/lokal-tooltip.vue';
import useUrlMethods from './use/url-methods';
import MakerProductsAdd from './maker-products-add.vue';
import LokalGenericCombobox from './shared/lokal-generic-combobox.vue';

const DEBOUNCE_TIME = 200;

const STATUSES_HASH = { all: -1, inactive: 0, outOfStock: 1, active: 2 };

interface MakersProductListProps {
  maker: Maker,
  withMakerImage?: boolean,
}

const props = withDefaults(defineProps<MakersProductListProps>(), {
  withMakerImage: true,
});

const currentPage = ref(1);
const shadowSearchText = ref('');
const searchText = ref('');
const debounceIsTyping = debounce(() => {
  searchText.value = shadowSearchText.value;
  currentPage.value = 1;
}, DEBOUNCE_TIME);
watch(shadowSearchText, debounceIsTyping);

const selectedStatus = ref({ id: 'all', value: 'Todos' });
const statuses = ref([{ id: 'all', value: 'Todos' }, { id: 'active', value: 'Activos' },
  { id: 'inactive', value: 'Inactivos' }, { id: 'outOfStock', value: 'Sin stock' }]);
const queryParamsForStatus = computed(() => {
  if (selectedStatus.value.id === 'all') return '';

  return `q[status_eq]=${STATUSES_HASH[selectedStatus.value.id as keyof typeof STATUSES_HASH]}&`;
});

const queryParams = computed(() => {
  let params = `q[maker_slug_in][]=${props.maker.slug}&q[s][]=status+desc&q[s][]=maker_priority+asc&`;
  if (searchText.value) params += `search_text=${searchText.value}&`;
  if (queryParamsForStatus.value) params += queryParamsForStatus.value;

  return new URLSearchParams(params).toString();
});
const productQuery = useQuery(['products', currentPage, queryParams], () =>
  productsApi.newIndex(currentPage.value, queryParams.value),
{ keepPreviousData: true });
const isLoading = computed(() => productQuery.isLoading.value || productQuery.isPreviousData.value);
const newProducts = computed(() => productQuery.data.value?.data.products as Product[]);
const totalResources = computed(() => useCustomParseInt(productQuery.data.value?.headers['x-total']));
const resourcesPerPage = computed(() => useCustomParseInt(productQuery.data.value?.headers['x-per-page']));
const totalPages = computed(() => Math.ceil(totalResources.value / resourcesPerPage.value));

const isMobile = inject('isMobile');

const showFileUploaderDialog = ref(false);
const messages = ref([] as string[][]);
const showMessages = computed(() => messages.value);
const showBanners = ref({} as { [string: number]: boolean });

watch(() => messages.value.length, () => {
  messages.value.forEach((_, index) => {
    showBanners.value[index] = true;
  });
});

const priorityInformation = `
  La prioridad es el orden en el que se muestran los productos en el perfil público de tu marca.
  Siendo 1 la primera, 2 la segunda, 3 la tercera y así sucesivamente.
`;

const { updateUrlParams, getQueryParam } = useUrlMethods();
function updateQueryParams() {
  updateUrlParams({ page: `${currentPage.value}` });
}

function setPage() {
  const page = getQueryParam('page');

  if (page) {
    currentPage.value = parseInt(page, 10);
  }
}

const urlParamStore = useUrlParamStore();
watch(currentPage, updateQueryParams);
onMounted(setPage);
onUnmounted(() => {
  urlParamStore.paramsToUpdate.page = '';
});
</script>
