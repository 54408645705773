import { csrfToken } from '@rails/ujs';
import { ref } from 'vue';
import api from './index';

const token = ref(csrfToken());
let headers = {};
if (token.value) {
  headers = { 'X-CSRF-Token': token.value };
}

export default {
  index(queryParams = '') {
    return api({
      method: 'get',
      url: `/api/internal/collections?${queryParams}`,
      headers,
    });
  },
  create(data: Partial<Collection>) {
    return api({
      method: 'post',
      url: '/api/internal/collections',
      headers,
      data,
    });
  },
  update(id: number, data: Partial<Collection>) {
    return api({
      method: 'put',
      url: `/api/internal/collections/${id}`,
      headers,
      data,
    });
  },
};
