<template>
  <div class="flex flex-col items-center mb-10 w-full h-full md:px-5 md:max-w-7xl lg:px-10">
    <div class="flex items-center px-2 mb-3 w-full md:px-0">
      <p class="flex flex-col self-end text-xl text-gray-700 md:text-2xl lg:text-3xl">
        Incidencias
      </p>
      <button
        class="flex items-center p-2 ml-auto text-sm text-lk-green hover:text-white bg-white hover:bg-lk-green rounded-md border border-lk-green md:p-3 md:text-base"
        @click="showIncidenceNewDialog = !showIncidenceNewDialog"
      >
        <PlusIcon class="shrink-0 mr-2 w-5 h-5" />
        <span>Crear incidencia</span>
      </button>
    </div>
    <div class="flex flex-col py-3 px-2 w-full h-full text-gray-700 bg-white rounded-md md:py-5 md:px-4 lg:px-10">
      <lokal-loading
        v-if="isLoading"
        :loading="isLoading"
      />
      <template v-else-if="incidenceReports?.length">
        <p class="mb-2 ml-auto text-sm font-light md:text-base">
          {{ totalResources }} incidencias
        </p>
        <div
          v-for="incidenceReport in incidenceReports"
          :key="incidenceReport.id"
          class="flex flex-col p-2 mb-2 rounded-md border border-lk-light-gray md:p-3"
        >
          <div class="flex items-center">
            <ExclamationCircleIcon
              class="shrink-0 mr-1 w-10 h-10 md:w-8 md:h-8"
            />
            <p class="text-base uppercase md:text-lg">
              {{ incidenceReport.makerName }} · orden #{{ incidenceReport.orderId }}
            </p>
            <div
              class="py-1 px-2 mr-2 ml-auto text-xs uppercase rounded-md md:text-base"
              :class="{ 'bg-lk-green text-white': incidenceReport.status === 'incidence_resolved',
                        'bg-lk-light-gray text-white': incidenceReport.status === 'incidence_in_progress' }"
            >
              {{ t(`incidenceReport.status.${camelCase(incidenceReport.status)}`) }}
            </div>
          </div>
          <p class="mb-2 text-sm font-light md:text-base">
            {{ timeToDate(incidenceReport.createdAt) }}
          </p>
          <p
            class="text-sm md:text-base"
            v-html="incidenceReport.description"
          />
          <div
            v-if="incidenceReport.images.length"
            class="flex flex-col mb-2"
          >
            <p class="mb-2">
              Imágenes
            </p>
            <div class="flex flex-wrap items-center">
              <a
                v-for="image in incidenceReport.images"
                :key="image.id"
                class="shrink-0 mr-2 w-32 h-32"
                :href="image.derivatives?.jpgLg?.url || image.derivatives?.original?.url"
                target="_blank"
              >
                <img
                  :src="image.derivatives?.jpgMd?.url"
                  class="object-cover object-center shrink-0 mr-2 w-32 h-32"
                >
              </a>
            </div>
          </div>
          <div
            v-if="incidenceReport.documents.length"
            class="flex flex-col"
          >
            <p class="mb-2">
              Documentos
            </p>
            <div
              v-for="document in incidenceReport.documents"
              :key="document.id"
              class="flex items-center space-x-2"
            >
              <DocumentTextIcon class="shrink-0 w-4 h-4 md:w-5 md:h-5" />
              <a
                :href="document.documentUrl"
                target="_blank"
                class="font-light hover:underline"
              >
                {{ document.documentData?.metadata?.filename }}
              </a>
            </div>
          </div>
        </div>
        <pagination-component
          :current-page="currentPage"
          :total-pages="totalPages"
          @set-new-page="updatePage"
        />
      </template>
      <p
        v-if="incidenceReports?.length === 0"
        class="text-base md:text-lg"
      >
        ¡Buenísimo!. No hay incidencias.
      </p>
    </div>
    <incidence-report-new-dialog
      v-if="showIncidenceNewDialog"
      v-model="showIncidenceNewDialog"
      :shop-id="props.shopId"
      @reload:incidence-reports="async () => incidenceReportsQuery.refetch.value()"
    />
  </div>
</template>
<script lang="ts" setup>
import { ref, computed } from 'vue';
import { useQuery } from 'vue-query';
import { camelCase } from 'lodash';
import { PlusIcon, ExclamationCircleIcon, DocumentTextIcon } from '@heroicons/vue/24/outline';
import { useI18n } from 'vue-i18n';
import incidenceReportsApi from 'api/incidence-reports';
import incidenceReportNewDialog from './incidence-report-new-dialog.vue';
import useDateTools from './use/date-tools';
import useCustomParseInt from './use/custom-parse-int';
import paginationComponent from './shared/pagination-component.vue';

interface ShopIncidenceListProps {
  shopId: number
}

const props = withDefaults(defineProps<ShopIncidenceListProps>(), {
});

const showIncidenceNewDialog = ref(false);

const { t } = useI18n();
const { timeToDate } = useDateTools();

const currentPage = ref(1);
const queryParams = computed(() => 'q[s][]=created_at+desc');
const incidenceReportsQuery = useQuery(
  ['incidence-reports', props.shopId, currentPage, queryParams],
  () => incidenceReportsApi.shopIndex(props.shopId, currentPage.value, queryParams.value),
  {
    keepPreviousData: true,
  },
);
const incidenceReports = computed(() => incidenceReportsQuery.data.value?.data?.incidenceReports as IncidenceReport[]);
const totalResources = computed(() => useCustomParseInt(incidenceReportsQuery.data.value?.headers['x-total']));
const resourcesPerPage = computed(() => useCustomParseInt(incidenceReportsQuery.data.value?.headers['x-per-page']));
const totalPages = computed(() => Math.ceil(totalResources.value / resourcesPerPage.value));
const isLoading = computed(() => incidenceReportsQuery.isLoading.value || incidenceReportsQuery.isPreviousData.value);

function updatePage(page: number) {
  currentPage.value = page;
}
</script>
