<template>
  <div class="flex flex-col items-center py-10 w-full bg-gray-50">
    <div class="flex flex-col py-3 px-5 w-full max-w-3xl text-gray-700 bg-white rounded-md md:p-10">
      <div class="flex justify-between mb-7 w-full">
        <a
          class="flex items-center text-sm hover:underline md:text-base"
          href="/makers/account?tab=products&subTab=all"
        >
          <ChevronLeftIcon class="mr-2 w-4 h-4 md:w-5 md:h-5" />
          Volver
        </a>
        <lokal-button
          v-if="product && product.id"
          size="small"
          action-name="Ver producto"
          action="secondary"
          tag="a"
          target="_blank"
          :href="`/products/${product?.id}`"
        />
      </div>
      <lokal-loading
        v-if="isLoading"
        size="big"
        class="self-center"
      />
      <div
        v-else
        class="flex flex-col w-full"
      >
        <h3 class="mb-5 font-serif text-xl text-center md:text-3xl">
          {{ product ? 'Editar información de producto' : 'Crear nuevo producto' }}
        </h3>
        <div
          v-if="product"
          class="grid grid-cols-3 gap-3 items-center self-center mb-5"
        >
          <img
            v-for="image in sortedProductImages"
            :key="image.id"
            :src="image.derivatives?.jpgSm?.url"
            class="object-cover w-20 h-20 rounded-md md:mr-5 md:w-24 md:h-24"
          >
        </div>
        <form
          class="flex flex-col mb-10 w-full text-sm text-gray-700 md:text-base"
          @submit="onSubmit"
        >
          <div
            v-if="product"
            class="flex flex-col justify-between mb-1"
          >
            <p class="mb-1 font-normal">
              Estado
            </p>
            <lokal-single-selector
              v-model="selectedActivationOption"
              key-option="value"
              class="mb-2 w-full"
              :options="activationOptions"
              option-label="label"
              options-label="Seleccionar estado"
              custom-label-color="bg-lk-light-green"
              size="md"
              color-key="colorKey"
            />
          </div>
          <div class="flex justify-between mb-2 w-full">
            <p class="font-normal">
              Recomendado
            </p>
            <lokal-switch v-model="form.recommended" />
          </div>
          <p class="font-normal">
            Nombre
          </p>
          <form-input
            v-model="form.name"
            class="mb-1"
            placeholder="Nombre"
            type="text"
            name="name"
            label-name="Nombre"
            :rules="{ required: true }"
          />
          <p class="font-normal">
            Precio público (c/IVA)
          </p>
          <form-input
            v-model.number="form.unitaryPriceCents"
            class="mb-1"
            placeholder="Precio público"
            type="number"
            name="unitaryPriceCents"
            label-name="Precio público"
            :rules="{ required: true }"
          />
          <div class="flex items-center">
            <lokal-tooltip
              main-label="Precio mayorista neto"
              position-options="left"
              information-classes="w-80"
              button-classes="flex items-center space-x-1"
              information="Precio sin ningún impuesto."
            />
          </div>
          <form-input
            v-model.number="form.wholesalePriceCents"
            class="mb-1"
            placeholder="Precio mayorista"
            type="number"
            name="wholesalePriceCents"
            label-name="Precio mayorista"
            :rules="{ required: true }"
          />
          <p class="font-normal">
            Impuesto adicional
          </p>
          <lokal-generic-combobox
            v-model="additionalTax"
            compare-by="value"
            :options="additionalTaxOptions"
            :get-option-key="(option) => option.value"
            :get-option-label="(option) => option.label"
            options-label="Impuesto adicional"
            class="mb-1"
            text-size="text-xs md:text-sm"
            :multiple="false"
          />
          <p class="font-normal">
            Margen para la tienda (recomendamos más de 30%)
          </p>
          <p class="p-2 mb-2 text-xs bg-gray-100 rounded-md md:text-sm">
            {{ margin }}%
          </p>
          <p class="font-normal">
            Unidades mínimas a comprar
          </p>
          <form-input
            v-model.number="form.minimumPurchaseQuantity"
            class="mb-2"
            placeholder="Unidades mínimas a comprar"
            type="number"
            name="minimumPurchaseQuantity"
            label-name="Unidades mínimas a comprar"
            :rules="{ required: true, min_value: 1 }"
          />
          <div class="flex items-center">
            <lokal-tooltip
              main-label="Unidades por pack"
              :position-options="isMobile ? 'left' : 'center'"
              information-classes="w-80"
              button-classes="flex items-center space-x-1"
              :information="packUnitsInformation"
            />
          </div>
          <form-input
            v-model.number="form.packUnits"
            class="mb-2"
            placeholder="Unidades por pack"
            type="number"
            name="packUnits"
            label-name="Unidades por pack"
            :rules="{ required: true, min_value: 1 }"
          />
          <div class="flex items-center">
            <lokal-tooltip
              :position-options="isMobile ? 'left' : 'center'"
              information-classes="w-80"
              button-classes="flex items-center space-x-1"
              main-label="SKU"
              :information="skuInformation"
            />
          </div>
          <form-input
            v-model="form.sku"
            class="mb-2"
            placeholder="SKU. Debe ser único."
            type="string"
            name="sku"
            label-name="Sku"
            :rules="{ required: false, validateSku: [maker.id, product?.id] }"
          />
          <div class="flex items-center">
            <lokal-tooltip
              :position-options="isMobile ? 'left' : 'center'"
              information-classes="w-80"
              button-classes="flex items-center space-x-1"
              main-label="Prioridad"
              :information="priorityInformation"
            />
          </div>
          <form-input
            v-model.number="form.makerPriority"
            class="mb-2"
            placeholder="Prioridad"
            type="number"
            name="makerPriority"
            label-name="Prioridad"
            :rules="{ required: true, min_value: 1 }"
          />
          <p class="font-normal">
            Peso
          </p>
          <div class="flex items-center mb-3">
            <form-input
              v-model.number="form.weight"
              class="mr-2 w-full"
              placeholder="Peso"
              type="number"
              name="weight"
              label-name="Peso"
              :rules="{ required: false }"
            />
            <form-input
              v-model="form.weightUnit"
              hidden
              class="mb-0"
              placeholder="Unidad de Peso"
              type="text"
              name="weightUnit"
              label-name="Unidad de Peso"
              :rules="{ required: false }"
            />
            <lokal-single-selector
              v-model="selectedWeightUnit"
              :options="weightUnits"
              class="shrink-0"
              key-option="value"
              option-label="label"
              option-value="value"
              options-label="Unidades de peso"
              :multiple="false"
              @update:model-value="(optionRecord: OptionRecord) => form.weightUnit = (optionRecord['value'] as string)"
            />
          </div>
          <div
            class="flex justify-between items-center mb-2 w-full"
          >
            <p class="font-normal">
              Duración del producto (solo aplica para productos con menos de 6 meses de duración)
            </p>
            <lokal-switch v-model="shelfLifeEnable" />
          </div>
          <div
            class="flex items-center mb-3"
          >
            <form-input
              v-model.number="form.shelfLife"
              class="mr-2 w-full"
              placeholder="Peso"
              type="number"
              name="shelfLife"
              label-name="Peso"
              :rules="{ required: false }"
              :disabled="!shelfLifeEnable"
            />
            <form-input
              v-model="form.shelfLifeUnit"
              hidden
              class="mb-0"
              placeholder="Unidad de Peso"
              type="text"
              name="shelfLifeUnit"
              label-name="Unidad de Peso"
              :rules="{ required: false }"
              :disabled="!shelfLifeEnable"
            />
            <lokal-single-combobox
              v-model="selectedShelfLifeUnit"
              :options="shelfLifeUnits"
              options-label="Unidad de duración"
              option-label="label"
              key-option="value"
              text-size="text-sm"
              :disabled="!shelfLifeEnable"
              @update:model-value="
                (shelfLifeUnitObject: OptionRecord) => form.shelfLifeUnit = (shelfLifeUnitObject['value'] as string)"
            />
          </div>
          <template v-if="maker.inventoryEnabled">
            <div class="flex justify-between mb-2 w-full">
              <p class="font-normal">
                Unidades disponibles (inventario)
              </p>
              <lokal-switch v-model="inventoryEnable" />
            </div>
            <form-input
              v-model.number="inventoryQuantity"
              class="mb-2"
              :disabled="!inventoryEnable"
              :class="{ 'opacity-50': !inventoryEnable }"
              placeholder="Unidades disponibles (inventario)"
              type="number"
              name="inventoryQuantity"
              label-name="Unidades disponibles (inventario)"
              :rules="{ required: false }"
            />
          </template>
          <p class="font-normal">
            Especificaciones
          </p>
          <form-input
            v-model="form.specifications"
            class="mb-2"
            placeholder="Ej: 10x20x5 cm, 100ml, 10 unidades, etc"
            type="text"
            name="specifications"
            label-name="Especificaciones"
            :rules="{ required: false }"
          />
          <p class="font-normal">
            Descripción
          </p>
          <tip-tap-editor
            v-model="form.description"
            class="mb-5"
          />
          <lokal-loading
            v-if="labelsQueriesAreLoading"
            v-model="labelsQueriesAreLoading"
            size="xs"
          />
          <template v-else>
            <div class="flex items-center mb-1 w-full">
              <div
                v-for="selectedLabel in selectedProductLabels"
                :key="`selected-product-label-${selectedLabel.id}`"
                class="flex items-center py-1 px-2 mr-2 text-xs text-white bg-lk-green rounded-full md:text-sm"
              >
                <span class="mr-1">{{ selectedLabel.name }}</span>
                <button
                  class="shrink-0 w-4 h-4"
                  @click="removeSelectedProductLabel(selectedLabel.id)"
                >
                  <XCircleIcon class="shrink-0 w-full h-full" />
                </button>
              </div>
            </div>
            <lokal-generic-combobox
              v-model="selectedProductLabels"
              :options="labels"
              :get-option-key="(label: Label) => label.id"
              :get-option-label="(label: Label) => label.name"
              options-label="Escoge etiquetas"
              class="mb-5"
              :multiple="true"
            />
          </template>
          <image-uploader
            :current-images="productImages"
            class="mb-5"
            @update-files="updateimagesData"
            @update-images="updateProductImages"
          />
          <lokal-button
            v-if="!mutationLoading"
            :action-name="product?.id ? 'Actualizar Producto' : 'Crear Producto'"
            :size="isMobile ? 'normal' : 'big'"
            action="primary"
            class="self-center mb-2 w-full md:w-1/2"
            type="submit"
          />
          <lokal-loading
            v-else
            size="big"
            class="self-center"
          />
          <a
            :href="`/makers/account?tab=products&subTab=all`"
            class="flex items-center self-center mb-3 text-sm hover:underline md:text-base"
          >
            Volver a productos
          </a>
        </form>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, computed, watch, inject } from 'vue';
import { round, sortBy, snakeCase } from 'lodash';
import { useForm } from 'vee-validate';
import { ChevronLeftIcon, XCircleIcon } from '@heroicons/vue/24/outline';
import { useMutation, useQuery } from 'vue-query';
import productsApi from 'api/products';
import imageApi from 'api/images';
import useLabelsQuery from './queries/labels-query';
import FormInput from './shared/form-input.vue';
import imageUploader from './shared/image-uploader.vue';
import lokalSingleSelector, { type OptionRecord } from './shared/lokal-single-selector.vue';
import lokalSwitch from './shared/lokal-switch.vue';
import lokalTooltip from './shared/lokal-tooltip.vue';
import LokalSingleCombobox from './shared/lokal-single-combobox.vue';
import LokalGenericCombobox from './shared/lokal-generic-combobox.vue';
import useBillingTools from './use/billing-tools';

const PORCENTAGE = 100;
const DECIMAL_ROUND = 2;
const NUMBER_OF_IMAGES = 3;

const OPTIONS = [
  { value: 'inactive', label: 'Inactivo', colorKey: 'bg-red-500 text-white' },
  { value: 'outOfStock', label: 'Sin Stock', colorKey: 'bg-lk-light-purple text-white' },
  { value: 'active', label: 'Activo', colorKey: 'bg-lk-green text-white' },
];
const INACTIVE_POSITION = 0;
const OUT_OF_STOCK_POSITION = 1;
const ACTIVE_POSITION = 2;

const props = defineProps<{
  product?: Product
  maker: Maker
}>();

const { billTools, convertAdditionalTax, additionalTaxToText } = useBillingTools();
const isMobile = inject('isMobile');
const form = ref({
  name: props.product?.name || '',
  description: props.product?.description || '',
  unitaryPriceCents: props.product?.unitaryPriceCents || 0,
  wholesalePriceCents: props.product?.wholesalePriceCents || 0,
  sku: props.product?.sku || '',
  makerPriority: props.product?.makerPriority || 1,
  weight: props.product?.weight || 0,
  weightUnit: props.product?.weightUnit || '',
  shelfLife: props.product?.shelfLife || 0,
  shelfLifeUnit: props.product?.shelfLifeUnit || '',
  specifications: props.product?.specifications || '',
  minimumPurchaseQuantity: props.product?.minimumPurchaseQuantity || 1,
  packUnits: props.product?.packUnits || 1,
  recommended: props.product?.recommended || false,
  images: [] as Image[],
  additionalTax: props.product?.additionalTax || 'no_tax',
});
const imagesDataAndPriority = ref([] as { priority: number, imageData: ImageData }[]);
function updateimagesData(value: { priority: number, imageData: ImageData }[]) {
  imagesDataAndPriority.value = value;
}

const inventoryQuantity = ref(props.product?.inventory?.quantity || 0);
const imagesQuery = useQuery(
  ['product-images', props.product?.id],
  () => {
    if (props.product?.id) return imageApi.index({ imagableId: props.product?.id, imagableType: 'Product' });

    return Promise.resolve({ data: { images: [] } });
  },
  { refetchOnWindowFocus: false, enabled: computed(() => !!props.product?.id) });
const productImages = computed(() => imagesQuery.data.value?.data.images as Image[]);

// additional tax
const additionalTaxOptions = ref([
  { value: 'no_tax', label: additionalTaxToText('no_tax') },
  { value: 'low_iaba', label: additionalTaxToText('low_iaba') },
  { value: 'high_iaba', label: additionalTaxToText('high_iaba') },
  { value: 'liqueur_ila', label: additionalTaxToText('liqueur_ila') },
  { value: 'wine_ila', label: additionalTaxToText('wine_ila') },
]);
const additionalTax = ref({
  value: props.product?.additionalTax || additionalTaxOptions.value[0].value,
  label: additionalTaxOptions.value.find((option) => option.value === props.product?.additionalTax)?.label ||
    additionalTaxOptions.value[0].label,
});
const currentAdditionalTax = computed(() => convertAdditionalTax(additionalTax.value.value));
watch(additionalTax, () => {
  form.value.additionalTax = additionalTax.value.value;
});

const labelsQueryParams = computed(() => `q[products_id_eq]=${props.product?.id}`);
const productLabelsQuery = useLabelsQuery(labelsQueryParams, computed(() => !!props.product?.id));
const productLabelsIsLoading = computed(() => productLabelsQuery.labelsQuery.isLoading.value);
const productLabels = computed(() => productLabelsQuery.labels.value);
const { labels, labelsQuery } = useLabelsQuery(computed(() => ''), computed(() => true));
const labelsQueryIsLoading = computed(() => labelsQuery.isLoading.value);
const selectedProductLabels = ref([] as Label[]);
const labelsQueriesAreLoading = computed(() => productLabelsIsLoading.value || labelsQueryIsLoading.value);
const labelsData = computed(() => selectedProductLabels.value.map((label) => label.id));

function removeSelectedProductLabel(labelId: number) {
  selectedProductLabels.value = selectedProductLabels.value.filter((label) => label.id !== labelId);
}

watch(productLabels, () => {
  selectedProductLabels.value = [...selectedProductLabels.value, ...productLabels.value];
});

const inventoryEnable = ref(props.product?.inventory?.enable || false);
const updateData = computed(() => {
  if (inventoryEnable.value) {
    const status = inventoryQuantity.value > 0 ? 'active' : 'out_of_stock';
    const inventoryAttributes = { id: props.product?.inventoryId, quantity: inventoryQuantity.value, enable: true };

    return { product: { ...form.value, status, inventoryAttributes },
      imagesDataAndPriority: imagesDataAndPriority.value, labelIds: labelsData.value };
  }

  return { product: { ...form.value }, imagesDataAndPriority: imagesDataAndPriority.value, labelIds: labelsData.value };
});

const createData = computed(() => {
  if (inventoryEnable.value) {
    const inventoryAttributes = { id: props.product?.inventoryId, quantity: inventoryQuantity.value, enable: true };

    return {
      product: { ...form.value, makerId: props.maker.id, inventoryAttributes },
      imagesDataAndPriority: imagesDataAndPriority.value, labelIds: labelsData.value,
    };
  }

  return {
    product: { ...form.value, makerId: props.maker.id },
    imagesDataAndPriority: imagesDataAndPriority.value, labelIds: labelsData.value,
  };
});

const productMutation = useMutation(() => {
  if (props.product?.id) return productsApi.update(props.product.id, updateData.value);

  return productsApi.create(createData.value);
}, {
  onSuccess: () => {
    if (window.history.length > 1) {
      window.history.back();
    } else {
      window.location.href = `/makers/${props.maker.slug}/admin?tab=products&subTab=all`;
    }
  },
});

const isLoading = computed(() => imagesQuery.isLoading.value);
const mutationLoading = computed(() => productMutation.isLoading.value);

const activationOptions = ref(OPTIONS as OptionRecord[]);
const selectedActivationOption = ref({} as OptionRecord);
const weightUnits = ref([{ value: 'grams', label: 'gr' }, { value: 'kilograms', label: 'kg' }]);
const selectedWeightUnit = ref(weightUnits.value.find((unit) => unit.value === props.product?.weightUnit) || {});
const shelfLifeUnits = ref([{ value: 'days', label: 'días' }, { value: 'months', label: 'meses' }]);
const selectedShelfLifeUnit = ref(
  shelfLifeUnits.value.find((unit) => unit.value === props.product?.shelfLifeUnit) || {});
const shelfLifeEnable = ref(!!props.product?.shelfLife || false);

async function updateProductImages() {
  if (props.product?.id) await imagesQuery.refetch.value();
}

watch(() => props.product, () => {
  if (props.product?.status === 'inactive') {
    selectedActivationOption.value = activationOptions.value[INACTIVE_POSITION];
  } else if (props.product?.status === 'out_of_stock') {
    selectedActivationOption.value = activationOptions.value[OUT_OF_STOCK_POSITION];
  } else {
    selectedActivationOption.value = activationOptions.value[ACTIVE_POSITION];
  }
});

watch(() => productImages.value, () => {
  form.value.images = productImages.value;
});

const additionalTaxValue = computed(() => round(form.value.wholesalePriceCents * currentAdditionalTax.value));
const netWholesalePriceWithAdditionalTax = computed(() => form.value.wholesalePriceCents + additionalTaxValue.value);
const margin = computed(() => {
  if (form.value.unitaryPriceCents === 0 || form.value.wholesalePriceCents === 0) return 0;
  const netUnitaryPrice = round(form.value.unitaryPriceCents / billTools.value.ivaValue);
  const fraction = (netUnitaryPrice - netWholesalePriceWithAdditionalTax.value) / netUnitaryPrice;

  return round(fraction * PORCENTAGE, DECIMAL_ROUND);
});

const { handleSubmit } = useForm();
const onSubmit = handleSubmit(async () => {
  productMutation.mutate();
});

const sortedProductImages = computed(() => sortBy(props.product?.images, 'priority').slice(0, NUMBER_OF_IMAGES));

const statusMutation = useMutation((status: string) => {
  if (props.product?.id) productsApi.update(props.product?.id, { product: { status } });

  return Promise.resolve({});
});

watch(selectedActivationOption, async (newActivationOption: OptionRecord) => {
  if (props.product?.id) {
    statusMutation.mutate(snakeCase(`${newActivationOption.value}`));
  }
});

const packUnitsInformation = ref(`
  Cantidad de unidades que contiene el paquete. <br>
  Ejemplo: Si el producto es un pack de 6 unidades de galletas, entonces el valor de este campo
  debe ser 6. <br>
  Nota: Si el producto es un pack el precio debe ser por unidad y NO por pack.
`);

const skuInformation = ref(`
  SKU es el código que identifica a tu producto. Debe ser único. En caso de no tener uno te crearemos
  uno automáticamente de la siguiente manera: <br>
  - Iniciales de la maraca + id de la marca + número de producto <br>
  - Ejemplo: LK-12_003 <br>
`);

const priorityInformation = `
  La prioridad es el orden en el que se muestran los productos en el perfil público de tu marca.
  Siendo 1 la primera, 2 la segunda, 3 la tercera y así sucesivamente.
`;
</script>
