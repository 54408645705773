<template>
  <div
    v-if="shop?.kind === 'personal_consumption'"
    class="flex flex-col"
  >
    <ExclamationCircleIcon class="shrink-0 self-center mb-2 w-60 h-60 text-lk-green" />
    <p
      class="mb-3 text-sm text-red-500 md:text-base"
    >
      En Lokal vendemos productos a tiendas o empresas exclusivamente.
      Si deseas productos para consumo personal debes comprar directamente en la página de
      las marcas que te interesen.
    </p>
    <a
      class="p-2 text-sm text-center text-white bg-lk-green hover:bg-lk-green-deep rounded-md md:text-base"
      href="/"
    >
      Aceptar
    </a>
  </div>
  <div
    v-else
    class="flex flex-col items-center self-center w-full max-w-sm h-full text-gray-700"
  >
    <p class="font-serif text-xl text-center md:text-2xl">
      ¡Tu cuenta fue creada con éxito!
    </p>
    <p class="mt-2 text-sm text-gray-500 md:text-center">
      Encuentra nuevos productos para tu tienda junto a estos beneficios
    </p>
    <div class="grid grid-cols-2 gap-x-2 self-center my-5">
      <div class="flex flex-col items-center p-4 py-5 space-y-2 w-full bg-gray-100 md:p-4">
        <div class="flex shrink-0 col-span-2 col-start-2 justify-center items-center self-center w-20 h-20 bg-white rounded-full md:w-24 md:h-24">
          <inline-svg-wrapper
            name="tag-icon"
            class="h-10 fill-gray-700 md:h-11"
          />
        </div>
        <p class="text-sm font-semibold text-center md:text-base">
          Devoluciones gratis para tu primera compra de cada marca
        </p>
      </div>
      <div class="flex flex-col items-center p-4 py-5 space-y-2 bg-gray-100 md:p-4">
        <div class="flex shrink-0 col-span-2 col-start-2 justify-center items-center self-center w-20 h-20 bg-white rounded-full md:w-24 md:h-24">
          <inline-svg-wrapper
            name="bill-icon"
            class="h-10 fill-gray-700 md:h-11"
          />
        </div>
        <p class="text-sm font-semibold text-center md:text-base">
          Condiciones de pago a 60 días para tiendas físicas
        </p>
      </div>
    </div>
    <button
      class="p-2 text-sm text-white bg-lk-green hover:bg-lk-green-deep rounded-md md:text-base"
      @click="reload"
    >
      Revisar catálogo
    </button>
  </div>
</template>
<script lang="ts" setup>
import { computed, type ComputedRef } from 'vue';
import { ExclamationCircleIcon } from '@heroicons/vue/24/outline';
import useSessionStore from 'stores/user-store';
import useShopQuery from './queries/shop-query';
import inlineSvgWrapper from './inline-svg-wrapper.vue';

const sessionStore = useSessionStore();

const shopId = computed(() => sessionStore.user?.shopId);
const { shop } = useShopQuery(shopId as ComputedRef<number>);

function reload() {
  if (sessionStore.user?.referredSlug) {
    window.location.href = `/makers/${sessionStore.user?.referredSlug}`;
  } else {
    window.location.href = '/makers';
  }
}
</script>
