<template>
  <button
    v-if="enabledPath"
    v-show="showBottomMessages"
    class="flex fixed bottom-0 z-20 p-2 w-full text-amber-900 bg-yellow-50 md:p-3"
    :class="{ 'z-30': !onlyShowOneMessage }"
    @mouseover="!isMobile && (onlyShowOneMessage = false)"
    @mouseleave="!isMobile && (onlyShowOneMessage = true)"
    @click="onlyShowOneMessage = !onlyShowOneMessage"
  >
    <bottom-message-list-card
      v-for="makerId in cartStore.itemsMakerIds"
      :key="`hidden-messages-list-card-${makerId}`"
      class="hidden"
      :maker-id="makerId"
      @maker-enabled="(value: boolean) => setMakerIdEnabled(value, makerId)"
    />
    <div
      v-if="onlyShowOneMessage"
      class="overflow-hidden w-full h-full"
    >
      <div
        class="flex transition-transform duration-500"
        :style="{ transform: `translateX(-${currentIndex * 100}%)` }"
      >
        <template
          v-for="makerId in makerIds"
          :key="`transition-messages-list-card-${makerId}`"
        >
          <bottom-message-list-card
            class="flex shrink-0 justify-center items-center w-full"
            :maker-id="makerId"
          />
        </template>
      </div>
    </div>
    <div
      v-else
      class="flex flex-col items-center w-full"
    >
      <bottom-message-list-card
        v-for="makerId in cartStore.itemsMakerIds"
        :key="`messages-list-card-${makerId}`"
        :maker-id="makerId"
      />
    </div>
  </button>
</template>
<script lang="ts" setup>
import { computed, ref, onMounted, onUnmounted, watch, inject } from 'vue';
import { values } from 'lodash';
import useCartStore from 'stores/cart';
import bottomMessageListCard from './bottom-message-list-card.vue';

const TIME_BETWEEN_MESSAGES = 2500;

const cartStore = useCartStore();

const isMobile = inject('isMobile') as boolean;

const onlyShowOneMessage = ref(true);
const showMakerId = ref({} as Record<number, boolean>);
const showBottomMessages = computed(() => values(showMakerId.value).some((value) => value));
const enabledPath = computed(() => {
  const currentPath = window.location.pathname;
  const makerPathRegex = /\/makers\/.+/;
  const productPathRegex = /\/products\/.+/;

  return !makerPathRegex.test(currentPath) && !productPathRegex.test(currentPath);
});

const makerIds = computed(() => {
  const makerIdKeys = Object.keys(showMakerId.value).map((key) => parseInt(key, 10));

  return makerIdKeys.filter((makerId) => !!showMakerId.value[makerId]);
});

const currentIndex = ref(0);
let intervalId: number;

function updateCurrentIndex() {
  currentIndex.value = (currentIndex.value + 1) % makerIds.value.length;
}

function startCarousel() {
  intervalId = window.setInterval(() => {
    updateCurrentIndex();
  }, TIME_BETWEEN_MESSAGES);
}

function setMakerIdEnabled(value: boolean, makerId: number) {
  showMakerId.value[makerId] = value;
}

function stopCarousel() {
  clearInterval(intervalId);
}

onMounted(() => {
  startCarousel();
});

onUnmounted(() => {
  stopCarousel();
});

watch(onlyShowOneMessage, () => {
  if (onlyShowOneMessage.value) {
    startCarousel();
  } else {
    stopCarousel();
  }
});
</script>
