import { csrfToken } from '@rails/ujs';
import { ref } from 'vue';
import api from './index';

const token = ref(csrfToken());
let headers = {};
if (token.value) {
  headers = { 'X-CSRF-Token': token.value };
}

export default {
  create(data: Partial<PotentialMakerContact>) {
    const path = '/api/internal/potential_maker_contacts';
    const dataToSend = { potentialMakerContact: data };

    return api({
      method: 'post',
      headers,
      url: path,
      data: dataToSend,
    });
  },
};
