import { csrfToken } from '@rails/ujs';
import { ref } from 'vue';
import api from './index';

const token = ref(csrfToken());
let headers = {};
if (token.value) {
  headers = { 'X-CSRF-Token': token.value };
}

export default {
  get(subOrderId: number) {
    const path = `/api/internal/sub_orders/${subOrderId}/incidence_report`;

    return api({
      method: 'get',
      url: path,
      headers,
    });
  },
  index(subOrderId: number) {
    const path = `/api/internal/sub_orders/${subOrderId}/incidence_reports`;

    return api({
      method: 'get',
      url: path,
      headers,
    });
  },
  shopIndex(shopId: number, page: number, queryParams: string) {
    const path = `/api/internal/shops/${shopId}/incidence_reports?page[number]=${page}&page[size]=12&${queryParams}`;

    return api({
      method: 'get',
      url: path,
      headers,
    });
  },
  create(incidenceReport: Partial<IncidenceReport>) {
    const path = '/api/internal/incidence_reports';

    return api({
      method: 'post',
      url: path,
      headers,
      data: incidenceReport,
    });
  },
};
