<template>
  <Dialog
    :open="modelValue"
    as="div"
    class="relative z-50"
    @click="emit('update:modelValue', false)"
  >
    <div
      class="fixed inset-0 bg-black/30"
      aria-hidden="true"
    />
    <div class="flex fixed inset-0 justify-center items-center p-2">
      <DialogPanel class="flex flex-col w-full max-w-md max-h-[500px] text-gray-700 bg-gray-50 rounded-md">
        <order-show-initial-message
          :order="order"
          :total-amount-with-taxes="totalAmountWithTaxes"
        >
          <template #cross>
            <button
              class="ml-auto focus:outline-none"
              @click="emit('update:modelValue', false)"
            >
              <XMarkIcon class="w-6 h-6 fill-gray-700" />
            </button>
          </template>
        </order-show-initial-message>
        <div class="flex flex-col items-center my-2 mx-4">
          <button
            class="p-1 mb-1 w-full text-sm text-center text-lk-green hover:text-white hover:bg-lk-green rounded-md border border-lk-green md:p-2 md:text-base"
            @click="emit('update:modelValue', false)"
          >
            {{ buttonText }}
          </button>
          <a
            class="text-sm hover:font-semibold hover:text-lk-green underline"
            :href="`https://wa.me/${phoneNumber}?text=${whatsappText}`"
            target="_blank"
            noopener
          >
            Necesito ayuda
          </a>
        </div>
      </DialogPanel>
    </div>
  </Dialog>
</template>
<script setup lang="ts">
import { XMarkIcon } from '@heroicons/vue/24/outline';
import { computed } from 'vue';
import {
  Dialog,
  DialogPanel,
} from '@headlessui/vue';
import OrderShowInitialMessage from './order-show-initial-message.vue';

interface OrderShowInitialMessageDialogProps {
  modelValue: boolean,
  order: Order,
  totalAmountWithTaxes: number
}

const props = withDefaults(defineProps<OrderShowInitialMessageDialogProps>(), {
  modelValue: false,
});

const emit = defineEmits<{(e: 'update:modelValue', value: boolean): void}>();
const phoneNumber = computed(() => process.env.CONTACT_NUMBER);

const buttonText = computed(() => {
  if (props.order.paymentMethod === 'transfer_now' || props.order.paymentMethod === 'card_now') {
    return '¡Entiendo!';
  }

  return '¡Buenísimo!';
});

const whatsappText = computed(() => `Necesito ayuda para avanzar con la Orden #${props.order.id}`);
</script>
