import { defineRule, configure } from 'vee-validate';
import AllRules from '@vee-validate/rules';
import { rutValidate, rutClean } from 'rut-helpers';
import { localize, setLocale } from '@vee-validate/i18n';
import en from '@vee-validate/i18n/dist/locale/en.json';
import es from '@vee-validate/i18n/dist/locale/es.json';
import productsApi from 'api/products';
import shopsApi from 'api/shops';
import usersApi from 'api/users';
import inventoryApi from 'api/inventories';
import discountCodesApi from 'api/discount-codes';

const TWO = 2;
const ARBITRARY_DATE_YEAR = 2000;
const THREE_SIXTY_IN_MS = 3600000;
// eslint-disable-next-line no-magic-numbers
const TWO_HOURS_IN_MS = 2 * 60 * 60 * 1000;

// eslint-disable-next-line max-statements
function useVeeValidateConfiguration() {
  Object.keys(AllRules).forEach(rule => {
    defineRule(rule, AllRules[rule]);
  });
  defineRule('rut', (value: string) => {
    if (!value || !value.length || !rutValidate(value)) {
      return 'El rut ingresado es inválido';
    }

    return true;
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  defineRule('minValueMaker', (value: number, [min, formattedMin]: [number, string]) => {
    if (!value || value < min) {
      return false;
    }

    return true;
  });
  defineRule('validateSku', async (value: string, [makerId, productId]: [number, number]) => {
    if (!value || !value.length) return true;

    const response = await productsApi.validateSku({ sku: value, makerId, productId });
    const data = response.data;
    if (!data.valid || data.error) {
      return data.error || 'El sku ingresado ya está en uso';
    }

    return true;
  });
  defineRule('validateRut', async (rut: string, [shopId]: [number]) => {
    if (!rut || !rut.length) return true;

    const response = await shopsApi.validateRut(rutClean(rut), shopId);
    const data = response.data;
    if (!data.valid || data.error) {
      return data.error || 'El rut ingresado ya está en uso';
    }

    return true;
  });
  defineRule('validateUserEmail', async (email: string) => {
    if (!email || !email.length) return true;

    const response = await usersApi.validateEmail(email);
    const data = response.data;
    if (!data.valid || data.error) {
      return data.error || 'El email ingresado ya está en uso';
    }

    return true;
  });
  defineRule('userEmailExists', async (email: string) => {
    if (!email || !email.length) return true;

    const response = await usersApi.validateEmail(email);
    const data = response.data;
    if (data.error) {
      return data.error;
    } else if (!data.valid) {
      return true;
    }

    return 'El email ingresado no está registrado';
  });
  defineRule('greaterOrEqualThan', (value: number, [number]: [number]) => {
    if (!value || value < number) {
      return false;
    }

    return true;
  });
  defineRule('asyncStockValidation', async (value: number, [productId]: [number], ctx) => {
    if (!value || value < 0) return false;

    const response = await inventoryApi.get(productId);
    const data = response.data;
    const inventory = data.inventory as Inventory;
    if (data.error) {
      return data.error;
    } else if (inventory.enable && value > inventory.quantity) {
      return `La cantidad ingresada (${value} unidades) de ${ctx.field} supera el stock disponible
              (${(inventory.quantity)} unidades).`;
    }

    return true;
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  defineRule('isValidDestination', (value: boolean, [constrainableName]: string) => value);
  defineRule('isValidDiscountCode', async (value: string, [makerId]: [number]) => {
    if (value !== '' && value !== undefined && value !== null) {
      const response = await discountCodesApi.validate(makerId, value);

      return response.data ? response.data.valid : false;
    }

    return true;
  });
  defineRule('validateRangeTime', (value: Record<string, number>[], [rangeTime] = [TWO_HOURS_IN_MS]) => {
    if (!value || value.length !== TWO) return 'Debe seleccionar un rango de tiempo';

    const [startTime, endTime] = value;
    const start = new Date(ARBITRARY_DATE_YEAR, 0, 1, startTime.hours, startTime.minutes);
    const end = new Date(ARBITRARY_DATE_YEAR, 0, 1, endTime.hours, endTime.minutes);
    const timeDiff = end.getTime() - start.getTime();

    if (timeDiff !== rangeTime) {
      // console.log('timeDiff', timeDiff);
      // console.log('rangeTime', rangeTime);
      const msToHrs = rangeTime / THREE_SIXTY_IN_MS;

      return `La diferencia de horas debe ser exactamente de ${msToHrs} hrs`;
    }

    return true;
  });

  configure({
    generateMessage: localize({
      en: {
        messages: {
          ...en.messages,
          minValueMaker: "{field}'s minimum is 1:{formattedMin}",
          greaterOrEqualThan: "{field}'s minimum is {number}",
          isValidDestination: 'The selected destination address is not valid for {constrainableName}',
        },
      },
      es: {
        names: {
          address: 'dirección',
          'shop[name]': "'nombre de la tienda'",
          'shop[commercial_businnes]': "'giro comercial'",
          name: 'nombre',
          commercialBusiness: 'giro comercial',
          businessRut: 'rut de empresa',
          socialReason: 'razón social',
          contactEmail: 'email de contacto',
          contactNumber: 'número de contacto',
          webpageLink: 'página web',
        },
        messages: {
          ...es.messages,
          minValueMaker: 'Mínimo de {field} es 1:{formattedMin}',
          url: 'El campo {field} no es una URL válida (0:{patter})',
          greaterOrEqualThan: 'El mínimo de {field} es 0:{number} Kg',
          isValidDestination: 'La dirección de destino seleccionado no es válida para 0:{constrainableName}',
          isValidDiscountCode: '{field} no es válido',
        },
      },
    }),
  });
  // let userLang = navigator.language;
  // if (!!userLang) {
  //   if (userLang === 'es-CL') {
  //     userLang = 'es';
  //   }
  //   setLocale(userLang);
  // }
  setLocale('es');
}

export default useVeeValidateConfiguration;
