
import { computed, type ComputedRef } from 'vue';
import { useQuery } from 'vue-query';
import shippingQuotationsApi from 'api/shipping-quotations';

export default function useValidDestinationQuery(
  constrainableId: ComputedRef<number>, constrainableType: ComputedRef<string>,
  communeId: ComputedRef<number | undefined>, enabled = computed(() => false),
) {
  const internalEnabled = computed(() => enabled.value && !!(constrainableId.value && communeId.value));
  const validDestinationQuery = useQuery(
    ['validDestination', constrainableId, constrainableType, communeId],
    () => shippingQuotationsApi.validate(constrainableId.value, constrainableType.value, communeId.value),
    { enabled: internalEnabled },
  );
  const validDestinationIsLoading = computed(() => validDestinationQuery.isLoading.value);
  const validDestinationData = computed(() => validDestinationQuery.data.value?.data);
  const validDestination = computed(() => validDestinationData.value?.valid as boolean);

  return {
    validDestinationQuery, validDestination, validDestinationIsLoading, validDestinationData,
  };
}
