import { computed } from 'vue';
import { useQuery } from 'vue-query';
import collectionsApi from 'api/collections';

export default function useCollectionsQuery(queryParams = computed(() => ''), enabled = computed(() => false)) {
  const collectionsQuery = useQuery(
    ['collections', queryParams],
    () => collectionsApi.index(queryParams.value),
    { enabled },
  );
  const isCollectionsLoading = computed(() => collectionsQuery.isLoading.value);
  const collections = computed(() => collectionsQuery.data.value?.data.collections as Collection[]);

  return { isCollectionsLoading, collections, collectionsQuery };
}
