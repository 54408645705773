<template>
  <div class="flex flex-col w-full h-full text-gray-700">
    <p class="flex flex-col items-center mb-4">
      <span class="font-serif text-xl md:text-2xl">Te damos la bienvenida</span>
      <span class="text-sm text-gray-500 md:text-base">Nos gustaría conocerte un poco más</span>
    </p>
    <form
      class="flex flex-col w-full"
      @submit.prevent="onSubmit"
    >
      <p class="text-sm">
        Nombre
      </p>
      <form-input
        v-model="signUpStore.userForm.firstName"
        class=""
        placeholder="Nombre"
        type="text"
        name="user[first_name]"
        label-name="Nombre"
        :rules="{ required: true }"
      />
      <p class="mt-1 text-sm">
        Apellido
      </p>
      <form-input
        v-model="signUpStore.userForm.lastName"
        class=""
        placeholder="Apellido"
        type="text"
        name="user[last_name]"
        label-name="Apellido"
        :rules="{ required: true }"
      />
      <p class="mt-1 text-sm">
        Contraseña
      </p>
      <form-input
        v-model="signUpStore.userForm.password"
        class=""
        placeholder="Contraseña"
        type="password"
        name="user[password]"
        label-name="Contraseña"
        :rules="{ required: true, min: 6 }"
      />
      <p class="mt-1 text-sm">
        Número de contacto
      </p>
      <contact-number-input v-model="signUpStore.userForm.contactNumber" />
      <lokal-loading
        v-if="isLoading"
        :loading="isLoading"
        class="self-center mt-3"
      />
      <div
        v-else
        class="flex flex-col items-center mt-4 w-full"
      >
        <button class="p-2 w-full text-sm text-white bg-lk-green hover:bg-lk-green-deep rounded-md md:text-base">
          Guardar y continuar
        </button>
        <a
          class="mt-1 text-sm cursor-pointer"
          @click="previousStep"
        >
          Volver
        </a>
      </div>
    </form>
  </div>
</template>
<script lang="ts" setup>
import { ref, computed } from 'vue';
import usersApi from 'api/users';
import { useMutation } from 'vue-query';
import { useForm } from 'vee-validate';
import useSignUpStore from 'stores/sign-up-store';
import useSessionStore from 'stores/user-store';
import formInput from './shared/form-input.vue';
import ContactNumberInput from './shared/contact-number-input.vue';

const kind = ref('shop');
const sessionStore = useSessionStore();
const signUpStore = useSignUpStore();
function previousStep() {
  signUpStore.currentStep = 'email';
}

const createUserMutation = useMutation(() => usersApi.create({
  ...signUpStore.userForm, contactNumber: `56${signUpStore.userForm.contactNumber}`,
}), {
  onSuccess: (response) => {
    sessionStore.user = response.data.user;
    signUpStore.resetUserForm();
    signUpStore.currentStep = 'shop';
  },
});
const isLoading = computed(() => createUserMutation.isLoading.value);

const { handleSubmit } = useForm();
const onSubmit = handleSubmit(() => {
  signUpStore.userForm.kind = kind.value;
  createUserMutation.mutate();
});
</script>
