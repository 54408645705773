import { csrfToken } from '@rails/ujs';
import { ref } from 'vue';
import api from './index';

const token = ref(csrfToken());
let headers = {};
if (token.value) {
  headers = { 'X-CSRF-Token': token.value };
}

export default {
  create(data: Partial<ProductCollection>) {
    const path = '/api/internal/product_collections';

    return api({
      method: 'post',
      data,
      url: path,
      headers,
    });
  },
  destroy(productId: number, collectionId: number) {
    const path = `/api/internal/product_collections?product_id=${productId}&collection_id=${collectionId}`;

    return api({
      method: 'delete',
      url: path,
      headers,
    });
  },
};
