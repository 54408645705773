<template>
  <div
    v-if="showComponent"
    class="flex justify-center items-center text-xs md:text-sm"
  >
    <InformationCircleIcon class="shrink-0 mr-2 w-4 h-4 md:w-5 md:h-5" />
    <p
      v-if="nextFreeShipping?.id"
      class="text-left"
    >
      Agrega {{ useFormatCurrency(missingAmountForFreeShipping) }} para obtener despacho gratis de
      <a
        :href="`/makers/${makerId}`"
        class="underline"
        @click.stop
      >
        {{ cartStore.makersNameHash[makerId] }}.
      </a>
    </p>
    <p
      v-else-if="nextSubsidizedShipping?.id"
      class="text-left"
    >
      Agrega {{ useFormatCurrency(missingAmountForSubsidizedShipping) }} y obten
      {{ useFormatCurrency(subsidizedShippingAmount(nextSubsidizedShipping)) }}
      de descuento en el despacho de
      <a
        :href="`/makers/${makerId}`"
        class="underline"
        @click.stop
      >
        {{ cartStore.makersNameHash[makerId] }}.
      </a>
    </p>
  </div>
</template>
<script setup lang="ts">
import { computed, onMounted, watch } from 'vue';
import { InformationCircleIcon } from '@heroicons/vue/24/outline';
import useCartStore from 'stores/cart';
import useSessionStore from 'stores/user-store';
import useMakerCodeDiscounts from './use/maker-code-discounts';
import useMakerFreeShippingHelper from './use/maker-free-shipping-helper';
import useFreeShippingsQuery from './queries/free-shippings-query';
import useFormatCurrency from './use/format-currency';
import useSubsidizedShippings from './use/subsidized-shippings';
import useMakerSubsidizedShippingsHelper from './use/maker-subsidized-shippings-helper';

const SUBSIDIZED_FACTOR = 2;

const props = defineProps<{
  makerId: number
}>();

const cartStore = useCartStore();
const sessionStore = useSessionStore();
const currentCommuneId = computed(() => sessionStore.currentCommuneId);
const currentRegionId = computed(() => sessionStore.currentRegionId);

const emit = defineEmits<{(e: 'show-bottom-messages', value: boolean): void,
  (e: 'update:modelValue', value: boolean): void, (e: 'maker-enabled', value: boolean): void}>();

const computedMakerId = computed(() => props.makerId);
const { productWholesalePriceWithDiscountCode } = useMakerCodeDiscounts(computedMakerId);

// free shipping
const freeShippingsQueryParams = computed(() => 'q[active_eq]=true');
const { orderedFreeShippings } = useFreeShippingsQuery(computedMakerId, freeShippingsQueryParams);
const { nextFreeShipping, missingAmountForFreeShipping } = useMakerFreeShippingHelper(
  orderedFreeShippings, productWholesalePriceWithDiscountCode, currentRegionId, currentCommuneId,
);

// subsidized shipping
const subsidizeShippingsQueryParams = computed(() => 'q[active_eq]=true');
const { orderedSubsidizedShippings } = useSubsidizedShippings(
  computedMakerId, subsidizeShippingsQueryParams, computed(() => true),
);
const { nextSubsidizedShipping, missingAmountForSubsidizedShipping } = useMakerSubsidizedShippingsHelper(
  orderedSubsidizedShippings, productWholesalePriceWithDiscountCode,
);

function subsidizedShippingAmount(subsidizedShipping: SubsidizedShipping | null | undefined) {
  if (!subsidizedShipping) return 0;
  if (subsidizedShipping.kind === 'with_lokal') {
    return subsidizedShipping.subsidizedAmountCents * SUBSIDIZED_FACTOR;
  }

  return subsidizedShipping.subsidizedAmountCents;
}

const productsInCart = computed(() => cartStore.itemsMakerIds.includes(computedMakerId.value));
const showMissingAmountForFreeShipping = computed(() =>
  !!nextFreeShipping.value?.id && missingAmountForFreeShipping.value > 0);
const showMissingAmountForSubsidizedShipping = computed(() =>
  missingAmountForSubsidizedShipping.value > 0 &&
  (!nextFreeShipping.value?.id || missingAmountForSubsidizedShipping.value < missingAmountForFreeShipping.value),
);

const showComponent = computed(() =>
  productsInCart.value &&
  (showMissingAmountForFreeShipping.value || showMissingAmountForSubsidizedShipping.value),
);

watch(showComponent, () => {
  emit('maker-enabled', showComponent.value);
});

onMounted(() => {
  emit('maker-enabled', showComponent.value);
});
</script>
