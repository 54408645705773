<template>
  <div class="flex flex-col items-center mb-10 w-full h-full text-gray-700 md:px-5 lg:px-10 xl:max-w-7xl">
    <div class="flex items-center px-2 mb-3 w-full md:px-0">
      <p class="flex flex-col self-start text-xl text-gray-700 md:text-2xl lg:text-3xl">
        <span>Programa de referidos</span>
      </p>
    </div>
    <div class="flex flex-col py-5 px-4 w-full bg-white rounded-md md:py-5 md:px-10">
      <p class="mb-3 text-base md:text-lg">
        Invita a tiendas y vende con 0% comisión
      </p>
      <div class="flex flex-col mb-2 w-full md:mb-5">
        <p class="mb-2 text-sm font-light md:text-base">
          Invita a tus clientes a registrarse con tu link de referidos y obten
          <span class="font-semibold">0% de comisión</span> en las compras que te realicen.
        </p>
        <maker-refer-link
          v-if="!discountCodeQueryIsLoading"
          :maker="maker"
          class="mb-1"
          :code="discountCode?.token"
        />
        <p class="mb-2 text-sm font-light md:text-base">
          *Asegurate cuando compartas el link, que la persona se cree la cuenta.
        </p>
      </div>
      <p class="mb-3 text-base md:text-lg">
        <span class="">¿Qué beneficios tiene para ti?</span> <br>
        <span class="font-light">Tendrás 0% de comisión en todos los pedidos realizados por tus referidos.</span>
      </p>
      <p class="mb-5 text-base md:text-lg">
        <span class="">
          ¿Qué pasa si uno de tus cliente ya tiene cuenta?
        </span> <br>
        <span class="font-light">
          No te preocupes. Con cualquier factura que le hayas emitido puedes demostrarnos que es tu
          cliente y te lo asociamos como referido.
        </span>
      </p>
      <p class="mb-3 text-base font-semibold md:text-lg">
        ¿Se te olvida enviar el link?
      </p>
      <p class="mb-2">
        A todos se nos olvida. Despreocúpate y usa este iFrame (embebido) en tu página web.
      </p>
      <div class="flex items-center mb-3 space-x-3 w-full">
        <p>
          Agregar contexto:
        </p>
        <lokal-switch v-model="withIframeContext" />
        <p v-if="withIframeContext">
          Sacar título:
        </p>
        <lokal-switch
          v-if="withIframeContext"
          v-model="withoutIframeTitle"
        />
      </div>
      <iframe
        :src="`${host}/embed/maker_referred?${iframeQueryParams}`"
        title="Esto es Lokal"
        width="100%"
        :height="iframHeight"
        class="self-center"
      />
      <copy-text
        :text="iframeText"
        class="mb-3 font-light md:mb-5"
      />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, computed } from 'vue';
import MakerReferLink from './maker-refer-link.vue';
import copyText from './shared/copy-text.vue';
import lokalSwitch from './shared/lokal-switch.vue';
import useDiscountCodeByCodeQuery from './queries/discount-code-by-code-query';

const props = defineProps<{
  maker: Maker
}>();

const protocol = computed(() => (process.env.APPLICATION_HOST?.includes('http') ? '' : 'https://'));
const host = ref(`${protocol.value}${process.env.APPLICATION_HOST}`);
const withIframeContext = ref(false);
const withoutIframeTitle = ref(false);
const iframHeight = computed(() => {
  if (withIframeContext.value) {
    if (withoutIframeTitle.value) return '560';

    return '700';
  }

  return '470';
});
const iframeQueryParams = computed(() =>
  `slug=${props.maker.slug}&with_context=${withIframeContext.value}&without_title=${withoutIframeTitle.value}`);
const iframeText = computed(() =>
  `<iframe src="${host.value}/embed/maker_referred?${iframeQueryParams.value}"
  title="Compra los productos de ${props.maker.name} en Lokal" width="100%"
  height="${iframHeight.value}" frameBorder="0"></iframe>`,
);

const code = computed(() => `RS${props.maker.id}`);
const computedMakerId = computed(() => props.maker.id);
const {
  discountCode, discountCodeQueryIsLoading,
} = useDiscountCodeByCodeQuery(computedMakerId, code, computed(() => true));
</script>
