<template>
  <DialogTitle class="mb-3 font-serif text-xl text-center text-gray-700 md:mb-5 md:text-2xl">
    <span v-if="subOrder.paymentConditions === 'regular'">
      ¿Quieres cambiar la condición de pago del pedido #{{ subOrder.id }}?
    </span>
    <span v-else>
      ¿Cuándo pagamos el pedido #{{ subOrder.id }}?
    </span>
  </DialogTitle>
  <DialogDescription
    as="div"
    class="mb-3 font-light md:mb-5"
  >
    <p class="mb-3 text-sm md:text-base">
      En Lokal puedes adelantar el pago de tus pedidos para el día hábil siguiente de ser despachado.
      Recuerda que en cualquier caso debes
      <strong>subir la factura</strong>
      para poder hacer efectivo el pago.
    </p>
    <p class="mb-0.5 text-sm font-normal md:text-base">
      Pago en {{ maker.maxPaymentDays }} días:
      <span class="font-light">sin costo</span>
    </p>
    <button
      class="flex items-center p-2 mb-3 space-x-2 w-full text-sm text-left rounded-md border md:space-x-3 md:text-base"
      :class="{ 'border border-lk-green': selectedOption === 'default',
                'border-gray-300': selectedOption !== 'default'
      }"
      @click="selectedOption = 'default'"
    >
      <div
        class="flex shrink-0 justify-center items-center w-4 h-4 rounded-full border-2 md:w-5 md:h-5"
        :class="{ 'border-lk-green': selectedOption === 'default',
                  'border-gray-300': selectedOption !== 'default' }"
      >
        <div
          class="shrink-0 w-1.5 h-1.5 rounded-full md:w-2.5 md:h-2.5"
          :class="{ 'bg-lk-green': selectedOption === 'default',
                    'bg-white': selectedOption !== 'default' }"
        />
      </div>
      <p class="flex items-center">
        <span>
          Por el total
          {{ useFormatCurrency(discountedAmountWithoutCommission) }}
          + IVA
        </span>
      </p>
    </button>
    <p class="mb-0.5 text-sm font-normal md:text-base">
      Pago al día hábil siguiente:
      <span class="font-light">
        costo
        {{ maker.advancePaymentFeeValue }}%
      </span>
    </p>
    <button
      class="flex items-center p-2 space-x-2 w-full text-sm text-left rounded-md border md:space-x-3 md:text-base"
      :class="{ 'border border-lk-green': selectedOption === 'advancePayment',
                'border-gray-300': selectedOption !== 'advancePayment'
      }"
      @click="selectedOption = 'advancePayment'"
    >
      <div
        class="flex shrink-0 justify-center items-center w-4 h-4 rounded-full border-2 md:w-5 md:h-5"
        :class="{ 'border-lk-green': selectedOption === 'advancePayment',
                  'border-gray-300': selectedOption !== 'advancePayment' }"
      >
        <div
          class="shrink-0 w-1.5 h-1.5 rounded-full md:w-2.5 md:h-2.5"
          :class="{ 'bg-lk-green': selectedOption === 'advancePayment',
                    'bg-white': selectedOption !== 'advancePayment' }"
        />
      </div>
      <p class="flex items-center">
        <span>
          Por un total de
          {{ useFormatCurrency(totalAdvanceAmount) }} + IVA
        </span>
      </p>
    </button>
    <p
      v-if="selectedOption === 'advancePayment' || true"
      class="mt-1 mb-2 text-xs font-light md:text-sm"
    >
      *Esta opción tiene un costo adicional de
      {{ useFormatCurrency(advancePaymentFeeAmount) }}
      ({{ maker.advancePaymentFeeValue }}%).
    </p>
  </DialogDescription>
  <lokal-loading
    v-if="isLoading || paymentConditionsMutationIsLoading"
    class="self-center"
    :loading="isLoading || paymentConditionsMutationIsLoading"
  />
  <template v-else>
    <button
      v-if="selectedOption === 'default'"
      class="self-center p-2 mb-1 w-full text-sm text-white bg-lk-green hover:bg-lk-green-deep rounded-md md:text-base"
      @click="paymentConditionsMutation.mutate('regular')"
    >
      {{ subOrder.paymentConditions == 'regular' ? 'Mantener' : 'Continuar' }}
    </button>
    <button
      v-else
      class="self-center p-2 mb-1 w-full text-sm text-white bg-lk-green hover:bg-lk-green-deep rounded-md md:text-base"
      @click="advancePaymentMutation.mutate()"
    >
      Adelantar pago
    </button>
    <button
      class="text-xs hover:underline md:text-sm"
      @click="emit('update:modelValue', false)"
    >
      Cancelar
    </button>
  </template>
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue';
import {
  DialogTitle,
  DialogDescription,
} from '@headlessui/vue';
import { round } from 'lodash';
import subOrdersApi from 'api/sub-orders';
import { useMutation } from 'vue-query';
import useMessageStore from 'stores/message-store';
import useFormatCurrency from './use/format-currency';

const PERCENTAGE = 100;

interface SubOrderAdvancePaymentDialogProps {
  modelValue: boolean
  maker: Maker
  subOrder: SubOrder
}
const props = withDefaults(defineProps<SubOrderAdvancePaymentDialogProps>(), {
  modelValue: false,
});

const messageStore = useMessageStore();

const emit = defineEmits<{(e: 'update:modelValue', value: boolean): void,
  (e: 'update:subOrder'): void
}>();

const selectedOption = ref('default');

const makerDiscountedAmount = computed(() =>
  props.subOrder.totalWholesaleAmountCents - props.subOrder.totalMakerDiscountAmountCents);
const makerDiscountedAmountWithVolumeDiscountAndMakerCodeDiscount = computed(() =>
  makerDiscountedAmount.value - props.subOrder.volumeDiscountAmountCents - props.subOrder.makerDiscountCodeAmountCents);
const commission = computed(() =>
  round(makerDiscountedAmountWithVolumeDiscountAndMakerCodeDiscount.value * props.subOrder.commission / PERCENTAGE));
const discountedAmountWithoutCommission = computed(() =>
  makerDiscountedAmountWithVolumeDiscountAndMakerCodeDiscount.value - commission.value);
const advancePaymentFeeValue = computed(() => props.maker.advancePaymentFeeValue / PERCENTAGE);
const advancePaymentFeeAmount = computed(() =>
  round(discountedAmountWithoutCommission.value * advancePaymentFeeValue.value));
const totalAdvanceAmount = computed(() =>
  discountedAmountWithoutCommission.value - advancePaymentFeeAmount.value);

const paymentConditionsMutation = useMutation(
  (paymentConditions: string) => subOrdersApi.update(props.subOrder.id, { paymentConditions }),
  {
    onSuccess: () => {
      emit('update:subOrder');
      messageStore.showMessage('Se ha registrado correctamente la condición de pago.', 'success');
      emit('update:modelValue', false);
    },
  },
);
const paymentConditionsMutationIsLoading = computed(() => paymentConditionsMutation.isLoading.value);

const advancePaymentMutation = useMutation(
  () => subOrdersApi.update(props.subOrder.id, {
    advancePaymentFeeValue: props.maker.advancePaymentFeeValue, paymentConditions: 'advance_payment',
  }),
  {
    onSuccess: () => {
      emit('update:subOrder');
      messageStore.showMessage('Se ha registrado el adelantamiento del pago exitosamente.', 'success');
      emit('update:modelValue', false);
    },
  },
);
const isLoading = computed(() => advancePaymentMutation.isLoading.value);
</script>
