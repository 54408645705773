<template>
  <div
    class="mb-3 md:mb-5"
    :class="{
      'border-red-500 rounded-md p-2 md:p-3 -m-2 md:-m-3 border': subOrder.modificationsStatus === 'in_progress'
    }"
  >
    <div class="flex flex-col">
      <p class="w-fit text-xs font-light text-left md:hidden md:text-sm">
        {{ t(`subOrder.status.${subOrder.status}`) }}
        <span v-if="subOrder.modificationsStatus === 'accepted'"> / este pedido tuvo modificaciones</span>
        <span v-else-if="subOrder.modificationsStatus === 'waiting_stock_reposition'">
          / espera reposición de stock
        </span>
      </p>
      <div
        class="flex items-center mb-1 w-full"
      >
        <button
          class="flex items-center mr-1 space-x-2"
          @click="isOpen = !isOpen"
        >
          <h2 class="text-lg text-left md:text-xl">
            {{ subOrder.makerName }}
          </h2>
          <ChevronDownIcon
            class="w-4 h-full"
            :class="{ 'rotate-180 transform duration-150 ease-linear': isOpen,
                      'duration-150 ease-linear transform': !isOpen}"
          />
        </button>
        <div
          class="hidden p-0.5 ml-2 w-fit text-xs text-left text-white uppercase rounded-full md:block md:py-1 md:px-2 md:text-sm"
          :class="{
            'bg-lk-light-gray': ['draft', 'pending'].includes(subOrder.status),
            'bg-yellow-500': subOrder.status === 'confirmed',
            'bg-lk-light-purple': ['delivery_in_progress', 'ready_for_pickup'].includes(subOrder.status),
            'bg-lk-green': subOrder.status === 'delivered',
          }"
        >
          {{ t(`subOrder.status.${camelCase(subOrder.status)}`) }}
        </div>
        <div
          v-if="['accepted', 'waiting_stock_reposition'].includes(subOrder.modificationsStatus)"
          class="hidden p-0.5 ml-2 w-fit text-xs text-left text-white uppercase rounded-full md:block md:py-1 md:px-2 md:text-sm"
          :class="{
            'bg-red-500': 'waiting_stock_reposition' !== subOrder.modificationsStatus,
            'bg-yellow-500': 'accepted' !== subOrder.modificationsStatus,
          }"
        >
          <span v-if="subOrder.modificationsStatus === 'accepted'">Este pedido tuvo modificaciones</span>
          <span v-else>Espera reposición de stock</span>
        </div>
        <button
          v-if="subOrder.modificationsStatus === 'in_progress'"
          class="hidden p-0.5 ml-2 w-fit text-xs text-left text-lk-green hover:text-white uppercase hover:bg-lk-green rounded-md border border-lk-green md:block md:py-1 md:px-2 md:text-sm"
          @click="showSubOrderModificationsShopDialog = !showSubOrderModificationsShopDialog"
        >
          Ver modificaciones
        </button>
        <LokalDropdown
          class="shrink-0 ml-auto"
          :labels="['Ver Seguimiento', 'Guía de despacho', 'Material de apoyo']"
          :hrefs="[subOrder.trackingUrl, subOrder.shipmentInvoiceUrl, subOrder.supportMaterialLink]"
          main-label="Ver más"
        />
      </div>
      <button
        v-if="subOrder.modificationsStatus === 'in_progress' && isMobile"
        id="bla"
        class="p-0.5 w-fit text-xs text-left text-lk-green hover:text-white uppercase hover:bg-lk-green rounded-md border border-lk-green md:block md:py-1 md:px-2 md:text-sm"
        @click="showSubOrderModificationsShopDialog = !showSubOrderModificationsShopDialog"
      >
        Ver modificaciones
      </button>
    </div>
    <p class="mb-1 text-sm font-light text-left md:mb-2">
      <span v-if="subOrder.shipmentMethod === 'shop_pickup'">
        {{ t(`subOrder.shipmentMethod.${camelCase(subOrder.shipmentMethod)}`) }}. <br>
      </span>
      <template v-if="subOrder.shipmentDetailId">
        <span>
          Este despacho es realizado por Lokal. <br>
        </span>
        <span v-if="subOrder.shipmentDetailId && subOrder.deadlineAt">
          Fecha estimada de entrega: {{ formattedDeadlineAt }}.
          <br v-if="isMobile">
        </span>
        <a
          v-if="subOrder.trackingUrl"
          :href="subOrder.trackingUrl"
          class="underline"
          target="blank"
          rel="nofollow"
        >
          Ver seguimiento.
        </a>
      </template>
      <template v-else-if="subOrder.makerShipmentDetailId">
        <span>
          Este despacho es realizado por la marca. <br>
        </span>
        <span v-if="subOrder.initialDeliveryAt && subOrder.deadlineAt">
          Entre {{ useTimeToDate(subOrder.initialDeliveryAt) }} y
          {{ formattedDeadlineAt }}.
        </span>
        <a
          v-else-if="subOrder.trackingUrl"
          :href="subOrder.trackingUrl"
          class="hover:underline"
          target="blank"
          rel="nofollow"
        >
          Ver seguimiento
        </a>
      </template>
      <template v-else />
    </p>
    <div
      :class="{ 'border-gray-300': !isOpen, 'border-gray-700': isOpen }"
      class="col-span-6 mb-3 w-full border-b-2 md:col-span-7"
    />
    <div
      v-if="isOpen"
      class="mb-5 text-sm text-gray-700 md:grid md:grid-cols-7 md:gap-4 md:place-items-center md:text-base"
    >
      <template v-if="!isMobile">
        <p class="text-center">
          Imagen referencial
        </p>
        <p class=" place-self-center md:col-span-2">
          Nombre producto
        </p>
        <p class="text-center">
          Precio público <br> (c/IVA)
        </p>
        <p class="text-center">
          Precio mayorista <br> neto
        </p>
        <p class="text-center">
          Cantidad
        </p>
        <p class="text-center">
          Suma
        </p>
        <div class="col-span-6 w-full border-b border-gray-700 md:col-span-7" />
      </template>
      <template
        v-for="subOrderProduct in subOrderProducts"
        :key="subOrderProduct.id"
      >
        <OrderShowSubOrderProductCard
          :sub-order-product="subOrderProduct"
        />
      </template>
      <div class="flex flex-col col-span-5 ml-auto md:col-span-7">
        <div
          v-if="['free_shipping_with_lokal', 'free_shipping_by_maker'].includes(subOrder.shippingConditions) &&
            subOrder.shippingQuotation !== 'confirm_after'"
          class="flex justify-end w-full"
        >
          <p class="p-1 text-sm text-white bg-lk-light-purple rounded-md">
            Despacho gratis
          </p>
        </div>
        <p
          v-if="subOrder.volumeDiscountAmountCents > 0"
          class="flex space-x-3 md:justify-end"
        >
          <span>DESCT X VOLUMEN ({{ subOrder.volumeDiscountValue }}%):</span>
          <span>{{ useFormatCurrency(subOrder.volumeDiscountAmountCents) }}</span>
        </p>
        <p
          v-if="subOrder.totalDiscountCodeAmountCents > 0"
          class="flex space-x-3 md:justify-end"
        >
          <span>CÓDIGO DESCT:</span>
          <span>{{ useFormatCurrency(subOrder.totalDiscountCodeAmountCents) }}</span>
        </p>
        <p
          class="flex space-x-3 md:justify-end"
        >
          <span>SUB TOTAL:</span>
          <span
            v-if="discountActive"
            :class="{ 'line-through': discountActive }"
          >
            {{ useFormatCurrency(subOrder.totalWholesaleAmountCents) }}
          </span>
          <span>
            {{ useFormatCurrency(totalProductsPriceCents) }}
          </span>
        </p>
        <p
          v-if="subOrder.shipmentMethod !== 'shop_pickup'"
          class="flex space-x-3 md:justify-end"
        >
          <span>DESPACHO:</span>
          <span v-if="subOrder.shippingQuotation === 'confirm_after'">Pendiente</span>
          <span v-else-if="!!subOrder.shopPickupAddressId">
            $0
          </span>
          <template v-else>
            <span
              v-if="differentShippingPrices"
              :class="{ 'line-through': differentShippingPrices }"
            >
              {{ useFormatCurrency(subOrder.originalShippingCostAmountCents) }}
            </span>
            <span>
              {{ useFormatCurrency(subOrder.shippingCostAmountCents) }}
            </span>
          </template>
        </p>
        <p
          v-if="!!subOrder.shopPickupAddressId"
          class="flex space-x-3 md:justify-end"
        >
          <span>Retiro en bodega:</span>
          <span>
            {{ shopPickupAddress?.address }}, {{ shopPickupAddress?.communeName }}
          </span>
        </p>
      </div>
    </div>
    <sub-order-modifications-shop-dialog
      v-if="subOrder.modificationsStatus === 'in_progress' && showSubOrderModificationsShopDialog"
      v-model="showSubOrderModificationsShopDialog"
      :sub-order="subOrder"
      @update:sub-order="() => emit('reload:sub-orders')"
    />
  </div>
</template>
<script setup lang="ts">
import { inject, ref, computed } from 'vue';
import { ChevronDownIcon } from '@heroicons/vue/24/outline';
import { camelCase } from 'lodash';
import { useI18n } from 'vue-i18n';
import useFormatCurrency from './use/format-currency';
import OrderShowSubOrderProductCard from './order-show-sub-order-product-card.vue';
import LokalDropdown from './shared/lokal-dropdown.vue';
import useTimeToDate from './use/time-to-date';
import useAddressQuery from './queries/address-query';
import SubOrderModificationsShopDialog from './sub-order-modifications-shop-dialog.vue';

const isMobile = inject('isMobile');

interface OrderShowSubOrderProps {
  subOrder: SubOrder,
  subOrderProducts: SubOrderProduct[],
  isFirst: boolean
}

const props = withDefaults(defineProps<OrderShowSubOrderProps>(), {
  isFirst: false,
});

const { t } = useI18n();

const showSubOrderModificationsShopDialog = ref(props.subOrder.modificationsStatus === 'in_progress');

const emit = defineEmits<{(e: 'reload:sub-orders'): void}>();

const totalProductsPriceCents = computed(() =>
  props.subOrder.totalWholesaleAmountCents - props.subOrder.totalDiscountAmountCents -
  props.subOrder.volumeDiscountAmountCents - props.subOrder.totalDiscountCodeAmountCents);
const discountActive = computed(() => props.subOrder.totalDiscountAmountCents > 0 ||
  props.subOrder.volumeDiscountAmountCents > 0);

const isOpen = ref(false || props.isFirst);

const formattedDeadlineAt = computed(() => {
  if (props.subOrder.deadlineAt) {
    return new Date(props.subOrder.deadlineAt.replace(/-/g, '/')).toLocaleDateString();
  }

  return '';
});

const differentShippingPrices = computed(() =>
  props.subOrder.shippingCostAmountCents !== props.subOrder.originalShippingCostAmountCents);

const { address: shopPickupAddress } = useAddressQuery(computed(() => props.subOrder.shopPickupAddressId));
</script>
