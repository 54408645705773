<template>
  <Dialog
    :open="modelValue"
    as="div"
    class="relative z-50"
    @click="emit('update:modelValue', false)"
  >
    <div
      class="fixed inset-0 bg-black/30"
      aria-hidden="true"
    />
    <div class="flex fixed inset-0 justify-center items-center p-4">
      <DialogPanel class="flex overflow-y-auto flex-col p-2 w-full max-w-md bg-gray-50 rounded md:p-3">
        <button
          class="ml-auto focus:outline-none"
          @click="emit('update:modelValue', false)"
        >
          <XMarkIcon class="w-6 h-6 fill-gray-700" />
        </button>
        <address-new
          :addressable-id="addressableId"
          :addressable-type="addressableType"
          :initial-region-id="initialRegionId"
          :initial-commune-id="initialCommuneId"
          @address-created="closeDialogAndUpdateAddresses"
        />
      </DialogPanel>
    </div>
  </Dialog>
</template>
<script setup lang="ts">
import { XMarkIcon } from '@heroicons/vue/24/outline';
import { computed } from 'vue';
import {
  Dialog,
  DialogPanel,
} from '@headlessui/vue';
import AddressNew from './address-new.vue';
import useAddressesQuery from './queries/addresses-query';

interface AddressNewDialogProps {
  modelValue: boolean,
  addressableId: number,
  addressableType: 'Shop' | 'Maker',
  initialRegionId?: number,
  initialCommuneId?: number,
}

const props = withDefaults(defineProps<AddressNewDialogProps>(), {
  modelValue: false,
  initialRegionId: undefined,
  initialCommuneId: undefined,
});

const emit = defineEmits<{(e: 'update:modelValue', value: boolean): void,
  (e: 'address-created', value: number): void}>();

const computedAddressableId = computed(() => props.addressableId);
const computedAddressableType = computed(() => props.addressableType);
const { addressesQuery } = useAddressesQuery(computedAddressableId, computedAddressableType);

async function closeDialogAndUpdateAddresses(addressId: number) {
  await addressesQuery.refetch.value();
  emit('address-created', addressId);
  emit('update:modelValue', false);
}
</script>
