<template>
  <div class="flex flex-col w-full">
    <lokal-loading
      v-if="isLoading"
      :loading="isLoading"
    />
    <div
      v-else
      class="flex flex-col w-full"
    >
      <div class="flex justify-between items-center mb-3 w-full">
        <p class="text-base md:text-lg">
          Historial
        </p>
        <button
          class="flex justify-center items-center py-1 px-2 text-sm text-lk-green hover:text-white hover:bg-lk-green rounded-md border border-lk-green md:text-base"
          @click="showMakerNewFollowUpDialog = !showMakerNewFollowUpDialog"
        >
          <PlusIcon class="mr-2 w-4 h-4" />
          <span class="">Agregar seguimiento</span>
        </button>
      </div>
      <template
        v-for="container in sortedContainers"
        :key="container.id"
      >
        <maker-follow-up-shop-card
          v-if="container.type === 'followUp' && container?.followUp"
          :follow-up="container.followUp"
          :show-followable-name="false"
          class="mb-2"
        />
        <maker-shop-show-sub-order
          v-if="container.type === 'subOrder' && container?.subOrder"
          :sub-order="container.subOrder"
        />
      </template>
      <p
        v-if="sortedContainers.length === 0"
        class="mb-3 text-sm font-light md:text-base"
      >
        Aún no tienes seguimientos o pedidos de esta tienda.
      </p>
    </div>
    <maker-new-follow-up-dialog
      v-model="showMakerNewFollowUpDialog"
      :maker="maker"
      follow-up-type="Shop"
      :follow-up-id="shop.id"
      :followable-name="shop.name"
      @reload-follow-ups="async () => await followUpsQuery.refetch.value()"
    />
  </div>
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue';
import { PlusIcon } from '@heroicons/vue/24/outline';
import { orderBy } from 'lodash';
import { useQuery } from 'vue-query';
import subOrdersApi from 'api/sub-orders';
import followUpsApi from 'api/follow-ups';
import MakerFollowUpShopCard from './maker-follow-up-shop-card.vue';
import MakerShopShowSubOrder from './maker-shop-show-sub-order.vue';
import MakerNewFollowUpDialog from './maker-new-follow-up-dialog.vue';

const SUB_ORDER_PAGINATION_SIZE = 50;
const CONTAINERS_LIMIT = 3;

interface MakerShopProps {
  maker: Maker
  shop: Shop
  withContainerLimit: boolean
}

const props = withDefaults(defineProps<MakerShopProps>(), {
  withContainerLimit: false,
});
const showMakerNewFollowUpDialog = ref(false);

const currentSubOrderPage = ref(1);
const queryParams = computed(() => `q[order_shop_id_eq]=${props.shop.id}`);
const subOrdersQuery = useQuery(['subOrders', props.maker.id, queryParams, currentSubOrderPage],
  () => subOrdersApi.index(props.maker.id, currentSubOrderPage.value, queryParams.value, SUB_ORDER_PAGINATION_SIZE),
  { keepPreviousData: true },
);
const isLoadingSubOrderQuery = computed(() => subOrdersQuery.isLoading.value || subOrdersQuery.isPreviousData.value);
const subOrders = computed(() => subOrdersQuery.data.value?.data.subOrders as SubOrder[]);

const followUpsQuery = useQuery(['followUps', props.shop, props.maker],
  () => followUpsApi.indexByShop(props.shop.id, props.maker.id));
const isLoadingFollowUps = computed(() => followUpsQuery.isLoading.value);
const followUps = computed(() => followUpsQuery.data.value?.data.followUps as FollowUp[]);
const isLoading = computed(() => isLoadingSubOrderQuery.value || isLoadingFollowUps.value);

interface Container {
  id: string
  followUp?: FollowUp
  subOrder?: SubOrder
  type: 'followUp' | 'subOrder'
  createdAt: Date
}

const followUpContainers = computed(() => followUps.value?.map((followUp) =>
    ({ followUp, type: 'followUp', createdAt: followUp.createdAt, id: `follow-up-${followUp.id}` }) as Container));

const sortedContainers = computed(() => {
  const subOrderContainers = subOrders.value?.map((subOrder) =>
    ({ subOrder, type: 'subOrder', createdAt: subOrder.pendingAt, id: `sub-order-${subOrder.id}` }) as Container);
  const containers = [...followUpContainers.value, ...subOrderContainers];
  if (props.withContainerLimit) {
    return orderBy(containers, 'createdAt', 'desc').slice(0, CONTAINERS_LIMIT) as Container[];
  }

  return orderBy(containers, 'createdAt', 'desc') as Container[];
});
</script>
