<template>
  <div class="flex flex-col items-center py-6 w-full text-gray-700 md:py-10">
    <h2 class="mb-5 font-serif text-2xl md:text-3xl">
      Cambiar contraseña
    </h2>
    <template
      v-for="errorMessage in errorMessages"
      :key="errorMessage"
    >
      <lokal-banner
        v-if="errorMessage"
        v-model="showBanner"
        :message="errorMessage"
        :color-key="('alert' as ColorKeyType)"
      />
    </template>
    <form
      class="flex flex-col px-4 mb-5 space-y-2 w-full md:max-w-lg"
      action="/users/password"
      method="post"
      accept-charset="UTF-8"
      @submit.prevent="submitForm"
    >
      <input
        type="hidden"
        name="_method"
        value="put"
      >
      <input
        type="hidden"
        name="user[reset_password_token]"
        :value="token"
      >
      <Field
        v-model="newForm.newPassword"
        name="user[password]"
        label="new password"
        type="password"
        class="p-2 w-full rounded-md border border-slate-200"
        placeholder="Nueva clave"
        :rules="{ required: true, min: 6 }"
      />
      <ErrorMessage
        name="user[password]"
        class="text-sm text-red-500"
      />
      <Field
        v-model="newForm.newPasswordConfirmation"
        name="user[password_confirmation]"
        label="new password confirmation"
        type="password"
        class="p-2 w-full rounded-md border border-slate-200"
        placeholder="Confirma tu nueva clave"
        :rules="{ required: true, confirmed: '@user[password]' }"
      />
      <ErrorMessage
        name="user[password_confirmation]"
        class="text-sm text-red-500"
      />
      <lokal-button
        action-name="Cambiar clave"
        size="normal"
        type="submit"
      />
      <csrf-token />
    </form>
  </div>
</template>
<script lang="ts" setup>
import { ref, computed } from 'vue';
import { useForm, Field, ErrorMessage } from 'vee-validate';
import CsrfToken from './shared/csrf-input.vue';
import LokalBanner, { type ColorKeyType } from './shared/lokal-banner.vue';

const showBanner = ref(true);

const newForm = ref({ newPassword: '', newPasswordConfirmation: '', oldPassword: '' });

const token = computed(() => window.location.search.split('=')[1]);

const { submitForm } = useForm();

interface PasswordNewProps {
  errorMessages?: string[]
}

withDefaults(defineProps<PasswordNewProps>(), {
  errorMessages: () => [''],
});
</script>
