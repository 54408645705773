import { computed } from 'vue';
import { useQuery } from 'vue-query';
import labelsApi from 'api/labels';

export default function useLabelsQuery(queryParams = computed(() => ''), enabled = computed(() => false)) {
  const labelsQuery = useQuery(
    ['labels', queryParams],
    () => labelsApi.index(queryParams.value),
    { enabled },
  );
  const labels = computed(() => labelsQuery.data.value?.data.labels as Label[]);

  return { labels, labelsQuery };
}
