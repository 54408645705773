<template>
  <div
    v-if="incidenceReportEnabled"
    class="flex flex-col w-full"
  >
    <lokal-loading
      v-if="incidenceReportsQueryIsLoading"
      :loading="incidenceReportsQueryIsLoading"
    />
    <sub-order-show-incidence-report-list-card
      v-for="incidenceReport in incidenceReports"
      v-else
      :key="`incidence-report-${incidenceReport.id}`"
      :incidence-report="incidenceReport"
    />
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import { useQuery } from 'vue-query';
import incidenceReportsApi from 'api/incidence-reports';
import subOrderShowIncidenceReportListCard from './sub-order-show-incidence-report-list-card.vue';
import LokalLoading from './shared/lokal-loading.vue';

const props = defineProps<{
  subOrder: SubOrder
}>();

const incidenceReportEnabled = computed(() =>
  ['incidence_in_progress', 'incidence_resolved'].includes(props.subOrder.incidenceStatus));

const incidenceReportsQuery = useQuery(
  ['incidence-reports', props.subOrder.id],
  () => incidenceReportsApi.index(props.subOrder.id),
);
const incidenceReportsQueryIsLoading = computed(() => incidenceReportsQuery.isLoading.value);
const incidenceReports = computed(() => incidenceReportsQuery.data.value?.data?.incidenceReports as IncidenceReport);
</script>
