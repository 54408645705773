<template>
  <div
    class="flex flex-col col-span-3 place-self-start mr-auto mb-1 w-full font-medium text-gray-700 md:col-span-3 md:mb-0"
  >
    <div class="flex items-center mb-1">
      <div
        v-if="discountCode?.id"
        class="flex items-center p-1 text-xs text-white bg-lk-light-purple rounded-full md:text-sm"
      >
        <span class="mr-1">{{ discountCode.code }} {{ discountCode?.totalDiscount }}%</span>
        <button
          type="button"
          @click="removeDiscountCode"
        >
          <XMarkIcon class="shrink-0 w-4 h-4" />
        </button>
      </div>
    </div>
    <div class="flex items-start">
      <div class="flex flex-col mr-2 w-full">
        <form-input
          v-model="discountCodeRef"
          :rules="{ required: false, isValidDiscountCode: maker.id }"
          :name="`discount-code-${maker.name}`"
          :label-name="`Código de descuento de ${maker.name}`"
          :placeholder="`Ingresa descuento para ${maker.name}`"
          class="w-full"
          :disabled="!!(discountCode?.id && discountCode?.active)"
        />
      </div>
      <lokal-loading
        v-if="makerDiscountCodeValidationIsLoading"
        v-model="makerDiscountCodeValidationIsLoading"
        size="small"
        class="shrink-0"
      />
      <button
        v-else
        class="shrink-0 p-2 text-xs rounded-md border border-lk-light-gray focus:border-lk-green md:text-sm"
        :class="{ 'opacity-50': !!(discountCode?.id && discountCode?.active) }"
        :disabled="!!(discountCode?.id && discountCode?.active)"
        type="button"
        @click="validateDiscountCode(discountCodeRef)"
      >
        Aplicar
      </button>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { XMarkIcon } from '@heroicons/vue/24/outline';
import useCartStore from 'stores/cart';
import useMessageStore from 'stores/message-store';
import discountCodesApi from 'api/discount-codes';
import useMakerCodeDiscounts from './use/maker-code-discounts';
import formInput from './shared/form-input.vue';

interface OrderProductsByMakerDiscountCodeProps {
  maker: Maker
}

const props = withDefaults(defineProps<OrderProductsByMakerDiscountCodeProps>(), {
});

const cartStore = useCartStore();
const messageStore = useMessageStore();

const makerId = computed(() => props.maker.id);
const { codeDiscountAmount } = useMakerCodeDiscounts(makerId);

const makerDiscountCodeValidationIsLoading = ref(false);
const discountCodeRef = ref(cartStore.discountCodes[makerId.value]?.code || '');
const discountCode = computed(() => cartStore.discountCodes[makerId.value]);
async function validateDiscountCode(code: string) {
  try {
    makerDiscountCodeValidationIsLoading.value = true;
    const response = await discountCodesApi.byCodeValid(props.maker.id, code);
    if (response.data && !!response.data?.discountCode?.active) {
      cartStore.discountCodes[makerId.value] = response.data.discountCode as DiscountCode;
    }
  } finally {
    makerDiscountCodeValidationIsLoading.value = false;
  }
}

watch(codeDiscountAmount, () => {
  cartStore.discountCodeAmounts[makerId.value] = codeDiscountAmount.value;
});

watch(discountCode, () => {
  if (discountCode.value?.id) {
    messageStore.showMessage(`Código de descuento de ${props.maker.name} aplicado con éxito`, 'success');
  }
});

function removeDiscountCode() {
  delete cartStore.discountCodes[makerId.value];
  delete cartStore.discountCodeAmounts[makerId.value];
}
onMounted(() => {
  cartStore.discountCodeAmounts[makerId.value] = codeDiscountAmount.value;
});
</script>
