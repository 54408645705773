<template>
  <lokal-loading
    v-if="deleteMutationIsLoading"
    :loading="deleteMutationIsLoading"
    class="self-center"
  />
  <div
    v-else
    class="flex flex-col p-3 mb-2 text-gray-700 rounded-md border border-lk-light-gray md:flex-row md:items-center md:p-4"
  >
    <div class="flex items-center mb-2 md:mb-0">
      <div class="flex justify-center items-center p-1 mr-3 bg-lk-light-purple rounded-full">
        <TagIcon class="w-5 h-5 text-white md:w-6 md:h-6" />
      </div>
      <p class="text-sm md:text-base">
        {{ round(volumeDiscount.discount) }}% de dscto adicional en órdenes sobre
        {{ useFormatCurrency(volumeDiscount.minimumAmountCents) }} (SIN IVA)
      </p>
    </div>
    <div class="flex items-center mb-2 md:mb-0 md:ml-auto">
      <button
        class="p-1 mr-2 text-xs text-gray-500 hover:text-lk-green rounded-md border border-gray-500 md:p-1.5 md:text-sm md:border-0"
        @click="showVolumeDiscountNewDialog = !showVolumeDiscountNewDialog"
      >
        editar
      </button>
      <lokal-switch v-model="active" />
    </div>
    <makers-volume-discount-new-dialog
      v-if="showVolumeDiscountNewDialog"
      v-model="showVolumeDiscountNewDialog"
      :volume-discount="volumeDiscount"
      @reload-volume-discounts="() => emit('reload-volume-discounts')"
    />
    <lokal-confirmation-dialog
      v-model="showDeleteConfirmationDialog"
      :title="`¿Estás seguro de eliminar el descuento de ${volumeDiscount.discount}%?`"
      confirm-action-text="Eliminar"
      reject-action-text="Cancelar"
      event-name="delete-volume-discount"
      :positive-action="false"
      @delete-volume-discount="() => deleteMutation.mutate()"
    />
  </div>
</template>
<script lang="ts" setup>
import { ref, computed, watch } from 'vue';
import { round } from 'lodash';
import { TagIcon } from '@heroicons/vue/24/outline';
import { useMutation } from 'vue-query';
import volumeDiscountsApi from 'api/volume-discounts';
import useMessageStore from 'stores/message-store';
import useFormatCurrency from './use/format-currency';
import makersVolumeDiscountNewDialog from './makers-volume-discount-new-dialog.vue';
import LokalConfirmationDialog from './shared/lokal-confirmation-dialog.vue';
import lokalSwitch from './shared/lokal-switch.vue';

const props = defineProps<{
  volumeDiscount: VolumeDiscount
}>();

const messageStore = useMessageStore();
const showVolumeDiscountNewDialog = ref(false);
const showDeleteConfirmationDialog = ref(false);
const active = ref(props.volumeDiscount.active);

const emit = defineEmits<{(e: 'reload-volume-discounts'): void}>();
const deleteMutation = useMutation(() => volumeDiscountsApi.delete(props.volumeDiscount.id), {
  onSuccess: () => emit('reload-volume-discounts'),
});
const deleteMutationIsLoading = computed(() => deleteMutation.isLoading.value);

const updateMutation = useMutation(() => volumeDiscountsApi.update(props.volumeDiscount.id, { active: active.value }), {
  onSuccess: () => {
    messageStore.kind = 'success';
    const activeMessage = active.value ? 'activado' : 'desactivado';
    messageStore.message = `Descuento de ${props.volumeDiscount.discount}% ${activeMessage}`;
  },
});

watch(active, (newValue, oldValue) => {
  if (oldValue !== undefined && newValue !== oldValue) {
    updateMutation.mutate();
  }
});
</script>
