import { csrfToken } from '@rails/ujs';
import { ref } from 'vue';
import api from './index';

const token = ref(csrfToken());
let headers = {};
if (token.value) {
  headers = { 'X-CSRF-Token': token.value };
}

export default {
  index(queryParams: string) {
    const path = `/api/internal/follow_ups?${queryParams}`;

    return api({
      method: 'get',
      url: path,
      headers,
    });
  },
  indexByShop(shopId: number, makerId: number) {
    const path = `/api/internal/follow_ups/by_shop?shop_id=${shopId}&maker_id=${makerId}`;

    return api({
      method: 'get',
      url: path,
      headers,
    });
  },
  create(followUp: Partial<FollowUp>) {
    const path = '/api/internal/follow_ups';

    return api({
      method: 'post',
      url: path,
      data: { followUp },
      headers,
    });
  },
};

