<template>
  <div class="flex flex-col w-full text-sm text-gray-700 md:text-base">
    <button
      class="grid grid-cols-1 py-3 px-5 w-full hover:bg-gray-50 sm:grid-cols-2 sm:gap-y-1"
      :class="{ 'bg-gray-50': showHistory }"
      @click="showHistory = !showHistory"
    >
      <div class="flex items-center select-text">
        <inline-svg-wrapper
          name="store"
          class="shrink-0 mr-1 w-4 h-4"
        />
        <a
          class="text-base capitalize md:text-lg"
          :class="{ 'mr-2': referred }"
          :href="url"
          @click.stop
        >
          <span :class="{ 'hover:underline': !!shopName }">{{ shopName ? shopName : 'Sin nombre' }}</span>
          <span
            v-if="referred"
            class="shrink-0 ml-1 text-sm text-lk-green uppercase md:text-base"
          >
            Referido
          </span>
        </a>
      </div>
      <p
        v-if="createdAt"
        class="flex items-center space-x-1 font-light select-text"
      >
        <CalendarIcon class="shrink-0 w-4 h-4 " />
        <span class="mr-1">Creado en</span>
        <span>{{ useTimeToDate(createdAt) }}</span>
      </p>
      <p
        v-else-if="lastSubOrderAt"
        class="flex items-center font-light select-text"
      >
        <CalendarIcon class="shrink-0 mr-1 w-4 h-4 " />
        <span class="mr-1">Última compra</span>
        <span>{{ useTimeToDate(lastSubOrderAt) }}</span>
      </p>
      <component
        :is="referredType === 'User' ? 'a' : 'div'"
        class="flex row-start-2 items-center font-light select-text"
        :href="url"
        :class="{ 'hover:underline': referredType === 'User' }"
      >
        <UserCircleIcon class="shrink-0 mr-1 w-4 h-4 " />
        <span>{{ contactName }}</span>
      </component>
      <a
        class="flex items-center font-light select-text"
        :class="{ 'hover:underline': !!contactNumber }"
        :href="`https://wa.me/${contactNumber}`"
        target="_blank"
        noopener
        @click.stop
      >
        <inline-svg-wrapper
          name="whatsapp-logo"
          class="shrink-0 mr-1 w-4 h-4 fill-gray-700"
        />
        <span>{{ contactNumber }}</span>
      </a>
      <p class="flex items-center font-light select-text">
        <EnvelopeIcon class="shrink-0 mr-1 w-4 h-4 " />
        <span class="truncate select-text">{{ contactEmail }}</span>
      </p>
      <p
        v-if="shopKind"
        class="flex items-center font-light select-text"
      >
        <span>{{ $t(`shop.kind.${camelCase(shopKind)}`) }}</span>
        <span v-if="otherKind">: {{ otherKind }}</span>
      </p>
      <div
        v-if="!isMobile"
        class="col-start-2 row-span-2 row-start-3"
      >
        <div class="flex items-center font-light hover:underline">
          <span>Ver detalles</span>
          <ChevronDownIcon
            class="shrink-0 ml-1 w-4 h-4"
            :class="{ 'rotate-180': showHistory }"
          />
        </div>
      </div>
      <div
        v-if="isMobile"
        class=""
      >
        <button
          class="flex items-center p-1 text-lk-green hover:text-white hover:bg-lk-green rounded-md border border-lk-green"
          @click.stop.prevent="emit('open-follow-up-dialog')"
        >
          <PlusIcon class="shrink-0 mr-2 w-3 h-3" />
          <span>Agregar seguimiento</span>
        </button>
      </div>
    </button>
    <template v-if="showShopHistory">
      <lokal-loading
        v-if="shopQuery.isLoading.value"
        :loading="shopQuery.isLoading.value"
      />
      <maker-shop-history
        v-else
        class="px-5"
        :maker="maker"
        :shop="shop"
        :as-table="false"
      />
    </template>
    <maker-referred-user-history
      v-else-if="showUserHistory"
      class="px-5"
      :user-id="referredId"
      :maker="maker"
      :user-name="contactName"
    />
  </div>
</template>
<script lang="ts" setup>
import { inject, ref, computed } from 'vue';
import { EnvelopeIcon, UserCircleIcon, CalendarIcon, PlusIcon, ChevronDownIcon } from '@heroicons/vue/24/outline';
import { camelCase } from 'lodash';
import useTimeToDate from './use/time-to-date';
import makerShopHistory from './maker-shop-history.vue';
import useShopQuery from './queries/shop-query';
import MakerReferredUserHistory from './maker-referred-user-history.vue';
import inlineSvgWrapper from './inline-svg-wrapper.vue';

interface MakerContactListCardProps {
  maker: Maker
  referredType: string
  referredId: number
  shopName: string
  shopKind?: string
  otherKind?: string
  createdAt?: Date
  contactName: string
  contactNumber: string
  contactEmail: string
  referred: boolean
  url: string
  lastSubOrderAt?: Date
  showHasBought?: boolean
  hasBought?: boolean
}

const props = withDefaults(defineProps<MakerContactListCardProps>(), {
  shopKind: undefined,
  otherKind: undefined,
  createdAt: undefined,
  lastSubOrderAt: undefined,
  showHasBought: false,
  hasBought: false,
});

const isMobile = inject('isMobile');

const emit = defineEmits<{(e: 'open-follow-up-dialog'): void}>();
const showHistory = ref(false);
const showShopHistory = computed(() => showHistory.value && props.referredType === 'Shop');
const showUserHistory = computed(() => showHistory.value && props.referredType === 'User');
const { shop, shopQuery } = useShopQuery(computed(() => props.referredId), computed(() => showShopHistory.value));
</script>
