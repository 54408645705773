<template>
  <div
    class="flex flex-col w-full max-w-xl"
    :class="{ 'p-5': !inDialog }"
  >
    <template v-if="!inDialog">
      <p class="mb-2 font-serif text-2xl md:text-3xl">
        ¿Te gustaría vender en Lokal?
      </p>
      <p class="mb-3 text-sm md:text-base">
        Te estamos esperando, completa el siguiente formulario y nos pondremos en contacto contigo.
      </p>
    </template>
    <lokal-banner
      v-if="showBanner"
      v-model="showBanner"
      :message="errorMessage"
      :color-key="('alert' as ColorKeyType)"
    />
    <form
      class="flex flex-col"
      @submit.prevent="onSubmit"
    >
      <form-input
        v-model="form.firstName"
        class="mb-1"
        placeholder="Nombre"
        type="text"
        name="firstName"
        label-name="Nombre"
        :rules="{ required: true }"
      />
      <form-input
        v-model="form.lastName"
        class="mb-1"
        placeholder="Apellido"
        type="text"
        name="lastName"
        label-name="Apellido"
        :rules="{ required: true }"
      />
      <form-input
        v-model="form.email"
        class="mb-1"
        placeholder="Email"
        type="text"
        name="email"
        :rules="{ required: true, email: true }"
      />
      <form-input
        v-model="form.makerName"
        class="mb-1"
        placeholder="Nombre de la marca"
        type="text"
        name="makerName"
        label-name="Nombre de marca"
        :rules="{ required: true }"
      />
      <form-input
        v-model="form.link"
        class="mb-1"
        placeholder="Link de página web o instagram"
        type="text"
        name="link"
        :rules="{ required: true }"
      />
      <form-input
        v-model="form.comment"
        class="mb-3"
        placeholder="Déjanos cualquier comentario adicional"
        type="textarea"
        name="comment"
        :rules="{ required: false }"
      />
      <form-input
        v-model="potentialMakerContactStore.referenceSlug"
        type="text"
        hidden
        name="referenceSlug"
        :rules="{ required: false }"
      />
      <lokal-loading
        v-if="isLoading"
        :loading="isLoading"
        class="self-center"
      />
      <button
        v-else
        class="self-center p-2 w-full text-sm text-white bg-lk-green rounded-md md:text-base"
      >
        Enviar
      </button>
    </form>
  </div>
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useForm } from 'vee-validate';
import { useMutation } from 'vue-query';
import potentialMakerContactApi from 'api/potential-maker-contacts';
import usePotentialMakerContactStore from 'stores/potential-maker-contact-store';
import FormInput from './shared/form-input.vue';
import LokalBanner, { type ColorKeyType } from './shared/lokal-banner.vue';

interface PotentialMakerInterface {
  inDialog: boolean
}

const props = withDefaults(defineProps<PotentialMakerInterface>(), {
  inDialog: true,
});

const emit = defineEmits<{(e: 'pontential-maker-contact-created'): void,
}>();

const potentialMakerContactStore = usePotentialMakerContactStore();
const showBanner = ref(false);
const errorMessage = ref('');

const form = ref({
  firstName: '',
  lastName: '',
  email: '',
  makerName: '',
  link: '',
  comment: '',
});

const potentialMakerContactMutation = useMutation(
  () => potentialMakerContactApi.create({ ...form.value, referenceSlug: potentialMakerContactStore.referenceSlug }),
  {
    onSuccess: () => {
      potentialMakerContactStore.reset();
      if (props.inDialog) {
        emit('pontential-maker-contact-created');
      } else {
        window.location.pathname = '/';
      }
    },
    onError: () => {
      errorMessage.value = 'Ocurrió un error. Intentelo más tarde.';
      showBanner.value = true;
    },
  },
);
const isLoading = computed(() => potentialMakerContactMutation.isLoading.value);
const { handleSubmit } = useForm();
const onSubmit = handleSubmit(() => {
  potentialMakerContactMutation.mutate();
});
</script>
