import { csrfToken } from '@rails/ujs';
import { ref } from 'vue';
import api from './index';

const token = ref(csrfToken());
let headers = {};
if (token.value) {
  headers = { 'X-CSRF-Token': token.value };
}

export default {
  index(makerId: number, queryParams?: string) {
    let path = `/api/internal/free_shippings?maker_id=${makerId}`;
    if (queryParams) path += `&${queryParams}`;

    return api({
      method: 'get',
      url: path,
      headers,
    });
  },
  create(data: Partial<FreeShipping>, shippables: [number, string][]) {
    const path = '/api/internal/free_shippings';

    return api({
      method: 'post',
      url: path,
      headers,
      data: { ...data, shippables },
    });
  },
  update(id: number, data: Partial<FreeShipping>, shippables?: [number, string][]) {
    const path = `/api/internal/free_shippings/${id}`;

    return api({
      method: 'patch',
      url: path,
      headers,
      data: shippables ? { ...data, shippables } : data,
    });
  },
};
