<template>
  <Dialog
    :open="modelValue"
    as="div"
    class="relative z-50"
    @close="emit('update:modelValue', false)"
  >
    <div
      class="fixed inset-0 bg-black/30"
      aria-hidden="true"
    />
    <div class="flex fixed inset-0 justify-center items-center p-2">
      <DialogPanel
        class="flex overflow-y-auto flex-col p-2 w-full max-w-md bg-gray-50 rounded"
      >
        <button
          class="mb-0 ml-auto focus:outline-none"
          @click="emit('update:modelValue', false)"
        >
          <XMarkIcon class="w-5 h-5 fill-gray-700" />
        </button>
        <div
          v-if="showFollowUpCreated"
          class="flex flex-col items-center h-full"
        >
          <CheckBadgeIcon class="shrink-0 mb-2 w-40 h-40 fill-lk-green md:w-52 md:h-52" />
          <DialogTitle class="mb-3 text-lg text-center text-gray-700 md:text-xl">
            Seguimiento creado correctamente
          </DialogTitle>
          <button
            class="py-1 w-full text-sm text-lk-green hover:text-white hover:bg-lk-green rounded-md border border-lk-green md:p-2 md:text-base"
            @click="emit('update:modelValue', false)"
          >
            ¡Buenísimo!
          </button>
        </div>
        <template v-else>
          <DialogTitle class="px-2 mb-2 font-serif text-lg text-left text-gray-700 md:text-2xl">
            Nuevo seguimiento para {{ followableName }}
          </DialogTitle>
          <DialogDescription as="template">
            <form
              class="flex flex-col px-2 mb-3 w-full text-sm font-light text-gray-700 md:text-base"
              @submit="onSubmit"
            >
              <p class="flex flex-col mb-3">
                <span>
                  Los seguimiento te permiten registrar lo que sucede con tus clientes y
                  definir recordatorios. Por ejemplo,
                </span>
                <span class="ml-2">- "El cliente me dijo que volvería en 2 semanas"</span>
                <span class="ml-2">- "El clinte aún tiene stock, volver a preguntar en 1 mes"</span>
              </p>
              <div class="flex flex-col p-0 mb-3 rounded-md  border-lk-light-gray">
                <div
                  class="flex items-center self-start py-1 px-2 mb-2 font-normal text-white bg-lk-green rounded-md"
                >
                  <div class="flex justify-center items-center p-0 mr-2">
                    <UserCircleIcon class="shrink-0 w-4 h-4 text-white md:w-5 md:h-5" />
                  </div>
                  <p>{{ sessionStore.user.firstName }} {{ sessionStore.user.lastName }}</p>
                </div>
                <Field
                  v-model="content"
                  as="textarea"
                  class="p-2 mb-2 h-20 focus:outline-none"
                  :rules="{ required: true }"
                  name="text"
                />
                <div class="flex items-center w-full">
                  <p class="shrink-0 mr-2">
                    Recordatorio:
                  </p>
                  <lokal-datepicker
                    v-model="reminderDate"
                    :auto-apply="true"
                    :min-date="new Date()"
                    :range="false"
                  />
                </div>
              </div>
              <lokal-loading
                v-if="isLoading"
                :loading="isLoading"
                class="self-center"
              />
              <button
                v-else
                class="self-center p-2 w-full text-sm font-normal text-lk-green hover:text-white bg-white hover:bg-lk-green rounded-md border border-lk-green md:text-base"
                type="submit"
              >
                Crear seguimiento
              </button>
            </form>
          </DialogDescription>
        </template>
      </DialogPanel>
    </div>
  </dialog>
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue';
import { XMarkIcon, CheckBadgeIcon } from '@heroicons/vue/24/solid';
import { UserCircleIcon } from '@heroicons/vue/24/outline';
import { Field, useForm } from 'vee-validate';
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  DialogDescription,
} from '@headlessui/vue';
import useSessionStore from 'stores/user-store';
import followUpsApi from 'api/follow-ups';
import { useMutation } from 'vue-query';
import LokalDatepicker from './shared/lokal-datepicker.vue';

interface LokalMessageDialogProps {
  modelValue: boolean
  maker: Maker
  followUpType: 'Shop' | 'User'
  followUpId: number
  followableName: string
}

const props = withDefaults(defineProps<LokalMessageDialogProps>(), {
  modelValue: false,
});

const emit = defineEmits<{(e: 'reload-follow-ups'): void,
  (e: 'update:modelValue', value: boolean): void
}>();

const sessionStore = useSessionStore();
const content = ref('');
const reminderDate = ref(new Date());
const showFollowUpCreated = ref(false);

const followUpMutation = useMutation(
  () => followUpsApi.create({
    content: content.value,
    userId: sessionStore.user.id,
    makerId: props.maker.id,
    followableType: props.followUpType,
    followableId: props.followUpId,
    reminderDate: reminderDate.value?.toISOString(),
  }),
  {
    onSuccess: () => {
      content.value = '';
      reminderDate.value = new Date();
      showFollowUpCreated.value = true;
      emit('reload-follow-ups');
    },
  },
);

const isLoading = computed(() => followUpMutation.isLoading.value);

const { handleSubmit } = useForm();
const onSubmit = handleSubmit(async () => {
  followUpMutation.mutate();
});
</script>
<style lang="scss">
.dp__input {
  font-size: 0.875rem;
  font-weight: 300;
}
.dp-custom-cell {
  font-size: 0.875rem;
  font-weight: 300;
}
</style>
