import { useSessionStorage } from '@vueuse/core';
import { defineStore } from 'pinia';
import { keys, groupBy, values, uniq, sum } from 'lodash';
import { ref, computed, onMounted } from 'vue';

const SUBSIDIZED_FACTOR = 0.5;

// eslint-disable-next-line max-statements
const useCartStore = defineStore('cart', () => {
  const subsidizedFactor = ref(SUBSIDIZED_FACTOR);
  const items = ref({} as { [index: number]: Item });
  const shippingCostPerMaker = ref({} as { [index: number]: number });
  const lokalShippingCostPerMaker = ref({} as { [index: number]: number });
  const makerShippingCostPerMaker = ref({} as { [index: number]: number });
  const originalShippingCostPerMaker = ref({} as { [index: number]: number });
  const newShippingCostPerMaker = computed(() => {
    const newShippingCost = {} as { [index: number]: number };
    keys(originalShippingCostPerMaker).forEach((makerId) => {
      const key = parseInt(makerId, 10);
      newShippingCost[key] = originalShippingCostPerMaker.value[key] - lokalShippingCostPerMaker.value[key] -
        makerShippingCostPerMaker.value[key];
    });

    return newShippingCost;
  });
  const loadingShippingCostPerMaker = useSessionStorage('cart/loadingShippingCostPerMaker',
    {} as { [index: number]: boolean });
  const discountCodes = useSessionStorage('cart/makerDiscounts', {} as { [index: number]: DiscountCode });
  const discountCodeAmounts = useSessionStorage('cart/makerDiscountAmounts', {} as { [index: number]: number });
  const shopPickupAddressIds = useSessionStorage('cart/shopPickupAddressIds', {} as { [index: number]: number });
  const volumeDiscounts = useSessionStorage('cart/volumeDiscounts', {} as { [index: number]: VolumeDiscount });
  const volumeDiscountPerMaker = useSessionStorage('cart/volumeDiscountPerMaker', {} as { [index: number]: number });
  const loadingVolumeDiscountPerMaker = useSessionStorage('cart/loadingVolumeDiscountPerMaker',
    {} as { [index: number]: boolean });
  const shippingCostConfirmAfter = useSessionStorage('cart/shippingCostConfirmAfter',
    {} as { [index: number]: boolean });

  const orderShippingCostConfirmAfter = computed(() =>
    values(shippingCostConfirmAfter.value).some((value) => value === true));

  const loadingNewShippingCost = computed(() =>
    values(loadingShippingCostPerMaker.value).some((value) => value === true));

  const loadingVolumeDiscount = computed(() =>
    values(loadingVolumeDiscountPerMaker.value).some((value) => value === true));

  const itemsMakerIds = computed((): number[] => {
    const makerIds = values(items.value).map((item) => item.product.makerId);

    return uniq(makerIds);
  });

  const shippingCost = computed(() => sum(values(shippingCostPerMaker.value)));

  const totalVolumeDiscounts = computed(() => sum(values(volumeDiscountPerMaker.value)));

  const totalDiscountCodeAmounts = computed(() => sum(values(discountCodeAmounts.value)));

  const shippingCostMakerId = computed(() =>
    itemsMakerIds.value.find((makerId) => shippingCostPerMaker.value[makerId] === shippingCost.value),
  );

  const groupedItems = computed((): { [makerName: string]: Item[] } =>
    (groupBy(values(items.value), (item => item.product.makerName))),
  );

  function getProductItem(productId: number) {
    return items.value && items.value[productId];
  }

  const getItemsLength = computed(() => keys(items.value).length);

  function itemPresent(producId: number) {
    return !!items.value[producId]?.quantity;
  }

  function includeProductToCart(
    product: Product, newQuantity: number, wholesalePriceCents: number, variant?: Variant,
  ) {
    const currentItem = getProductItem(product.id);
    if (currentItem?.quantity) {
      items.value[product.id] = { quantity: newQuantity, product, wholesalePriceCents, variant };
    } else {
      const quantity = Math.max(newQuantity, product.minimumPurchaseQuantity);
      items.value[product.id] = { quantity, product, wholesalePriceCents, variant };
    }
  }

  function removeProductUnitFromCart(product: Product, newQuantity: number, wholesalePriceCents: number) {
    if (newQuantity < product.minimumPurchaseQuantity) {
      delete items.value[product.id];
      delete shippingCostPerMaker.value[product.makerId];
    } else if (items.value[product.id]?.quantity > 1) {
      items.value[product.id] = { ...items.value[product.id], quantity: newQuantity, product, wholesalePriceCents };
    } else {
      delete items.value[product.id];
      delete shippingCostPerMaker.value[product.makerId];
    }
  }

  const makersNameHash = computed(() => {
    const hash = {} as { [index: number]: string };
    values(items.value).forEach((item) => {
      hash[item.product.makerId] = item.product.makerName;
    });

    return hash;
  });

  onMounted(() => {
    // delete any maker shipping cost of makers that are not in the cart
    keys(shippingCostPerMaker.value).forEach((makerId) => {
      const numberMakerId = parseInt(makerId, 10);
      if (!itemsMakerIds.value.includes(numberMakerId)) {
        delete shippingCostPerMaker.value[numberMakerId];
      }
    });
    keys(loadingShippingCostPerMaker.value).forEach((makerId) => {
      const numberMakerId = parseInt(makerId, 10);
      if (!itemsMakerIds.value.includes(numberMakerId)) {
        delete loadingShippingCostPerMaker.value[numberMakerId];
      }
    });
  });

  function reset() {
    items.value = {} as { [index: number]: Item };
    volumeDiscounts.value = {} as { [index: number]: VolumeDiscount };
    volumeDiscountPerMaker.value = {} as { [index: number]: number };
    loadingVolumeDiscountPerMaker.value = {} as { [index: number]: boolean };
    discountCodes.value = {} as { [index: number]: DiscountCode };
    discountCodeAmounts.value = {} as { [index: number]: number };
    shippingCostPerMaker.value = {} as { [index: number]: number };
  }

  return {
    items, includeProductToCart, getProductItem, removeProductUnitFromCart, getItemsLength, reset, itemPresent,
    groupedItems, shippingCost, itemsMakerIds, shippingCostPerMaker, loadingNewShippingCost,
    makersNameHash, shippingCostMakerId, loadingShippingCostPerMaker, subsidizedFactor,
    originalShippingCostPerMaker, discountCodes, discountCodeAmounts, shopPickupAddressIds,
    volumeDiscounts, volumeDiscountPerMaker, totalVolumeDiscounts, loadingVolumeDiscountPerMaker,
    loadingVolumeDiscount, totalDiscountCodeAmounts, newShippingCostPerMaker, lokalShippingCostPerMaker,
    makerShippingCostPerMaker, orderShippingCostConfirmAfter, shippingCostConfirmAfter,
  };
});

export default useCartStore;
