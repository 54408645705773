import { computed, type ComputedRef, type Ref } from 'vue';
import { useQuery } from 'vue-query';
import shopPaymentsApi from 'api/shop-payments';
import useCustomParseInt from 'components/use/custom-parse-int';

export default function useShopPaymentsQuery(
  shopId: ComputedRef<number>, currentPage: Ref<number>, queryParams: ComputedRef<string>,
) {
  const shopPaymentsQuery = useQuery(
    ['shop-payments', shopId, currentPage, queryParams],
    () => shopPaymentsApi.index(shopId.value, currentPage.value, queryParams.value),
    { keepPreviousData: true },
  );
  const shopPayments = computed(() => shopPaymentsQuery.data.value?.data.shopPayments as ShopPayment[]);
  const totalResources = computed(() => useCustomParseInt(shopPaymentsQuery.data.value?.headers['x-total']));
  const resourcesPerPage = computed(() => useCustomParseInt(shopPaymentsQuery.data.value?.headers['x-per-page']));
  const totalPages = computed(() => Math.ceil(totalResources.value / resourcesPerPage.value));

  return { shopPayments, shopPaymentsQuery, totalPages };
}
