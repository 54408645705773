<template>
  <div class="flex flex-col w-full h-full text-gray-700">
    <p class="flex flex-col items-center mb-4">
      <span class="font-serif text-xl md:text-2xl">Un placer conocerte, {{ sessionStore.user?.firstName }}</span>
      <span class="text-sm text-center text-gray-500 md:text-sm">
        Nos gustaría conocer un poco de tu negocio para poder mostrarte el catálogo mayorista
        <span v-if="maker?.id">
          de {{ maker.name }}
        </span>
      </span>
    </p>
    <form
      class="flex flex-col w-full"
      @submit="onSubmit"
    >
      <p class="mt-1 text-sm">
        Nombre de tienda
      </p>
      <form-input
        v-model="signUpStore.shopForm.name"
        class=""
        placeholder="Nombre de tu tienda"
        type="text"
        name="user[first_name]"
        label-name="Nombre de tu tienda"
        :rules="{ required: true }"
      />
      <p class="mt-1 text-sm">
        Página web o redes sociales
      </p>
      <form-input
        v-model="signUpStore.shopForm.webpageLink"
        class=""
        placeholder="Página web o redes sociales"
        type="text"
        name="user[last_name]"
        label-name="Página web o redes sociales"
        :rules="{ required: true }"
      />
      <p class="mt-1 text-sm">
        Tipo de tienda
      </p>
      <lokal-generic-combobox
        v-model="selectedKind"
        :options="shopKindOptions"
        :get-option-key="(option: ShopKindOption) => option.id"
        :get-option-label="(option: ShopKindOption) => option.name"
        option-label="name"
        options-label="Selecciona una opción"
        text-size="text-xs md:text-sm"
      />
      <form-input
        v-model="kind"
        class="hidden"
        placeholder="Tipo de tienda"
        type="text"
        name="shops[kind]"
        label-name="Tipo de tienda"
        :rules="{ required: true }"
      />
      <ErrorMessage
        name="shops[kind]"
        class="text-sm text-red-500"
      />
      <form-input
        v-if="selectedKind.id === 3"
        v-model="otherKind"
        class="mt-1"
        placeholder="Otro tipo de tienda"
        type="text"
        name="shops[other_kind]"
        label-name="Otro tipo de tienda"
        :rules="{ required: true }"
      />
      <div class="flex items-center space-x-3 w-full">
        <div
          v-if="!regionsQuery.isLoading.value"
          class="flex flex-col w-full"
        >
          <p class="mt-1 text-sm">
            Región de tu tienda
          </p>
          <lokal-generic-combobox
            v-model="selectedRegion"
            :options="regions"
            :get-option-key="(option: Region) => option.id"
            :get-option-label="(option: Region) => option.name"
            option-label="name"
            options-label="Región de destino"
            text-size="text-xs md:text-sm"
            :multiple="false"
          />
          <Field
            v-model="selectedRegionId"
            hidden
            name="selectedRegionId"
            label="Región de destino"
            :rules="{ required: true }"
          />
          <ErrorMessage
            name="selectedRegionId"
            class="text-sm text-red-500"
          />
        </div>
        <div
          v-if="!communesQuery.isLoading.value"
          class="flex flex-col w-full"
        >
          <p class="mt-1 text-sm">
            Comuna de tu tienda
          </p>
          <lokal-generic-combobox
            v-model="selectedCommune"
            :options="filterCommunes"
            :get-option-key="(option: Commune) => option.id"
            :get-option-label="(option: Commune) => option.name"
            option-label="name"
            options-label="Comuna de destino"
            text-size="text-xs md:text-sm"
          />
          <Field
            v-model="selectedCommuneId"
            hidden
            name="selectedCommuneId"
            label="Comuna de destino"
            :rules="{ required: true }"
          />
          <ErrorMessage
            name="selectedCommuneId"
            class="text-sm text-red-500"
          />
        </div>
      </div>
      <div
        v-if="signUpStore.shopForm.registrationDiscountCodeToken"
        class="flex flex-col mt-1"
      >
        <p class="mb-1 text-xs md:text-sm">
          Código de descuento para tu primera compra:
        </p>
        <div class="p-1 mr-auto text-xs text-white bg-lk-light-purple rounded-full md:text-sm">
          {{ signUpStore.shopForm.registrationDiscountCodeToken }}
        </div>
      </div>
      <lokal-loading
        v-if="isLoading"
        class="self-center mt-3"
        :loading="isLoading"
      />
      <div
        v-else
        class="flex flex-col items-center my-3 w-full"
      >
        <button class="p-2 mb-1 w-full text-sm text-white bg-lk-green/90 hover:bg-lk-green rounded-md md:text-base">
          Guardar y finalizar
        </button>
        <div class="flex justify-between items-center w-full text-xs md:text-sm">
          <button
            class="hover:font-semibold hover:text-lk-green underline"
            @click="resetStoresAndSignOut"
          >
            No soy una tienda
          </button>
          <a
            class="hover:font-semibold hover:text-lk-green underline"
            :href="`https://wa.me/${phoneNumber}?text=${whatsappText}`"
            target="_blank"
            noopener
          >
            Necesito ayuda
          </a>
        </div>
        <p
          v-if="selectedKind.id === 2"
          class="mt-2 text-sm text-red-500"
        >
          Lokal es una plataforma para abastecer tiendas. Si deseas comprar
          para consumo personal debes hacerlo en la página de cada marca.
        </p>
      </div>
    </form>
    <address-new-dialog
      v-model="showAddressNewDialog"
      :addressable-id="1"
      addressable-type="Shop"
    />
  </div>
</template>
<script lang="ts" setup>
import { ref, computed, watch } from 'vue';
import shopsApi from 'api/shops';
import { useMutation, useQuery } from 'vue-query';
import makersApi from 'api/makers';
import useSignUpStore from 'stores/sign-up-store';
import useSessionStore from 'stores/user-store';
import { useForm, ErrorMessage, Field } from 'vee-validate';
import formInput from './shared/form-input.vue';
import AddressNewDialog from './address-new-dialog.vue';
import lokalGenericCombobox from './shared/lokal-generic-combobox.vue';
import useRegionsQuery from './queries/regions-query';
import useCommunesQuery from './queries/communes-query';
import useShopQuery from './queries/shop-query';

interface ShopKindOption {
  id: number;
  name: string;
  type: string;
}

const sessionStore = useSessionStore();
const signUpStore = useSignUpStore();

const showAddressNewDialog = ref(false);
const selectedKind = ref({} as ShopKindOption);
const kind = computed(() => selectedKind.value.name as string);
const otherKind = ref('');
const shopKindOptions = ref([
  { id: 4, name: 'Tienda física y online', type: 'physical_and_online_store' },
  { id: 0, name: 'Tienda física', type: 'physical_store' },
  { id: 1, name: 'Solo tienda online', type: 'online_store' },
  { id: 2, name: 'Consumo personal', type: 'personal_consumption' },
  { id: 3, name: 'Otro', type: 'other' },
] as ShopKindOption[]);

const { shop, shopQuery } = useShopQuery(computed(() => sessionStore.user?.shopId));
const { regions, regionsQuery } = useRegionsQuery();
const { communes, communesQuery } = useCommunesQuery();
const selectedRegion = ref({} as Region);
const selectedRegionId = computed(() => selectedRegion.value.id as number);
const selectedCommune = ref({} as Commune);
const selectedCommuneId = computed(() => selectedCommune.value.id as number);
const filterCommunes = computed(() =>
  (communes.value?.filter((commune) => commune.regionId === selectedRegion.value.id)));
const shopCreationData = computed(() => ({
  ...signUpStore.shopForm, referenceCommuneId: selectedCommuneId.value, referenceRegionId: selectedRegionId.value,
}));

watch(selectedRegion, () => {
  selectedCommune.value = {} as Commune;
});

async function shopCreationOrUpdateSuccess() {
  signUpStore.resetShopForm();
  await sessionStore.reloadUser();
  signUpStore.currentStep = 'success';
}

const { handleSubmit } = useForm();
const createShopMutation = useMutation(() => shopsApi.create(shopCreationData.value), {
  onSuccess: () => {
    shopCreationOrUpdateSuccess();
  },
});
const updateShopMutation = useMutation(() => shopsApi.update(shop.value.id, shopCreationData.value), {
  onSuccess: () => {
    shopCreationOrUpdateSuccess();
  },
});
const isLoading = computed(() => createShopMutation.isLoading.value || communesQuery.isLoading.value ||
  regionsQuery.isLoading.value && shopQuery.isLoading.value);

const onSubmit = handleSubmit(() => {
  signUpStore.shopForm.kind = selectedKind.value.type as string;
  if (selectedKind.value.type === 'other') signUpStore.shopForm.otherKind = otherKind.value;
  if (shop.value?.id) {
    updateShopMutation.mutate();
  } else {
    createShopMutation.mutate();
  }
});

watch(shop, (newValue, oldValue) => {
  if (!oldValue?.id && newValue.id) {
    signUpStore.shopForm.name = newValue.name;
    signUpStore.shopForm.webpageLink = newValue.webpageLink as string;
    signUpStore.shopForm.kind = newValue.kind;
    selectedKind.value = shopKindOptions.value.find((option) => option.type === newValue.kind) as ShopKindOption;
    signUpStore.shopForm.otherKind = newValue.otherKind;
  }
});

function resetStoresAndSignOut() {
  sessionStore.reset();
  signUpStore.reset();
  window.location.href = '/users/sign_out';
}

const makerSlug = computed(() => sessionStore.user?.referredSlug);
const makerQuery = useQuery(['maker', makerSlug], () => makersApi.get(makerSlug.value), {
  enabled: computed(() => !!makerSlug.value),
});
const maker = computed(() => makerQuery.data.value?.data.maker);

const phoneNumber = computed(() => process.env.CONTACT_NUMBER);
const whatsappText = computed(() => 'Necesito ayuda en la creación de mi tienda.');
</script>
