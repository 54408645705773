<template>
  <div class="flex flex-col py-6 px-5 mb-10 max-w-2xl text-sm font-light text-gray-700 md:py-10 md:px-0 md:text-base">
    <h1 class="mb-8 text-2xl font-normal text-center text-lk-green md:text-3xl">
      Política de despacho
    </h1>
    <p class="mb-3">
      El tiempo de confirmación de una orden es de 1-5 días hábiles más el despacho de hasta 5 días hábiles a menos
      que el producto indique lo contrario o el destino sea una región extrema.
    </p>
    <h3 class="mb-2 text-lg font-normal md:text-xl">
      Desconocimiento de entrega
    </h3>
    <p>
      El cliente deberá contactarse con Lokal para reportar la incidencia.
    </p>
    <ul class="px-5 list-decimal md:px-8">
      <li>
        Plazo: Corresponde al tiempo desde que la empresa transportista confirma haber entregado el bulto
        y el día efectivo de reporte. El plazo es de 20 días de corrido.
      </li>
      <li>
        Responsabilidad de la tienda: Informar a Lokal dentro del plazo estipulado.
      </li>
      <li>
        Responsabilidad de la marca: Respaldar y documentar con evidencias:
        <ul class="px-5 list-disc md:px-8">
          <li>
            Adjuntar factura de la orden.
          </li>
          <li>
            Caso de pickup: Adjuntar el manifiesto firmado por el transportista.
          </li>
          <li>
            Caso entrega en sucursal: Adjuntar documento de entrega del bulto.
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>
