<template>
  <div
    for=""
    class="text-gray-700 border"
  >
    <p class="p-3 text-sm bg-gray-50 md:p-4 md:text-base">
      {{ label }}
    </p>
    <div class="flex flex-col">
      <form-radio-input
        v-for="(value, index) in values"
        :key="`${name}-${value}`"
        v-model="inputValue"
        :label="radioLabels[index]"
        :name="name"
        :value="value"
        :custom-label-classes="customRadioLabelClasses"
      />
      <span
        v-if="errorMessage"
        class="mt-1 text-sm text-red-600"
      >
        {{ errorMessage }}
      </span>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { watch, computed, toRefs } from 'vue';
import { useField } from 'vee-validate';
import FormRadioInput from './form-radio-input.vue';

interface FormRadioInputsProps {
  name: string
  label: string
  description?: string
  modelValue: string | boolean | number
  values: string[] | boolean[] | number[]
  radioLabels: string[]
  rules?: string
  customRadioLabelClasses?: string
}

const props = withDefaults(defineProps<FormRadioInputsProps>(), {
  description: '',
  rules: '',
  customRadioLabelClasses: '',
});

const { name, rules } = toRefs(props);
const {
  errorMessage,
  value: inputValue,
} = useField(name, rules, {
  type: 'radio',
  initialValue: props.modelValue,
});

const emit = defineEmits<{(e: 'update:modelValue', value: string | boolean | number): void}>();

watch(() => props.modelValue, () => {
  inputValue.value = props.modelValue;
});

watch(inputValue, (newVal) => {
  emit('update:modelValue', newVal);
});
</script>
