import { csrfToken } from '@rails/ujs';
import { ref } from 'vue';
import api from './index';

const token = ref(csrfToken());
let headers = {};
if (token.value) {
  headers = { 'X-CSRF-Token': token.value };
}

const PAGE_SIZE = 24;

export default {
  index(subOrderId?: number) {
    const path = `/api/internal/sub_orders/${subOrderId}/sub_order_products`;

    return api({
      method: 'get',
      url: path,
      headers,
    });
  },
  quantityGroupByProduct(pageNumber: number, queryParams?: string) {
    let path = '/api/internal/sub_order_products/quantity_group_by_products';
    path = `${path}?page[number]=${pageNumber}&page[size]=${PAGE_SIZE}&${queryParams}`;

    return api({
      method: 'get',
      url: path,
      headers,
    });
  },
};
