<template>
  <Switch
    :model-value="modelValue"
    :class="[modelValue ? color : 'bg-lk-light-gray']"
    class="inline-flex relative shrink-0 items-center w-7 h-4 rounded-full md:w-11 md:h-6"
    :disabled="disabled"
    @update:model-value="updateValue"
    @click.stop
  >
    <span
      :class="{ 'translate-x-4 md:translate-x-6': modelValue, 'translate-x-1': !modelValue }"
      class="inline-block w-2 h-2 bg-white rounded-full transition duration-300 ease-in-out md:w-4 md:h-4"
    />
  </Switch>
</template>
<script lang="ts" setup>
import { Switch } from '@headlessui/vue';

interface LokalSwithProps {
  modelValue: boolean;
  color: string;
  disabled?: boolean
}

withDefaults(defineProps<LokalSwithProps>(), {
  modelValue: false,
  color: 'bg-lk-green',
  disabled: false,
});

const emit = defineEmits(['update:modelValue']);

function updateValue(value: boolean) {
  emit('update:modelValue', value);
}
</script>
