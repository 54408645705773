<template>
  <div
    v-if="modelValue"
    class="flex p-3 w-full"
    :class="[wrapperClasses[cleanColorKey]]"
  >
    <p :class="[pClasses[cleanColorKey]]">
      {{ message }}
    </p>
    <button
      class="ml-auto focus:outline-none"
      @click="emit('update:modelValue', false)"
    >
      <XMarkIcon
        class="w-6 h-6"
        :class="[xIconClasses[cleanColorKey]]"
      />
    </button>
  </div>
</template>
<script lang="ts" setup>
import { XMarkIcon } from '@heroicons/vue/24/outline';
import { computed } from 'vue';

export type ColorKeyType = 'notice' | 'alert' | 'notice_dialog';
export type EnableColorKeyType = 'notice' | 'alert';

const props = defineProps<{
  modelValue: boolean
  message: string
  colorKey: ColorKeyType
}>();

const emit = defineEmits<{(e: 'update:modelValue', value: boolean): void}>();

const cleanColorKey = computed(() => {
  if (props.colorKey.includes('dialog')) {
    return props.colorKey.split('_')[0] as EnableColorKeyType;
  }

  return props.colorKey as EnableColorKeyType;
});

const wrapperClasses = {
  notice: 'bg-lk-light-green text-sm md:text-base',
  alert: 'bg-slate-200 text-sm md:text-base',
};

const pClasses = {
  notice: 'text-white',
  alert: 'text-red-500',
};

const xIconClasses = {
  notice: 'text-white',
  alert: 'text-gray-700',
};
</script>
