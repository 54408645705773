import { computed, type ComputedRef } from 'vue';
import { useQuery } from 'vue-query';
import subOrdersApi from 'api/sub-orders';

function useDestinationMetricsQuery(
  makerId: ComputedRef<number>, enabled: ComputedRef<boolean>, queryParams?: ComputedRef<string>,
) {
  const destinationMetricsQuery = useQuery(['destination-metrics', makerId, queryParams], () =>
    subOrdersApi.destinationMetrics(makerId.value, queryParams?.value), { enabled });
  const destinationMetricsLoading = computed(() => destinationMetricsQuery.isLoading.value);
  const subOrders = computed(() => destinationMetricsQuery.data.value?.data.subOrders as SubOrder[]);

  return { destinationMetricsQuery, destinationMetricsLoading, subOrders };
}

export default useDestinationMetricsQuery;
