import { computed } from 'vue';
import { orderBy } from 'lodash';
import greenKImage from 'assets/images/green-k.jpg';

export default function useProductImage(product: Product, size?: keyof Derivatives) {
  const defaultSize = size || 'jpgMd';

  const productImageToShow = computed(() => {
    const orderedImages = orderBy(product.images, 'priority', 'asc');

    return orderedImages[0]?.derivatives?.[defaultSize]?.url || greenKImage;
  });

  return { productImageToShow, defaultSize };
}
