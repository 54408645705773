<template>
  <div
    v-if="size === 'xs'"
    class="flex items-center space-x-1"
  >
    <div class="w-2 h-2 bg-lk-green rounded-full animate-[bounce_0.8s_infinite_alternate]" />
    <div class="w-2 h-2 bg-lk-green rounded-full animate-[bounce_0.6s_infinite_alternate]" />
    <div class="w-2 h-2 bg-lk-green rounded-full animate-[bounce_0.4s_infinite_alternate]" />
  </div>
  <div
    v-else
    class="relative"
    :class="{ 'self-center': isMobile }"
  >
    <div
      class="rounded-full border-4 border-t-lk-green animate-spin"
      :class="sizeClasses[size]"
    />
    <img
      src="assets/images/green-k.jpg"
      class="absolute rounded-full animate-pulse"
      :class="imgClasses[size]"
    >
  </div>
</template>
<script lang="ts" setup>
import { inject } from 'vue';

interface LokalLoadingProps {
  loading: boolean,
  size?: 'xs' | 'small' | 'big'
}

withDefaults(defineProps<LokalLoadingProps>(), {
  size: 'big',
});

const isMobile = inject('isMobile');

const sizeClasses = {
  'small': 'w-10 h-10 mb-10',
  'big': 'w-16 h-16 md:w-20 md:h-20 mb-2',
};
const imgClasses = {
  'small': 'top-1 left-1 w-8 h-8',
  'big': 'top-2 left-2 w-12 md:w-16 h-12 md:h-16',
};
</script>
