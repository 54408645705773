<template>
  <Dialog
    :open="modelValue"
    as="div"
    class="relative z-50"
    @close="emit('update:modelValue', false)"
  >
    <div
      class="fixed inset-0 bg-black/30"
      aria-hidden="true"
    />
    <div class="flex fixed inset-0 justify-center items-center p-2">
      <DialogPanel class="flex overflow-y-auto flex-col p-2 w-full max-w-md max-h-[650px] bg-gray-50 rounded md:p-4">
        <button
          class="mb-2 ml-auto focus:outline-none"
          @click="emit('update:modelValue', false)"
        >
          <XMarkIcon class="w-6 h-6 fill-gray-700" />
        </button>
        <DialogTitle
          v-if="freeShipping?.id"
          class="mb-3 font-serif text-xl text-center text-gray-700 md:text-3xl"
        >
          Editar despacho gratis #{{ freeShipping.id }}
        </DialogTitle>
        <DialogTitle
          v-else
          class="mb-3 font-serif text-xl text-center text-gray-700 md:text-3xl"
        >
          Nuevo despacho gratis
        </DialogTitle>
        <DialogDescription
          class="mb-5 text-sm font-light text-gray-700 md:text-base"
        >
          <form @submit="onSubmit">
            <p class="text-sm md:text-base">
              Monto mínimo (SIN IVA) para aplicar el despacho gratis
            </p>
            <div class="flex flex-col mb-2 w-full">
              <Field
                v-model="shadowMinimumAmountCents"
                class="p-2 w-full text-sm rounded-md border focus:outline-none md:text-base"
                name="shadowMinimumAmountCents"
                :rules="{ required: true }"
                @focus="shadowMinimumAmountCents = reverseFormatCurrency(shadowMinimumAmountCents)"
                @blur="shadowMinimumAmountCents = useFormatCurrency(toNumber(shadowMinimumAmountCents))"
              />
              <Field
                v-model="minimumAmountCents"
                :rules="{ required: true, min_value: 1 }"
                name="minimumAmountCents"
                label="Monto mínimo"
                hidden
              />
              <ErrorMessage
                name="minimumAmountCents"
                class="mt-1 text-sm text-red-500"
              />
            </div>
            <div class="flex flex-col mb-2 w-full">
              <p class="text-sm md:text-base">
                Selecciona las regiones a las que aplicará el despacho gratis.
              </p>
              <div
                v-if="selectedRegions && selectedRegions.length > 0"
                class="flex flex-wrap gap-1 items-center mb-1 text-sm md:text-base"
              >
                <div
                  v-for="region in selectedRegions"
                  :key="region.id"
                  class="flex items-center p-1 text-white bg-lk-green rounded-md"
                >
                  <span>{{ region.name }}</span>
                  <button
                    class="shrink-0"
                    @click="selectedRegions =
                      selectedRegions.filter((intRegion: Region) => intRegion.id !== region.id)"
                  >
                    <XMarkIcon class="w-4 h-4 fill-white" />
                  </button>
                </div>
              </div>
              <lokal-loading
                v-if="regionsQueryIsLoading"
                :loading="regionsQueryIsLoading"
                size="xs"
              />
              <lokal-generic-combobox
                v-else
                v-model="selectedRegions"
                :options="regions"
                :get-option-key="(option) => option.id"
                :get-option-label="(option) => option.name"
                options-label="Selecciona una o más regiones"
                :multiple="true"
                :rules="{ required: true }"
              />
              <Field
                v-model="selectedRegionsAndCommunesLength"
                name="selectedRegionsAndCommunesLength"
                :rules="{ required: true, min_value: 1 }"
                label="Regiones o communas seleccionadas"
                hidden
              />
              <ErrorMessage
                name="selectedRegionsAndCommunesLength"
                class="mt-1 text-sm text-red-500"
              />
            </div>
            <div class="flex justify-between items-center mb-2 w-full">
              <p class="text-sm md:text-base">
                ¿Filtrar comunas por provincia?
              </p>
              <lokal-switch
                v-model="enableProvinceFilter"
              />
            </div>
            <div
              v-if="enableProvinceFilter"
              class="flex flex-col mb-2 w-full"
            >
              <p class="text-sm md:text-base">
                Selecciona una provincia a las que aplicará el despacho gratis
              </p>
              <div
                v-if="selectedProvinces && selectedProvinces.length > 0"
                class="flex flex-wrap gap-1 items-center mb-1 text-sm md:text-base"
              >
                <div
                  v-for="province in selectedProvinces"
                  :key="province.id"
                  class="flex items-center p-1 text-white bg-lk-green rounded-md"
                >
                  <span>{{ province.name }}</span>
                  <button
                    class="shrink-0"
                    @click="selectedProvinces =
                      selectedProvinces.filter((intProvince) => intProvince.id !== province.id)"
                  >
                    <XMarkIcon class="w-4 h-4 fill-white" />
                  </button>
                </div>
              </div>
              <lokal-loading
                v-if="provincesQueryIsLoading"
                :loading="provincesQueryIsLoading"
                size="xs"
              />
              <lokal-generic-combobox
                v-else
                v-model="selectedProvinces"
                :options="provinces"
                :get-option-key="(option) => option.id"
                :get-option-label="(option) => option.name"
                options-label="Selecciona una provincia"
                :multiple="true"
                :rules="{ required: true }"
              />
            </div>
            <div class="flex flex-col w-full">
              <p class="text-sm md:text-base">
                Selecciona las comunas a las que aplicará el despacho gratis
              </p>
              <div
                v-if="selectedCommunes && selectedCommunes.length > 0"
                class="flex flex-wrap gap-1 items-center mb-1 text-sm md:text-base"
              >
                <div
                  v-for="commune in selectedCommunes"
                  :key="commune.id"
                  class="flex items-center p-1 text-white bg-lk-green rounded-md"
                >
                  <span>{{ commune.name }}</span>
                  <button
                    class="shrink-0"
                    @click="selectedCommunes = selectedCommunes.filter((intCommune) => intCommune.id !== commune.id)"
                  >
                    <XMarkIcon class="w-4 h-4 fill-white" />
                  </button>
                </div>
              </div>
              <lokal-loading
                v-if="communesQueryIsLoading"
                :loading="communesQueryIsLoading"
                size="xs"
              />
              <lokal-generic-combobox
                v-else
                v-model="selectedCommunes"
                :options="communes"
                :get-option-key="(option) => option.id"
                :get-option-label="(option) => option.name"
                options-label="Selecciona una comuna"
                :multiple="true"
                :rules="{ required: true }"
              />
              <ErrorMessage
                name="selectedRegionsAndCommunesLength"
                class="mt-1 text-sm text-red-500"
              />
            </div>
            <p class="mb-3 text-xs font-light md:mb-4 md:text-sm">
              Nota: Si escoges una región, el despacho gratis se aplicará a todas las comunas de esa región.
            </p>
            <lokal-loading
              v-if="isLoading"
              :loading="isLoading"
              class="self-center"
            />
            <button
              class="p-2 w-full text-sm font-normal text-lk-green hover:text-white bg-white hover:bg-lk-green rounded-md border border-lk-green md:text-base"
            >
              {{ freeShipping?.id ? 'Actualizar' : 'Crear' }}
            </button>
          </form>
        </DialogDescription>
      </DialogPanel>
    </div>
  </dialog>
</template>
<script lang="ts" setup>
import { XMarkIcon } from '@heroicons/vue/24/solid';
import { Field, useForm, ErrorMessage } from 'vee-validate';
import { ref, watch, computed } from 'vue';
import { isNumber, toNumber } from 'lodash';
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  DialogDescription,
} from '@headlessui/vue';
import { useMutation } from 'vue-query';
import freeShippingsApi from 'api/free-shippings';
import useFormatCurrency from './use/format-currency';
import useRegionsQuery from './queries/regions-query';
import useCommunesQuery from './queries/communes-query';
import useProvincesQuery from './queries/provinces-query';
import LokalGenericCombobox from './shared/lokal-generic-combobox.vue';
import lokalSwitch from './shared/lokal-switch.vue';

interface LokalMessageDialogProps {
  modelValue: boolean
  makerId?: number
  freeShipping?: FreeShipping
}
const props = withDefaults(defineProps<LokalMessageDialogProps>(), {
  modelValue: false,
  makerId: undefined,
  freeShipping: undefined,
});

const enableProvinceFilter = ref(false);

const shadowMinimumAmountCents = ref(
  props.freeShipping?.id ? useFormatCurrency(props.freeShipping?.minimumAmountCents) : '0');
const minimumAmountCents = ref(props.freeShipping?.minimumAmountCents || 0);

const emit = defineEmits<{(e: 'reload-free-shippings'): void,
(e: 'update:modelValue', value: boolean): void
}>();

function emitEvents() {
  emit('reload-free-shippings');
  emit('update:modelValue', false);
}

function reverseFormatCurrency(value: string) {
  return value.replace(/[^0-9]+/g, '');
}

const { regions, regionsQuery } = useRegionsQuery();
const regionsQueryIsLoading = computed(() => regionsQuery.isLoading.value);
const selectedRegions = ref(props.freeShipping?.regions || [] as Region[]);

const { provinces, provincesQuery } = useProvincesQuery(computed(() => enableProvinceFilter.value));
const provincesQueryIsLoading = computed(() => provincesQuery.isLoading.value);
const selectedProvinces = ref([] as Province[]);

const { communes, communesQuery } = useCommunesQuery();
const communesQueryIsLoading = computed(() => communesQuery.isLoading.value);
const selectedCommunes = ref(props.freeShipping?.communes || [] as Commune[]);
const selectedRegionsAndCommunesLength = computed(() => selectedRegions.value.length || selectedCommunes.value.length);

watch(shadowMinimumAmountCents, (newValue) => {
  const reverseFormattedValue = toNumber(reverseFormatCurrency(newValue));
  if (isNumber(reverseFormattedValue)) {
    minimumAmountCents.value = reverseFormattedValue as number;
  }
});

const regionOrCommuneShippables = computed(() => {
  const shippable = [] as [number, string][];
  selectedRegions.value.forEach((region: Region) => shippable.push([region.id, 'Region']));
  selectedCommunes.value.forEach(commune => shippable.push([commune.id, 'Commune']));

  return shippable;
});
const data = computed(() => ({
  makerId: props.makerId,
  minimumAmountCents: minimumAmountCents.value,
}));
const updateMutation = useMutation(
  () => freeShippingsApi.update(props.freeShipping?.id, data.value, regionOrCommuneShippables.value), {
    onSuccess: () => {
      emitEvents();
    },
  });
const createMutation = useMutation(
  () => freeShippingsApi.create({ ...data.value }, regionOrCommuneShippables.value), {
    onSuccess: () => {
      emitEvents();
    },
  });
const isLoading = computed(() => createMutation.isLoading.value || updateMutation.isLoading.value);
const { handleSubmit } = useForm();
const onSubmit = handleSubmit(() => {
  if (props.freeShipping?.id) {
    updateMutation.mutate();
  } else {
    createMutation.mutate();
  }
});

watch(selectedProvinces, () => {
  const newSelectedCommunes = communes.value.filter((commune) =>
    selectedProvinces.value.some((province) => province.id === commune.provinceId));
  selectedCommunes.value = newSelectedCommunes;
});
</script>
