import { computed, type ComputedRef } from 'vue';
import { orderBy } from 'lodash';

const MAX_DELIVERY_DAYS = 15;
const ARBITRARY_PRICE = 7869;

export default function useMakerShippingQuotations(
  sortedShippingQuotations: ComputedRef<ShippingQuotation[]>, carrierCodes: ComputedRef<string[]>,
) {
  const filterSortedShippingQuotations = computed(() =>
    sortedShippingQuotations.value?.filter((shippingQuotation) =>
      carrierCodes.value?.includes(shippingQuotation.carrier),
    ),
  );

  function selectQuotation() {
    let selectedQuotation = {} as ShippingQuotationService;
    for (let i = 0; i < filterSortedShippingQuotations.value?.length; i++) {
      const quotations = filterSortedShippingQuotations.value[i]?.services.filter(
        (service) => service.transitDays <= MAX_DELIVERY_DAYS);

      const quotation = orderBy(quotations, (service) => service.price, 'asc')?.[0];
      if (quotation) {
        selectedQuotation = { ...quotation,
          carrierName: filterSortedShippingQuotations.value[i].carrier } as ShippingQuotationService;
        break;
      }
    }

    return selectedQuotation;
  }

  const selectedShippingQuotation = computed((): ShippingQuotationService => {
    const length = sortedShippingQuotations.value.length;
    if (length === 0) return {} as ShippingQuotationService;

    if (filterSortedShippingQuotations.value.length > 0) {
      const selectedQuotation = selectQuotation();
      if (selectedQuotation) return selectedQuotation;

      return {} as ShippingQuotationService;
    }

    return {} as ShippingQuotationService;
  });

  const shippingCost = computed(() => {
    if (selectedShippingQuotation.value?.price) {
      return selectedShippingQuotation.value?.price;
    }

    return ARBITRARY_PRICE;
  });

  return {
    shippingCost,
    selectedShippingQuotation,
    filterSortedShippingQuotations,
  };
}
