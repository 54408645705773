import { computed, inject, type Ref } from 'vue';
import { sortBy } from 'lodash';

// eslint-disable-next-line complexity
export default function useImageUrl(product: Product, size?: keyof Derivatives) {
  const isMobile = inject<Ref<boolean>>('isMobile');
  const firstImage = computed(() => {
    const sortedImages = sortBy(product.images, (image) => image.priority);

    return sortedImages[0];
  });

  if (!!size) {
    return (product.images && firstImage.value?.derivatives[size]?.url) || '';
  } else if (isMobile?.value) {
    return (product.images && firstImage.value?.derivatives?.jpgSm?.url) || '';
  }

  return (product.images && firstImage.value?.derivatives?.jpgMd?.url) || '';
}
