<template>
  <tr
    class="border"
  >
    <td class="p-2">
      <a
        class="underline"
        :href="`/makers/${maker.id}/shops/${shop.id}`"
      >
        {{ shop.name }}
      </a>
    </td>
    <td class="hidden p-2 md:block">
      {{ shop.socialReason }}
    </td>
    <td class="text-center">
      <lokal-loading
        v-if="isLoadingSubOrderQuery"
        :loading="isLoadingSubOrderQuery"
        size="xs"
      />
      <span v-else>
        {{ subOrders.length }}
      </span>
    </td>
    <td class="text-center">
      <lokal-loading
        v-if="isLoadingSubOrderQuery"
        :loading="isLoadingSubOrderQuery"
        size="xs"
      />
      <span v-else-if="subOrders.length && subOrders.length > 0">
        {{ new Date(subOrders[subOrders.length - 1]?.pendingAt)?.toDateString() }}
      </span>
    </td>
    <td class="text-center">
      <lokal-loading
        v-if="isLoadingSubOrderQuery"
        :loading="isLoadingSubOrderQuery"
        size="xs"
      />
      <span v-else-if="subOrders.length && subOrders.length > 1">
        {{ round(meanFrequencyTimesFormat, 2) }} {{ isFrequencyHours ? 'horas' : 'días' }}
      </span>
      <span v-else>
        -
      </span>
    </td>
  </tr>
</template>
<script lang="ts" setup>
import { ref, computed } from 'vue';
import { orderBy, mean, round } from 'lodash';
import useMakerSubOrders from './use/maker-sub-orders';

const HOURS_PER_DAY = 24;

const props = defineProps<{
  shop: Shop
  maker: Maker
}>();

const currentPage = ref(1);
const queryParams = computed(() => `q[order_shop_id_eq]=${props.shop.id}&q[s][]=pending_at+asc`);
const computedMakerId = computed(() => props.maker.id);
const { subOrders, isLoadingSubOrderQuery } = useMakerSubOrders(
  computedMakerId, { queryParams, page: currentPage, paginationSize: 50 },
);

function timeDifference(date1: Date, date2: Date) {
  const difference = date1.getTime() - date2.getTime();

  // eslint-disable-next-line no-magic-numbers
  return difference / (1000 * 60 * 60 * 24);
}

const filterdSubOrders = computed(() => subOrders.value?.filter((subOrder: SubOrder) => subOrder.pendingAt));
const orderedSubOrders = computed(() => orderBy(filterdSubOrders.value, 'pendingAt', 'asc'));

// frequency time of suborders
const frequencyTimes = computed(() => {
  if (!subOrders.value?.length || subOrders.value.length === 1) return [];

  const times = [] as number[];
  let initialDate = orderedSubOrders.value[0]?.pendingAt;
  orderBy(orderedSubOrders.value?.slice(1), 'pendingAt', 'asc')?.forEach((subOrder: SubOrder) => {
    const pendingDate = new Date(subOrder.pendingAt);
    const difference = timeDifference(pendingDate, new Date(initialDate));
    times.push(difference);
    initialDate = subOrder.pendingAt;
  });

  return times;
});
const meanFrequencyTimes = computed(() => mean(frequencyTimes.value));
const meanFrequencyTimesFormat = computed(() => {
  if (meanFrequencyTimes.value <= 1) return meanFrequencyTimes.value * HOURS_PER_DAY;

  return meanFrequencyTimes.value;
});
const isFrequencyHours = computed(() => meanFrequencyTimes.value < 1);
</script>
