<template>
  <div
    ref="stickyElement"
    class="flex sticky -top-1 z-10 flex-col w-full max-w-3xl bg-gray-50"
    :class="{ 'pb-5 md:pt-5': isStickyElement, 'py-5': !isStickyElement }"
  >
    <div class="flex items-center p-5 w-full text-xs bg-white md:px-10 md:text-sm">
      <div
        class="shrink-0 p-2 text-white bg-lk-green rounded-full"
      >
        <p
          v-if="showPendingStatus || currentSubOrder.status === 'pending' || !isMobileOrTablet"
          @mouseleave="showPendingStatus = false"
          @click="showPendingStatus = false"
        >
          Pendiente
        </p>
        <p
          v-else
          class="px-1.5"
          @mouseover="showPendingStatus = true"
        >
          P
        </p>
      </div>
      <div
        class="w-full border border-b"
        :class="{ 'border-gray-300': currentSubOrder.status === 'pending',
                  'border-lk-green': currentSubOrder.status !== 'pending' }"
      />
      <div
        class="shrink-0 p-2 text-white rounded-full"
        :class="{ 'bg-gray-300': currentSubOrder.status === 'pending',
                  'bg-lk-green': currentSubOrder.status !== 'pending' }"
      >
        <p
          v-if="showConfirmedStatus || currentSubOrder.status === 'confirmed' || !isMobileOrTablet"
          @mouseleave="showConfirmedStatus = false"
          @click="showConfirmedStatus = false"
        >
          Confirmado
        </p>
        <p
          v-else
          class="shrink-0 px-1.5"
          @mouseover="showConfirmedStatus = true"
        >
          C
        </p>
      </div>
      <template v-if="currentSubOrder.shipmentMethod === 'shop_pickup'">
        <div
          class="w-full border border-b"
          :class="{ 'border-gray-300': !['ready_for_pickup', 'delivered'].includes(currentSubOrder.status),
                    'border-lk-green': ['ready_for_pickup', 'delivered'].includes(currentSubOrder.status) }"
        />
        <div
          class="shrink-0 p-2 text-white rounded-full md:p-2"
          :class="{ 'bg-gray-300': !['ready_for_pickup', 'delivered'].includes(currentSubOrder.status),
                    'bg-lk-green': ['ready_for_pickup', 'delivered'].includes(currentSubOrder.status) }"
        >
          <p
            v-if="showReadyForPickupStatus || currentSubOrder.status === 'ready_for_pickup' || !isMobileOrTablet"
            @mouseleave="showReadyForPickupStatus = false"
            @click="showReadyForPickupStatus = false"
          >
            Listo para retirar
          </p>
          <p
            v-else
            class="shrink-0 px-1.5"
            @mouseover="showReadyForPickupStatus = true"
          >
            L
          </p>
        </div>
        <div
          class="w-full border border-b"
          :class="{ 'border-gray-300': currentSubOrder.status !== 'delivered',
                    'border-lk-green': currentSubOrder.status === 'delivered' }"
        />
        <div
          class="shrink-0 p-2 text-white rounded-full md:p-2"
          :class="{ 'bg-gray-300': currentSubOrder.status !== 'delivered',
                    'bg-lk-green': currentSubOrder.status === 'delivered' }"
        >
          <p
            v-if="showDeliveredStatus || currentSubOrder.status === 'delivered' || !isMobileOrTablet"
            @mouseleave="showDeliveredStatus = false"
            @click="showDeliveredStatus = false"
          >
            Retirado
          </p>
          <p
            v-else
            class="shrink-0 px-1.5"
            @mouseover="showDeliveredStatus = true"
          >
            R
          </p>
        </div>
      </template>
      <template v-else>
        <div
          class="w-full border border-b"
          :class="{ 'border-gray-300': !shippingDetailsUploaded,
                    'border-lk-green': shippingDetailsUploaded }"
        />
        <div
          class="shrink-0 p-2 text-white rounded-full md:p-2"
          :class="{ 'bg-gray-300': !shippingDetailsUploaded,
                    'bg-lk-green': shippingDetailsUploaded }"
        >
          <p
            v-if="showShippingDetailsUploadedStatus || !isMobileOrTablet"
            @mouseleave="showShippingDetailsUploadedStatus = false"
            @click="showShippingDetailsUploadedStatus = false"
          >
            Detalles de envío
          </p>
          <p
            v-else
            class="shrink-0 px-1.5"
            @mouseover="showShippingDetailsUploadedStatus = true"
          >
            D
          </p>
        </div>
        <div
          class="w-full border border-b"
          :class="{ 'border-gray-300': !['delivery_in_progress', 'delivered'].includes(currentSubOrder.status),
                    'border-lk-green': ['delivery_in_progress', 'delivered'].includes(currentSubOrder.status) }"
        />
        <div
          class="shrink-0 p-2 text-white rounded-full md:p-2"
          :class="{ 'bg-gray-300': !['delivery_in_progress', 'delivered'].includes(currentSubOrder.status),
                    'bg-lk-green': ['delivery_in_progress', 'delivered'].includes(currentSubOrder.status) }"
        >
          <p
            v-if="showDeliveryInProgressStatus || currentSubOrder.status === 'delivery_in_progress' ||
              !isMobileOrTablet"
            @mouseleave="showDeliveryInProgressStatus = false"
            @click="showDeliveryInProgressStatus = false"
          >
            En despacho
          </p>
          <p
            v-else
            class="shrink-0 px-1.5"
            @mouseover="showDeliveryInProgressStatus = true"
          >
            E
          </p>
        </div>
        <div
          class="w-full border border-b"
          :class="{ 'border-gray-300': currentSubOrder.status !== 'delivered',
                    'border-lk-green': currentSubOrder.status === 'delivered' }"
        />
        <div
          class="shrink-0 p-2 text-white rounded-full md:p-2"
          :class="{ 'bg-gray-300': currentSubOrder.status !== 'delivered',
                    'bg-lk-green': currentSubOrder.status === 'delivered' }"
        >
          <p
            v-if="showDeliveredStatus || currentSubOrder.status === 'delivered' || !isMobileOrTablet"
            @mouseleave="showDeliveredStatus = false"
            @click="showDeliveredStatus = false"
          >
            Entregado
          </p>
          <p
            v-else
            class="shrink-0 px-1.5"
            @mouseover="showDeliveredStatus = true"
          >
            E
          </p>
        </div>
      </template>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { inject, ref, computed } from 'vue';
import { useIntersectionObserver } from '@vueuse/core';

const props = defineProps<{
  subOrder: SubOrder,
}>();

const currentSubOrder = computed(() => props.subOrder);

const stickyElement = ref(null);
const isStickyElement = ref(false);
useIntersectionObserver(
  stickyElement,
  ([entry]) => {
    isStickyElement.value = entry.intersectionRatio < 1;
  },
  { threshold: 1 },
);

const isMobileOrTablet = inject('isMobileOrTablet');

const shippingDetailsUploaded = computed(() => {
  if (currentSubOrder.value?.shipmentMethod === 'maker') {
    return !!currentSubOrder.value?.makerShipmentDetailId;
  } else if (currentSubOrder.value?.shipmentMethod === 'lokal') {
    return !!currentSubOrder.value?.shipmentDetailId;
  } else if (currentSubOrder.value?.shipmentMethod === 'shop_pickup') {
    return true;
  }

  return false;
},
);

const showPendingStatus = ref(currentSubOrder.value.status === 'pending');
const showConfirmedStatus = ref(currentSubOrder.value.status === 'confirmed');
const showShippingDetailsUploadedStatus = ref(shippingDetailsUploaded.value &&
  !['delivery_in_progress', 'delivered', 'ready_for_pickup'].includes(currentSubOrder.value.status),
);
const showReadyForPickupStatus = ref(currentSubOrder.value.status === 'ready_for_pickup');
const showDeliveryInProgressStatus = ref(currentSubOrder.value.status === 'delivery_in_progress');
const showDeliveredStatus = ref(currentSubOrder.value.status === 'delivered');
</script>

