<template>
  <div class="flex relative w-full">
    <div
      v-if="isLoading"
      class="flex justify-center items-center w-full"
    >
      <lokal-loading
        :loading="isLoading"
        class="self-center mt-5"
      />
    </div>
    <template v-else>
      <div
        v-if="isMobileOrTablet && menuStore.showMakerAdminMenu"
        class="fixed inset-0 z-10 bg-gray-800/50"
        aria-hidden="true"
        @click="menuStore.showMakerAdminMenu = false"
      />
      <transition
        enter-active-class="transition-transform duration-300"
        enter-from-class="-translate-x-full"
        enter-to-class="translate-x-0"
        leave-active-class="transition-transform duration-300"
        leave-from-class="translate-x-0"
        leave-to-class="-translate-x-full"
      >
        <aside
          v-if="!isMobileOrTablet || menuStore.showMakerAdminMenu"
          id="maker-admin-menu"
          class="overflow-y-auto fixed z-20 shrink-0 p-2 py-5 w-3/5 h-full bg-white shadow-md sm:w-52 md:relative md:top-0 lg:w-60"
        >
          <div class="flex sticky top-0 flex-col w-full">
            <button
              v-if="isMobileOrTablet"
              class="ml-auto"
              @click="menuStore.showMakerAdminMenu = false"
            >
              <XMarkIcon
                class="w-5 h-5 text-gray-700"
              />
            </button>
            <div class="flex flex-col items-center mb-3">
              <img
                :src="maker.logoImage?.derivatives['jpgSm']?.url || greenKImage"
                class="object-cover shrink-0 w-24 h-24 rounded-full"
              >
            </div>
            <div class="flex flex-col p-2 mb-5">
              <div
                v-for="option, key in options"
                :key="key"
                class="flex flex-col shrink-0 py-2 w-full text-sm text-left md:text-base"
              >
                <button
                  class="flex items-center"
                  :disabled="key === currentOptionKey && withSubOptions(key)"
                  @click="setCurrentOptionKey(
                    key, option['subOptions'] ? Object.keys(option['subOptions'])[0] : '',
                    !withMoreThanOneSubOption(key)
                  )"
                >
                  <div
                    :class="{
                      'border-lk-green': key === currentOptionKey,
                      'border-gray-200': key === currentOptionKey && withSubOptions(key),
                      'border-transparent': key !== currentOptionKey }"
                    class="mr-3 h-6 border-2"
                  />
                  <span
                    class="hover:font-semibold"
                    :class="{ 'font-semibold': key === currentOptionKey }"
                  >
                    {{ option['name'] }}
                  </span>
                  <ChevronDownIcon
                    v-if="withSubOptions(key)"
                    class="shrink-0 ml-auto w-4 h-4"
                    :class="{ 'rotate-180 transform duration-150 ease-linear': key === currentOptionKey }"
                  />
                </button>
                <div
                  v-if="key === currentOptionKey && withSubOptions(key)"
                  class="flex flex-col"
                >
                  <button
                    v-for="subOptionName, subOrderKey in option['subOptions']"
                    :key="subOrderKey"
                    class="flex items-center"
                    @click="setCurrentOptionKey(key, subOrderKey, true)"
                  >
                    <div
                      :class="{ 'border-lk-green': subOrderKey === currentSubOptionKey,
                                'border-gray-200': subOrderKey !== currentSubOptionKey }"
                      class="mr-3 h-7 border-2"
                    />
                    <span
                      class="ml-2 font-light text-left"
                    >
                      {{ subOptionName }}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </aside>
      </transition>
      <div class="flex flex-col items-center py-3 w-full h-full bg-gray-50 sm:py-10 lg:py-10">
        <button
          v-if="isMobileOrTablet"
          class="flex items-center self-start px-2 mb-3 text-base"
          @click="menuStore.showMakerAdminMenu = !menuStore.showMakerAdminMenu"
        >
          <span class="mr-1">Menu</span>
          <ChevronRightIcon class="shrink-0 w-4 h-4" />
        </button>
        <template v-if="currentOptionKey === 'metrics' && maker?.id">
          <maker-metrics
            v-if="currentSubOptionKey === 'general' && maker?.id"
            :maker="maker"
          />
          <maker-compared-metrics
            v-if="currentSubOptionKey === 'compared' && maker?.id"
            :maker="maker"
          />
          <maker-shop-metrics-list
            v-if="currentSubOptionKey === 'rotation' && maker?.id"
            :maker="maker"
          />
        </template>
        <template v-if="currentOptionKey === 'maker' && maker?.id">
          <maker-account-details
            v-if="currentSubOptionKey === '' || currentSubOptionKey === 'public'"
            :maker="maker"
            @refetch-maker="async () => await makerQuery.refetch.value()"
          />
          <maker-account-private
            v-if="currentSubOptionKey === 'private'"
            :maker="maker"
          />
          <maker-addresses
            v-if="currentSubOptionKey === 'addresses'"
            :maker="maker"
            @refetch-maker="async () => await makerQuery.refetch.value()"
          />
          <maker-shop-pickup
            v-if="currentSubOptionKey === 'shopPickup'"
            :maker="maker"
            @refetch-maker="async () => await makerQuery.refetch.value()"
          />
        </template>
        <template v-if="currentOptionKey === 'subOrders' && maker?.id">
          <sub-order-list
            v-if="currentSubOptionKey === '' || currentSubOptionKey === 'all'"
            :maker="maker"
            :with-metrics="false"
            :with-recomedation-link="false"
          />
          <maker-pickup-list
            v-if="currentSubOptionKey === 'pickups'"
            :maker="maker"
          />
        </template>
        <template v-if="currentOptionKey === 'products' && maker?.id">
          <makers-product-list
            v-if="currentSubOptionKey === 'all'"
            :maker="maker"
            :with-maker-image="false"
          />
          <maker-collection-list
            v-if="currentSubOptionKey === 'collections'"
            :maker="maker"
          />
        </template>
        <template v-if="currentOptionKey === 'discounts' && maker?.id">
          <makers-free-shipping-list
            v-if="currentSubOptionKey === 'freeShippings'"
            :maker="maker"
          />
          <makers-volume-discount-list
            v-if="currentSubOptionKey === 'volumeDiscounts'"
            :maker="maker"
            :with-maker-image="false"
          />
          <maker-referred-discount
            v-if="currentSubOptionKey === 'referredDiscounts'"
            :maker="maker"
          />
        </template>
        <maker-refer
          v-if="currentOptionKey === 'refer' && maker?.id"
          :maker="maker"
        />
        <maker-contacts
          v-if="currentOptionKey === 'clients' && maker?.id"
          :maker="maker"
        />
        <maker-sub-order-payment-list
          v-if="currentOptionKey === 'payments' && maker?.id"
          :maker="maker"
        />
        <maker-shipping-invoice-list
          v-if="currentOptionKey === 'shippingInvoices' && maker?.id"
          :maker="maker"
        />
        <maker-shipping-quotation-list
          v-if="currentOptionKey === 'shippingQuotations' && maker?.id"
          :maker="maker"
        />
        <maker-follow-up-list
          v-if="currentOptionKey === 'followUps' && maker?.id && false"
          :maker="maker"
        />
        <maker-user-list
          v-if="currentOptionKey === 'users' && maker?.id"
          :maker="maker"
          @update-private-information="setCurrentOptionKey('maker', 'private')"
        />
        <tutorial-list v-if="currentOptionKey === 'tutorials'" />
      </div>
    </template>
  </div>
</template>
<script lang="ts" setup>
import { inject, ref, computed, onBeforeMount } from 'vue';
import { useQuery } from 'vue-query';
import makersApi from 'api/makers';
import { keys } from 'lodash';
import { XMarkIcon, ChevronRightIcon, ChevronDownIcon } from '@heroicons/vue/24/outline';
import useGeneralMenuStore from 'stores/general-menu-store';
import greenKImage from 'assets/images/green-k.jpg';
import SubOrderList from './sub-order-list.vue';
import useUrlMethods from './use/url-methods';
import MakersProductList from './makers-product-list.vue';
import MakerAccountDetails from './maker-account-details.vue';
import MakerUserList from './maker-user-list.vue';
import TutorialList from './tutorial-list.vue';
import MakerRefer from './maker-refer.vue';
import MakerFollowUpList from './maker-follow-up-list.vue';
import MakerContacts from './maker-contacts.vue';
import MakerMetrics from './maker-metrics.vue';
import MakersVolumeDiscountList from './makers-volume-discount-list.vue';
import MakerAccountPrivate from './maker-account-private.vue';
import MakerAddresses from './maker-addresses.vue';
import MakersFreeShippingList from './makers-free-shipping-list.vue';
import MakerPickupList from './maker-pickup-list.vue';
import MakerShippingQuotationList from './maker-shipping-quotation-list.vue';
import MakerShopPickup from './maker-shop-pickup.vue';
import MakerCollectionList from './maker-collection-list.vue';
import makerShippingInvoiceList from './maker-shipping-invoice-list.vue';
import makerReferredDiscount from './maker-referred-discount.vue';
import makerComparedMetrics from './maker-compared-metrics.vue';
import makerShopMetricsList from './maker-shop-metrics-list.vue';
import makerSubOrderPaymentList from './maker-sub-order-payment-list.vue';

interface MakerAccountProps {
  makerId: number
  optionalMakerId?: number
  isSuperAdmin?: boolean
}

const props = withDefaults(defineProps<MakerAccountProps>(), {
  optionalMakerId: undefined,
  isSuperAdmin: false,
});

const menuStore = useGeneralMenuStore();
const isMobileOrTablet = inject('isMobileOrTablet') as boolean;
const makerQuery = useQuery(['makerPrivate', props.makerId], () => makersApi.privateShow(props.makerId));
const isLoading = computed(() => makerQuery.isLoading.value);
const maker = computed(() => makerQuery.data.value?.data.maker as Maker);

const subOrderSubOptions = computed(() => {
  if (maker.value?.id && maker.value.warehousePickupCondition === 'with_pickup') {
    return { all: 'Pedidos', pickups: 'Retiros' };
  }

  return { all: 'Pedidos' };
});
const options = computed(() => ({
  metrics: { name: 'Métricas', subOptions: { general: 'General', compared: 'Comparativa', rotation: 'Rotación' } },
  maker: { name: 'Mi marca', subOptions:
    { public: 'Información pública', private: 'Información privada', addresses: 'Direcciones',
      shopPickup: 'Retiro en bodega' },
  },
  subOrders: { name: 'Pedidos', subOptions: subOrderSubOptions.value },
  products: { name: 'Productos', subOptions: { all: 'Todos', collections: 'Colecciones' } },
  discounts: { name: 'Descuentos', subOptions: { freeShippings: 'Despachos gratis',
    volumeDiscounts: 'Descuentos por volumen', referredDiscounts: 'Descuento referidos' } },
  refer: { name: 'Referir', subOptions: {} },
  clients: { name: 'Clientes', subOptions: {} },
  payments: { name: 'Pago de pedidos', subOptions: {} },
  shippingInvoices: { name: 'Facturas de despacho', subOptions: {} },
  shippingQuotations: { name: 'Costos de despacho', subOptions: {} },
  users: { name: 'Usuarios', subOptions: {} },
  tutorials: { name: 'Tutoriales', subOptions: {} },
}));
const currentOptionKey = ref('metrics');
const currentSubOptionKey = ref('general');
function withSubOptions(option: keyof typeof options.value) {
  return Object.keys(options.value[option].subOptions).length > 0;
}

function withMoreThanOneSubOption(option: keyof typeof options.value) {
  return Object.keys(options.value[option].subOptions).length > 1;
}

const { updateUrlParams, getQueryParam } = useUrlMethods();
function setCurrentOptionKey(key: string, subOrderKey?: string, closeAfterUpdate = false) {
  currentOptionKey.value = key;
  currentSubOptionKey.value = subOrderKey || '';
  updateUrlParams({ tab: key, subTab: currentSubOptionKey.value });
  if (closeAfterUpdate) menuStore.showMakerAdminMenu = false;
}

onBeforeMount(() => {
  const tab = getQueryParam('tab');
  const subTab = getQueryParam('subTab');
  if (keys(options.value).includes(tab)) {
    currentOptionKey.value = tab;
    if (subTab) {
      currentSubOptionKey.value = subTab;
    } else if (withSubOptions(tab as keyof typeof options.value)) {
      currentSubOptionKey.value = Object.keys(options.value[tab as keyof typeof options.value].subOptions)[0];
    }
  }
});
</script>
