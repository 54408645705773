<template>
  <div
    class="flex flex-col items-center p-6 w-full max-w-lg h-full text-gray-700 md:py-10"
    :class="{'mb-10': !inDialog}"
  >
    <template
      v-for="message, index in messages"
      :key="message"
    >
      <lokal-banner-message-dialog
        v-if="message[1]"
        v-model="showBanners[index]"
        :color-key="(message[0] as ColorKeyType)"
        :show-dialog="message[0].includes('dialog')"
        :message="message[1]"
        title="Información importante"
        :class="{ 'mb-5': messages[messages.length - 1] === messages[index] }"
      />
    </template>
    <inline-svg-wrapper
      name="lokal-logo"
      class="object-cover shrink-0 self-center mb-2 w-auto h-14 fill-lk-green"
      :class="{ 'md:h-20': !inDialog, 'md:h-14': inDialog }"
    />
    <template v-if="product?.id && signUpStore.currentStep === 'email'">
      <p class="mb-3 font-serif text-xl text-center text-gray-700 md:text-2xl">
        <template v-if="discountCode?.id">
          Regístrate y obtén <br>
          <span class="text-2xl md:text-4xl">
            ¡{{ discountCode?.totalDiscount }}% DE DESCUENTO!
          </span>
          <br>
          en tu primera compra
          <span v-if="maker?.id">de </span>
          <span
            v-if="maker?.id"
            class="text-lk-green capitalize"
          >
            {{ maker?.name }}
          </span>
        </template>
        <template v-else>
          Revisa el catálogo mayorista de
          <span class="text-lk-green">{{ product.makerName }}</span>
        </template>
      </p>
      <p
        class="mb-5 text-sm font-light text-center text-gray-700 md:text-base"
      >
        Accede a todos los productos de
        <span class="font-semibold">{{ product.makerName }}</span>
        y a cientos productos de todas las marcas presentes en
        <span class="font-serif">Lokal</span>
      </p>
      <div
        v-if="product?.images?.length"
        class="flex mb-4 space-x-2"
      >
        <img
          v-for="image, index in product.images.slice(0, 3)"
          :key="index"
          :src="image?.derivatives['jpgSm']?.url"
          class="object-cover w-20 h-20 rounded-md md:w-28 md:h-28"
        >
      </div>
      <img
        v-else
        :src="useImageUrl(product) ? useImageUrl(product) : greenKImage"
        class="object-cover self-center mb-4 w-20 h-20 rounded-md md:w-28 md:h-28"
      >
    </template>
    <template v-if="!product?.id">
      <p
        v-if="showSignIn || signUpStore.currentStep === 'email'"
        class="mb-2 font-serif text-xl text-center text-gray-700 md:text-2xl"
      >
        <template v-if="discountCode?.id">
          Regístrate y obtén <br>
          <span class="text-2xl md:text-4xl">
            ¡{{ discountCode?.totalDiscount }}% DE DESCUENTO!
          </span>
          <br>
          en tu primera compra
          <span v-if="maker?.id">de </span>
          <span
            v-if="maker?.id"
            class="text-lk-green capitalize"
          >
            {{ maker?.name }}
          </span>
        </template>
        <template v-else>
          Regístrate y revisa el catálogo mayorista
          <span v-if="maker?.id">de </span>
          <span
            v-if="maker?.id"
            class="text-lk-green capitalize"
          >
            {{ maker?.name }}
          </span>
        </template>
      </p>
      <p
        v-if="maker?.id && signUpStore.currentStep === 'email'"
        class="mb-5 text-sm font-light text-center text-gray-700 md:text-sm"
      >
        Accede a todos los productos de
        <span class="font-semibold">{{ maker.name }}</span>
        y a cientos de productos de todas las marcas presentes en
        <span class="font-serif">Lokal</span>
      </p>
      <p
        v-else-if="signUpStore.currentStep === 'email'"
        class="mb-3 text-sm font-light text-center text-gray-700 md:text-base"
      >
        Accede a cientos de
        productos de todas las marcas presentes en <span class="font-serif">Lokal</span>
      </p>
      <img
        v-if="maker?.id && signUpStore.currentStep === 'email'"
        :src="useMakerImageUrl(maker) ? useMakerImageUrl(maker) : greenKImage"
        class="object-cover shrink-0 self-center mb-5 w-20 h-20 rounded-md md:w-28 md:h-28"
      >
    </template>
    <SignIn
      v-if="showSignIn"
      v-model="showSignIn"
      class="mb-5 w-full"
      :class="{ 'sm:min-w-[300px] md:min-w-[450px]': signUpStore.currentPathIncludesShopsSignUp }"
      :user-email="userEmail"
    />
    <SignUpNew
      v-else
      v-model="showSignIn"
      class="w-full"
      :class="{ 'mb-5': showConditions,
                'sm:min-w-[300px] md:min-w-[450px]': signUpStore.currentPathIncludesShopsSignUp }"
    />
    <p
      v-if="showConditions"
      class="mt-auto text-xs font-light text-center"
    >
      Al inscribirte en <span class="font-serif">Lokal</span>, aceptas nuestros
      <a
        href="/terms_of_service"
        class="font-semibold underline"
      >
        términos y condiciones
      </a>.
    </p>
  </div>
</template>
<script lang="ts" setup>
import { ref, computed } from 'vue';
import useSignUpStore from 'stores/sign-up-store';
import { useQuery } from 'vue-query';
import makersApi from 'api/makers';
import greenKImage from 'assets/images/green-k.jpg';
import useDiscountCodeQuery from './queries/discount-code-query';
import SignIn from './sign-in.vue';
import SignUpNew from './sign-up-new.vue';
import type { ColorKeyType } from './shared/lokal-banner.vue';
import useMakerImageUrl from './use/maker-image-url';
import useImageUrl from './use/image-url';
import inlineSvgWrapper from './inline-svg-wrapper.vue';

interface SignSelectorProps {
  initialValueSignIn: boolean
  messages?: string[][]
  inDialog?: boolean
  product?: Product
  userEmail?: string
}

const props = withDefaults(defineProps<SignSelectorProps>(), {
  initialValueSignIn: true,
  messages: () => [['', '']],
  product: () => ({} as Product),
  inDialog: false,
  userEmail: '',
});

const showSignIn = ref(props.initialValueSignIn);

const showBanners = ref({} as { [string: number]: boolean });
props.messages.forEach((_, index) => {
  showBanners.value[index] = true;
});

const signUpStore = useSignUpStore();
const makerSlug = computed(() => signUpStore.userForm.referredSlug);
const makerQuery = useQuery(['maker', makerSlug], () => makersApi.get(makerSlug.value), {
  enabled: computed(() => !!makerSlug.value),
});
const maker = computed(() => makerQuery.data.value?.data.maker);

const showConditions = computed(() => signUpStore.currentStep !== 'success' && !showSignIn.value);

const makerDiscountCode = computed(() => signUpStore.shopForm.registrationDiscountCodeToken);
const discountCodeQueryEnabled = computed(() => !!makerDiscountCode.value);
const { discountCode } = useDiscountCodeQuery(makerDiscountCode, discountCodeQueryEnabled);
</script>
