<template>
  <Popover
    v-slot="{ open }"
    class="relative"
  >
    <div
      v-if="open"
      class="overflow-y-hidden fixed inset-0 z-10 bg-gray-800/50"
      aria-hidden="true"
    />
    <PopoverButton
      class="flex z-10 items-center p-2 text-sm text-lk-green bg-white rounded-md border border-lk-green focus:outline-none md:p-3 md:space-x-2 md:text-base"
      :class="{ 'bg-lk-green text-white': open, 'hover:bg-lk-green hover:text-white': !open }"
    >
      <span class="mr-2">Agregar producto/s</span>
      <ChevronDownIcon
        class="w-4 h-4 md:w-5 md:h-5"
        :class="{ 'rotate-180': open }"
      />
    </PopoverButton>
    <div
      v-if="open"
      class="absolute right-4 z-20 border-8 border-t-0 border-lk-green border-x-transparent border-solid"
    />
    <PopoverPanel class="flex overflow-y-auto absolute right-0 z-10 flex-col py-0 mt-2 w-52 max-h-96 text-sm rounded-md border border-lk-green divide-y divide-lk-green focus:outline-none origin-top-right">
      <a
        class="flex items-center p-2 hover:font-semibold text-lk-green hover:text-white bg-white hover:bg-lk-green md:py-3"
        :href="`/makers/${maker.id}/products/new`"
        target="_blank"
        noopener
      >
        <PlusIcon
          class="shrink-0 w-4 h-4"
        />
        <span class="ml-auto">Agregar un producto</span>
      </a>
      <button
        class="flex items-center p-2 hover:font-semibold text-lk-green hover:text-white bg-white hover:bg-lk-green md:py-3"
        @click="showFileUploaderDialog = !showFileUploaderDialog"
      >
        <PlusIcon
          class="shrink-0 w-4 h-4"
        />
        <PlusIcon
          class="shrink-0 w-4 h-4"
        />
        <span class="ml-auto">Agregar varios productos</span>
      </button>
    </PopoverPanel>
    <upload-products-from-file-dialog
      v-model="showFileUploaderDialog"
      :maker="maker"
    />
  </Popover>
</template>
<script lang="ts" setup>
import { ref } from 'vue';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import { ChevronDownIcon, PlusIcon } from '@heroicons/vue/24/outline';
import uploadProductsFromFileDialog from './shared/upload-products-from-file-dialog.vue';

defineProps<{
  maker: Maker
}>();

const showFileUploaderDialog = ref(false);
</script>
