<template>
  <div class="flex flex-col py-3 px-5 mb-10 w-full max-w-2xl rounded md:py-4 md:px-10 lg:px-0">
    <p class="mb-5 font-serif text-2xl text-center text-gray-700 md:mb-8 md:text-3xl">
      Hemos recibido correctamente tu orden
    </p>
    <div class="flex items-center mb-3">
      <a
        href=""
        class="text-sm"
      >
        Método de pago
      </a>
      <ChevronRightIcon class="w-4 h-4 text-gray-700/60" />
      <a
        href=""
        class="text-sm text-gray-700/60"
      >
        Confirmación
      </a>
    </div>
    <div class="p-4 mb-5 border">
      <p class="mb-2 font-serif text-xl">
        Resumen de la orden
      </p>
      <p class="flex flex-col text-sm font-light text-left md:text-lg">
        <span class="">Costo del despacho: {{ useFormatCurrency(order.shippingCostAmountCents) }}</span>
        <span class="py-2 border-b-2">
          Costo de los productos: {{ useFormatCurrency(order.totalWholesaleAmountCents) }}
        </span>
        <span class="pt-2 font-normal">Monto total: {{ useFormatCurrency(totalCostWithShipping) }}</span>
      </p>
    </div>
    <form
      class="flex flex-col"
      @submit.prevent="onSubmit"
    >
      <csfr-input />
      <form-radio-inputs
        v-model="payment"
        class="mb-5"
        name="payment"
        label="Selecciona un método de pago"
        :values="['payLater', 'payNow']"
        :radio-labels="[payLaterMessage, payNowMessage]"
        custom-radio-label-classes="p-3 md:p-4 mb-2 text-sm md:text-base flex space-x-2 items-start border-t"
        rules="required"
      />
      <form-radio-inputs
        v-if="payLater"
        v-model="payLaterOption"
        class="mb-5"
        name="payLaterOption"
        label="¿Cómo quieres pagar?"
        :values="['transferLater']"
        :radio-labels="[transferLaterMessage]"
        custom-radio-label-classes="p-3 md:p-4 mb-2 text-sm md:text-base flex space-x-2 items-start border-t"
        rules="required"
      />
      <form-radio-inputs
        v-else
        v-model="payNowOption"
        class="mb-5"
        name="payNowOption"
        label="¿Cómo quieres pagar?"
        :values="['transferNow']"
        :radio-labels="[transferNowMessage]"
        custom-radio-label-classes="p-3 md:p-4 mb-2 text-sm md:text-base flex space-x-2 items-start border-t"
        rules="required"
      />
      <div
        v-if="!payLater && payNowWithTransfer"
        class="mb-5 text-sm font-light text-gray-700 md:text-base"
      >
        <div class="p-3 mb-3 rounded-md border md:p-5">
          <button
            class="flex justify-between items-center w-full text-base text-left md:text-xl"
            :class="{ 'mb-3': showTransferInformation }"
            type="button"
            @click="showTransferInformation = !showTransferInformation"
          >
            <p>
              Estos son los datos para pagar con tranferencia electrónica
            </p>
            <ChevronDownIcon
              class="w-6 h-6"
              :class="{ 'rotate-180': showTransferInformation }"
            />
          </button>
          <lokal-transfer-payment-information v-if="showTransferInformation" />
        </div>
      </div>
      <lokal-button
        v-if="!(transbankResponse?.data && payLater && payLaterWithRegisterCard) && !loading"
        :action-name="nextActionButtonName"
        size="normal"
        class="self-center w-full md:w-1/2"
      />
      <lokal-loading
        v-if="loading"
        size="small"
        class="self-center"
      />
    </form>
    <form
      v-if="transbankResponse?.data && payLater && payLaterWithRegisterCard"
      class="flex flex-col"
      method="post"
      :action="transbankResponse.data.urlWebpay"
    >
      <input
        type="hidden"
        name="TBK_TOKEN"
        :value="transbankResponse.data.token"
      >
      <lokal-button
        action-name="Registrar tarjeta"
        type="submit"
        size="normal"
        class="self-center w-full md:w-1/2"
        @click="updatePaymentMethod"
      />
    </form>
    <lokal-message-dialog
      v-if="showCardRegistrationDialog"
      v-model="showCardRegistrationDialog"
      message="Hemos registrado exitosamente tu tarjeta. Te cobraremos 60 días después de que recibas los productos."
      title="Registro exitoso"
      @accept-message="redirectToOrder"
    />
    <lokal-message-dialog
      v-if="errorPresent"
      v-model="errorPresent"
      :message="errorMessage"
      title="Hubo un error registrando tu tarjeta"
      @accept-message="deleteParam"
    />
  </div>
</template>
<script lang=ts setup>
import { computed, onMounted, ref, watch } from 'vue';
import { useForm } from 'vee-validate';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/vue/24/solid';
import { snakeCase } from 'lodash';
import useSessionStore from 'stores/user-store';
import shopsApi from 'api/shops';
import transbankApi from 'api/transbank';
import ordersApi from 'api/orders';
import LokalTransferPaymentInformation from './shared/lokal-transfer-payment-information.vue';
import useFormatCurrency from './use/format-currency';
import FormRadioInputs from './shared/form-radio-inputs.vue';
import CsfrInput from './shared/csrf-input.vue';
import LokalMessageDialog from './shared/lokal-message-dialog.vue';

interface OrderNewPaymentProps {
  order: Order
}
const props = withDefaults(defineProps<OrderNewPaymentProps>(), {
});

const totalCostWithDiscount = computed(() => props.order.totalWholesaleAmountCents - props.order.totalDiscountAmount);
const totalCostWithShipping = computed(() => totalCostWithDiscount.value + props.order.shippingCostAmountCents);

const showTransferInformation = ref(false);

const payment = ref('payLater');
const payLater = computed(() => payment.value === 'payLater');
const payLaterOption = ref('transferLater');
const payLaterWithRegisterCard = computed(() => payLaterOption.value === 'registerCard');
const payNowOption = ref('transferNow');
const payNowWithTransfer = computed(() => payNowOption.value === 'transferNow');
const paymentMethod = computed(() => (payLater.value ? payLaterOption.value : payNowOption.value));
const nextActionButtonName = computed(() => {
  if (payLater.value) {
    if (payLaterWithRegisterCard.value) {
      return 'Registrar tarjeta';
    }

    return 'Confirmar orden de compra';
  }
  if (payNowWithTransfer.value) {
    return 'Confirmar orden de compra';
  }

  return 'Solicitar link de pago';
});

const loading = ref(false);
const showCardRegistrationDialog = ref(false);
const errorPresent = ref(false);
const sessionStore = useSessionStore();
const user = computed(() => sessionStore.user);
const transbankResponse = ref();
const link = computed(() => new URLSearchParams(window.location.search));
const tbkToken = computed(() => link.value.get('TBK_TOKEN'));
function deleteParam() {
  window.history.pushState({}, '', window.location.pathname);
}
function redirectToOrder() {
  window.location.pathname = `/orders/${props.order.id}`;
}

async function updatePaymentMethod() {
  return await ordersApi.update(props.order.id, { paymentMethod: snakeCase(paymentMethod.value) });
}

const errorMessage = ref('');
async function confirmInscription(token: string) {
  loading.value = true;
  try {
    await transbankApi.confirmInscription({ token });
    showCardRegistrationDialog.value = true;
    deleteParam();
  } catch (e) {
    errorMessage.value = `No pudimos registrar correctamente tu tarjeta. Intenta registrándola nuevamente,
    si el error persiste contáctanos :)`;
    errorPresent.value = true;
  } finally {
    loading.value = false;
  }
}
// eslint-disable-next-line max-statements
onMounted(async () => {
  await updatePaymentMethod();
  const shopResponse = await shopsApi.get(props.order.shopId);
  const shop = shopResponse.data.shop as Shop;
  if (shop?.id && shop.kind !== 'physical_store') payment.value = 'payNow';
  if (tbkToken.value) {
    confirmInscription(tbkToken.value);
    sessionStore.reloadUser();
  } else {
    const data = {
      username: `${user.value.firstName}_${user.value.lastName}_${user.value.id}`,
      email: user.value.email, responseUrl: `${process.env.APPLICATION_HOST}/orders/${props.order.id}/payment`,
    };
    transbankResponse.value = await transbankApi.createToken(data);
  }
});

const { handleSubmit } = useForm();
const onSubmit = handleSubmit(async () => {
  loading.value = true;
  await updatePaymentMethod();
  window.location.pathname = `/orders/${props.order.id}`;
  loading.value = false;
});

function usePaymentMessages() {
  const payLaterMessage = `
    <p class="flex flex-col">
      <span class="font-semibold">Pagar a 60 días</span>
      <span class="font-light">
        Podrás pagar hasta 60 días después de que recibas los productos. Solo válido para tiendas físicas.
      </span>
    </p>
  `;
  const payNowMessage = `
    <p class="flex flex-col">
      <span class="font-semibold">Pagar hoy</span>
      <span class="font-light">Deberás pagar la totalidad de la compra para confirmar la orden.</span>
    </p>
  `;
  const registerCardMessage = `
    <p class="flex flex-col">
      <span class="font-semibold">Con tarjeta a través de Transbank</span>
      <span class="font-light">
        Te cobraremos a la tarjeta registrada 60 días después de que recibas los productos.
      </span>
    </p>
  `;
  const transferLaterMessage = `
    <p class="flex flex-col">
      <span class="font-semibold">Con una transferencia electrónica</span>
      <span class="font-light">A los 60 días deberás realizar una transferencia por el total de la orden.</span>
    </p>
  `;
  const cardNowMessage = `
    <p class="flex flex-col">
      <span class="font-semibold">Con tarjeta de débito o crédito</span>
      <span class="font-light">
        Te enviaremos un link de mercado pago por whatsapp para que puedas realizar el pago
        con tu tarjeta de débito o crédito.
      </span>
    </p>
  `;
  const transferNowMessage = `
    <p class="flex flex-col">
      <span class="font-semibold">Con una transferencia electrónica</span>
      <span class="font-light">
        Deberás transferir a nuestra cuenta el monto total para poder terminar
        de procesar la orden.
      </span>
    </p>
  `;

  return {
    payLaterMessage,
    payNowMessage,
    registerCardMessage,
    transferLaterMessage,
    cardNowMessage,
    transferNowMessage,
  };
}

const {
  payLaterMessage,
  payNowMessage,
  transferLaterMessage,
  transferNowMessage,
} = usePaymentMessages();
</script>
