<template>
  <TransitionRoot
    :show="modelValue"
    as="template"
    enter="duration-300 ease-out"
    enter-from="opacity-0"
    enter-to="opacity-100"
  >
    <Dialog
      as="div"
      class="relative z-40"
      @close="emit('update:modelValue', false)"
    >
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
      >
        <div
          class="fixed inset-0 bg-black/30"
          aria-hidden="true"
        />
      </TransitionChild>
      <div class="flex fixed inset-0 justify-center items-center px-2 md:px-0">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
        >
          <DialogPanel class="flex overflow-y-auto flex-col py-2 px-3 w-full max-w-lg max-h-[650px] text-gray-700 bg-gray-50 rounded resize md:p-4">
            <button
              class="mb-2 ml-auto focus:outline-none"
              @click="emit('update:modelValue', false)"
            >
              <XMarkIcon class="w-5 h-5 fill-gray-700 md:w-6 md:h-6" />
            </button>
            <h3 class="mb-3 text-lg md:text-xl">
              Propuesta de modificación de pedido
            </h3>
            <p class="mb-3 text-sm md:text-base">
              Si no puedes completar el pedido original puedes modificarlo aquí para hacerle una
              propuesta a la tienda.
            </p>
            <lokal-loading
              v-if="productsQueryIsLoading"
              size="xs"
              :loading="productsQueryIsLoading"
            />
            <lokal-generic-combobox
              v-else
              v-model="selectedProducts"
              class="mb-2 text-xs md:text-sm"
              :class="{ 'border-2 p-2 border-lk-green rounded-md': addProductsVisualAction }"
              :options="products"
              :get-option-label="(option: Product) => option.name"
              :get-option-key="(option: Product) => `${option.id}`"
              :placeholder="'Escribe el nombre del producto'"
              :multiple="true"
              :options-label="'Recomendar alternativas de productos'"
            >
              <template
                v-for="product in products"
                :key="product.id"
                #[`custom-option-${product.id}`]="{ active, selected, option, getOptionLabel }"
              >
                <li
                  class="flex items-center p-2 space-x-1 cursor-pointer md:space-x-2"
                  :class="{ 'bg-lk-green text-white': active || selected }"
                >
                  <CheckIcon
                    class="object-cover shrink-0 w-4 h-4 md:w-5 md:h-5"
                    :class="{ 'hidden': !selected, 'text-white': selected }"
                  />
                  <img
                    :src="product.images?.[0]?.derivatives?.jpgSm?.url"
                    class="shrink-0 w-8 h-8"
                  >
                  <span>{{ getOptionLabel(option) }}</span>
                </li>
              </template>
            </lokal-generic-combobox>
            <form
              class="flex flex-col w-full text-xs md:text-sm"
              @submit="onSubmit"
            >
              <div class="flex overflow-y-auto flex-col w-full max-h-[350px]">
                <table
                  class="mb-1 bg-white rounded-md border"
                  :class="{ 'blur-md': addProductsVisualAction }"
                >
                  <thead class="p-2 border">
                    <tr class="">
                      <th class="p-2">
                        Imgaen
                      </th>
                      <th class="p-2">
                        Producto
                      </th>
                      <th class="p-2">
                        Cantidad
                      </th>
                      <th class="p-2">
                        Reposición en
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <sub-order-modifications-card
                      v-for="product in selectedProducts"
                      :key="product.id"
                      v-model="newQuantitiesForm"
                      :main-image-url="product.images?.[0]?.derivatives?.jpgSm?.url"
                      :product-name="product.name"
                      :original-quantity="1"
                      :unique-id="product.id"
                      :sub-order="subOrder"
                      :maker="maker"
                      :product-id="product.id"
                      :new-product="true"
                    />
                    <sub-order-modifications-card
                      v-for="subOrderProduct in subOrderProducts"
                      :key="subOrderProduct.id"
                      v-model="newQuantitiesForm"
                      :main-image-url="subOrderProduct.mainImageUrl"
                      :product-name="subOrderProduct.productName"
                      :original-quantity="subOrderProduct.quantity"
                      :unique-id="subOrderProduct.id"
                      :sub-order="subOrder"
                      :maker="maker"
                      :product-id="subOrderProduct.productId"
                    />
                  </tbody>
                </table>
                <p
                  class="text-xs md:text-base"
                >
                  NOTA: Si cambias la cantidad de productos, te recomendamos proponer nuevos productos
                  para no disminuir la venta.
                </p>
              </div>
              <Field
                v-model="computedQuantitiesKeys"
                name="atLeastOneChange"
                :rules="{ required: true, min_value: 1 }"
                hidden
              />
              <ErrorMessage
                name="atLeastOneChange"
                class="mt-1 text-xs text-center text-red-500 md:text-sm"
                as="p"
              >
                Debes modificar o agregar al menos un producto.
              </ErrorMessage>
              <lokal-loading
                v-if="createSubOrderProductModificationsIsLoading"
                :loading="createSubOrderProductModificationsIsLoading"
                class="self-center"
              />
              <template v-else>
                <button
                  v-if="selectedProducts.length === 0"
                  type="button"
                  class="p-1 mt-3 mb-2 text-sm text-lk-green hover:text-white bg-white hover:bg-lk-green rounded-md border border-lk-green md:p-1.5 md:text-base"
                  @click="addProductsVisualAction = !addProductsVisualAction"
                >
                  <span v-if="addProductsVisualAction">
                    Cancelar
                  </span>
                  <span v-else>
                    Recomendar alternativa de productos
                  </span>
                </button>
                <button
                  v-if="!addProductsVisualAction"
                  type="submit"
                  class="p-1 text-sm text-lk-green hover:text-white bg-white hover:bg-lk-green rounded-md border border-lk-green md:p-1.5 md:text-base"
                >
                  Crear propuesta
                </button>
              </template>
            </form>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<script lang="ts" setup>
import { ref, computed, watch } from 'vue';
import { XMarkIcon, CheckIcon } from '@heroicons/vue/24/solid';
import {
  Dialog,
  DialogPanel,
  TransitionRoot,
  TransitionChild,
} from '@headlessui/vue';
import { keys } from 'lodash';
import { useQuery, useMutation } from 'vue-query';
import productsApi from 'api/products';
import { useForm, Field, ErrorMessage } from 'vee-validate';
import subOrderProductModificationsApi from 'api/sub-order-product-modifications';
import useMessageStore from 'stores/message-store';
import subOrderModificationsCard from './sub-order-modifications-card.vue';
import lokalGenericCombobox from './shared/lokal-generic-combobox.vue';

const MAX_PRODUCTS = 150;

interface SubOrderAdvancePaymentDialogProps {
  modelValue: boolean
  maker: Maker
  subOrder: SubOrder
  subOrderProducts: SubOrderProduct[]
}
const props = withDefaults(defineProps<SubOrderAdvancePaymentDialogProps>(), {
  modelValue: false,
});

const emit = defineEmits<{(e: 'update:modelValue', value: boolean): void,
  (e: 'update:subOrder'): void
}>();

const messageStore = useMessageStore();

const addProductsVisualAction = ref(false);

const selectedProducts = ref([] as Product[]);
const productsQueryParams = computed(() => {
  let queryParams = `q[maker_id_eq]=${props.maker.id}&q[s]=maker_priority asc`;
  props.subOrderProducts.forEach((subOrderProduct) => {
    queryParams += `&q[id_not_in][]=${subOrderProduct.productId}`;
  });

  return queryParams;
},
);
const productsQuery = useQuery(['products', props.maker.id],
  () => productsApi.active(1, productsQueryParams.value, MAX_PRODUCTS),
  { enabled: computed(() => !!props.maker?.id), keepPreviousData: true });
const productsQueryIsLoading = computed(() => productsQuery.isLoading.value);
const products = computed(() => productsQuery.data.value?.data.products as Product[]);

const newQuantitiesForm = ref({} as Record<string, Record<string, number>>);
const computedQuantitiesKeys = computed(() => keys(newQuantitiesForm.value)?.length);

const createSubOrderProductModifications = useMutation(
  () => {
    const data = Object.values(newQuantitiesForm.value);

    return subOrderProductModificationsApi.bulkCreate(data);
  },
  {
    onSuccess: () => {
      messageStore.showMessage('Propuesta creada correctamente.', 'success');
      emit('update:subOrder');
      emit('update:modelValue', false);
    },
  },
);
const createSubOrderProductModificationsIsLoading = computed(() => createSubOrderProductModifications.isLoading.value);

const { handleSubmit } = useForm();
const onSubmit = handleSubmit(() => {
  createSubOrderProductModifications.mutate();
});

watch(selectedProducts, () => {
  selectedProducts.value.forEach((product) => {
    if (!props.subOrderProducts.some((subOrderProduct) => subOrderProduct.productId === product.id)) {
      addProductsVisualAction.value = false;
    }
  });
});
</script>

