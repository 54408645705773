import { csrfToken } from '@rails/ujs';
import { ref } from 'vue';
import api from './index';

const token = ref(csrfToken());
let headers = {};
if (token.value) {
  headers = { 'X-CSRF-Token': token.value };
}

interface UserCreationParams extends Partial<User> {
  password: string
}

export default {
  index(queryParams: string) {
    const path = `/api/internal/users?${queryParams}`;

    return api({
      method: 'get',
      url: path,
      headers,
    });
  },
  create(user: UserCreationParams) {
    const path = '/api/internal/users';

    return api({
      method: 'post',
      url: path,
      data: { user },
      headers,
    });
  },
  validateEmail(email: string) {
    const encondeEmail = encodeURIComponent(email);
    const path = `/api/internal/users/validate_email?email=${encondeEmail}`;

    return api({
      method: 'get',
      url: path,
      headers,
    });
  },
  get(userId: number) {
    const path = `/api/internal/users/${userId}`;

    return api({
      method: 'get',
      url: path,
      headers,
    });
  },
  referred(makerSlug: string) {
    const path = `/api/internal/users/referred?maker_slug=${makerSlug}`;

    return api({
      method: 'get',
      url: path,
      headers,
    });
  },
  sendMagicLink(email: string) {
    const path = '/api/internal/users/send_magic_link';

    return api({
      method: 'post',
      url: path,
      data: { email },
      headers,
    });
  },
};
