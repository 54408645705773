<template>
  <Combobox
    :model-value="modelValue"
    :disabled="disabled"
    @update:model-value="(value) => emit('update:modelValue', value)"
  >
    <div
      class="flex flex-col w-full"
      :class="[textSize]"
    >
      <div
        class="flex items-center p-2 bg-white rounded-md border border-lk-light-gray"
        :class="{ 'bg-gray-100 opacity-50': disabled }"
      >
        <ComboboxInput
          class="mr-2 w-full focus:outline-none"
          :class="{ 'bg-gray-100': disabled }"
          :placeholder="optionsLabel"
          autofocus
          :display-value="() => (modelValue[optionLabel] as string)"
          @change="searchText = $event.target.value"
        />
        <ComboboxButton>
          <ChevronDownIcon
            class="w-5 h-5 text-gray-400"
            aria-hidden="true"
          />
        </ComboboxButton>
      </div>
      <div class="relative">
        <ComboboxOptions
          class="flex overflow-y-auto absolute left-0 z-10 flex-col mt-2 w-full max-h-96 text-gray-700 bg-white rounded-md border border-lk-light-gray focus:outline-none"
        >
          <template v-if="filteredOptions.length && filteredOptions.length > 0">
            <ComboboxOption
              v-for="option in filteredOptions"
              :key="option[keyOption]"
              v-slot="{ active, selected }"
              :value="option"
              as="template"
            >
              <li
                class="flex justify-between items-center p-2 cursor-pointer"
                :class="{ 'bg-lk-green text-white': active | selected }"
              >
                {{ option[optionLabel] }}
              </li>
            </ComboboxOption>
          </template>
          <ComboboxOption
            v-else
            class="flex justify-between items-center p-2 cursor-pointer"
          >
            No hay opciones
          </ComboboxOption>
        </ComboboxOptions>
      </div>
    </div>
  </Combobox>
</template>
<script lang="ts" setup>
// THIS IS A LEGACY COMPONENT, INSTEAD USE THE GENERIC ONE
import { ref, watch } from 'vue';
import { debounce } from 'lodash';
import { ChevronDownIcon } from '@heroicons/vue/24/solid';
import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
  ComboboxButton,
} from '@headlessui/vue';

export type OptionRecord = Record<string | number, string | number>;

interface ProductSingleComboboxProps {
  options: OptionRecord[],
  optionsLabel: string,
  optionLabel: string,
  keyOption: string,
  modelValue: OptionRecord,
  localSearch?: boolean,
  textSize?: string
  disabled?: boolean
}

const props = withDefaults(defineProps<ProductSingleComboboxProps>(), {
  localSearch: true,
  textSize: 'text-sm md:text-base',
  disabled: false,
});

const emit = defineEmits<{(e: 'update:modelValue', value: OptionRecord): void,
  (e: 'button-pressed'): void, (e: 'update:options'): void }>();

const searchText = ref('');
const filteredOptions = ref(props.options);

function clearAccents(text: string): string {
  return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

function searchForRecords() {
  if (props.localSearch) {
    if (searchText.value.length > 0) {
      const lowerSearchText = clearAccents(searchText.value.toLowerCase());
      filteredOptions.value = props.options.filter((option) => {
        const optionName = clearAccents(`${option[props.optionLabel]}`.toLowerCase());

        return optionName.includes(lowerSearchText);
      });
    } else {
      filteredOptions.value = props.options;
    }
  } else {
    emit('update:options');
  }
}

const DEBOUNCE_TIME = 200;
const debounceIsTyping = debounce(() => {
  searchForRecords();
}, DEBOUNCE_TIME);
const debounceSearchTextClear = debounce(() => {
  searchText.value = '';
}, DEBOUNCE_TIME);

watch(searchText, () => {
  debounceIsTyping();
});

watch(filteredOptions, () => {
  if (filteredOptions.value.length === 0) {
    debounceSearchTextClear();
  }
});

watch(() => props.options, () => {
  filteredOptions.value = props.options;
});
</script>
