import { computed, type ComputedRef, type Ref } from 'vue';
import { useQuery } from 'vue-query';
import makersApi from 'api/makers';
import useCustomParseInt from 'components/use/custom-parse-int';

const DEFAULT_MAKERS_PER_PAGE = 24;

export default function useActiveMakersQuery(currentPage: Ref<number>, queryParams: ComputedRef<string>,
  enabled = computed(() => false), pageSize = computed(() => DEFAULT_MAKERS_PER_PAGE)) {
  const makersQuery = useQuery(['makers', currentPage, queryParams],
    () => makersApi.active(currentPage.value, queryParams.value, pageSize.value),
    { enabled, refetchOnWindowFocus: false, keepPreviousData: true },
  );
  const makers = computed(() => makersQuery.data.value?.data.makers as Maker[]);
  const makersQueryIsLoading = computed(() => makersQuery.isLoading.value || makersQuery.isPreviousData.value);
  const totalResources = computed(() => useCustomParseInt(makersQuery.data.value?.headers['x-total']));
  const resourcesPerPage = computed(() => useCustomParseInt(makersQuery.data.value?.headers['x-per-page']));
  const totalPages = computed(() => Math.ceil(totalResources.value / resourcesPerPage.value));

  return { makers, makersQuery, makersQueryIsLoading, totalResources, resourcesPerPage, totalPages };
}
