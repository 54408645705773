<template>
  <lokal-loading
    v-if="addressesQueryIsLoading"
    :loading="addressesQueryIsLoading"
  />
  <div
    v-else
    class="flex flex-col"
  >
    <button
      v-if="addresses.length === 0 && !showNewAddressComponent"
      type="button"
      class="flex justify-center items-center p-2 text-sm text-white bg-lk-green hover:bg-lk-green-deep rounded-md"
      @click="addNewAdrress"
    >
      <PlusIcon class="mr-2 w-4 h-4 text-white fill-white" />
      Agregar nueva dirección
    </button>
    <div
      v-else-if="addresses.length > 0 && !showNewAddressComponent"
      class="flex flex-col"
    >
      <div class="flex space-x-2">
        <lokal-generic-combobox
          v-model="selectedAddress"
          class="w-full"
          :get-option-key="(option: Address) => option.id"
          :get-option-label="(option: Address) => `${option.nameReference} - ${option.address}`"
          :options="addresses"
          :options-label="`Selecciona una dirección`"
          :multiple="false"
          :local-search="false"
        />
        <button
          class="shrink-0 p-2 bg-lk-light-gray hover:bg-lk-green rounded-md"
          type="button"
          @click="addNewAdrress"
        >
          <PlusIcon class="shrink-0 w-5 h-5 text-white fill-white" />
        </button>
      </div>
    </div>
    <Field
      v-model="selectedAddressId"
      name="address"
      :rules="{ required: true }"
      hidden
    />
    <ErrorMessage
      name="address"
      class="text-sm text-left text-red-500 md:text-center"
    />
    <address-new-dialog
      v-if="showNewAddressDialog && newAddressWithDialog && !loading"
      v-model="showNewAddressDialog"
      :addressable-id="addressableId"
      :addressable-type="addressableType"
      @update:model-value="(value: boolean) => showNewAddressDialog = value"
      @address-created="updateModelValueWithAddress"
    />
    <address-new
      v-if="showNewAddressComponent && !newAddressWithDialog && !loading"
      :addressable-id="addressableId"
      :addressable-type="addressableType"
      @address-created="updateModelValueWithAddress"
    />
    <lokal-loading
      v-if="loading"
      class="self-center"
      :loading="loading"
    />
  </div>
</template>
<script lang="ts" setup>
import { PlusIcon } from '@heroicons/vue/24/outline';
import { onMounted, ref, computed, watch } from 'vue';
import { Field, ErrorMessage } from 'vee-validate';
import AddressNewDialog from './address-new-dialog.vue';
import AddressNew from './address-new.vue';
import useAddressesQuery from './queries/addresses-query';
import LokalGenericCombobox from './shared/lokal-generic-combobox.vue';

interface AddressSelectorProps {
  addressableId: number,
  addressableType: 'Shop' | 'Maker',
  modelValue: Address,
  newAddressWithDialog?: boolean,
  defaultAddressId?: number,
}

const props = withDefaults(defineProps<AddressSelectorProps>(), {
  newAddressWithDialog: true,
  defaultAddressId: undefined,
});

const computedAddressableId = computed(() => props.addressableId);
const computedAddressableType = computed(() => props.addressableType);
const { addresses, addressesQuery } = useAddressesQuery(computedAddressableId, computedAddressableType);
const addressesQueryIsLoading = computed(() => addressesQuery.isLoading.value);

const loading = ref(false);
const showNewAddressDialog = ref(false);
const showNewAddressComponent = ref(false);
const selectedAddress = ref({} as Address);
const selectedAddressId = computed(() => selectedAddress.value.id);

onMounted(async () => {
  if (!!props.addressableId) {
    await addressesQuery.refetch.value();
    if (props.defaultAddressId) {
      const initialAddress = addresses.value?.find((address: Address) =>
        address.id === props.defaultAddressId);
      if (initialAddress?.id) {
        selectedAddress.value = initialAddress;
      }
    } else {
      if (addresses.value?.[0] && addresses.value?.[0].id) {
        selectedAddress.value = addresses.value[0];
      }
    }
  }
});

const emit = defineEmits<{(e: 'update:modelValue', value: Address): void,
  (e: 'creating-address'): void, (e: 'address-created'): void }>();
watch(selectedAddress, (newSelectedAddress) => {
  if (newSelectedAddress) emit('update:modelValue', newSelectedAddress);
});

function addNewAdrress() {
  if (props.newAddressWithDialog) {
    showNewAddressDialog.value = !showNewAddressDialog.value;
  } else {
    showNewAddressComponent.value = !showNewAddressComponent.value;
    emit('creating-address');
  }
}

async function updateModelValueWithAddress(addressId: number) {
  await addressesQuery.refetch.value();
  const newAddress = addresses.value.find((address: Address) => address.id === addressId);
  if (newAddress?.id) selectedAddress.value = newAddress;
  if (props.newAddressWithDialog) showNewAddressDialog.value = !showNewAddressDialog.value;
  else showNewAddressComponent.value = !showNewAddressComponent.value;
  emit('address-created');
}
</script>
