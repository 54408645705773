import { computed, type ComputedRef } from 'vue';
import { useQuery } from 'vue-query';
import shopPickupConditionsApi from 'api/shop-pickup-conditions';

export default function useShopPickupConditionQuery(makerId: ComputedRef<number>) {
  const shopPickupConditionQuery = useQuery(
    ['shopPickupCondition', makerId],
    () => shopPickupConditionsApi.get(makerId.value),
  );
  const shopPickupConditonQueryIsLoading = computed(() => shopPickupConditionQuery.isLoading.value);
  const shopPickupCondition = computed(() =>
    shopPickupConditionQuery.data.value?.data?.shopPickupCondition as ShopPickupCondition);

  return { shopPickupConditionQuery, shopPickupConditonQueryIsLoading, shopPickupCondition };
}
