<template>
  <Popover
    v-slot="{ open }"
    class="relative"
  >
    <div
      v-if="open"
      class="overflow-y-hidden fixed inset-0 z-10 bg-gray-800/50"
      aria-hidden="true"
    />
    <PopoverButton
      class="flex z-10 items-center p-2 text-sm text-lk-green bg-white rounded-md border border-lk-green focus:outline-none md:p-3 md:space-x-2 md:text-base"
      :class="{ 'bg-lk-green text-white': open, 'hover:bg-lk-green hover:text-white': !open }"
    >
      <span class="mr-2">Descargas</span>
      <ChevronDownIcon
        class="w-3 h-3 md:w-4 md:h-4"
        :class="{ 'rotate-180': open }"
      />
    </PopoverButton>
    <div
      v-if="open"
      class="absolute right-4 z-20 border-8 border-t-0 border-lk-green border-x-transparent border-solid"
    />
    <PopoverPanel
      v-slot="{ close }"
      class="flex overflow-y-auto absolute right-0 z-10 flex-col py-0 mt-2 w-52 max-h-96 text-sm rounded-md border border-lk-green divide-y divide-lk-green focus:outline-none origin-top-right"
    >
      <button
        class="flex items-center p-2 w-full hover:font-semibold text-lk-green hover:text-white bg-white hover:bg-lk-green md:py-3"
        @click="downloadShops(close)"
      >
        <ArrowDownTrayIcon class="shrink-0 w-4 h-4" />
        <span class="ml-auto">Clientes</span>
      </button>
      <button
        class="flex items-center p-2 w-full hover:font-semibold text-lk-green hover:text-white bg-white hover:bg-lk-green md:py-3"
        @click="downloadReferredContacts(close)"
      >
        <ArrowDownTrayIcon class="shrink-0 w-4 h-4" />
        <span class="ml-auto">Referidos</span>
      </button>
    </PopoverPanel>
  </Popover>
</template>
<script lang="ts" setup>
import { useMutation } from 'vue-query';
import makersApi from 'api/makers';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import useMessageStore from 'stores/message-store';
import { ChevronDownIcon, ArrowDownTrayIcon } from '@heroicons/vue/24/outline';

const props = defineProps<{
  maker: Maker
}>();

const messageStore = useMessageStore();

const downloadShopsQuery = useMutation(
  ['downloadShops', props.maker.id],
  () => makersApi.downloadShops(props.maker.id),
  {
    onSuccess: () => {
      messageStore.message = `
        Te enviaremos un correo con los pedidos en un archivo Excel con las tiendas que te han
        comprado en los próximos minutos.`;
    },
  },
);

const downloadReferredContactsQuery = useMutation(
  ['downloadReferredContacts', props.maker.id],
  () => makersApi.downloadReferredContacts(props.maker.id),
  {
    onSuccess: () => {
      messageStore.message = `
        Te enviaremos un correo con los pedidos en un archivo Excel con los usuarios que se han
        registrado con tu link de referido en los próximos minutos.`;
    },
  },
);

function downloadShops(close: () => void) {
  downloadShopsQuery.mutate();
  close();
}

function downloadReferredContacts(close: () => void) {
  downloadReferredContactsQuery.mutate();
  close();
}
</script>
