import { inject, type Ref } from 'vue';

// eslint-disable-next-line complexity
export default function useMakerImageUrl(maker: Maker, size?: keyof Derivatives) {
  const isMobile = inject<Ref<boolean>>('isMobile');

  if (!!size) {
    return (maker.image && maker.image?.derivatives[size]?.url) || '';
  } else if (isMobile?.value) {
    return (maker.image && maker.image?.derivatives?.jpgSm?.url) || '';
  }

  return (maker.image && maker.image?.derivatives?.jpgMd?.url) || '';
}
