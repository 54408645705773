import api from './index';

export default {
  createToken(data: { username: string, email: string, responseUrl: string }) {
    const path = '/api/internal/transbank/create_token';

    return api({
      method: 'post',
      url: path,
      data,
    });
  },
  confirmInscription(data: { token: string }) {
    const path = '/api/internal/transbank/confirm_inscription';

    return api({
      method: 'post',
      url: path,
      data,
    });
  },
};
