import { computed, type Ref } from 'vue';
import { useQuery } from 'vue-query';
import productsApi from 'api/products';
import useCustomParseInt from 'components/use/custom-parse-int';

const PAGE_SIZE = 24;

export default function useProductsQuery(currentPage: Ref<number>, queryParams = computed(() => ''),
  enabled = computed(() => false), pageSize = computed(() => PAGE_SIZE)) {
  const productQuery = useQuery(
    ['products', currentPage, queryParams, pageSize],
    () => productsApi.newIndex(currentPage.value, queryParams.value, pageSize.value),
    { enabled, keepPreviousData: true },
  );
  const productQueryIsLoading = computed(() => productQuery.isLoading.value || productQuery.isPreviousData.value);
  const products = computed(() => productQuery.data.value?.data.products as Product[]);
  const totalResources = computed(() => useCustomParseInt(productQuery.data.value?.headers['x-total']));
  const resourcesPerPage = computed(() => useCustomParseInt(productQuery.data.value?.headers['x-per-page']));
  const totalPages = computed(() => Math.ceil(totalResources.value / resourcesPerPage.value));

  return { productQueryIsLoading, products, totalResources, resourcesPerPage, totalPages, productQuery };
}
