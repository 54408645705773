<template>
  <div class="w-full">
    <lokal-banner
      v-if="showLokalBanner"
      v-model="showLokalBanner"
      :message="message"
      :color-key="(colorKey as ColorKeyType)"
    />
    <lokal-message-dialog
      v-if="showLokalMessageDialog"
      v-model="showLokalMessageDialog"
      :message="message"
      :title="title"
    />
  </div>
</template>
<script lang="ts" setup>
import { ref } from 'vue';
import LokalBanner, { type ColorKeyType } from './lokal-banner.vue';
import LokalMessageDialog from './lokal-message-dialog.vue';

interface LokalBannerMessageDialogProps {
  modelValue: boolean
  message: string
  showDialog?: boolean
  title?: string
  colorKey?: string
}

const props = withDefaults(defineProps<LokalBannerMessageDialogProps>(), {
  modelValue: false,
  showDialog: false,
  message: '',
  title: '',
  colorKey: 'notice',
});

const showLokalBanner = ref(props.modelValue);
const showLokalMessageDialog = ref(props.modelValue && props.showDialog);
</script>
