import { csrfToken } from '@rails/ujs';
import { ref } from 'vue';
import api from './index';

const token = ref(csrfToken());
let headers = {};
if (token.value) {
  headers = { 'X-CSRF-Token': token.value };
}

export default {
  show(shopId: number) {
    const path = `/api/internal/shops/${shopId}/payment_methods`;

    return api({
      method: 'get',
      url: path,
      headers,
    });
  },
};
