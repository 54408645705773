import { computed, type ComputedRef } from 'vue';
import { useQuery } from 'vue-query';
import discountCodesApi from 'api/discount-codes';

export default function useDiscountCodeQuery(discountCodeId: ComputedRef<number | string>,
  enabled = computed(() => false),
) {
  const discountCodeQuery = useQuery(
    ['discountCodeQuery', discountCodeId],
    () => discountCodesApi.get(discountCodeId.value),
    { enabled },
  );
  const discountCodeQueryIsLoading = computed(() => discountCodeQuery.isLoading.value);
  const discountCode = computed(() => discountCodeQuery.data.value?.data.discountCode as DiscountCode);

  return { discountCode, discountCodeQuery, discountCodeQueryIsLoading };
}
