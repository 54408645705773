<template>
  <div class="flex p-3 border border-b-0">
    <div class="flex flex-col w-full">
      <p class="text-sm md:text-base">
        Filtrar por fecha de registro:
      </p>
      <VueDatePicker
        v-model="dates"
        :teleport="true"
        :inline="false"
        :range="true"
        :enable-time-picker="false"
        class="mb-2 text-sm font-light"
        :month-change-on-scroll="false"
        calendar-cell-class-name="dp-custom-cell"
        :locale="es"
        select-text="Elegir"
        cancel-text="Cancelar"
        @open="isDatePickerOpen = true"
        @close="isDatePickerOpen = false"
      />
      <div class="flex flex-col flex-wrap mb-2 space-y-1 md:flex-row md:items-center md:space-y-0 md:space-x-2">
        <div class="flex flex-wrap items-center">
          <p class="mr-2 text-sm md:text-base">
            Ver solo usuarios con tienda:
          </p>
          <lokal-switch v-model="onlyShops" />
        </div>
        <div class="flex flex-wrap items-center">
          <p class="mr-2 text-sm md:text-base">
            Ver solo usuarios sin tienda:
          </p>
          <lokal-switch v-model="onlyUsers" />
        </div>
      </div>
      <template v-if="onlyShops">
        <p class="text-sm md:text-base">
          Tipo de tienda:
        </p>
        <div class="flex items-center mb-1">
          <lokal-single-selector
            v-model="kindRecord"
            key-option="value"
            class="md:w-60"
            :class="{ 'w-full': kindRecord.value === undefined, 'w-1/2 mr-2': kindRecord.value !== undefined }"
            :options="kindOptions"
            option-label="label"
            options-label="Selecciona un tipo de tienda"
            custom-label-color="bg-lk-light-green"
            size="md"
          />
          <button
            v-if="kindRecord.value !== undefined"
            class="p-2 w-1/2 text-sm text-lk-green hover:text-white hover:bg-lk-green rounded-md border border-lk-green md:w-auto md:text-base"
            @click="kindRecord = {}"
          >
            Limpiar búsqueda
          </button>
        </div>
      </template>
    </div>
  </div>
  <div
    v-if="isLoading"
    class="p-3 rounded-b-md border"
  >
    <lokal-loading :loading="isLoading" />
  </div>
  <div
    v-else-if="referredContacts?.length"
    class="flex flex-col"
  >
    <div class="flex mb-1 w-full text-xs border-x md:text-sm">
      <p class="mr-2 ml-auto">
        Total {{ totalResources }}
      </p>
    </div>
    <div class="flex flex-col rounded-md rounded-t-none border divide-y">
      <maker-contact-list-card
        v-for="referredContact, index in referredContacts"
        :key="index"
        :referred-id="referredContact.referredId"
        :referred-type="referredContact.referredType"
        :shop-name="referredContact.shopName"
        :shop-kind="referredContact.referredType === 'User' ? 'user' : referredContact.shopKind"
        :other-kind="referredContact.otherKind"
        :created-at="referredContact.createdAt"
        :contact-name="referredContact.contactName"
        :contact-number="referredContact.contactNumber"
        :contact-email="referredContact.contactEmail"
        :referred="referredContact.referred"
        :url="referredContact.url"
        :show-has-bought="true"
        :has-bought="referredContact.hasBought"
        :class="{ 'hover:rounded-b-md': referredContact.url === referredContacts[referredContacts.length - 1]?.url }"
        :maker="maker"
        @open-follow-up-dialog="openFollowUpDialog(referredContact)"
      />
    </div>
    <pagination-component
      v-if="!isLoading && referredContacts?.length"
      :total-pages="totalPages"
      :current-page="currentPage"
      class="self-center"
      @set-new-page="(newPage: number) => currentPage = newPage"
    />
    <maker-new-follow-up-dialog
      v-model="showNewFollowUpDialog"
      :maker="maker"
      :follow-up-id="followUpId"
      follow-up-type="Shop"
      :followable-name="followUpTypeName"
    />
  </div>
  <div
    v-else
    class="flex flex-col border"
  >
    <p class="p-5">
      Aún no tienes referidos. Comparte tu link de referido para que tus clientes se registren y
      empieces a sacarle el mayor provecho a Lokal.
    </p>
  </div>
</template>
<script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { useQuery } from 'vue-query';
import referredContactsApi from 'api/referred-contacts';
import { es } from 'date-fns/locale';
import useUrlParamStore from 'stores/url-param-store';
import MakerContactListCard from './maker-contact-list-card.vue';
import PaginationComponent from './shared/pagination-component.vue';
import useCustomParseInt from './use/custom-parse-int';
import LokalSwitch from './shared/lokal-switch.vue';
import useUrlMethods from './use/url-methods';
import MakerNewFollowUpDialog from './maker-new-follow-up-dialog.vue';
import lokalSingleSelector, { type OptionRecord } from './shared/lokal-single-selector.vue';

const KINDOPTIONS = [
  { value: 4, label: 'Tienda física y online' },
  { value: 0, label: 'Tiendas físicas' },
  { value: 1, label: 'Tiendas online' },
  { value: 3, label: 'Otros' },
];

interface MakerContactsProps {
  maker: Maker
}

const props = defineProps<MakerContactsProps>();

const onlyShops = ref(true);
const onlyUsers = ref(false);
const kindRecord = ref({} as OptionRecord);
const kindOptions = ref(KINDOPTIONS as OptionRecord[]);
const showNewFollowUpDialog = ref(false);
const followUpId = ref(0);
const followUpType = ref('');
const followUpTypeName = ref('');
function openFollowUpDialog(referredContact: ReferredContact) {
  followUpId.value = referredContact.referredId;
  followUpType.value = referredContact.referredType;
  followUpTypeName.value = referredContact.shopName || referredContact.contactName;
  showNewFollowUpDialog.value = true;
}

const dates = ref([] as Date[]);
const isDatePickerOpen = ref(false);
const initialDate = computed(() => (dates.value?.[0] ? dates.value[0].toISOString().split('T')[0] : ''));
const endDate = computed(() => (dates.value?.[1] ? dates.value[1].toISOString().split('T')[0] : ''));
const searchParams = computed(() => {
  let params = `q[created_at_gteq]=${initialDate.value}&q[created_at_lteq]=${endDate.value}`;
  params = `${params}&have_not_bought=true`;
  if (onlyShops.value) params += '&q[shop_id_not_null]=1';
  if (onlyUsers.value) params += '&q[shop_id_null]=1';
  if (kindRecord.value?.value !== undefined) params += `&q[shop_kind_eq]=${kindRecord.value.value}`;

  return params;
});

const currentPage = ref(1);
const referredContactsQuery = useQuery(['referredContacts', props.maker.id, currentPage, searchParams],
  () => referredContactsApi.index(props.maker.id, currentPage.value, searchParams.value));
const isLoading = computed(() => referredContactsQuery.isLoading.value);
const referredContacts = computed(() => referredContactsQuery.data.value?.data.referredContacts as ReferredContact[]);
const totalResources = computed(() => useCustomParseInt(referredContactsQuery.data.value?.headers['x-total']));
const resourcesPerPage = computed(() => useCustomParseInt(referredContactsQuery.data.value?.headers['x-per-page']));
const totalPages = computed(() => Math.ceil(totalResources.value / resourcesPerPage.value));

const { updateUrlParams, getQueryParam } = useUrlMethods();
function updateQueryParams() {
  updateUrlParams({ initialDate: initialDate.value, endDate: endDate.value });
}

watch(dates, updateQueryParams);

function setInitialAndEndDates() {
  const initialDateParam = getQueryParam('initialDate');
  const endDateParam = getQueryParam('endDate');

  if (initialDateParam && endDateParam) {
    dates.value = [new Date(initialDateParam), new Date(endDateParam)];
  }
}

const urlParamStore = useUrlParamStore();
onMounted(setInitialAndEndDates);
onUnmounted(() => {
  ['initialDate', 'endDate'].forEach((param) => {
    urlParamStore.paramsToUpdate[param] = '';
  });
});

watch(onlyShops, () => {
  if (onlyShops.value) {
    onlyUsers.value = false;
  }
});
watch(onlyUsers, () => {
  if (onlyUsers.value) {
    onlyShops.value = false;
    kindRecord.value = {} as OptionRecord;
  }
});
</script>
