import { computed, type ComputedRef } from 'vue';
import { useQuery } from 'vue-query';
import freeShippingsApi from 'api/free-shippings';
import { orderBy } from 'lodash';

export default function useFreeShippingsQuery(
  makerId: ComputedRef<number>, queryParams?: ComputedRef<string>, enabled = computed(() => true),
) {
  const freeShippingsQuery = useQuery(
    ['freeShippings', makerId, queryParams],
    () => freeShippingsApi.index(makerId.value, queryParams?.value),
    { enabled: enabled && computed(() => !!makerId.value) },
  );
  const freeShippings = computed(() => freeShippingsQuery.data.value?.data.freeShippings as FreeShipping[]);
  const isLoading = computed(() => freeShippingsQuery.isLoading.value);
  const orderedFreeShippings = computed(() => orderBy(freeShippings.value, ['minimumAmountCents'], ['asc']));

  return { freeShippings, isLoading, orderedFreeShippings, freeShippingsQuery };
}
