<template>
  <tr class="text-xs md:text-sm">
    <td class="py-1 px-2 text-center hover:text-lk-green-deep hover:underline border md:py-2 md:px-4">
      <a :href="`/sub_orders/${subOrder.id}`">
        #{{ subOrder.id }}
      </a>
    </td>
    <td
      class="py-1 px-2 text-center border md:py-2 md:px-4"
    >
      <span
        class="shrink-0 p-1 text-xs text-center text-white uppercase bg-lk-green rounded-full sm:px-2 md:text-sm"
        :class="{
          'bg-red-600': subOrder.status === 'pending',
          'bg-yellow-500': subOrder.status === 'confirmed',
          'bg-lk-light-purple': subOrder.status === 'delivery_in_progress',
        }"
      >
        {{ $t(`subOrder.status.${camelCase(subOrder.status)}`) }}
      </span>
    </td>
    <td class="py-1 px-2 text-center border md:py-2 md:px-4">
      <div class="p-1 text-center uppercase rounded-full border border-gray-300 sm:px-2">
        {{ $t(`subOrder.paymentStatus.${camelCase(subOrder.paymentStatus)}`) }}
      </div>
    </td>
    <td class="py-1 px-2 text-center border md:py-2 md:px-4">
      <template v-if="subOrderInvoicesQueryEnabled">
        <lokal-loading
          v-if="subOrderInvoicesIsLoading"
          :loading="subOrderInvoicesIsLoading"
          size="xs"
        />
        <a
          v-for="subOrderInvoice in subOrderInvoices"
          v-else
          :key="`sub-order-invoice-url-${subOrderInvoice.id}`"
          :href="subOrderInvoice.invoiceUrl"
          target="_blank"
          noopener
          class="hover:text-blue-900 hover:underline"
        >
          Factura #{{ subOrderInvoice.invoiceNumber }}
        </a>
      </template>
      <button
        v-else
        class="flex items-center p-1 mx-auto hover:text-white hover:bg-lk-green rounded-md border border-gray-700 hover:border-lk-green md:p-1"
        @click="uploadBillDialogOpen = !uploadBillDialogOpen"
      >
        <span class="mr-1">Subir</span>
        <ArrowUpOnSquareIcon class="shrink-0 w-3 h-3 md:w-4 md:h-4" />
      </button>
    </td>
    <td class="py-1 px-2 text-center border md:py-2 md:px-4">
      <span v-if="maker.paymentConditions === 'from_delivered'">
        {{ subOrder.deliveredAt ? new Date(subOrder.deliveredAt).toLocaleDateString() : '-' }}
      </span>
      <span v-else>
        {{ subOrder.deliveryInProgressAt ? new Date(subOrder.deliveryInProgressAt).toLocaleDateString() : '-' }}
      </span>
    </td>
    <td class="py-1 px-2 text-center border md:py-2 md:px-4">
      {{ subOrder.paymentDate ? dateTools.dateWithoutTimeToDate(subOrder.paymentDate).toLocaleDateString() : '-' }}
    </td>
    <td class="py-1 px-2 text-center border md:py-2 md:px-4">
      {{ useFormatCurrency(totalAmountToPay) }}
    </td>
    <sub-order-show-upload-bill-dialog
      v-if="uploadBillDialogOpen"
      v-model="uploadBillDialogOpen"
      :sub-order-id="subOrder.id"
      :sub-order="subOrder"
      :maker="maker"
      @invoice-created="() => emit('reload:sub-orders')"
    />
  </tr>
</template>
<script setup lang="ts">
import { ref, computed } from 'vue';
import { camelCase, round } from 'lodash';
import { ArrowUpOnSquareIcon } from '@heroicons/vue/24/outline';
import { useQuery } from 'vue-query';
import subOrderInvoicesApi from 'api/sub-order-invoices';
import useFormatCurrency from './use/format-currency';
import SubOrderShowUploadBillDialog from './sub-order-show-upload-bill-dialog.vue';
import useDateTools from './use/date-tools';

const TOTAL_PERCENTAGE = 100;

const props = defineProps<{
  subOrder: SubOrder
  maker: Maker
}>();

const dateTools = useDateTools();

const uploadBillDialogOpen = ref(false);

const discountedAmount = computed(() =>
  props.subOrder.totalWholesaleAmountCents - props.subOrder.makerDiscountCodeAmountCents -
props.subOrder.volumeDiscountAmountCents - props.subOrder.makerDiscountCodeAmountCents,
);
const commissionAmount = computed(() => round(discountedAmount.value * props.subOrder.commission / TOTAL_PERCENTAGE));
const totalAmountToPay = computed(() =>
  props.subOrder.totalWholesaleAmountCents - props.subOrder.makerDiscountCodeAmountCents -
  props.subOrder.volumeDiscountAmountCents - props.subOrder.makerDiscountCodeAmountCents -
  commissionAmount.value - props.subOrder.advancePaymentFeeAmountCents,
);
const emit = defineEmits<{(e: 'reload:sub-orders'): void}>();

const subOrderInvoicesQueryEnabled = computed(() => !!props.subOrder.subOrderInvoiceUrls?.length);
const subOrderInvoicesQuery = useQuery(
  ['subOrderInvoices', props.subOrder.id],
  () => subOrderInvoicesApi.index(props.subOrder.id),
  { enabled: subOrderInvoicesQueryEnabled },
);
const subOrderInvoicesIsLoading = computed(() => subOrderInvoicesQuery.isLoading.value);
const subOrderInvoices = computed(() => subOrderInvoicesQuery.data.value?.data.subOrderInvoices as SubOrderInvoice[]);
</script>
