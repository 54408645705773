<template>
  <div class="flex flex-col w-full text-sm text-gray-700 md:text-base">
    <button
      class="grid grid-cols-1 py-3 px-5 w-full hover:bg-gray-50 sm:grid-cols-2 sm:gap-y-1"
      :class="{ 'bg-gray-50': showHistory }"
      @click="showHistory = !showHistory"
    >
      <div class="flex items-center">
        <inline-svg-wrapper
          name="store"
          class="shrink-0 mr-1 w-4 h-4 md:w-5 md:h-5"
        />
        <a
          class="text-base capitalize md:text-lg"
          :class="{ 'mr-2': referred }"
          :href="`/makers/${maker.id}/shops/${shop.id}`"
          @click.stop
        >
          <span class="hover:underline">{{ shop.name }}</span>
          <span
            v-if="referred"
            class="shrink-0 ml-1 text-sm text-lk-green uppercase md:text-base"
          >
            Referido
          </span>
        </a>
      </div>
      <p
        v-if="shop.createdAt"
        class="flex items-center space-x-1 font-light"
      >
        <CalendarIcon class="shrink-0 w-4 h-4" />
        <span class="mr-1">Creado en</span>
        <span>{{ useTimeToDate(shop.createdAt) }}</span>
      </p>
      <p
        v-else-if="shop.lastSubOrderAt"
        class="flex items-center font-light"
      >
        <CalendarIcon class="shrink-0 mr-1 w-4 h-4" />
        <span class="mr-1">Última compra</span>
        <span>{{ useTimeToDate(shop.lastSubOrderAt) }}</span>
      </p>
      <p class="flex row-start-2 items-center font-light">
        <UserCircleIcon class="shrink-0 mr-1 w-4 h-4" />
        <span>{{ shop.contactName }}</span>
      </p>
      <a
        class="flex items-center font-light"
        :class="{ 'hover:underline': !!shop.contactNumber }"
        :href="`https://wa.me/${shop.contactNumber}`"
        target="_blank"
        noopener
        @click.stop
      >
        <inline-svg-wrapper
          name="whatsapp-logo"
          class="shrink-0 mr-1 w-4 h-4 fill-gray-700"
        />
        <span>{{ shop.contactNumber }}</span>
      </a>
      <p class="flex items-center font-light">
        <EnvelopeIcon class="shrink-0 mr-1 w-4 h-4" />
        <span class="truncate">{{ shop.contactEmail }}</span>
      </p>
      <p
        v-if="shop.kind"
        class="flex items-center font-light"
      >
        <span>{{ $t(`shop.kind.${camelCase(shop.kind)}`) }}</span>
        <span v-if="shop.otherKind">: {{ shop.otherKind }}</span>
      </p>
      <div
        v-if="!isMobile"
        class="col-start-2 row-span-2 row-start-3"
      >
        <div class="flex items-center font-light hover:underline">
          <span>Ver pedidos y seguimientos</span>
          <ChevronDownIcon
            class="shrink-0 ml-1 w-4 h-4"
            :class="{ 'rotate-180 transform duration-150': showHistory }"
          />
        </div>
      </div>
      <div
        v-if="isMobile"
        class=""
      >
        <div class="flex items-center font-light hover:underline">
          <span>Ver pedidos y seguimientos</span>
          <ChevronDownIcon
            class="shrink-0 ml-1 w-4 h-4"
            :class="{ 'rotate-180 transform duration-150': showHistory }"
          />
        </div>
      </div>
    </button>
    <template v-if="showHistory">
      <maker-shop-history
        class="px-5"
        :maker="maker"
        :shop="shop"
        :with-container-limit="true"
      />
    </template>
  </div>
</template>
<script lang="ts" setup>
import { inject, ref, computed } from 'vue';
import {
  EnvelopeIcon,
  UserCircleIcon,
  CalendarIcon,
  ChevronDownIcon,
} from '@heroicons/vue/24/outline';
import { camelCase } from 'lodash';
import useTimeToDate from './use/time-to-date';
import makerShopHistory from './maker-shop-history.vue';
import inlineSvgWrapper from './inline-svg-wrapper.vue';

interface MakerContactListCardProps {
  maker: Maker
  shop: Shop
}

const props = withDefaults(defineProps<MakerContactListCardProps>(), {});

const isMobile = inject('isMobile');

const showHistory = ref(false);

const referred = computed(() => !!props.shop.referralMakers?.find((maker) => maker.id === props.maker.id) || false);
</script>
