<template>
  <div
    class="flex flex-col w-full text-sm text-center text-white bg-lk-green md:text-base"
    :class="{'rounded-b-md': !inProductOrOrdersPath,
             'rounded-md md:w-1/2': inProductOrOrdersPath }"
  >
    <button
      v-if="!showSentMessage"
      class="p-2 hover:bg-lk-green-deep"
      :class="{'hover:rounded-b-md': !inProductOrOrdersPath,
               'hover:rounded-md': inProductOrOrdersPath }"
      @click="manageNotification"
    >
      Notifícame en stock
    </button>
    <p
      v-else
      class="flex justify-center items-center p-2 bg-lk-green"
      :class="{'rounded-b-md': !inProductOrOrdersPath, 'rounded-md': inProductOrOrdersPath }"
    >
      <span class="mr-2">Registro existoso</span>
      <BoltIcon class="shrink-0 w-4 h-4" />
    </p>
  </div>
</template>
<script lang="ts" setup>
import { ref, computed } from 'vue';
import { BoltIcon } from '@heroicons/vue/24/outline';
import useSessionStore from 'stores/user-store';
import { useMutation } from 'vue-query';
import stockNotificationsApi from 'api/stock-notifications';
import useMessageStore from 'stores/message-store';

const props = defineProps<{
  product: Product;
}>();

const sessionStore = useSessionStore();
const messageStore = useMessageStore();
const showSentMessage = ref(false);
const emit = defineEmits<{(e: 'show-register-dialog'): void}>();
const data = computed(() => ({ productId: props.product.id, userId: sessionStore.user?.id }));
const stockNotificationMutation = useMutation(() => stockNotificationsApi.create(data.value), {
  onSuccess: () => {
    showSentMessage.value = true;
    messageStore.kind = 'success';
    messageStore.message = 'Registro exitoso';
  },
});

function manageNotification() {
  if (sessionStore.user?.id) {
    stockNotificationMutation.mutate();
  } else {
    emit('show-register-dialog');
  }
}

const currentPath = computed(() => window.location.pathname);

const inProductOrOrdersPath = computed(() => {
  const regex = /\/products\/[\d]+|\/orders\/(new|[\d]+)/;

  return regex.test(currentPath.value);
});
</script>
