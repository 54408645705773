import { computed, type ComputedRef } from 'vue';
import { useQuery } from 'vue-query';
import shopsApi from 'api/shops';

export default function useShopQuery(shopId: ComputedRef<number>, enabled?: ComputedRef<boolean>) {
  const internalEnabled = computed(() => !!shopId.value);
  const shopQuery = useQuery(['shop', shopId],
    () => shopsApi.get(shopId.value), { enabled: enabled || internalEnabled });
  const shop = computed(() => shopQuery.data.value?.data.shop as Shop);

  return { shop, shopQuery };
}
