<template>
  <Dialog
    :open="modelValue"
    as="div"
    class="relative z-50"
    @click="emit('update:modelValue', false)"
  >
    <div
      class="fixed inset-0 bg-black/30"
      aria-hidden="true"
    />
    <div class="flex fixed inset-0 justify-center items-center p-2">
      <DialogPanel class="flex flex-col p-3 w-full max-w-md bg-white rounded">
        <button
          class="ml-auto focus:outline-none"
          @click="emit('update:modelValue', false)"
        >
          <XMarkIcon class="w-5 h-5 fill-gray-700 md:w-6 md:h-6" />
        </button>
        <address-new
          v-if="showAddressNew"
          :addressable-id="addressableId"
          :addressable-type="addressableType"
          class="mb-1"
          @address-created="selectAddressAndCloseDialog"
        />
        <div
          v-else
          class="flex flex-col mb-3 divide-y"
        >
          <p class="px-3 mb-2 text-lg md:text-xl">
            Seleccion una dirección
          </p>
          <div class="flex overflow-y-auto flex-col mb-3 max-h-96">
            <button
              v-for="address in addresses"
              :key="address.id"
              class="flex flex-col p-3 text-sm text-left hover:bg-gray-100 md:text-base"
              @click="selectAddressAndCloseDialog(address.id)"
            >
              <span class="font-semibold capitalize">{{ address.nameReference }}</span>
              <span>{{ address.address }}</span>
              <span>{{ address.communeName }}, {{ address.regionName }}</span>
              <span
                v-if="!!address.observations"
                class="font-light"
              >
                Observaciones: {{ address.observations }}
              </span>
            </button>
          </div>
        </div>
        <button
          v-if="showAddressNew"
          class="text-sm hover:underline"
          @click="showAddressNew = !showAddressNew"
        >
          Volver
        </button>
        <button
          v-else
          class="flex justify-center items-center p-1 text-sm text-lk-green hover:text-white bg-white hover:bg-lk-green rounded-md border border-lk-green md:text-base"
          @click="showAddressNew = !showAddressNew"
        >
          <PlusIcon class="shrink-0 mr-1 w-4 h-4" />
          Agregar nueva dirección
        </button>
      </DialogPanel>
    </div>
  </Dialog>
</template>

<script setup lang="ts">
import { XMarkIcon, PlusIcon } from '@heroicons/vue/24/outline';
import { computed, ref } from 'vue';
import {
  Dialog,
  DialogPanel,
} from '@headlessui/vue';
import AddressNew from './address-new.vue';
import useAddressesQuery from './queries/addresses-query';

interface OrderNewAddressesEditProps {
  modelValue: boolean,
  addressableId: number,
  addressableType: 'Maker' | 'Shop'
  addressable: Shop | Maker
}

const props = withDefaults(defineProps<OrderNewAddressesEditProps>(), {
  modelValue: false,
});

const emit = defineEmits<{(e: 'update:modelValue', value: boolean): void,
  (e: 'address-created', value: number): void, (e: 'address-selected', value: number): void}>();

const showAddressNew = ref(false);
const computedAddressableId = computed(() => props.addressableId);
const { addresses } = useAddressesQuery(computedAddressableId, computed(() => props.addressableType));

function selectAddressAndCloseDialog(addressId: number) {
  emit('address-selected', addressId);
  emit('update:modelValue', false);
}
</script>
