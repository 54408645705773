import { computed, type ComputedRef } from 'vue';
import { useQuery } from 'vue-query';
import ordersApi from 'api/orders';

export default function useOrderAddressQuery(orderId: ComputedRef<number>, enabled?: ComputedRef<boolean>) {
  const internalEnabled = computed(() => !!orderId.value);
  const orderAddressQuery = useQuery(
    ['OrderAddress', orderId],
    () => ordersApi.address(orderId.value),
    { enabled: enabled || internalEnabled },
  );
  const orderAddressIsLoading = computed(() => orderAddressQuery.isLoading.value);
  const orderAddress = computed(() => orderAddressQuery.data.value?.data?.address as Address);

  return { orderAddressQuery, orderAddressIsLoading, orderAddress };
}
