<template>
  <div class="flex flex-col items-center mb-10 w-full h-full text-gray-700 md:px-5 lg:px-10 xl:max-w-7xl">
    <div class="flex items-center px-2 mb-3 w-full md:px-0">
      <p class="flex flex-col self-start text-xl text-gray-700 md:text-2xl lg:text-3xl">
        <span>Métricas</span>
      </p>
    </div>
    <div class="flex flex-col py-3 px-2 w-full h-full bg-white rounded-md md:py-5 md:px-4 lg:px-10">
      <div class="flex items-center self-start mb-5 w-full text-sm md:text-base">
        <template
          v-for="value, key in statusHash"
          :key="key"
        >
          <button
            class="shrink-0 py-3 border-b-2"
            :class="{ 'border-gray-300' : selectedStatus !== key, 'border-lk-green' : selectedStatus === key }"
            @click="updateSelectedStatus(key)"
          >
            {{ value }}
          </button>
          <div
            class="self-end w-10 last:w-full border-b-2 border-gray-300"
          />
        </template>
      </div>
      <div class="flex flex-col mb-2 w-full">
        <p class="mb-1 text-sm md:text-base">
          Filtrar por fecha:
        </p>
        <VueDatePicker
          v-model="dates"
          :teleport="true"
          :inline="false"
          :range="true"
          :enable-time-picker="false"
          class="mb-2 text-sm font-light"
          :month-change-on-scroll="false"
          calendar-cell-class-name="dp-custom-cell"
          :locale="es"
          select-text="Elegir"
          cancel-text="Cancelar"
          @open="isDatePickerOpen = true"
          @close="isDatePickerOpen = false"
        />
      </div>
      <maker-metrics-general
        v-if="selectedStatus === 'general'"
        :maker="maker"
        :initial-date="initialDate"
        :end-date="endDate"
      />
      <maker-metrics-product-list
        v-else-if="selectedStatus === 'products'"
        :maker="maker"
        :initial-date="initialDate"
        :end-date="endDate"
      />
      <maker-metrics-operations
        v-else-if="selectedStatus === 'operations'"
        :maker="maker"
        :initial-date="initialDate"
        :end-date="endDate"
      />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, computed, watch, onMounted, onUnmounted } from 'vue';
import { es } from 'date-fns/locale';
import useUrlParamStore from 'stores/url-param-store';
import useUrlMethods from './use/url-methods';
import useDateTools from './use/date-tools';
import MakerMetricsGeneral from './maker-metrics-general.vue';
import MakerMetricsProductList from './maker-metrics-product-list.vue';
import makerMetricsOperations from './maker-metrics-operations.vue';

defineProps<{
  maker: Maker
}>();

const STATUSES_HASH = {
  general: 'general',
  products: 'products',
  operations: 'operacionales',
} as const;
const selectedStatus = ref<keyof typeof STATUSES_HASH>('general');
const statusHash = ref({ general: 'General', products: 'Productos', operations: 'Operacionales' });
function updateSelectedStatus(status: keyof typeof STATUSES_HASH) {
  selectedStatus.value = status;
}

const { stringToDate } = useDateTools();
const daysAgo = 30;
const currentDate: Date = new Date();
const oneMonthAgo = new Date();
oneMonthAgo.setDate(currentDate.getDate() - daysAgo);
const dates = ref([oneMonthAgo, currentDate] as Date[]);
const initialDate = computed(() => (dates.value?.[0] ? dates.value[0].toISOString().split('T')[0] : ''));
const endDate = computed(() => {
  if (!dates.value?.[1]) return '';
  const date = new Date(dates.value?.[1]);
  // eslint-disable-next-line no-magic-numbers
  date.setHours(23, 59, 59, 999);

  return date.toISOString();
});
const endDateFormatted = computed(() => (dates.value?.[1] ? dates.value[1].toISOString().split('T')[0] : ''));
const isDatePickerOpen = ref(false);

const { updateUrlParams, getQueryParam } = useUrlMethods();
function updateQueryParams() {
  updateUrlParams({ initialDate: initialDate.value, endDate: endDateFormatted.value });
}

watch(dates, updateQueryParams);

function setInitialAndEndDates() {
  const initialDateParam = getQueryParam('initialDate');
  const endDateParam = getQueryParam('endDate');

  if (initialDateParam && endDateParam) {
    dates.value = [stringToDate(initialDateParam), stringToDate(endDateParam)];
  } else {
    dates.value = [oneMonthAgo, currentDate];
  }
}

const urlParamStore = useUrlParamStore();
onMounted(setInitialAndEndDates);
onUnmounted(() => {
  ['initialDate', 'endDate'].forEach((param) => {
    urlParamStore.paramsToUpdate[param] = '';
  });
});
</script>
