<template>
  <div class="flex items-center p-2 mb-2 w-full text-sm rounded-md border md:text-base">
    <img
      :src="productImageToShow"
      class="object-cover shrink-0 mr-3 w-16 h-16 rounded-md"
    >
    <p class="flex flex-col mr-3">
      <span>{{ product.name }}</span>
      <span class="font-light">{{ product.sku }}</span>
      <span class="font-light">Estado: {{ $t(`product.status.${camelCase(product.status)}`) }}</span>
    </p>
    <button
      class="flex shrink-0 justify-center items-center p-2 mr-3 ml-auto rounded-md"
      :class="{ 'bg-lk-green text-white': isSelected,
                'border-lk-green hover:bg-lk-green hover:text-white text-lk-green border': !isSelected }"
      @click="changeCondition"
    >
      <component
        :is="isSelected ? CheckIcon : PlusIcon"
        class="shrink-0 mr-2 w-4 h-4 md:w-5 md:h-5"
      />
      <span v-if="isSelected">
        Agregado
      </span>
      <span v-else>
        Agregar
      </span>
    </button>
  </div>
</template>
<script lang="ts" setup>
import { ref } from 'vue';
import { PlusIcon, CheckIcon } from '@heroicons/vue/24/outline';
import { useMutation } from 'vue-query';
import { camelCase } from 'lodash';
import productCollectionsApi from 'api/product-collections';
import useMessageStore from 'stores/message-store';
import useProductImage from './use/product-image';

const props = defineProps<{
  product: Product;
  collection: Collection
  selected: boolean
  index: number;
  queryName: string
}>();

const { productImageToShow } = useProductImage(props.product);

const isSelected = ref(props.selected);

const messageStore = useMessageStore();

const createProductCollectionMutation = useMutation(
  () => productCollectionsApi.create({ collectionId: props.collection.id, productId: props.product.id }),
  {
    onSuccess: () => {
      isSelected.value = true;
      messageStore.kind = 'success';
      messageStore.message = `Producto ${props.product.name} agregado a la colección ${props.collection.name}`;
    },
  },
);

const destroyProductCollectionMutation = useMutation(
  () => productCollectionsApi.destroy(props.product.id, props.collection.id),
  {
    onSuccess: () => {
      isSelected.value = false;
      messageStore.kind = 'success';
      messageStore.message = `Producto ${props.product.name} eliminado de la colección ${props.collection.name}`;
    },
  },
);

function changeCondition() {
  if (isSelected.value) {
    destroyProductCollectionMutation.mutate();
  } else {
    createProductCollectionMutation.mutate();
  }
}
</script>
