<template>
  <div class="relative">
    <Pie
      :id="chartId"
      :data="data"
      :options="options"
      width="400"
      height="400"
    />
  </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import { Pie } from 'vue-chartjs';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, type ChartData } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

declare interface PieChartProps {
  chartId?: string;
  labels: number[] | string[];
  datasets: unknown[];
  options: Record<string, unknown>;
}

const props = withDefaults(defineProps<PieChartProps>(), {
  chartId: 'pie-chart',
});

const data = computed(() => ({
  labels: props.labels,
  datasets: props.datasets,
} as ChartData<'pie', number[], unknown>));
</script>
