<template>
  <transition
    enter-active-class="transition duration-150 ease-in"
    enter-from-class="translate-y-1 opacity-0"
    enter-to-class="translate-y-0 opacity-100"
    leave-active-class="transition duration-150 ease-in"
    leave-from-class="translate-y-0 opacity-100"
    leave-to-class="translate-y-1 opacity-0"
  >
    <div
      v-if="message.length > 0"
      class="flex fixed right-1/2 z-50 flex-col items-center p-2 w-3/4 text-sm text-white rounded-md translate-x-1/2 md:right-4 md:p-3 md:w-80 md:text-base md:translate-x-0"
      :class="[mainBgColor]"
      :style="{ top: topHeaderValue + 'px' }"
    >
      <CheckIcon
        v-if="messageStore.kind === 'success'"
        class="shrink-0 mr-2 w-5 h-5"
      />
      <ExclamationCircleIcon
        v-else-if="messageStore.kind === 'error'"
        class="shrink-0 mr-2 w-5 h-5"
      />
      <ClockIcon
        v-else
        class="shrink-0 mr-2 w-5 h-5"
      />
      <p class="whitespace-pre-wrap">
        {{ message }}
      </p>
    </div>
  </transition>
</template>
<script lang="ts" setup>
import { ClockIcon, CheckIcon, ExclamationCircleIcon } from '@heroicons/vue/24/solid';
import { computed, watch } from 'vue';
import { debounce } from 'lodash';
import useMessageStore from 'stores/message-store';
import useMenuStore from 'stores/general-menu-store';

const SEPARATION_FROM_HEADER = 8;

const messageStore = useMessageStore();
const menuStore = useMenuStore();
const message = computed(() => messageStore.message);

function hideBanner() {
  messageStore.message = '';
}

const debounceHideBanner = debounce(hideBanner, messageStore.timeout);
watch(message, debounceHideBanner);

const mainBgColor = computed(() => {
  if (messageStore.kind === 'success') {
    return 'bg-lk-green';
  } else if (messageStore.kind === 'error') {
    return 'bg-red-500';
  }

  return 'bg-lk-light-purple';
});

const topHeaderValue = computed(() => menuStore.headerHeight + SEPARATION_FROM_HEADER);
</script>
