import { computed } from 'vue';
import { useSessionStorage } from '@vueuse/core';
import { defineStore } from 'pinia';
import useShopQuery from 'components/queries/shop-query';

import userApi from '../api/users';

const useSessionStore = defineStore('session', () => {
  const user = useSessionStorage('session/user', {} as User);
  const currentAddress = useSessionStorage('session/currentAddress', {} as Address);
  const referredSlug = useSessionStorage('session/referredSlug', '');

  function updateCurrentAddress(address: Address) {
    currentAddress.value = address;
  }

  const { shop } = useShopQuery(computed(() => user.value?.shopId));
  const currentCommuneId = computed(() => {
    if (!!user.value?.shopId) {
      return currentAddress.value?.communeId || shop.value?.referenceCommuneId;
    }

    return 0;
  });
  const currentRegionId = computed(() => {
    if (!!user.value?.shopId) {
      return currentAddress.value?.regionId || shop.value?.referenceRegionId;
    }

    return 0;
  });

  async function reloadUser() {
    try {
      const response = await userApi.get(user.value.id);
      user.value = response.data.user;

      return response;
    } catch (e) {
      user.value = {} as User;

      return e;
    }
  }

  function reset() {
    user.value = {} as User;
  }

  return {
    user, reset, reloadUser, currentAddress, currentCommuneId, currentRegionId,
    updateCurrentAddress, referredSlug,
  };
});

export default useSessionStore;
