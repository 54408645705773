<template>
  <DialogTitle class="font-serif text-xl md:text-2xl">
    {{ invoicesExists ? 'Editar factura/s' : `Subir factura pedido #${subOrder.id}` }}
  </DialogTitle>
  <DialogDescription
    class="mb-5 text-sm text-gray-700 md:text-base"
  >
    <p class="mb-1 md:mb-2">
      <span>La factura debe ser emitida a nombre de Lokal Spa.</span>
    </p>
    <div
      class="flex flex-col p-2 w-full text-sm rounded-md border md:text-base"
      :class="{ 'hover:bg-gray-50': !showTransferPaymentInformation }"
    >
      <button
        class="flex items-center w-full"
        :class="{ 'mb-3': showTransferPaymentInformation }"
        @click="showTransferPaymentInformation = !showTransferPaymentInformation"
      >
        <span>Mostrar datos de faturación</span>
        <ChevronDownIcon
          class="ml-auto w-4 h-4 md:w-5 md:h-5"
          :class="{ 'transform rotate-180 duration-150': showTransferPaymentInformation,
                    'transform duration-150': !showTransferPaymentInformation }"
        />
      </button>
      <lokal-transfer-payment-information v-if="showTransferPaymentInformation" />
    </div>
  </DialogDescription>
  <template v-if="!showForm">
    <p
      v-if="subOrderInvoices?.length"
      class="mb-2 text-sm md:text-base"
    >
      Selecciona una factura:
    </p>
    <lokal-loading
      v-if="subOrderInvoicesIsLoading"
      :loading="subOrderInvoicesIsLoading"
    />
    <button
      v-for="subOrderInvoice in subOrderInvoices"
      v-else
      :key="`sub-order-invoice-${subOrderInvoice.id}`"
      class="p-2 text-sm text-left hover:bg-gray-50 rounded-md border md:text-base"
      @click="selectedInvoice = subOrderInvoice; showForm = !showForm"
    >
      <p class="flex items-center">
        <span>Factura #{{ subOrderInvoice.invoiceNumber }}</span>
        <span class="mr-1 ml-auto text-xs font-light md:text-sm">
          editar
        </span>
        <PencilSquareIcon class="shrink-0 w-4 h-4 md:w-4 md:h-4" />
      </p>
      <p class="text-xs font-light md:text-sm">
        Nombre del archivo: {{ subOrderInvoice.invoiceData.metadata?.filename }}
      </p>
    </button>
    <button
      class="flex justify-center items-center p-2 mt-auto text-sm text-center text-lk-green hover:text-white hover:bg-lk-green rounded-md border border-lk-green md:text-base"
      @click="showForm = !showForm"
    >
      <PlusIcon class="shrink-0 mr-2 w-4 h-4 md:w-5 md:h-5" />
      <span>Agregar factura</span>
    </button>
  </template>
  <button
    v-else-if="subOrderInvoices?.length || true"
    class="flex items-center pb-2 mb-2 text-sm text-left border-b md:text-base"
    @click="showForm = !showForm"
  >
    <ChevronDownIcon class="shrink-0 mr-2 w-4 h-4 rotate-90" />
    <span class="hover:underline">Ver facturas</span>
  </button>
  <sub-order-show-upload-bill-dialog-new-invoice
    v-if="showForm"
    :sub-order="subOrder"
    :invoice="selectedInvoice"
    @upload-invoices="uploadInvoices"
  />
</template>
<script setup lang="ts">
import { ChevronDownIcon, PencilSquareIcon, PlusIcon } from '@heroicons/vue/24/solid';
import { ref, computed, watch } from 'vue';
import {
  DialogTitle,
  DialogDescription,
} from '@headlessui/vue';
import { useQuery } from 'vue-query';
import subOrderInvoicesApi from 'api/sub-order-invoices';
import lokalTransferPaymentInformation from './shared/lokal-transfer-payment-information.vue';
import subOrderShowUploadBillDialogNewInvoice from './sub-order-show-upload-bill-dialog-new-invoice.vue';

interface UploadBillDialogProps {
  modelValue: boolean
  subOrderId: number
  subOrder: SubOrder
}

const props = withDefaults(defineProps<UploadBillDialogProps>(), {
  modelValue: false,
});

const emit = defineEmits<{(e: 'update:modelValue', value: boolean): void,
  (e: 'invoice-created'): void
}>();

const selectedInvoice = ref({} as Invoice | SubOrderInvoice);
const showForm = ref(false);
const showTransferPaymentInformation = ref(false);

const subOrderInvoicesQuery = useQuery(
  ['subOrderInvoices', props.subOrderId],
  () => subOrderInvoicesApi.index(props.subOrderId),
);
const subOrderInvoices = computed(() => subOrderInvoicesQuery.data.value?.data.subOrderInvoices as SubOrderInvoice[]);
const subOrderInvoicesIsLoading = computed(() => subOrderInvoicesQuery.isLoading.value);
const invoicesExists = computed(() => subOrderInvoices.value?.length && subOrderInvoices.value?.length > 0);

async function uploadInvoices() {
  await subOrderInvoicesQuery.refetch.value();
  showForm.value = false;
  selectedInvoice.value = {} as Invoice | SubOrderInvoice;
  emit('invoice-created');
}

watch(subOrderInvoices, () => {
  if (!!subOrderInvoices.value && subOrderInvoices.value?.length === 0) {
    showForm.value = true;
  }
});
</script>
