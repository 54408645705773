<template>
  <div class="flex flex-col items-center mb-10 w-full h-full md:px-5 md:max-w-7xl lg:px-10">
    <div class="flex items-center px-2 mb-3 w-full md:px-0">
      <p class="flex flex-col self-end text-xl text-gray-700 md:text-2xl lg:text-3xl">
        <span>Retiro en bodega</span>
      </p>
    </div>
    <div class="flex flex-col py-3 px-2 w-full h-full bg-white rounded-md md:py-5 md:px-10">
      <lokal-loading
        v-if="isLoading"
        :loading="isLoading"
      />
      <template v-else>
        <button
          v-if="!shopPickupConditionExists"
          class="flex justify-center items-center p-2 mb-2 text-sm text-lk-green hover:text-white hover:bg-lk-green rounded-md border border-lk-green md:text-base"
          @click="createShopPickupConditionMutation.mutate()"
        >
          <PlusIcon class="shrink-0 mr-2 w-4 h-4 md:w-5 md:h-5" />
          <span>Crear retiro en bodega</span>
        </button>
        <template v-else>
          <address-selector
            v-model="address"
            :addressable-id="maker.id"
            addressable-type="Maker"
            class="mb-2"
            :default-address-id="shopPickupCondition?.addressId"
          />
          <div
            class="flex flex-col p-2 mb-2 rounded-md border border-lk-light-gray md:p-3"
          >
            <div class="flex items-center">
              <inline-svg-wrapper
                name="warehouse-icon"
                class="shrink-0 mr-1 w-10 h-10 md:w-12 md:h-12"
              />
              <p class="text-base capitalize md:text-lg">
                {{ address.nameReference?.toLocaleLowerCase() }}
              </p>
            </div>
            <p class="text-sm md:text-base">
              <span>{{ address.address }}</span>
            </p>
            <p class="text-sm md:text-base">
              <span>{{ address.communeName }}</span>
              <span>, {{ address.regionName }}</span>
            </p>
          </div>
          <div class="flex items-center mb-2">
            <p class="mr-2">
              Activo
            </p>
            <lokal-switch
              v-model="activeShopPickupCondition"
              class=""
            />
          </div>
          <button
            class="p-2 mb-2 text-sm text-lk-green hover:text-white hover:bg-lk-green rounded-md border border-lk-green md:text-base"
            @click="updateShopPickupCondition"
          >
            Guardar
          </button>
        </template>
      </template>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { PlusIcon } from '@heroicons/vue/24/outline';
import { useMutation } from 'vue-query';
import shopPickupConditionsApi from 'api/shop-pickup-conditions';
import useMessageStore from 'stores/message-store';
import LokalSwitch from './shared/lokal-switch.vue';
import AddressSelector from './address-selector.vue';
import inlineSvgWrapper from './inline-svg-wrapper.vue';
import useShopPickupConditionQuery from './queries/shop-pickup-condition-query';

interface MakerAddressesProps {
  maker: Maker;
}

const props = withDefaults(defineProps<MakerAddressesProps>(), {
});

const address = ref({} as Address);

const messageStore = useMessageStore();

const { shopPickupConditionQuery, shopPickupConditonQueryIsLoading, shopPickupCondition } = useShopPickupConditionQuery(
  computed(() => props.maker.id),
);
// when is preloaded the watch is not triggered
const activeShopPickupCondition = ref(!!shopPickupCondition.value?.active || false);
const shopPickupConditionExists = computed(() => !!shopPickupCondition.value?.id);

watch(shopPickupCondition, () => {
  activeShopPickupCondition.value = !!shopPickupCondition.value?.active;
});

const createShopPickupConditionMutation = useMutation(
  () => shopPickupConditionsApi.create(props.maker.id),
  {
    onSuccess: async () => {
      messageStore.showMessage('Condiciones de retiro en bodega guardadas correctamente', 'success');
      await shopPickupConditionQuery.refetch.value();
    },
    onError: () => {
      messageStore.showMessage('Error al guardar las condiciones de retiro en bodega', 'error');
    },
  },
);
const createShopPickupConditionMutationIsLoading = computed(() => createShopPickupConditionMutation.isLoading.value);

const shopPickupConditionUpdateData = computed(() => ({
  active: activeShopPickupCondition.value,
}));
const updateShopPickupConditionMutation = useMutation(
  (data: Partial<ShopPickupCondition>) => shopPickupConditionsApi.update(shopPickupCondition.value?.id, data),
  {
    onSuccess: async () => {
      messageStore.showMessage('Condiciones de retiro en bodega guardadas correctamente', 'success');
      await shopPickupConditionQuery.refetch.value();
    },
    onError: () => {
      messageStore.showMessage('Error al guardar las condiciones de retiro en bodega', 'error');
    },
  },
);
const updateShopPickupConditionMutationIsLoading = computed(() => updateShopPickupConditionMutation.isLoading.value);

const isLoading = computed(() => shopPickupConditonQueryIsLoading.value ||
  createShopPickupConditionMutationIsLoading.value || updateShopPickupConditionMutationIsLoading.value);

function updateShopPickupCondition() {
  updateShopPickupConditionMutation.mutate({ addressId: address.value.id, ...shopPickupConditionUpdateData.value });
}
</script>
