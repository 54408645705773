<template>
  <form
    class="flex flex-col w-full text-gray-700"
    @submit="onSubmit"
  >
    <p class="mb-2 text-base font-light md:text-lg">
      ¡Hola de nuevo!
    </p>
    <div class="flex flex-col mb-2 w-full">
      <Field
        v-model.trim="email"
        class="p-2 text-xs rounded-md border border-lk-light-gray focus:border-lk-green focus:outline-none md:text-sm"
        placeholder="Email"
        type="text"
        name="user[email]"
        label="Email"
        :rules="{ required: true, userEmailExists: [email] }"
      />
      <ErrorMessage
        name="user[email]"
        class="mt-1 text-sm text-red-500"
      />
    </div>
    <button
      class="flex justify-center items-center p-2 mb-1 text-sm text-white bg-lk-green hover:bg-lk-green-deep rounded-md md:text-base"
      type="submit"
    >
      <span class="mr-1">Continuar</span>
      <ArrowRightIcon class="shrink-0 w-4 h-4" />
    </button>
    <p class="flex justify-center items-center mb-5 text-sm text-center">
      <span class="mr-1">¿Aún no tienes cuenta en Lokal?</span>
      <a
        class="text-sm text-center hover:text-lk-green underline cursor-pointer"
        @click="emit('create-account')"
      >
        Crear una cuenta
      </a>
    </p>
  </form>
</template>
<script lang="ts" setup>
import { ref, watch } from 'vue';
import { useForm, Field, ErrorMessage } from 'vee-validate';
import { ArrowRightIcon } from '@heroicons/vue/24/outline';

const props = defineProps<{
  signInEmail: boolean,
  email: string
}>();

const email = ref(props.email);
const { handleSubmit } = useForm();

const emit = defineEmits<{(e: 'create-account'): void,
  (e: 'update:signInEmail', value: boolean): void,
  (e: 'update:email', value: string): void
}>();
const onSubmit = handleSubmit(() => {
  emit('update:email', email.value);
  emit('update:signInEmail', false);
});

watch(email, () => {
  email.value = email.value.toLowerCase();
});
</script>
