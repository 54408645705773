<template>
  <div class="flex flex-col py-6 px-5 mb-10 max-w-2xl text-sm font-light text-gray-700 md:py-10 md:px-0 md:text-base">
    <h1 class="mb-8 text-2xl font-normal text-center text-lk-green md:text-3xl">
      Políticas de devolución Lokal
    </h1>

    <h3 class="mb-2 text-lg font-normal md:text-xl">
      Procedimiento de devolución
    </h3>
    <p class="mb-2">
      En cada caso, deberán comunicarse con Lokal para regularizar las órdenes de compra y factura si corresponde.
    </p>
    <p class="mb-3">
      Esta política tiene como objetivo asegurar una relación transparente y justa entre las marcas y las tiendas,
      garantizando la satisfacción del cliente final y el correcto funcionamiento del marketplace.
    </p>

    <h3 class="mb-2 text-lg font-normal md:text-xl">
      Devolución de productos del primer pedido
    </h3>
    <p class="mb-3">
      Como se detalla en los Términos y Condiciones de Lokal inciso 13.04, se podrá ofrecer devolución gratuita
      en el primer pedido realizado a algunas Marcas, por un plazo de hasta 60 días desde que la Tienda recibió
      el pedido de la Marca. El costo del despacho de la devolución lo cubrirá la Tienda a menos que se indique
      lo contrario.
    </p>

    <h4 class="font-normal">
      Procedimiento:
    </h4>
    <ol class="px-5 mb-3 list-disc md:px-8">
      <li>
        Se debe ejecutar en un plazo máximo de 5 días corridos posterior a la fecha de vencimiento de la orden/factura.
      </li>
      <li>
        La solicitud debe ser enviada a {{ contactEmail }} indicando el número de la orden.
      </li>
      <li>
        Recibida la solicitud, la Tienda tendrá un plazo máximo de 3 días corridos para enviar toda la
        documentación correspondiente.
      </li>
    </ol>

    <h3 class="mb-2 text-lg font-normal md:text-xl">
      Devolución de productos defectuosos
    </h3>
    <h4 class="font-normal">
      Defectuosos de origen:
    </h4>
    <p class="mb-1">
      En caso de recibir un producto defectuoso, la marca deberá cubrir el 100% de los costos asociados
      a la gestión de devolución, reparación y regularización. Incluye los costos logísticos, de despacho
      y cualquier otro relativo a la regularización.
      El procedimiento es el siguiente:
    </p>
    <ol class="px-5 mb-2 list-decimal md:px-8">
      <li>
        Plazos: debe ser notificado antes de las 24 horas recibido el pedido.
      </li>
      <li>
        Documentación de evidencias:
        <ol class="px-5 list-disc md:px-8">
          <li>
            Foto del pedido recibido
          </li>
          <li>
            Detallar tipo de productos y cantidad de unidades faltantes
          </li>
        </ol>
      </li>
      <li>
        Donde enviar información: Contactar a Lokal mediante la mensajería de whatsapp o al correo
        {{ contactEmail }}
      </li>
      <li>
        Procedimiento: Lokal levantará un reporte de incidencia dirigido a la marca. Confirmada la
        responsabilidad de esta, Lokal coordinará el envío de productos y la regularización de la orden
        y factura. Los costos asociados al envío de productos de reemplazo y envíos serán cubiertos por la marca.
      </li>
    </ol>

    <h4 class="font-normal">
      Defectuosos por operadores logísticos:
    </h4>
    <p class="mb-1">
      De haber daño en el bulto ocasionado por el operador logístico, el procedimiento es el siguiente:
    </p>
    <ol class="px-5 mb-3 list-decimal md:px-8">
      <li>
        Plazos: debe ser notificado antes de las 12 horas.
      </li>
      <li class="px-5 list-disc md:px-8">
        Documentación de evidencias:
        <ol>
          <li>
            Si el operador le pide firmar un documento de entrega conforme, indicar que el bulto presenta daños.
          </li>
          <li>
            Foto del bulto exterior y de su interior justificando los productos faltantes, embalaje
            adulterado y productos entregados.
          </li>
          <li>
            Detallar tipo de productos y cantidad de unidades faltantes
          </li>
        </ol>
      </li>
      <li>
        Donde enviar información: Contactar a Lokal mediante la mensajería de whatsapp o al correo
        {{ contactEmail }}.
      </li>
      <li>
        Procedimiento: confirmada la responsabilidad del operador logístico, Lokal gestionará la recepción
        del pedido en su totalidad. Los costos asociados al envío de productos de reemplazo y envíos serán
        cubiertos por Lokal.
      </li>
    </ol>

    <h3 class="mb-2 text-lg font-normal md:text-xl">
      Plazos de devolución voluntaria
    </h3>
    <p class="mb-1">
      Si la tienda desea realizar una devolución voluntaria por motivos distintos a la defectuosidad del
      producto, como por ejemplo, si el producto no satisfizo sus expectativas:
    </p>
    <ul class="px-5 mb-3 list-disc md:px-8">
      <li>
        Plazo: comunicar a Lokal en un plazo máximo de 24 horas desde la fecha de recepción del producto.
      </li>
      <li>
        Procedimiento: Aprobada la solicitud por la marca, la tienda será la responsable de cubrir
        los costos logísticos asociados a la devolución.
      </li>
      <li>
        Excepciones: Esta política no aplica al ejecutarse la Garantía de devolución Lokal.
      </li>
    </ul>

    <h3 class="mb-2 text-lg font-normal md:text-xl">
      Excepciones en artículos no retornables
    </h3>
    <p class="mb-3">
      Se califican como artículos no retornables tales productos como productos perecederos
      (como alimentos, flores o plantas), productos personalizados (como pedidos especiales o artículos personalizados),
      artículos de cuidado personal (como productos de belleza) y artículos en oferta o tarjetas de regalo.
      Tampoco aceptamos devoluciones de materiales peligrosos, líquidos o gases inflamables.
    </p>

    <h3 class="mb-2 text-lg font-normal md:text-xl">
      Reembolsos
    </h3>
    <p class="mb-3">
      Le notificaremos una vez que hayamos inspeccionado y aprobado su devolución. Si se aprueba, se le
      reembolsará automáticamente en su método de pago original. Recordar el plazo de procesamiento del reembolso.
    </p>

    <h3 class="mb-2 text-lg font-normal md:text-xl">
      Definición de Conceptos:
    </h3>
    <ul class="px-5 list-disc md:px-8">
      <li>
        Tienda: punto de venta que realiza la compra de productos a través de la plataforma www.somoslokal.cl.
      </li>
      <li>
        Marca: proveedor que vende productos a través de la plataforma www.somoslokal.cl.
      </li>
      <li>
        Plataforma o sitio: sitio web, www.somoslokal.cl, donde se realiza la compra y venta de productos.
      </li>
      <li>
        Garantía de devolución Lokal: Lokal entregará una garantía de devolución de 60 días, lo que significa
        que tienes 60 días después de recibir tu artículo, para solicitar una devolución gratis.
      </li>
      <li>
        Producto defectuoso: Aquel que presente un daño irreparable que lo inhabilite para su venta o uso previsto.
      </li>
    </ul>
  </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue';

const contactEmail = computed(() => process.env.CONTACT_EMAIL);
</script>
