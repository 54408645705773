<template>
  <div
    ref="stickyElement"
    class="flex sticky -top-1 z-10 flex-col pb-3 w-full max-w-3xl bg-gray-50 md:py-5"
  >
    <div class="flex flex-col px-5 pt-5 pb-3 w-full bg-white rounded-md md:py-5 md:px-10">
      <div class="flex items-center mb-2 text-xs md:text-sm">
        <div
          class="shrink-0 p-2 text-white bg-lk-green rounded-full md:p-2"
        >
          Pendiente
        </div>
        <div
          class="w-full border border-b"
          :class="{ 'border-gray-300': order.status === 'pending',
                    'border-lk-green': order.status !== 'pending' }"
        />
        <div
          class="shrink-0 p-2 text-white rounded-full md:p-2"
          :class="{ 'bg-gray-300': order.status === 'pending', 'bg-lk-green': order.status !== 'pending' }"
        >
          Aprobada
        </div>
        <div
          class="w-full border border-b"
          :class="{ 'border-gray-300': !isAnySubOrderInDelivery && !isEverySubOrderDelivered,
                    'border-lk-green': isAnySubOrderInDelivery || isEverySubOrderDelivered }"
        />
        <div
          class="shrink-0 p-2 text-white rounded-full md:p-2"
          :class="{ 'bg-gray-300': !isAnySubOrderInDelivery && !isEverySubOrderDelivered,
                    'bg-lk-green': isAnySubOrderInDelivery || isEverySubOrderDelivered }"
        >
          En despacho <br>
        </div>
        <div
          class="w-full border border-b"
          :class="{ 'border-gray-300': !isEverySubOrderDelivered,
                    'border-lk-green': isEverySubOrderDelivered }"
        />
        <div
          class="shrink-0 p-2 text-white rounded-full md:p-2"
          :class="{ 'bg-gray-300': !isEverySubOrderDelivered,
                    'bg-lk-green': isEverySubOrderDelivered }"
        >
          Entregado
        </div>
      </div>
      <button
        class="flex items-center text-xs text-left underline md:ml-auto md:text-sm"
        @click="showStatusDialog = !showStatusDialog"
      >
        <span class="mr-1">Más información</span>
        <QuestionMarkCircleIcon class="shrink-0 w-3 h-3 md:w-4 md:h-4" />
      </button>
    </div>
    <order-show-status-dialog
      v-if="showStatusDialog"
      v-model="showStatusDialog"
    />
  </div>
</template>
<script lang="ts" setup>
import { computed, ref, inject } from 'vue';
import { QuestionMarkCircleIcon } from '@heroicons/vue/24/outline';
import OrderShowStatusDialog from './order-show-status-dialog.vue';

const props = defineProps<{
  order: Order
  subOrders: SubOrder[],
}>();

const isMobile = inject('isMobile');
const showStatusDialog = ref(false);

const isAnySubOrderInDelivery = computed(() =>
  !!props.subOrders.find((subOrder) => subOrder.status === 'delivery_in_progress'));
const subOrdersDelivered = computed(() =>
  props.subOrders.filter((subOrder) => subOrder.status === 'delivered'));
const isEverySubOrderDelivered = computed(() =>
  subOrdersDelivered.value.length === props.subOrders.length);
</script>
