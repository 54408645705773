<template>
  <Dialog
    :open="isOpen"
    as="div"
    class="relative z-50"
    @close="emit('update:isOpen', false)"
  >
    <div
      class="fixed inset-0 bg-black/30"
      aria-hidden="true"
    />
    <div class="flex fixed inset-0 justify-center items-center p-2">
      <DialogPanel
        class="flex overflow-y-scroll flex-col p-2 w-full max-w-md bg-gray-50 rounded md:p-4"
        :class="{ 'h-[600px]': showShipmentForm, 'h-[340px] md:h-[420px]': !showShipmentForm }"
      >
        <div class="flex items-center mb-3">
          <button
            v-if="currentStage === STAGES.confirmed"
            class="flex items-center"
            @click="currentStage = STAGES.pending"
          >
            <ChevronLeftIcon class="mr-1 w-4 h-4" />
            <p
              class="text-sm hover:underline"
            >
              Cambiar forma de envío
            </p>
          </button>
          <button
            class="ml-auto focus:outline-none"
            @click="emit('update:isOpen', false)"
          >
            <XMarkIcon class="w-5 h-5 fill-gray-700" />
          </button>
        </div>
        <sub-order-shipment-method-options
          v-if="currentStage === STAGES.pending"
          :sub-order="subOrder"
          :maker="maker"
          :show-maker-shipment-form="showMakerShipmentForm"
          :show-shipment-form="showShipmentForm"
          @update:sub-order="() => emit('update:subOrder')"
          @update:is-open="(value: boolean) => emit('update:isOpen', value)"
          @update:show-maker-shipment-form="updateShowMakerShipmentForm"
          @update:show-shipment-form="updateShowShipmentForm"
        />
        <sub-order-shipment-dialog-form
          v-if="showShipmentForm && currentStage === STAGES.confirmed"
          v-model="showShipmentForm"
          :maker="maker"
          :sub-order="subOrder"
          :sub-order-products="subOrderProducts"
          @update:sub-order="() => emit('update:subOrder')"
          @update:is-open="(value: boolean) => emit('update:isOpen', value)"
        />
        <sub-order-maker-shipment-dialog-form
          v-if="showMakerShipmentForm && currentStage === STAGES.confirmed"
          v-model="showMakerShipmentForm"
          :sub-order="subOrder"
          @reload:sub-order="() => emit('update:subOrder')"
          @update:model-value="(value: boolean) => emit('update:isOpen', value)"
        />
      </DialogPanel>
    </div>
  </Dialog>
</template>
<script setup lang="ts">
import { ref } from 'vue';
import { XMarkIcon, ChevronLeftIcon } from '@heroicons/vue/24/outline';
import {
  Dialog,
  DialogPanel,
} from '@headlessui/vue';
import SubOrderShipmentDialogForm from './sub-order-shipment-dialog-form.vue';
import SubOrderMakerShipmentDialogForm from './sub-order-maker-shipment-dialog-form.vue';
import subOrderShipmentMethodOptions from './sub-order-shipment-method-options.vue';

const STAGES = {
  pending: 'pending',
  confirmed: 'confirmed',
};

interface SubOrderShipmentDialogProps {
  isOpen: boolean,
  subOrder: SubOrder,
  maker: Maker,
  subOrderProducts: SubOrderProduct[]
}
const props = withDefaults(defineProps<SubOrderShipmentDialogProps>(), {
  isOpen: false,
});

const emit = defineEmits<{(e: 'update:isOpen', value: boolean): void,
  (e: 'update:subOrder'): void }>();
const currentStage = ref(props.subOrder.status === 'pending' ? STAGES.pending : STAGES.confirmed);
const showShipmentForm = ref(props.subOrder.status === 'confirmed' && props.subOrder.shipmentMethod === 'lokal');
const showMakerShipmentForm = ref(props.subOrder.status === 'confirmed' && props.subOrder.shipmentMethod === 'maker');

function updateShowMakerShipmentForm(value: boolean) {
  showMakerShipmentForm.value = value;
  showShipmentForm.value = !value;
  currentStage.value = STAGES.confirmed;
}

function updateShowShipmentForm(value: boolean) {
  showShipmentForm.value = value;
  showMakerShipmentForm.value = !value;
  currentStage.value = STAGES.confirmed;
}
</script>
