import { csrfToken } from '@rails/ujs';
import { ref } from 'vue';
import api from './index';

const token = ref(csrfToken());
let headers = {};
if (token.value) {
  headers = { 'X-CSRF-Token': token.value };
}

const PAGE_SIZE = 24;

export default {
  index(makerId: number, pageNumber: number, queryParams?: string, pageSize = PAGE_SIZE) {
    const path = `/api/internal/makers/${makerId}/sub_orders?page[number]=${pageNumber}&page[size]=${pageSize}`;

    return api({
      method: 'get',
      url: `${path}&${queryParams}`,
      headers,
    });
  },
  shopIndex(shopId: number, pageNumber: number, queryParams?: string, pageSize = PAGE_SIZE) {
    const path = `/api/internal/shops/${shopId}/sub_orders?page[number]=${pageNumber}&page[size]=${pageSize}`;

    return api({
      method: 'get',
      url: `${path}&${queryParams}`,
      headers,
    });
  },
  groupByStatus(makerId: number) {
    const path = `/api/internal/makers/${makerId}/sub_orders/group_by_status`;

    return api({
      method: 'get',
      url: path,
      headers,
    });
  },
  groupByIncidenceStatus(makerId: number) {
    const path = `/api/internal/makers/${makerId}/sub_orders/group_by_incidence_status`;

    return api({
      method: 'get',
      url: path,
      headers,
    });
  },
  get(subOrderId: number) {
    const path = `/api/internal/sub_orders/${subOrderId}`;

    return api({
      method: 'get',
      url: path,
      headers,
    });
  },
  create(data: {
    orderId: number,
    makerId: number,
    shippingCostAmountCents: number,
    originalShippingCostAmountCents: number,
    discountCodeId: number,
    shopPickupAddressId?: number,
    shopMethod?: string,
    subOrderProductPartials?: Partial<SubOrderProduct>[]
  }) {
    const path = '/api/internal/sub_orders';

    return api({
      method: 'post',
      url: path,
      data,
      headers,
    });
  },
  update(subOrderId: number, data: Partial<SubOrder>) {
    const path = `/api/internal/sub_orders/${subOrderId}`;

    return api({
      method: 'patch',
      url: path,
      data,
      headers,
    });
  },
  destinationAddresses(makerId: number, queryParams?: string) {
    const path = `/api/internal/sub_orders/destination_addresses?maker_id=${makerId}&${queryParams}`;

    return api({
      method: 'get',
      url: path,
      headers,
    });
  },
  destinationMetrics(makerId: number, queryParams?: string) {
    const path = `/api/internal/sub_orders/destination_metrics?maker_id=${makerId}&${queryParams}`;

    return api({
      method: 'get',
      url: path,
      headers,
    });
  },
  confirm(subOrderId: number, data: Partial<SubOrder>) {
    const path = `/api/internal/sub_orders/${subOrderId}/confirm`;

    return api({
      method: 'patch',
      url: path,
      headers,
      data,
    });
  },
};
