import { computed } from 'vue';
import { useQuery } from 'vue-query';
import provincesApi from 'api/provinces';

export default function useProvincesQuery(enabled = computed(() => true)) {
  const provincesQuery = useQuery('provinces', () => provincesApi.index(), { enabled });
  const provinces = computed(() => provincesQuery.data.value?.data.provinces as Province[]);

  return { provinces, provincesQuery };
}
