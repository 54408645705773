<template>
  <div class="flex flex-col rounded-md border">
    <div class="flex justify-between items-center p-3 text-base bg-gray-50 rounded-t-md md:p-5 md:text-lg">
      <p>Dirección de facturación</p>
      <button
        v-if="billingAddress?.id"
        class="text-sm hover:underline focus:outline-none"
        type="button"
        @click="showEditAddressDialog = !showEditAddressDialog"
      >
        Cambiar
      </button>
    </div>
    <div class="flex flex-col p-3 text-sm rounded-b-md md:p-5 md:text-base">
      <template v-if="!billingAddress?.id">
        <button
          class="flex items-center mb-1"
          type="button"
          @click="selectShippingAddressAsBillingAddress"
        >
          <div class="flex shrink-0 justify-center items-center self-start mt-1 mr-2 w-[14px] h-[14px] rounded-full border-2 border-gray-700 cursor-pointer md:mt-[6px] md:w-4 md:h-4">
            <div
              v-if="useShippingAddress"
              class="w-[6px] h-[6px] bg-gray-700 rounded-full md:w-2 md:h-2"
            />
          </div>
          <p>Usar dirección de despacho</p>
        </button>
        <button
          class="flex items-center"
          type="button"
          :class="{ 'mb-2': useAnotherAddress }"
          @click="useShippingAddress = false; useAnotherAddress = true"
        >
          <div class="flex shrink-0 justify-center items-center self-start mt-1 mr-2 w-[14px] h-[14px] rounded-full border-2 border-gray-700 cursor-pointer md:mt-[6px] md:w-4 md:h-4">
            <div
              v-if="useAnotherAddress"
              class="w-[6px] h-[6px] bg-gray-700 rounded-full md:w-2 md:h-2"
            />
          </div>
          <p>Usar otra dirección</p>
        </button>
        <button
          v-if="useAnotherAddress"
          class="p-2 text-sm text-lk-green hover:text-white hover:bg-lk-green rounded-md border border-lk-green md:text-base"
          type="button"
          @click="showEditAddressDialog = !showEditAddressDialog"
        >
          Seleccionar dirección
        </button>
      </template>
      <p
        v-else
        class="flex flex-col"
      >
        <span class="font-semibold capitalize">Facturación</span>
        <span>{{ billingAddress.address }}</span>
        <span>{{ billingAddress.communeName }}, {{ billingAddress.regionName }}</span>
      </p>
    </div>
    <order-checkout-addresses-edit-dialog
      v-if="!!shop && showEditAddressDialog"
      v-model="showEditAddressDialog"
      :addressable-id="shop.id"
      addressable-type="Shop"
      :addressable="shop"
      @address-selected="manageAddressCreated"
    />
  </div>
</template>
<script lang="ts" setup>
import { ref, computed } from 'vue';
import useShopQuery from './queries/shop-query';
import useAddressesQuery from './queries/addresses-query';
import orderCheckoutAddressesEditDialog from './order-checkout-addresses-edit-dialog.vue';

const props = defineProps<{
  shopId: number
  shippingAddress: Address
  billingAddress: Address
}>();

const useShippingAddress = ref<boolean>();
const useAnotherAddress = ref<boolean>();
const showEditAddressDialog = ref<boolean>(false);

const computedShopId = computed(() => props.shopId);
const { shop } = useShopQuery(computedShopId);
const { addresses, addressesQuery } = useAddressesQuery(computedShopId, computed(() => 'Shop'),
  computed(() => !!shop.value?.id));

const emit = defineEmits<{(e: 'update:billingAddress', value: Address): void}>();
async function manageAddressCreated(addressId: number) {
  await addressesQuery.refetch.value();
  const searchedAddress = addresses.value?.find((address) => address.id === addressId) || null;
  if (!!searchedAddress) {
    emit('update:billingAddress', searchedAddress);
  }
}

function selectShippingAddressAsBillingAddress() {
  useShippingAddress.value = true;
  useAnotherAddress.value = false;
  emit('update:billingAddress', props.shippingAddress);
}
</script>
