<template>
  <div class="p-2 mb-2 text-sm text-gray-700 rounded-md border border-lk-light-gray md:p-3 md:text-base">
    <div class="flex items-center mb-1 w-full">
      <a
        class="flex items-center py-1 mr-2 font-semibold hover:underline rounded-md"
        :href="`/sub_orders/${subOrder.id}`"
        target="_blank"
      >
        <CubeIcon class="shrink-0 mr-1 w-4 h-4 md:mr-2 md:w-5 md:h-5" />
        <span>Pedido #{{ subOrder.id }}</span>
      </a>
      <lokal-loading
        v-if="isLoading"
        :loading="isLoading"
        size="xs"
      />
      <lokal-tooltip
        v-else
        :information="paymentInformation"
        button-classes="flex items-center space-x-1"
        information-classes="w-52 md:w-60 p-1 md:p-2 text-center"
        position-options="center"
      >
        <template #custom-content>
          <CheckIcon
            v-if="order?.paymentStatus === 'paid'"
            class="shrink-0 w-4 h-4 text-lk-green md:w-5 md:h-5"
          />
          <QuestionMarkCircleIcon
            v-else-if="order?.paymentStatus === 'created' || !notFormattedPaymentDate || !lateOnPayment"
            class="shrink-0 w-4 h-4 text-gray-700 md:w-5 md:h-5"
          />
          <XMarkIcon
            v-else
            class="shrink-0 w-4 h-4 text-red-500 md:w-5 md:h-5"
          />
        </template>
      </lokal-tooltip>
      <p class="ml-auto font-light">
        {{ useTimeToDate(subOrder.pendingAt) }}
      </p>
    </div>
    <div class="flex flex-wrap items-center font-light">
      <p class="mr-2">
        Monto total: {{ useFormatCurrency(subOrder.totalWholesaleAmountCents) }}
      </p>
      <span class="mr-2">|</span>
      <p
        class="mr-2"
      >
        Comisión: {{ subOrder.commission }}%
      </p>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import { useQuery } from 'vue-query';
import { CheckIcon, XMarkIcon, QuestionMarkCircleIcon, CubeIcon } from '@heroicons/vue/24/outline';
import ordersApi from 'api/orders';
import useFormatCurrency from './use/format-currency';
import useTimeToDate from './use/time-to-date';
import LokalTooltip from './shared/lokal-tooltip.vue';

interface MakerShopShowSubOrderProps {
  subOrder: SubOrder
}

const props = defineProps<MakerShopShowSubOrderProps>();

const orderQuery = useQuery(
  ['order', props.subOrder.orderId],
  () => ordersApi.get(props.subOrder.orderId),
);
const order = computed(() => orderQuery.data.value?.data.order as Order);
const isLoading = computed(() => orderQuery.isLoading.value);

const today = computed(() => new Date());
const notFormattedPaymentDate = computed(() => order.value?.paymentDate);
const paymentDate = computed(() => new Date(order.value?.paymentDate));
const lateOnPayment = computed(() => {
  if (order.value?.paymentStatus === 'paid' || order.value?.paymentStatus === 'created') {
    return false;
  }

  return today.value > paymentDate.value;
});

const paymentInformation = computed(() => {
  if (!notFormattedPaymentDate.value) {
    return 'información de pago pendiente';
  } else if (order.value?.paymentStatus === 'paid') {
    return 'pagado';
  } else if (!lateOnPayment.value) {
    return 'con tiempo para pagar aún';
  }

  return 'pago atrasado';
});
</script>
