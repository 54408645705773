<template>
  <div class="flex flex-col items-center mb-10 w-full h-full text-gray-700 md:px-5 md:max-w-7xl lg:px-10">
    <div class="flex flex-col px-2 mb-3 w-full md:px-0">
      <p class="flex flex-col mb-1 text-xl md:text-2xl lg:text-3xl">
        Ordenes pendientes de pago
      </p>
      <div class="flex items-center">
        <p class="mr-1 text-base text-red-500 md:text-lg">
          Deuda total (vencida): {{ useFormatCurrency(totalAmountDebt) }}
        </p>
        <button
          class="flex shrink-0 justify-center items-center p-1.5 ml-auto text-sm text-white bg-lk-green hover:bg-lk-green-deep rounded-md md:p-2 md:text-base"
          @click="showPaymentInformationDialog = !showPaymentInformationDialog"
        >
          <PlusIcon class="shrink-0 mr-1 w-4 h-4 md:mr-2" />
          <span>Agregar pago</span>
        </button>
      </div>
    </div>
    <lokal-loading
      v-if="ordersQuery.isLoading.value"
      :loading="ordersQuery.isLoading.value"
    />
    <div
      v-else
      class="flex flex-col py-3 px-2 w-full bg-white rounded-md md:py-5 md:px-4 lg:px-10"
    >
      <div class="overflow-x-auto w-full">
        <table class="mb-6 text-sm rounded-md border border-gray-300 sm:w-full md:mb-8 md:text-base">
          <tr
            class="text-center border-b border-gray-300"
          >
            <th class="shrink-0 p-1">
              Orden id
            </th>
            <th class="shrink-0 p-1">
              Nro. Factura
            </th>
            <th class="shrink-0 p-1">
              Monto total
            </th>
            <th>
              Fecha vencimiento
            </th>
            <th>
              Mora (días)
            </th>
            <th class="shrink-0 p-1">
              Abonado
            </th>
            <th class="shrink-0 p-1">
              Por pagar
            </th>
          </tr>
          <shop-unpaid-order-card
            v-for="order in orders"
            :key="order.id"
            class="text-center"
            :shop-id="shopId"
            :order="order"
            @total-amount-debt="(totalAmountDebt: number) => updateTotalAmountDebtByOrder(order.id, totalAmountDebt)"
          />
        </table>
      </div>
      <p class="mb-2 text-sm md:text-base">
        Recuerda que puedes abonar tus ordenes en cualquier momento. Algunos clientes prefieren
        pagar todas las semanas, otros prefieren pagar una vez al mes o al finalizar el plazo de la
        orden. Tú decides. Nosotros te apoyamos.
      </p>
      <p class="text-sm md:text-base">
        Recuerda también que los pagos anticipados <b>mejoran</b> tu historial crediticio y te permiten
        postular a un aumento de crédito.
      </p>
    </div>
    <lokal-payment-information-dialog
      v-if="showPaymentInformationDialog"
      v-model="showPaymentInformationDialog"
    />
  </div>
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue';
import { PlusIcon } from '@heroicons/vue/24/outline';
import useOrdersQuery from './queries/orders-query';
import shopUnpaidOrderCard from './shop-unpaid-order-card.vue';
import lokalPaymentInformationDialog from './shared/lokal-payment-information-dialog.vue';
import useFormatCurrency from './use/format-currency';

const PAYMENT_STATUS_ENUM_VALUE = 2;

const props = defineProps<{
  shopId: number
}>();

const showPaymentInformationDialog = ref(false);
const computedShopId = computed(() => props.shopId);
const currentPage = ref(1);
const queryParams = computed(() =>
  `q[payment_status_not_eq]=${PAYMENT_STATUS_ENUM_VALUE}&q[s]=payment_date asc, created_at asc`);
const { orders, ordersQuery } = useOrdersQuery(computedShopId, currentPage, queryParams);
const totalAmountDebtByOrder = ref({} as Record<number, number>);
const totalAmountDebt = computed(() =>
  Object.values(totalAmountDebtByOrder.value).reduce((acc, curr) => acc + curr, 0));

function updateTotalAmountDebtByOrder(orderId: number, amountDebt: number) {
  totalAmountDebtByOrder.value = {
    ...totalAmountDebtByOrder.value,
    [orderId]: amountDebt,
  };
}
</script>
