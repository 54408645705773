import { csrfToken } from '@rails/ujs';
import { ref } from 'vue';
import api from './index';

const DEFAULT_MAKERS_PER_PAGE = 24;

const token = ref(csrfToken());
let headers = {};
if (token.value) {
  headers = { 'X-CSRF-Token': token.value };
}

export default {
  index(pageNumber = 1, categoryIds = [] as number[], subCategoryIds = [] as number[], searchText?: string) {
    let queryParams = `page[number]=${pageNumber}`;
    if (categoryIds.length > 0) {
      queryParams = `${queryParams}&category_ids=${categoryIds}`;
    }
    if (subCategoryIds.length > 0) {
      queryParams = `${queryParams}&sub_category_ids=${subCategoryIds}`;
    }
    if (searchText && searchText.length > 0) {
      queryParams = `${queryParams}&search_text=${searchText}`;
    }

    return api({
      method: 'get',
      url: `/api/internal/makers?page[size]=24&${queryParams}`,
      headers,
    });
  },
  indexNames(pageNumber = 1, categoryIds = [] as number[], subCategoryIds = [] as number[], searchText?: string) {
    let queryParams = `page[number]=${pageNumber}`;
    if (categoryIds.length > 0) {
      queryParams = `${queryParams}&category_ids=${categoryIds}`;
    }
    if (subCategoryIds.length > 0) {
      queryParams = `${queryParams}&sub_category_ids=${subCategoryIds}`;
    }
    if (searchText && searchText.length > 0) {
      queryParams = `${queryParams}&search_text=${searchText}`;
    }

    return api({
      method: 'get',
      url: `/api/internal/makers/names?page[size]=24&${queryParams}`,
      headers,
    });
  },
  active(pageNumber: number, queryParams?: string, pageSize = DEFAULT_MAKERS_PER_PAGE) {
    return api({
      method: 'get',
      url: `/api/internal/makers/active?page[number]=${pageNumber}&page[size]=${pageSize}&${queryParams}`,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },
  plaineActive(pageNumber = 1, queryParams?: string, pageSize = DEFAULT_MAKERS_PER_PAGE) {
    return api({
      method: 'get',
      url: `/api/internal/makers/plain_active?page[number]=${pageNumber}&page[size]=${pageSize}&${queryParams}`,
      headers,
    });
  },
  activeReferred(shopId: number, queryParams?: string) {
    return api({
      method: 'get',
      url: `/api/internal/makers/active_referred?shop_id=${shopId}&${queryParams}`,
      headers,
    });
  },
  get(makerId: number | string) {
    const path = `/api/internal/makers/${makerId}`;

    return api({
      method: 'get',
      url: path,
      headers,
    });
  },
  privateShow(makerId: number | string) {
    const path = `/api/internal/makers/${makerId}/private_show`;

    return api({
      method: 'get',
      url: path,
      headers,
    });
  },
  update(makerId: number, data: Partial<Maker>) {
    const path = `/api/internal/makers/${makerId}`;

    return api({
      method: 'patch',
      url: path,
      data,
      headers,
    });
  },
  referrals(makerId: number) {
    const path = `/api/internal/makers/${makerId}/referrals`;

    return api({
      method: 'get',
      url: path,
      headers,
    });
  },
  downloadSubOrders(makerId: number) {
    const path = `/api/internal/makers/${makerId}/download_sub_orders`;

    return api({
      method: 'post',
      url: path,
      headers,
    });
  },
  downloadShops(makerId: number) {
    const path = `/api/internal/makers/${makerId}/download_shops`;

    return api({
      method: 'post',
      url: path,
      headers,
    });
  },
  downloadReferredContacts(makerId: number) {
    const path = `/api/internal/makers/${makerId}/download_referred_contacts`;

    return api({
      method: 'post',
      url: path,
      headers,
    });
  },
};
