<template>
  <div class="flex flex-col text-gray-700">
    <label class="flex flex-col">
      <div class="flex">
        <div class="p-2 w-full text-sm rounded-md rounded-r-none border border-slate-200">
          {{ modelValue ? modelValue.name : '&nbsp;' }}
        </div>
        <span
          class="inline-flex justify-center items-center px-4 text-sm font-medium text-white bg-lk-light-purple rounded-r-lg cursor-pointer"
          type="button"
        >
          Adjuntar
        </span>
      </div>
      <Field
        v-slot="{ value }"
        :model-value="modelValue"
        name="upload"
        :label="labelName"
        :rules="{ required: true }"
      >
        <input
          hidden
          type="file"
          :accept="acceptInputAttributs"
          :modelValue="value"
          @input="setFile"
        >
      </Field>
    </label>
    <ErrorMessage
      name="upload"
      class="text-sm text-red-500"
    />
  </div>
</template>
<script setup lang="ts">
import { Field, ErrorMessage } from 'vee-validate';

withDefaults(defineProps<{
  modelValue?: File
  labelName?: string
  acceptInputAttributs?: string
}>(), {
  modelValue: undefined,
  labelName: 'Archivo',
  acceptInputAttributs: 'applitacion/pdf',
});

const emit = defineEmits<{(e: 'update:modelValue', value: File): void}>();

function setFile(event: Event) {
  const fileInput = event.target as HTMLInputElement;
  if (fileInput.files && fileInput.files[0]) {
    emit('update:modelValue', fileInput.files[0]);
  }
}

</script>
