<template>
  <div class="flex flex-col items-center py-10 w-full bg-gray-50">
    <lokal-loading
      v-if="isLoading"
      v-model="isLoading"
    />
    <makers-product-edit
      v-else
      :product="product"
      :maker="maker"
    />
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import { useQuery } from 'vue-query';
import productsApi from 'api/products';
import MakersProductEdit from './makers-product-edit.vue';

const props = defineProps<{
  productId?: number
  maker: Maker
}>();

const productQuery = useQuery(['product', props.productId], () => {
  if (props.productId) return productsApi.get(props.productId);

  return Promise.resolve({ data: { product: {} } });
}, { refetchOnWindowFocus: false, enabled: computed(() => !!props.productId) });
const product = computed(() => productQuery.data.value?.data.product as Product);
const isLoading = computed(() => productQuery.isLoading.value);
</script>
