<template>
  <button
    class="flex fixed bottom-6 z-50 justify-center items-center bg-[#25D366] rounded-full md:bottom-8"
    :class="{
      'right-[300px]': !isMobile && menuStore.showCartSidebar,
      'right-64': !isMobile && menuStore.showMenu,
      'left-5': isMobile && menuStore.showCartSidebar,
      'right-5': isMobile && (menuStore.showMenu || !menuStore.showCartSidebar),
      'md:right-8': !isMobile && !(menuStore.showCartSidebar || menuStore.showMenu),
    }"
    @click="showIframe = !showIframe"
  >
    <transition
      mode="out-in"
      enter-active-class="transition duration-150"
      enter-from-class="opacity-0 rotate-180"
      enter-to-class="opacity-100"
      leave-active-class="transition duration-75"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <ChevronDownIcon
        v-if="showIframe"
        key="chevron-icon"
        class="m-4 w-6 h-6 text-white md:m-5 md:w-7 md:h-7"
      />
      <inline-svg-wrapper
        v-else
        name="whatsapp-logo"
        class="m-5 w-6 h-6 fill-white md:m-5 md:w-7 md:h-7"
      />
    </transition>
  </button>
  <div
    v-if="showIframe"
    class="fixed inset-0 z-40 bg-black/30"
    @click="showIframe = false"
  />
  <transition
    enter-from-class="opacity-0 scale-0 origin-bottom-right"
    enter-to-class="opacity-100 scale-100 origin-bottom-right"
    enter-active-class="transition duration-150 ease-in"
    leave-active-class="transition duration-75 ease-out"
    leave-from-class="opacity-100 scale-100 origin-bottom-right"
    leave-to-class="opacity-0 scale-0 origin-bottom-right"
  >
    <div
      v-show="showIframe"
      class="fixed inset-x-2 bottom-[88px] z-50 md:right-5 md:bottom-[108px] md:left-auto"
      :style="{ top: iframeHeight + 'px' }"
    >
      <iframe
        :src="iframeSrc"
        title="Chatbot"
        :width="isMobile ? '100%' : '350'"
        :height="isMobile ? '100%' : '100%'"
      />
    </div>
  </transition>
</template>
<script lang="ts" setup>
import { inject, ref, computed } from 'vue';
import { ChevronDownIcon } from '@heroicons/vue/24/outline';
import InlineSvgWrapper from 'components/inline-svg-wrapper.vue';
import useGeneralMenuStore from 'stores/general-menu-store';
import useSessionStore from 'stores/user-store';

const DIFF_WITH_HEADER = 4;

interface FloatingChatbotProps {
  contactNumber: string
  assistantId?: string
}

const props = withDefaults(defineProps<FloatingChatbotProps>(), {
  assistantId: 'show',
});

const showIframe = ref(false);

const menuStore = useGeneralMenuStore();
const isMobile = inject('isMobile') as boolean;
const sessionStore = useSessionStore();

const currentUser = computed(() => sessionStore.user);

const iframeHeight = computed(() => menuStore.headerHeight - DIFF_WITH_HEADER);

const currentAssistantId = computed(() => {
  if (/^\/makers\/kuna-foods(\/(?!admin).*)?$/.test(window.location.pathname)) {
    return 'asst_c5355m24x4Z64QKtPhXBO8lv';
  }

  return props.assistantId;
});

const makerSlug = computed(() => window.location.pathname.split('/makers/')[1]);

const iframeQueryParams = computed(() => {
  let params = '';

  if (makerSlug.value) {
    params += `maker_slug=${makerSlug.value}&`;
  }
  if (currentUser.value) {
    params += `user_id=${currentUser.value.id}`;
  }

  return params;
});

const iframeSrc = computed(() => {
  // current window is localhost
  if (window.location.hostname === 'localhost') {
    return `http://localhost:8000/assistants/${currentAssistantId.value}?${iframeQueryParams.value}`;
  }

  return `https://chat.somoslokal.com/assistants/${currentAssistantId.value}?${iframeQueryParams.value}`;
});
</script>
