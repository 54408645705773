import { csrfToken } from '@rails/ujs';
import { ref } from 'vue';
import api from './index';

const token = ref(csrfToken());
let headers = {};
if (token.value) {
  headers = { 'X-CSRF-Token': token.value };
}

export default {
  index(makerId: number, queryParams?: string) {
    let path = `/api/internal/volume_discounts?maker_id=${makerId}`;
    if (queryParams) path += `&${queryParams}`;

    return api({
      method: 'get',
      url: path,
      headers,
    });
  },
  create(data: Partial<VolumeDiscount>) {
    const path = '/api/internal/volume_discounts';

    return api({
      method: 'post',
      url: path,
      data,
      headers,
    });
  },
  update(volumeDiscountId: number, data: Partial<VolumeDiscount>) {
    const path = `/api/internal/volume_discounts/${volumeDiscountId}`;

    return api({
      method: 'patch',
      url: path,
      data,
      headers,
    });
  },
  delete(volumeDiscountId: number) {
    const path = `/api/internal/volume_discounts/${volumeDiscountId}`;

    return api({
      method: 'delete',
      url: path,
      headers,
    });
  },
  currentVolumeDiscount(makerId: number, totalProductsPricesValue: number) {
    const params = `maker_id=${makerId}&total_products_prices_value=${totalProductsPricesValue}`;
    const path = `/api/internal/volume_discounts/current?${params}`;

    return api({
      method: 'get',
      url: path,
      headers,
    });
  },
};
