<template>
  <div class="flex flex-col items-center mb-10 w-full h-full md:px-5 md:max-w-7xl lg:px-10">
    <div class="flex items-center px-2 mb-3 w-full md:px-0">
      <p class="flex flex-col self-end text-xl text-gray-700 md:text-2xl lg:text-3xl">
        <span>Cotización de despachos con Lokal</span>
      </p>
    </div>
    <div class="flex flex-col py-3 px-2 w-full bg-white rounded-md md:py-5 md:px-10">
      <div class="py-5 max-w-4xl">
        <div class="mb-2 font-serif text-lg md:text-xl">
          {{ maker.name }}
        </div>
        <div class="flex items-center mb-2">
          <p class="mr-2">
            Peso (kg):
          </p>
          <Field
            v-model="shadowWeight"
            class="p-2 rounded-md border"
            name="weight"
          />
        </div>
        <div class="flex flex-col mb-2">
          <p class="flex items-center mb-2 space-x-1">
            <span>Carriers disponibles:</span>
            <template
              v-for="carrier, index in carriers"
              :key="carrier.id"
            >
              <span>{{ carrier.name }}</span>
              <span v-if="index !== carriers.length - 1">·</span>
            </template>
          </p>
          <span>Seleccionar Carrier:</span>
          <lokal-single-selector
            v-model="selectedCarrierOption"
            key-option="value"
            class="md:w-60"
            :options="carriersOptions"
            option-label="label"
            options-label="Selecciona un carrier"
            custom-label-color="bg-lk-light-green"
            size="md"
          />
        </div>
        <div class="flex flex-col mb-2">
          <lokal-loading
            v-if="makerAddressesQuery.isLoading.value"
            :loading="makerAddressesQuery.isLoading.value"
            size="xs"
          />
          <template v-else>
            <p>Dirección:</p>
            <p
              class="flex flex-col md:flex-row md:items-center md:space-x-1"
            >
              <span>{{ origineAddress?.address }}</span>
              <span class="hidden md:block">·</span>
              <span>{{ origineAddress?.communeName }}</span>
            </p>
          </template>
        </div>
        <div class="flex flex-col mb-5">
          <lokal-generic-combobox
            v-model="selectedAddress"
            :options="makerAddresses"
            :get-option-key="(option) => option.id"
            :get-option-label="(option) => option.address"
            options-label="Selecciona una dirección"
            custom-label-color="bg-lk-light-green"
            class="md:w-60"
          />
        </div>
        <div class="flex flex-col mb-5">
          <p class="mr-2">
            Región:
          </p>
          <lokal-generic-combobox
            v-model="selectedRegion"
            :options="regions"
            :get-option-key="(option) => option.id"
            :get-option-label="(option) => option.name"
            options-label="Selecciona una región"
            custom-label-color="bg-lk-light-green"
            class="md:w-60"
          />
        </div>
        <table class="w-full border border-slate-400 dark:border-red-500 border-collapse table-auto">
          <thead>
            <tr>
              <th class="shrink-0 p-2 border border-slate-300">
                Comuna
              </th>
              <th class="shrink-0 p-2 border border-slate-300">
                Carrier
              </th>
              <th class="shrink-0 p-2 border border-slate-300">
                Precio
              </th>
              <th class="shrink-0 p-2 border border-slate-300">
                Nombre del servicio
              </th>
              <th class="shrink-0 p-2 border border-slate-300">
                Código de servicio
              </th>
              <th class="shrink-0 p-2 border border-slate-300">
                Tipos de servicios
              </th>
              <th class="shrink-0 p-2 border border-slate-300">
                Días de tránsito
              </th>
            </tr>
          </thead>
          <lokal-loading
            v-if="isLoading"
            :loading="isLoading"
          />
          <tbody v-else>
            <template
              v-for="commune in selectedCommunes"
              :key="commune.id"
            >
              <tr class="text-center">
                <td class="p-2 border border-slate-300">
                  {{ commune.name }}
                </td>
                <maker-shipping-quotation-list-card
                  :maker-id="maker.id"
                  :origine-address="{ communeName: origineAddress.communeName, regionId: origineAddress.regionId }"
                  :destination-address="{ communeName: commune.name, regionId: commune.regionId }"
                  :weight="weight"
                  :carrier-codes="computed(() => selectedCarrierCodes)"
                />
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { Field } from 'vee-validate';
import { debounce } from 'lodash';
import makerShippingQuotationListCard from './maker-shipping-quotation-list-card.vue';
import useCarriersQuery from './queries/carriers-query';
import useAddressesQuery from './queries/addresses-query';
import useRegionsQuery from './queries/regions-query';
import useCommunesQuery from './queries/communes-query';
import lokalSingleSelector, { type OptionRecord } from './shared/lokal-single-selector.vue';
import lokalGenericCombobox from './shared/lokal-generic-combobox.vue';

const DEFAULT_WEIGTH = 3;
const TIMEOUT = 500;

const props = defineProps<{
  maker: Maker
}>();

const {
  addresses: makerAddresses, addressesQuery: makerAddressesQuery,
} = useAddressesQuery(computed(() => props.maker.id), computed(() => 'Maker'));
const selectedAddress = ref({} as Address);
const origineAddress = computed(() => selectedAddress.value);

watch(makerAddresses, () => {
  if (makerAddresses.value.length > 0 && selectedAddress.value.id === undefined) {
    selectedAddress.value = makerAddresses.value[0];
  }
});

const shadowWeight = ref(DEFAULT_WEIGTH);
const weight = ref(shadowWeight.value);
function updateWeight() {
  weight.value = shadowWeight.value;
}
const debounceUpdateWeight = debounce(updateWeight, TIMEOUT);
watch(shadowWeight, debounceUpdateWeight);

const carriersQueryParams = computed(() =>
  `q[maker_carriers_maker_id_eq]=${props.maker.id}&q[maker_carriers_active_eq]=true`);
const { carriers, carriersQuery } = useCarriersQuery(carriersQueryParams);

const { regions, regionsQuery } = useRegionsQuery();
const selectedRegion = ref({} as Region);
const communesQueryParams = computed(() => `q[region_id_eq]=${selectedRegion.value?.id}`);
const communesQueryEnabled = computed(() => !!selectedRegion.value?.id);
const { communes, communesQuery } = useCommunesQuery(communesQueryParams, communesQueryEnabled);
const selectedCommunes = computed(() =>
  communes.value,
);

const isLoading = computed(() =>
  makerAddressesQuery.isLoading.value || carriersQuery.isLoading.value || communesQuery.isLoading.value ||
    regionsQuery.isLoading.value);

const selectedCarrierOption = ref({ label: 'Todos', value: 'all' } as OptionRecord);
const carriersOptions = computed(() => {
  const options = [{ label: 'Todos', value: 'all' } as OptionRecord] as OptionRecord[];
  carriers.value?.forEach((carrier) => {
    options.push({ label: carrier.name, value: carrier.code });
  });

  return options;
});
const selectedCarrierCodes = computed(() => {
  if (selectedCarrierOption.value.value === 'all') {
    return carriers.value?.map((carrier) => carrier.code);
  }

  return [selectedCarrierOption.value.value as string];
});
</script>
