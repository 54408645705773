<template>
  <template v-if="!isMobile">
    <a
      class="relative py-2"
      :href="`/products/${subOrderProduct.productId}`"
    >
      <span
        v-if="subOrderProduct.activeDiscounts?.length"
        class="absolute right-0 p-0.5 text-xs text-white bg-lk-light-purple"
      >
        {{ subOrderProduct.activeDiscounts[0].name }}
      </span>
      <img
        :src="subOrderProduct.mainImageUrl"
        class="object-cover max-w-[5.5rem] h-24 rounded-sm"
      >
    </a>
    <p class="flex flex-col justify-center text-left md:col-span-2">
      <span>{{ subOrderProduct.product.name }}</span> <br>
      <span
        v-if="subOrderProduct.variant?.id && subOrderProduct.variant?.colorId"
        class="text-xs md:text-sm"
      >
        {{ subOrderProduct.variant?.colorName }} - {{ subOrderProduct.variant?.sizeName }}
      </span>
      <span
        v-if="subOrderProduct.variant?.id && subOrderProduct.variant?.aromaId"
        class="text-xs md:text-sm"
      >
        {{ subOrderProduct.variant?.aromaName }}
      </span>
    </p>
    <p class="">
      <span>{{ useFormatCurrency(subOrderProduct.unitaryPriceCents) }}</span>
    </p>
    <p class="flex flex-col">
      <span :class="{ 'line-through': isActiveDiscount }">
        {{ useFormatCurrency(subOrderProduct.wholesalePriceCents) }}
      </span>
      <span v-if="isActiveDiscount">{{ useFormatCurrency(subOrderProduct.shopDiscountedAmountCents) }}</span>
    </p>
    <p>
      {{ subOrderProduct.quantity }}
    </p>
    <p class="">
      {{ useFormatCurrency(totalProductPriceCents) }}
    </p>
    <div class="col-span-6 w-full border-b border-gray-700 md:col-span-7" />
  </template>
  <div
    v-else
    class="flex first:pt-3 mb-3 w-full border-b border-gray-700"
  >
    <div class="relative">
      <span
        v-if="subOrderProduct.activeDiscounts?.length"
        class="absolute left-0 p-0.5 text-xs text-white bg-lk-light-purple"
      >
        {{ subOrderProduct.activeDiscounts[0].name }}
      </span>
      <img
        :src="subOrderProduct.mainImageUrl"
        class="object-cover mr-5 mb-2 w-28 h-28 rounded-sm"
      >
    </div>
    <p class="flex flex-col justify-between mb-2 text-xs">
      <span class="mb-1">{{ subOrderProduct.product.name }}</span>
      <span
        v-if="subOrderProduct.variant?.id"
      >
        {{ subOrderProduct.variant?.colorName }} - {{ subOrderProduct.variant?.sizeName }}
      </span>
      <span class="mb-1">Precio Público: {{ useFormatCurrency(subOrderProduct.unitaryPriceCents) }}</span>
      <span
        class="mb-1"
      >
        Precio Mayorista:
        <span
          :class="{ 'line-through mr-1': isActiveDiscount }"
        >
          {{ useFormatCurrency(subOrderProduct.wholesalePriceCents) }}
        </span>
        <span v-if="isActiveDiscount">{{ useFormatCurrency(productWholesalePriceCents) }}</span>
      </span>
      <span class="mb-1">Cantidad: {{ subOrderProduct.quantity }}</span>
      <span>Suma 2: {{ useFormatCurrency(totalProductPriceCents) }}</span>
    </p>
  </div>
</template>
<script setup lang="ts">
import { inject, computed } from 'vue';
import useFormatCurrency from './use/format-currency';

interface OrderShowSubOrderProductCardProps {
  subOrderProduct: SubOrderProduct,
}

const props = defineProps<OrderShowSubOrderProductCardProps>();

const isMobile = inject('isMobile');
const productWholesalePriceCents = computed(() =>
  props.subOrderProduct.wholesalePriceCents - props.subOrderProduct.completeDiscountAmountCents);
const isActiveDiscount = computed(() =>
  props.subOrderProduct.wholesalePriceCents !== props.subOrderProduct.shopDiscountedAmountCents);
const totalProductPriceCents = computed(() =>
  props.subOrderProduct.quantity * props.subOrderProduct.shopDiscountedAmountCents);
</script>
