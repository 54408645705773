import { csrfToken } from '@rails/ujs';
import { ref } from 'vue';
import api from './index';

const token = ref(csrfToken());
let headers = {};
if (token.value) {
  headers = { 'X-CSRF-Token': token.value };
}

export default {
  get(makerId: number) {
    const path = `/api/internal/makers/${makerId}/shop_pickup_condition`;

    return api({
      url: path,
      method: 'get',
      headers,
    });
  },
  create(makerId: number) {
    const path = '/api/internal/shop_pickup_conditions';

    return api({
      url: path,
      method: 'post',
      headers,
      data: { makerId },
    });
  },
  update(shopPickupConditionId: number, data: Partial<ShopPickupCondition>) {
    const path = `/api/internal/shop_pickup_conditions/${shopPickupConditionId}`;

    return api({
      url: path,
      method: 'patch',
      headers,
      data,
    });
  },
};
