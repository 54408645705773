import { csrfToken } from '@rails/ujs';
import { ref } from 'vue';
import api from './index';

const token = ref(csrfToken());
let headers = {};
if (token.value) {
  headers = { 'X-CSRF-Token': token.value };
}

export default {
  get(communeId: number, queryParams = '') {
    let path = `/api/internal/communes/${communeId}`;
    if (queryParams) path = `${path}?${queryParams}`;

    return api({
      method: 'get',
      url: path,
      headers,
    });
  },
  index(queryParams = '') {
    const path = `/api/internal/communes?${queryParams}`;

    return api({
      method: 'get',
      url: path,
      headers,
    });
  },
};
