import { defineStore } from 'pinia';
import { useSessionStorage } from '@vueuse/core';

const usePotentialMakerContactStore = defineStore('potentialMakerContactStore', () => {
  const referenceSlug = useSessionStorage('potentialMakerContact/referenceSlug', '');

  function reset() {
    referenceSlug.value = '';
  }

  return { referenceSlug, reset };
});

export default usePotentialMakerContactStore;
