import { computed, type ComputedRef } from 'vue';
import { useQuery } from 'vue-query';
import volumeDiscountsApi from 'api/volume-discounts';

export default function useVolumeDiscountsQuery(
  makerId: ComputedRef<number>, queryParams?: ComputedRef<string>, enabled = computed(() => true),
) {
  const volumeDiscountsQuery = useQuery(
    ['volumeDiscounts', makerId, queryParams],
    () => volumeDiscountsApi.index(makerId.value, queryParams?.value),
    { enabled: computed(() => !!makerId.value) && enabled },
  );
  const volumeDiscounts = computed(() => volumeDiscountsQuery.data.value?.data.volumeDiscounts as VolumeDiscount[]);
  const volumeDiscountIsLoading = computed(() => volumeDiscountsQuery.isLoading.value);

  return { volumeDiscounts, volumeDiscountIsLoading, volumeDiscountsQuery };
}
