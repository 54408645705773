import { defineStore } from 'pinia';
import { ref, onMounted, onUnmounted, watch, type Ref } from 'vue';

// eslint-disable-next-line max-statements
const useGeneralMenuStore = defineStore('generalMenuStore', () => {
  const showMenu = ref(false);
  const showUserRegisterDialog = ref(false);
  const initialValueSignIn = ref(true);
  const showCartSidebar = ref(false);
  const showMakerFilterSidebar = ref(false);
  const showProductFilterSidebar = ref(false);
  const showMakerAdminMenu = ref(false);
  const showShopAdminMenu = ref(false);
  const headerRef: Ref<HTMLElement | null> = ref(null);
  const headerHeight = ref(0);
  let resizeObserver: ResizeObserver;

  function openUserRegisterDialog(withSignInOpen: boolean) {
    initialValueSignIn.value = withSignInOpen;
    showUserRegisterDialog.value = !showUserRegisterDialog.value;
    showMenu.value = false;
  }

  function updateHeaderSize() {
    if (headerRef.value) headerHeight.value = headerRef.value.offsetHeight;
  }

  watch(() => headerRef.value, () => {
    if (headerRef.value) {
      resizeObserver.observe(headerRef.value);
      updateHeaderSize();
    }
  });

  onMounted(() => {
    resizeObserver = new ResizeObserver(updateHeaderSize);
    if (headerRef.value) {
      resizeObserver.observe(headerRef.value);
      updateHeaderSize();
    }
  });

  onUnmounted(() => {
    if (resizeObserver) resizeObserver.disconnect();
  });

  return {
    showMenu, showUserRegisterDialog, initialValueSignIn, openUserRegisterDialog, showCartSidebar,
    showMakerFilterSidebar, showMakerAdminMenu, headerHeight, headerRef, showProductFilterSidebar,
    showShopAdminMenu,
  };
});

export default useGeneralMenuStore;
