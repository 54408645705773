import type { InjectionKey } from 'vue';

export interface BillTools {
  iva: number
  ivaValue: number
  totalPorcentage: number
  ilaLiqorAndDestilles: number
  ilaLiqorAndDestillatesPercentage: number
  ilaWinesAndOthers: number
  ilaWinesAndOthersPercentage: number
}
export const billToolsKey = Symbol() as InjectionKey<BillTools>;
