<template>
  <Dialog
    :open="modelValue"
    as="div"
    class="relative z-50"
  >
    <div
      class="fixed inset-0 bg-black/30"
      aria-hidden="true"
    />
    <div class="flex fixed inset-0 justify-center items-center px-2">
      <DialogPanel
        id="panel"
        class="flex overflow-y-auto flex-col items-center p-2 w-full max-w-md max-h-[600px] bg-gray-50 rounded md:p-4"
      >
        <button
          v-if="signUpStore.currentStep === 'email'"
          class="ml-auto focus:outline-none"
          @click="emit('update:modelValue', false)"
        >
          <XMarkIcon class="w-5 h-5 fill-gray-700 md:w-6 md:h-6" />
        </button>
        <SignSelector
          class="py-0 px-1 max-w-sm md:py-0 md:mb-3"
          :initial-value-sign-in="initialValueSignIn"
          :in-dialog="true"
          :product="product"
        />
      </DialogPanel>
    </div>
  </Dialog>
</template>

<script setup lang="ts">
import { XMarkIcon } from '@heroicons/vue/24/outline';
import {
  Dialog,
  DialogPanel,
} from '@headlessui/vue';
import useSignUpStore from 'stores/sign-up-store';
import SignSelector from '../sign-selector.vue';

interface MakerUserRegisterProps {
  modelValue: boolean
  initialValueSignIn?: boolean
  product?: Product
}
withDefaults(defineProps<MakerUserRegisterProps>(), {
  modelValue: false,
  initialValueSignIn: true,
  product: () => ({} as Product),
});

const signUpStore = useSignUpStore();

const emit = defineEmits<{(e: 'update:modelValue', value: boolean): void}>();
</script>
