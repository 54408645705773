<template>
  <div class="flex flex-col">
    <button
      class="flex justify-between items-center p-3"
      @click="showMetrics = !showMetrics"
    >
      <p class="text-base md:text-lg">
        Número de clientes por región:
      </p>
      <ChevronDownIcon
        class="w-5 h-5 md:w-6 md:h-6"
        :class="{ 'rotate-180': showMetrics }"
      />
    </button>
    <template v-if="showMetrics">
      <lokal-loading
        v-if="destionationAddressesLoading"
        :loading="destionationAddressesLoading"
      />
      <div
        v-else
        class="px-3"
      >
        <div class="flex flex-col mb-2 text-sm rounded border md:text-base">
          <div class="grid grid-cols-4 p-3 bg-gray-50">
            <p class="col-span-3">
              Región
            </p>
            <p class="place-self-center text-center">
              Número de clientes
            </p>
          </div>
          <div
            v-for="addresses, regionCode in groupedDestinationAddresses"
            :key="regionCode"
            class="grid grid-cols-4 py-1 px-3"
          >
            <p class="col-span-3">
              {{ regionCode }}:
            </p>
            <p class="place-self-center">
              {{ addresses.length }}
            </p>
          </div>
        </div>
        <pie-chart
          class="flex justify-center items-center h-[450px]"
          :labels="labels"
          :datasets="datasets"
          :options="{ responsive: true, maintainAspectRatio: true }"
        />
      </div>
    </template>
  </div>
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue';
import { ChevronDownIcon } from '@heroicons/vue/24/outline';
import { groupBy, values } from 'lodash';
import PieChart from './shared/pie-chart.vue';
import regionColorsJson from '../files/region_colors.json';
import useDestinationAddressesQuery from './queries/destination-addresses-query';

const props = defineProps<{
  maker: Maker
  queryParams: string
}>();

const showMetrics = ref(false);
const colorRegions = ref(regionColorsJson.regions as Record<string, string>);
const computedMakerId = computed(() => props.maker.id);
const computedShowMetrics = computed(() => showMetrics.value);
const computedQueryParams = computed(() => props.queryParams);
const {
  destinationAddresses, destionationAddressesLoading,
} = useDestinationAddressesQuery(computedMakerId, computedShowMetrics, computedQueryParams);
const groupedDestinationAddresses = computed(() => groupBy(destinationAddresses.value, 'regionName'));
const labels = computed(() => values(groupedDestinationAddresses.value).map((addresses) =>
  addresses[0].regionName));
const datasets = computed(() =>
  [
    {
      backgroundColor: values(groupedDestinationAddresses.value).map((addresses) =>
        colorRegions.value[addresses[0].regionCode]),
      data: values(groupedDestinationAddresses.value).map((addresses) => addresses.length),
    },
  ],
);
</script>
