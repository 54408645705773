<template>
  <div class="flex flex-col items-center mb-10 w-full h-full md:px-5 md:max-w-7xl lg:px-10">
    <div class="flex items-center px-2 mb-3 w-full md:px-0">
      <p class="flex flex-col self-end text-xl text-gray-700 md:text-2xl lg:text-3xl">
        <span>Colecciones</span>
        <a
          :href="`/makers/${maker.slug}`"
          target="_blank"
          class="mt-1 text-sm font-light hover:underline md:text-base"
        >
          Revisar collecciones
        </a>
      </p>
      <button
        class="flex items-center p-2 ml-auto text-sm text-lk-green hover:text-white bg-white hover:bg-lk-green rounded-md border border-lk-green md:p-3 md:text-base"
        @click="showNewCollectionDialog = !showNewCollectionDialog"
      >
        <PlusIcon class="shrink-0 mr-1 w-4 h-4 md:mr-2 md:w-5 md:h-5" />
        <span>Agregar collección</span>
      </button>
    </div>
    <div class="flex flex-col py-3 px-2 w-full h-full text-gray-700 bg-white rounded-md md:py-5 md:px-4 lg:px-10">
      <div class="flex items-center mb-3 w-full ">
        <p class="text-base md:text-lg">
          Selecciona una colección
        </p>
        <button
          v-if="selectedCollection?.id"
          class="p-2 ml-auto text-sm text-lk-green hover:text-white hover:bg-lk-green rounded-md border border-lk-green md:text-base"
          @click="resetCollection"
        >
          Mostrar colecciones
        </button>
      </div>
      <lokal-loading
        v-if="isCollectionsLoading"
        v-model="isCollectionsLoading"
      />
      <template
        v-for="collection in collections"
        v-else
      >
        <maker-collection-list-card
          v-if="!selectedCollection?.id || selectedCollection?.id === collection.id"
          :key="collection.id"
          :selected-collection="selectedCollection"
          :collection="collection"
          :maker="maker"
          class="mb-2"
          @set:collection="(collection: Collection) => selectedCollection = collection"
          @unset:collection="() => selectedCollection = {} as Collection"
          @reload-collections="async () => await collectionsQuery.refetch.value()"
        />
      </template>
      <div
        v-if="showProducts"
        class="flex flex-col w-full"
      >
        <div class="flex justify-between items-center mb-1">
          <p class="text-sm md:text-base">
            Busca por nombre o sku:
          </p>
          <p class="ml-auto text-xs md:text-sm">
            {{ totalResources }} productos
          </p>
        </div>
        <Field
          v-model="shadowSearchText"
          name="shadowSearchText"
          class="p-2 mb-2 text-sm rounded-md border border-lk-light-gray focus:outline-none md:text-base"
        />
        <lokal-loading
          v-if="totalProductQueryIsLoading"
          v-model="totalProductQueryIsLoading"
        />
        <template v-else>
          <template v-if="currentPage === collectionProductsPage">
            <maker-collection-list-product-card
              v-for="collectionProduct, index in collectionProducts"
              :key="`collection-product-${collectionProduct.id}`"
              :product="collectionProduct"
              :collection="selectedCollection"
              :selected="true"
              :index="index"
              query-name="Collection Products"
            />
            <template v-if="collectionProducts.length < pageSize">
              <maker-collection-list-product-card
                v-for="product, index in products.slice(0, pageSize - collectionProducts.length)"
                :key="`product-${product.id}`"
                :product="product"
                :collection="selectedCollection"
                :selected="false"
                :index="index"
                query-name="Products"
              />
            </template>
          </template>
          <template v-else>
            <maker-collection-list-product-card
              v-for="product, index in previousProducts.slice(pageSize - collectionProducts.length, pageSize)"
              :key="`product-${product.id}`"
              :product="product"
              :collection="selectedCollection"
              :selected="false"
              :index="index"
              query-name="Previous Products"
            />
            <maker-collection-list-product-card
              v-for="product, index in products.slice(0, pageSize - collectionProducts.length)"
              :key="`product-${product.id}`"
              :product="product"
              :collection="selectedCollection"
              :selected="false"
              :index="index"
              query-name="Products"
            />
          </template>
          <pagination-component
            :total-pages="totalPages"
            :current-page="currentPage"
            @set-new-page="updatePage"
          />
        </template>
      </div>
    </div>
    <makers-collection-new-dialog
      v-if="showNewCollectionDialog"
      v-model="showNewCollectionDialog"
      :maker-id="maker.id"
      @reload-collections="async () => await collectionsQuery.refetch.value()"
    />
  </div>
</template>
<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { PlusIcon } from '@heroicons/vue/24/outline';
import { Field } from 'vee-validate';
import { debounce } from 'lodash';
import MakersCollectionNewDialog from './makers-collection-new-dialog.vue';
import MakerCollectionListCard from './maker-collection-list-card.vue';
import useProductsQuery from './queries/products-query';
import MakerCollectionListProductCard from './maker-collection-list-product-card.vue';
import PaginationComponent from './shared/pagination-component.vue';
import useCollectionsQuery from './queries/collections-query';

const PAGE_SIZE = 24;

const props = defineProps<{
  maker: Maker;
}>();

const DEBOUNCE_TIME = 200;

const selectedCollection = ref({} as Collection);
const showNewCollectionDialog = ref(false);
const showProducts = computed(() => !!selectedCollection.value?.id);

const colletionsQueryParams = computed(() => `q[maker_id_eq]=${props.maker.id}&q[s][]=maker_priority+asc`);
const { collections, isCollectionsLoading, collectionsQuery } = useCollectionsQuery(
  colletionsQueryParams, computed(() => true),
);

function resetCollection() {
  selectedCollection.value = {} as Collection;
}

const currentPage = ref(1);
const collectionProductsPage = ref(1);
const productsPage = ref(1);
const previousProductsPage = computed(() => (productsPage.value === 1 ? 1 : productsPage.value - 1));
const shadowSearchText = ref('');
const searchText = ref('');
const debounceIsTyping = debounce(() => {
  searchText.value = shadowSearchText.value;
  currentPage.value = 1;
}, DEBOUNCE_TIME);
watch(shadowSearchText, debounceIsTyping);
const queryParams = computed(() => {
  let params = `q[maker_id_eq]=${props.maker.id}&q[s][]=status+desc&q[s][]=maker_priority+asc&`;
  if (selectedCollection.value?.id) {
    params += `q[product_collections_collection_id_not_eq]=${selectedCollection.value.id}&`;
  }
  if (searchText.value) params += `search_text=${searchText.value}&`;

  return new URLSearchParams(params).toString();
});
const collectionProductsQueryParams = computed(() => {
  let params = `q[maker_id_eq]=${props.maker.id}&q[s][]=status+desc&q[s][]=maker_priority+asc&`;
  if (selectedCollection.value?.id) params += `q[product_collections_collection_id_eq]=${selectedCollection.value.id}&`;
  if (searchText.value) params += `search_text=${searchText.value}&`;

  return new URLSearchParams(params).toString();
});

const pageSize = computed(() => PAGE_SIZE);
const productQueryEnabled = computed(() => showProducts.value);
const {
  products: collectionProducts, productQueryIsLoading: collectionProductQueryIsLoading,
  totalResources: collectionTotalResources, totalPages: collectionTotalPages,
} = useProductsQuery(collectionProductsPage, collectionProductsQueryParams, productQueryEnabled, pageSize);
const {
  products, productQueryIsLoading, totalResources,
} = useProductsQuery(productsPage, queryParams, productQueryEnabled, pageSize);
const {
  products: previousProducts, productQueryIsLoading: previousProductQueryIsLoading,
} = useProductsQuery(previousProductsPage, queryParams, productQueryEnabled, pageSize);
const totalProductQueryIsLoading = computed(() => productQueryIsLoading.value ||
  collectionProductQueryIsLoading.value || previousProductQueryIsLoading.value);

const sumOfResources = computed(() => totalResources.value + collectionTotalResources.value);
const totalPages = computed(() => Math.ceil(sumOfResources.value / pageSize.value));

function updatePage(page: number) {
  currentPage.value = page;
}

watch(currentPage, () => {
  if (collectionTotalPages.value >= currentPage.value) {
    collectionProductsPage.value = currentPage.value;
    productsPage.value = 1;
  } else {
    collectionProductsPage.value = collectionTotalPages.value;
    productsPage.value = currentPage.value - collectionTotalPages.value + 1;
  }
});
</script>
