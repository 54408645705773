<template>
  <div class="flex flex-col items-center mb-10 w-full h-full text-gray-700 md:px-5 lg:px-10 xl:max-w-7xl">
    <div class="flex items-center px-2 mb-3 w-full md:px-0">
      <p class="flex flex-col self-start text-xl text-gray-700 md:text-2xl lg:text-3xl">
        <span>Métricas comparativas</span>
      </p>
    </div>
    <div class="flex flex-col py-3 px-2 w-full h-full bg-white rounded-md md:py-5 md:px-4 lg:px-10">
      <lokal-generic-combobox
        v-model="monthsPeriod"
        class="mb-2 w-full"
        :options-label="`Selecciona un periodo de meses`"
        :multiple="false"
        compare-by="name"
        :local-search="false"
        :options="monthPeriodOptions"
        :get-option-key="(option: Record<string, string>) => option.value"
        :get-option-label="(option: Record<string, string>) => option.name"
      />
      <p
        v-if="!destinationMetricsLoading && subOrders.length === 0"
        class="mb-3 text-base md:mb-5 md:text-lg"
      >
        No tienes pedidos en el periodo seleccionado.
      </p>
      <div class="grid grid-cols-1 lg:flex-row lg:grid-cols-2 lg:gap-2 xl:gap-x-4">
        <lokal-loading
          v-if="destinationMetricsLoading"
          :loading="destinationMetricsLoading"
        />
        <template v-else>
          <bar-chart
            class="flex justify-center items-center h-[350px] xl:h-[450px]"
            :chart-id="`chart-bar-metrics-sub-orders-${props.maker.id}`"
            :labels="labels"
            :datasets="subOrderDatasets"
          />
          <bar-chart
            class="flex justify-center items-center h-[350px] xl:h-[450px]"
            :chart-id="`chart-bar-metrics-amount-${props.maker.id}`"
            :labels="labels"
            :datasets="amountDatasets"
            :options="{ plugins: wholesaleAmountPlugins }"
          />
          <line-chart
            class="flex justify-center items-center h-[350px] xl:h-[450px]"
            :chart-id="`chart-bar-metrics-mean-ticket-${props.maker.id}`"
            :labels="labels"
            :datasets="meanTicketDatasets"
            :options="{ scales: { y: { beginAtZero: true, offset: true }, x: { offset: true } },
                        plugins: wholesaleAmountPlugins }"
          />
          <bar-chart
            class="flex justify-center items-center h-[350px] xl:h-[450px]"
            :chart-id="`chart-bar-metrics-mean-ticket-${props.maker.id}`"
            :labels="labels"
            :datasets="clientsDatasets"
          />
        </template>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, computed } from 'vue';
import { groupBy, keys, orderBy } from 'lodash';
import lokalGenericCombobox from './shared/lokal-generic-combobox.vue';
import useDestinationMetricsQuery from './queries/destination-metrics-query';
import barChart from './shared/bar-chart.vue';
import lineChart from './shared/line-chart.vue';
import useFormatCurrency from './use/format-currency';

interface MakerComparedMetricsProps {
  maker: Maker
}

const props = defineProps<MakerComparedMetricsProps>();

const monthPeriodOptions = ref([{ value: 3, name: '3 meses' }, { value: 6, name: '6 meses' },
  { value: 12, name: '12 meses' }]);
const monthsPeriod = ref(monthPeriodOptions.value[0]);

const initialDate = computed(() => new Date(new Date().setMonth(new Date().getMonth() - monthsPeriod.value.value)));
const endDate = ref(new Date());

const queryParams = computed(() =>
  `q[pending_at_gteq]=${initialDate.value}&q[pending_at_lteq]=${endDate.value}&`);

const {
  destinationMetricsLoading, subOrders,
} = useDestinationMetricsQuery(computed(() => props.maker.id), computed(() => true), queryParams);

const groupedSubOrders = computed(() => groupBy(subOrders.value, (subOrder) => {
  const date = new Date(subOrder.pendingAt);

  // getMonth() returns 0-11
  return `${date.getFullYear()}-${date.getMonth() + 1}`;
}));

const freeShipingSubOrders = computed(() => subOrders.value.filter((subOrder) =>
  ['free_shipping_with_lokal', 'free_shipping_by_maker'].includes(subOrder.shippingConditions)));
const groupedFreeShippingSubOrders = computed(() => groupBy(freeShipingSubOrders.value, (subOrder) => {
  const date = new Date(subOrder.pendingAt);

  // getMonth() returns 0-11
  return `${date.getFullYear()}-${date.getMonth() + 1}`;
}));

const subsidizedShippingSubOrders = computed(() => subOrders.value.filter((subOrder) =>
  ['subsidized_shipping_with_lokal', 'subsidized_shipping_by_maker'].includes(subOrder.shippingConditions)));
const groupedSubsidizedShippingSubOrders = computed(() => groupBy(subsidizedShippingSubOrders.value, (subOrder) => {
  const date = new Date(subOrder.pendingAt);

  // getMonth() returns 0-11
  return `${date.getFullYear()}-${date.getMonth() + 1}`;
}));

function stringDateToNumber(str: string) {
  const monthInYear = 12;
  const [year, month] = str.split('-');

  return Number(year) * monthInYear + Number(month);
}

const labels = computed(() => orderBy(keys(groupedSubOrders.value), (label) => stringDateToNumber(label), 'asc'));
const sortedSubOrderValues = computed(() => labels.value?.map((label) => groupedSubOrders.value[label].length));
const sortedFreeShippingSubOrderValues = computed(() => labels.value?.map((label) =>
  groupedFreeShippingSubOrders.value?.[label]?.length));
const sortedSubsidizedShippingSubOrders = computed(() => labels.value?.map((label) =>
  groupedSubsidizedShippingSubOrders.value?.[label]?.length));
const sortedSubOrdersWithoutShippingDiscounts = computed(() =>
  sortedSubOrderValues.value.map((value, index) => {
    let result = value;
    if (sortedFreeShippingSubOrderValues.value[index]) {
      result -= sortedFreeShippingSubOrderValues.value[index];
    }
    if (sortedSubsidizedShippingSubOrders.value[index]) {
      result -= sortedSubsidizedShippingSubOrders.value[index];
    }

    return result;
  },
  ),
);

function amountWithoutDiscounts(subOrder: SubOrder) {
  const result = subOrder.totalWholesaleAmountCents - subOrder.totalDiscountAmountCents -
    subOrder.volumeDiscountAmountCents - subOrder.makerDiscountCodeAmountCents;

  return result;
}

const sortedSubOrderAmounts = computed(() => {
  const subOrderAmounts = labels.value?.map((label) => groupedSubOrders.value[label].map((subOrder) =>
    amountWithoutDiscounts(subOrder)));

  return subOrderAmounts.map((ammounts) => ammounts.reduce((acc, amt) => acc + amt, 0));
});

const meanTicketValues = computed(() =>
  sortedSubOrderAmounts.value.map((amount, index) => amount / sortedSubOrderValues.value[index]));

const shopsRecurrentPurchaseValues = computed(() => labels.value?.map((label) => {
  const shopIds = keys(groupBy(groupedSubOrders.value[label].filter(
    (subOrder) => !subOrder.firstShopMakerPurchase), 'shopId'));

  return shopIds.length;
}));

const firstShopMakerPurchaseValues = computed(() => labels.value?.map((label) => {
  const shopIds = keys(groupBy(groupedSubOrders.value[label].filter(
    (subOrder) => !!subOrder.firstShopMakerPurchase), 'shopId'));

  return shopIds.length;
}));

const wholesaleAmountPlugins = computed(() => ({
  tooltip: {
    callbacks: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      label: (context: Record<string, any>) => useFormatCurrency(context.parsed.y as number),
    },
  },
}));

const subOrderDatasets = computed(() => {
  const base = [
    {
      label: 'Pedidos',
      backgroundColor: '#00594C',
      data: sortedSubOrdersWithoutShippingDiscounts.value,
      stack: 'group',
    },
  ];
  if (freeShipingSubOrders.value?.length) {
    base.push({
      label: 'Pedidos c/envíos gratis',
      backgroundColor: '#BCC9C5',
      data: sortedFreeShippingSubOrderValues.value,
      stack: 'group',
    });
  }
  if (subsidizedShippingSubOrders.value?.length) {
    base.push({
      label: 'Pedidos c/descuentos en envíos',
      backgroundColor: '#9CAF88',
      data: sortedSubsidizedShippingSubOrders.value,
      stack: 'group',
    });
  }

  return base;
},
);
const amountDatasets = computed(() =>
  [
    {
      label: 'Ventas netas',
      backgroundColor: '#9CAF88',
      data: sortedSubOrderAmounts.value,
    },
  ],
);
const clientsDatasets = computed(() =>
  [
    {
      label: 'Clientes recurrentes',
      backgroundColor: '#00594C',
      data: shopsRecurrentPurchaseValues.value,
      stack: 'group',
    },
    {
      label: 'Primer pedido',
      backgroundColor: '#BCC9C5',
      data: firstShopMakerPurchaseValues.value,
      stack: 'group',
    },
  ],
);
const meanTicketDatasets = computed(() =>
  [
    {
      label: 'Ticket promedio neto',
      borderColor: '#9CAF88',
      backgroundColor: '#9CAF88',
      data: meanTicketValues.value,
    },
  ],
);
</script>
