<template>
  <div class="flex relative w-full">
    <div
      v-if="isMobileOrTablet && menuStore.showShopAdminMenu"
      class="fixed inset-0 z-10 bg-gray-800/50"
      aria-hidden="true"
      @click="menuStore.showShopAdminMenu = false"
    />
    <transition
      enter-active-class="transition-transform duration-300"
      enter-from-class="-translate-x-full"
      enter-to-class="translate-x-0"
      leave-active-class="transition-transform duration-300"
      leave-from-class="translate-x-0"
      leave-to-class="-translate-x-full"
    >
      <aside
        v-if="!isMobileOrTablet || menuStore.showShopAdminMenu"
        id="maker-admin-menu"
        class="absolute z-20 shrink-0 p-2 py-5 w-3/5 h-full bg-white sm:w-52 md:relative md:top-0 lg:w-60"
      >
        <div class="flex sticky top-0 flex-col w-full">
          <button
            v-if="isMobileOrTablet"
            class="ml-auto"
            @click="menuStore.showShopAdminMenu = false"
          >
            <XMarkIcon
              class="w-5 h-5 text-gray-700"
            />
          </button>
          <div class="flex flex-col p-2 mb-5">
            <div
              v-for="option, key in options"
              :key="key"
              class="flex flex-col shrink-0 py-2 w-full text-sm text-left md:text-base"
            >
              <button
                class="flex items-center"
                :disabled="key === currentOptionKey && withSubOptions(key)"
                @click="setCurrentOptionKey(key, option['subOptions'] ? Object.keys(option['subOptions'])[0] : '')"
              >
                <div
                  :class="{
                    'border-lk-green': key === currentOptionKey,
                    'border-gray-200': key === currentOptionKey && withSubOptions(key),
                    'border-transparent': key !== currentOptionKey }"
                  class="mr-3 h-6 border-2"
                />
                <span
                  class="hover:font-semibold"
                  :class="{ 'font-semibold': key === currentOptionKey }"
                >
                  {{ option['name'] }}
                </span>
                <ChevronDownIcon
                  v-if="withSubOptions(key)"
                  class="shrink-0 ml-auto w-4 h-4"
                  :class="{ 'rotate-180 transform duration-150 ease-linear': key === currentOptionKey }"
                />
              </button>
              <div
                v-if="key === currentOptionKey && withSubOptions(key)"
                class="flex flex-col"
              >
                <button
                  v-for="subOptionName, subOrderKey in option['subOptions']"
                  :key="subOrderKey"
                  class="flex items-center"
                  @click="setCurrentOptionKey(key, subOrderKey)"
                >
                  <div
                    :class="{ 'border-lk-green': subOrderKey === currentSubOptionKey,
                              'border-gray-200': subOrderKey !== currentSubOptionKey }"
                    class="mr-3 h-7 border-2"
                  />
                  <span
                    class="ml-2 font-light text-left"
                  >
                    {{ subOptionName }}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </aside>
    </transition>
    <div class="flex flex-col items-center py-3 w-full h-full bg-gray-50 sm:py-10 lg:py-10">
      <button
        v-if="isMobileOrTablet"
        class="flex items-center self-start px-2 mb-3 text-base"
        @click="menuStore.showShopAdminMenu = !menuStore.showShopAdminMenu"
      >
        <span class="mr-1">Menu</span>
        <ChevronRightIcon class="shrink-0 w-4 h-4" />
      </button>
      <template v-if="currentOptionKey === 'shop'">
        <shop-show-wrapper
          v-if="currentSubOptionKey === '' || currentSubOptionKey === 'information'"
          :shop-id="shopId"
          class="w-full"
        />
        <shop-addresses
          v-else-if="currentSubOptionKey === 'addresses' && shopId"
          :shop-id="shopId"
        />
      </template>
      <shop-users
        v-else-if="currentOptionKey === 'users' && shopId"
        class="p-0 md:p-0"
        :shop-id="shopId"
      />
      <order-list
        v-else-if="currentOptionKey === 'orders' && shopId"
        :shop-id="shopId"
        :with-title="false"
        class="p-0 md:p-0"
      />
      <shop-unpaid-orders
        v-else-if="currentOptionKey === 'unpaidOrders' && shopId"
        :shop-id="shopId"
      />
      <shop-sub-orders
        v-else-if="currentOptionKey === 'subOrders' && shopId"
        :shop-id="shopId"
      />
      <shop-incidence-report-list
        v-else-if="currentOptionKey === 'incidenceReports' && shopId"
        :shop-id="shopId"
      />
      <shop-maker-list
        v-else-if="currentOptionKey === 'makers' && shopId"
        :shop-id="shopId"
      />
      <shop-payment-list
        v-else-if="currentOptionKey === 'payments' && shopId"
        :shop-id="shopId"
      />
      <shop-refer
        v-else-if="currentOptionKey === 'refer' && shopId"
        :shop-id="shopId"
      />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, computed, onBeforeMount, inject } from 'vue';
import useSessionStore from 'stores/user-store';
import { ChevronRightIcon, ChevronDownIcon, XMarkIcon } from '@heroicons/vue/24/solid';
import { keys } from 'lodash';
import useGeneralMenuStore from 'stores/general-menu-store';
import ShopShowWrapper from './shop-show-wrapper.vue';
import ShopUsers from './shop-users.vue';
import OrderList from './order-list.vue';
import ShopPaymentList from './shop-payment-list.vue';
import useUrlMethods from './use/url-methods';
import ShopRefer from './shop-refer.vue';
import ShopUnpaidOrders from './shop-unpaid-orders.vue';
import ShopAddresses from './shop-addresses.vue';
import shopSubOrders from './shop-sub-orders.vue';
import ShopMakerList from './shop-maker-list.vue';
import shopIncidenceReportList from './shop-incidence-report-list.vue';

interface ShopAccountProps {
  optionalShopId?: number;
  isSuperAdmin?: boolean;
}

const props = withDefaults(defineProps<ShopAccountProps>(), {
  optionalShopId: undefined,
  isSuperAdmin: false,
});

const isMobileOrTablet = inject('isMobileOrTablet');

const menuStore = useGeneralMenuStore();
const sessionStore = useSessionStore();
const shopId = computed(() => (props.isSuperAdmin ? props.optionalShopId : sessionStore.user?.shopId));

const options = ref({
  orders: { name: 'Ordenes', subOptions: {} },
  unpaidOrders: { name: 'Ordenes pendientes', subOptions: {} },
  subOrders: { name: 'Seguimientos', subOptions: {} },
  incidenceReports: { name: 'Incidencias', subOptions: {} },
  makers: { name: 'Marcas compradas', subOptions: {} },
  shop: { name: 'Mi tienda', subOptions: { information: 'Información', addresses: 'Direcciones' } },
  refer: { name: 'Referir', subOptions: {} },
  payments: { name: 'Pagos / Créditos', subOptions: {} },
  users: { name: 'Usuarios', subOptions: {} },
});
const currentOptionKey = ref('orders');
const currentSubOptionKey = ref('');

function withSubOptions(option: keyof typeof options.value) {
  return Object.keys(options.value[option].subOptions).length > 0;
}

const { updateUrlParams, getQueryParam } = useUrlMethods();
function setCurrentOptionKey(key: string, subOrderKey?: string) {
  currentOptionKey.value = key;
  currentSubOptionKey.value = subOrderKey || '';
  updateUrlParams({ tab: key, subTab: currentSubOptionKey.value });
}

onBeforeMount(() => {
  const tab = getQueryParam('tab');
  const subTab = getQueryParam('subTab');
  if (keys(options.value).includes(tab)) {
    currentOptionKey.value = tab;
    if (subTab) {
      currentSubOptionKey.value = subTab;
    } else if (withSubOptions(tab as keyof typeof options.value)) {
      currentSubOptionKey.value = Object.keys(options.value[tab as keyof typeof options.value].subOptions)[0];
    }
  }
});
</script>
