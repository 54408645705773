<template>
  <Popover
    v-slot="{ open }"
    class="relative"
  >
    <PopoverButton
      class="flex items-center space-x-1 text-sm focus:outline-none md:space-x-2 md:text-base"
    >
      <p v-if="mainLabelShowCondition">
        {{ mainLabel }}
      </p>
      <EllipsisVerticalIcon class="w-4 h-4 md:w-5 md:h-5" />
    </PopoverButton>
    <div
      v-if="open"
      :class="[arrowPositionClasses, { 'border-lk-light-gray': !hrefs[0], 'border-lk-green': hrefs[0] }]"
      class="absolute z-20 border-8 border-t-0 border-x-transparent border-solid"
    />
    <PopoverPanel
      class="flex overflow-y-auto absolute right-0 z-10 flex-col py-0 mt-2 w-52 max-h-96 text-sm rounded-md border border-lk-green divide-y divide-lk-green focus:outline-none origin-top-right"
    >
      <a
        v-for="label, index in labels"
        :key="`${label}-${index}`"
        class="flex justify-between items-center p-2 space-x-2"
        :class="{ 'hover:bg-lk-green hover:text-white text-lk-green bg-white hover:font-semibold': hrefs[index],
                  'bg-lk-light-gray text-gray-700': !hrefs[index] }"
        :href="hrefs[index]"
        target="_blank"
        noopener
        :disabled="!hrefs[index]"
      >
        <slot
          :name="`label-${index}`"
          :label="label"
        >
          <span>{{ label }}</span>
          <CheckCircleIcon
            v-if="hrefs[index]"
            class="w-4 h-4"
          />
          <ClockIcon
            v-if="!hrefs[index]"
            class="w-4 h-4"
          />
        </slot>
      </a>
      <div
        v-for="prop, index in propComponents"
        :key="index"
        class="flex justify-between items-center p-2 space-x-2 hover:font-semibold"
        :class="{ 'bg-lk-light-gray text-gray-700': !components[index],
                  'hover:bg-lk-green hover:text-white bg-white text-lk-green': components[index] }"
      >
        <component
          :is="components[index]"
          v-bind="prop"
        />
        <CheckCircleIcon
          v-if="components[index]"
          class="w-4 h-4"
        />
        <ClockIcon
          v-if="!components[index]"
          class="w-4 h-4"
        />
      </div>
    </PopoverPanel>
  </Popover>
</template>
<script lang="ts" setup>
import { computed, type Component } from 'vue';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import { EllipsisVerticalIcon, ClockIcon, CheckCircleIcon } from '@heroicons/vue/24/outline';

interface LokalDropDownProps {
  labels: string[],
  hrefs?: (string | undefined)[]
  propComponents?: Record<string, unknown>[]
  components?: Component[]
  mainLabel: string
  mainLabelShowCondition?: boolean
  arrowPositionOptions?: 'center' | 'left' | 'right' | ''
  arrowClasses?: string
}

const props = withDefaults(defineProps<LokalDropDownProps>(), {
  mainLabelShowCondition: true,
  hrefs: () => [] as (string | undefined)[],
  propComponents: () => [] as Record<string, unknown>[],
  components: () => [] as Component[],
  arrowPositionOptions: 'right',
  arrowClasses: '',
});

const arrowPositionClasses = computed(() => {
  if (props.arrowClasses) return props.arrowClasses;
  if (props.arrowPositionOptions === 'center') return 'left-1/2 -translate-x-1/2';
  if (props.arrowPositionOptions === 'left') return 'left-4';
  if (props.arrowPositionOptions === 'right') return 'right-4';

  return '';
});
</script>
