<template>
  <Dialog
    :open="modelValue"
    as="div"
    class="relative z-50"
    @close="emit('update:modelValue', false)"
  >
    <div
      class="fixed inset-0 bg-black/30"
      aria-hidden="true"
    />
    <div class="flex fixed inset-0 justify-center items-center p-2 md:p-0">
      <DialogPanel class="flex overflow-y-auto flex-col p-2 w-full max-w-md text-gray-700 bg-gray-50 rounded md:p-4">
        <button
          class="mb-2 ml-auto focus:outline-none"
          @click="emit('update:modelValue', false)"
        >
          <XMarkIcon class="w-4 h-4 fill-gray-700 md:w-5 md:h-5" />
        </button>
        <template v-if="!messageSent">
          <DialogTitle class="mb-3 text-xl text-left md:text-2xl">
            ¿Hay productos de
            <span class="text-lk-green">{{ maker.name }}</span>
            que no están en Lokal?
          </DialogTitle>
          <DialogDescription class="flex flex-col mb-3 text-sm md:text-base">
            Describe detalladamente los productos que no están presentes
            en Lokal para que los podamos solicitar.
          </DialogDescription>
          <form
            class="flex flex-col mb-2 md:mb-0"
            @submit="onSubmit"
          >
            <div class="flex flex-col mb-3">
              <Field
                v-model="content"
                as="textarea"
                class="p-2 h-32 text-sm font-light focus:outline-none md:text-base"
                :rules="{ required: true }"
                name="content"
                label="Contenido"
              />
              <ErrorMessage
                name="content"
                class="text-sm text-red-500"
              />
            </div>
            <lokal-loading
              v-if="isLoading"
              :loading="isLoading"
              class="self-center"
            />
            <button
              v-else
              class="self-center p-2 w-full text-sm text-white bg-lk-green hover:bg-lk-green-deep rounded-md md:text-base"
            >
              Enviar
            </button>
          </form>
        </template>
        <div
          v-else
          class="flex flex-col items-center"
        >
          <CheckBadgeIcon class="w-52 h-52 fill-lk-green" />
          <DialogTitle class="flex items-center mb-1 text-xl text-left md:text-2xl">
            ¡Hemos recibido tu solicitud!
          </DialogTitle>
          <p class="mb-5 font-light">
            Nos contactaremos contigo lo antes posible.
          </p>
          <button
            class="self-center p-2 w-full text-sm text-white bg-lk-green hover:bg-lk-green-deep rounded-md md:w-3/4 md:text-base"
            @click="emit('update:modelValue', false)"
          >
            Aceptar
          </button>
        </div>
      </DialogPanel>
    </div>
  </dialog>
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue';
import { XMarkIcon, CheckBadgeIcon } from '@heroicons/vue/24/solid';
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  DialogDescription,
} from '@headlessui/vue';
import { Field, useForm, ErrorMessage } from 'vee-validate';
import { useMutation } from 'vue-query';
import missingProductsRequestsApi from 'api/missing-products-requests';

interface LokalMessageDialogProps {
  modelValue: boolean
  maker: Maker
}
const props = withDefaults(defineProps<LokalMessageDialogProps>(), {
  modelValue: false,
});

const emit = defineEmits<{(e: 'update:modelValue', value: boolean): void}>();

const content = ref('');
const messageSent = ref(false);

const makerQuestionMutation = useMutation(
  () => missingProductsRequestsApi.create({ makerId: props.maker.id, content: content.value }),
  {
    onSuccess: () => {
      messageSent.value = true;
    },
  },
);
const isLoading = computed(() => makerQuestionMutation.isLoading.value);
const { handleSubmit } = useForm();
const onSubmit = handleSubmit(() => {
  makerQuestionMutation.mutate();
});
</script>
