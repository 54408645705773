import { csrfToken } from '@rails/ujs';
import { ref } from 'vue';
import api from './index';

const token = ref(csrfToken());
let headers = {};
if (token.value) {
  headers = { 'X-CSRF-Token': token.value };
}

export default {
  get(id: number | string) {
    const path = `/api/internal/discount_codes/${id}`;

    return api({
      method: 'get',
      url: path,
      headers,
    });
  },
  getByCode(makerId: number, makerDiscountCode: string) {
    const path = `/api/internal/makers/${makerId}/discount_codes/by_code?code=${makerDiscountCode}`;

    return api({
      method: 'get',
      url: path,
      headers,
    });
  },
  validate(makerId: number, makerDiscountCode: string) {
    const path = `/api/internal/makers/${makerId}/discount_codes/validate?code=${makerDiscountCode}`;

    return api({
      method: 'get',
      url: path,
      headers,
    });
  },
  byCodeValid(makerId: number, makerDiscountCode: string) {
    const path = `/api/internal/makers/${makerId}/discount_codes/by_code_valid?code=${makerDiscountCode}`;

    return api({
      method: 'get',
      url: path,
      headers,
    });
  },
  createReferredShopsCode(makerId: number, makerDiscount: number) {
    const path = `/api/internal/makers/${makerId}/discount_codes/create_referred_shops_code`;

    return api({
      method: 'post',
      url: path,
      headers,
      data: {
        discountCode: { makerDiscount },
      },
    });
  },
  update(id: number, discountCode: Partial<DiscountCode>) {
    const path = `/api/internal/discount_codes/${id}`;

    return api({
      method: 'put',
      url: path,
      headers,
      data: {
        discountCode,
      },
    });
  },
};
