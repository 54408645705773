<template>
  <form
    class="flex flex-col w-full"
    @submit="onSubmit"
  >
    <p class="mb-1">
      Nombre de marca
    </p>
    <form-input
      v-model="form.name"
      name="name"
      class="mb-1"
      placeholder="Nombre"
      type="text"
      label-name="Nombre"
      :rules="{ required: true }"
    />
    <p class="mb-1">
      Monto mínimo de compra ({{ useFormatCurrency(form.minimumPurchaseAmountCents) }})
    </p>
    <form-input
      v-model="form.minimumPurchaseAmountCents"
      name="minimumPurchaseAmountCents"
      class="mb-1"
      placeholder="Monto mínimo de compra"
      type="text"
      label-name="Monto mínimo de compra"
      :rules="{ required: true }"
    />
    <p class="mb-1">
      Tiempo promedio de entrega (días)
    </p>
    <form-input
      v-model="form.averageDeliveryDays"
      name="averageDeliveryDays"
      class="mb-1"
      placeholder="Tiempo promedio de entrega"
      type="text"
      label-name="Tiempo promedio de entrega"
      :rules="{ required: true }"
    />
    <lokal-tooltip
      main-label="¿Deseas ofrecer muestras?"
      class="mb-1"
      :information="samplesEnabledInformation"
      button-classes="flex items-center space-x-1"
      information-classes="w-60 p-1 md:p-2"
      position-options="left"
    />
    <lokal-switch v-model="form.samplesEnabled" />
    <p class="mb-1">
      Descripción
    </p>
    <tip-tap-editor
      v-model="form.description"
      class="mb-5"
      @update:model-value="updateDescription"
    />
    <lokal-loading
      v-if="isLoading"
      :loading="isLoading"
      class="self-center"
    />
    <button
      v-else
      class="self-center p-2 w-full text-sm text-white bg-lk-green hover:bg-lk-green-deep rounded-md md:text-base"
    >
      Actualizar
    </button>
  </form>
</template>
<script lang="ts" setup>
import { ref, computed } from 'vue';
import { useMutation } from 'vue-query';
import DOMPurify from 'isomorphic-dompurify';
import makersApi from 'api/makers';
import { useForm } from 'vee-validate';
import useFormatCurrency from './use/format-currency';
import tipTapEditor from './shared/tip-tap-editor.vue';
import formInput from './shared/form-input.vue';
import LokalSwitch from './shared/lokal-switch.vue';
import LokalTooltip from './shared/lokal-tooltip.vue';

const props = defineProps<{
  maker: Maker;
}>();

const form = ref({
  name: props.maker.name,
  minimumPurchaseAmountCents: props.maker.minimumPurchaseAmountCents,
  averageDeliveryDays: props.maker.averageDeliveryDays,
  description: props.maker.description,
  samplesEnabled: props.maker.samplesEnabled,
});

const emit = defineEmits<{(event: 'maker-updated'): void}>();
const { mutate, isLoading } = useMutation(() => makersApi.update(props.maker.id, form.value), {
  onSuccess: () => {
    emit('maker-updated');
  },
});

const { handleSubmit } = useForm();
const onSubmit = handleSubmit(() => {
  mutate();
});

function updateDescription(value: string) {
  form.value.description = DOMPurify.sanitize(value, { USE_PROFILES: { html: true } });
}

const samplesEnabledInformation = computed(() =>
  `Con esta opción las tiendas podrán solicitar muestra de tus productos.
   Por las muestras puedes cobrar el monto que desees.`);
</script>
