<template>
  <div class="relative">
    <Line
      :id="chartId"
      :data="data"
      :options="options"
      width="400"
      height="400"
    />
  </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import { Line } from 'vue-chartjs';
import {
  Chart as ChartJS, Title, Tooltip, Legend, LineElement, PointElement, CategoryScale, LinearScale, type ChartData,
} from 'chart.js';

// Register the necessary components
ChartJS.register(Title, Tooltip, Legend, LineElement, PointElement, CategoryScale, LinearScale);

declare interface LineChartProps {
  chartId?: string;
  labels: number[] | string[];
  datasets: unknown[];
  options: Record<string, unknown>;
}

const props = withDefaults(defineProps<LineChartProps>(), {
  chartId: 'line-chart',
});

const data = computed(() => ({
  labels: props.labels,
  datasets: props.datasets,
} as ChartData<'line', number[], unknown>));
</script>
