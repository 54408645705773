import { computed, ref, type ComputedRef, type Ref } from 'vue';
import { useQuery } from 'vue-query';
import subOrdersApi from 'api/sub-orders';

const SUB_ORDER_PAGINATION_SIZE = 24;

interface MakerSubOrdersOptions {
  page: Ref<number>,
  queryParams: ComputedRef<string>,
  enabled?: ComputedRef<boolean>,
  paginationSize?: number,
}

const defaultOptions = {
  page: ref(1),
  queryParams: computed(() => ''),
  enabled: computed(() => true),
  paginationSize: SUB_ORDER_PAGINATION_SIZE,
} as MakerSubOrdersOptions;

export default function useMakerSubOrders(makerId: ComputedRef<number>, options = defaultOptions) {
  const { page, queryParams, enabled, paginationSize } = options;
  const subOrdersQuery = useQuery(
    ['subOrders', makerId.value, page, queryParams, paginationSize],
    () => subOrdersApi.index(makerId.value, page.value, queryParams.value, paginationSize),
    { keepPreviousData: true, enabled },
  );
  const isLoadingSubOrderQuery = computed(() => subOrdersQuery.isLoading.value || subOrdersQuery.isPreviousData.value);
  const subOrders = computed(() => subOrdersQuery.data.value?.data.subOrders as SubOrder[]);

  return { subOrdersQuery, isLoadingSubOrderQuery, subOrders };
}
