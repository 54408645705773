<template>
  <div class="flex flex-col w-full">
    <div class="grid grid-cols-2 gap-2 items-center mb-4 lg:grid-cols-3 2xl:flex 2xl:flex-row 2xl:gap-0 2xl:space-y-0 2xl:space-x-5">
      <div class="flex justify-center items-center place-self-start p-2 w-full rounded-md border md:p-3 lg:p-4 xl:p-5 xl:h-32 2xl:w-44">
        <lokal-loading
          v-if="destinationMetricsLoading"
          :loading="destinationMetricsLoading"
          size="xs"
        />
        <div
          v-else
          class="flex flex-col items-center"
        >
          <lokal-tooltip
            :information="'Ventas totales netas incluida la comisión de Lokal'"
            class="ml-auto text-sm md:text-base"
            button-classes="flex items-center space-x-1"
            information-classes="w-40 md:w-52 p-1 md:p-2"
            position-options="center"
            main-label="Ventas totales"
          />
          <p class="text-xl md:text-2xl">
            {{ useFormatCurrency(totalSales) }}
          </p>
        </div>
      </div>
      <div class="flex justify-center items-center place-self-end p-2 w-full rounded-md border md:p-3 lg:p-4 xl:p-5 xl:h-32 2xl:w-44">
        <lokal-loading
          v-if="destinationMetricsLoading"
          :loading="destinationMetricsLoading"
          size="xs"
        />
        <div
          v-else
          class="flex flex-col items-center"
        >
          <p class="text-sm text-center md:text-base">
            Ticket promedio
          </p>
          <p class="text-xl md:text-2xl">
            {{ subOrders?.length ? useFormatCurrency(round(averageSalesTicket)) : '-' }}
          </p>
        </div>
      </div>
      <div class="flex justify-center items-center place-self-end p-2 w-full rounded-md border md:p-3 lg:p-4 xl:p-5 xl:h-32 2xl:w-44">
        <lokal-loading
          v-if="destinationMetricsLoading"
          :loading="destinationMetricsLoading"
          size="xs"
        />
        <div
          v-else
          class="flex flex-col items-center"
        >
          <lokal-tooltip
            :information="'La suma de todas las comisiones dividido los ingresos totales'"
            class="ml-auto text-sm md:text-base"
            button-classes="flex items-center space-x-1"
            information-classes="w-40 md:w-52 p-1 md:p-2"
            position-options="center"
            main-label="Comisión promedio"
          />
          <p class="text-xl md:text-2xl">
            {{ subOrders?.length ? `${round(commissionFraction * TOTAL_PERCENTAGE, 2)}%` : '-' }}
          </p>
        </div>
      </div>
      <div class="flex justify-center items-center place-self-end p-2 w-full rounded-md border md:p-3 lg:p-4 xl:p-5 xl:h-32 2xl:w-44">
        <lokal-loading
          v-if="destinationMetricsLoading"
          :loading="destinationMetricsLoading"
          size="xs"
        />
        <div
          v-else
          class="flex flex-col items-center"
        >
          <p class="text-sm text-center md:text-base">
            Pedidos
          </p>
          <p class="text-xl md:text-2xl">
            {{ totalSubOrders }}
          </p>
        </div>
      </div>
      <div class="flex justify-center items-center place-self-center p-2 w-full rounded-md border md:p-3 lg:p-4 xl:p-5 xl:h-32 2xl:w-44">
        <lokal-loading
          v-if="destinationMetricsLoading"
          :loading="destinationMetricsLoading"
          size="xs"
        />
        <div
          v-else
          class="flex flex-col items-center"
        >
          <p class="text-sm text-center md:text-base">
            Tiendas activas
          </p>
          <p class="text-xl md:text-2xl">
            {{ shopIds.length }}
          </p>
        </div>
      </div>
      <div class="flex justify-center items-center place-self-end p-2 w-full rounded-md border md:p-3 lg:p-4 xl:p-5 xl:h-32 2xl:w-44">
        <lokal-loading
          v-if="referredContactsCountLoading"
          :loading="referredContactsCountLoading"
          size="xs"
        />
        <div
          v-else
          class="flex flex-col items-center"
        >
          <p class="text-sm text-center md:text-base">
            Usuarios referidos
          </p>
          <p class="text-xl md:text-2xl">
            {{ referredCountactsCount }}
          </p>
        </div>
      </div>
    </div>
    <div class="flex flex-col space-y-3 divide-y">
      <maker-metrics-sales-by-region
        :maker="maker"
        :query-params="queryParams"
      />
      <maker-metrics-sub-orders-per-region
        :maker="maker"
        :query-params="queryParams"
      />
      <maker-metrics-clients-by-region
        :maker="maker"
        :query-params="queryParams"
      />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import { sumBy, groupBy, meanBy, round } from 'lodash';
import referredContactsApi from 'api/referred-contacts';
import { useQuery } from 'vue-query';
import useDestinationMetricsQuery from './queries/destination-metrics-query';
import MakerMetricsSalesByRegion from './maker-metrics-sales-by-region.vue';
import MakerMetricsSubOrdersPerRegion from './maker-metrics-sub-orders-per-region.vue';
import MakerMetricsClientsByRegion from './maker-metrics-clients-by-region.vue';
import useFormatCurrency from './use/format-currency';
import LokalTooltip from './shared/lokal-tooltip.vue';

const TOTAL_PERCENTAGE = 100;

const props = defineProps<{
  maker: Maker,
  initialDate: string,
  endDate: string,
}>();

const queryParams = computed(() =>
  `q[pending_at_gteq]=${props.initialDate}&q[pending_at_lteq]=${props.endDate}&`);

const {
  destinationMetricsLoading, subOrders,
} = useDestinationMetricsQuery(computed(() => props.maker.id), computed(() => true), queryParams);

const groupedByShop = computed(() => groupBy(subOrders.value, 'shopId'));
const shopIds = computed(() => Object.keys(groupedByShop.value));
const totalSales = computed(() =>
  sumBy(subOrders.value, (subOrder) => subOrder.totalWholesaleAmountCents - subOrder.volumeDiscountAmountCents -
    subOrder.totalDiscountAmountCents));
const averageSalesTicket = computed(() =>
  meanBy(subOrders.value, (subOrder) => subOrder.totalWholesaleAmountCents - subOrder.volumeDiscountAmountCents -
    subOrder.totalDiscountAmountCents));
const totalSubOrderCommissions = computed(() =>
  sumBy(subOrders.value,
    (subOrder: SubOrder) => {
      const base = subOrder.totalWholesaleAmountWithoutCommissionCents * subOrder.commission;

      return base / (TOTAL_PERCENTAGE - subOrder.commission);
    }),
);
const commissionFraction = computed(() => totalSubOrderCommissions.value / totalSales.value);
const totalSubOrders = computed(() => subOrders.value.length);

const queryParamsForReferredContacts = computed(() =>
  `q[created_at_gteq]=${props.initialDate}&q[created_at_lteq]=${props.endDate}&`);

const referredCountactsCountQuery = useQuery(['referredCountactsCount', props.maker, queryParamsForReferredContacts],
  () => referredContactsApi.indexCount(props.maker.id, queryParamsForReferredContacts.value));
const referredContactsCountLoading = computed(() => referredCountactsCountQuery.isLoading.value);
const referredCountactsCount = computed(() => referredCountactsCountQuery.data.value?.data.count as number);
</script>
