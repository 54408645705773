<template>
  <Dialog
    :open="modelValue"
    as="div"
    class="relative z-50"
    @close="emit('update:modelValue', false)"
  >
    <div
      class="fixed inset-0 bg-black/30"
      aria-hidden="true"
    />
    <div class="flex fixed inset-0 justify-center items-center">
      <DialogPanel class="flex overflow-y-auto flex-col p-2 w-full max-w-md bg-gray-50 rounded md:p-4">
        <button
          class="mb-2 ml-auto focus:outline-none"
          @click="emit('update:modelValue', false)"
        >
          <XMarkIcon class="w-6 h-6 fill-gray-700" />
        </button>
        <DialogTitle
          v-if="volumeDiscount?.id"
          class="mb-3 font-serif text-xl text-center text-gray-700 md:text-3xl"
        >
          Editar descuento por volumen #{{ volumeDiscount.id }}
        </DialogTitle>
        <DialogTitle
          v-else
          class="mb-3 font-serif text-xl text-center text-gray-700 md:text-3xl"
        >
          Nuevo descuento por volumen
        </DialogTitle>
        <DialogDescription
          class="mb-5 text-sm font-light text-gray-700 md:text-base"
        >
          <form @submit="onSubmit">
            <p class="text-sm md:text-base">
              Porcentaje de descuento adicional
            </p>
            <div class="flex flex-col mb-2 w-full">
              <div class="flex items-center w-full text-sm rounded-md border md:text-base">
                <Field
                  v-model="discount"
                  type="number"
                  label="Porcentaje de descuento"
                  class="p-2 w-full bg-white rounded-md rounded-r-none focus:outline-none"
                  name="discount"
                  :rules="{ required: true, min_value: 0, max_value: 100}"
                />
                <span class="p-2 ml-auto text-gray-400 bg-white rounded-md rounded-l-none">%</span>
              </div>
              <ErrorMessage
                name="discount"
                class="mt-1 text-sm text-red-500"
              />
            </div>
            <p class="text-sm md:text-base">
              Monto mínimo de la orden (SIN IVA) para aplicar el descuento sobre precios mayoristas
            </p>
            <div class="flex flex-col mb-2 w-full">
              <Field
                v-model="shadowMinimumAmountCents"
                class="p-2 w-full text-sm rounded-md border focus:outline-none md:text-base"
                name="shadowMinimumAmountCents"
                :rules="{ required: true }"
                @focus="shadowMinimumAmountCents = reverseFormatCurrency(shadowMinimumAmountCents)"
                @blur="shadowMinimumAmountCents = useFormatCurrency(toNumber(shadowMinimumAmountCents))"
              />
              <Field
                v-model="minimumAmountCents"
                :rules="{ required: true, min_value: 1 }"
                name="minimumAmountCents"
                label="Monto mínimo"
                hidden
              />
              <ErrorMessage
                name="minimumAmountCents"
                class="mt-1 text-sm text-red-500"
              />
            </div>
            <p class="mb-3 text-xs font-light md:mb-4 md:text-sm">
              Nota: El descuento por volumen se aplica sobre el precios mayorista. Por ejemplo, si
              a un producto con precio mayorista de $7.000 y un descuento por volumen de 5%,
              el precio final del producto será de $6.650.
            </p>
            <lokal-loading
              v-if="isLoading"
              :loading="isLoading"
              class="self-center"
            />
            <button
              v-else
              class="p-2 w-full text-sm font-normal text-lk-green hover:text-white bg-white hover:bg-lk-green rounded-md border border-lk-green md:text-base"
            >
              {{ volumeDiscount?.id ? 'Actualizar' : 'Crear' }}
            </button>
          </form>
        </DialogDescription>
      </DialogPanel>
    </div>
  </dialog>
</template>
<script lang="ts" setup>
import { XMarkIcon } from '@heroicons/vue/24/solid';
import { Field, useForm, ErrorMessage } from 'vee-validate';
import { ref, watch, computed } from 'vue';
import { isNumber, toNumber } from 'lodash';
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  DialogDescription,
} from '@headlessui/vue';
import { useMutation } from 'vue-query';
import volumeDiscountsApi from 'api/volume-discounts';
import useFormatCurrency from './use/format-currency';

interface LokalMessageDialogProps {
  modelValue: boolean
  makerId?: number
  volumeDiscount?: VolumeDiscount
}
const props = withDefaults(defineProps<LokalMessageDialogProps>(), {
  modelValue: false,
  makerId: undefined,
  volumeDiscount: undefined,
});

const shadowMinimumAmountCents = ref(
  props.volumeDiscount?.id ? useFormatCurrency(props.volumeDiscount?.minimumAmountCents) : '0');
const minimumAmountCents = ref(props.volumeDiscount?.minimumAmountCents || 0);
const discount = ref(props.volumeDiscount?.discount);

const emit = defineEmits<{(e: 'reload-volume-discounts'): void,
(e: 'update:modelValue', value: boolean): void
}>();

function emitEvents() {
  emit('update:modelValue', false);
  emit('reload-volume-discounts');
}

function reverseFormatCurrency(value: string) {
  return value.replace(/[^0-9]+/g, '');
}

watch(shadowMinimumAmountCents, (newValue) => {
  const reverseFormattedValue = toNumber(reverseFormatCurrency(newValue));
  if (isNumber(reverseFormattedValue)) {
    minimumAmountCents.value = reverseFormattedValue as number;
  }
});

const data = computed(() => ({
  discount: discount.value,
  minimumAmountCents: minimumAmountCents.value,
}));
const updateMutation = useMutation(() => volumeDiscountsApi.update(props.volumeDiscount?.id, data.value), {
  onSuccess: () => {
    emitEvents();
  },
});
const createMutation = useMutation(() => volumeDiscountsApi.create({ makerId: props.makerId, ...data.value }), {
  onSuccess: () => {
    emitEvents();
  },
});
const isLoading = computed(() => createMutation.isLoading.value || updateMutation.isLoading.value);
const { handleSubmit } = useForm();
const onSubmit = handleSubmit(() => {
  if (props.volumeDiscount?.id) {
    updateMutation.mutate();
  } else {
    createMutation.mutate();
  }
});
</script>
