<template>
  <div class="flex justify-center mt-5 mb-10 text-sm md:text-base">
    <button
      :class="{ 'cursor-default': currentPage === 1 }"
      :disabled="currentPage === 1"
      @click="emitChangePage(currentPage - 1)"
    >
      <ChevronRightIcon
        class="mr-1 w-4 h-4 text-gray-700 rotate-180 md:mr-4 md:w-5 md:h-5"
        :class="{ 'hover:text-lk-green': currentPage > 1 }"
      />
    </button>
    <button
      v-if="shadowPagesArray.length > 1"
      class="p-1 mr-1 text-gray-700 underline md:mr-4"
      :disabled="currentPage <= 1"
      :class="{ 'cursor-default': currentPage <= 1, 'hover:text-lk-green': currentPage > 1 }"
      @click="emitChangePage(1)"
    >
      Primera
    </button>
    <template v-if="secondPagesArrayEnabled">
      <button
        v-for="number in firstPagesArray"
        :key="number"
        class="p-1 mr-1 rounded-sm border-0 md:mr-4"
        :class="{ 'text-lk-green font-semibold': currentPage === number,
                  'text-gray-700 hover:text-lk-green': currentPage !== number }"
        @click="emitChangePage(number)"
      >
        {{ number }}
      </button>
      <div
        v-if="showDotPages"
        class="mr-1 text-gray-700 hover:text-lk-green md:mr-4"
      >
        . . .
      </div>
      <template v-if="secondPagesArrayEnabled">
        <button
          v-for="number in secondPagesArray"
          :key="number"
          class="p-1 mr-1 rounded-sm border-0 md:mr-4"
          :class="{ 'text-lk-green font-semibold': currentPage === number,
                    'text-gray-700 hover:text-lk-green': currentPage !== number }"
          @click="emitChangePage(number)"
        >
          {{ number }}
        </button>
      </template>
    </template>
    <template v-else>
      <button
        v-for="number in shadowPagesArray"
        :key="number"
        class="p-1 mr-1 rounded-sm border-0 md:mr-4"
        :class="{ 'text-lk-green font-semibold': currentPage === number,
                  'text-gray-700 hover:text-lk-green': currentPage !== number }"
        @click="emitChangePage(number)"
      >
        {{ number }}
      </button>
    </template>
    <button
      v-if="shadowPagesArray.length > 1"
      class="p-1 mr-1 text-gray-700 underline md:mr-4"
      :disabled="currentPage >= totalPages"
      :class="{ 'cursor-default': currentPage >= totalPages, 'hover:text-lk-green': currentPage < totalPages }"
      @click="emitChangePage(shadowPagesArray[shadowPagesArray.length - 1])"
    >
      Última
    </button>
    <button
      :class="{ 'cursor-default': currentPage === totalPages }"
      :disabled="currentPage === totalPages"
      @click="emitChangePage(currentPage + 1)"
    >
      <ChevronRightIcon
        class="mr-1 w-4 h-4 text-gray-700 md:mr-4 md:w-5 md:h-5"
        :class="{ 'hover:text-lk-green': currentPage > 1 }"
      />
    </button>
  </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import { ChevronRightIcon } from '@heroicons/vue/24/solid';

const DOUBLE = 2;
const PAGES_TO_SELECT = 3;
const TOTAL_PAGES_TO_SELECT = PAGES_TO_SELECT * DOUBLE;

const props = defineProps<{
  totalPages: number,
  currentPage: number,
}>();

const currentPageIndex = computed(() => props.currentPage - 1);
const secondPagesArrayEnabled = computed(() => props.totalPages >= TOTAL_PAGES_TO_SELECT);
const showDotPages = computed(() =>
  secondPagesArrayEnabled.value && props.currentPage <= props.totalPages - TOTAL_PAGES_TO_SELECT);
const shadowPagesArray = computed(() => Array(props.totalPages).fill('').map((_, i) => i + 1));
const secondPagesArray = computed(() => shadowPagesArray.value.slice(- PAGES_TO_SELECT));
const firstPagesArray = computed(() => {
  if (props.totalPages <= PAGES_TO_SELECT) {
    return shadowPagesArray.value;
  } else if (!showDotPages.value) {
    return shadowPagesArray.value.slice(- TOTAL_PAGES_TO_SELECT, - PAGES_TO_SELECT).filter(
      i => !secondPagesArray.value.includes(i));
  }

  return shadowPagesArray.value.slice(currentPageIndex.value, currentPageIndex.value + PAGES_TO_SELECT);
});

const emit = defineEmits<{(e: 'set-new-page', value: number): void}>();
function emitChangePage(newPageValue: number) {
  emit('set-new-page', newPageValue);
}
</script>
