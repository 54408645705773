import { csrfToken } from '@rails/ujs';
import { ref } from 'vue';
import { serialize } from 'object-to-formdata';
import api from './index';

const token = ref(csrfToken());
let headers = {};
if (token.value) {
  headers = { 'X-CSRF-Token': token.value };
}

export default {
  index(data: { imagableId: number, imagableType: string }) {
    const path = `/api/internal/images?imagable_id=${data.imagableId}&imagable_type=${data.imagableType}`;

    return api({
      method: 'get',
      url: path,
      headers,
    });
  },
  create(data: object) {
    const path = '/api/internal/images';
    const serializeData = serialize({ image: data });

    return api({
      method: 'post',
      url: path,
      headers,
      data: serializeData,
    });
  },
  update(imageId: number, data: Partial<Image>) {
    const path = `/api/internal/images/${imageId}`;

    return api({
      method: 'put',
      url: path,
      headers,
      data,
    });
  },
  destroy(imageId: number) {
    const path = `/api/internal/images/${imageId}`;

    return api({
      method: 'delete',
      url: path,
      headers,
    });
  },
  bulkCreate(imagesPartialData: Partial<Image>[]) {
    const path = '/api/internal/images/bulk_create';
    const serializeData = serialize({ images: imagesPartialData });

    return api({
      method: 'post',
      url: path,
      headers,
      data: serializeData,
    });
  },
};
