<template>
  <div class="flex relative flex-col w-full">
    <div class="sticky top-0 z-20 py-2 bg-gray-50">
      <button
        class="flex justify-between items-center px-4 w-full hover:text-white hover:bg-lk-green"
        @click="showDetails = !showDetails"
      >
        <a
          class="py-1 mb-1 text-base hover:underline md:text-lg"
          :href="`/makers/${items[0]?.product?.makerId}`"
          @click.stop
        >
          {{ makerName }}
        </a>
        <ChevronDownIcon
          class="shrink-0 w-5 h-5"
          :class="{ 'rotate-180 trasform duration-150 ease-linear': showDetails,
                    'trasform duration-150 ease-linear': !showDetails }"
        />
      </button>
      <div class="flex flex-col px-4">
        <lokal-loading
          v-if="isLoading"
          :loading="isLoading"
          size="xs"
        />
        <p
          v-else
          class="flex flex-wrap items-center space-x-1 text-sm md:text-base"
        >
          <span>{{ useFormatCurrency(minimumPurchaseAmount) }} min</span>
          <span v-if="filledBar < 100">·</span>
          <span v-if="filledBar < 100">{{ useFormatCurrency(amountToReachedMinimum) }} faltantes</span>
          <span v-else>alcanzado</span>
        </p>
        <div class="flex items-center">
          <div class="mr-2 w-full h-2 bg-gray-300 rounded-md">
            <div
              class="h-full bg-lk-green rounded-md"
              :style="{ width: filledBar + '%' }"
            />
          </div>
          <CheckCircleIcon
            class="w-5 h-5"
            :class="{ 'text-lk-green': filledBar === 100, 'text-gray-300': filledBar !== 100 }"
          />
        </div>
      </div>
    </div>
    <transition
      enter-active-class="transition duration-150"
      enter-from-class="-translate-y-2 opacity-50"
      enter-to-class="translate-y-0 transform opacity-100"
      leave-active-class="transition duration-150"
      leave-from-class="translate-y-0"
      leave-to-class="-translate-y-2 transform opacity-0"
    >
      <div
        v-if="showDetails"
        class="flex flex-col px-4"
      >
        <cart-sidebar-item
          v-for="item in items"
          :key="item.product.id"
          :item="item"
          class="mb-2"
        />
        <p
          v-if="totalVolumeDiscount > 0"
          class="flex space-x-1 text-sm"
        >
          <span>Desct x volumen ({{ currentVolumeDiscount.discount }}%):</span>
          <span>{{ useFormatCurrency(totalVolumeDiscount) }}</span>
        </p>
        <p class="flex space-x-1 text-sm">
          <span class="">Sub total neto:</span>
          <span
            v-if="totalVolumeDiscount > 0"
            :class="{ 'line-through': totalVolumeDiscount > 0 }"
          >
            {{ useFormatCurrency(totalProductPriceForItems(items)) }}
          </span>
          <span>{{ useFormatCurrency(totalProductsPriceWithVolumeDiscount) }}</span>
        </p>
      </div>
    </transition>
  </div>
</template>
<script lang="ts" setup>
import { ref, computed, watch, onMounted } from 'vue';
import { useQuery } from 'vue-query';
import { CheckCircleIcon, ChevronDownIcon } from '@heroicons/vue/24/solid';
import makersApi from 'api/makers';
import useCartStore from 'stores/cart';
import cartSidebarItem from './cart-sidebar-item.vue';
import useTotalItemPriceMethods from './use/total-item-price-methods';
import useFormatCurrency from './use/format-currency';
import useMakerVolumeDiscounts from './use/maker-volume-discounts';
import useMakerDestinationsQuery from './queries/maker-destinations-query';

const TOTAL_PORCENTAGE = 100;

const props = defineProps<{
  makerName: string | number
}>();

const cartStore = useCartStore();
const items = computed(() => cartStore.groupedItems[props.makerName]);
const showDetails = ref(false);
const makerId = computed(() => items.value?.[0]?.product.makerId);
const makerQuery = useQuery(['maker', makerId], () => makersApi.get(makerId.value), {
  enabled: computed(() => !!makerId.value),
});
const isLoading = computed(() => makerQuery.isLoading.value);
const maker = computed(() => makerQuery.data.value?.data.maker as Maker);

const { currentMakerDestination } = useMakerDestinationsQuery(makerId);

const minimumPurchaseAmount = computed(() =>
  currentMakerDestination.value?.minimumPurchaseAmountCents || maker.value?.minimumPurchaseAmountCents);
const { totalProductPriceForItems } = useTotalItemPriceMethods();
const filledBar = computed(() => {
  if (totalProductPriceForItems(items.value) >= minimumPurchaseAmount.value) {
    return TOTAL_PORCENTAGE;
  }

  return (totalProductPriceForItems(items.value) * TOTAL_PORCENTAGE) / minimumPurchaseAmount.value;
});
const amountToReachedMinimum = computed(() => {
  const diff = minimumPurchaseAmount.value - totalProductPriceForItems(items.value);

  return diff > 0 ? diff : 0;
},
);

const {
  currentVolumeDiscount,
  totalVolumeDiscount,
  totalProductsPriceWithVolumeDiscount,
} = useMakerVolumeDiscounts(makerId);

const inMakersPath = computed(() =>
  window.location.pathname.includes(`/makers/${items.value?.[0]?.product.makerId}`) ||
    window.location.pathname.includes(`${maker.value?.slug}`));

onMounted(() => {
  showDetails.value = inMakersPath.value;
});

watch(() => inMakersPath.value, () => {
  showDetails.value = inMakersPath.value;
});
</script>
