import { csrfToken } from '@rails/ujs';
import { ref } from 'vue';
import api from './index';

const PAGE_SIZE = 24;

const token = ref(csrfToken());
let headers = {};
if (token.value) {
  headers = { 'X-CSRF-Token': token.value };
}

export default {
  index(shopId: number, pageNumber: number, queryParams?: string) {
    const internalQueryParams = `${queryParams}&page[number]=${pageNumber}&page[size]=${PAGE_SIZE}`;
    const path = `/api/internal/shops/${shopId}/orders?${internalQueryParams}`;

    return api({
      method: 'get',
      url: path,
      headers,
    });
  },
  get(orderId: number) {
    const path = `/api/internal/orders/${orderId}`;

    return api({
      method: 'get',
      url: path,
      headers,
    });
  },
  create(data: { shopId: number, addressId: number, billingAddressId: number, shippingCostAmountCents: number }) {
    const path = '/api/internal/orders';

    return api({
      method: 'post',
      url: path,
      data,
      headers,
    });
  },
  update(orderId: number, data: Partial<Order>) {
    const path = `/api/internal/orders/${orderId}`;

    return api({
      method: 'patch',
      url: path,
      data,
      headers,
    });
  },
  address(orderId: number) {
    const path = `/api/internal/orders/${orderId}/address`;

    return api({
      method: 'get',
      url: path,
      headers,
    });
  },
};
