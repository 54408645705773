<template>
  <div class="flex flex-col">
    <button
      class="flex justify-between items-center p-3"
      @click="showMetrics = !showMetrics"
    >
      <p class="text-base md:text-lg">
        Número de pedidos por región:
      </p>
      <ChevronDownIcon
        class="w-5 h-5 md:w-6 md:h-6"
        :class="{ 'rotate-180': showMetrics }"
      />
    </button>
    <template v-if="showMetrics">
      <lokal-loading
        v-if="destinationMetricsLoading"
        :loading="destinationMetricsLoading"
      />
      <div
        v-else
        class="px-3"
      >
        <div class="flex flex-col mb-2 text-sm rounded border md:text-base">
          <div class="grid grid-cols-4 p-3 bg-gray-50">
            <p class="col-span-3">
              Región
            </p>
            <p class="place-self-center text-center">
              Número de pedidos
            </p>
          </div>
          <div
            v-for="internalSubOrders, regionName in groupedSubOrders"
            :key="regionName"
            class="grid grid-cols-4 py-1 px-3"
          >
            <p class="col-span-3">
              {{ regionName }}:
            </p>
            <p class="place-self-center">
              {{ internalSubOrders.length }}
            </p>
          </div>
        </div>
        <pie-chart
          :id="`chart-sub-orders`"
          class="flex justify-center items-center w-full h-[450px]"
          :labels="groupedSubOrdersLabels"
          :datasets="groupedSubOrdersDatasets"
          :options="{ responsive: true, maintainAspectRatio: true }"
        />
      </div>
    </template>
  </div>
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue';
import { ChevronDownIcon } from '@heroicons/vue/24/outline';
import { groupBy, values } from 'lodash';
import pieChart from './shared/pie-chart.vue';
import regionColorsJson from '../files/region_colors.json';
import useDestinationMetricsQuery from './queries/destination-metrics-query';

const props = defineProps<{
  maker: Maker
  queryParams: string
}>();

const showMetrics = ref(false);
const colorRegions = ref(regionColorsJson.regions as Record<string, string>);
const computedMakerId = computed(() => props.maker.id);
const computedQueryParams = computed(() => props.queryParams);
const computedShowMetrics = computed(() => showMetrics.value);
const {
  destinationMetricsLoading, subOrders,
} = useDestinationMetricsQuery(computedMakerId, computedShowMetrics, computedQueryParams);
const groupedSubOrders = computed(() => groupBy(subOrders.value, 'destinationAddress.regionName'));

const groupedSubOrdersLabels = computed(() => values(groupedSubOrders.value).map((_subOrders) =>
  _subOrders[0].destinationAddress?.regionName || 'Sin región'));
const groupedSubOrdersDatasets = computed(() =>
  [
    {
      backgroundColor: values(groupedSubOrders.value).map((_subOrders) =>
        colorRegions.value[_subOrders[0].destinationAddress?.regionCode]),
      data: values(groupedSubOrders.value).map((_subOrders) => _subOrders.length),
    },
  ],
);
</script>
