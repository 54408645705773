<template>
  <div class="flex flex-col px-3 w-full max-w-sm text-gray-700 md:px-0">
    <div
      v-if="withContext"
      class="flex flex-col w-full"
    >
      <p
        v-if="!withoutTitle"
        class="mb-3 text-2xl text-center uppercase"
      >
        Compra productos al por mayor en Lokal
      </p>
      <div
        v-if="!withoutTitle"
        class="self-center mb-3 w-10 border-b-2 border-gray-300"
      />
      <p class="mb-3 text-center">
        Estaríamos muy felices de que tengas
        <span class="font-semibold">{{ maker.name }}</span>
        en tu tienda.
      </p>
      <p class="mb-3 text-center">
        Nuestros productos están disponibles para comprar en
        <a
          :href="link"
          class="border-b border-gray-300"
          target="_blank"
          noOpener
        >
          somoslokal.cl
        </a>
        . Al comprar con ellos puedes disfrutar de pagos a 60 días para tiendas físicas.
      </p>
    </div>
    <p class="flex flex-col items-center mb-3">
      <span
        class="mb-1 text-xl font-semibold"
        :class="{'text-2xl uppercase': !withContext}"
      >
        Compra al por mayor
      </span>
      <span class="text-center">Nos hemos asociado con Lokal para ofrecerte:</span>
    </p>
    <div class="grid grid-cols-2 gap-x-2 mb-5">
      <div class="flex flex-col items-center p-4 py-5 space-y-2 w-full bg-gray-100 md:p-5">
        <div class="flex shrink-0 col-span-2 col-start-2 justify-center items-center self-center w-20 h-20 bg-white rounded-full md:w-28 md:h-28">
          <inline-svg-wrapper
            name="handrise-icon"
            class="h-10 fill-gray-700 md:h-12"
          />
        </div>
        <p class="text-sm font-semibold text-center md:text-base">
          Devoluciones gratis en tu primera compra
        </p>
      </div>
      <div class="flex flex-col items-center p-4 py-5 space-y-2 bg-gray-100 md:p-5">
        <div class="flex shrink-0 col-span-2 col-start-2 justify-center items-center self-center w-20 h-20 bg-white rounded-full md:w-28 md:h-28">
          <inline-svg-wrapper
            name="bill-icon"
            class="h-10 fill-gray-700 md:h-12"
          />
        </div>
        <p class="text-sm font-semibold text-center md:text-base">
          Condiciones de pago a 60 días para tiendas físicas
        </p>
      </div>
    </div>
    <a
      :href="link"
      class="self-center p-2 w-full text-sm text-center text-white bg-gray-700 hover:bg-gray-800 md:text-base"
      target="_blank"
      noopener
    >
      {{ buttonMessage || 'Comprar al por mayor' }}
    </a>
    <div
      class="grid grid-cols-3 items-center w-full h-full"
    >
      <div class="flex flex-col items-center py-2 text-sm text-center md:py-3 md:text-base">
        <BanknotesIcon class="w-6 h-6 md:w-8 md:h-8" />
        <span class="font-light">Mínimo</span>
        <span>{{ useFormatCurrency(maker.minimumPurchaseAmountCents) }}</span>
      </div>
      <div class="flex items-center w-full h-full">
        <div class="w-0.5 h-1/2 bg-gray-100" />
        <div class="flex flex-col flex-1 col-span-1 items-center py-2 text-sm text-center md:py-3 md:text-base">
          <MapPinIcon class="w-6 h-6 md:w-8 md:h-8" />
          <span class="font-light">Envío desde</span>
          <span>{{ commune }}</span>
        </div>
        <div class="w-0.5 h-1/2 bg-gray-100" />
      </div>
      <div class="flex flex-col col-span-1 items-center py-2 text-sm text-center md:py-3 md:text-base">
        <CalendarIcon class="w-6 h-6 md:w-8 md:h-8" />
        <span class="font-light">Despacho</span>
        <span>{{ maker.averageDeliveryDays }} días</span>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, computed } from 'vue';
import { BanknotesIcon, MapPinIcon, CalendarIcon } from '@heroicons/vue/24/outline';
import { useQuery } from 'vue-query';
import addressesApi from 'api/addresses';
import useUrlMethods from './use/url-methods';
import useFormatCurrency from './use/format-currency';
import inlineSvgWrapper from './inline-svg-wrapper.vue';

const props = defineProps<{
  maker: Maker
  referredShopsCode?: DiscountCode,
}>();

const { getQueryParam } = useUrlMethods();
const withContext = computed(() => getQueryParam('with_context') === 'true');
const withoutTitle = computed(() => getQueryParam('without_title') === 'true');
const protocol = computed(() => (process.env.APPLICATION_HOST?.includes('http') ? '' : 'https://'));
const host = ref(`${protocol.value}${process.env.APPLICATION_HOST}`);
const link = computed(() => {
  const base = `${host.value}/makers/${props.maker.slug}?referred=${props.maker.slug}`;

  return props.referredShopsCode?.id ? `${base}&discount_code=${props.referredShopsCode?.token}` : base;
});
const buttonMessage = computed(() => getQueryParam('button_message'));
const addressesQuery = useQuery(['addresses', props.maker], () => addressesApi.index(props.maker.id, 'Maker'));
const addresses = computed(() => addressesQuery.data.value?.data.addresses);
const commune = computed(() => addresses.value?.[0]?.communeName);
</script>
