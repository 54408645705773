import axios, { type AxiosRequestConfig } from 'axios';
import convertKeys from 'utils/convert-keys';

function api(options: AxiosRequestConfig) {
  axios.interceptors.response.use((response) => {
    if (response.data) {
      const objData = Object.assign(
          convertKeys(response.data, 'camelize') as unknown as Record<string, unknown>,
          { headers: response.headers },
      );
      response.data = objData;

      return response;
    }

    return response;
  });

  axios.interceptors.request.use(config => {
    const newConfig = { ...config };
    if (config.params) {
      newConfig.params = convertKeys(config.params, 'decamelize');
    }
    if (config.data) {
      newConfig.data = convertKeys(config.data, 'decamelize');
    }

    return newConfig;
  });

  return axios({
    ...options,
  });
}

export default api;
