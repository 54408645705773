<template>
  <div
    class="flex flex-col p-3 mb-2 text-gray-700 rounded-md border border-lk-light-gray md:flex-row md:items-center md:p-4"
  >
    <div class="flex items-center mb-2 md:mb-0">
      <div class="flex justify-center items-center p-1 mr-3 bg-lk-light-purple rounded-full">
        <TruckIcon class="w-5 h-5 text-white md:w-6 md:h-6" />
      </div>
      <p class="text-sm md:text-base">
        <span>
          Despacho gratis para pedidos sobre {{ useFormatCurrency(freeShipping.minimumAmountCents) }} (SIN IVA)
        </span>
        <span
          v-if="freeShipping.lokalPercentage > 0"
          class="p-1 text-xs text-white uppercase bg-lk-green rounded-md md:text-sm"
        >
          Con Lokal
        </span>
      </p>
    </div>
    <div
      v-if="freeShipping.lokalPercentage === 0"
      class="flex items-center mb-2 md:mb-0 md:ml-auto"
    >
      <button
        class="p-1 mr-2 text-xs text-gray-500 hover:text-lk-green rounded-md border border-gray-500 md:p-1.5 md:text-sm md:border-0"
        @click="showFreeShippingNewDialog = !showFreeShippingNewDialog"
      >
        editar
      </button>
      <lokal-switch v-model="active" />
    </div>
    <p
      v-else
      class="text-xs text-gray-500 md:ml-auto md:text-base"
    >
      <span v-if="active">activo</span>
      <span v-else>desactivo</span>
    </p>
    <makers-free-shipping-new-dialog
      v-if="showFreeShippingNewDialog"
      v-model="showFreeShippingNewDialog"
      :free-shipping="freeShipping"
      @reload-free-shippings="() => emit('reload-free-shippings')"
    />
  </div>
</template>
<script lang="ts" setup>
import { ref, watch } from 'vue';
import { TruckIcon } from '@heroicons/vue/24/outline';
import { useMutation } from 'vue-query';
import freeShippingsApi from 'api/free-shippings';
import useMessageStore from 'stores/message-store';
import useFormatCurrency from './use/format-currency';
import MakersFreeShippingNewDialog from './makers-free-shipping-new-dialog.vue';
import LokalSwitch from './shared/lokal-switch.vue';

const props = defineProps<{
  freeShipping: FreeShipping
}>();

const messageStore = useMessageStore();
const showFreeShippingNewDialog = ref(false);
const active = ref(props.freeShipping.active);

const emit = defineEmits<{(e: 'reload-free-shippings'): void}>();

const updateMutation = useMutation(() => freeShippingsApi.update(props.freeShipping.id, { active: active.value }), {
  onSuccess: () => {
    messageStore.kind = 'success';
    const activeMessage = active.value ? 'activado' : 'desactivado';
    messageStore.message = `Despacho gratis por monto de
      ${useFormatCurrency(props.freeShipping.minimumAmountCents)} ${activeMessage}`;
  },
});

watch(active, (newValue, oldValue) => {
  if (oldValue !== undefined && newValue !== oldValue) {
    updateMutation.mutate();
  }
});
</script>
