<template>
  <div class="flex flex-col items-center mb-10 w-full h-full md:px-5 md:max-w-7xl lg:px-10">
    <div class="flex items-center px-2 mb-3 w-full md:px-0">
      <p class="flex flex-col self-start text-xl text-gray-700 md:text-2xl lg:text-3xl">
        <span>Clientes</span>
      </p>
      <maker-contacts-actions
        :maker="maker"
        class="ml-auto"
      />
    </div>
    <div class="flex flex-col py-3 px-2 w-full bg-white rounded-md md:py-5 md:px-4 lg:px-10">
      <p class="flex flex-col mb-5 text-sm text-gray-700 md:text-base">
        <span class="mr-1">Clientes:</span>
        <span class="font-light">Aquellas tiendas que te han hecho una o más compras.</span>
        <span class="mr-1">Referidos:</span>
        <span class="font-light">
          Usuarios o tiendas registrados con tu link de referido, que no te ha hecho una compra aún.
        </span>
        <span class="mr-1">Prospectos:</span>
        <span class="font-light">Información de usuarios que tu has subido manualemente. Estos usuarios aún no tienen cuenta.</span>
      </p>
      <div class="flex w-fit rounded-t-md">
        <button
          v-for="option, key in options"
          :key="key"
          class="shrink-0 p-2 text-sm md:text-base"
          :class="{
            'bg-lk-green text-white': currentOptionKey === key,
            'rounded-tl-md': key === 'clients',
            'rounded-tr-md': key === 'prospects',
            'border-t border-r': currentOptionKey !== key,
            'border-l': currentOptionKey !== 'clients' && key === 'clients',
          }"
          @click="setCurrentOptionKey(key)"
        >
          {{ option }}
        </button>
      </div>
      <maker-shop-list
        v-if="currentOptionKey === 'clients'"
        :maker="maker"
      />
      <maker-referred-list
        v-else-if="currentOptionKey === 'referred'"
        :maker="maker"
      />
      <maker-prospect-list
        v-else-if="currentOptionKey === 'prospects'"
        :maker="maker"
      />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, onBeforeMount, onBeforeUnmount } from 'vue';
import { keys } from 'lodash';
import MakerShopList from './maker-shop-list.vue';
import MakerReferredList from './maker-referred-list.vue';
import MakerProspectList from './maker-prospect-list.vue';
import MakerContactsActions from './maker-contacts-actions.vue';
import useUrlMethods from './use/url-methods';

interface MakerContactsProps {
  maker: Maker
}

defineProps<MakerContactsProps>();

const options = ref({ clients: 'Clientes', referred: 'Referidos', prospects: 'Prospectos' });
const currentOptionKey = ref('clients');

const { updateUrlParams, getQueryParam } = useUrlMethods();
function setCurrentOptionKey(key: string) {
  currentOptionKey.value = key;
  updateUrlParams({ subTab: key });
}

onBeforeMount(() => {
  const subTab = getQueryParam('subTab');
  if (keys(options.value).includes(subTab)) {
    currentOptionKey.value = subTab;
  }
});

onBeforeUnmount(() => {
  updateUrlParams({ subTab: '' });
});
</script>
