<template>
  <img
    :src="image.derivatives?.jpgSm?.url"
    class="object-contain w-14 h-16 md:w-20"
  >
  <p class="flex flex-col col-span-1 self-center place-self-start pl-2 w-full text-xs text-gray-700 truncate md:col-span-2 md:text-sm">
    <span class="mb-1 truncate">{{ image.imageData.metadata.filename }}</span>
    <span class="text-gray-500">{{ round(image.imageData.metadata.size / BYTES_TO_KB) }} Kb</span>
  </p>
  <input
    v-model="priority"
    type="number"
    :name="`priority-${image.id}`"
    class="p-2 w-14 text-center rounded-md"
    @input="tryToUpdateImagePriority"
  >
  <button
    v-if="!loading"
    class="flex items-center place-self-center p-1 space-x-1 text-xs text-white bg-red-500 rounded-md md:p-2 md:space-x-2 md:text-sm"
    type="button"
    @click="showConfirmationModal = !showConfirmationModal"
  >
    <span v-if="!isMobile">Eliminar</span>
    <XCircleIcon
      class="w-4 h-4 text-white"
    />
  </button>
  <lokal-loading
    v-else
    size="size"
  />
  <div class="last:hidden col-span-4 w-full border-b border-gray-300 md:col-span-5" />
  <lokal-confirmation-dialog
    v-if="showConfirmationModal"
    v-model="showConfirmationModal"
    title="Eliminar imagen"
    description="¿Deseas eliminar esta imagen?, esta acción es permanente."
    confirm-action-text="Eliminar imagen"
    event-name="image-destroy"
    :positive-action="false"
    :src="image.derivatives?.jpgSm?.url"
    @image-destroy="removeImage"
  />
</template>
<script lang="ts" setup>
import { ref, inject } from 'vue';
import { round } from 'lodash';
import { useMutation } from 'vue-query';
import imagesApi from 'api/images';
import { XCircleIcon } from '@heroicons/vue/24/outline';
import lokalConfirmationDialog from './lokal-confirmation-dialog.vue';

const NO_CONTENT_STATUS = 204;
const BYTES_TO_KB = 1024;

const props = defineProps<{
  image: Image,
}>();

const emit = defineEmits<{(e: 'update-images'): void, (e: 'priority-update', value: number): void}>();

const isMobile = inject('isMobile');
const loading = ref(false);
const showConfirmationModal = ref(false);
const priority = ref(props.image.priority);

const updateImageMutation = useMutation(
  () => imagesApi.update(props.image.id, { priority: priority.value }),
  {
    onSuccess: () => emit('update-images'),
  },
);

function tryToUpdateImagePriority() {
  if (!!priority.value) updateImageMutation.mutate();
}

async function removeImage() {
  loading.value = true;
  try {
    const response = await imagesApi.destroy(props.image.id);
    if (response.status === NO_CONTENT_STATUS) emit('update-images');
  } catch (e) {
    // console.error(e);
  } finally {
    loading.value = false;
  }
}
</script>
