import { computed } from 'vue';
import { defineStore } from 'pinia';
import { useSessionStorage } from '@vueuse/core';

const useSignUpStore = defineStore('signUpStore', () => {
  const userForm = useSessionStorage('signUp/userForm', { firstName: '', lastName: '', email: '',
    password: '', contactNumber: '', shopName: '', referredSlug: '', kind: '' });
  const shopForm = useSessionStorage('signUp/shopForm', { name: '', webpageLink: '', kind: '', otherKind: '',
    referenceRegionId: 0, referenceCommuneId: 0, registrationDiscountCodeToken: '' });
  const steps = useSessionStorage('signUp/steps', ['email', 'user', 'shop', 'success']);
  const currentStep = useSessionStorage('signUp/currentStep', 'email');

  function resetUserForm() {
    userForm.value = { firstName: '', lastName: '', email: '', password: '', contactNumber: '',
      shopName: '', referredSlug: '', kind: '' };
  }

  function resetShopForm() {
    shopForm.value = { name: '', webpageLink: '', kind: '', otherKind: '', referenceRegionId: 0,
      referenceCommuneId: 0, registrationDiscountCodeToken: '' };
  }

  const currentPathIncludesShopsSignUp = computed(() => window.location.pathname.includes('/shops/sign_up'));

  function reset() {
    resetUserForm();
    resetShopForm();
    currentStep.value = 'email';
  }

  return { userForm, shopForm, steps, currentStep, reset, resetUserForm, resetShopForm,
    currentPathIncludesShopsSignUp };
});

export default useSignUpStore;
