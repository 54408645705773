import { computed, type ComputedRef, type Ref } from 'vue';
import { useQuery } from 'vue-query';
import ordersApi from 'api/orders';
import useCustomParseInt from 'components/use/custom-parse-int';

export default function useOrdersQuery(
  shopId: ComputedRef<number>, pageNumber: Ref<number>,
  queryParams?: ComputedRef<string>, enabled?: boolean | ComputedRef<boolean>,
) {
  const internalEnabled = computed(() => !!shopId.value);
  const ordersQuery = useQuery(['shop', shopId, pageNumber, queryParams],
    () => ordersApi.index(shopId.value, pageNumber.value, queryParams?.value || ''),
    { enabled: enabled || internalEnabled });
  const ordersQueryIsLoading = computed(() => ordersQuery.isLoading.value || ordersQuery.isPreviousData.value);
  const orders = computed(() => ordersQuery.data.value?.data.orders as Order[]);
  const totalResources = computed(() => useCustomParseInt(ordersQuery.data.value?.headers['x-total']));
  const resourcesPerPage = computed(() => useCustomParseInt(ordersQuery.data.value?.headers['x-per-page']));
  const totalPages = computed(() => Math.ceil(totalResources.value / resourcesPerPage.value));

  return { orders, ordersQueryIsLoading, ordersQuery, totalResources, resourcesPerPage, totalPages };
}
