<template>
  <div class="flex flex-col max-w-7xl">
    <p>
      HOST: {{ host }}
    </p>
    <p>
      LOKAL_RUT: {{ lokalRut }}
    </p>
    <p>
      LOKAL_LINE_OF_BUSINESS: {{ lokalLineOfBusiness }}
    </p>
    <p>
      LOKAL_ACCOUNT_NUMBER: {{ lokalAccountNumber }}
    </p>
    <p>
      CONTACT_NUMBER: {{ contactNumber }}
    </p>
    <p>
      INFO_EMAIL: {{ infoEmail }}
    </p>
    <p>
      ADDRESS: {{ address }}
    </p>
    <div class="w-full border-b" />
    <div
      v-for="envValue, envKey in env"
      :key="envKey"
    >
      {{ envKey }}: {{ envValue }}
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue';

const env = computed(() => process.env);
const host = computed(() => process.env.APPLICATION_HOST);
const lokalRut = computed(() => process.env.LOKAL_RUT || '');
const lokalLineOfBusiness = computed(() => process.env.LOKAL_LINE_OF_BUSINESS || '');
const lokalAccountNumber = computed(() => process.env.LOKAL_ACCOUNT_NUMBER || '');
const contactNumber = computed(() => process.env.CONTACT_NUMBER || '');
const infoEmail = computed(() => process.env.INFO_EMAIL || '');
const address = computed(() => process.env.ADDRESS || '');
</script>
