<template>
  <div class="z-10 mb-3 w-full bg-gray-50 divide-y md:rounded-md md:border">
    <p class="p-3 text-base md:p-5 md:text-lg md:bg-white md:rounded-t-md">
      Resumen de Orden
    </p>
    <lokal-loading
      v-if="isBalanceAccountLoading"
      :loading="isBalanceAccountLoading"
      size="xs"
    />
    <div
      v-else-if="balanceAccount.maximumCreditsApprovedDate"
      class="p-3 text-sm md:px-5 md:text-base"
    >
      <p
        class="flex justify-between mb-1"
      >
        <span>Créditos disponibles</span>
        <span>
          {{ useFormatCurrency(balanceAccount.creditsLeft) }}
        </span>
      </p>
    </div>
    <div class="p-3 text-sm md:p-5 md:text-base">
      <p
        v-for="(makerItems, makerName) in cartStore.groupedItems"
        :key="makerName"
        class="flex justify-between mb-2 last:mb-0"
      >
        <span>{{ makerName }}</span>
        <span>{{ useFormatCurrency(totalProductPriceForItems(makerItems)) }}</span>
      </p>
    </div>
    <div class="p-3 text-sm md:p-5 md:text-base">
      <p class="flex justify-between">
        <span>Sub total</span>
        <span>{{ useFormatCurrency(totalProductsPrice) }}</span>
      </p>
    </div>
    <button
      v-if="cartStore.totalVolumeDiscounts && cartStore.totalVolumeDiscounts > 0"
      class="p-3 w-full text-sm md:p-5 md:text-base"
      type="button"
      :class="{ 'hover:bg-gray-100': !showVolumeDiscountDetails }"
      @click="showVolumeDiscountDetails = !showVolumeDiscountDetails"
    >
      <div
        class="flex justify-between w-full"
        :class="{ 'mb-2': showVolumeDiscountDetails }"
      >
        <p class="flex items-center">
          <span class="mr-2">Descuentos por Volumen:</span>
          <ChevronDownIcon
            class="shrink-0 w-4 h-4"
            :class="{ 'rotate-180 transition duration-180': showVolumeDiscountDetails,
                      'transition duration-180': !showVolumeDiscountDetails }"
          />
        </p>
        <lokal-loading
          v-if="cartStore.loadingVolumeDiscount"
          :loading="cartStore.loadingVolumeDiscount"
          size="xs"
        />
        <span v-else>
          -{{ useFormatCurrency(cartStore.totalVolumeDiscounts) }}
        </span>
      </div>
      <order-checkout-resume-maker-volume-discount
        v-for="makerId in cartStore.itemsMakerIds"
        v-show="showVolumeDiscountDetails"
        :key="`order-checkout-resume-maker-volume-discount-${makerId}`"
        :maker-id="makerId"
      />
    </button>
    <button
      v-if="selectedAddress?.id"
      class="p-3 w-full text-sm md:p-5 md:text-base"
      :class="{ 'hover:bg-gray-100': !showShippingCosts }"
      type="button"
      @click="showShippingCosts = !showShippingCosts"
    >
      <div
        class="flex justify-between w-full"
        :class="{ 'mb-2': showShippingCosts }"
      >
        <p class="flex items-center text-left">
          <span class="mr-2">Despacho total:</span>
          <ChevronDownIcon
            class="shrink-0 w-4 h-4"
            :class="{ 'rotate-180 transition duration-180': showShippingCosts,
                      'transition duration-180': !showShippingCosts }"
          />
        </p>
        <p v-if="cartStore.orderShippingCostConfirmAfter">
          Sujeto a cotización
        </p>
        <lokal-loading
          v-else-if="cartStore.loadingNewShippingCost"
          :loading="cartStore.loadingNewShippingCost"
          size="xs"
        />
        <span v-else>
          {{ shippingCostMessage }}
        </span>
      </div>
      <maker-shipping-price
        v-for="makerId, index in cartStore.itemsMakerIds"
        v-show="showShippingCosts"
        :id="`maker-shipping-price-${makerId}`"
        :key="`maker-shipping-price-${makerId}`"
        class="text-left"
        :class="{ 'mb-1': cartStore.itemsMakerIds.length - 1 !== index}"
        :maker-id="makerId"
        :destination-address="selectedAddress"
      />
      <div
        v-if="extremeRegionCodes.includes(selectedAddress.regionCode)"
        class="flex items-center mt-1 text-sm font-light md:text-base"
      >
        <InformationCircleIcon
          class="shrink-0 mr-2 w-4 h-4 md:w-5 md:h-5"
        />
        <p class="text-left">
          El costo de despacho a tu dirección puede cambiar dado que es una zona extrema.
          Realiza la orden para poder obtener el costo real del despacho.
        </p>
      </div>
      <div
        v-if="values(cartStore.shippingCostPerMaker).some((cost) => cost >= 30000)"
        class="flex items-center mt-1 text-sm font-light md:text-base"
      >
        <InformationCircleIcon
          class="shrink-0 mr-2 w-4 h-4 md:w-5 md:h-5"
        />
        <p class="text-left">
          Tienes al menos un despacho con alto costo, genera la orden para poder revisar alternativas.
        </p>
      </div>
    </button>
    <div
      v-else
      class="flex justify-between items-center p-3 w-full text-sm md:p-5 md:text-base"
    >
      <p>Despacho:</p>
      <p>Pendiente</p>
    </div>
    <div class="p-3 text-sm md:p-5 md:text-base">
      <p
        v-for="makerId in keys(cartStore.discountCodes)"
        :key="`maker-discounts-${cartStore.discountCodes[parseInt(makerId, 10)].id}`"
        class="flex justify-between mb-1"
      >
        <span>
          <span>Código de descuento </span>
          <span class="px-1 text-xs font-light text-white bg-lk-light-purple rounded-full md:text-sm">
            {{ cartStore.discountCodes[parseInt(makerId, 10)].code }}
          </span>
        </span>
        <span>
          - {{ useFormatCurrency(cartStore.discountCodeAmounts[parseInt(makerId, 10)]) }}
        </span>
      </p>
      <p class="flex justify-between mb-1">
        <span>
          Total neto
          {{ cartStore.orderShippingCostConfirmAfter ? 'sin despacho' : '' }}
        </span>
        <span v-if="selectedAddress?.id">
          {{ useFormatCurrency(totalNetAmount) }}
        </span>
        <span v-else>Pendiente</span>
      </p>
      <p class="flex justify-between mb-1">
        <span>IVA 19%</span>
        <span v-if="selectedAddress?.id">
          {{ useFormatCurrency(ivaAmount) }}
        </span>
        <span v-else>Pendiente</span>
      </p>
      <template v-for="(additionalTax, key) in groupedAdditionalTaxAmounts">
        <p
          v-if="additionalTax > 0"
          :key="`order-checkout-resume-additional-tax-${key}`"
          class="flex justify-between mb-1"
        >
          <span class="">{{ additionalTaxInitials(key) }}</span>
          <span class="">{{ useFormatCurrency(additionalTax) }}</span>
        </p>
      </template>
      <p class="flex justify-between mb-1 font-semibold">
        <span>
          Total bruto de la orden
          {{ cartStore.orderShippingCostConfirmAfter ? 'sin despacho' : '' }}
        </span>
        <span v-if="selectedAddress?.id">
          {{ useFormatCurrency(totalAmountWithTaxes) }}
        </span>
        <span v-else>Pendiente</span>
      </p>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, ref, type ComputedRef } from 'vue';
import { ChevronDownIcon, InformationCircleIcon } from '@heroicons/vue/24/outline';
import { keys, values, groupBy, sumBy, round } from 'lodash';
import useCartStore from 'stores/cart';
import useSessionStore from 'stores/user-store';
import useFormatCurrency from './use/format-currency';
import useTotalItemPriceMethods from './use/total-item-price-methods';
import useBalanceAccountQuery from './queries/balance-account-query';
import MakerShippingPrice from './shared/maker-shipping-price.vue';
import orderCheckoutResumeMakerVolumeDiscount from './order-checkout-resume-maker-volume-discount.vue';
import useBillingTools from './use/billing-tools';

const TOTAL_PERCENTAGE = 100;
const ARBITRATY_COST_DIFFERENT_THAN_ZERO = 5000;

defineProps<{
  selectedAddress: Address | undefined
}>();

const { billTools, convertAdditionalTax, additionalTaxInitials } = useBillingTools();

const sessionStore = useSessionStore();
const cartStore = useCartStore();
const { totalProductPriceForItems } = useTotalItemPriceMethods();
const totalProductsPrice = computed(() => totalProductPriceForItems(values(cartStore.items)));
const shippingCost = computed(() => cartStore.shippingCost || 0);

const showVolumeDiscountDetails = ref(false);
const showShippingCosts = ref(false);

const shippingCostMessage = computed(() => {
  if (shippingCost.value === 0) return useFormatCurrency(0);

  return useFormatCurrency(shippingCost.value || ARBITRATY_COST_DIFFERENT_THAN_ZERO);
});

const totalNetAmount = computed(() => {
  let base = totalProductsPrice.value - cartStore.totalVolumeDiscounts - cartStore.totalDiscountCodeAmounts;
  if (!cartStore.orderShippingCostConfirmAfter) base += shippingCost.value;

  return base;
});
const ivaAmount = computed(() => totalNetAmount.value * billTools.value.iva);
function additionalTaxForItem(item: Item) {
  const wholesalePrice = item.wholesalePriceCents;
  const volumeDiscount = (cartStore.volumeDiscounts[item.product.makerId]?.discount || 0) / TOTAL_PERCENTAGE;
  const volumeDiscountAmount = round(wholesalePrice * volumeDiscount);
  const discountCode = (cartStore.discountCodes[item.product.makerId]?.totalDiscount || 0) / TOTAL_PERCENTAGE;
  const discountCodeAmount = round((wholesalePrice - volumeDiscountAmount) * discountCode);
  const discountedPrice = wholesalePrice - volumeDiscountAmount - discountCodeAmount;
  const base = round(discountedPrice * convertAdditionalTax(item.product.additionalTax));

  return base * item.quantity;
}
const groupedAdditionalTaxAmounts: ComputedRef<Record<string, number>> = computed(() => {
  const groupedTaxes = groupBy(cartStore.items, (item) => item.product.additionalTax);

  return keys(groupedTaxes).reduce(
    (newHash, taxKey: string) => {
      const sumAdditionalTaxes = sumBy(groupedTaxes[taxKey], (item: Item) => additionalTaxForItem(item));

      return { ...newHash, [`${taxKey}`]: sumAdditionalTaxes };
    }, {});
});
const totalAdditionalTaxAmounts = computed(() =>
  sumBy(values(groupedAdditionalTaxAmounts.value), (tax: number) => tax));

const totalAmountWithTaxes = computed(() => totalNetAmount.value + ivaAmount.value + totalAdditionalTaxAmounts.value);

const {
  balanceAccount, isLoading: isBalanceAccountLoading,
} = useBalanceAccountQuery(computed(() => sessionStore.user?.shopId));

const extremeRegionCodes = computed(() => ['AP', 'AN', 'AI', 'MA']);
</script>
