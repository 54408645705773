import { computed, type ComputedRef } from 'vue';
import { useQuery } from 'vue-query';
import addressesApi from 'api/addresses';

export default function useAddressesQuery(addressableId: ComputedRef<number>, addressableType: ComputedRef<string>,
  enabled?: ComputedRef<boolean>) {
  const internalEnabled = enabled || computed(() => !!addressableId.value && !!addressableType.value);
  const addressesQuery = useQuery(['addresses', addressableId, addressableType], () =>
    addressesApi.index(addressableId.value, addressableType.value), { enabled: internalEnabled });
  const addresses = computed(() => addressesQuery.data.value?.data.addresses as Address[]);

  return { addresses, addressesQuery };
}
