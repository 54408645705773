<template>
  <div class="flex flex-col items-center mb-10 w-full h-full md:px-5 md:max-w-7xl lg:px-10">
    <div class="flex items-center px-2 mb-3 w-full md:px-0">
      <p class="flex flex-col self-end text-xl text-gray-700 md:text-2xl lg:text-3xl">
        Seguimiento de pedidos
      </p>
    </div>
    <div
      class="flex flex-col py-3 px-2 w-full h-full bg-white rounded-md md:py-5 md:px-4 lg:px-10"
    >
      <div class="flex items-center self-start mb-5 w-full text-sm md:text-base">
        <button
          class="shrink-0 py-3 border-b-2"
          :class="{ 'border-gray-300' : selectedStatus !== 'pending',
                    'border-lk-green' : selectedStatus === 'pending' }"
          @click="selectedStatus = 'pending'"
        >
          Pendiente / En despacho
        </button>
        <div
          class="self-end w-10 last:w-full border-b-2 border-gray-300"
        />
        <button
          class="shrink-0 py-3 border-b-2"
          :class="{ 'border-gray-300' : selectedStatus !== 'delivered',
                    'border-lk-green' : selectedStatus === 'delivered' }"
          @click="selectedStatus = 'delivered'"
        >
          Entregado
        </button>
        <div
          class="self-end w-10 last:w-full border-b-2 border-gray-300"
        />
      </div>
      <div class="overflow-x-auto w-full">
        <lokal-loading
          v-if="shopSubOrdersIsLoading"
          :loading="shopSubOrdersIsLoading"
        />
        <table
          v-else-if="shopSubOrders?.length > 0"
          class="mb-6 text-sm rounded-md border border-gray-300 table-auto sm:w-full md:mb-8 md:text-base"
        >
          <thead>
            <tr
              class="text-sm text-center bg-gray-50 border-b border-gray-300 md:text-base"
            >
              <th class="shrink-0 p-1 py-2 px-4">
                Orden id
              </th>
              <th class="shrink-0 p-1 py-2 px-4">
                Marca
              </th>
              <th class="shrink-0 p-1 py-2 px-4">
                Estado
              </th>
              <th class="shrink-0 p-1 py-2 px-4">
                Fecha de confirmación
              </th>
              <th class="shrink-0 p-1 py-2 px-4">
                Fecha estimada de entrega
              </th>
              <th class="shrink-0 p-1 py-2 px-4">
                Método de envío
              </th>
              <th>
                Seguimiento
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="subOrder in shopSubOrders"
              :key="subOrder.id"
              class="text-xs text-center border-b border-gray-300 md:text-sm"
            >
              <td class="shrink-0 p-1 py-2 px-4">
                <a
                  :href="`/orders/${subOrder.orderId}`"
                  class="hover:underline"
                  target="_blank"
                >
                  #{{ subOrder.orderId }}
                </a>
              </td>
              <td class="shrink-0 p-1 py-2 px-4">
                <a
                  :href="`/makers/${subOrder.makerId}`"
                  class="hover:underline"
                  target="_blank"
                >
                  {{ subOrder.makerName }}
                </a>
              </td>
              <td class="flex justify-center p-1 py-2">
                <span
                  class="self-center p-1 w-fit text-xs text-center text-white uppercase rounded-full md:py-1 md:px-2 md:text-sm"
                  :class="{
                    'bg-lk-light-gray': ['draft', 'pending'].includes(subOrder.status),
                    'bg-yellow-500': subOrder.status === 'confirmed',
                    'bg-lk-light-purple': subOrder.status === 'delivery_in_progress',
                    'bg-lk-green': subOrder.status === 'delivered',
                  }"
                >
                  {{ t(`subOrder.status.${camelCase(subOrder.status)}`) }}
                </span>
              </td>
              <td class="">
                {{ subOrder.confirmationAt ? new Date(subOrder.confirmationAt).toLocaleDateString() : '-' }}
              </td>
              <td class="">
                {{ subOrder.deadlineAt ? new Date(subOrder.deadlineAt).toLocaleDateString() : '-' }}
              </td>
              <td class="shrink-0 p-1 py-2 px-4">
                <span v-if="subOrder.shipmentMethod">
                  {{ t(`subOrder.shipmentMethod.${camelCase(subOrder.shipmentMethod)}`) }}
                </span>
              </td>
              <td>
                <a
                  v-if="subOrder.trackingUrl"
                  :href="subOrder.trackingUrl"
                  target="_blank"
                  rel="nofollow"
                  class="text-blue-500 hover:underline"
                >
                  Link de seguimiento
                </a>
                <span v-else-if="subOrder.initialDeliveryAt && subOrder.deadlineAt">
                  Entre {{ new Date(subOrder.initialDeliveryAt).toLocaleDateString() }} y
                  {{ new Date(subOrder.deadlineAt).toLocaleDateString() }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <p
          v-else
          class="text-sm md:text-base"
        >
          No tienes pedidos en este estado aún.
        </p>
        <pagination-component
          v-if="!shopSubOrdersIsLoading && shopSubOrders?.length > 0"
          :current-page="currentPage"
          :total-pages="totalPages"
          @set-new-page="(newPage: number) => currentPage = newPage"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue';
import { camelCase } from 'lodash';
import { useI18n } from 'vue-i18n';
import { useQuery } from 'vue-query';
import subOrdersApi from 'api/sub-orders';
import useCustomParseInt from 'components/use/custom-parse-int';
import paginationComponent from './shared/pagination-component.vue';

const props = defineProps<{
  shopId: number
}>();

const DB_STATUSES_HASH = {
  draft: 0,
  pending: 1,
  confirmed: 2,
  deliveryInProgress: 3,
  delivered: 4,
};

const selectedStatus = ref('pending');

const { t } = useI18n();

const computedShopId = computed(() => props.shopId);
const currentPage = ref(1);
const queryParams = computed(() => {
  let base = 'q[s][]=created_at+desc';
  if (selectedStatus.value === 'pending') {
    base += `&q[status_in][]=${DB_STATUSES_HASH.draft}`;
    base += `&q[status_in][]=${DB_STATUSES_HASH.pending}`;
    base += `&q[status_in][]=${DB_STATUSES_HASH.confirmed}`;
    base += `&q[status_in][]=${DB_STATUSES_HASH.deliveryInProgress}`;
  } else if (selectedStatus.value === 'delivered') base += `&q[status_eq]=${DB_STATUSES_HASH.delivered}`;

  return base;
});
const shopSubOrdersQuery = useQuery(
  ['shopSubOrders', computedShopId, currentPage, queryParams],
  () => subOrdersApi.shopIndex(computedShopId.value, currentPage.value, queryParams.value),
  {
    enabled: !!computedShopId.value,
    keepPreviousData: true,
  },
);
const shopSubOrdersIsLoading = computed(() => shopSubOrdersQuery.isLoading.value ||
  shopSubOrdersQuery.isPreviousData.value);
const shopSubOrders = computed(() => shopSubOrdersQuery.data?.value?.data?.subOrders as SubOrder[]);
const totalResources = computed(() => useCustomParseInt(shopSubOrdersQuery.data.value?.headers['x-total']));
const resourcesPerPage = computed(() => useCustomParseInt(shopSubOrdersQuery.data.value?.headers['x-per-page']));
const totalPages = computed(() => Math.ceil(totalResources.value / resourcesPerPage.value));
</script>
