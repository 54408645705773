import { computed } from 'vue';
import { useQuery } from 'vue-query';
import shippingQuotationsApi from 'api/shipping-quotations';

export default function useCarriersQuery(queryParams = computed(() => ''), enabled = computed(() => true)) {
  const carriersQuery = useQuery(
    ['carriers', queryParams],
    () => shippingQuotationsApi.carriers(queryParams.value),
    { enabled },
  );
  const carriers = computed(() => carriersQuery.data.value?.data.carriers as Carrier[]);

  return { carriersQuery, carriers, isLoading: carriersQuery.isLoading };
}
