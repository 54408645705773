<template>
  <div class="flex flex-col py-5 w-full h-full bg-gray-50">
    <div class="flex flex-col self-center p-5 w-full max-w-3xl bg-white rounded-lg shadow-xl">
      <h1 class="mb-3 text-2xl font-bold">
        Cotizador Bluex
      </h1>
      <p>
        Selecciona una comuna de origen:
      </p>
      <lokal-loading
        v-if="communesQuery.isLoading.value"
        :loading="communesQuery.isLoading.value"
        size="small"
      />
      <lokal-generic-combobox
        v-else
        v-model="selectedOriginCommune"
        :options="communes"
        :get-option-key="(option: Commune) => option.id"
        :get-option-label="(option: Commune) => option.name"
        :options-label="`Selecciona una comuna de origen`"
        :multiple="false"
      />
      <p>
        Selecciona una comuna de destino:
      </p>
      <lokal-loading
        v-if="communesQuery.isLoading.value"
        :loading="communesQuery.isLoading.value"
        size="small"
      />
      <lokal-generic-combobox
        v-else
        v-model="selectedDestinationCommune"
        :options="communes"
        :get-option-key="(option: Commune) => option.id"
        :get-option-label="(option: Commune) => option.name"
        :options-label="`Selecciona una comuna de destino`"
        :multiple="false"
      />
      <p>
        Selecciona un peso:
      </p>
      <Field
        v-model="weight"
        type="number"
        name="weight"
        class="p-2 w-full rounded-md border"
      />
      <lokal-loading
        v-if="bluexQuotationIsLoading"
        :loading="bluexQuotationIsLoading"
        size="small"
      />
      <div v-else>
        <p>
          Precio: {{ bluexQuotationData?.services?.[0]?.['priceFormatted'] }}
        </p>
        <!-- <p>
          {{ bluexQuotationData }}
        </p> -->
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, ref } from 'vue';
import { Field } from 'vee-validate';
import { useQuery } from 'vue-query';
import shippingQuotationsApi from 'api/shipping-quotations';
import useCommunesQuery from './queries/communes-query';
import lokalGenericCombobox from './shared/lokal-generic-combobox.vue';

const DEFAULT_WEIGHT = 3;

const weight = ref(DEFAULT_WEIGHT);
const { communes, communesQuery } = useCommunesQuery();
const selectedOriginCommune = ref({} as Commune);
const selectedDestinationCommune = ref({} as Commune);

const enabled = computed(() => !!selectedOriginCommune.value?.id && !!selectedDestinationCommune.value?.id);
const bluexQuotationQuery = useQuery(
  ['bluex-quotation', selectedOriginCommune, selectedDestinationCommune, weight],
  () => shippingQuotationsApi.bluexQuotation(
    selectedOriginCommune.value.name,
    selectedDestinationCommune.value.name,
    weight.value,
  ),
  {
    enabled,
  },
);
const bluexQuotationIsLoading = computed(() => bluexQuotationQuery.isLoading.value);
const bluexQuotationData = computed(() =>
  bluexQuotationQuery.data.value?.data?.shippingQuotation as ShippingQuotation);
</script>
