<template>
  <div class="flex flex-col w-full">
    <p class="mb-1 text-base font-semibold md:text-lg">
      Información de facturación
    </p>
    <div class="mb-2 w-full border-b border-gray-700" />
    <lokal-loading v-if="billingInformationIsLoading || addressesQuery.isLoading.value" />
    <form
      v-else
      class="flex flex-col w-full"
      @submit="onSubmit"
    >
      <p class="text-sm md:text-base">
        Rut de empresa
      </p>
      <form-input
        v-model="businessRut"
        name="businessRut"
        class="mb-1 font-light"
        :rules="{ required: true, rut: true }"
        @change="updateBillingInformation({ businessRut })"
      />
      <p class="text-sm md:text-base">
        Razón social
      </p>
      <form-input
        v-model="socialReason"
        name="socialReason"
        class="mb-1 font-light"
        :rules="{ required: true }"
        @change="updateBillingInformation({ socialReason })"
      />
      <p class="text-sm md:text-base">
        Giro comercial
      </p>
      <form-input
        v-model="commercialBusiness"
        name="commercialBusiness"
        class="mb-1 font-light"
        :rules="{ required: true }"
        @change="updateBillingInformation({ commercialBusiness })"
      />
      <p class="text-sm md:text-base">
        Dirección de facturación
      </p>
      <lokal-generic-combobox
        v-model="address"
        :options-label="`Seleccione una dirección`"
        class="font-light"
        :class="{ 'border-red-500 border rounded-md': errors.address }"
        :multiple="false"
        compare-by="id"
        :local-search="false"
        :options="addresses"
        :get-option-key="(address: Address) => address.id"
        :get-option-label="(address: Address) => address?.id ? `${address.address}, ${address.communeName}` : ''"
        @update:model-value="() => updateBillingInformation({ addressId: address.id })"
      />
      <Field
        v-model="addressId"
        name="address"
        hidden
        :rules="{ required: true }"
      />
      <ErrorMessage
        name="address"
        class="text-xs text-red-500 md:text-sm"
      />
      <lokal-loading
        v-if="createBillingInformationIsLoading"
        :loading="createBillingInformationIsLoading"
        class="mt-2"
      />
      <button
        v-else-if="!billingInformation?.id"
        class="p-2 mt-2 mr-auto text-lk-green hover:text-white bg-white hover:bg-lk-green rounded-md border border-lk-green"
        type="submit"
      >
        Guardar
      </button>
    </form>
  </div>
</template>
<script lang="ts" setup>
import { ref, computed, watch } from 'vue';
import { useQuery, useMutation } from 'vue-query';
import { useForm, Field, ErrorMessage } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import billingInformationsApi from 'api/billing-informations';
import useMessageStore from 'stores/message-store';
import formInput from './shared/form-input.vue';
import useAddressesQuery from './queries/addresses-query';
import lokalGenericCombobox from './shared/lokal-generic-combobox.vue';

const props = defineProps<{
  maker: Maker;
}>();

const messageStore = useMessageStore();
const { t } = useI18n();

const { handleSubmit, errors } = useForm();

const computedMakerId = computed(() => props.maker.id);
const { addresses, addressesQuery } = useAddressesQuery(computedMakerId, computed(() => 'Maker'));
const billingInformationQuery = useQuery(
  ['billingInformation', props.maker.id], () => billingInformationsApi.get(props.maker.id),
);
const billingInformationIsLoading = computed(() => billingInformationQuery.isLoading.value);
const billingInformation = computed(() =>
  billingInformationQuery.data.value?.data?.billingInformation as BillingInformation);

const socialReason = ref('');
const commercialBusiness = ref('');
const businessRut = ref('');
const address = ref({} as Address);
const addressId = computed(() => address.value?.id);

let definedInitialValues = false;
watch(billingInformation, (newValue) => {
  if (newValue?.id && !definedInitialValues) {
    socialReason.value = billingInformation.value.socialReason;
    commercialBusiness.value = billingInformation.value.commercialBusiness;
    businessRut.value = billingInformation.value.businessRut;
    address.value = billingInformation.value.address;
    definedInitialValues = true;
  }
});

const updateBillingInformationMutation = useMutation(
  (data: Record<string, string | number>) =>
    billingInformationsApi.update(billingInformation.value.id, data),
  {
    onSuccess: async (_, variables) => {
      let message = 'Datos actualizados correctamente:\n';
      Object.keys(variables).forEach((key) => {
        const keyName = t(`billingInformation.${key}`);
        message += `${keyName}: ${variables[key]}\n`;
      });
      messageStore.showMessage(message, 'success');
      await billingInformationQuery.refetch.value();
    },
  },
);

function updateBillingInformation(
  data: { socialReason?: string, commercialBusiness?: string, businessRut?: string, addressId?: number },
) {
  if (billingInformation.value?.id) {
    updateBillingInformationMutation.mutate(data);
  }
}

const createBillinInformationData = computed(() => ({
  socialReason: socialReason.value,
  commercialBusiness: commercialBusiness.value,
  businessRut: businessRut.value,
  addressId: address.value.id,
}));

const createBillingInformationMutation = useMutation(
  () => billingInformationsApi.create(props.maker.id, createBillinInformationData.value),
  {
    onSuccess: async () => {
      messageStore.showMessage('Información de facturación creada correctamente', 'success');
      await billingInformationQuery.refetch.value();
    },
  },
);
const createBillingInformationIsLoading = computed(() => createBillingInformationMutation.isLoading.value);

const onSubmit = handleSubmit(() => {
  if (!billingInformation.value?.id) {
    createBillingInformationMutation.mutate();
  }
});
</script>
