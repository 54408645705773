import { computed, type ComputedRef } from 'vue';
import { round } from 'lodash';
import useCartStore from 'stores/cart';
import useMakerVolumeDiscounts from './maker-volume-discounts';

const TOTAL_PORCENTAGE = 100;

// eslint-disable-next-line max-statements
export default function useMakerCodeDiscounts(makerId: ComputedRef<number>) {
  const { totalProductsPriceWithVolumeDiscount } = useMakerVolumeDiscounts(makerId);
  const cartStore = useCartStore();
  const codeDiscountAmount = computed(() => {
    const discountCode = cartStore.discountCodes[makerId.value];
    if (discountCode?.id) {
      const discountCodePercentage = discountCode.totalDiscount / TOTAL_PORCENTAGE;

      return round(totalProductsPriceWithVolumeDiscount.value * discountCodePercentage);
    }

    return 0;
  });
  const productWholesalePriceWithVolumeDiscountAndDiscountCode = computed(() =>
    totalProductsPriceWithVolumeDiscount.value - codeDiscountAmount.value);
  const productWholesalePriceWithDiscountCode = computed(() =>
    productWholesalePriceWithVolumeDiscountAndDiscountCode.value);

  return {
    codeDiscountAmount,
    productWholesalePriceWithVolumeDiscountAndDiscountCode,
    productWholesalePriceWithDiscountCode,
  };
}
