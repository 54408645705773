<template>
  <div
    class="flex flex-col justify-between items-center w-auto max-w-sm rounded-md border-slate-300"
    :class="{ 'hover:shadow-lg': product.status === 'active' }"
  >
    <component
      :is="sessionStore.user?.id && product.status !== 'out_of_stock' ? 'a' : 'button'"
      class="flex relative flex-col justify-between items-center w-full"
      :class="{ 'opacity-50 cursor-default': productOutOfStock }"
      :href="`/products/${product.id}`"
      :disabled="productOutOfStock"
      @click="openResisterDialog"
    >
      <div
        class="flex overflow-hidden relative justify-center items-center mb-2 w-full h-40 rounded-t-md md:h-60 lg:h-72 xl:h-80"
        style="transform-origin: center center"
      >
        <div
          :style="{ 'background-image': `url(${productImageToShow})` }"
          class="object-cover absolute w-full h-full bg-center bg-cover duration-300 ease-in-out hover:scale-105"
        />
        <p
          v-if="productOutOfStock"
          class="z-10 self-start p-1 w-full text-xs text-center text-white bg-lk-light-gray rounded-t-md md:p-2 md:text-sm"
        >
          Agotado
        </p>
        <template v-else>
          <p
            v-if="product.recommended"
            class="z-10 self-start p-1 w-full text-xs text-center text-white bg-lk-light-green md:p-2 md:text-sm"
            :class="{ 'rounded-tl-md': product.activeDiscounts?.length,
                      'rounded-t-md': !product.activeDiscounts?.length }"
          >
            Recomendado
          </p>
          <p
            v-if="product.activeDiscounts?.length"
            class="z-10 self-start p-1 w-full text-xs text-center text-white bg-lk-light-purple rounded-tr-md md:p-2 md:text-sm"
            :class="{ 'rounded-tr-md': product.recommended, 'rounded-t-md': !product.recommended }"
          >
            {{ product.activeDiscounts[0].name }}
          </p>
        </template>
      </div>
      <div class="flex flex-col px-1 w-full text-xs text-left text-gray-600 md:px-3 md:text-base">
        <p class="flex items-center font-light md:text-sm">
          <span>{{ product.makerName }}</span>
          <span
            v-if="product.activeDiscounts?.length"
            class="p-1 ml-auto text-xs text-white bg-lk-light-purple rounded-md md:px-2 md:text-sm"
          >
            - {{ product.activeDiscounts[0].totalDiscount }}%
          </span>
        </p>
        <p class="mb-1 capitalize">
          {{ product.name.toLocaleLowerCase() }}
        </p>
        <p
          class="flex items-center text-xs md:text-sm"
          :class="{ 'font-semibold': activeDiscount }"
        >
          <span class="mb-1">
            {{ isMobile ? 'Mayorista Neto' : 'Precio Mayorista Neto' }}:
          </span>
          <template v-if="loadWholesalePrice">
            <span
              class="ml-auto font-normal"
              :class="{'line-through mr-2': activeDiscount || showDiscountedAmount, 'blur-sm': !sessionStore.user?.id }"
            >
              {{ useFormatCurrency(product.wholesalePriceCents) }}
            </span>
            <span
              v-if="showDiscountedAmount"
              :class="{ 'blur-sm': !sessionStore.user?.id }"
            >
              {{ useFormatCurrency(productWholesalePrice) }}
            </span>
          </template>
          <lokal-tooltip
            v-else
            :information="lockInformation"
            class="ml-auto"
            button-classes="flex items-center space-x-1"
            information-classes="w-40 md:w-52 p-1 md:p-2"
            position-options="right"
          >
            <template #custom-content>
              <LockClosedIcon class="shrink-0 w-4 h-4" />
              <span class=" blur-sm">
                {{ useFormatCurrency(round(product.unitaryPriceCents)) }}
              </span>
            </template>
          </lokal-tooltip>
        </p>
        <div class="flex justify-between text-xs md:text-sm">
          <span class="mb-1">{{ isMobile ? 'Público (c/IVA)' : 'Precio Público (c/IVA)' }}:</span>
          <span>{{ useFormatCurrency(round(product.unitaryPriceCents)) }}</span>
        </div>
        <div
          v-if="sessionStore.user?.id && margin"
          class="flex justify-between mb-1 text-xs md:text-sm"
        >
          <lokal-tooltip
            main-label="Márgen:"
            :position-options="'left'"
            information-classes="w-40 p-1 md:p-2 md:w-64 text-xs md:text-sm"
            button-classes="flex items-center space-x-1"
            :information="marginInformation"
          />
          <span>{{ margin }}%</span>
        </div>
        <div
          v-if="sessionStore.user?.id && product.inventory?.enable"
          class="flex justify-between mb-1 text-xs font-light md:text-sm"
        >
          <lokal-tooltip
            main-label="Unidades disponible:"
            :position-options="'left'"
            information-classes="w-40 p-1 md:p-2 md:w-64 text-xs md:text-sm"
            button-classes="flex items-center space-x-1"
            :information="'Cantidad de productos que quedan disponibles'"
          />
          <span>{{ product.inventory?.quantity }}</span>
        </div>
      </div>
    </component>
    <product-shopping-button
      v-if="shoppingButtonEnabled"
      :product="product"
      :variant-needed="!!product.variants?.length"
      class="w-full"
      @show-register-dialog="showRegisterDialog = true"
    />
    <lokal-user-register-dialog
      v-if="showRegisterDialog"
      v-model="showRegisterDialog"
      :product="product"
      :total-product-count="totalProductCount"
      :initial-value-sign-in="false"
    />
  </div>
</template>
<script setup lang="ts">
import { ref, computed, inject } from 'vue';
import useSessionStore from 'stores/user-store';
import { LockClosedIcon } from '@heroicons/vue/24/solid';
import { round } from 'lodash';
import useCartStore from 'stores/cart';
import useFormatCurrency from './use/format-currency';
import useActiveProductDiscounts from './use/active-product-discounts';
import useProductImage from './use/product-image';
import ProductShoppingButton from './product-shopping-button.vue';
import lokalUserRegisterDialog from './shared/lokal-user-register-dialog.vue';
import useProductWholesalePriceMethods from './use/product-wholesale-price';
import lokalTooltip from './shared/lokal-tooltip.vue';
import useBillingTools from './use/billing-tools';

interface ProductCardProps {
  product: Product,
  totalProductCount?: number,
  shoppingButtonEnabled: boolean,
}

const props = withDefaults(defineProps<ProductCardProps>(), {
  totalProductCount: 0,
  shoppingButtonEnabled: true,
});

const TOTAL_PORCENTAGE = 100;

const { billTools, convertAdditionalTax, additionalTaxInitials } = useBillingTools();
const isMobile = inject('isMobile');

const showRegisterDialog = ref(false);

const productOutOfStock = computed(() =>
  props.product.status === 'out_of_stock' ||
    (props.product.inventory?.enable && props.product.inventory?.quantity === 0));

const sessionStore = useSessionStore();
const cartStore = useCartStore();
const quantity = computed(() => cartStore.items[props.product.id]?.quantity || 0);
const { productWholesalePrice } = useProductWholesalePriceMethods(props.product, quantity);
const loadWholesalePrice = computed(() => !!sessionStore.user?.id);
const showDiscountedAmount = computed(() => productWholesalePrice.value < props.product.wholesalePriceCents);
const { activeDiscount } = useActiveProductDiscounts(props.product);

const currentAdditionalTax = computed(() => convertAdditionalTax(props.product.additionalTax));
const additionalTaxValue = computed(() => round(productWholesalePrice.value * currentAdditionalTax.value));
const netWholesalePriceWithAdditionalTax = computed(() => productWholesalePrice.value + additionalTaxValue.value);
const margin = computed(() => {
  if (props.product.unitaryPriceCents === 0) return 0;

  const netUnitaryPrice = round(props.product.unitaryPriceCents / billTools.value.ivaValue);
  const diff = netUnitaryPrice - netWholesalePriceWithAdditionalTax.value;

  return round((diff / netUnitaryPrice) * TOTAL_PORCENTAGE);
});

const { productImageToShow } = useProductImage(props.product);

function openResisterDialog() {
  if (!sessionStore.user?.id) showRegisterDialog.value = true;
}

const lockInformation = ref(`
  Para acceder a los precios mayoristas debes estar registrado en Lokal. <br>
`);
const marginInformation = computed(() => {
  if (currentAdditionalTax.value > 0) {
    return `
    (Precio Público - (Precio Mayorista + ${additionalTaxInitials(props.product.additionalTax)})) / Precio Público <br>
    Ambos precios sin IVA.
  `;
  }

  return `
    (Precio Público - Precio Mayorista) / Precio Público <br>
    Ambos precios sin IVA.
  `;
});
</script>
<style>
.image-heigh {
  height: 426px
}
.card-width {
  width: 240px
}
</style>
