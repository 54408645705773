<template>
  <div
    v-if="product.images.length"
    class="flex flex-col shrink-0 items-center py-3 px-5 mb-5 border-b-2 border-lk-green md:col-span-8 md:border-r-2 md:border-b-0"
    :class="{ 'opacity-50': product.status === 'out_of_stock' }"
  >
    <img
      :src="currentImage?.derivatives?.jpgLg?.url"
      class="mb-3"
    >
    <div
      v-if="!isMobile"
      class="flex mb-3"
    >
      <button
        v-if="firstAlternativeImage"
        @click="changeIndexByImage(firstAlternativeImage)"
      >
        <img
          :src="firstAlternativeImage?.derivatives?.jpgMd?.url"
          class="mr-5 w-60"
        >
      </button>
      <button
        v-if="secondAlternativeImage"
        @click="changeIndexByImage(secondAlternativeImage)"
      >
        <img
          :src="secondAlternativeImage?.derivatives?.jpgMd?.url"
          class="w-60"
        >
      </button>
    </div>
    <div class="flex items-center mb-3 space-x-3">
      <ArrowLeftCircleIcon
        class="w-8 h-8 fill-lk-green"
        :class="{ 'fill-lk-light-gray cursor-default': currentIndex === 0,
                  'cursor-pointer': 0 < currentIndex }"
        @click="previousImage"
      />
      <span class="text-sm font-light">{{ currentIndex + 1 }} / {{ product.images.length }}</span>
      <ArrowRightCircleIcon
        class="w-8 h-8 fill-lk-green"
        :class="{ 'fill-lk-light-gray cursor-default': currentIndex === product.images.length - 1,
                  'cursor-pointer': currentIndex !== product.images.length - 1 }"
        @click="nextImage"
      />
    </div>
  </div>
  <div
    v-else
    class="flex flex-col justify-center items-center"
  >
    <img
      src="assets/images/green-k.jpg"
      class="object-scale-down mb-3 w-fit"
    >
  </div>
</template>
<script lang="ts" setup>
import { ref, computed, inject } from 'vue';
import { sortBy, findIndex } from 'lodash';
import { ArrowRightCircleIcon, ArrowLeftCircleIcon } from '@heroicons/vue/24/solid';

const props = defineProps<{
  product: Product;
}>();

const isMobile = inject('isMobile');

// eslint-disable-next-line max-statements
function useImageNavegation(images: Image[]) {
  const currentIndex = ref(0);
  const currentIndexSrc = ref<string>();
  const currentSecondPreviousImage = computed(() => images[currentIndex.value - 2]);
  const currentPreviousImage = computed(() => images[currentIndex.value - 1]);
  const currentImage = computed(() => images[currentIndex.value]);
  const currentNextImage = computed(() => images[currentIndex.value + 1]);
  const currentSecondNextImage = computed(() => images[currentIndex.value + 2]);

  if (isMobile?.value) {
    currentIndexSrc.value = currentImage.value?.derivatives?.jpgSm?.url;
  } else {
    currentIndexSrc.value = currentImage.value?.derivatives?.jpgMd?.url;
  }

  function getImageByIndex(index: number) {
    return images[index];
  }

  const firstAlternativeImage = computed(() => {
    if (currentIndex.value === 0) return getImageByIndex(currentIndex.value + 1);

    return getImageByIndex(currentIndex.value - 1);
  });

  const secondAlternativeImage = computed(() => {
    if (currentIndex.value === 0) return getImageByIndex(currentIndex.value + 2);
    else if (currentIndex.value === images.length - 1) return getImageByIndex(currentIndex.value - 2);

    return getImageByIndex(currentIndex.value + 1);
  });

  function nextImage() {
    if (currentIndex.value < images.length - 1) {
      currentIndex.value += 1;
    }
  }

  function previousImage() {
    if (currentIndex.value > 0) {
      currentIndex.value -= 1;
    }
  }

  function changeIndexByImage(image: Image) {
    currentIndex.value = findIndex(images, (arrayImage: Image) => arrayImage.id === image.id);
  }

  return {
    currentIndex, currentImage, nextImage, previousImage, currentNextImage, currentSecondNextImage,
    currentSecondPreviousImage, currentPreviousImage, changeIndexByImage,
    firstAlternativeImage, secondAlternativeImage,
  };
}

const {
  currentIndex, currentImage, nextImage, previousImage, changeIndexByImage, firstAlternativeImage,
  secondAlternativeImage,
} = useImageNavegation(sortBy(props.product.images, (image) => image.priority));
</script>
