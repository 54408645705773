<template>
  <div class="flex flex-col py-6 px-5 mb-10 max-w-2xl text-sm font-light text-justify text-gray-700 md:py-10 md:px-0 md:text-base">
    <h1 class="mb-8 font-serif text-4xl font-medium text-center text-lk-green md:text-5xl">
      LOKAL
    </h1>
    <h2 class="mb-8 text-xl font-medium md:text-2xl">
      TERMINOS Y CONDICIONES
    </h2>
    <p class="mb-3">
      “Lokal”, es un marketplace online mayorista administrada por Lokal SpA (en adelante, “Lokal”),
      que conecta marcas y emprendedores independientes (en adelante, la “Marca”) con tiendas
      minoristas (en adelante, la “Tienda” y los “Servicios”, respectivamente) y que se encuentra
      disponible a través del sitio web “www.somoslokal.cl” (en adelante, el “Sitio”).
    </p>
    <p class="mb-3">
      El presente documento (en adelante, los “Términos y Condiciones”) contiene los términos y
      condiciones de uso de los Servicios, del Sitio y de la plataforma que éste contiene, ofrecida por
      Lokal,
      los cuales prevalecen sobre todo lo que no haya sido acordado o modificado expresamente por ésta
      y cualquier persona que se registre en el Sitio y/o haga uso de éste, ya sea como Marca o como
      Tienda (en adelante, el “Usuario”), incluido cualquier servicio adicional a los ofrecidos en el Sitio
      que las partes acuerden de forma expresa y por escrito.
    </p>
    <p class="mb-3">
      Estos Términos y Condiciones deben ser leídos en conjunto con la Política de Devolución y la
      Política de Despacho (en adelante y conjuntamente, la “Regulación Lokal”), los que se incorporan
      por referencia y que regulan el uso y desarrollo de los Servicios. La Regulación Lokal se encuentra
      disponible en el siguiente link https://somoslokal.cl/frequent_questions
    </p>
    <p class="mb-3">
      A menos que se especifique lo contrario o que se trate de nombres propios y de la palabra inicial de
      cualquier frase, las palabras y expresiones tendrán el significado que se indica en los mismos,
      siendo extensivas tales definiciones tanto a su forma en singular o plural.
    </p>
    <p class="mb-3">
      Asimismo, para facilidad de lectura, los términos “nosotros”, “nos” y “nuestro” se refieren a Lokal,
      y los términos “usted” y/o “su” se refieren a cualquier Usuario que visite el Sitio, así como a
      cualquier usuario de los Servicios.
    </p>
    <p class="mb-3">
      CUALQUIER PERSONA – NATURAL O JURÍDICA – QUE NO ACEPTE ESTOS TÉRMINOS
      Y CONDICIONES, LOS CUALES TIENEN UN CARÁCTER OBLIGATORIO Y
      VINCULANTE, DEBERÁ ABSTENERSE DE UTILIZAR EL SITIO Y/O LOS SERVICIOS
      OFRECIDOS POR LOKAL.
    </p>
    <p class="mb-3 text-base font-normal underline md:text-lg">
      SECCIÓN I <br>
      DISPOSICIONES GENERALES
    </p>
    <p class="mb-3 font-normal">
      ARTÍCULO I <br>
      OBJETO DE LOS TÉRMINOS Y CONDICIONES
    </p>
    <p class="mb-3">
      Los Términos y Condiciones que a continuación se detallan tienen por objeto regular la relación
      entre Lokal y el Usuario, Marca o Tienda, independiente del motivo de registro, y los derechos y
      obligaciones de cada uno de éstos, en relación con el uso del Sitio y de los Servicios.
    </p>
    <p class="mb-3">
      Al acceder y/o registrarse en el Sitio como usuario, el Usuario reconoce y declara haber leído,
      comprendido y aceptado íntegramente, desde ese mismo momento, plenamente y sin reserva alguna,
      la Regulación Lokal, según dicho término se define en estos Términos y Condiciones, declarando
      conocer los detalles y especificaciones del Sitio y de los Servicios que Lokal presta, su forma de
      solicitud, ejecución y aprobación. Asimismo, se entiende que acepta todas las demás reglas de
      operación, políticas y procedimientos que se encuentren publicados o que sean publicados en un
      futuro por Lokal en el Sitio, cada uno de los cuales se incorporan a estos Términos y Condiciones
      por referencia.
    </p>
    <p class="mb-3">
      Los presentes Términos y Condiciones también se aplicarán para cualquier nueva herramienta o
      característica del Sitio, por lo tanto, es responsabilidad del Usuario revisarlos periódicamente para
      conocer sus actualizaciones. El uso continuo o acceso al Sitio después de la publicación de
      cualquier cambio constituye la aceptación, por parte de la Tienda, de dichos cambios.
    </p>
    <p class="mb-3">
      Por último, Lokal hace presente que los Términos y Condiciones forman parte inseparable de
      cualquier prestación y servicio ofrecido por Lokal ya sea de intermediación o compra de productos
      realizados a través del Sitio. Asimismo, Lokal declara que no tiene como finalidad hacer una
      imposición unilateral de los presentes Términos y Condiciones, sino que se trata de una
      característica fundamental del comercio electrónico y masivo que se realiza por canales
      tecnológicos y digitales, de lo contrario, Lokal no podría ofrecer los Servicios que se detallan en el
      presente instrumento.
    </p>
    <p class="mb-3 font-normal">
      ARTÍCULO III <br>
      MODIFICACIONES A LOS TÉRMINOS Y CONDICIONES Y SITIO
    </p>
    <p class="mb-3">
      Lokal se reserva el derecho de actualizar, modificar o reemplazar estos Términos y Condiciones en
      cualquier momento e implementar términos o condiciones nuevos o adicionales. En cuyo caso, el
      Usuario deberá aceptar expresamente dichas modificaciones. Esas modificaciones, o aquellos
      términos y condiciones adicionales serán oportunamente informadas al Usuario, por ejemplo, a
      través de un aviso destacado en la Cuenta Lokal, según dicho término es definido más adelante, o
      mediante un correo electrónico. Las modificaciones entrarán en vigencia inmediatamente o en la
      fecha que la misma modificación señale, o desde la aceptación del Usuario, según sea el caso, y
      formarán parte de estos Términos y Condiciones. En cualquier caso, a partir de la fecha en que
      entren en vigencia las modificaciones a los Términos y Condiciones, los Servicios sólo podrán ser
      utilizados por el Usuario una vez confirme la aceptación de los nuevos Términos y Condiciones.
    </p>
    <p class="mb-3">
      Sí, habiéndose modificado los Términos y Condiciones, no son aceptados por el Usuario, éste podrá
      solicitar el término de los Servicios de conformidad a la sección 21.07 del Artículo XXI siguiente,
      pudiendo Lokal eliminar o suspender la Cuenta Lokal, según este término es definido más adelante,
      del respectivo Usuario, sin que ello genere derecho a resarcimiento alguno.
    </p>
    <p class="mb-3">
      Asimismo, sin que implique una modificación a los Términos y Condiciones, Lokal se reserva la
      facultad y el derecho de actualizar, modificar o eliminar, en parte o en todo, y sin expresión de
      causa, cualquier contenido o información publicada en el Sitio según lo estime conveniente,
      pudiendo hacer uso de tal facultad en cualquier momento y sin previo aviso. Este derecho abarca los
      contenidos referidos a la disponibilidad de productos o servicios, especificaciones, precio, o en
      cuanto a la configuración y funcionalidades del Sitio o de la información de actividades
      promocionales. Asimismo, podrá modificar unilateralmente y sin previo aviso, siempre que lo
      considere oportuno, la estructura y diseño del Sitio, sus contenidos y las condiciones de acceso y/o
      uso a este.
    </p>
    <p class="mb-3 font-normal">
      ARTÍCULO IV <br>
      SERVICIOS OFRECIDOS
    </p>
    <p class="mb-3">
      A través del Sitio y de la plataforma que éste contiene, Lokal actúa como un intermediario entre la
      Marca y la Tienda, con el objetivo de que estas últimas accedan y compren los productos de las
      primeras, facilitando y asegurando una operación comercial sencilla, directa y eficaz para aumentar
      y potenciar la venta de los productos de la Marca a través del Sitio.
    </p>
    <p class="mb-5">
      Para efectos de realizar las referidas operaciones comerciales, Lokal podrá obrar, indistintamente,
      en representación de la Tienda, o a nombre propio, pero en ambos casos, por cuenta y riesgo de la
      Tienda, encontrándose debidamente facultado al efecto.
    </p>
    <p class="mb-3 text-base font-normal underline md:text-lg">
      SECCIÓN II <br>
      DISPOSICIONES APLICABLES AL SITIO
    </p>
    <p class="mb-3 font-normal">
      ARTÍCULO V
      REGISTRO DEL USUARIO
    </p>
    <p class="mb-3 underline">
      5.01. Cuenta Lokal.
    </p>
    <p class="mb-3">
      Para utilizar el Sitio y acceder a los Servicios, el Usuario deberá registrarse en el Sitio. Durante el
      proceso de registro, el Usuario deberá proporcionar cierta información (dependiendo del tipo de
      cuenta con la que se registre, según se detalla más abajo) con el objeto de registrar una cuenta en el
      Sitio. Dicha información, así como cualquier otra información que proporcione el Usuario en el
      Sitio a través del uso de los Servicios, quedará protegida de acuerdo con las disposiciones del
      Artículo IX siguiente.
    </p>
    <p class="mb-3">
      El Usuario se compromete a proporcionar información que sea precisa, actual y completa, así como
      a mantenerla actualizada. Lokal se reserva el derecho a suspender temporalmente o a cancelar
      permanentemente la cuenta del Usuario si alguno de los datos proporcionados durante el proceso de
      registro o posteriormente, resulte ser inexacto, desactualizado o incompleto. El Usuario reconoce y
      acepta la obligación de actualizar de inmediato su cuenta y toda otra información, incluida su
      dirección de correo electrónico, números de tarjeta de crédito y fechas de vencimiento, tan pronto
      como esta haya sido modificada, para que podamos completar sus transacciones y comunicarnos
      con usted según sea necesario.
    </p>
    <p class="mb-3">
      Lokal podrá rechazar una solicitud de registro o bien cancelar un registro ya aceptado, sin que esto
      genere derecho alguno a resarcimiento de cualquier tipo para el Usuario. No podrá registrarse
      nuevamente en el Sitio, el Usuario cuya cuenta haya sido inhabilitada previamente.
    </p>
    <p class="mb-3">
      Lokal ofrece dos tipos de cuenta dependiendo si se trata de una Tienda (en adelante, “Cuenta
      Tienda”), o si se trata de una Marca (en adelante, “Cuenta Marca”, y conjuntamente, con la Cuenta
      Tienda, la “Cuenta Lokal”).
    </p>
    <p class="mb-3 underline">
      5.02 Cuenta Marca.
    </p>
    <p class="mb-3">
      La Cuenta Marca está diseñada para la Marca que busca vender sus productos a través del Sitio,
      haciendo uso de los Servicios. Para registrarse en este tipo de cuenta la Marca tendrá que presentar
      una solicitud que deberá ser aprobada por Lokal. En dicha solicitud, la Marca otorgará información
      de la empresa y los productos a ofrecer. En caso de ser aceptada, Lokal podrá solicitar a la Marca
      información adicional, datos bancarios para abono de pagos, documentación tributaria, así como
      cualquier otra información que sea necesaria a entera discreción de Lokal. Asimismo, antes de
      realizar su primera venta, Lokal podrá requerir que proporcione información adicional como el
      método de pago aceptado por la Marca, su número de identificación tributario o de otro tipo, y
      cualquier otra información que pueda requerir Lokal o cualquier procesador de pagos.
    </p>
    <p class="mb-3 underline">
      5.03 Cuenta Tienda.
    </p>
    <p class="mb-3">
      La Cuenta Tienda está diseñada para la Tienda que busca comprar los productos que se venden a
      través del Sitio, haciendo uso de los Servicios. Para registrarse en este tipo de cuenta la Tienda
      deberá presentar una solicitud que deberá ser aprobada por Lokal, junto con proporcionar cierta
      información, como su nombre y apellido o razón social, según sea el caso, el tipo de tienda (si es
      física, digital o ambas), dirección de correo electrónico, así como cualquier otra información que
      sea necesaria, a entera discreción de Lokal. Al registrarse en este tipo de cuenta, la Tienda autoriza a
      Lokal para acceder a su historial crediticio personal y empresarial, incluyendo la obtención de un
      informe de crédito de consumidor, con el fin de evaluar su acceso a crédito comercial por parte de la
      Marca y/o Lokal. Además, la Tienda acepta que la revisión crediticia puede afectar su puntuación de
      crédito. Asimismo, antes de realizar su primera compra, Lokal podrá requerir que proporcione
      información adicional como su número de identificación tributario o de otro tipo, y cualquier otra
      información que pueda requerir Lokal o cualquier procesador de pagos. Además, la Tienda deberá
      contar con canales de ventas físicos y/o digitales para la venta de los productos, de lo contrario, no
      podrá registrarse en el Sitio y hacer uso de los Servicios prestados por Lokal.
    </p>
    <p class="mb-3">
      Asimismo, al proporcionar la información de pago con el registro de una Cuenta Tienda y/o la
      compra de productos, la Tienda acepta pagar Lokal, los productos adquiridos, cualesquiera
      impuestos que resulten aplicables y cualesquiera otros honorarios que puedan devengarse. Además,
      la Tienda autoriza al procesador de pagos designado por Lokal para que procesen el pago con la
      información que la Tienda ha proporcionado, y/o para que se abone el mismo si resultara necesario
      para realizar cualquier ajuste.
    </p>
    <p class="mb-3 font-normal">
      ARTÍCULO VI <br>
      USO DEL SITIO
    </p>
    <p class="mb-3 underline">
      6.01 Requisitos mínimos de uso del Sitio y los Servicios.
    </p>
    <p class="flex flex-col mb-3">
      <span>
        Para utilizar y acceder a los Servicios que se ofrecen a través del Sitio el Usuario debe cumplir
        previamente con los siguientes requisitos (en adelante e indistintamente también denominados como
        los “Requisitos Mínimos”):
      </span>
    </p>
    <p class="flex flex-col mb-3 space-y-2">
      <span class="grid grid-cols-12 ml-4">
        <span class="mr-2">(i)</span>
        <span class="col-span-11">
          Ser legalmente capaz de acuerdo con la legislación aplicable.
        </span>
      </span>
      <span class="grid grid-cols-12 ml-4">
        <span class="mr-2">(ii)</span>
        <span class="col-span-11">
          Aceptar la Regulación Lokal.
        </span>
      </span>
      <span class="grid grid-cols-12 ml-4">
        <span class="mr-2">(iii)</span>
        <span class="col-span-11">
          Registrarse, creando una cuenta personal con un usuario y contraseña. Para ello, el
          Usuario deberá completar, de forma veraz y precisa, el formulario de registro disponible
          en el Sitio.
        </span>
      </span>
      <span class="grid grid-cols-12 ml-4">
        <span class="mr-2">(iv)</span>
        <span class="col-span-11">
          Disponer del hardware, soporte tecnológico o dispositivo que cumpla con los requisitos
          de compatibilidad y con los requerimientos de sistema correspondientes, los cuales
          pueden variar de tiempo en tiempo, y de una conexión a Internet estable, entre otras
          condiciones básicas para el correcto funcionamiento del Sitio y los Servicios. El ancho de
          banda de la conexión a Internet, la mantención de los dispositivos y aplicaciones que
          conectan al Sitio, es de responsabilidad de los Usuarios.
        </span>
      </span>
    </p>
    <p class="mb-3">
      En caso de que el Usuario sea una persona jurídica, se requerirá además del cumplimiento de los
      requisitos previamente enunciados, en cuanto le sean aplicables, que su representante legal, tenga
      poder suficiente para actuar y contratar a nombre del mismo, lo que deberá acreditar con la
      documentación correspondiente. Si la persona que acepta estos Términos y Condiciones lo hace en
      nombre de una empresa u otra entidad legal, dicha persona declara que tiene la autoridad para
      obligar a dicha entidad y sus personas relacionadas a estos Términos y Condiciones.
    </p>
    <p class="mb-3">
      Al registrarse como Usuario en el Sitio, el Usuario reconoce y declara conocer los Requisitos
      Mínimos, comprendiendo y aceptándolos íntegra y libremente, desde ese mismo momento,
      plenamente y sin reserva alguna, y dejando expresa constancia que se encuentra en cumplimiento de
      éstos.
    </p>
    <p class="mb-3">
      El incumplimiento o cumplimiento imperfecto de los Requisitos Mínimos influirá en la
      accesibilidad al Sitio y rendimiento de los Servicios, siendo de cargo y responsabilidad del Usuario
      cumplir dichos Requisitos Mínimos para poder utilizarlos.
    </p>
    <p class="mb-3 underline">
      6.02 Utilización del Sitio y de los Servicios.
    </p>
    <p class="mb-3">
      El Usuario podrá acceder libremente al Sitio, revisar su contenido, navegar, obtener información de
      los Servicios, hacer seguimiento de sus operaciones, y revisar el estado de los Servicios que
      contrate.
    </p>
    <p class="mb-3">
      Lokal se reserva la facultad y el derecho de actualizar, modificar o eliminar, en parte o en todo, y sin
      expresión de causa, cualquier contenido o información publicada en el Sitio según lo estime
      conveniente, pudiendo hacer uso de tal facultad en cualquier momento y sin previo aviso. Este
      derecho abarca lo referido a disponibilidad de Servicios, o en cuanto a la configuración y
      funcionalidades del Sitio o de la información de actividades promocionales. Asimismo, podrá
      modificar unilateralmente y sin previo aviso, siempre que lo considere oportuno, la estructura y
      diseño del Sitio, así como modificar o eliminar los Servicios, los contenidos y las condiciones de
      acceso y/o uso de estas.
    </p>
    <p class="mb-3">
      Los Servicios y el uso del Sitio están sujetos a los límites y detalles establecidos en estos Términos
      y Condiciones, y se regirán por las disposiciones de estos documentos y la legislación aplicable.
    </p>
    <p class="mb-3 underline">
      6.03 Acceso y/o Acceso no Autorizado.
    </p>
    <p class="mb-3">
      El acceso a la Cuenta Tienda o Cuenta Marca se realizará mediante el ingreso de su correo
      electrónico y contraseña (en adelante, la "Clave de Seguridad"), previamente determinada por el
      Usuario al momento del registro. Por la aceptación de estos Términos y Condiciones, el Usuario se
      obliga a mantener siempre bajo confidencialidad su Clave de Seguridad. El Usuario acepta y
      entiende que la Cuenta Lokal es personal, única e intransferible, responsabilizándose por todas las
      operaciones efectuadas desde su Cuenta Lokal, pues el acceso a la misma está restringido y la Clave
      de Seguridad debe ser mantenida en confidencialidad.
    </p>
    <p class="mb-3">
      El Usuario se obliga a notificar a Lokal en forma inmediata al correo de contacto
      <a
        class="text-blue-500"
        :href="`mailto:contacto@somoslokal.cl`"
      >
        contacto@somoslokal.cl
      </a>
      cualquier uso no autorizado de su Cuenta Lokal, así como el ingreso por
      terceros no autorizados a la misma. Lokal no será responsable por pérdidas derivadas del uso no
      autorizado de su Cuenta Lokal.
    </p>
    <p class="mb-3">
      En razón del carácter personal de la Cuenta Lokal y la relación de esta con la información
      financiera, bancaria, crediticia o comercial del Usuario, la venta, cesión o transferencia de la Cuenta
      Lokal bajo cualquier título, está estrictamente prohibida. En caso de detectarse la venta, cesión o
      transferencia de la Cuenta Lokal, Lokal podrá proceder discrecionalmente a su cancelación,
      suspensión o inhabilitación.
    </p>
    <p class="mb-3">
      Asimismo, cada Usuario solo podrá inscribir o poseer una Cuenta Marca y una Cuenta Tienda,
      respectivamente, quedando prohibida la apertura de cuentas adicionales. En caso de que Lokal
      detecte distintas Cuentas Lokal que contengan datos coincidentes o relacionados al mismo Usuario,
      podrá de manera discrecional cancelar, suspender o inhabilitar una o ambas Cuentas Lokal.
    </p>
    <p class="mb-3 underline">
      6.04 Derechos del Usuario.
    </p>
    <p class="mb-3">
      El Usuario gozará de todos los derechos que le reconoce la legislación vigente en la República de
      Chile, en especial, los establecidos en la Ley Nº 19.496 sobre protección a los derechos del
      consumidor y la Ley Nº 19.628 sobre protección de datos de carácter personal, en la medida que
      éstas sean aplicables, de acuerdo con las disposiciones de cada una de las leyes y las características
      del Usuario, según sea el caso, y además, los que se le otorgan en estos Términos y Condiciones.
    </p>
    <p class="mb-3 underline">
      6.05 Comentarios de Usuario, retroalimentación y otros envíos.
    </p>
    <p class="mb-3">
      Si, a petición de Lokal, usted envía determinadas presentaciones específicas (por ejemplo,
      participaciones en un concurso) o sin una solicitud nuestra, envía ideas creativas, sugerencias,
      propuestas, planes u otros materiales, ya sea en línea, por correo electrónico, por correo postal o de
      otro modo (en adelante, “Comentarios”), Lokal podrá, en cualquier momento, sin restricciones,
      editar, copiar, publicar, distribuir, traducir y utilizar en cualquier medio dichos comentarios. Lokal
      no tiene ni tendrá ninguna obligación (1) de mantener la confidencialidad de los Comentarios; (2)
      pagar una compensación por los Comentarios; o (3) responder a los Comentarios.
    </p>
    <p class="mb-3">
      Lokal se encuentra facultado, pero no tiene la obligación de, monitorear, editar o eliminar contenido
      que, a su exclusivo criterio, determine que es ilegal, ofensivo, amenazante, calumnioso, difamatorio,
      pornográfico, obsceno o de cualquier otro modo objetable o que viole la propiedad intelectual de
      cualquiera de las partes, de terceros o de estos Términos y Condiciones.
    </p>
    <p class="mb-3">
      El Usuario se obliga a que sus Comentarios no vulneren derechos de terceros, incluidos los
      derechos de autor, marcas comerciales, privacidad, personalidad u otro derecho personal o de
      propiedad. Del mismo modo, se obliga a que sus comentarios no contendrán material difamatorio o
      ilegal, abusivo u obsceno, ni contendrán ningún virus informático u otro malware que pueda afectar
      de alguna manera el funcionamiento del Sitio o cualquier sitio web relacionado. No está permitido
      utilizar una dirección de correo electrónico falsa, pretender ser otra persona que no sea usted mismo
      o engañar a Lokal o a terceros en cuanto al origen de los Comentarios. El Usuario es el único
      responsable de los comentarios que haga y de su precisión. Lokal no asume ninguna responsabilidad
      por los comentarios publicados por el Usuario o un tercero.
    </p>
    <p class="mb-3 underline">
      6.06 Links externos y softwares de terceros.
    </p>
    <p class="mb-3 italic">
      (a) Publicidad, marketing y links externos.
    </p>
    <p class="mb-3">
      El Sitio puede contener banners publicitarios y/o links o enlaces externos relacionados a productos,
      servicios y softwares desarrollados por, o de propiedad de, personas ajenas a Lokal (en adelante,
      “Links de Terceros”), los que no están bajo el control de Lokal. Cualquier adquisición por parte del
      Usuario de tales productos y servicios o cualquier intercambio de datos, productos o servicios entre
      el Usuario y cualquier proveedor que no sea Lokal, será considerado como un intercambio
      exclusivo y únicamente entre el Usuario y el tercero proveedor.
    </p>
    <p class="mb-3">
      Lokal no garantiza ni es responsable de Links de Terceros u otros productos o servicios de terceros,
      a menos que se indique expresamente lo contrario en estos Términos y Condiciones. Lokal no se
      hace responsable de la divulgación, modificación o eliminación de datos personales, que resulten
      del acceso a dichos Links de Terceros o los proveedores de aquellos terceros.
    </p>
    <p class="mb-3">
      Aunque Lokal supervisa la información contenida en el Sitio, parte de la información es
      suministrada por terceros independientes y bajo responsabilidad de estos. De esta manera, si bien
      Lokal realiza sus mejores esfuerzos para verificar la exactitud de la información contenida en el
      Sitio, Lokal no otorga garantía alguna en cuanto a la exactitud, veracidad y vigencia de dicha
      información.
    </p>
    <p class="mb-3">
      Los banners publicitarios y/o links o enlaces externos están disponibles para conveniencia del
      Usuario. Lokal no respalda ni avala ninguno de estos sitios o contenidos, no asumiendo
      responsabilidad alguna por cualquier material a que pueda acceder a través de otras plataformas
      distintas a las ofrecidas por Lokal.
    </p>
    <p class="mb-3">
      Con todo, Lokal prohíbe enlaces no autorizados en el Sitio y el enmarcado de cualquier información
      contenida en el Sitio. Lokal se reserva el derecho de desactivar cualquier enlace o enmarcado no
      autorizado.
    </p>
    <p class="mb-3">
      Lokal no tiene ninguna responsabilidad por cualquier material en otros sitios Web que pueden
      contener enlaces al Sitio.
    </p>
    <p class="mb-3">
      Lokal no es responsable de ningún daño o perjuicio relacionado con la compra o el uso de bienes,
      servicios, recursos, contenido o cualquier otra transacción realizada en relación con páginas web de
      terceros. Revise cuidadosamente, los presentes Términos y Condiciones, las políticas y prácticas de
      los terceros y asegúrese de comprenderlas antes de realizar cualquier transacción. Las quejas,
      reclamos, inquietudes o preguntas sobre productos de terceros deben dirigirse al tercero.
    </p>
    <p class="mb-3 italic">
      (b) Inter-operación, sub-contratación y softwares de terceros
    </p>
    <p class="mb-3">
      Los Servicios y el Sitio pueden contener características diseñada para interoperar con softwares de
      terceros. Lokal no garantiza la disponibilidad continua de dichas características de los Servicios, y
      puede dejar de proporcionarlas sin dar derecho alguno al Usuario a reembolso, crédito u otra
      compensación si, por ejemplo, el proveedor de un software deja de estar disponible para interoperar
      con las características del Servicio correspondiente, de una manera aceptable para Lokal, a su sola
      discreción.
    </p>
    <p class="mb-3">
      El Usuario reconoce y comprende, declarando aceptarla íntegra y libremente, sin reserva alguna, la
      facultad de Lokal para contratar, delegar y/o sub-contratar la ejecución de cualquiera de sus
      obligaciones, tanto respecto a los Servicios, así como también en cuanto a las características del
      Sitio. El Usuario podrá solicitar el listado de los proveedores de servicios a través del correo
      electrónico
      <a
        class="text-blue-500"
        :href="`mailto:contacto@somoslokal.cl`"
      >
        contacto@somoslokal.cl
      </a>
    </p>
    <p class="mb-3 font-normal">
      ARTÍCULO VII <br>
      PROHIBICIONES Y RESTRICCIONES AL USUARIO
    </p>
    <p class="mb-3">
      Sin perjuicio de las prohibiciones y restricciones enunciadas precedentemente en estos Términos y
      Condiciones, queda expresamente prohibido al Usuario:
    </p>
    <p class="flex flex-col mb-3">
      <span class="grid grid-cols-12 mb-2 ml-4">
        <span>(i)</span>
        <span class="col-span-11">
          <span class="font-normal">Venta, distribución o cesión a terceros.</span> No está permitido vender,
          alquilar, arrendar,
          transmitir, modificar, transferir ni asignar la Cuenta Lokal, la Clave de Seguridad ni
          cualquiera de los derechos sobre los mismos, como también, conceder una sublicencia de
          los Servicios y/o del Sitio o de los referidos derechos a ningún tercero, sin la autorización
          previa y por escrito de Lokal.
        </span>
      </span>
      <span class="grid grid-cols-12 mb-2 ml-4">
        <span>(ii)</span>
        <span class="col-span-11">
          <span class="font-normal">Uso de la información.</span> No está permitido modificar, copiar,
          distribuir, transmitir,
          exhibir, publicar, vender, licenciar, crear trabajos derivados o utilizar cualquier
          información disponible en o a través del Sitio para fines comerciales o cualquier otro fin
          que no esté expresamente autorizado en estos Términos y Condiciones.
        </span>
      </span>
      <span class="grid grid-cols-12 mb-2 ml-4">
        <span>(iii)</span>
        <span class="col-span-11">
          <span class="font-normal">Funciones de seguridad.</span> El Usuario no debe intentar ayudar,
          autorizar ni fomentar a
          otras personas a que evadan, inhabiliten o anulen las funciones de seguridad o los
          componentes o la encriptación que proteja o de otro modo restrinja el acceso al Sitio. La
          infracción a lo anterior puede constituir una infracción civil o penal con las consecuentes
          responsabilidades emanadas de ello para el Usuario.
        </span>
      </span>
      <span class="grid grid-cols-12 mb-2 ml-4">
        <span>(iv)</span>
        <span class="col-span-11">
          <span class="font-normal">Avisos sobre propiedad.</span> Queda prohibido al Usuario eliminar
          marcas de agua, etiquetas
          y otros avisos legales o sobre propiedad incluidos en el Sitio y/o en cualquier otro
          contenido disponible al Usuario a través de éste, ni intentar realizar modificaciones al
          Sitio.
        </span>
      </span>
      <span class="grid grid-cols-12 mb-2 ml-4">
        <span>(v)</span>
        <span class="col-span-11">
          <span class="font-normal">Uso del Sitio.</span>
          Queda prohibido al Usuario utilizar el Sitio o su contenido: (a) para
          cualquier propósito ilegal; (b) solicitar a otros que realicen o participen en actos ilícitos;
          (c) violar cualquier reglamento, regla, ley u ordenanza local internacional, federal,
          provincial o estatal; (d) infringir o violar nuestros derechos de propiedad intelectual o los
          derechos de propiedad intelectual de terceros; (e) acosar, abusar, insultar, dañar, difamar,
          calumniar, menospreciar, intimidar o discriminar por motivos de género, orientación
          sexual, religión, etnia, raza, edad, nacionalidad o discapacidad; (f) enviar información
          falsa o engañosa; (g) cargar o transmitir código, archivo, script, agente o programa
          destinado a hacer daño, incluyendo, por ejemplo virus, gusanos, bombas de tiempo,
          material infractor, difamatorio o de otro modo ilegal o lícito, así como, cualquier otro tipo
          de código malintencionado que se utilizará o se podrá utilizar de cualquier forma que
          afecte la funcionalidad o el funcionamiento del Sitio o de cualquier sitio web relacionado,
          otros sitios web, o afecte los derecho de privacidad de terceros; (h) para recopilar o
          rastrear la información personal de otros; (i) enviar spam, phishing, pharm, pretext,
          spider, crawl o scrape; (j) para cualquier propósito obsceno o inmoral; o (k) para
          interferir o eludir las características de seguridad del Sitio o cualquier sitio web
          relacionado, otros sitios web. Nos reservamos el derecho de terminar su uso del Sitio, de
          los Servicios o cualquier sitio web relacionado por violar cualquiera de los usos
          prohibidos.
        </span>
      </span>
    </p>
    <p class="mb-3 font-normal">
      ARTÍCULO VIII <br>
      SUSPENSIÓN E INHABILITACIÓN DE CUENTAS E INCUMPLIMIENTOS
    </p>
    <p class="mb-3">
      El Usuario reconoce y comprende, declarando aceptar íntegra y libremente, sin reserva alguna, la
      facultad de Lokal para suspender el acceso a la Cuenta Lokal de acuerdo con lo señalado en estos
      Términos y Condiciones, en caso de que se infrinjan las obligaciones, prohibiciones y restricciones
      establecidas en los mismos. En consecuencia, el Usuario quedará impedido para acceder a su
      Cuenta Lokal, a la información contenida en la misma y/o a los archivos o cualquier otro contenido
      almacenado en su Cuenta Lokal.
    </p>
    <p class="mb-3">
      Lo anterior se entenderá sin perjuicio de la facultad de Lokal para poner término unilateral y de
      inmediato a los Servicios y de inhabilitar y cancelar la Cuenta Lokal, en caso de incumplimientos
      que sean calificados como graves (en adelante, un “Incumplimiento Grave”), y sin perjuicio de las
      demás acciones que procedan conforme a la ley y a estos Términos y Condiciones.
    </p>
    <p class="mb-3">
      A título meramente referencial y no limitativo, y sin perjuicio de la facultad de Lokal de determinar
      caso a caso si una determinada conducta constituye un Incumplimiento Grave, se considerarán
      como un Incumplimiento Grave las siguientes situaciones:
    </p>
    <p class="flex flex-col mb-3">
      <span class="grid grid-cols-12 mb-2 ml-4">
        <span>(i)</span>
        <span class="col-span-11">
          El no pago oportuno de las tarifas pactadas, en el caso que corresponda.
        </span>
      </span>
      <span class="grid grid-cols-12 mb-2 ml-4">
        <span>(ii)</span>
        <span class="col-span-11">
          El uso no autorizado de cualquier marca de propiedad de Lokal.
        </span>
      </span>
      <span class="grid grid-cols-12 mb-2 ml-4">
        <span>(iii)</span>
        <span class="col-span-11">
          La entrega de información errónea, falsa, inexacta, incompleta o de cualquier forma
          poco fidedigna requerida para el registro de Usuario o para la prestación de los
          Servicios.
        </span>
      </span>
      <span class="grid grid-cols-12 mb-2 ml-4">
        <span>(iv)</span>
        <span class="col-span-11">
          Utilizar el Sitio para fines fraudulentos, dolosos y/o ilícitos.
        </span>
      </span>
      <span class="grid grid-cols-12 mb-2 ml-4">
        <span>(v)</span>
        <span class="col-span-11">
          Por incurrir el Usuario en alguna conducta u omisión que vulnere las disposiciones de
          las leyes sobre propiedad intelectual e industrial ya sea por reproducir, modificar,
          preparar obras derivadas del Sitio, distribuir, licenciar, arrendar, revender, transferir,
          exhibir públicamente, presentar públicamente, transmitir, retransmitir o explotar de otra
          forma el contenido del Sitio.
        </span>
      </span>
      <span class="grid grid-cols-12 mb-2 ml-4">
        <span>(vi)</span>
        <span class="col-span-11">
          Intentar un acceso no autorizado o dañar cualquier aspecto del Sitio.
        </span>
      </span>
      <span class="grid grid-cols-12 mb-2 ml-4">
        <span>(vii)</span>
        <span class="col-span-11">
          Si se quebrantara alguna ley, o cualquiera de las estipulaciones contenidas en los
          Términos y Condiciones.
        </span>
      </span>
      <span class="grid grid-cols-12 mb-2 ml-4">
        <span>(viii)</span>
        <span class="col-span-11">
          Cualquier uso no autorizado del contenido del Sitio.
        </span>
      </span>
      <span class="grid grid-cols-12 mb-2 ml-4">
        <span>(ix)</span>
        <span class="col-span-11">
          El uso no autorizado de cualquier dato o información provista por Lokal o puesta a
          disposición en cualquier forma al Usuario.
        </span>
      </span>
      <span class="grid grid-cols-12 mb-2 ml-4">
        <span>(i)</span>
        <span class="col-span-11">
          La transmisión, al Sitio, de cualquier virus o código de naturaleza destructiva.
        </span>
      </span>
    </p>
    <p class="mb-3">
      En caso de tener lugar un Incumplimiento Grave, se suspenderán inmediatamente los derechos que
      al Usuario le fueran concedidos en virtud de estos Términos y Condiciones, y se procederá a la
      inhabilitación definitiva de la Cuenta Lokal, sin derecho a devolución ni indemnización alguna a su
      favor.
    </p>
    <p class="mb-3">
      Finalmente, Lokal tendrá la facultad de rechazar cualquier solicitud de registro y cancelar un
      registro o Cuenta Lokal en caso de tener antecedentes fundados de que un Usuario ha entregado
      información no válida y/o ha realizado cualquier conducta o uso fraudulento del Sitio, doloso o
      contrario a los Términos y Condiciones o a la legislación aplicable o ha incurrido en un
      Incumplimiento Grave.
    </p>
    <p class="mb-3 font-normal">
      ARTÍCULO IX <br>
      PROTECCIÓN DE DATOS Y COMUNICACIÓN DE DATOS
    </p>
    <p class="mb-3 underline">
      9.01 Tratamiento de Datos – Datos del Usuario.
    </p>
    <p class="mb-3">
      Para poder registrarse en el Sitio, es posible que el Usuario deba proporcionar algunos datos que
      pueden ser considerados como datos personales, por ejemplo, nombre o razón social, número de
      cédula de identidad o rol único tributario, dirección, correo electrónico, teléfono, [datos bancarios]
      (en adelante, los “Datos Personales”). El Usuario reconoce y consiente en que los Datos Personales,
      sean tratados por Lokal con los siguientes fines:
    </p>
    <p class="flex flex-col mb-3">
      <span class="grid grid-cols-12 mb-2 ml-4">
        <span>(i)</span>
        <span class="col-span-11">
          Cumplir y gestionar la relación contractual y/o comercial entre las partes y permitir el
          uso del Sitio por parte del Usuario.
        </span>
      </span>
      <span class="grid grid-cols-12 mb-2 ml-4">
        <span>(Ii)</span>
        <span class="col-span-11">
          Obtener, a partir del uso del Sitio por parte del Usuario, información sobre el tamaño de
          su empresa, información demográfica y geográfica, a fin de facilitar y fortalecer la
          relación comercial entre Lokal y el Usuario.
        </span>
      </span>
      <span class="grid grid-cols-12 mb-2 ml-4">
        <span>(iii)</span>
        <span class="col-span-11">
          Emitir informes y estadísticas agregadas, agrupadas, anonimizadas y disociadas, las que
          podrán ser utilizadas para medir y analizar el comportamiento del Usuario y del
          mercado con el cual interactúan, con fines de marketing, promocionales, de
          investigación de mercados y comerciales.
        </span>
      </span>
    </p>
    <p class="mb-3">
      El Usuario desde ya autoriza a Lokal, en caso de que se registre en el Sitio a que utilice los Datos
      Personales proporcionados, tales como: nombre, correo electrónico y teléfono y/o celular, para
      enviarle las notificaciones o alarmas correspondientes, a objeto de enviarle cualquier comunicación
      o aviso relativo al uso del Sitio.
    </p>
    <p class="mb-3">
      El Usuario desde ya autoriza a Lokal para el tratamiento de los Datos Personales realizados por el
      Sitio a través de <span class="italic">log files</span> o <span class="italic">cookies</span>.
    </p>
    <p class="mb-3">
      Lokal trabaja con empresas de publicidad externas, con fines publicitarios, de seguimiento y de
      elaboración de estudios comerciales. Estas empresas externas podrán instalar cookies en el
      dispositivo del Usuario, cuando esté visite el Sitio, exclusivamente para los fines antes descritos y
      sin que Lokal les facilite dato personal alguno del Usuario.
    </p>
    <p class="mb-3">
      Además, Lokal podrá obtener información del Usuario a partir de proveedores externos de
      información y recabar información sobre las aplicaciones y/o páginas web visitados por el Usuario
      antes o después de haber ingresado al Sitio, todo con fines estrictamente comerciales.
    </p>
    <p class="mb-3 underline">
      9.02. Entrega de la información a terceros.
    </p>
    <p class="mb-3">
      El Usuario consiente en que Lokal podrá anonimizar, disociar y agrupar los Datos Personales, y
      generar informes con fines estadísticos, de marketing, de investigación y comerciales.
    </p>
    <p class="mb-3">
      Finalmente, Lokal podrá revelar información del Usuario en casos de venta o transferencia de
      activos empresariales, para hacer cumplir sus derechos y proteger su patrimonio y el de terceros,
      con motivo de auditorías externas y en cumplimientos legales y/o de requerimientos judiciales.
    </p>
    <p class="mb-3 underline">
      9.03 Derechos del Usuario.
    </p>
    <p class="mb-3">
      El Usuario podrá, en todo momento, ejercer los derechos otorgados por la Ley N° 19.628 y
      especialmente podrá:
    </p>
    <p class="flex flex-col mb-3">
      <span class="grid grid-cols-12 mb-2 ml-4">
        <span>(i)</span>
        <span class="col-span-11">
          Solicitar información sobre los Datos Personales relativos a su persona, su procedencia
          y destinatario, el propósito del almacenamiento y la individualización de las personas u
          organismos a los cuales sus datos personales son transmitidos regularmente;
        </span>
      </span>
      <span class="grid grid-cols-12 mb-2 ml-4">
        <span>(ii)</span>
        <span class="col-span-11">
          Solicitar que se modifiquen sus Datos Personales cuando ellos no sean correctos o no se
          encuentren actualizados, si fuere procedente;
        </span>
      </span>
      <span class="grid grid-cols-12 mb-2 ml-4">
        <span>(iii)</span>
        <span class="col-span-11">
          Solicitar la eliminación o cancelación de los Datos Personales entregados cuando su
          almacenamiento carezca de fundamento legal o cuando estuvieren caducos, o cuando el
          Usuario haya proporcionado voluntariamente sus Datos Personales o ellos se usen para
          comunicaciones comerciales y no desea continuar figurando en el registro respectivo;
        </span>
      </span>
      <span class="grid grid-cols-12 mb-2 ml-4">
        <span>(iv)</span>
        <span class="col-span-11">
          Oponerse a la utilización de sus Datos Personales con fines de publicidad, investigación
          de mercado o encuestas de opinión.
        </span>
      </span>
    </p>
    <p class="mb-3">
      Para ejercer sus derechos, el Usuario deberá contactarse con Lokal a través del siguiente correo:
      <a
        class="text-blue-500"
        :href="`mailto:contacto@somoslokal.cl`"
      >
        contacto@somoslokal.cl
      </a> o al número +569 2600 4440 indicando de forma clara y precisa su solicitud.
    </p>
    <p class="mb-3">
      Toda la información que el Usuario proporcione a Lokal debe ser precisa, correcta y estar
      actualizada. El Usuario podrá en todo momento revocar la autorización que entrega a Lokal para el
      tratamiento de sus datos conforme a lo previsto en la Ley Nº 19.628 sobre protección de la vida
      privada.
    </p>
    <p class="mb-3 font-normal">
      ARTÍCULO X <br>
      PROPIEDAD INTELECTUAL E INDUSTRIAL
    </p>
    <p class="mb-3 underline">
      10.01 Propiedad Intelectual e Industrial de Lokal.
    </p>
    <p class="mb-3">
      Al aceptar estos Términos y Condiciones el Usuario reconoce y declara, sin reserva alguna, que,
      salvo por aquella información o contenido aportado por los Usuarios, la información o contenido
      disponible en o a través de los Servicios y el Sitio, así como también cualquier desarrollo,
      actualización y/o mejora a proyectos, productos, servicios, procesos, negocios, bases de datos,
      procedimientos, programas computacionales, o descubrimientos y, en general, cualquier elemento o
      información de Lokal y todas las invenciones, derechos de autor, documentos, informes,
      investigaciones, formulaciones, aplicaciones y en general cualquier obra preexistente a la relación
      comercial o creada, realizada o desarrollada por Lokal como consecuencia y con ocasión de sus
      Servicios, sean o no susceptibles de ser protegidas, registradas o patentadas de conformidad a la
      legislación vigente, son y serán de exclusivo dominio de Lokal y/o de sus licenciantes, al igual que
      cualquier otro tipo de propiedad intelectual o industrial generada previamente o que se genere o
      derive de tales Servicios, sin limitación o condición alguna y por todo el periodo o plazo de
      protección que fijen las leyes aplicables en materias de propiedad intelectual e industrial, sin mayor
      cargo para Lokal.
    </p>
    <p class="mb-3">
      En consecuencia, el Usuario declara no tener derecho alguno sobre el Sitio, los Servicios y otros
      bienes señalados precedentemente, los que son de propiedad única y exclusiva de Lokal y/o de sus
      licenciadores, renunciando desde ya a cualquier pretensión acción o derecho sobre los mismos.
    </p>
    <p class="mb-3">
      En este sentido, Lokal, sus licenciadores y proveedores, se reservan todos los derechos, títulos e
      intereses en y para los Servicios y el Sitio, incluyendo todos sus derechos de propiedad intelectual
      relacionados. No se conceden al Usuario derechos más allá de los expresamente establecidos en
      estos Términos y Condiciones.
    </p>
    <p class="mb-3">
      El Usuario se compromete a respetar dicha propiedad intelectual en conformidad con la Ley N°
      17.336 sobre Propiedad Intelectual y sus modificaciones posteriores; y a asegurar que sus
      dependientes y profesionales que le presten servicios así lo hagan.
    </p>
    <p class="mb-3">
      El Usuario reconoce que las marcas comerciales de Lokal, de sus personas relacionadas, y de sus
      licenciadores, son activos altamente valiosos, que el Usuario se compromete a respetar y proteger,
      absteniéndose de ejecutar cualquier acto que pudiera desacreditar o perjudicar dichas marcas. Queda
      expresamente estipulado que ni estos Términos y Condiciones otorgan al Usuario derecho alguno
      sobre, ni para el uso de, las marcas asociadas a Lokal, si no es con autorización previa y por escrito
      de esta última, y sólo dentro del ámbito de los Servicios a que se refieren los presentes Términos y
      Condiciones.
    </p>
    <p class="mb-3 underline">
      10.02 Autorización otorgada a Lokal.
    </p>
    <p class="mb-3">
      La Marca otorga a Lokal y sus filiales, una licencia gratuita, perpetua, internacional, transferible,
      sublicenciable y no exclusiva sobre todos los contenidos incluidos en sus publicaciones, tales como,
      los nombres comerciales, marcas, imágenes, textos y todo otro elemento o signo distintivo que
      identifique a la Marca o a los productos publicados en el Sitio.
    </p>
    <p class="mb-3">
      Esta autorización no implica la cesión de los derechos de propiedad intelectual o industrial que
      pudieran existir sobre dichos contenidos.
    </p>
    <p class="mb-3">
      La Marca declara que tiene todas las facultades necesarias para conceder la licencia a Lokal, en los
      términos mencionados. En particular, la Marca acepta que Lokal, a su solo arbitrio, conceda
      sublicencias limitadas, exentas de pago, a la Tienda para que utilicen en sus páginas web el
      contenido aportado por la Marca con el único fin de promocionar los productos comprados a través
      de los Servicios.
    </p>
    <p class="mb-3">
      Asimismo, la Marca responderá de cualquier infracción a derechos de terceros que el uso de dichos
      contenidos pueda ocasionar, y que se compromete a liberar a Lokal de cualquier reclamo que
      pudiera recibir de terceros derivados del uso del contenido descrito precedentemente.
    </p>
    <p class="mb-3 text-base font-normal underline md:text-lg">
      SECCIÓN III <br>
      SERVICIOS
    </p>
    <p class="mb-3 font-normal">
      ARTÍCULO XI
      CONDICIONES DE LOS SERVICIOS
    </p>
    <p class="mb-3">
      Los Servicios ofrecidos por Lokal están sujetos a las siguientes condiciones:
    </p>
    <p class="flex flex-col mb-3">
      <span class="grid grid-cols-12 mb-2 ml-4">
        <span>(i)</span>
        <span class="col-span-11">
          Lokal se reserva el derecho de denegar los Servicios a cualquier persona en la medida en
          que determine que la solicitud de los Servicios se presta para actos ilegales, ofensivos,
          amenazantes, calumniosos, difamatorios, pornográficos, obscenos o de cualquier otro
          modo objetable o que viole la propiedad intelectual de cualquiera de las partes, de un
          tercero, o de estos Términos y Condiciones. Asimismo, Lokal se reserva el derecho a
          rechazar cualquier pedido realizado, conservando la facultad de limitar o cancelar las
          cantidades de productos o servicios compradas por persona, por hogar o por pedido. Estas
          restricciones pueden incluir pedidos realizados por o bajo la misma Cuenta Lokal, la
          misma tarjeta de crédito y/o pedidos que utilizan la misma dirección de facturación y/o
          envío.
        </span>
      </span>
      <span class="grid grid-cols-12 mb-2 ml-4">
        <span>(ii)</span>
        <span class="col-span-11">
          En caso de cambios o cancelaciones, lo notificaremos al correo, teléfono o dirección de
          contacto proporcionada al momento en que se realizó el pedido. Nos reservamos el
          derecho de limitar o prohibir los pedidos que, a nuestro exclusivo criterio, parecen ser
          realizados por concesionarios, revendedores o distribuidores no autorizados al efecto.
        </span>
      </span>
      <span class="grid grid-cols-12 mb-2 ml-4">
        <span>(iii)</span>
        <span class="col-span-11">
          Los precios y las descripciones de los productos ofrecidos proporcionados por la Marca
          están sujetos a cambios sin previo aviso. Sin perjuicio de lo anterior, cualquier cambio de
          precio deberá ser realizado por la Marca responsable del producto ofrecido.
        </span>
      </span>
      <span class="grid grid-cols-12 mb-2 ml-4">
        <span>(iv)</span>
        <span class="col-span-11">
          Lokal se reserva el derecho de, en cualquier momento, modificar o descontinuar los
          Servicios, sin perjuicio de que siempre velará por el correcto término o finalización de los
          Servicios en curso.
        </span>
      </span>
      <span class="grid grid-cols-12 mb-2 ml-4">
        <span>(v)</span>
        <span class="col-span-11">
          Lokal no se hace responsable por ninguna modificación, cambio de precio, suspensión o
          interrupción en los Servicios.
        </span>
      </span>
      <span class="grid grid-cols-12 mb-2 ml-4">
        <span>(vi)</span>
        <span class="col-span-11">
          Lokal se reserva el derecho de limitar las cantidades de cualquier producto o servicio
          ofrecido.
        </span>
      </span>
      <span class="grid grid-cols-12 mb-2 ml-4">
        <span>(vii)</span>
        <span class="col-span-11">
          Ciertos productos o servicios pueden tener cantidades limitadas y están sujetos a
          devolución o cambio solo de acuerdo con nuestra
          <a
            :href="`/frequent_questions`"
            class="text-blue-500"
          >Política de Devolución</a>, la que
          podrán encontrar en el Sitio. Hemos hecho todo lo posible para mostrar con la mayor
          precisión posible los colores e imágenes de los productos ofrecidos por la Marca. No
          podemos garantizar que la visualización de cualquier color en el monitor de su
          computadora sea precisa.
        </span>
      </span>
      <span class="grid grid-cols-12 mb-2 ml-4">
        <span>(viii)</span>
        <span class="col-span-11">
          Respecto a los productos vendidos mediante la intermediación de Lokal, o cualquier
          contenido relacionado con éstos, Lokal no asume la obligación de determinar si: (a)
          cumplen con todas las leyes, normas y reglamentos que resulten de aplicación, incluidos,
          entre otros, los relativos a seguridad, etiquetado, pruebas, advertencias,
          importación/exportación y otras leyes de protección del consumidor aplicables; (b) no
          infringen ningún derecho de propiedad intelectual e industrial, la publicidad u otros
          derechos de terceros; (c) no contienen información falsa, inexacta o engañosa; (d) están
          autorizados para ser distribuidos desde la jurisdicción de la Marca y/o en la jurisdicción
          de usted; y/o (e) están autorizados a ser vendidos por usted desde su jurisdicción a la
          jurisdicción de su cliente.
        </span>
      </span>
    </p>
    <p class="mb-3">
      Ocasionalmente, puede haber información en el Sitio que contenga errores tipográficos,
      inexactitudes u omisiones que puedan estar relacionadas con descripciones de productos, precios,
      promociones, ofertas, cargos de envío de productos, tiempos de tránsito y disponibilidad. Lokal se
      reserva el derecho de corregir cualquier error, inexactitud u omisión, y de cambiar o actualizar la
      información o cancelar pedidos si alguna información en el Servicio o en el Sitio es inexacta en
      cualquier momento sin previo aviso (incluso después de que haya enviado su pedido).
    </p>
    <p class="mb-3">
      La Marca declara conocer y aceptar la existencia y aplicación de las comisiones establecidas en el
      numeral 15.02 siguiente, independiente de la estructura o modalidad de Servicio que se utilice.
    </p>
    <p class="mb-3">
      La Tienda se compromete a llevar a cabo la necesaria comprobación o debida diligencia del
      cumplimiento y adecuación de la Marca y de sus productos así como a aceptar las implicaciones
      legales de la compra de productos vendidos en los Servicios, incluidos, entre otros, los impuestos y
      tasas, la concesión de licencias y cualquier trámite aduanero, derechos o aranceles asociados a las
      compras internacionales. Por la presente, exime a Lokal de cualquier responsabilidad al respecto y
      se compromete a indemnizar, defender y exonerar a Lokal de cualquier reclamación, tal y como se
      dispone en el Artículo XX siguiente.
    </p>
    <p class="mb-3 font-normal">
      ARTÍCULO XII
      COMPRA A TRAVÉS DEL SITIO
    </p>
    <p class="mb-3 underline">
      12.01 Orden de Compra.
    </p>
    <p class="mb-3">
      La Tienda deberá generar una orden de compra en el Sitio, indicando el detalle y número de
      productos que pretenda adquirir, que en ningún caso podrá ser inferior al pedido mínimo señalado
      por la Marca.
    </p>
    <p class="mb-3">
      Lokal notificará a la Marca cada vez que una Tienda genere una orden de compra de cualquiera de
      sus productos.
    </p>
    <p class="mb-3 underline">
      12.02 Aceptación de la Marca.
    </p>
    <p class="mb-3">
      La Marca deberá aceptar o rechazar la orden de compra generada por una Tienda y a nombre de
      ésta, debiendo comunicar su aceptación o rechazo a Lokal e conformidad a lo establecido a través
      de la plataforma (www.somoslokal.cl) según la Regulación Lokal.
    </p>
    <p class="mb-3">
      En caso de que la Marca no manifieste su aceptación o rechazo dentro del plazo indicado, se
      entenderá que la orden de compra se encuentra rechazada.
    </p>
    <p class="mb-3 underline">
      12.03 Facturación.
    </p>
    <p class="mb-3">
      La facturación dependerá del servicio prestado. En la mayoría de los casos, la Marca deberá emitir
      una factura a Lokal con la comisión descontada según corresponda, y Lokal emitirá una
      factura a la Tienda con los precios mayoristas publicados en el Sitio. En algunos casos, y bajo concenso de
      la Marca y Lokal, la Marca podrá emitir una factura directamente a la Tienda y Lokal emitirá una
      factura de venta por la comisión de los servicios prestados, cuando corresponda.
    </p>
    <p class="mb-3">
      La Marca declara conocer y aceptar la existencia y aplicación de las comisiones establecidas en el
      numeral 15.02 siguiente, independiente de la estructura o modalidad de Servicio que se utilice.
    </p>
    <p class="mb-3 font-normal">
      ARTÍCULO XIII <br>
      ENVÍO Y DEVOLUCIÓN DE PRODUCTOS
    </p>
    <p class="mb-3 underline">
      13.01 Envío de los productos.
    </p>
    <p class="mb-3 italic">
      (a) Coordinación de envío de los productos.
    </p>
    <p class="mb-3">
      Los productos de la Marca serán enviados a la Tienda a través de empresas externas, independientes
      y no vinculadas a Lokal, por lo que la continuidad de su prestación de servicios en el tiempo, así
      como el correcto funcionamiento de sus herramientas, serán de su exclusiva responsabilidad y en
      ningún caso de Lokal. Sin perju icio de lo anterior, Lokal apoyará en la gestión de los envíos y,
      en caso de incidencias, Lokal velará por resolver los problemas lo más rápido coordinando nuevos
      envíos en caso de ser necesario.
    </p>
    <p class="mb-3">
      Adicionalmente, las Marcas podrán enviar los productos a través de sus propios medios, en cuyo caso
      deberán reportar en el Sitio el estado del pedido y así Lokal comunicar a la Tienda el estado del
      envío. En caso de que un pedido se pierda o no llegue a la Tienda en tiempo y forma, la Marca será
      responsable de reenviar el pedido a la Tienda.
    </p>
    <p class="mb-3 italic">
      (b) Número de seguimiento.
    </p>
    <p class="mb-3">
      Cada vez que se realice un envío a través de un empresa externa, se publicará un número de seguimiento
      en el Sitio para que la Marca, Lokal y/o la Tienda, puedan realizar el seguimiento al envío de los productos.
    </p>
    <p class="mb-3">
      En caso de que el envío se realice a través de los propios medios de la Marca, la Marca deberá
      reportar el estado del pedido en el Sitio, para que Lokal pueda comunicar a la Tienda el estado del
      envío.
    </p>
    <p class="mb-3 italic">
      (c) Gastos incurridos por el envío de los productos.
    </p>
    <p class="mb-3">
      Los gastos incurridos por el envío de los productos serán soportados por la Tienda.
    </p>
    <p class="mb-3 italic">
      (d) Autorización Marca y Tienda.
    </p>
    <p class="mb-3">
      La Marca y la Tienda autorizan a Lokal a otorgar toda la información necesaria a los proveedores de
      servicios externos para realizar el envío de los productos.
    </p>
    <p class="mb-3 underline">
      (e) Empaque de los productos.
    </p>
    <p class="mb-3">
      La Marca estará obligada a embalar y empaquetar los productos correctamente y con el debido
      cuidado para cuidar la calidad del producto y protegerlo de golpes o daños que se puedan producir
      durante su traslado. En caso de que la Marca no cumpla con esta obligación, estará obligada a
      resarcir a la Tienda en los términos indicados en el numeral 13.02 siguiente.
    </p>
    <p class="mb-3 underline">
      13.02 Responsabilidad de la Marca por productos dañados y/o pedidos incompletos.
    </p>
    <p class="mb-3">
      En caso de que la Tienda reciba productos dañados o en mal estado, la Marca será responsable de
      reemplazar dichos productos dañados asumiendo todos los costos asociados a su reemplazo, no
      cabiendo responsabilidad alguna a Lokal por este concepto. Sin perjuicio de lo anterior, si por
      cualquier motivo, Lokal debiera responder a la Tienda por productos dañados o en mal estado, la
      Marca se obliga a indemnizar íntegramente a Lokal por todos los gastos en que haya incurrido por
      esta razón.
    </p>
    <p class="mb-3">
      Asimismo, Lokal tampoco responderá en caso de que el pedido llegue incompleto, o no llegue, a la
      Tienda, debiendo la Marca asumir dicha responsabilidad.
    </p>
    <p class="mb-3">
      En todo caso, si cualquiera de las hipótesis planteadas en este numeral se verifica por una causa
      imputable a la empresa de despacho, Lokal realizará sus mejores esfuerzos para proporcionar toda
      la información necesaria a fin de que la Marca pueda dirigirse contra dicho proveedor para obtener
      la indemnización correspondiente.
    </p>
    <p class="mb-3 underline">
      13.03 Devolución de los productos.
    </p>
    <p class="mb-3">
      La Tienda podrá devolver los productos comprados en los siguientes casos:
    </p>
    <p class="flex flex-col mb-3">
      <span class="grid grid-cols-12 mb-2 ml-4">
        <span>(i)</span>
        <span class="col-span-11">
          Los productos comprados no cumplen con las características indicadas en el Sitio; y/o
        </span>
      </span>
      <span class="grid grid-cols-12 mb-2 ml-4">
        <span>(i)</span>
        <span class="col-span-11">
          Los productos recibidos estén dañados o en mal estado.
        </span>
      </span>
    </p>
    <p class="mb-3">
      Los gastos que se incurran por concepto de devolución de los productos en los casos indicados en
      los romanillos (i) y (ii), serán de cargo de la Marca.
    </p>
    <p class="mb-3 underline">
      13.04 Devolución primer pedido.
    </p>
    <p class="mb-3">
      Lokal podrá ofrecer devolución gratuita en el primer pedido realizado a algunas Marcas, por un
      plazo de 60 días desde que la Tienda recibió el pedido de la Marca, siempre y cuando, a juicio de
      Lokal, el producto cumpla con los requisitos y condiciones para ser devuelto de acuerdo a lo
      establecido en las Políticas de Devolución publicadas en la página web. Este beneficio ofrecido por
      Lokal solo aplicará al primer pedido realizado a la Marca por parte de una Tienda y cuando este
      beneficio se encuentre debidamente especificado en su catálogo en la Página de la Marca, en caso
      contrario este beneficio no se encuentra habilitado para la Marca.
    </p>
    <p class="mb-3 underline">
      13.05 Productos devueltos.
    </p>
    <p class="mb-3">
      En caso de que los productos de la Marca sean devueltos por la Tienda, Lokal podrá adquirir dichos
      productos en los términos establecidos en las Políticas de Devolución y vender dichos productos a
      través de sus canales de redistribución. Asimismo, cualquier otra Tienda puede comprar estos
      productos sin tener que cumplir el pedido mínimo establecido por la Marca.
    </p>
    <p class="mb-3">
      La Marca también podrá recomprar los productos que han sido devueltos por la Tienda, a través del
      programa de recompra de productos establecido por Lokal en el Sitio.
    </p>
    <p class="mb-3 font-normal">
      ARTÍCULO XIV <br>
      OBLIGACIONES DE LA MARCA Y DE LA TIENDA
    </p>
    <p class="mb-3 underline">
      14.01 Obligaciones de la Marca.
    </p>
    <p class="mb-3">
      La Marca declara y garantiza el cumplimiento de todas las obligaciones establecidas en estos
      Términos y Condiciones. Adicionalmente, la Marca se obliga a:
    </p>
    <p class="flex flex-col mb-3">
      <span class="grid grid-cols-12 mb-2 ml-4">
        <span>(i)</span>
        <span class="col-span-11">
          Respetar el precio y las condiciones de venta publicadas en el Sitio y en estos Términos
          y Condiciones.
        </span>
      </span>
      <span class="grid grid-cols-12 mb-2 ml-4">
        <span>(ii)</span>
        <span class="col-span-11">
          Emitir la factura o ticket correspondiente, [salvo que la Marca sea una persona física que
          efectúe ventas ocasionales o esté eximida de la obligación de emisión de facturas.]
        </span>
      </span>
      <span class="grid grid-cols-12 mb-2 ml-4">
        <span>(iii)</span>
        <span class="col-span-11">
          Garantizar el derecho de retracto o revocación de la compra dentro del plazo establecido
          en la ley.
        </span>
      </span>
      <span class="grid grid-cols-12 mb-2 ml-4">
        <span>(iv)</span>
        <span class="col-span-11">
          Respetar las garantías legales aplicables y aquellas que haya acordado voluntariamente
          con Lokal, según sea el caso.
        </span>
      </span>
      <span class="grid grid-cols-12 mb-2 ml-4">
        <span>(v)</span>
        <span class="col-span-11">
          Aceptar cambios o devoluciones de los productos, en las condiciones de la Política de
          Devoluciones.
        </span>
      </span>
      <span class="grid grid-cols-12 mb-2 ml-4">
        <span>(vi)</span>
        <span class="col-span-11">
          Asumir los costos de la revocación, cambios o devolución de los productos, en las
          condiciones de la Política de Devoluciones.
        </span>
      </span>
      <span class="grid grid-cols-12 mb-2 ml-4">
        <span>(vii)</span>
        <span class="col-span-11">
          Aceptar los medios de pago que Lokal ponga a su disposición para garantizar la
          seguridad de las operaciones y el funcionamiento de los programas de protección.
        </span>
      </span>
      <span class="grid grid-cols-12 mb-2 ml-4">
        <span>(viii)</span>
        <span class="col-span-11">
          Mantener inventario suficiente de los productos ofrecidos en la Sitio y actualizar dicho
          inventario cada vez que se realice un pedido.
        </span>
      </span>
      <span class="grid grid-cols-12 mb-2 ml-4">
        <span>(ix)</span>
        <span class="col-span-11">
          Informar a Lokal en caso de que se agregue o elimine un producto del catálogo del Sitio.
        </span>
      </span>
      <span class="grid grid-cols-12 mb-2 ml-4">
        <span>(x)</span>
        <span class="col-span-11">
          Responder a las consultas de la Tienda.
        </span>
      </span>
    </p>
    <p class="mb-3 underline">
      14.02 Obligaciones de la Tienda.
    </p>
    <p class="mb-3">
      La Tienda declara y garantiza el cumplimiento de todas las obligaciones establecidas en estos
      Términos y Condiciones. Adicionalmente, la Tienda se obliga a:
    </p>
    <p class="flex flex-col mb-3">
      <span class="grid grid-cols-12 mb-2 ml-4">
        <span>(i)</span>
        <span class="col-span-11">
          Respetar las condiciones de compra publicadas en el Sitio.
        </span>
      </span>
      <span class="grid grid-cols-12 mb-2 ml-4">
        <span>(ii)</span>
        <span class="col-span-11">
          Pagar el precio de los productos y su costo de envío, así como, recibir el producto.
          Aceptar los medios de pago que Lokal pone a su disposición para garantizar la seguridad de las
          operaciones y el funcionamiento de los programas de protección.
        </span>
      </span>
    </p>
    <p class="mb-3 font-normal">
      ARTÍCULO XV <br>
      CONTRATACIÓN Y PAGOS
    </p>
    <p class="mb-3 underline">
      15.01. Contratación y medios de pago.
    </p>
    <p class="mb-3">
      En algunos casos, particularmente al registrarse como Cuenta Marca, Lokal cobrará una tarifa por el
      uso del Sitio y de los Servicios. El Usuario, en caso de que sea aplicable, se compromete a pagar a
      Lokal la tarifa informada, en el tiempo y montos previstos en las condiciones particulares de los
      Servicios a que se refieren estos Términos y Condiciones, y que se complementa con lo señalado en
      el Sitio. Todo lo cual estará disponible y deberá ser aceptado al momento de registrarse en el Sitio.
      Lokal dispondrá de diversos medios de pago electrónicos y portales de pago que permitirán pagar la
      tarifa del, o los, Servicios que se estén contratando. El Usuario declara conocer y aceptar que estos
      medios de pago o portales de pago pertenecen a terceras empresas proveedoras de estos servicios,
      independientes y no vinculadas a Lokal, por lo que la continuidad de su prestación de servicios en el
      tiempo, así como el correcto funcionamiento de sus herramientas y botones de pago en línea, serán
      de exclusiva responsabilidad de las empresas proveedoras de estos servicios y en ningún caso de
      Lokal.
    </p>
    <p class="mb-3 underline">
      15.02. Comisiones Marca.
    </p>
    <p class="mb-3">
      Salvo acuerdo en contrario, Lokal cobrará una comisión equivalente a un porcentaje del total del
      monto pagado a la Marca en el primer pedido, según se específica en el Sitio. A partir del segundo
      pedido, dicha comisión se ajustará de acuerdo con lo establecido en el Sitio.
    </p>
    <p class="mb-3">
      Tanto la comisión inicial, como aquella aplicable a partir del segundo pedido, se encontrarán
      disponibles en el Sitio y deberá ser aceptada por la Marca al momento de enrolarse como Usuario
      del Sitio.
    </p>
    <p class="mb-3">
      En caso de que una Tienda a través del Sitio realice un mismo pedido a distintas marcas, la
      comisión a cobrar a cada Marca se calculará sobre el monto total de los productos comprados a cada
      Marca.
    </p>
    <p class="mb-3">
      La comisión se calculará sobre el importe total del pedido realizado a la Marca, excluyendo los
      gastos del envío que deberán ser soportados por la Tienda.
    </p>
    <p class="mb-3">
      Ciertas categorías de productos y ubicaciones pueden dar lugar a una estructura de comisiones
      diferente de la prevista. En ese último caso, esto le será comunicado a usted durante la
      incorporación o la creación de su Cuenta Marca o al realizar el pedido respectivo.
    </p>
    <p class="mb-3">
      Al aceptar estos Términos y Condiciones, la Marca reconoce y acepta las comisiones, los
      honorarios, los cálculos y los plazos de pago señalados precedentemente.
    </p>
    <p class="mb-3 underline">
      15.03. Pagos.
    </p>
    <p class="mb-3 italic">
      (a) Plazos de Pagos.
    </p>
    <p class="mb-3">
      La Tienda estará obligada al pago del precio de los productos a través del método de pago indicado
      en la Cuenta Tienda, dentro del plazo máximo indicado en cada caso.
    </p>
    <p class="mb-3">
      La Marca recibirá el pago del precio por la venta del o los productos dentro del plazo indicado en el
      Sitio, contado desde el envío del producto, salvo acuerdo en contrario con Lokal y/o la Tienda.
    </p>
    <p class="mb-3">
      Si cualquier fecha de pago establecida precedentemente cayese en un día que no es un día hábil
      bancario, dicha fecha de pago se extenderá al día hábil bancario inmediatamente siguiente.
    </p>
    <p class="mb-3">
      La Marca expresamente faculta a Lokal para recibir el pago del precio de los productos y coordinar
      los pedidos realizados por la Tienda, por lo que, una vez realizado el pago, se entiende que la
      obligación de pago de la Tienda con la Marca queda extinguida.
    </p>
    <p class="mb-3 underline">
      (b) Deducción de comisiones.
    </p>
    <p class="mb-3">
      La Marca acepta y autoriza que Lokal y/o cualquier procesador de pagos mandatado por Lokal
      deduzca todas las comisiones y honorarios que la Marca debe pagar o pueda adeudar a Lokal por los
      Servicios.
    </p>
    <p class="mb-3 underline">
      (c) Suspensión de pagos.
    </p>
    <p class="mb-3">
      Lokal se reserva el derecho de suspender los pagos debido a cualquier disputa pendiente,
      devoluciones o reembolsos excesivos y/o cualquier otra actividad impropia o ilegal.
    </p>
    <p class="mb-3 italic">
      (d) Impuestos.
    </p>
    <p class="mb-3">
      Usted reconoce y acepta que es el único responsable de la recaudación y el envío de cualquier
      impuesto, incluidos el IVA y los impuestos sobre las ventas, así como de cualquier otro pago o
      declaración exigidos por la legislación aplicable.
    </p>
    <p class="mb-3 underline">
      15.04 Incumplimiento de pagos de la Tienda.
    </p>
    <p class="mb-3">
      Si la Tienda incumple sus obligaciones de pago en el plazo establecidas en la letra (a) precedente o
      de cualquier otra obligación adeudada, Lokal podrá:
    </p>
    <p class="grid grid-cols-12 mb-3 ml-4">
      <span>(i)</span>
      <span class="col-span-11 mb-2">
        Pagar a la Marca y recibir los productos comprados, con el objeto de venderlos
        directamente a través de sus canales de redistribución, y percibir el monto de dicha
        venta.
      </span>
      <span>(ii)</span>
      <span class="col-span-11 mb-2">
        Cancelar cualquier orden de compra pendiente de confirmación por parte de la Marca.
      </span>
      <span>(iii)</span>
      <span class="col-span-11 mb-2">
        Disminuir o cancelar el crédito comercial aprobado.
      </span>
      <span>(iv)</span>
      <span class="col-span-11 mb-2">
        Suspender temporal o definitivamente la Cuenta Tienda.
      </span>
    </p>
    <p class="mb-3">
      En caso de no pago, Lokal se reserva el derecho, actuando por cuenta del acreedor correspondiente,
      a solicitar la gestión del cobro de las cantidades adeudadas a una agencia de cobros o buscar
      cualquier otro recurso o acción legal disponible al efecto.
    </p>
    <p class="mb-3 underline">
      15.05 Beneficios Adicionales.
    </p>
    <p class="mb-3 italic">
      (a) Pago a 60 días.
    </p>
    <p class="mb-3">
      Lokal podrá ofrecer a la Tienda facturarle a 60 días en cada compra de productos, con el objeto de
      que la Tienda tenga un plazo mayor de pago, lo cual se informará oportunamente al momento de
      realizar la compra. Sin perjuicio de lo anterior, la Tienda podrá pagar en cualquier momento durante
      el plazo de los 60 días.
    </p>
    <p class="mb-3 italic">
      (b) Crédito comercial.
    </p>
    <p class="mb-3">
      Lokal podrá ofrecer a la Tienda un crédito comercial en base a su historial crediticio, cumplimiento
      con los pagos, capacidad de crédito, nivel de deudas, entre otras métricas. Los créditos comerciales
      son el monto que puedes usar para pagar hasta 60 días después de recibir los productos. Los créditos
      comerciales iniciales no excederán el monto indicado en el Sitio y están sujetos a una previa
      evaluación a cada empresa.
    </p>
    <p class="mb-3">
      El monto disponible se calculará caso a caso mediante una puntuación crediticia de la Tienda que le
      permitirá acceder a un determinado monto de crédito, el que aumentará o disminuirá dependiendo el
      cumplimiento de pagos de la Tienda y de otros criterios fijados por Lokal.
    </p>
    <p class="mb-3">
      La Tienda podrá solicitar un aumento de crédito comercial ya aprobado, previa evaluación y
      aprobación de Lokal. Lokal se reserva el derecho de cambiar el crédito comercial en cualquier
      momento a su discreción. Usted se compromete a no crear varias cuentas a fin de intentar eludir los
      límites de crédito comercial asignados a la Tienda.
    </p>
    <p class="mb-3">
      Lokal se reserva el derecho a suspender temporal o definitivamente el crédito comercial con la
      Tienda, así como a disminuir el monto otorgado, a sola discreción de Lokal.
    </p>
    <p class="mb-3 font-normal">
      ARTÍCULO XVI <br>
      PÁGINA DE LA MARCA
    </p>
    <p class="mb-3 underline">
      16.01 Página de la Marca.
    </p>
    <p class="mb-3">
      Cada Marca tiene en el Sitio una página mediante la cual presenta sus productos, incluyendo las
      descripciones y otra información pertinente de los mismos, la que deberá ser entregada
      íntegramente por la Marca (en adelante, la “Página de la Marca”).
    </p>
    <p class="mb-3">
      Lokal creará y diseñará la Página de la Marca, con la información que proporcione la Marca. No
      obstante lo anterior, Lokal podrá autorizar a la Marca a hacerlo ella misma. Cualquiera sea el caso,
      Lokal se reserva la facultad de ajustar, editar, aprobar o rechazar el diseño de la Página de la Marca,
      a su entera discreción.
    </p>
    <p class="mb-3">
      Adicionalmente, los productos podrán ser exhibidos y/o agrupados libremente dentro del Sitio,
      pudiendo ser agrupados por tipo o categoría, sub-categorías, marcas, entre otras clasificaciones, las
      que se establecerán a entera discreción de Lokal.
    </p>
    <p class="mb-3">
      Para efectos de lo anterior, la Marca deberá proporcionar a Lokal:
    </p>
    <p class="grid grid-cols-12 mb-3 ml-4">
      <span>(i)</span>
      <span class="col-span-11 mb-2">Formulario de información.</span>
      <span>(ii)</span>
      <span class="col-span-11 mb-2">Fotografías de alta resolución de los productos.</span>
      <span>(iii)</span>
      <span class="col-span-11 mb-2">Pedidos mínimos.</span>
      <span>(iv)</span>
      <span class="col-span-11 mb-2">Plazo mínimo de entrega de los productos.</span>
    </p>
    <p class="mb-3 italic">
      (a) Formulario de información.
    </p>
    <p class="mb-3">
      Como parte del proceso de registro, la Marca deberá completar un formulario con la siguiente
      información del o los productos que serán ofrecidos en la Página de la Marca: (i) nombre; (ii)
      descripción; (iii) tamaño; (iv) variaciones o modelos; (v) precio público y precio mayorista; (vi)
      tamaño de la caja o empaquetado; y, (vii) presencia en el mercado así como cualquier otra
      información que Lokal estime relevante para una adecuada venta de productos.
    </p>
    <p class="mb-3 italic">
      (b) Fotografías de alta resolución de los productos.
    </p>
    <p class="mb-3">
      La Marca deberá proporcionar a Lokal fotografías de alta resolución de sus productos que cumplan
      con los requerimientos exigidos por Lokal. Queda expresamente prohibido las fotografías que
      puedan considerarse violentas, pornográficas, acosadoras, amenazantes, odiosas, o de cualquier otro
      modo inapropiadas e ilegales.
    </p>
    <p class="mb-3">
      Lokal se reserva el derecho a seleccionar discrecionalmente las fotos enviadas por la Marca que
      estime convenientes para la Página de la Marca.
    </p>
    <p class="mb-3 italic">
      (c) Pedidos mínimos.
    </p>
    <p class="mb-3">
      La Marca podrá establecer un número mínimo de productos o monto mínimo por cada pedido de la
      Tienda para acceder al precio mayorista. Para efectos de lo anterior deberá proporcionar a Lokal la
      información requerida tanto para el primer pedido como para pedidos posteriores.
    </p>
    <p class="mb-3 italic">
      (d) Plazo de entrega de los productos.
    </p>
    <p class="mb-3">
      La Marca deberá establecer el plazo en que entregará los productos vendidos a través del Sitio, y se
      comprometerá a realizar sus mejores esfuerzos para cumplir dichos plazos. Sin perjuicio de lo
      anterior, Lokal se reserva el derecho a modificar los plazos informados en base el cumplimiento
      histórico de la Marca.
    </p>
    <p class="mb-3 underline">
      16.02 Declaraciones y Garantías.
    </p>
    <p class="mb-3">
      La Marca declara y garantiza que: (a) es el único y exclusivo propietario de tales contenidos, o que
      tiene todos los derechos, licencias, consentimientos y permisos necesarios para conceder a Lokal los
      derechos sobre los mismos según lo dispuesto en estos Términos y Condiciones; y que, (b) ni el
      contenido proporcionado ni ninguna parte del mismo, ni su anuncio, carga, publicación, envío o
      transmisión, ni el uso por parte de Lokal del mismo en, a través o por medio de los Servicios y/o
      plataformas de terceros, supondrá una infracción, apropiación indebida o violación de las patentes,
      derechos de autor, marcas comerciales, secretos comerciales u otros derechos intelectuales e
      industriales de terceros, derechos morales u otros derechos de propiedad intelectual e industrial, o
      derechos de publicidad o privacidad, ni contendrán material difamatorio, engañoso o de otro modo
      ilegal, abusivo u obsceno, ni cualquier virus informático u otro malware que pueda afectar de algún
      modo al funcionamiento de los Servicios, o que dé lugar al incumplimiento de cualquier ley o
      normativa aplicable.
    </p>
    <p class="mb-3">
      La Marca es la única responsable de los contenidos que proporcione y de la exactitud de éstos.
      Lokal no asume responsabilidad alguna por las publicaciones de que haya realizado la Marca o un
      tercero.
    </p>
    <p class="mb-3 font-normal">
      ARTÍCULO XVII <br>
      PRODUCTOS DE LA MARCA
    </p>
    <p class="mb-3 underline">
      17.01 Listado de productos de la Marca.
    </p>
    <p class="mb-3">
      La Marca deberá proporcionar a Lokal un listado de los productos que se ofrecerán en el Sitio, para
      que esta confeccione y publique con el objeto el catálogo de los productos ofrecidos por la Marca.
      Dicho listado deberá contener una descripción de cada uno de los productos.
    </p>
    <p class="mb-3">
      Lokal se reserva el derecho de aceptar o rechazar los productos incluidos en el referido catálogo de
      productos.
    </p>
    <p class="mb-3 italic">
      17.02 Precio de los productos de la Marca.
    </p>
    <p class="mb-3">
      La Marca no podrá fijar un precio mayor a aquellos que ofrece en otros canales de venta al por
      mayor, por lo que Lokal dispondrá de un precio máximo en que se podrán ofrecer los productos en
      el Sitio. Asimismo, Lokal podrá hacer una revisión periódica de los precios en el Sitio.
    </p>
    <p class="mb-3">
      Tanto los precios mayoristas, como los precios públicos, se mostrarán en el Sitio considerando los
      impuestos asociados, incluyendo el IVA.
    </p>
    <p class="mb-3 italic">
      17.03 Inventario.
    </p>
    <p class="mb-3">
      La Marca deberá proporcionar a Lokal el inventario inicial del, o los, productos que se ofrecerán en
      el Sitio, el cual deberá ser suficiente y, en cualquier caso, tras la realización de una venta. La Marca
      reconoce y acepta la obligación de actualizar dicho inventario, como parte de sus obligaciones
      esenciales y requisitos mínimos en relación con el uso de los Servicios.
    </p>
    <p class="mb-3 font-normal">
      ARTÍCULO XVIII <br>
      EVALUACIÓN DE LA MARCA
    </p>
    <p class="mb-3">
      Lokal evaluará periódicamente a la Marca bajo los siguientes criterios y parámetros de evaluación:
    </p>
    <p class="mb-3 italic">
      (a) Métricas.
    </p>
    <p class="mb-3">
      Lokal realizará un seguimiento y una medición del rendimiento de la Marca utilizando las
      siguientes métricas:
    </p>
    <p class="grid grid-cols-12 mb-3 ml-4">
      <span>(i)</span>
      <span class="col-span-11 mb-2">Recopilación de reseñas, comentarios y calificaciones realizadas por la Tienda.</span>
      <span>(ii)</span>
      <span class="col-span-11 mb-2">Precisión del listado de productos.</span>
      <span>(iii)</span>
      <span class="col-span-11 mb-2">Catálogos completos.</span>
      <span>(iv)</span>
      <span class="col-span-11 mb-2">Tiempo para aceptar o rechazar un pedido.</span>
      <span>(v)</span>
      <span class="col-span-11 mb-2">Número de reclamos por pedidos con productos dañados o defectuosos, así como, pedidos incompletos.</span>
      <span>(vi)</span>
      <span class="col-span-11 mb-2">Rapidez para responder las consultas de la Tienda.</span>
      <span>(vii)</span>
      <span class="col-span-11 mb-2">
        Capacidad de solucionar con la Tienda cualquier dificultad que se produzca en los
        pedidos, en particular, daño de los productos o pedidos incompletos.
      </span>
    </p>
    <p class="mb-3 italic">
      (b) Volumen de pedidos.
    </p>
    <p class="mb-3">
      La medición de las métricas indicadas en la letra (a) precedente, será realizada por Lokal tomando
      en consideración el volumen de los pedidos que se realizan a la Marca.
    </p>
    <p class="mb-3 italic">
      (c) Periodicidad.
    </p>
    <p class="mb-3">
      Lokal evaluará [mensualmente] a cada Marca, sin perjuicio de que evaluarlas más de una vez
      durante el mismo mes dependiendo los volúmenes de pedidos que tenga la Marca.
    </p>
    <p class="mb-3">
      Lokal se reserva el derecho a ajustar la periodicidad de evaluación a cada Marca.
    </p>
    <p class="mb-3 italic">
      (d) Responsabilidad y sanciones.
    </p>
    <p class="mb-3">
      Si la Marca no está cumpliendo con los estándares fijados por Lokal en estos Términos y
      Condiciones o en cualquier otro documento, Lokal se pondrá en contacto con la Marca para
      solucionar los problemas que ésta tenga.
    </p>
    <p class="mb-3">
      En caso de que no sea posible encontrar una solución, Lokal podrá limitar las funcionalidades de la
      Cuenta de la Marca, el número de productos ofrecidos, el número de tiendas a las que puede vender,
      entre otras sanciones. Asimismo, también podrá suspender temporalmente o cancelar
      definitivamente la cuenta.
    </p>
    <p class="mb-3 font-normal">
      ARTÍCULO XIX <br>
      SEGUROS
    </p>
    <p class="mb-3">
      Cada Marca estará obligada a suscribir, por cuenta propia y bajo su costo, un seguro de
      responsabilidad civil por daños por los productos que estén en el Sitio, que resulten defectuosos y
      puedan producir un daño o perjuicio a la Tienda y/o terceros, así como respecto a los daños que
      sufran los productos durante el transporte a la Tienda en caso de que la Marca sea responsable de
      dicho despacho.
    </p>
    <p class="mb-3">
      Lokal podrá en cualquier momento solicitar antecedentes que acrediten la contratación y vigencia
      de dicho seguro, y en caso de que la Marca no cumpla con esta obligación, Lokal tendrá derecho a
      cancelar definitivamente la Cuenta Marca.
    </p>
    <p class="mb-3 text-base font-normal underline md:text-lg">
      SECCIÓN IV <br>
      EXCLUSIÓN DE GARANTÍAS Y RESPONSABILIDAD
    </p>
    <p class="mb-3 font-normal">
      ARTÍCULO XX <br>
      EXCLUSIÓN DE GARANTÍAS Y RESPONSABILIDAD DE LOKAL
    </p>
    <p class="mb-3 underline">
      20.01 Exclusión de garantía.
    </p>
    <p class="mb-3">
      El Usuario acepta y reconoce, sin reserva alguna, que Lokal ofrece y proporciona los Servicios y el
      Sitio, “tal cual” y como están disponibles, sin ninguna garantía, ni expresa ni implícita,
      especialmente en relación con que éste funcione u opere ininterrumpidamente o libre de errores. En
      consecuencia, Lokal no tendrá responsabilidad alguna por la pérdida de datos o información propia
      del Usuario o de terceros, ni por cualesquiera otras pérdidas, daños y/o perjuicios que se puedan
      causar en la persona, bienes o información del Usuario, de sus dependientes, de sus clientes o de
      terceros, relacionados con el uso del Sitio. El Usuario comprende y acepta que el uso del Sitio es a
      su propio riesgo y reconoce que los sistemas informáticos y de telecomunicaciones involucrados en
      el funcionamiento de ésto no está libre de errores y que, eventualmente, se pueden producir
      períodos de indisponibilidad, debiendo el Usuario tomar todas las medidas preventivas usuales en la
      actividad de soluciones tecnológicas y/o virtuales, con el fin de evitar que las fallas o
      indisponibilidades le causen cualquier tipo de daño o perjuicio.
    </p>
    <p class="mb-3">
      Asimismo, el Usuario acepta y reconoce que el material del Sitio se proporciona solo para
      información general y no debe confiarse en él ni utilizarse como la única base para tomar decisiones
      sin consultar fuentes de información primarias, más precisas, más completas o más oportunas.
      Cualquier confianza en el material de este Sitio es bajo su propio riesgo. Los Servicios incluyen el
      contenido proporcionado por terceros, así como los materiales proporcionados por Usuarios y
      terceros licenciantes. Todas las declaraciones y opiniones expresadas en dichos materiales, así como
      todos los artículos y respuestas a consultas y otros contenidos distintos de aquella información o
      documentos proporcionados por Lokal, son únicamente responsabilidad de la persona o entidad que
      los proporciona. Estos materiales no reflejan necesariamente la opinión de Lokal. No nos hacemos
      responsables, ni ante usted ni ante terceros, del contenido o la exactitud de los materiales
      proporcionados por terceros. Usted es el único responsable de todas las comunicaciones e
      interacciones que intercambie con otros usuarios de los Servicios y con otras personas con las que
      se comunica o interactúa como consecuencia del empleo de los Servicios.
    </p>
    <p class="mb-3">
      Tampoco garantizamos que la calidad de cualquier producto, servicio, información u otro material
      comprado u obtenido por usted, a través del Sitio, cumpla con sus expectativas, o que cualquier
      error en el Servicio será corregido. Sin limitar lo anterior, Lokal rechaza explícitamente cualquier
      garantía de comerciabilidad, idoneidad para un fin determinado, goce y uso pacífico o no infracción,
      así como cualquier garantía derivada del curso de los negocios o del uso del comercio. Lokal no
      garantiza que los Servicios o el contenido del Sitio satisfagan sus necesidades o estén disponibles de
      forma ininterrumpida, segura o sin errores. Lokal no garantiza la calidad de los productos, servicios
      o contenidos adquiridos u obtenidos a través de los Servicios, ni la exactitud, puntualidad,
      veracidad, integridad o fiabilidad de los productos, servicios o contenidos obtenidos a través de los
      Servicios, los que serán de exclusiva responsabilidad de la Marca y/o de sus distribuidores, según
      corresponda. Ningún consejo o información, ya sea oral o escrito, obtenido de Lokal o a través de
      los Servicios o contenidos del Sitio, creará ninguna garantía que no se haya otorgado expresamente
      en el presente.
    </p>
    <p class="mb-3">
      Por último, el Usuario acepta y reconoce que el uso del Sitio es de responsabilidad exclusiva de
      éste, por lo que Lokal no se responsabiliza por los daños ocasionado por alguno de los siguientes
      conceptos:
    </p>
    <p class="grid grid-cols-12 mb-3 ml-4">
      <span>(i)</span>
      <span class="col-span-11 mb-2">
        Daños ocasionados por cualquier virus o malware que pudiera infectar el equipo del
        Usuario;
      </span>
      <span>(ii)</span>
      <span class="col-span-11 mb-2">
        Infracciones a leyes de propiedad intelectual e industrial incurridas por el Usuario del
        Sitio; y
      </span>
      <span>(iii)</span>
      <span class="col-span-11 mb-2">
        Suplantación de identidad.
      </span>
    </p>
    <p class="mb-3 underline">
      20.02 Responsabilidad por daños que originen los productos de la Marca.
    </p>
    <p class="mb-3">
      Los productos son de exclusiva propiedad de la Marca, por lo que, cualquier eventual daño o
      perjuicio que sufra la Tienda por la compra de los productos a través del Sitio, es y será de exclusiva
      responsabilidad de la Marca, incluyendo los casos en que Lokal actué como intermediario por
      cuenta de la Tienda.
    </p>
    <p class="mb-3">
      Asimismo, Lokal tampoco es responsable en caso de que la Tienda reciba productos defectuosos, o
      que no cumplan con las características y descripciones que se detallan en el Sitio, ya que la Marca
      será responsable de proporcionar y completar toda la información relacionada a sus productos en la
      Página de la Marca, de conformidad a lo indicado en el Artículo XVI anterior.
    </p>
    <p class="mb-3 underline">
      20.03 Limitación de Responsabilidad.
    </p>
    <p class="mb-3">
      NO OBSTANTE LO ANTERIOR, SI EXISTIERA PARA EL USUARIO EL DERECHO LEGAL
      A RECLAMAR EN CONTRA DE LOKAL POR LOS DAÑOS Y PERJUICIOS OCASIONADOS
      POR EL USO DEL SITIO, ÉSTE EXPRESAMENTE DECLARA Y ACEPTA QUE LA
      RESPONSABILIDAD DE INDEMNIZAR POR PARTE DE LOKAL INCLUIRÁ ÚNICAMENTE
      AQUELLOS DAÑOS MATERIALES DIRECTOS Y PREVISTOS, CON EXCLUSIÓN DE
      CUALQUIER OTRO DAÑO, TALES COMO DAÑOS INDIRECTOS, LUCRO CESANTE,
      DAÑO MORAL, ETC., Y, EN TODO CASO, HASTA UN MONTO MÁXIMO EQUIVALENTE A
      LA ORDEN DE COMPRA QUE DIO ORIGEN A LOS DAÑOS QUE SE RECLAMAN.
    </p>
    <p class="mb-3">
      LOKAL NO INDEMNIZARÁ AL USUARIO, BAJO NINGUNA CIRCUNSTANCIA, DE
      CUALQUIER DAÑO, HONORARIOS DE ABOGADOS Y COSTOS FINALMENTE
      ADJUDICADOS CONTRA EL USUARIO COMO RESULTADO DE, O POR LAS
      CANTIDADES PAGADAS POR EL USUARIO EN VIRTUD DE UN ACUERDO O UNA
      RECLAMACIÓN CONTRA EL USUARIO.
    </p>
    <p class="mb-3 underline">
      20.04 Indemnidad de Lokal.
    </p>
    <p class="mb-3">
      El Usuario se obliga a indemnizar, defender y eximir de toda responsabilidad a Lokal y su matriz,
      subsidiarias, afiliadas, accionistas, trabajadores, directores, agentes, contratistas, indemnes de
      cualquier reclamo o demanda, incluyendo honorarios razonables de abogados, hechos por cualquier
      tercero que surjan de un incumplimiento por parte del Usuario de los presentes Términos y
      Condiciones, los documentos que se incorporan para su referencia, la violación cualquier ley o
      derechos de terceros. Lokal dará aviso, a la brevedad posible, de cualquier reclamación, demanda o
      procedimiento, y tendrá derecho a asumir la defensa respecto de cualquier reclamación, demanda o
      procedimiento.
    </p>
    <p class="mb-3 text-base font-normal underline md:text-lg">
      SECCIÓN V <br>
      MISCELÁNEOS <br>
      ARTÍCULO XXI <br>
      MISCELÁNEOS
    </p>
    <p class="mb-3 underline">
      21.01 Renuncias.
    </p>
    <p class="mb-3">
      El hecho que Lokal no ejercite o demore el ejercicio de cualesquiera de sus derechos de acuerdo con
      estos Términos y Condiciones no constituirá una renuncia a ellos, ni tampoco el ejercicio separado
      o parcial de algún derecho impedirá el ejercicio posterior de los mismo o de otros derechos.
      Solamente una renuncia por escrito y firmada por un representante legal de Lokal tendrá efecto
      legal.
    </p>
    <p class="mb-3 underline">
      21.02 Independencia de las cláusulas.
    </p>
    <p class="mb-3">
      Si se determina que alguna disposición de estos Términos y Condiciones es ilegal, nula o
      inaplicable por algún motivo, dicha disposición se considerará separable de estos Términos y
      Condiciones y no afectará la validez y aplicabilidad de las disposiciones restantes.
    </p>
    <p class="mb-3 underline">
      21.03 Notificaciones y comunicaciones.
    </p>
    <p class="mb-3">
      Todos los avisos y otras comunicaciones a que se refieren los Términos y Condiciones se efectuarán
      por escrito y se enviarán por correo electrónico o correo certificado, o se entregarán personalmente
      con acuso de recibo, y deberán ser enviadas a las direcciones señaladas por Lokal y el Usuario en el
      Sitio. En caso que se modifique alguno de los destinatarios señalados o alguno de los datos de tales
      destinatarios, bastará que tal circunstancia sea informada enviando un aviso de la forma señalada en
      este numeral a la contraparte.
    </p>
    <p class="mb-3 underline">
      21.04 Ley aplicable, domicilio y jurisdicción.
    </p>
    <p class="mb-3">
      Los presentes Términos y Condiciones, así como la relación entre el Usuario y Lokal, se regirán e
      interpretarán con arreglo a la legislación vigente de la República de Chile. Para todos los efectos
      legales, el Usuario y Lokal fijan su domicilio en la comuna y ciudad de Santiago de Chile, y
      prorrogan competencia a sus tribunales ordinarios de justicia.
    </p>
    <p class="mb-3 underline">
      21.05 Denominación de los artículos y numerales.
    </p>
    <p class="mb-3">
      Los títulos y encabezamientos de los artículos y numerales de los presentes Términos y Condiciones
      son sólo para conveniencia y no afectarán la interpretación de ninguna disposición de éste.
    </p>
    <p class="mb-3 underline">
      21.06 Interpretación.
    </p>
    <p class="mb-3">
      A menos que se indique expresamente lo contrario, en los Términos y Condiciones: /i/ la forma
      singular incluye al plural y viceversa; /ii/ la referencia a un contrato cualquiera, documento o
      instrumento, significa ese contrato, documento o instrumento, incluyendo sus modificaciones o
      versiones enmendadas, en tanto se encuentre vigente de conformidad con sus términos y, si
      corresponde, a los términos de los Términos y Condiciones; /iii/ la referencia a una Ley significa
      esa Ley y sus versiones enmendadas, modificadas, codificadas o vueltas a promulgar, en forma total
      o parcial, y siempre que se encuentre vigente, incluyendo, si corresponde, reglas y normas
      promulgadas en ejecución de la misma; y /iv/ la referencia a un Considerando, Cláusula, Artículo, o
      Numeral se entiende efectuada al Considerando, Cláusula, Artículo, o Numeral del contrato al cual
      se refiera.
    </p>
    <p class="mb-3 underline">
      21.07 Terminación.
    </p>
    <p class="mb-3">
      Estos Términos y Condiciones son efectivos a menos y hasta que usted o nosotros los rescindamos.
      Puede rescindir estos Términos y Condiciones de servicio en cualquier momento notificándonos
      que ya no desea utilizar nuestros Servicios o cuando deje de utilizar nuestro Sitio.
      Si, a nuestro exclusivo criterio, no cumple, o sospechamos que no ha cumplido con cualquier
      término o disposición de estos Términos y Condiciones, también podemos rescindir este acuerdo en
      cualquier momento sin previo aviso y usted seguirá siendo responsable de todos los montos
      adeudados. Hasta e incluyendo la fecha de terminación; y/o, en consecuencia, puede denegarle el
      acceso a nuestros Servicios (o cualquier parte de los mismos).
      Las obligaciones y responsabilidades de las partes incurridas antes de la fecha de terminación
      sobrevivirán a la terminación de este acuerdo para todos los propósitos.
    </p>
    <p class="mb-3">
      Última actualización: 09/04/2024
    </p>
  </div>
</template>
