<template>
  <div class="flex flex-col items-center w-full">
    <div class="flex flex-col items-start py-24 px-5 w-full bg-textura md:items-center md:px-0">
      <p class="p-2 mb-4 text-lg font-semibold text-lk-green md:px-3 md:mb-8 md:text-2xl">
        Sobre Nosotros
      </p>
      <p class="max-w-2xl text-4xl text-center text-lk-green md:text-5xl">
        En
        <span class="font-serif">Lokal</span>
        apoyamos a emprendedores a cumplir sus sueños.
      </p>
    </div>
    <div class="flex flex-col items-center w-full text-gray-700 bg-gray-50">
      <div class="grid grid-cols-1 mb-14 max-w-5xl md:grid-cols-2 md:py-14 md:mb-20">
        <div class="flex flex-col place-self-center px-4">
          <div class="mb-2 w-5 h-5 bg-lk-green rounded-sm md:w-6 md:h-6" />
          <p class="mb-4 text-4xl font-semibold text-lk-green md:mb-5 md:text-5xl">
            Creemos que el futuro <br v-if="!isMobile"> es lo<span class="italic">k</span>al
          </p>
          <p class="text-lg text-gray-700 md:text-xl">
            Hoy más que nunca, los clientes prefieren comprar de marcas locales con valores que los
            representen y definan su indentidad. En Lokal estamos ayudando a emprendedores a encontrar
            esos productos y así, apoyar a las marcas locales a estar más cerca de sus clientes.
          </p>
        </div>
        <img
          src="assets/images/about_us/local-community.jpg"
          class="object-cover shrink-0 row-start-1 place-self-end mb-5 w-full h-60 md:row-auto md:mb-0 md:w-[450px] md:h-[450px] md:rounded-md"
        >
      </div>
      <div class="grid grid-cols-1 mb-14 max-w-5xl md:grid-cols-2 md:py-14 md:mb-20">
        <img
          src="assets/images/about_us/craftwoman.jpg"
          class="object-cover shrink-0 mb-5 w-full h-60 md:mb-0 md:w-[450px] md:h-[450px] md:rounded-md"
        >
        <div class="flex flex-col place-self-center px-4">
          <div class="flex items-center mb-2">
            <div class="mr-2 w-5 h-5 bg-lk-green rounded-sm md:w-6 md:h-6" />
            <div class="w-5 h-5 bg-lk-green rounded-sm md:w-6 md:h-6" />
          </div>
          <p class="mb-4 text-4xl font-semibold text-lk-green md:mb-5 md:text-5xl">
            Apoyamos a una comunidad <br> de emprendedores
          </p>
          <p class="text-lg text-gray-700 md:text-xl">
            Estamos creando una comunidad de emprendedores dedicada a potenciar tiendas físicas y
            marcas locales. A las tiendas físicas, les entregamos facilidades para probar nuevos
            productos sin riesgo y de este modo potenciamos a las marcas a llegar a nuevos
            puntos de ventas a lo largo del país.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { inject } from 'vue';

const isMobile = inject('isMobile');
</script>
