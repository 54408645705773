<template>
  <template v-if="!isMobileOrTablet">
    <a
      class="relative py-2"
      :href="`/products/${item.product.id}`"
    >
      <span
        v-if="item.product.activeDiscounts?.length"
        class="absolute right-0 p-1 text-sm text-white bg-lk-light-purple md:p-0.5 md:text-xs"
      >
        {{ item.product.activeDiscounts[0].name }}
      </span>
      <img
        :src="useImageUrl(item.product, 'jpgSm')"
        class="object-cover w-24 h-24 rounded-sm"
      >
    </a>
    <p class="flex flex-col justify-center text-left md:col-span-2">
      <span>{{ item.product.name }}</span> <br>
      <span
        v-if="item.variant?.id && item.variant?.colorId"
        class="text-xs md:text-sm"
      >
        {{ item.variant?.colorName }} - {{ item.variant?.sizeName }}
      </span>
      <span
        v-if="item.variant?.id && item.variant?.aromaId"
        class="text-xs md:text-sm"
      >
        {{ item.variant?.aromaName }}
      </span>
    </p>
    <p class="">
      <span>{{ useFormatCurrency(item.product.unitaryPriceCents) }}</span>
    </p>
    <p class="flex flex-col">
      <span :class="{ 'line-through': activeDiscount || showVolumePrice }">
        {{ useFormatCurrency(productWholesalePriceWithVolumeDiscount) }}
      </span>
      <span v-if="activeDiscount || showVolumePrice">{{ useFormatCurrency(productWholesalePrice) }}</span>
    </p>
    <product-shopping-button
      v-if="orderInProgress"
      :product="item.product"
      class="justify-center sm:w-1/2 md:w-full"
    />
    <p v-else>
      {{ item.quantity }}
    </p>
    <p class="">
      {{ useFormatCurrency(totalProductPriceForItem(item)) }}
    </p>
    <button
      class="hover:text-red-500"
      type="button"
      @click="showDeleteConfirmationDialog = !showDeleteConfirmationDialog"
    >
      <TrashIcon class="shrink-0 w-5 h-5" />
    </button>
    <div class="col-span-6 w-full border-b border-gray-700 md:col-span-8" />
  </template>
  <div
    v-else
    :product="item.product"
    class="flex first:pt-3 mb-3 w-full border-b border-gray-700"
  >
    <div class="relative">
      <span
        v-if="item.product.activeDiscounts?.length"
        class="absolute left-0 p-0.5 text-xs text-white bg-lk-light-purple"
      >
        {{ item.product.activeDiscounts[0].name }}
      </span>
      <img
        :src="useImageUrl(item.product, 'jpgMd')"
        class="object-cover mr-5 mb-2 w-28 h-28 rounded-sm"
      >
    </div>
    <div class="flex flex-col justify-between mb-2 text-xs">
      <p>{{ item.product.name }}</p>
      <p
        v-if="item.variant?.id"
      >
        {{ item.variant?.colorName }} - {{ item.variant?.sizeName }}
      </p>
      <p>Precio Público: {{ useFormatCurrency(item.product.unitaryPriceCents) }}</p>
      <p class="flex">
        <span class="mr-1">Precio Mayorista:</span>
        <span :class="{ 'line-through mr-1': activeDiscount || showVolumePrice }">
          {{ useFormatCurrency(productWholesalePriceWithVolumeDiscount) }}
        </span>
        <span v-if="activeDiscount || showVolumePrice">{{ useFormatCurrency(productWholesalePrice) }}</span>
      </p>
      <p>Cantidad: {{ item.quantity }}</p>
      <product-shopping-button
        v-if="orderInProgress"
        :product="item.product"
        class="justify-center w-full"
      />
      <p>Suma: {{ useFormatCurrency(totalProductPriceForItem(item)) }}</p>
      <button
        class="flex items-center text-xs hover:text-red-500"
        type="button"
        @click="showDeleteConfirmationDialog = !showDeleteConfirmationDialog"
      >
        <TrashIcon class="shrink-0 mr-2 w-4 h-4" />
        <span>Eliminar</span>
      </button>
    </div>
  </div>
  <Field
    v-if="validProductDestination !== undefined"
    v-model="validProductDestination"
    hidden
    :name="`${item.product.name}-validProductDestination`"
    :rules="{ isValidDestination: item.product.name }"
  />
  <lokal-confirmation-dialog
    v-model="showDeleteConfirmationDialog"
    :title="`¿Estás seguro de eliminar ${item.product.name} del carrito?`"
    confirm-action-text="Eliminar"
    reject-action-text="Cancelar"
    event-name="delete-product-from-cart"
    :positive-action="false"
    @delete-product-from-cart="() => removeProductFromCart()"
  />
</template>
<script setup lang="ts">
import { ref, computed, inject } from 'vue';
import { Field } from 'vee-validate';
import { TrashIcon } from '@heroicons/vue/24/outline';
import useSessionStore from 'stores/user-store';
import useFormatCurrency from './use/format-currency';
import useActiveProductDiscounts from './use/active-product-discounts';
import useProductWholesalePriceMethods from './use/product-wholesale-price';
import useTotalItemPriceMethods from './use/total-item-price-methods';
import useImageUrl from './use/image-url';
import ProductShoppingButton from './product-shopping-button.vue';
import useValidDestinationQuery from './queries/valid-destination-query';
import useCartManager from './use/cart-manager';
import LokalConfirmationDialog from './shared/lokal-confirmation-dialog.vue';

interface Props {
  item: Item,
  orderInProgress?: boolean,
}

const props = withDefaults(defineProps<Props>(), {
  orderInProgress: true,
});

const sessionStore = useSessionStore();
const currentCommuneId = computed(() => sessionStore.currentAddress?.communeId);
const isMobileOrTablet = inject('isMobileOrTablet');
const { activeDiscount } = useActiveProductDiscounts(props.item.product);
const computedQuantity = computed(() => props.item.quantity);
const {
  productWholesalePrice, productWholesalePriceWithVolumeDiscount,
} = useProductWholesalePriceMethods(props.item.product, computedQuantity);
const { totalProductPriceForItem } = useTotalItemPriceMethods();
const showVolumePrice = computed(() => props.item.product.wholesalePriceCents !== props.item.wholesalePriceCents);
const { removeProductFromCart } = useCartManager(computed(() => props.item.product));

const showDeleteConfirmationDialog = ref(false);

const { validDestination: validProductDestination } = useValidDestinationQuery(
  computed(() => props.item.product.id), computed(() => 'Product'), currentCommuneId,
);
</script>
