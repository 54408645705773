<template>
  <a
    :href="`/orders/${order.id}`"
    class="flex flex-col p-3 hover:bg-gray-50 rounded-md border md:px-4"
    :class="{ 'border-red-500': lateOnPayment }"
  >
    <div class="flex flex-col justify-between items-start mb-2 space-y-1 text-gray-700">
      <div class="flex flex-wrap items-center">
        <span
          v-if="order.status === 'pending'"
          class="py-1 px-2 mr-2 mb-1 text-xs text-center text-lk-green rounded-full border border-lk-green transition-opacity duration-300 ease-in-out md:mr-3 md:mb-0 md:text-sm"
        >
          {{ t(`order.status.${camelCase(order.status)}`) }}
        </span>
        <lokal-tooltip
          v-if="['paid'].includes(order.paymentStatus)"
          position-options="left"
          arrow-classes="left-1.5"
          class="mr-1 sm:mr-2"
          information-classes="w-64 md:w-80 text-left"
          information="Esta orden ya ha sido pagado."
        >
          <template #custom-content>
            <inline-svg-wrapper
              name="outline-checked-document"
              class="shrink-0 w-4 h-4 text-lk-green fill-white stroke-2 md:w-5 md:h-5"
            />
          </template>
        </lokal-tooltip>
        <lokal-tooltip
          v-else-if="['billed', 'partially_paid'].includes(order.paymentStatus)"
          position-options="left"
          class="mr-1 sm:mr-2"
          arrow-classes="left-1.5"
          information-classes="w-64 md:w-80 text-left"
          information="La factura ya está emitida."
        >
          <template #custom-content>
            <DocumentTextIcon
              class="shrink-0 w-4 h-4 md:w-5 md:h-5"
            />
          </template>
        </lokal-tooltip>
        <lokal-tooltip
          v-if="lateOnPayment"
          class="mr-1 sm:mr-2"
          position-options="left"
          arrow-classes="left-1.5"
          information-classes="w-64 md:w-80 text-left"
          :information="`Esta orden debió ser pagada el ${dateTools.timeToDate(order.paymentDate)}.`"
        >
          <template #custom-content>
            <ExclamationCircleIcon
              class="shrink-0 w-4 h-4 text-red-500 md:w-5 md:h-5"
            />
          </template>
        </lokal-tooltip>
        <lokal-tooltip
          v-if="order.returnStatus !== 'no_return'"
          position-options="left"
          arrow-classes="left-1.5"
          information-classes="w-64 md:w-80 text-left"
          :information="`Esta orden debió ser pagada el ${dateTools.timeToDate(order.paymentDate)}.`"
        >
          <template #custom-content>
            <inline-svg-wrapper
              name="arrow-path"
              class="shrink-0 mr-2 w-4 h-4 text-lk-green md:mr-3 md:w-5 md:h-5"
            />
          </template>
        </lokal-tooltip>
      </div>
      <div class="flex items-center w-full">
        <a
          :href="`/orders/${order.id}`"
          class="shrink-0 self-start text-base md:text-lg md:hover:underline"
        >
          Orden #{{ order.id }}
        </a>
        <span class="mx-1 sm:block md:mx-2">·</span>
        <p class="text-sm md:ml-0 md:text-base">{{ useTimeToDate(order.createdAt) }}</p>
      </div>
    </div>
    <p
      class="flex items-center mb-1 text-sm font-light md:text-base"
      :class="{ 'justify-between': !withDiscounts }"
    >
      <span v-if="orderPartiallyPaid">Monto total de la orden:</span>
      <span v-else>Monto {{ orderPaid ? 'pagado' : 'por pagar' }}:</span>
      <span :class="{ 'line-through ml-auto mr-2': withDiscounts }">
        {{ useFormatCurrency(totalOriginalAmountWithShipping) }}
      </span>
      <span v-if="withDiscounts">
        {{ useFormatCurrency(totalOrderAmountWithShipping) }}
      </span>
    </p>
    <p
      v-if="orderPartiallyPaid"
      class="flex justify-between items-center mb-1 text-sm font-light md:text-base"
    >
      <span>Monto pagado:</span>
      <lokal-loading
        v-if="orderPaymentsLoading"
        :loading="orderPaymentsLoading"
        size="xs"
      />
      <span v-else>
        {{ useFormatCurrency(totalOrderPayment) }}
      </span>
    </p>
    <p
      v-if="orderPartiallyPaid"
      class="flex justify-between items-center mb-1 text-sm font-light md:text-base"
    >
      <span>Monto por pagar:</span>
      <lokal-loading
        v-if="orderPaymentsLoading"
        :loading="orderPaymentsLoading"
        size="xs"
      />
      <span v-else>
        {{ useFormatCurrency(totalOrderAmountWithShipping - totalOrderPayment) }}
      </span>
    </p>
    <p
      class="flex justify-between items-center mb-1 text-sm font-light md:text-base"
    >
      <span>Fecha de pago:</span>
      <span
        v-if="order.paymentDate"
        :class="{ 'text-red-500': lateOnPayment }"
      >
        {{ dateTools.timeToDate(order.paymentDate) }}
      </span>
      <span v-else>pendiente</span>
    </p>
    <div class="flex justify-between items-center mb-2 font-light">
      <a
        :href="order.invoiceUrl"
        class="text-sm underline md:text-base"
        :class="{ 'cursor-not-allowed': !order.invoiceUrl }"
        target="_blank"
        @click.stop
      >
        Factura
      </a>
      <a
        :href="`/orders/${order.id}`"
        class="ml-auto text-sm underline md:text-base"
        @click.stop
      >
        Ver Detalle
      </a>
    </div>
    <div class="flex flex-wrap items-center mb-1 text-gray-700">
      <a
        v-for="subOrder in order.subOrders"
        :key="subOrder.id"
        class="py-1 px-2 mr-3 mb-1 text-xs text-center bg-gray-200 rounded-full md:text-sm md:hover:underline"
        :href="`/makers/${subOrder.makerId}`"
      >
        {{ subOrder.makerName }}
      </a>
    </div>
  </a>
</template>
<script lang="ts" setup>
import { inject, computed } from 'vue';
import { ExclamationCircleIcon, DocumentTextIcon } from '@heroicons/vue/24/outline';
import { camelCase, round, sum, values } from 'lodash';
import { useI18n } from 'vue-i18n';
import { useQuery } from 'vue-query';
import orderPaymentsApi from 'api/order-payments';
import { billToolsKey, type BillTools } from '../symbols';
import useFormatCurrency from './use/format-currency';
import useTimeToDate from './use/time-to-date';
import LokalTooltip from './shared/lokal-tooltip.vue';
import useDateTools from './use/date-tools';
import inlineSvgWrapper from './inline-svg-wrapper.vue';

interface OrderListCardProps {
  order: Order;
  lastOrder: boolean;
}

const props = withDefaults(defineProps<OrderListCardProps>(), {
  lastOrder: false,
});

const { t } = useI18n();

const dateTools = useDateTools();

const billTools = inject(billToolsKey) as BillTools;

const orderPaid = computed(() => props.order.paymentStatus === 'paid');
const orderPartiallyPaid = computed(() => props.order.paymentStatus === 'partially_paid');

const lateOnPayment = computed(() =>
  (props.order.paymentDate && (new Date(props.order.paymentDate) < new Date()) && !(orderPaid.value)));

const queryParams = computed(() => `q[order_id_eq]=${props.order.id}]`);
const orderPaymentsQuery = useQuery(
  ['orderPayments', props.order.shopId, queryParams],
  () => orderPaymentsApi.index(props.order.shopId, 1, queryParams.value),
  { enabled: orderPartiallyPaid, keepPreviousData: true },
);
const orderPaymentsLoading = computed(() => orderPaymentsQuery.isLoading.value);
const orderPayments = computed(() => orderPaymentsQuery.data.value?.data.orderPayments as OrderPayment[]);
const totalOrderPayment = computed(() =>
  orderPayments.value?.reduce((acc, orderPayment) => acc + orderPayment.amountCents, 0));
const totalOrderAmount = computed(() => {
  const internalTotal = props.order.totalWholesaleAmountCents - props.order.totalDiscountAmount -
    props.order.totalVolumeDiscountAmountCents - props.order.totalDiscountCodeAmount;

  return internalTotal;
});
const additionalTaxes = computed(() => sum(values(props.order.groupedAdditionalTaxAmounts)));
const totalNetAmountWithShippingCost = computed(() => totalOrderAmount.value + props.order.shippingCostAmountCents);
const ivaAmount = computed(() => round(totalNetAmountWithShippingCost.value * billTools.iva));
const totalOrderAmountWithShipping = computed(() =>
  totalNetAmountWithShippingCost.value + ivaAmount.value + additionalTaxes.value,
);
const totalOriginalAmountWithShipping = computed(() => {
  const originalWholesaleWithShipping = props.order.totalWholesaleAmountCents + props.order.shippingCostAmountCents;
  const originalIvaAmount = round(originalWholesaleWithShipping * billTools.iva);

  // additionalTaxes is not the original tax amount  but its simply to sum an approximation
  return originalWholesaleWithShipping + originalIvaAmount + additionalTaxes.value;
},
);
const withDiscounts = computed(() =>
  props.order.totalDiscountAmount > 0 || props.order.totalVolumeDiscountAmountCents > 0 ||
    props.order.totalDiscountCodeAmount > 0);
</script>
