import { computed } from 'vue';
import { round } from 'lodash';

const TOTAL_PORCENTAGE = 100;

export default function useActiveProductDiscounts(product: Product | SubOrderProduct) {
  const activeDiscount = computed(() => !!product.activeDiscounts?.length);
  // deprecated use discountAmount of useProductWholesalePrice
  const currentDiscountAmount = computed(() =>
    (product.activeDiscounts?.length ? product.activeDiscounts[0].totalDiscount : 0));
  // deprecated use productWholesalePrice of useProductWholesalePrice
  const wholesalePriceCents = computed(() =>
    round(product.wholesalePriceCents * (1 - (currentDiscountAmount.value / TOTAL_PORCENTAGE))),
  );
  const discountAmountCents = computed(() =>
    product.wholesalePriceCents - wholesalePriceCents.value);

  return { activeDiscount, currentDiscountAmount, discountAmountCents, wholesalePriceCents };
}
