<template>
  <span
    v-if="withTitles"
    class="w-full text-xs md:text-sm"
  >
    Nombre de empresa:
  </span>
  <p class="flex justify-between items-center mb-1 w-full text-xs rounded-md border md:text-sm">
    <span class="flex-1 p-2">{{ companyName }}</span>
    <button
      class="border-l"
      @click="copyToClipBoard(companyName, 'companyName')"
    >
      <ClipboardDocumentCheckIcon
        v-if="copiedStatus['companyName']"
        class="m-2 w-4 text-lk-green md:w-5"
      />
      <ClipboardDocumentIcon
        v-else
        class="m-2 w-4 md:w-5"
      />
    </button>
  </p>
  <span
    v-if="withTitles"
    class="w-full text-sm"
  >
    Rut:
  </span>
  <p class="flex justify-between items-center mb-1 w-full text-xs rounded-md border md:text-sm">
    <span class="flex-1 p-2">{{ lokalRut }}</span>
    <button
      class="border-l"
      @click="copyToClipBoard(lokalRut, 'lokalRut')"
    >
      <ClipboardDocumentCheckIcon
        v-if="copiedStatus['lokalRut']"
        class="m-2 w-4 text-lk-green md:w-5"
      />
      <ClipboardDocumentIcon
        v-else
        class="m-2 w-4 md:w-5"
      />
    </button>
  </p>
  <span
    v-if="withTitles"
    class="w-full text-sm"
  >
    Giro:
  </span>
  <p class="flex justify-between items-center mb-1 w-full text-xs rounded-md border md:text-sm">
    <span class="flex-1 p-2">{{ lokalLineOfBusiness }}</span>
    <button
      class="border-l"
      @click="copyToClipBoard(lokalLineOfBusiness, 'lokalLineOfBusiness')"
    >
      <ClipboardDocumentCheckIcon
        v-if="copiedStatus['lokalLineOfBusiness']"
        class="m-2 w-4 text-lk-green md:w-5"
      />
      <ClipboardDocumentIcon
        v-else
        class="m-2 w-4 md:w-5"
      />
    </button>
  </p>
  <span
    v-if="withTitles"
    class="w-full text-sm"
  >
    Banco:
  </span>
  <p class="flex justify-between items-center mb-1 w-full text-xs rounded-md border md:text-sm">
    <span class="flex-1 p-2">{{ bankName }}</span>
    <button
      class="border-l"
      @click="copyToClipBoard(bankName, 'bankName')"
    >
      <ClipboardDocumentCheckIcon
        v-if="copiedStatus['bankName']"
        class="m-2 w-4 text-lk-green md:w-5"
      />
      <ClipboardDocumentIcon
        v-else
        class="m-2 w-4 md:w-5"
      />
    </button>
  </p>
  <span
    v-if="withTitles"
    class="w-full text-sm"
  >
    Cuenta Corriente:
  </span>
  <p class="flex justify-between items-center mb-1 w-full text-xs rounded-md border md:text-sm">
    <span class="flex-1 p-2">{{ lokalAccountNumber }}</span>
    <button
      class="border-l"
      @click="copyToClipBoard(lokalAccountNumber, 'lokalAccountNumber')"
    >
      <ClipboardDocumentCheckIcon
        v-if="copiedStatus['lokalAccountNumber']"
        class="m-2 w-4 text-lk-green md:w-5"
      />
      <ClipboardDocumentIcon
        v-else
        class="m-2 w-4 md:w-5"
      />
    </button>
  </p>
  <span
    v-if="withTitles"
    class="w-full text-sm"
  >
    Correo:
  </span>
  <p class="flex justify-between items-center mb-1 w-full text-xs rounded-md border md:text-sm">
    <span class="flex-1 p-2">{{ infoEmail }}</span>
    <button
      class="border-l"
      @click="copyToClipBoard(infoEmail, 'infoEmail')"
    >
      <ClipboardDocumentCheckIcon
        v-if="copiedStatus['infoEmail']"
        class="m-2 w-4 text-lk-green md:w-5"
      />
      <ClipboardDocumentIcon
        v-else
        class="m-2 w-4 md:w-5"
      />
    </button>
  </p>
  <span
    v-if="withTitles"
    class="w-full text-sm"
  >
    Dirección:
  </span>
  <p class="flex justify-between items-center mb-1 w-full text-xs rounded-md border md:text-sm">
    <span class="flex-1 p-2">{{ address }}</span>
    <button
      class="border-l"
      @click="copyToClipBoard(address, 'address')"
    >
      <ClipboardDocumentCheckIcon
        v-if="copiedStatus['address']"
        class="m-2 w-4 text-lk-green md:w-5"
      />
      <ClipboardDocumentIcon
        v-else
        class="m-2 w-4 md:w-5"
      />
    </button>
  </p>
</template>
<script lang="ts" setup>
import { ClipboardDocumentIcon, ClipboardDocumentCheckIcon } from '@heroicons/vue/24/outline';
import { computed, ref } from 'vue';

withDefaults(defineProps<{
  withTitles?: boolean
}>(), {
  withTitles: true,
});

type CopiedStatusKeys = {
  companyName: boolean
  lokalRut: boolean
  bankName: boolean
  lokalAccountNumber: boolean
  infoEmail: boolean
  lokalLineOfBusiness: boolean
  address: boolean
}
const copiedStatus = ref({
  companyName: false, lokalRut: false, bankName: false, lokalAccountNumber: false, infoEmail: false,
  address: false,
} as CopiedStatusKeys);
const companyName = computed(() => 'Lokal Spa');
const lokalRut = computed(() => process.env.LOKAL_RUT || '');
const lokalLineOfBusiness = computed(() => process.env.LOKAL_LINE_OF_BUSINESS || '');
const bankName = computed(() => 'Banco Bice');
const lokalAccountNumber = computed(() => process.env.LOKAL_ACCOUNT_NUMBER || '');
const infoEmail = computed(() => process.env.INFO_EMAIL || '');
const address = computed(() => process.env.ADDRESS || '');

function copyToClipBoard(value: string, statusKey: keyof CopiedStatusKeys) {
  navigator.clipboard.writeText(value).then(() => {
    copiedStatus.value[statusKey] = true;
  });
}
</script>
