<template>
  <Dialog
    :open="modelValue"
    as="div"
    class="relative z-50"
    @close="emit('update:modelValue', false)"
  >
    <div
      class="fixed inset-0 bg-black/30"
      aria-hidden="true"
    />
    <div class="flex overflow-y-auto fixed inset-0 justify-center items-center p-2 text-gray-700">
      <DialogPanel class="flex overflow-y-auto flex-col p-3 w-full max-w-[30rem] bg-gray-50 rounded md:p-5">
        <button
          class="mb-3 ml-auto focus:outline-none"
          @click="emit('update:modelValue', false)"
        >
          <XMarkIcon class="w-5 h-5 md:w-6 md:h-6" />
        </button>
        <div class="flex overflow-y-auto flex-col w-full h-full max-h-[25rem] md:max-h-[30rem]">
          <DialogTitle class="mb-4 font-serif text-xl text-center md:mb-5 md:text-2xl">
            ¡Accede a 50% de descuento en el despacho de una Marca! 🚚
          </DialogTitle>
          <DialogDescription
            class="flex flex-col mb-3 h-full text-sm text-gray-700 md:text-base"
          >
            Lokal funciona con dropshipping por lo que cada marca envía los productos desde su bodega.
            Sin embargo, ¡te queremos ayudar!. Si compraas 2 o más marcas y cumples con el mínimo
            por marca de cada región podrás acceder a un 50% de descuento en ese despacho.
          </DialogDescription>
          <lokal-loading
            v-if="regionsQuery.isLoading.value"
            :loading="regionsQuery.isLoading.value"
          />
          <table
            v-else
            class="mb-3 text-sm md:text-base"
          >
            <thead class="border-2 border-b-0">
              <tr class="font-bold text-left">
                <th class="py-1 px-2">
                  Región
                </th>
                <th class="py-1 px-2">
                  Mínimo Subsidiado*
                </th>
              </tr>
            </thead>
            <tbody class="border left">
              <tr
                v-for="region in regions"
                :key="`region-${region.id}`"
                class="border-2"
              >
                <td class="py-1 px-2">
                  {{ region.name }}
                </td>
                <td class="py-1 px-2">
                  {{ useFormatCurrency(region.subsidizedMinimum) }}
                </td>
              </tr>
            </tbody>
          </table>
          <p class="mb-3 text-sm font-semibold md:text-base">
            *El mínimo subsidiado es el monto mínimo que debes comprar por marca para acceder al descuento.
          </p>
        </div>
        <lokal-button
          class="mt-auto"
          action="secondary"
          action-name="Aceptar"
          @click="emit('update:modelValue', false)"
        />
      </DialogPanel>
    </div>
  </Dialog>
</template>

<script setup lang="ts">
import { XMarkIcon } from '@heroicons/vue/24/solid';
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  DialogDescription,
} from '@headlessui/vue';
import useRegionsQuery from 'components/queries/regions-query';
import useFormatCurrency from 'components/use/format-currency';

interface LokalConfirmationDialogProp {
  modelValue: boolean,
}
withDefaults(defineProps<LokalConfirmationDialogProp>(), {
  modelValue: false,
});

const { regions, regionsQuery } = useRegionsQuery();

const emit = defineEmits<{(e: 'update:modelValue', value: boolean): void}>();
</script>
