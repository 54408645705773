<template>
  <div class="flex flex-col w-full">
    <lokal-button
      size="normal"
      class="ml-auto"
      action="secondary"
      type="button"
      @click="dashboardModalOpen = !dashboardModalOpen"
    >
      <template #icon>
        <p class="flex items-center">
          <PlusIcon class="shrink-0 mr-2 w-4 h-4" />
          <span>Agregar nuevas imágenes</span>
        </p>
      </template>
    </lokal-button>
    <dashboard-modal
      class="z-10 mb-5"
      :uppy="uppy"
      :open="dashboardModalOpen"
      :props="{
        theme: 'light',
        hideUploadButton: true,
        closeModalOnClickOutside: true,
        waitForThumbnailsBeforeUpload: true,
        doneButtonHandler: () => { dashboardModalOpen = false },
      }"
    />
    <div
      class="grid grid-cols-4 gap-y-2 place-items-center p-2 text-sm rounded-md border border-gray-300 md:grid-cols-5 md:text-base"
    >
      <p class=" p-2">
        Imágenes
      </p>
      <p class="place-self-start p-2 pl-2 md:col-span-2">
        Nombre - Tamaño
      </p>
      <lokal-popover-information
        main-label="Prioridad"
        class="z-0"
        :information="priorityInformation"
      />
      <p class="place-self-center p-2">
        Acciones
      </p>
      <div class="col-span-4 w-full border border-b border-gray-400 md:col-span-5" />
      <template
        v-for="image in currentImagesSorted"
        :key="image.id"
      >
        <image-uploader-current-image
          :image="image"
          @update-images="emit('update-images')"
        />
      </template>
      <template
        v-for="file in files"
        :key="file.id"
      >
        <image-uploader-new-image
          :uppy="uppy"
          :file="file"
          @update-priority="(value: number) => updatePriority(file, value)"
        />
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Uppy, { type UppyFile } from '@uppy/core';
import { DashboardModal } from '@uppy/vue';
import { PlusIcon } from '@heroicons/vue/24/outline';
import XHRUpload from '@uppy/xhr-upload';
import ThumbnailGenerator from '@uppy/thumbnail-generator';
import { ref, computed, onBeforeUnmount } from 'vue';
import { sortBy } from 'lodash';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import lokalPopoverInformation from './lokal-popover-information.vue';
import imageUploaderCurrentImage from './image-uploader-current-image.vue';
import imageUploaderNewImage from './image-uploader-new-image.vue';

const props = defineProps<{
  currentImages: Image[],
}>();

const emit = defineEmits<{(e: 'update-files', value: { priority: number, imageData: ImageData }[]): void,
  (e: 'update-images'): void
}>();

const dashboardModalOpen = ref(false);
const uppy = computed(() =>
  new Uppy({ restrictions: { maxFileSize: 5000000 }, autoProceed: true })
    .use(ThumbnailGenerator, { thumbnailWidth: 200 })
    .use(XHRUpload, { endpoint: '/upload' }),
);

const files = ref({} as { [key: string]: UppyFile});
const filesPriority = ref({} as { [key: string]: number});
const filesImageData = ref({} as { [key: string]: ImageData});
const fileDatas = computed(() => Object.keys(files.value).map((key) => ({
  priority: filesPriority.value[key],
  imageData: filesImageData.value[key],
})));

function updatePriority(file: UppyFile, value: number) {
  filesPriority.value[file.id] = value;
  emit('update-files', fileDatas.value);
}

onBeforeUnmount(() => {
  uppy.value.close({ reason: 'unmount' });
});

uppy.value.on('file-added', (file: UppyFile) => {
  files.value[file.id] = file;
  filesPriority.value[file.id] = 1;
});

uppy.value.on('thumbnail:generated', (file: UppyFile) => {
  files.value[file.id] = file;
});

uppy.value.on('file-removed', (file: UppyFile) => {
  delete files.value[file.id];
});

uppy.value.on('upload-success', (file, response) => {
  const uploadedFileData = response.body.data;
  const uppyFile = file as UppyFile;
  filesImageData.value[uppyFile.id] = uploadedFileData;
  emit('update-files', fileDatas.value);
});

const currentImagesSorted = computed(() => sortBy(props.currentImages, 'priority'));

const priorityInformation = `
  La prioridad es el orden en el que se muestran las imágenes, siendo 1 la primera, 2 la segunda,
  3 la tercera y así sucesivamente.
`;
</script>
<style lang="scss">
.uppy-Dashboard--modal .uppy-Dashboard-inner {
  @media screen and (max-width: 768px)  {
    top: 135px;
    bottom: 100px;
  }
}
</style>
