import { computed, type ComputedRef } from 'vue';
import { findLast } from 'lodash';

export default function useMakerSubsidizedShippingsHelper(
  orderedSubsidizedShippings: ComputedRef<SubsidizedShipping[]>, priceWithDiscounts: ComputedRef<number>,
) {
  const filteredOrderedSubsidizedShippings = computed(() => orderedSubsidizedShippings.value.filter(
    (subsidizedShipping) => subsidizedShipping.minimumAmountCents <= priceWithDiscounts.value,
  ));
  const hasSubsidizedShipping = computed(() => filteredOrderedSubsidizedShippings.value?.length &&
  filteredOrderedSubsidizedShippings.value.length > 0);
  const currentSubsidizedShipping = computed(() => {
    if (!filteredOrderedSubsidizedShippings.value || filteredOrderedSubsidizedShippings.value.length === 0) return null;

    if (filteredOrderedSubsidizedShippings.value.length === 1) return filteredOrderedSubsidizedShippings.value[0];

    return findLast(
      filteredOrderedSubsidizedShippings.value,
      (subsidizedShipping) => subsidizedShipping.minimumAmountCents <= priceWithDiscounts.value,
    );
  });
  const nextSubsidizedShipping = computed(() => {
    if (!orderedSubsidizedShippings.value || orderedSubsidizedShippings.value.length === 0) return null;

    if (currentSubsidizedShipping.value?.id) {
      return orderedSubsidizedShippings.value.find(
        (subsidizedShipping) => subsidizedShipping.minimumAmountCents >= priceWithDiscounts.value &&
          subsidizedShipping.id !== currentSubsidizedShipping.value?.id,
      );
    }

    return orderedSubsidizedShippings.value[0];
  });
  const missingAmountForSubsidizedShipping = computed(() => {
    if (!nextSubsidizedShipping.value?.id) return 0;

    return nextSubsidizedShipping.value.minimumAmountCents - priceWithDiscounts.value;
  });

  return {
    filteredOrderedSubsidizedShippings,
    hasSubsidizedShipping,
    currentSubsidizedShipping,
    nextSubsidizedShipping,
    missingAmountForSubsidizedShipping,
  };
}
