<template>
  <Dialog
    :open="modelValue"
    as="div"
    class="relative z-50"
    @close="emit('update:modelValue', false)"
  >
    <div
      class="fixed inset-0 bg-black/30"
      aria-hidden="true"
    />
    <div class="flex fixed inset-0 justify-center items-center">
      <DialogPanel class="flex overflow-y-auto flex-col p-2 w-full max-w-md bg-gray-50 rounded md:p-4">
        <button
          class="mb-2 ml-auto focus:outline-none"
          @click="emit('update:modelValue', false)"
        >
          <XMarkIcon class="w-6 h-6 fill-gray-700" />
        </button>
        <DialogTitle
          class="mb-3 font-serif text-xl text-center text-gray-700 md:text-3xl"
        >
          {{ props.collection?.id ? 'Editar' : 'Crear una' }} colección
        </DialogTitle>
        <DialogDescription
          class="mb-5 text-sm text-gray-700 md:text-base"
        >
          <form @submit="onSubmit">
            <p class="mb-1 text-sm md:text-base">
              Nombre
            </p>
            <form-input
              v-model="collectionName"
              name="collectionName"
              label-name="Nombre"
              :rules="{ required: true }"
              class="mb-3 font-light"
            />
            <p class="mb-1 text-sm md:text-base">
              Prioridad
            </p>
            <form-input
              v-model.number="makerPriority"
              type="number"
              name="makerPriority"
              label-name="Prioridad"
              :rules="{ required: true, min_value: 1 }"
              class=" font-light"
            />
            <p class="mb-3 text-xs font-light md:text-sm">
              (a menor valor, mayor prioridad)
            </p>
            <lokal-loading
              v-if="isLoading"
              :loading="isLoading"
              class="self-center"
            />
            <button
              v-else
              class="p-2 w-full text-sm font-normal text-lk-green hover:text-white bg-white hover:bg-lk-green rounded-md border border-lk-green md:text-base"
            >
              {{ props.collection?.id ? 'Guardar' : 'Crear' }}
            </button>
          </form>
        </DialogDescription>
      </DialogPanel>
    </div>
  </dialog>
</template>
<script lang="ts" setup>
import { XMarkIcon } from '@heroicons/vue/24/solid';
import { useForm } from 'vee-validate';
import { ref, computed } from 'vue';
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  DialogDescription,
} from '@headlessui/vue';
import { useMutation } from 'vue-query';
import collectionsApi from 'api/collections';
import useMessageStore from 'stores/message-store';
import FormInput from './shared/form-input.vue';

interface LokalMessageDialogProps {
  modelValue: boolean
  makerId: number
  collection?: Collection
}
const props = withDefaults(defineProps<LokalMessageDialogProps>(), {
  modelValue: false,
  collection: undefined,
});

const messageStore = useMessageStore();

const collectionName = ref(props.collection?.name || '');
const makerPriority = ref(props.collection?.makerPriority);

const emit = defineEmits<{(e: 'reload-collections'): void,
  (e: 'update:modelValue', value: boolean): void}>();

const createCollectionMutation = useMutation(
  () => collectionsApi.create({ name: collectionName.value, makerId: props.makerId }),
  {
    onSuccess: (data) => {
      messageStore.showMessage(`Se ha creado una nueva colección: ${data.data.collection.name}.`, 'success');
      emit('reload-collections');
      emit('update:modelValue', false);
    },
  },
);

const updateCollectionMutation = useMutation(
  () => collectionsApi.update(props.collection?.id, { name: collectionName.value, makerPriority: makerPriority.value }),
  {
    onSuccess: (data) => {
      messageStore.showMessage(`La colección ${data.data.collection.name} ha sido actualizada.`, 'success');
      emit('reload-collections');
      emit('update:modelValue', false);
    },
  },
);

const isLoading = computed(() => createCollectionMutation.isLoading.value || updateCollectionMutation.isLoading.value);
const { handleSubmit } = useForm();
const onSubmit = handleSubmit(() => {
  if (props.collection?.id) {
    updateCollectionMutation.mutate();
  } else {
    createCollectionMutation.mutate();
  }
});
</script>
