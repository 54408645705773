<template>
  <Dialog
    :open="modelValue"
    as="div"
    class="relative z-50"
    @close="emit('update:modelValue', false)"
  >
    <div
      class="fixed inset-0 bg-black/30"
      aria-hidden="true"
    />
    <div class="flex fixed inset-0 justify-center items-center">
      <DialogPanel class="flex overflow-y-auto flex-col p-2 w-full max-w-md bg-gray-50 rounded md:p-4">
        <button
          class="mb-2 ml-auto focus:outline-none"
          @click="emit('update:modelValue', false)"
        >
          <XMarkIcon class="w-6 h-6 fill-gray-700" />
        </button>
        <DialogTitle class="mb-3 font-serif text-xl text-center text-gray-700 md:text-3xl">
          {{ title }}
        </DialogTitle>
        <DialogDescription
          class="mb-5 text-sm font-light text-center text-gray-700 md:text-base"
        >
          {{ message }}
        </DialogDescription>
        <lokal-button
          action-name="Aceptar"
          class="self-center w-3/4"
          @click="emitEvents"
        />
      </DialogPanel>
    </div>
  </dialog>
</template>
<script lang="ts" setup>
import { XMarkIcon } from '@heroicons/vue/24/solid';
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  DialogDescription,
} from '@headlessui/vue';

interface LokalMessageDialogProps {
  modelValue: boolean
  message: string
  title: string
}
withDefaults(defineProps<LokalMessageDialogProps>(), {
  modelValue: false,
  message: '',
  title: '',
});

const emit = defineEmits<{(e: 'accept-message'): void,
  (e: 'update:modelValue', value: boolean): void
}>();

function emitEvents() {
  emit('accept-message');
  emit('update:modelValue', false);
}
</script>
