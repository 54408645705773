<template>
  <Field
    v-slot="{ field }"
    :model-value="modelValue"
    :name="name"
    as="div"
    class="flex items-center font-light text-gray-700 rounded-md border border-gray-300"
    :class="classes"
    @update:model-value="($event) => emit('update:modelValue', $event)"
  >
    <MagnifyingGlassIcon
      class="shrink-0 text-gray-300"
      :class="searchIconClasses"
    />
    <input
      :placeholder="placeholder"
      v-bind="field"
      class="w-full placeholder:font-normal placeholder:text-gray-300 focus:outline-none"
    >
    <XMarkIcon
      v-if="modelValue"
      class="shrink-0 text-gray-300 cursor-pointer"
      :class="searchIconClasses"
      @click="() => emit('update:modelValue', '')"
    />
  </Field>
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import { Field } from 'vee-validate';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/vue/24/outline';

interface SearchBarProps {
  modelValue: string;
  name: string;
  placeholder?: string;
  size?: 'sm' | 'md';
}

const props = withDefaults(defineProps<SearchBarProps>(), {
  placeholder: '',
  size: 'md',
});

const emit = defineEmits<{(e: 'update:modelValue', value: string): void}>();

const classes = computed(() => {
  if (props.size === 'sm') {
    return 'text-sm p-1';
  }

  return 'text-sm md:text-base p-2';
});

const searchIconClasses = computed(() => {
  if (props.size === 'sm') {
    return 'w-3 h-3 md:w-4 md:h-4 mr-1';
  }

  return 'w-4 h-4 md:w-5 md:h-5 mr-2';
});
</script>
