import { provide, reactive } from 'vue';
import { billToolsKey } from '../../symbols/';

const IVA = 0.19;
const IVA_VALUE = 1 + IVA;
const TOTAL_PORCENTAGE = 100;
const ILA_LIQOR_AND_DESTILLATES = 0.315;
const ILA_LIQOR_AND_DESTILLATES_PERCENTAGE = ILA_LIQOR_AND_DESTILLATES * TOTAL_PORCENTAGE;
const ILA_WINES_AND_OTHERS = 0.205;
const ILA_WINES_AND_OTHERS_PERCENTAGE = ILA_WINES_AND_OTHERS * TOTAL_PORCENTAGE;

export default function useBillTools() {
  const billTools = reactive({
    iva: IVA,
    ivaValue: IVA_VALUE,
    totalPorcentage: TOTAL_PORCENTAGE,
    ilaLiqorAndDestilles: ILA_LIQOR_AND_DESTILLATES,
    ilaLiqorAndDestillatesPercentage: ILA_LIQOR_AND_DESTILLATES_PERCENTAGE,
    ilaWinesAndOthers: ILA_WINES_AND_OTHERS,
    ilaWinesAndOthersPercentage: ILA_WINES_AND_OTHERS_PERCENTAGE,
  });

  provide(billToolsKey, billTools);
}
