<template>
  <div
    v-if="menuStore.showCartSidebar"
    class="overflow-y-hidden fixed inset-0 z-30 bg-gray-800/50"
    aria-hidden="true"
    @click="menuStore.showCartSidebar = false"
  />
  <transition
    enter-active-class="transition-transform duration-300"
    enter-from-class="translate-x-full"
    enter-to-class="translate-x-0"
    leave-active-class="transition-transform duration-300"
    leave-from-class="translate-x-0"
    leave-to-class="translate-x-full"
  >
    <aside
      v-if="menuStore.showCartSidebar"
      id="cart-sidebar"
      class="flex fixed right-0 bottom-0 z-30 flex-col w-4/5 bg-white md:w-72"
      :style="{ top: menuStore.headerHeight + 'px' }"
    >
      <template v-if="isMobile">
        <lokal-loading
          v-if="isBalanceAccountLoading"
          :loading="isBalanceAccountLoading"
          size="xs"
          class="self-center py-2"
        />
        <p
          v-else-if="balanceAccount?.maximumCreditsApprovedDate"
          class="sticky top-0 z-30 py-2 text-sm text-center text-white bg-lk-green md:text-base"
        >
          Créditos: {{ useFormatCurrency(balanceAccount?.creditsLeft) }}
        </p>
      </template>
      <div class="flex overflow-y-auto sticky top-0 flex-col mb-2 w-full h-4/5 text-gray-700">
        <cart-sidebar-items
          v-for="_, makerName in cartStore.groupedItems"
          :key="makerName"
          :maker-name="makerName"
        />
      </div>
      <div class="flex fixed bottom-0 z-20 flex-col mb-2 w-4/5 md:w-72">
        <a
          href="/orders/new"
          class="p-2 mx-3 mt-auto mb-1 text-sm text-center text-white bg-lk-green hover:bg-lk-green-deep rounded-md md:text-base"
        >
          Revisar el carrito
        </a>
        <button
          class="px-2 mx-3 mb-1 text-xs text-center text-lk-green underline rounded-md md:text-sm"
          @click="menuStore.showCartSidebar = false"
        >
          Seguir comprando
        </button>
      </div>
    </aside>
  </transition>
</template>
<script lang="ts" setup>
import { inject, computed } from 'vue';
import useGeneralMenuStore from 'stores/general-menu-store';
import useCartStore from 'stores/cart';
import useSessionStore from 'stores/user-store';
import CartSidebarItems from './cart-sidebar-items.vue';
import useBalanceAccountsQuery from './queries/balance-account-query';
import useFormatCurrency from './use/format-currency';

const isMobile = inject('isMobile');

const sessionStore = useSessionStore();
const menuStore = useGeneralMenuStore();
const cartStore = useCartStore();

const {
  balanceAccount, isLoading: isBalanceAccountLoading,
} = useBalanceAccountsQuery(computed(() => sessionStore.user?.shopId));
</script>
