<template>
  <div class="flex flex-col w-full h-full text-gray-700">
    <form
      class="flex flex-col w-full h-full text-gray-700"
      @submit.prevent="onSubmit"
    >
      <p class="text-sm">
        Email
      </p>
      <div class="flex flex-col mb-2 w-full">
        <Field
          v-model.trim="signUpStore.userForm.email"
          class="p-2 text-xs rounded-md border border-lk-light-gray focus:border-lk-green focus:outline-none md:text-sm"
          placeholder="Email"
          type="text"
          name="user[email]"
          label="Email"
          :rules="{ required: true, validateUserEmail: true }"
        />
        <ErrorMessage
          name="user[email]"
          class="mt-1 text-sm text-red-500"
        />
      </div>
      <div class="flex flex-col items-center mt-0">
        <button class="p-2 w-full text-sm text-white bg-lk-green/90 hover:bg-lk-green rounded-md md:text-base">
          Empezar
        </button>
        <a
          class="mt-1 text-sm hover:underline cursor-pointer"
          @click="emit('already-has-account')"
        >
          ¿Ya tienes cuenta? Inicia sesión
        </a>
      </div>
    </form>
  </div>
</template>
<script lang="ts" setup>
import { watch } from 'vue';
import { useForm, Field, ErrorMessage } from 'vee-validate';
import useSignUpStore from 'stores/sign-up-store';

const emit = defineEmits<{(e: 'already-has-account'): void}>();

const signUpStore = useSignUpStore();
const { handleSubmit } = useForm();
const onSubmit = handleSubmit(() => {
  signUpStore.currentStep = 'user';
  if (!signUpStore.currentPathIncludesShopsSignUp) window.location.href = '/shops/sign_up';
});

watch(() => signUpStore.userForm.email, () => {
  signUpStore.userForm.email = signUpStore.userForm.email.toLowerCase();
});
</script>
