<template>
  <div
    v-if="!volumeDiscountIsLoading && orderedVolumeDiscounts.length > 0"
    class="flex flex-col shrink-0 self-start"
  >
    <div class="flex flex-col justify-start text-gray-700 bg-lk-light-purple rounded-md divide-y divide-white">
      <button
        class="flex items-center py-2 px-3 sm:py-3 sm:px-4"
        @click="showVolumeDiscountCards = !showVolumeDiscountCards"
      >
        <div class="flex justify-center items-center p-1 mr-3 bg-white rounded-full">
          <TagIcon class="w-5 h-5 text-lk-light-purple sm:w-5 sm:h-5" />
        </div>
        <p class="mr-2 text-sm text-left text-white sm:text-base">
          Hasta {{ orderedVolumeDiscounts[orderedVolumeDiscounts.length - 1].discount }}% de dscto
          en compras desde {{ useFormatCurrency(firstVolumeDiscount.minimumAmountCents) }}
        </p>
        <ChevronDownIcon
          class="shrink-0 ml-auto w-4 h-4 text-white"
          :class="{ 'rotate-180': showVolumeDiscountCards }"
        />
      </button>
      <transition
        enter-active-class="transition duration-150"
        enter-from-class="-translate-y-2 opacity-50"
        enter-to-class="translate-y-0 transform opacity-100"
        leave-active-class="transition duration-100"
        leave-from-class="translate-y-0"
        leave-to-class="-translate-y-2 transform opacity-0"
      >
        <div
          v-if="showVolumeDiscountCards"
          class="flex flex-col py-2 px-3 w-full sm:gap-y-1 sm:px-5"
        >
          <p
            v-for="volumeDiscount in orderedVolumeDiscounts"
            :key="volumeDiscount.id"
            class="flex flex-row text-sm font-light text-left text-white sm:flex-row md:text-base"
          >
            <span class="mr-1">{{ volumeDiscount.discount }}% de dscto en</span>
            <span class="">órdenes sobre {{ useFormatCurrency(volumeDiscount.minimumAmountCents) }}</span>
          </p>
        </div>
      </transition>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue';
import { orderBy } from 'lodash';
import { TagIcon, ChevronDownIcon } from '@heroicons/vue/24/outline';
import useVolumeDiscountsQuery from './queries/volume-discounts-query';
import useFormatCurrency from './use/format-currency';

interface MakerShowDiscountsProps {
  maker: Maker;
}

const props = defineProps<MakerShowDiscountsProps>();
const showVolumeDiscountCards = ref(false);

const volumeDiscountQueryParams = computed(() => 'q[active_eq]=true');
const {
  volumeDiscounts, volumeDiscountIsLoading,
} = useVolumeDiscountsQuery(computed(() => props.maker.id), volumeDiscountQueryParams);
const orderedVolumeDiscounts = computed(() => {
  const internalVolumeDiscounts = volumeDiscounts.value?.map((volumeDiscount) =>
    ({ ...volumeDiscount, discount: Number(volumeDiscount.discount) }));

  return orderBy(internalVolumeDiscounts, ['discount'], ['asc']);
});
const firstVolumeDiscount = computed(() => orderedVolumeDiscounts.value?.[0]);
</script>
