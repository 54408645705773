<template>
  <div v-if="!addressesQueryLoading">
    <p>
      Dirección:
    </p>
    <lokal-single-selector
      v-model="addressRecord"
      class="mb-2"
      key-option="id"
      :options="addressesRecord"
      option-label="address"
      options-label="Dirección"
    />
    <div class="flex items-center mb-2">
      <p class="mr-2">
        Peso (kg):
      </p>
      <Field
        v-model="weight"
        class="p-2 rounded-md border"
        name="weight"
      />
    </div>
    <div v-if="selectedAddress && weight">
      <lokal-loading
        v-if="isLoading"
        :loading="isLoading"
      />
      <template v-else>
        <p class="mb-3">
          Resultado:
        </p>
        <div
          class="grid grid-cols-6 font-semibold"
        >
          <span>Carrier</span>
          <span>Nombre del carrier</span>
          <span>Nombre del servicio</span>
          <span>Tipos de servicios</span>
          <span>Días de tránsito</span>
          <span>Precio</span>
        </div>
        <div
          v-for="shippingQuotation in sortedShippingQuotations"
          :key="shippingQuotation.id"
        >
          <div
            v-for="service in shippingQuotation.services"
            :key="`${service.name}-${shippingQuotations.id}`"
            class="grid grid-cols-6"
          >
            <span>{{ shippingQuotation.carrier }}</span>
            <span>{{ shippingQuotation.name }}</span>
            <span>{{ service.name }}</span>
            <span>{{ service.serviceTypes }}</span>
            <span>{{ service.transitDays }}</span>
            <span>{{ service.priceFormatted }}</span>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, computed, watch } from 'vue';
import { useQuery } from 'vue-query';
import { Field } from 'vee-validate';
import { orderBy, min } from 'lodash';
import shippingQuotationsApi from 'api/shipping-quotations';
import lokalSingleSelector, { type OptionRecord } from './shared/lokal-single-selector.vue';
import useAddressesQuery from './queries/addresses-query';

const props = defineProps<{
  maker: Maker,
  commune: Commune
}>();

const { addresses, addressesQuery } = useAddressesQuery(
  computed(() => props.maker.id), computed(() => 'Maker'));
const addressesQueryLoading = computed(() => addressesQuery.isLoading.value);
const addressRecord = ref({} as OptionRecord);
const addressesRecord = computed(() =>
  addresses.value?.map((address) => ({
    id: address.id, name: address.nameReference, address: `${address.nameReference}: ${address.address}`,
  })));
const selectedAddress = computed(() => addresses.value?.find((address) => address.id === addressRecord.value?.id));
const weight = ref(3);
const computedCommune = computed(() => props.commune.name);
const shippingQuotationsQuery = useQuery(['shippingQuotations', selectedAddress, weight, computedCommune], () =>
  shippingQuotationsApi.index(selectedAddress.value.communeName, props.commune.name, weight.value), {
  enabled: computed(() => !!selectedAddress.value?.id),
});
const isLoading = computed(() => shippingQuotationsQuery.isLoading.value);
const shippingQuotations = computed(() => shippingQuotationsQuery.data.value?.data.shippingQuotations);
const sortedShippingQuotations = computed(() =>
  orderBy(
    shippingQuotations.value,
    [function (shippingQuotation) {
      return min(shippingQuotation.services.map((service: { price: number; }) => service.price));
    }],
  ),
);

watch(() => props.maker, () => {
  addressRecord.value = {} as OptionRecord;
});
</script>
