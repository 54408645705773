<template>
  <TransitionRoot
    :show="modelValue"
    as="template"
    enter="duration-300 ease-out"
    enter-from="opacity-0"
    enter-to="opacity-100"
  >
    <Dialog
      as="div"
      class="relative z-40"
      @close="emit('update:modelValue', false)"
    >
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
      >
        <div
          class="fixed inset-0 bg-black/30"
          aria-hidden="true"
        />
      </TransitionChild>
      <div class="flex fixed inset-0 justify-center items-center px-2 md:px-0">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
        >
          <DialogPanel class="flex overflow-y-auto flex-col py-2 px-3 w-full max-w-lg max-h-[600px] text-gray-700 bg-gray-50 rounded resize md:p-4 md:max-h-[650px]">
            <button
              class="mb-2 ml-auto focus:outline-none"
              @click="emit('update:modelValue', false)"
            >
              <XMarkIcon class="w-5 h-5 fill-gray-700 md:w-6 md:h-6" />
            </button>
            <h3 class="mb-3 text-xl md:text-2xl">
              Propuesta de modificación de pedido
            </h3>
            <p class="mb-3 text-sm md:text-base">
              La marca no puede completar el pedido original por lo que te ha hecho una propuesta
              antes de continuar.
            </p>
            <lokal-loading
              v-if="subOrderProductModificationsIsLoading"
              size="xs"
              :loading="subOrderProductModificationsIsLoading"
            />
            <template v-else>
              <div class="flex overflow-x-auto flex-col mb-2 w-full h-full min-h-[300px] text-xs md:text-sm">
                <table class="">
                  <thead>
                    <tr class="font-normal bg-gray-100">
                      <th class="p-2 text-left">
                        Imagen
                      </th>
                      <th class="p-2 text-left">
                        Producto
                      </th>
                      <th class="p-2 text-left">
                        Estado
                      </th>
                      <th class="p-2 text-left">
                        Cantidad Solicitada
                      </th>
                      <th class="p-2 text-left">
                        Cantidad Disponible
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="subOrderProductModification in orderSubOrderProductModifications"
                      :key="subOrderProductModification.id"
                      class="border-b"
                      :class="{
                        'bg-green-50': modificationDataFor(subOrderProductModification)?.status === 'accepted',
                        'bg-red-50': modificationDataFor(subOrderProductModification)?.status === 'rejected',
                        'bg-yellow-50':
                          modificationDataFor(subOrderProductModification)?.status === 'waiting_stock_reposition',
                      }"
                    >
                      <td class="p-2">
                        <img
                          :src="subOrderProductModification.productImageUrl"
                          class="shrink-0 w-12 h-12 rounded-md md:w-14 md:h-14"
                        >
                      </td>
                      <td class="p-2 h-24">
                        <div class="overflow-y-auto max-h-full">
                          {{ subOrderProductModification.productName }}
                        </div>
                      </td>
                      <td class="p-2 text-center">
                        <div
                          :class="{
                            'shrink-0 px-1 text-xs rounded-md': true,
                            'text-red-500 border border-red-500': withoutStock(subOrderProductModification) ||
                              insufficientStock(subOrderProductModification),
                            'text-lk-green border border-lk-green': isNewProduct(subOrderProductModification) ||
                              isNewQuantity(subOrderProductModification),
                          }"
                        >
                          {{
                            withoutStock(subOrderProductModification) ? 'SIN STOCK' :
                            isNewProduct(subOrderProductModification) ? 'NUEVO PRODUCTO' :
                            insufficientStock(subOrderProductModification) ? 'STOCK INSUFICIENTE' :
                            isNewQuantity(subOrderProductModification) ? 'NUEVA CANTIDAD' :
                            '-'
                          }}
                        </div>
                        <span
                          v-if="!!subOrderProductModification.replacementTime"
                          class="text-xs text-gray-500"
                        >
                          Reposición: {{ subOrderProductModification.replacementTime }}
                        </span>
                      </td>
                      <td class="p-2 text-center">
                        {{ subOrderProductModification.originalQuantity }}
                      </td>
                      <td class="p-2 text-center">
                        {{ subOrderProductModification.newQuantity }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                v-if="!!selectedOption"
                class="flex justify-between items-center mb-2 text-xs font-light md:text-sm"
              >
                <div class="flex items-center mb-1">
                  <div class="shrink-0 mr-1 w-3 h-3 bg-green-50 border border-green-200 md:mr-2 md:w-4 md:h-4" />
                  <p class="">
                    Acepta cambios
                  </p>
                </div>
                <div class="flex items-center mb-1">
                  <div class="shrink-0 mr-1 w-3 h-3 bg-red-50 border border-red-200 md:mr-2 md:w-4 md:h-4" />
                  <p class="">
                    Rechaza cambios
                  </p>
                </div>
                <div class="flex items-center mb-1">
                  <div class="shrink-0 mr-1 w-3 h-3 bg-yellow-50 border border-yellow-200 md:mr-2 md:w-4 md:h-4" />
                  <p class="">
                    Esperar reposición
                  </p>
                </div>
              </div>
              <div class="flex flex-col mb-3">
                <button class="flex items-center">
                  <p class="mb-2 text-sm md:text-base">
                    {{ showOptions ? 'Ocultar' : 'Mostrar' }} opciones
                  </p>
                  <div
                    class="flex items-center p-2 mr-2 mb-2 space-x-2 text-sm text-gray-700 rounded-md md:space-x-3 md:text-base"
                    :class="{ 'rotate-180 transition-transform duration-300': showOptions }"
                    @click="showOptions = !showOptions"
                  >
                    <ChevronDownIcon class="w-5 h-5" />
                  </div>
                </button>
                <template v-if="showOptions">
                  <button
                    class="flex items-center p-2 mb-2 space-x-2 text-sm text-left text-gray-700 rounded-md border border-gray-700 md:space-x-3 md:text-base"
                    :class="{ 'bg-gray-700 text-white': selectedOption === 'acceptChanges' }"
                    @click="handleOptionClick('acceptChanges')"
                  >
                    <div
                      class="flex shrink-0 justify-center items-center w-4 h-4 bg-white rounded-full border-2 md:w-5 md:h-5"
                      :class="{ 'border-white': selectedOption === 'acceptChanges',
                                'border-gray-700': selectedOption !== 'acceptChanges' }"
                    >
                      <div
                        class="shrink-0 w-1.5 h-1.5 rounded-full md:w-2.5 md:h-2.5"
                        :class="{ 'bg-gray-700': selectedOption === 'acceptChanges',
                                  'bg-white': selectedOption !== 'acceptChanges' }"
                      />
                    </div>
                    <p>
                      Aceptar cambios propuestos por la marca
                    </p>
                  </button>
                  <button
                    v-if="subOrderProductModifications && subOrderProductModifications.length > 1"
                    class="flex items-center p-2 mb-2 space-x-2 text-sm text-left text-gray-700 rounded-md border border-gray-700 md:space-x-3 md:text-base"
                    :class="{ 'bg-gray-700 text-white': selectedOption === 'removeProductsWithoutStock' }"
                    @click="handleOptionClick('removeProductsWithoutStock')"
                  >
                    <div
                      class="flex shrink-0 justify-center items-center w-4 h-4 bg-white rounded-full border-2 md:w-5 md:h-5"
                      :class="{ 'border-white': selectedOption === 'removeProductsWithoutStock',
                                'border-gray-700': selectedOption !== 'removeProductsWithoutStock' }"
                    >
                      <div
                        class="shrink-0 w-1.5 h-1.5 rounded-full md:w-2.5 md:h-2.5"
                        :class="{ 'bg-gray-700': selectedOption === 'removeProductsWithoutStock',
                                  'bg-white': selectedOption !== 'removeProductsWithoutStock' }"
                      />
                    </div>
                    <p>
                      Eliminar unidades sin stock sin otros cambios
                    </p>
                  </button>
                  <button
                    v-if="atLeastOneProductHasReplacementTime()"
                    class="flex items-center p-2 space-x-2 text-sm text-left text-gray-700 rounded-md border border-gray-700 md:space-x-3 md:text-base"
                    :class="{ 'bg-gray-700 text-white': selectedOption === 'stockRepositionAndRejectChanges' }"
                    @click="handleOptionClick('stockRepositionAndRejectChanges')"
                  >
                    <div
                      class="flex shrink-0 justify-center items-center w-4 h-4 bg-white rounded-full border-2 md:w-5 md:h-5"
                      :class="{ 'border-white': selectedOption === 'stockRepositionAndRejectChanges',
                                'border-gray-700': selectedOption !== 'stockRepositionAndRejectChanges' }"
                    >
                      <div
                        class="shrink-0 w-1.5 h-1.5 rounded-full md:w-2.5 md:h-2.5"
                        :class="{ 'bg-gray-700': selectedOption === 'stockRepositionAndRejectChanges',
                                  'bg-white': selectedOption !== 'stockRepositionAndRejectChanges' }"
                      />
                    </div>
                    <p>
                      Esperar la reposición sin otros cambios
                    </p>
                  </button>
                  <p
                    v-if="selectedOption === 'stockRepositionAndRejectChanges'"
                    class="mt-1 text-xs font-light md:text-sm"
                  >
                    *El pedido se enviará cuando se tenga reposición de los productos
                  </p>
                </template>
              </div>
              <button
                :disabled="!selectedOption || bulkUpdateIsLoading"
                class="flex justify-center items-center p-2 mb-1 w-full text-sm text-white bg-lk-green rounded-md md:text-base"
                :class="{ 'hover:bg-lk-green-deep': !!selectedOption, 'opacity-50': !selectedOption }"
                @click="bulkUpdateMutation.mutate()"
              >
                <template
                  v-if="bulkUpdateIsLoading"
                >
                  <loading-spinner class="mr-2" />
                  <span>Confirmando...</span>
                </template>
                <span v-else>
                  Confirmar
                </span>
              </button>
              <a
                :href="`https://wa.me/${contactNumber}?text='Hola, necesito ayuda en el cambio de productos`"
                class="mb-2 text-xs text-center hover:underline md:text-sm"
              >
                Necesito ayuda
              </a>
            </template>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<script lang="ts" setup>
import { ref, computed } from 'vue';
import { XMarkIcon, ChevronDownIcon } from '@heroicons/vue/24/solid';
import {
  Dialog,
  DialogPanel,
  TransitionRoot,
  TransitionChild,
} from '@headlessui/vue';
import { useQuery, useMutation } from 'vue-query';
import subOrderProductModificationsApi from 'api/sub-order-product-modifications';
import useMessageStore from 'stores/message-store';
import { orderBy } from 'lodash';

interface SubOrderAdvancePaymentDialogProps {
  modelValue: boolean
  subOrder: SubOrder
}
const props = withDefaults(defineProps<SubOrderAdvancePaymentDialogProps>(), {
  modelValue: false,
});

const emit = defineEmits<{(e: 'update:modelValue', value: boolean): void,
  (e: 'update:subOrder'): void
}>();

const messageStore = useMessageStore();

const selectedOption = ref('');
const showOptions = ref(true);
const contactNumber = computed(() => process.env.CONTACT_NUMBER);

function handleOptionClick(option: string) {
  if (selectedOption.value === option) {
    selectedOption.value = '';
  } else {
    selectedOption.value = option;
  }
}

const subOrderProductModificationsQuery = useQuery(
  ['subOrderProductModifications', props.subOrder.id],
  () => subOrderProductModificationsApi.index(props.subOrder.id),
);
const subOrderProductModificationsIsLoading = computed(
  () => subOrderProductModificationsQuery.isLoading.value,
);
const subOrderProductModifications = computed(
  () =>
    subOrderProductModificationsQuery.data.value?.data?.subOrderProductModifications as SubOrderProductModification[],
);
const orderSubOrderProductModifications = computed(
  () => orderBy(subOrderProductModifications.value, ['originalQuantity', 'newQuantity'], ['desc', 'asc']),
);

function insufficientStock(subOrderProductModification: SubOrderProductModification) {
  return subOrderProductModification.newQuantity < subOrderProductModification.originalQuantity;
}

function isNewProduct(subOrderProductModification: SubOrderProductModification) {
  return subOrderProductModification.originalQuantity === 0;
}

function withoutStock(subOrderProductModification: SubOrderProductModification) {
  return subOrderProductModification.newQuantity === 0;
}

function isNewQuantity(subOrderProductModification: SubOrderProductModification) {
  return subOrderProductModification.newQuantity > subOrderProductModification.originalQuantity &&
    subOrderProductModification.newQuantity !== 0;
}

function atLeastOneProductHasReplacementTime() {
  return subOrderProductModifications.value?.some(
    (subOrderProductModification) => !!subOrderProductModification.replacementTime,
  ) || false;
}

function removeProductsWithoutStockStatus(subOrderProductModification: SubOrderProductModification) {
  if (isNewProduct(subOrderProductModification) || isNewQuantity(subOrderProductModification)) {
    return 'rejected';
  }

  return 'accepted';
}

function stockRepositionAndRejectChanges(
  subOrderProductModification: SubOrderProductModification,
) {
  if (withoutStock(subOrderProductModification)) return 'waiting_stock_reposition';
  else if (insufficientStock(subOrderProductModification)) return 'accepted';

  return 'rejected';
}

function subOrderModificationData(subOrderProductModification: SubOrderProductModification) {
  const data = { id: subOrderProductModification.id };
  if (selectedOption.value === 'acceptChanges') {
    return { ...data, status: 'accepted' };
  } else if (selectedOption.value === 'removeProductsWithoutStock') {
    return { ...data, status: removeProductsWithoutStockStatus(subOrderProductModification) };
  } else if (selectedOption.value === 'stockRepositionAndRejectChanges') {
    return { ...data, status: stockRepositionAndRejectChanges(subOrderProductModification) };
  }

  return {};
}

const bulkModificationsData = computed(() => {
  if (!selectedOption.value) return [];

  return subOrderProductModifications.value?.map((subOrderProductModification) =>
    subOrderModificationData(subOrderProductModification));
});

const bulkUpdateMutation = useMutation(
  ['bulkUpdateSubOrderProductModifications', props.subOrder.id],
  () => subOrderProductModificationsApi.bulkUpdate(bulkModificationsData.value),
  {
    onSuccess: () => {
      emit('update:subOrder');
      messageStore.showMessage('Se han aplicado los cambios seleccionados', 'success');
      emit('update:modelValue', false);
    },
  },
);
const bulkUpdateIsLoading = computed(() => bulkUpdateMutation.isLoading.value);

interface ModificationData {
  id: number
  subOrderId: number
  status: string
}

function modificationDataFor(
  subOrderProductModification: SubOrderProductModification,
): ModificationData {
  if (!selectedOption.value) return {} as ModificationData;

  return subOrderModificationData(subOrderProductModification) as ModificationData;
}
</script>

