<template>
  <div class="flex flex-col w-full">
    <div class="grid grid-cols-2 gap-2 items-center mb-4 h-full lg:grid-cols-3 2xl:flex 2xl:flex-row 2xl:gap-0 2xl:space-y-0 2xl:space-x-5">
      <div class="flex justify-center items-center place-self-start p-2 w-full rounded-md border md:p-3 lg:p-4 xl:p-5 xl:h-32 2xl:w-44">
        <lokal-loading
          v-if="destinationMetricsLoading"
          :loading="destinationMetricsLoading"
          size="xs"
        />
        <div
          v-else
          class="flex flex-col items-center"
        >
          <lokal-tooltip
            :information="'Tiempo promedio entre el estado pendiente y confirmado'"
            class="ml-auto text-sm md:text-base"
            button-classes="flex items-center space-x-1"
            information-classes="w-40 md:w-52 p-1 md:p-2"
            position-options="center"
            :main-label="`${isConfirmationHours ? 'Horas' : 'Días' } promedio en confirmar`"
          />
          <p class="text-xl md:text-2xl">
            {{ round(meanConfirmationTimesFormat, 2) }}
          </p>
        </div>
      </div>
      <div class="flex justify-center items-center place-self-start p-2 w-full rounded-md border md:p-3 lg:p-4 xl:p-5 xl:h-32 2xl:w-44">
        <lokal-loading
          v-if="destinationMetricsLoading"
          :loading="destinationMetricsLoading"
          size="xs"
        />
        <div
          v-else
          class="flex flex-col items-center"
        >
          <lokal-tooltip
            :information="'Tiempo promedio entre el estado confirmado y en despacho'"
            class="ml-auto text-sm md:text-base"
            button-classes="flex items-center space-x-1"
            information-classes="w-40 md:w-52 p-1 md:p-2"
            position-options="center"
            :main-label="`${isDeliveryInProgressHours ? 'Horas' : 'Días'} promedio en comenzar despacho`"
          />
          <p class="text-xl md:text-2xl">
            {{ round(meanDeliveryInProgressTimesFormat, 2) }}
          </p>
        </div>
      </div>
      <div class="flex justify-center items-center place-self-start p-2 w-full rounded-md border md:p-3 lg:p-4 xl:p-5 xl:h-32 2xl:w-44">
        <lokal-loading
          v-if="destinationMetricsLoading"
          :loading="destinationMetricsLoading"
          size="xs"
        />
        <div
          v-else
          class="flex flex-col items-center"
        >
          <lokal-tooltip
            :information="'Tiempo promedio entre el estado en despacho y entregado'"
            class="ml-auto text-sm md:text-base"
            button-classes="flex items-center space-x-1"
            information-classes="w-40 md:w-52 p-1 md:p-2"
            position-options="center"
            :main-label="`${isDeliveryHours ? 'Horas' : 'Días'} promedio de despachos`"
          />
          <p class="text-xl md:text-2xl">
            {{ round(meanDeliveryTimesFormat, 2) }}
          </p>
        </div>
      </div>
      <div class="flex justify-center items-center place-self-start p-2 w-full rounded-md border md:p-3 lg:p-4 xl:p-5 xl:h-32 2xl:w-44">
        <lokal-loading
          v-if="destinationMetricsLoading"
          :loading="destinationMetricsLoading"
          size="xs"
        />
        <div
          v-else
          class="flex flex-col items-center"
        >
          <lokal-tooltip
            :information="'Tiempo entre pedidos iniciando desde la fecha de inicio de la consulta'"
            class="ml-auto text-sm md:text-base"
            button-classes="flex items-center space-x-1"
            information-classes="w-40 md:w-52 p-1 md:p-2"
            position-options="center"
            :main-label="`${isFrequencyHours ? 'Horas' : 'Días'} promedio entre pedidos`"
          />
          <p class="text-xl md:text-2xl">
            {{ round(meanFrequencyTimesFormat, 2) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import { round, mean, orderBy } from 'lodash';
import useDestinationMetricsQuery from './queries/destination-metrics-query';
import lokalTooltip from './shared/lokal-tooltip.vue';

const HOURS_PER_DAY = 24;

const props = defineProps<{
  maker: Maker,
  initialDate: string,
  endDate: string,
}>();

const queryParams = computed(() =>
  `q[pending_at_gteq]=${props.initialDate}&q[pending_at_lteq]=${props.endDate}&`);

const {
  destinationMetricsLoading, subOrders,
} = useDestinationMetricsQuery(computed(() => props.maker.id), computed(() => true), queryParams);

function timeDifference(date1: Date, date2: Date) {
  const difference = date1.getTime() - date2.getTime();

  // eslint-disable-next-line no-magic-numbers
  return difference / (1000 * 60 * 60 * 24);
}

// mean time between pending and confirmation
const confirmationTimes = computed(() => {
  if (!subOrders.value?.length) return [];
  const filterTimes = subOrders.value?.filter((subOrder: SubOrder) =>
    subOrder.confirmationAt && subOrder.pendingAt);
  const times = filterTimes?.map((subOrder: SubOrder) =>
    timeDifference(new Date(subOrder.confirmationAt), new Date(subOrder.pendingAt)));

  return times;
});
const meanConfirmationTimes = computed(() => mean(confirmationTimes.value));
const meanConfirmationTimesFormat = computed(() => {
  if (meanConfirmationTimes.value <= 1) return meanConfirmationTimes.value * HOURS_PER_DAY;

  return meanConfirmationTimes.value;
});
const isConfirmationHours = computed(() => meanConfirmationTimes.value < 1);

// mean time between confirmation and delivery in progress
const deliveryInProgressTimes = computed(() => {
  if (!subOrders.value?.length) return [];
  const filterTimes = subOrders.value?.filter((subOrder: SubOrder) =>
    subOrder.confirmationAt && subOrder.deliveryInProgressAt);
  const times = filterTimes?.map((subOrder: SubOrder) =>
    timeDifference(new Date(subOrder.deliveryInProgressAt), new Date(subOrder.confirmationAt)));

  return times;
});
const meanDeliveryInProgressTimes = computed(() => mean(deliveryInProgressTimes.value));
const meanDeliveryInProgressTimesFormat = computed(() => {
  if (meanDeliveryInProgressTimes.value <= 1) return meanDeliveryInProgressTimes.value * HOURS_PER_DAY;

  return meanDeliveryInProgressTimes.value;
});
const isDeliveryInProgressHours = computed(() => meanDeliveryInProgressTimes.value < 1);

// mean time between delivery in progress and delivered
const deliveryTimes = computed(() => {
  if (!subOrders.value?.length) return [];
  const filterTimes = subOrders.value?.filter((subOrder: SubOrder) =>
    subOrder.deliveryInProgressAt && subOrder.deliveredAt);
  const times = filterTimes?.map((subOrder: SubOrder) =>
    timeDifference(new Date(subOrder.deliveredAt), new Date(subOrder.deliveryInProgressAt)));

  return times;
});
const meanDeliveryTimes = computed(() => mean(deliveryTimes.value));
const meanDeliveryTimesFormat = computed(() => {
  if (meanDeliveryTimes.value <= 1) return meanDeliveryTimes.value * HOURS_PER_DAY;

  return meanDeliveryTimes.value;
});
const isDeliveryHours = computed(() => meanDeliveryTimes.value < 1);

// frequency time of suborders
const frequencyTimes = computed(() => {
  if (!subOrders.value?.length) return [];
  const filterTimes = subOrders.value?.filter((subOrder: SubOrder) => subOrder.pendingAt);
  const times = [] as number[];
  let initialDate = props.initialDate;
  orderBy(filterTimes, 'pendingAt', 'asc')?.forEach((subOrder: SubOrder) => {
    const pendingDate = new Date(subOrder.pendingAt);
    if (initialDate) {
      const difference = timeDifference(pendingDate, new Date(initialDate));
      times.push(difference);
    }
    initialDate = subOrder.pendingAt;
  });
  if (initialDate && props.endDate) times.push(timeDifference(new Date(props.endDate), new Date(initialDate)));

  return times;
});
const meanFrequencyTimes = computed(() => mean(frequencyTimes.value));
const meanFrequencyTimesFormat = computed(() => {
  if (meanFrequencyTimes.value <= 1) return meanFrequencyTimes.value * HOURS_PER_DAY;

  return meanFrequencyTimes.value;
});
const isFrequencyHours = computed(() => meanFrequencyTimes.value < 1);
</script>
