import { defineStore } from 'pinia';
import { ref } from 'vue';

const SHORT_TIMEOUT = 1500;
const TIMEOUT = 3000;
const LONG_TIMEOUT = 5000;

const TIMEOUT_HASH = {
  short: SHORT_TIMEOUT,
  normal: TIMEOUT,
  long: LONG_TIMEOUT,
};

const useMessageStore = defineStore('messageStore', () => {
  const message = ref('');
  const kind = ref('futureAction' as 'success' | 'error' | 'futureAction');
  const timeout = ref(TIMEOUT);

  function showMessage(newMessage: string, newKind: 'success' | 'error' | 'futureAction',
    timeoutString = 'normal' as 'short' | 'normal' | 'long',
  ) {
    timeout.value = TIMEOUT_HASH[timeoutString];
    kind.value = newKind;
    message.value = newMessage;
  }

  return { message, kind, timeout, showMessage };
});

export default useMessageStore;
