<template>
  <div class="flex flex-col items-center py-8 px-4 w-full h-full bg-lk-light-gray sm:px-8 md:py-12 lg:pr-0 lg:pl-[128px] xl:pr-0">
    <div class="flex flex-col items-center w-full max-w-6xl h-full lg:pr-[96px]">
      <p class="mb-5 w-full font-serif-noto text-lg text-left text-lk-green-deep sm:text-xl md:mb-14 lg:text-2xl">
        Cómo comprar en Lokal
      </p>
      <div
        v-if="isMobileOrTablet"
        class="grid grid-cols-3 grid-rows-[repeat(3,_minmax(0,_1fr)_minmax(0,_80px))_minmax(0,_1fr)]"
      >
        <!-- first row -->
        <div class="flex flex-col justify-center items-center w-full">
          <div class="w-full" />
          <div class="flex shrink-0 col-span-2 col-start-2 justify-center items-center self-center w-20 h-20 bg-white rounded-full lg:w-28 lg:h-28">
            <inline-svg-wrapper
              name="registration-icon"
              class="h-10 fill-lk-green-deep lg:h-12"
            />
          </div>
          <div class="self-end w-1/2 h-1/2 border-l-2 border-white" />
        </div>
        <div class="flex flex-col col-span-2 w-full text-left md:text-center">
          <p class="flex flex-col text-lg font-semibold text-lk-green-deep xl:text-xl">
            <span>01</span>
            <span>Registra tu tienda</span>
          </p>
          <p class="text-sm text-gray-50 xl:text-base">
            Primero debes crear tu cuenta como usuario y luego registrar tu tienda.
          </p>
        </div>
        <!-- first line -->
        <div class="flex w-full h-20">
          <div class="ml-auto w-1/2 h-1/2 rounded-bl-[50px] border-b-2 border-l-2 border-white" />
        </div>
        <div class="self-start w-full h-1/2 border-b-2 border-white" />
        <div
          class="self-start w-1/2 h-1/2 rounded-tr-[50px] border-t-2 border-r-2 border-white outline-2 outline-white"
          style="margin-top: 38px"
        />
        <!-- second row -->
        <div class="flex flex-col col-span-2 w-full text-right md:text-center">
          <p class="flex flex-col text-lg font-semibold text-lk-green-deep xl:text-xl">
            <span>02</span>
            <span>Encuentra nuevos productos</span>
          </p>
          <p class="place-self-center w-full text-sm text-gray-50 xl:text-base">
            Busca entre los más de 2000 productos de marcas locales
          </p>
        </div>
        <div class="flex flex-col justify-center items-center w-full">
          <div class="self-start -mt-1 w-1/2 h-full border-r-2 border-white" />
          <div class="flex shrink-0 justify-center items-center w-20 h-20 bg-white rounded-full lg:w-28 lg:h-28">
            <inline-svg-wrapper
              name="shopping-icon"
              class="h-10 fill-lk-green-deep lg:h-12"
            />
          </div>
          <div class="self-start -mr-1 w-1/2 h-full border-r-2 border-white" />
        </div>
        <!-- second line -->
        <div
          class="justify-self-end w-1/2 h-1/2 rounded-tl-[50px] border-t-2 border-l-2 border-white"
          style="margin-top: 38px"
        />
        <div class="self-start w-full h-1/2 border-b-2 border-white" />
        <div class="h-20">
          <div class="self-start w-1/2 h-1/2 rounded-br-[50px] border-r-2 border-b-2 border-white outline-2 outline-white" />
        </div>
        <!-- third row -->
        <div class="flex flex-col justify-center items-center w-full">
          <div class="self-end -mt-1 w-1/2 h-full border-l-2 border-white" />
          <div class="flex shrink-0 col-span-2 col-start-2 justify-center items-center self-center w-20 h-20 bg-white rounded-full lg:w-28 lg:h-28">
            <inline-svg-wrapper
              name="bnapl-icon"
              class="h-10 fill-lk-green-deep lg:h-12"
            />
          </div>
          <div class="self-end w-1/2 h-full border-l-2 border-white" />
        </div>
        <div class="flex flex-col col-span-2 w-full text-left md:text-center">
          <p class="flex flex-col text-lg font-semibold text-lk-green-deep xl:text-xl">
            <span>03</span>
            <span>Compra y paga en 60 días</span>
          </p>
          <p class="text-sm text-gray-50 xl:text-base">
            Accede a precios mayoristas y paga a 60 días.
          </p>
        </div>
        <!-- third line -->
        <div class="flex w-full h-20">
          <div class="ml-auto w-1/2 h-1/2 rounded-bl-[50px] border-b-2 border-l-2 border-white" />
        </div>
        <div class="self-start w-full h-1/2 border-b-2 border-white" />
        <div
          class="self-start w-1/2 h-1/2 rounded-tr-[50px] border-t-2 border-r-2 border-white outline-2 outline-white"
          style="margin-top: 38px"
        />
        <!-- fourth row -->
        <div class="flex flex-col col-span-2 w-full text-right md:text-center">
          <p class="flex flex-col text-lg font-semibold text-lk-green-deep xl:text-xl">
            <span>04</span>
            <span>Prueba productos <br> hasta por 60 días</span>
          </p>
          <p class="w-full text-sm text-gray-50 md:place-self-center xl:text-base">
            La primera compra que hagas a cada marca tiene una garantía de devolución a 60 días.
          </p>
        </div>
        <div class="flex flex-col justify-center items-center w-full">
          <div class="self-start -mt-1 w-1/2 h-full border-r-2 border-white" />
          <div class="flex shrink-0 justify-center items-center w-20 h-20 bg-white rounded-full lg:w-28 lg:h-28">
            <inline-svg-wrapper
              name="try-products-icon"
              class="h-10 fill-lk-green-deep lg:h-12"
            />
          </div>
          <div class="w-full" />
        </div>
      </div>
      <div
        v-else
        class="grid relative grid-cols-[repeat(3,_minmax(0,_1fr)_minmax(0,_96px))_minmax(0,1fr)] grid-rows-[repeat(3,_minmax(0,_120px))] grid-flow-col justify-items-center"
      >
        <!-- first col -->
        <div class="flex justify-center items-center w-full">
          <div class="w-full" />
          <div class="flex shrink-0 col-span-2 col-start-2 justify-center items-center self-center w-20 h-20 bg-white rounded-full lg:w-28 lg:h-28">
            <inline-svg-wrapper
              name="registration-icon"
              class="h-10 fill-lk-green-deep lg:h-12"
            />
          </div>
          <div class="self-end w-full h-1/2 border-t-2 border-white" />
        </div>
        <p class="flex flex-col text-lg font-semibold text-center text-lk-green-deep xl:text-xl">
          <span>01</span>
          <span>Registra tu tienda</span>
        </p>
        <p class="text-sm text-center text-gray-50 xl:text-base">
          Primero debes crear tu cuenta como usuario y luego registrar tu tienda.
        </p>
        <!-- first line -->
        <div class="self-end place-self-start w-1/2 h-1/2 rounded-tr-[50px] border-t-2 border-r-2 border-white" />
        <div class="place-self-start w-1/2 h-full border-r-2 border-white" />
        <div class="self-start ml-[44px] w-1/2 h-1/2 rounded-bl-[50px] border-b-2 border-l-2 border-white outline-2 outline-white" />
        <!-- second col -->
        <p class="place-self-center w-full text-sm text-center text-gray-50 xl:text-base">
          Busca entre los más de 2000 productos de marcas locales
        </p>
        <p class="flex flex-col text-lg font-semibold text-center text-lk-green-deep xl:text-xl">
          <span>02</span>
          <span>Encuentra nuevos productos</span>
        </p>
        <div class="flex justify-center items-center w-full">
          <div class="self-start -ml-1 w-full h-1/2 border-b-2 border-white" />
          <div class="flex shrink-0 justify-center items-center w-20 h-20 bg-white rounded-full lg:w-28 lg:h-28">
            <inline-svg-wrapper
              name="shopping-icon"
              class="h-10 fill-lk-green-deep lg:h-12"
            />
          </div>
          <div class="self-start -mr-1 w-full h-1/2 border-b-2 border-white" />
        </div>
        <!-- second line -->
        <div class="self-end place-self-end w-1/2 h-1/2 rounded-tl-[50px] border-t-2 border-l-2 border-white" />
        <div class="place-self-end w-1/2 h-full border-l-2 border-white" />
        <div class="self-start place-self-start ml-[2px] w-1/2 h-1/2 rounded-br-[50px] border-r-2 border-b-2 border-white" />
        <!-- third row -->
        <div class="flex justify-center items-center w-full">
          <div class="self-end w-full h-1/2 border-t-2 border-white" />
          <div class="flex shrink-0 col-span-2 col-start-2 justify-center items-center self-center w-20 h-20 bg-white rounded-full lg:w-28 lg:h-28">
            <inline-svg-wrapper
              name="bnapl-icon"
              class="h-10 fill-lk-green-deep lg:h-12"
            />
          </div>
          <div class="self-end w-full h-1/2 border-t-2 border-white" />
        </div>
        <p class="flex flex-col text-lg font-semibold text-center text-lk-green-deep xl:text-xl">
          <span>03</span>
          <span>Compra y paga en 60 días</span>
        </p>
        <p class="text-sm text-center text-gray-50 xl:text-base">
          Accede a precios mayoristas y paga a 60 días.
        </p>
        <!-- third line -->
        <div class="self-end place-self-start w-1/2 h-1/2 rounded-tr-[50px] border-t-2 border-r-2 border-white" />
        <div class="place-self-start w-1/2 h-full border-r-2 border-white" />
        <div class="self-start ml-[44px] w-1/2 h-1/2 rounded-bl-[50px] border-b-2 border-l-2 border-white outline-2 outline-white" />
        <!-- forth col -->
        <p class="place-self-center w-full text-sm text-center text-gray-50 xl:text-base">
          La primera compra que hagas a cada marca tiene una garantía de devolución a 60 días.
        </p>
        <p class="flex flex-col text-lg font-semibold text-center text-lk-green-deep xl:text-xl">
          <span>04</span>
          <span>Prueba productos por 60 días</span>
        </p>
        <div class="flex justify-center items-center w-full">
          <div class="self-start -ml-1 w-full h-1/2 border-b-2 border-white" />
          <div class="flex shrink-0 justify-center items-center w-20 h-20 bg-white rounded-full lg:w-28 lg:h-28">
            <inline-svg-wrapper
              name="try-products-icon"
              class="h-10 fill-lk-green-deep lg:h-12"
            />
          </div>
          <div class="w-full" />
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { inject } from 'vue';
import inlineSvgWrapper from './inline-svg-wrapper.vue';

const isMobileOrTablet = inject('isMobileOrTablet');
</script>
