<template>
  <div class="flex flex-col w-full">
    <button
      class="flex items-center p-3 text-sm hover:bg-gray-50 rounded-md border border-lk-light-gray md:p-4 md:text-base"
      :class="{ 'border-1.5': selected }"
      @click="selected ? emit('unset:collection') : emit('set:collection', collection)"
    >
      <p class="flex flex-col text-left">
        <span>{{ collection.name }}</span>
        <span class="text-xs font-light md:text-sm">Prioridad: {{ collection.makerPriority }}</span>
      </p>
      <p
        class="mr-2 ml-auto font-light hover:underline"
      >
        {{ selected ? 'esconder' : 'ver' }}
      </p>
      <button
        class="font-light hover:underline"
        @click.stop="showUpdateCollectionDialog = !showUpdateCollectionDialog"
      >
        editar
      </button>
    </button>
    <makers-collection-new-dialog
      v-if="showUpdateCollectionDialog"
      v-model="showUpdateCollectionDialog"
      :maker-id="maker.id"
      :collection="collection"
      @reload-collections="() => emit('reload-collections')"
    />
  </div>
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue';
import MakersCollectionNewDialog from './makers-collection-new-dialog.vue';

const props = defineProps<{
  collection: Collection;
  maker: Maker;
  selectedCollection: Collection;
}>();

const emit = defineEmits<{(e: 'set:collection', value: Collection): void, (e: 'reload-collections'): void,
  (e: 'unset:collection'): void,
}>();

const selected = computed(() => props.selectedCollection.id === props.collection.id);

const showUpdateCollectionDialog = ref(false);
</script>
