import { ref } from 'vue';
import { defineStore } from 'pinia';

const useUrlParamStore = defineStore('urlParamStore', () => {
  const paramsToUpdate = ref({} as { [key: string]: string });

  function $reset() {
    paramsToUpdate.value = {} as { [key: string]: string };
  }

  return { paramsToUpdate, $reset };
});

export default useUrlParamStore;
