<template>
  <Listbox
    :model-value="modelValue"
    :multiple="true"
    @update:model-value="(value) => emit('update:modelValue', value)"
  >
    <div class="relative">
      <ListboxButton
        v-slot="{ open }"
        class="w-full"
      >
        <div
          class="flex justify-between items-center p-2 w-full text-white bg-lk-light-gray hover:bg-lk-green rounded-md focus:outline-none"
          :class="{ 'bg-lk-green': open, }"
        >
          <span>
            {{ label }}
          </span>
          <span>
            <ArrowDownCircleIcon
              class="w-5 h-5 fill-white"
              :class="{ 'rotate-180': open }"
            />
          </span>
        </div>
      </ListboxButton>
      <ListboxOptions class="overflow-y-auto absolute z-20 py-1 mt-2 w-full max-h-96 text-white bg-lk-light-gray rounded-md focus:outline-none">
        <ListboxOption
          v-for="option in options"
          v-slot="{ active, selected }"
          :key="option[keyOption]"
          :value="option"
        >
          <li
            class="flex justify-between items-center py-2 px-3 text-sm cursor-pointer md:text-base"
            :class="{ 'bg-lk-green': active || selected, 'bg-lk-light-gray': !(active || selected) }"
          >
            <span>
              {{ option[optionLabel] }}
            </span>
            <span v-if="selected">
              <CheckIcon
                class="w-5 h-5 fill-white"
              />
            </span>
          </li>
        </ListboxOption>
      </ListboxOptions>
    </div>
  </Listbox>
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import { CheckIcon, ArrowDownCircleIcon } from '@heroicons/vue/24/solid';
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from '@headlessui/vue';

export type OptionRecord = Record<string | number, string | number>;

interface LokalSelectorProps {
  options: OptionRecord[],
  optionsLabel: string,
  optionLabel: string,
  keyOption: string,
  modelValue: OptionRecord[],
}

const props = withDefaults(defineProps<LokalSelectorProps>(), {
});

const emit = defineEmits<{(e: 'update:modelValue', value: OptionRecord): void }>();

const label = computed(() => props.optionsLabel);
</script>
