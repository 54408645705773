<template>
  <div class="flex flex-col items-center mb-10 w-full h-full md:px-5 md:max-w-7xl lg:px-10">
    <div class="flex items-center px-2 mb-3 w-full md:px-0">
      <p class="flex flex-col self-end text-xl text-gray-700 md:text-2xl lg:text-3xl">
        <span>Descuento para referidos</span>
      </p>
    </div>
    <div class="flex flex-col p-3 w-full h-full text-gray-700 bg-white rounded-md shadow-md md:py-5 md:px-4 lg:px-10">
      <p class="mb-1 text-lg md:mb-2 md:text-xl">
        Activa esta funcionalidad para ofrecer un descuento (incentivo) a tus clientes referidos.
      </p>
      <p class="mb-5 text-sm font-light md:mb-7 md:text-base">
        Con esta funcionalidad, tus clientes referidos recibirán un descuento en su primera compra. Lo
        que te permitirá atraer nuevos clientes.
      </p>
      <lokal-loading
        v-if="referredDiscountCodeQueryIsLoading"
        :loading="referredDiscountCodeQueryIsLoading"
      />
      <form
        v-else
        class="flex flex-col p-5 mb-1 max-w-sm rounded-md border md:mb-2"
        @submit="onSubmit"
      >
        <p class="mb-5 text-lg border-b">
          <span>{{ referredDiscountCode?.id ? 'Actualiza' : 'Define' }}</span>
          el descuento para referidos.
        </p>
        <template v-if="referredDiscountCode?.id">
          <p class="mb-1 text-sm font-light md:text-base">
            Código
          </p>
          <form-input
            v-model="referredDiscountCode.code"
            name="referedDiscountCode"
            class="mb-3"
            type="text"
            disabled
            label-name="Código de descuento referido"
            :rules="{ required: true }"
          />
          <p class="mb-1 text-sm font-light md:text-base">
            ¿Descuento activo?
          </p>
          <lokal-switch
            v-model="active"
            name="active"
            class="mb-3"
            @update:model-value="updateReferredDiscountCodeMutation.mutate()"
          />
        </template>
        <p class="mb-1 text-sm font-light md:text-base">
          Descuento en primera compra para referidos:
        </p>
        <div class="flex flex-col mb-3 font-light">
          <div class="flex items-center p-2 text-xs rounded-md border border-lk-light-gray focus:border-lk-green focus:outline-none md:text-sm">
            <Field
              v-model.number="makerDiscount"
              name="makerDiscount"
              type="number"
              label="Descuento en primera compra"
              :rules="{ required: true, min_value: 1 }"
              class="flex-1 mr-1 focus:outline-none"
            />
            <span>%</span>
          </div>
          <ErrorMessage
            name="makerDiscount"
            class="text-xs text-red-500 md:text-sm"
            as="p"
          />
        </div>
        <lokal-loading
          v-if="mutationIsLoading"
          class="self-center"
          :loading="mutationIsLoading"
        />
        <button
          v-else
          class="p-2 w-full text-sm text-lk-green hover:text-white hover:bg-lk-green rounded-md border border-lk-green md:text-base"
          type="submit"
        >
          <span>{{ referredDiscountCode?.id ? 'Actualizar' : 'Crear' }}</span>
          descuento para referidos
        </button>
      </form>
      <p class="mb-3 text-sm font-light md:mb-5 md:text-base">
        *Con esta funcionalidad se agrega un parámetro al url de referidos de tu tienda.
      </p>
      <maker-refer-link
        :maker="maker"
        :code="makerReferLinkCode"
        class="mb-1"
      />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { useForm, Field, ErrorMessage } from 'vee-validate';
import { useMutation, useQuery } from 'vue-query';
import useMessageStore from 'stores/message-store';
import discountCodesApi from 'api/discount-codes';
import formInput from './shared/form-input.vue';
import lokalSwitch from './shared/lokal-switch.vue';
import makerReferLink from './maker-refer-link.vue';

const props = defineProps<{
  maker: Maker;
}>();

const messageStore = useMessageStore();

const referredCode = computed(() => `RS${props.maker.id}`);
const referredDiscountCodeQuery = useQuery(
  ['referredDiscountCode', props.maker.id, referredCode],
  () => discountCodesApi.getByCode(props.maker.id, referredCode.value),
);
const referredDiscountCodeQueryIsLoading = computed(() => referredDiscountCodeQuery.isLoading.value);
const referredDiscountCode = computed(() => referredDiscountCodeQuery.data.value?.data?.discountCode as DiscountCode);

const makerDiscount = ref(referredDiscountCode.value?.makerDiscount || 0);
const active = ref(referredDiscountCode.value?.active || false);

watch(referredDiscountCode, (newValue, previousValue) => {
  if (newValue?.id && !previousValue) {
    makerDiscount.value = newValue.makerDiscount;
    active.value = newValue.active;
  }
});

const referredDiscountCodeMutation = useMutation(() =>
  discountCodesApi.createReferredShopsCode(props.maker.id, makerDiscount.value),
{
  onSuccess: () => {
    messageStore.showMessage('Descuento para referidos activado', 'success', 'short');
    referredDiscountCodeQuery.refetch.value();
  },
});
const referredDiscountCodeMutationIsLoading = computed(() => referredDiscountCodeMutation.isLoading.value);

const updateReferredDiscountCodeMutation = useMutation(() =>
  discountCodesApi.update(referredDiscountCode.value.id, { makerDiscount: makerDiscount.value, active: active.value }),
{
  onSuccess: () => {
    messageStore.showMessage('Descuento para referidos actualizado', 'success', 'short');
    referredDiscountCodeQuery.refetch.value();
  },
});
const updateReferredDiscountCodeMutationIsLoading = computed(() => updateReferredDiscountCodeMutation.isLoading.value);

const mutationIsLoading = computed(() => referredDiscountCodeMutationIsLoading.value ||
updateReferredDiscountCodeMutationIsLoading.value);

const { handleSubmit } = useForm();
const onSubmit = handleSubmit(() => {
  if (referredDiscountCode.value?.id) {
    updateReferredDiscountCodeMutation.mutate();
  } else {
    referredDiscountCodeMutation.mutate();
  }
});

const makerReferLinkCode = computed(() =>
  (referredDiscountCode.value?.active ? referredDiscountCode.value?.token : ''));
</script>
