import { onBeforeMount, provide, ref } from 'vue';

const SM_SCREEN = 640;
const MD_SCREEN = 768;
const LG_SCREEN = 1024;
const XL_SCREEN = 1280;

const isMobile = ref(window.innerWidth < SM_SCREEN);
const isTablet = ref(window.innerWidth >= SM_SCREEN && window.innerWidth < MD_SCREEN);
const isMobileOrTablet = ref(isMobile.value || isTablet.value);
const isMdScreen = ref(window.innerWidth >= MD_SCREEN && window.innerWidth < LG_SCREEN);
const isLgScreen = ref(window.innerWidth >= LG_SCREEN && window.innerWidth < XL_SCREEN);

function setIsMobile() {
  isMobile.value = window.innerWidth < SM_SCREEN;
}
function setIsTablet() {
  isTablet.value = window.innerWidth >= SM_SCREEN && window.innerWidth < MD_SCREEN;
}
function setIsMobileOrTablet() {
  isMobileOrTablet.value = isMobile.value || isTablet.value;
}
function setIsMdScreen() {
  isMdScreen.value = window.innerWidth >= MD_SCREEN && window.innerWidth < LG_SCREEN;
}
function setIsLgScreen() {
  isLgScreen.value = window.innerWidth >= LG_SCREEN && window.innerWidth < XL_SCREEN;
}

function setScreenSizes() {
  setIsMobile();
  setIsTablet();
  setIsMobileOrTablet();
  setIsMdScreen();
  setIsLgScreen();
}

export default function useScreenSizes() {
  onBeforeMount(() => {
    setScreenSizes();
    window.addEventListener('resize', setScreenSizes);
  });

  provide('isMobile', isMobile);
  provide('isTablet', isTablet);
  provide('isMobileOrTablet', isMobileOrTablet);
  provide('isMdScreen', isMdScreen);
  provide('isLgScreen', isLgScreen);
}
