<template>
  <div class="flex flex-col items-center mb-10 w-full h-full text-gray-700 md:px-5 lg:px-10 xl:max-w-7xl">
    <div class="flex items-center px-2 mb-3 w-full md:px-0">
      <p class="flex flex-col self-start text-xl text-gray-700 md:text-2xl lg:text-3xl">
        <span>Tutoriales</span>
      </p>
    </div>
    <div class="flex flex-col py-3 px-2 w-full h-full bg-white rounded-md md:py-5 md:px-4 lg:px-10">
      <lokal-loading
        v-if="isLoading"
        :loading="isLoading"
      />
      <div
        v-else
        class="grid grid-cols-2 gap-3 mb-5 last:mb-10 w-full md:grid-cols-3 md:gap-5"
      >
        <div
          v-for="tutorial in tutorials"
          :key="tutorial.id"
          class="flex flex-col"
        >
          <iframe
            width="w-full"
            :height="isMobile ? '100' : '150'"
            frameborder="0"
            :src="tutorial.url"
            :title="tutorial.title"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          />
          <div class="flex flex-col mb-2">
            <p class="mb-1 text-sm md:mb-0 md:text-base">
              {{ tutorial.title }}
            </p>
            <a
              v-if="tutorial.documentUrl"
              class="text-sm font-light hover:underline cursor-pointer md:text-base"
              rel="noopener"
              target="_blank"
              :href="tutorial.documentUrl"
            >
              Descargar pdf
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { inject, computed } from 'vue';
import { useQuery } from 'vue-query';
import tutorialsApi from 'api/tutorials';

const isMobile = inject('isMobile');
const { isLoading, data } = useQuery('tutorials', () => tutorialsApi.index());
const tutorials = computed(() => data.value?.data.tutorials as Tutorial[]);
</script>
