<template>
  <Menu v-slot="{ open }">
    <div class="relative">
      <MenuButton class="flex items-center space-x-1 text-sm md:space-x-2 md:text-base">
        <p v-if="mainLabelShowCondition">
          {{ mainLabel }}
        </p>
        <EllipsisVerticalIcon class="w-4 h-4 md:w-5 md:h-5" />
      </MenuButton>
      <transition
        enter-active-class="transition duration-150 ease-out"
        enter-from-class="transform scale-75 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-75 ease-out"
        leave-from-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-95 opacity-0"
      >
        <div
          v-if="open"
          :class="[arrowPositionClasses]"
          class="absolute z-10 border border-x-8 border-t-0 border-b-8 border-lk-green border-x-transparent border-solid"
        />
      </transition>
      <transition
        enter-active-class="transition duration-150 ease-out"
        enter-from-class="transform scale-75 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-75 ease-out"
        leave-from-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-95 opacity-0"
      >
        <MenuItems
          class="flex overflow-y-auto absolute right-0 z-10 flex-col py-0 mt-2 w-52 max-h-96 text-sm text-white rounded-md border border-lk-green divide-y divide-lk-green focus:outline-none origin-top-right"
        >
          <MenuItem
            v-for="label, index in labels"
            :key="`${label}-${index}`"
            v-slot="{ active }"
            class=""
            :class="{ 'text-lk-green bg-white': hrefs[index], 'text-gray-700 bg-lk-light-gray': !hrefs[index] }"
          >
            <a
              class="flex justify-between items-center p-2 space-x-2"
              :class="{ 'bg-lk-green text-white': active && hrefs[index], 'cursor-default': !hrefs[index] }"
              :href="hrefs[index]"
              target="_blank"
              noopener
            >
              <span>
                {{ label }}
              </span>
              <CheckCircleIcon
                v-if="hrefs[index]"
                class="w-4 h-4 font-semibold"
              />
              <ClockIcon
                v-if="!hrefs[index]"
                class="w-4 h-4"
              />
            </a>
          </MenuItem>
          <MenuItem
            v-for="prop, index in propComponents"
            v-slot="{ active }"
            :key="index"
            class="p-2"
          >
            <div
              class="flex justify-between items-center space-x-2"
              :class="{
                'font-semibold bg-lk-green-deep': active,
                'bg-lk-light-gray': !components[index], 'bg-lk-green': components[index]
              }"
            >
              <component
                :is="components[index]"
                v-bind="prop"
              />
              <CheckCircleIcon
                v-if="components[index]"
                class="w-4 h-4"
              />
              <ClockIcon
                v-if="!components[index]"
                class="w-4 h-4"
              />
            </div>
          </MenuItem>
        </MenuItems>
      </transition>
    </div>
  </Menu>
</template>
<script lang="ts" setup>
import { computed, type Component } from 'vue';
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue';
import { EllipsisVerticalIcon, ClockIcon, CheckCircleIcon } from '@heroicons/vue/24/outline';

interface LokalDropDownProps {
  labels: string[],
  hrefs?: (string | undefined)[]
  propComponents?: Record<string, unknown>[]
  components?: Component[]
  mainLabel: string
  mainLabelShowCondition: boolean
  arrowPositionOptions?: 'center' | 'left' | 'right' | ''
  arrowClasses?: string
}

const props = withDefaults(defineProps<LokalDropDownProps>(), {
  mainLabelShowCondition: true,
  hrefs: () => [] as (string | undefined)[],
  propComponents: () => [] as Record<string, unknown>[],
  components: () => [] as Component[],
  arrowPositionOptions: 'right',
  arrowClasses: '',
});

const arrowPositionClasses = computed(() => {
  if (props.arrowClasses) return props.arrowClasses;
  if (props.arrowPositionOptions === 'center') return 'left-1/2 -translate-x-1/2';
  if (props.arrowPositionOptions === 'left') return 'left-4';
  if (props.arrowPositionOptions === 'right') return 'right-4';

  return '';
});
</script>
