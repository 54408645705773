<template>
  <lokal-loading
    v-if="discountCodeQueryIsLoading"
    :loading="discountCodeQueryIsLoading"
  />
  <template v-else>
    <div
      v-if="!isMobile"
      class="grid place-items-center py-3 w-full text-center text-gray-700 rounded-md"
      :class="{
        'grid-cols-9 transition-all duration-300': internalShowDiscountDetails && !advancePaymenteFeePresent,
        'grid-cols-10 transition-all duration-300': internalShowDiscountDetails && advancePaymenteFeePresent,
        'grid-cols-7 transition-all duration-300': !internalShowDiscountDetails && !advancePaymenteFeePresent,
        'grid-cols-8 transition-all duration-300': !internalShowDiscountDetails && advancePaymenteFeePresent,
      }"
    >
      <img
        :src="subOrderProduct.mainImageUrl"
        class="object-cover mr-1 w-14 h-14 rounded-sm md:w-20 md:h-20"
      >
      <p class="overflow-hidden w-full text-left text-ellipsis">
        {{ subOrderProduct.productName }}
      </p>
      <p class="">
        <span>{{ useFormatCurrency(subOrderProduct.wholesalePriceCents) }}</span>
      </p>
      <transition
        enter-active-class="transform-all duration-300"
        enter-from-class="-translate-x-20 opacity-0"
        leave-active-class="transform-all duration-0"
        leave-to-class="-translate-x-20 opacity-0"
      >
        <div
          v-if="internalShowDiscountDetails"
          class="grid grid-cols-3 col-span-3 place-items-center w-full"
        >
          <p class="flex flex-col">
            <span v-if="subOrderProduct.makerDiscountAmountCents > 0">
              {{ useFormatCurrency(subOrderProduct.makerDiscountAmountCents) }}
            </span>
            <span v-else>-</span>
          </p>
          <p class="flex flex-col">
            <span v-if="volumeDiscountAmount > 0">
              {{ useFormatCurrency(round(volumeDiscountAmount)) }}
            </span>
            <span v-else>-</span>
          </p>
          <p class="flex flex-col">
            <span v-if="makerDiscountAmount > 0">
              {{ useFormatCurrency(round(makerDiscountAmount)) }}
            </span>
            <span v-else>-</span>
          </p>
        </div>
      </transition>
      <transition
        enter-active-class="transform-all duration-300"
        enter-from-class="-translate-x-20 opacity-0"
        leave-active-class="transform-all duration-0"
        leave-to-class="-translate-x-20 opacity-0"
      >
        <p v-if="!internalShowDiscountDetails">
          {{
            useFormatCurrency(
              subOrderProduct.makerDiscountAmountCents + round(volumeDiscountAmount) + round(makerDiscountAmount),
            )
          }}
        </p>
      </transition>
      <p class="flex flex-col">
        <span v-if="isNumber(subOrder.commission)">
          {{ useFormatCurrency(round(commissionAmount)) }}
        </span>
        <span v-else>Por confirmar</span>
      </p>
      <p
        v-if="advancePaymenteFeePresent"
        class="flex flex-col"
      >
        {{ useFormatCurrency(advancePaymentFeeAmount) }}
      </p>
      <p class="flex flex-col">
        <span v-if="isNumber(subOrder.commission)">
          {{ useFormatCurrency(round(amountToBill)) }}
        </span>
        <span v-else>Por confirmar</span>
      </p>
      <p class="">
        {{ subOrderProduct.quantity }}
      </p>
    </div>
    <div
      v-if="isMobile"
      class="flex flex-col space-y-2 w-full"
    >
      <div
        class="flex py-2 px-3 w-full text-xs text-gray-700 rounded-md border border-lk-light-gray"
      >
        <img
          :src="subOrderProduct.mainImageUrl"
          class="object-cover self-center mr-3 max-w-[5.5rem] rounded-sm"
        >
        <div class="flex flex-col w-full">
          <p class="w-full font-semibold text-left">
            {{ subOrderProduct.productName }}
          </p>
          <p class="flex justify-between">
            <span>Precio mayorista neto:</span>
            <span>{{ useFormatCurrency(subOrderProduct.wholesalePriceCents) }}</span>
          </p>
          <button
            class="flex justify-between"
            @click="internalShowDiscountDetails = !internalShowDiscountDetails"
          >
            <div
              class="flex items-center"
            >
              <span class="mr-1">Total descuentos:</span>
              <ChevronDownIcon
                class="w-3 h-3 cursor-pointer"
                :class="{ 'rotate-180 tranform-all duration-200': internalShowDiscountDetails,
                          'tranform-all duration-200': !internalShowDiscountDetails }"
              />
            </div>
            <span>
              {{
                useFormatCurrency(
                  subOrderProduct.makerDiscountAmountCents + round(volumeDiscountAmount) + round(makerDiscountAmount),
                )
              }}
            </span>
          </button>
          <transition
            enter-active-class="transform-all duration-200 ease-linear"
            enter-from-class="-translate-y-5 opacity-0"
            leave-active-class="transform-all duration-200 ease-linear"
            leave-to-class="-translate"
          >
            <div
              v-if="internalShowDiscountDetails"
              class="flex flex-col"
            >
              <p class="flex justify-between ml-2">
                <span>- Descuento {{ subOrder.makerName }}:</span>
                <span v-if="subOrderProduct.makerDiscountAmountCents > 0">
                  {{ useFormatCurrency(subOrderProduct.makerDiscountAmountCents) }}
                </span>
                <span v-else>-</span>
              </p>
              <p
                v-if="volumeDiscountAmount > 0"
                class="flex justify-between ml-2"
              >
                <span>- Descuento x volumen ({{ subOrder.volumeDiscountValue }}%):</span>
                <span>
                  {{ useFormatCurrency(round(volumeDiscountAmount)) }}
                </span>
              </p>
              <p
                v-if="makerDiscountAmount > 0"
                class="flex justify-between ml-2"
              >
                <span>- Descuento x código ({{ discountCode.makerDiscount }}%):</span>
                <span>
                  {{ useFormatCurrency(round(makerDiscountAmount)) }}
                </span>
              </p>
            </div>
          </transition>
          <p
            v-if="isNumber(subOrder.commission)"
            class="flex justify-between"
          >
            <span>Comisión ({{ subOrder.commission }}%):</span>
            <span>
              {{ useFormatCurrency(round(commissionAmount)) }}
            </span>
          </p>
          <p
            v-else
            class="flex justify-between"
          >
            <span>Comisión:</span>
            <span>Por confirmar</span>
          </p>
          <p
            v-if="advancePaymenteFeePresent"
            class="flex justify-between"
          >
            <span>Costo pago anticipado:</span>
            <span>{{ useFormatCurrency(advancePaymentFeeAmount) }}</span>
          </p>
          <p
            v-if="isNumber(subOrder.commission)"
            class="flex justify-between"
          >
            <span>Monto a facturar (s/IVA):</span>
            <span>{{ useFormatCurrency(round(amountToBill)) }}</span>
          </p>
          <p
            v-else
            class="flex justify-between"
          >
            <span>Monto a facturar (s/IVA):</span>
            <span class="shrink-0">Por confirmar</span>
          </p>
          <p class="flex justify-between">
            <span>Cantidad:</span>
            <span>{{ subOrderProduct.quantity }}</span>
          </p>
        </div>
      </div>
    </div>
  </template>
</template>
<script lang="ts" setup>
import { ref, computed, inject, watch } from 'vue';
import { isNumber, round } from 'lodash';
import { ChevronDownIcon } from '@heroicons/vue/24/solid';
import useFormatCurrency from './use/format-currency';
import { billToolsKey, type BillTools } from '../symbols/';
import useDiscountCodeQuery from './queries/discount-code-query';

interface SubOrderShowSubOrderProductCardProps {
  subOrder: SubOrder,
  subOrderProduct: SubOrderProduct,
  showDiscountDetails?: boolean,
  advancePaymenteFeePresent?: boolean,
}

const props = withDefaults(defineProps<SubOrderShowSubOrderProductCardProps>(), {
  showDiscountDetails: false,
  advancePaymenteFeePresent: false,
});

const isMobile = inject('isMobile');
const billTools = inject(billToolsKey) as BillTools;

const internalShowDiscountDetails = ref(props.showDiscountDetails);

watch(() => props.showDiscountDetails, (newValue) => {
  internalShowDiscountDetails.value = newValue;
});

const computedDiscountCodeId = computed(() => props.subOrder.discountCodeId);
const discountCodeQueryEnabled = computed(() => !!props.subOrder.discountCodeId);
const { discountCode, discountCodeQueryIsLoading } = useDiscountCodeQuery(computedDiscountCodeId,
  discountCodeQueryEnabled);

const commisionFraction = computed(() => props.subOrder.commission / billTools.totalPorcentage);
const volumeDiscountFraction = computed(() => (props.subOrder.volumeDiscountValue || 0) / billTools.totalPorcentage);

const discountCodeFraction = computed(() => discountCode.value?.makerDiscount / billTools.totalPorcentage);

const volumeDiscountAmount = computed(() => {
  const completeDiscountedPrice = props.subOrderProduct.wholesalePriceCents -
    props.subOrderProduct.completeDiscountAmountCents;

  return round(completeDiscountedPrice * volumeDiscountFraction.value) || 0;
});
const makerDiscountAmount = computed(() => {
  const substractedPrice = props.subOrderProduct.wholesalePriceCents -
    props.subOrderProduct.completeDiscountAmountCents - volumeDiscountAmount.value;

  return round(substractedPrice * discountCodeFraction.value) || 0;
});
const commissionAmount = computed(() => {
  const substractedPrice = props.subOrderProduct.makerDiscountedAmountCents - volumeDiscountAmount.value -
    makerDiscountAmount.value;

  return substractedPrice * commisionFraction.value;
});
const advancePaymentFeeAmount = computed(() => {
  if (!props.advancePaymenteFeePresent) return 0;

  const base = props.subOrderProduct.makerDiscountedAmountCents - volumeDiscountAmount.value -
    makerDiscountAmount.value - commissionAmount.value;

  return round(base * props.subOrder.advancePaymentFeeValue / billTools.totalPorcentage);
});
const amountToBill = computed(() =>
  props.subOrderProduct.makerDiscountedAmountCents - volumeDiscountAmount.value - makerDiscountAmount.value -
    commissionAmount.value - advancePaymentFeeAmount.value);

</script>
