<template>
  <Dialog
    :open="modelValue"
    as="div"
    class="relative z-50"
    @close="emit('update:modelValue', false)"
  >
    <div
      class="fixed inset-0 bg-black/30"
      aria-hidden="true"
    />
    <div class="flex fixed inset-0 justify-center items-center p-2 text-gray-700">
      <DialogPanel class="flex flex-col p-3 w-full max-w-md bg-gray-50 rounded md:p-5">
        <button
          class="mb-1 ml-auto focus:outline-none"
          @click="emit('update:modelValue', false)"
        >
          <XMarkIcon class="w-5 h-5 md:w-6 md:h-6" />
        </button>
        <DialogTitle class="mb-3 w-full font-serif text-2xl text-center md:text-3xl">
          {{ title }}
        </DialogTitle>
        <DialogDescription
          v-if="description"
          class="flex items-center mb-5 space-x-2 text-sm text-gray-700 md:text-base"
        >
          <img
            v-if="src && src.length > 0"
            :src="src"
            class="object-cover w-20 h-20 rounded-md md:mr-5 md:w-32 md:h-32"
          >
          <span>{{ description }}</span>
        </DialogDescription>
        <lokal-loading
          v-if="isLoading"
          :loading="isLoading"
          class="self-center"
        />
        <div
          v-else
          class="flex flex-col items-center w-full"
        >
          <button
            v-if="confirmActionText"
            class="p-2 mb-2 w-full text-sm rounded-md border  md:text-base"
            :class="{ 'text-lk-green hover:text-white border-lk-green hover:bg-lk-green': positiveAction,
                      'text-red-500 hover:text-white border-red-500 hover:bg-red-500': !positiveAction }"
            @click.once="emitEventAndChangeDialog(true)"
          >
            {{ confirmActionText }}
          </button>
          <button
            v-if="rejectActionText"
            class="text-xs hover:underline md:text-sm"
            @click="emit('update:modelValue', false)"
          >
            {{ rejectActionText }}
          </button>
        </div>
      </DialogPanel>
    </div>
  </Dialog>
</template>
<script setup lang="ts">
import { ref } from 'vue';
import { XMarkIcon } from '@heroicons/vue/24/solid';
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  DialogDescription,
} from '@headlessui/vue';

interface LokalConfirmationDialogProp {
  title: string,
  description?: string,
  confirmActionText?: string
  rejectActionText?: string
  eventName: string
  modelValue: boolean
  positiveAction?: boolean
  src?: string
}
const props = withDefaults(defineProps<LokalConfirmationDialogProp>(), {
  modelValue: false,
  description: '',
  confirmActionText: 'Confirmar',
  rejectActionText: 'Cancelar',
  positiveAction: true,
  src: '',
});

const emit = defineEmits<{(e: LokalConfirmationDialogProp['eventName'], value: boolean): void,
  (e: 'update:modelValue', value: boolean): void
}>();

const isLoading = ref(false);
function emitEventAndChangeDialog(value: boolean) {
  isLoading.value = true;
  emit(props.eventName, value);
  emit('update:modelValue', false);
}
</script>
