<template>
  <div class="flex flex-col h-full">
    <sub-order-shipment-reminder
      v-if="!reminderChecked"
      v-model="reminderChecked"
      :sub-order="props.subOrder"
    />
    <sub-order-pickup-reminder
      v-else-if="showPickupReminder"
      v-model="pickupReminderChecked"
    />
    <template v-else>
      <p class="mb-1 font-serif text-xl text-center text-gray-700 md:text-2xl">
        Completa la siguiente información
      </p>
      <lokal-loading
        v-if="isLoading"
        class="self-center"
        :loading="isLoading"
      />
      <form
        v-else
        class="flex flex-col mb-5 w-full text-sm text-gray-700 md:text-base"
        @submit="onSubmit"
      >
        <p class="mb-5 text-sm font-light text-center text-gray-700 md:text-base">
          Debes ingresar las medidas y el peso del paquete
        </p>
        <lokal-tooltip
          v-if="!creatingNewAddress"
          main-label="Dirección de origen"
          class="mb-1"
          button-classes="flex space-x-1 items-center"
          :information="addressInformation"
        />
        <address-selector
          v-model="shippingAddress"
          :addressable-id="maker.id"
          addressable-type="Maker"
          :default-address-id="maker.defaultAddressId"
          class="col-span-2 mb-0"
          :new-address-with-dialog="false"
          @creating-address="creatingNewAddress = true"
          @address-created="creatingNewAddress = false"
        />
        <p class="mb-5 text-xs font-light md:text-sm">
          *Dirección de devolución en caso de que el carrier no pueda entregar.
        </p>
        <div
          v-if="!creatingNewAddress"
          class="grid md:grid-cols-2 md:gap-x-2"
        >
          <p class="font-semibold">
            Selecciona un carrier:
          </p>
          <div class="flex flex-col">
            <Field
              v-model="selectedCarrierId"
              name="carrierRecord"
              label="carrier"
              hidden
              :rules="{ required: true }"
            />
            <lokal-generic-combobox
              v-model="selectedCarrier"
              :options="carriers"
              :get-option-key="(carrier: Carrier) => carrier.id"
              :get-option-label="(carrier: Carrier) => carrier.name"
              options-label="Selecciona un carrier"
              :disabled="!!selectedShippingQuotation?.carrierName"
              class="mb-2"
            />
            <ErrorMessage
              name="carrierRecord"
              class="text-sm text-red-500"
            />
          </div>
          <p class="font-semibold">
            Número de paquetes:
          </p>
          <form-input
            v-model.number="form.nPackages"
            class="mb-1"
            placeholder="Número de paquetes"
            type="number"
            name="nPackages"
            label-name="Número de paquetes"
            :rules="{ required: true, min_value: 1 }"
          />
          <p class="font-semibold">
            Peso (Kg):
          </p>
          <form-input
            v-model.number="form.weight"
            class="mb-1"
            placeholder="Peso (Kg)"
            type="float"
            name="weight"
            label-name="Peso (Kg)"
            :rules="{ required: true, greaterOrEqualThan: [0.01], max_value: [60] }"
          />
          <p class="font-semibold">
            Altura (cm):
          </p>
          <form-input
            v-model="form.height"
            class="mb-1"
            placeholder="Altura (cm)"
            type="number"
            name="height"
            label-name="Altura (cm)"
            :rules="{ required: true, min_value: '1' }"
          />
          <p class="font-semibold">
            Ancho (cm):
          </p>
          <form-input
            v-model="form.width"
            class="mb-1"
            placeholder="Ancho (cm)"
            type="number"
            name="width"
            label-name="Ancho (cm)"
            :rules="{ required: true, min_value: '1' }"
          />
          <p class="font-semibold">
            Largo (cm):
          </p>
          <form-input
            v-model="form.length"
            class="mb-1"
            placeholder="Largo (cm)"
            type="number"
            name="length"
            label-name="Largo (cm)"
            :rules="{ required: true, min_value: '1' }"
          />
          <p class="font-semibold">
            Volumen (m^3):
          </p>
          <form-input
            v-model="volume"
            disabled
            class="mb-1"
            placeholder="Volumen (m^3)"
            type="text"
            name="volume"
            label-name="Volume (m^3)"
            :rules="{ required: true }"
          />
          <p class="font-semibold">
            Descripción del contenido:
          </p>
          <form-input
            v-model="form.contentDescription"
            class="mb-1"
            placeholder="Descripción"
            type="text"
            name="description"
            label-name="Descripción"
            :rules="{ required: true }"
          />
          <lokal-tooltip
            v-if="!creatingNewAddress"
            main-label="Comentario"
            class="mb-1"
            button-classes="flex space-x-1 items-center"
            position-classes="w-72"
            :information="commentInformation"
          />
          <textarea
            v-model="form.comment"
            class="col-span-2 p-2 mb-3 text-sm rounded-md border"
          />
          <lokal-loading
            v-if="mutationsAreLoading"
            class="col-span-2 self-center"
            :loading="mutationsAreLoading"
          />
          <button
            v-else
            class="col-span-2 place-self-center p-2 mb-1 w-full text-lk-green hover:text-white bg-white hover:bg-lk-green rounded-md border border-lk-green"
            type="submit"
          >
            Confirmar
          </button>
        </div>
      </form>
    </template>
  </div>
</template>
<script setup lang="ts">
import { useForm, Field, ErrorMessage } from 'vee-validate';
import { ref, computed, watch } from 'vue';
import subOrdersApi from 'api/sub-orders';
import { useMutation } from 'vue-query';
import { round, sum } from 'lodash';
import shipmentDetailsApi from '../api/shipment-details';
import formInput from './shared/form-input.vue';
import LokalGenericCombobox from './shared/lokal-generic-combobox.vue';
import lokalLoading from './shared/lokal-loading.vue';
import addressSelector from './address-selector.vue';
import lokalTooltip from './shared/lokal-tooltip.vue';
import useCarriersQuery from './queries/carriers-query';
import SubOrderShipmentReminder from './sub-order-shipment-reminder.vue';
import useShippingQuotationsQuery from './queries/shipping-quotations-query';
import useMakerShippingQuotations from './use/maker-shipping-quotations';
import useOrderAddressQuery from './queries/order-address-query';
import subOrderPickupReminder from './sub-order-pickup-reminder.vue';

const TRANSFORM_TO_CUBIC_METERS = 1000000;
const DECIMALS = 2;

interface SubOrderShipmentDialogFormProps {
  modelValue: boolean,
  maker: Maker,
  subOrder: SubOrder,
  subOrderProducts: SubOrderProduct[]
}
const props = withDefaults(defineProps<SubOrderShipmentDialogFormProps>(), {
});

const emit = defineEmits<{(e: 'update:modelValue', value: boolean): void,
  (e: 'update:subOrder'): void,
  (e: 'update:is-open', value: boolean): void,
}>();

const reminderChecked = ref(false);
const pickupReminderChecked = ref(false);
const showPickupReminder = ref(false);

const carriersQueryParams = computed(() =>
  `q[maker_carriers_maker_id_eq]=${props.maker.id}&q[maker_carriers_active_eq]=true`);
const carriersQueryEnabled = computed(() =>
  ['pending', 'confirmed'].includes(props.subOrder.status) && !props.subOrder.labelDocumentUrl);
const { carriers, carriersQuery } = useCarriersQuery(carriersQueryParams, carriersQueryEnabled);

const shippingAddress = ref({} as Address);
const shippingAddressId = computed(() => shippingAddress.value.id);

function weightToKilograms(subOrderProduct: SubOrderProduct) {
  const GRAMS_TO_KILOGRAMS = 1000;
  if (subOrderProduct.product.weightUnit === 'kilograms') {
    return subOrderProduct.product.weight * subOrderProduct.quantity;
  }

  return (subOrderProduct.product.weight * subOrderProduct.quantity) / GRAMS_TO_KILOGRAMS;
}

const computedWeight = computed(() => round(sum(props.subOrderProducts.map(
  (subOrderProduct) => weightToKilograms(subOrderProduct))), DECIMALS));

const form = ref({
  nPackages: 0, weight: computedWeight.value, height: 0, width: 0, length: 0, contentDescription: '', comment: '',
  carrierId: 0 });

const volume = computed(() => {
  const { height, width, length } = form.value;

  return (height * width * length) / TRANSFORM_TO_CUBIC_METERS;
});

const selectedCarrier = ref({} as Carrier);
const selectedCarrierId = computed(() => selectedCarrier.value.id);
const creatingNewAddress = ref(false);
const addressInformation = ref(`
  La dirección de envío es la dirección donde se devolverá el paquete en caso de que el carrier no pueda entregarlo.
  Si no tienes una dirección creada, puedes crear una nueva.
`);
const commentInformation = ref(`
  El comentario es un campo opcional donde puedes agregar información adicional sobre el envío. Esta
  información no se publica con la marca y es solo para uso interno.
`);

const subOrderMutation = useMutation(async () => {
  const subOrderData = { addressId: shippingAddressId.value, comment: form.value.comment };

  return subOrdersApi.update(props.subOrder.id, subOrderData);
}, {
  onSuccess: () => {
    emit('update:subOrder');
    if (props.maker.warehousePickupCondition === 'with_pickup') {
      showPickupReminder.value = true;
    } else {
      emit('update:is-open', false);
    }
  },
});
const subOrderMutationIsLoading = computed(() => subOrderMutation.isLoading.value);

const shipmentDetailMutation = useMutation(() => {
  const data = { subOrderId: props.subOrder.id, volume: volume.value, ...form.value };

  return shipmentDetailsApi.create(data);
}, {
  onSuccess: () => {
    subOrderMutation.mutate();
  },
});
const mutationsAreLoading = computed(() => carriersQuery.isLoading.value || subOrderMutationIsLoading.value);

const { handleSubmit } = useForm();

const onSubmit = handleSubmit(() => {
  shipmentDetailMutation.mutate();
});

const { orderAddress, orderAddressIsLoading } = useOrderAddressQuery(computed(() => props.subOrder.orderId));
const originCommune = computed(() => shippingAddress.value.communeName);
const destinationCommune = computed(() => orderAddress.value?.communeName);
const enabledShippingQuotationsQuery = computed(() => !!destinationCommune.value);
const { shippingQuotationsQuery, sortedShippingQuotations } = useShippingQuotationsQuery(
  originCommune, destinationCommune, computedWeight, enabledShippingQuotationsQuery,
);
const carrierCodes = computed(() => carriers.value?.map((carrier: Carrier) => carrier.code));
const { selectedShippingQuotation } = useMakerShippingQuotations(sortedShippingQuotations, carrierCodes);
const isLoading = computed(() => carriersQuery.isLoading.value || orderAddressIsLoading.value ||
  shippingQuotationsQuery.isLoading.value);

watch(computedWeight, () => {
  form.value.weight = computedWeight.value;
});

watch((selectedShippingQuotation), () => {
  if (selectedShippingQuotation.value?.carrierName) {
    const internalSelectedCarrier = carriers.value?.find((carrier) =>
      carrier.code === selectedShippingQuotation.value?.carrierName);
    if (internalSelectedCarrier?.id) {
      selectedCarrier.value = internalSelectedCarrier;
    }
  }
});

watch(selectedCarrier, () => {
  form.value.carrierId = selectedCarrier.value?.id;
});

watch(pickupReminderChecked, () => {
  if (pickupReminderChecked.value) {
    emit('update:is-open', false);
  }
});
</script>
