<template>
  <div class="flex flex-col">
    <div
      v-if="shopId"
      class="mb-2 text-sm rounded-md border divide-y md:text-base"
    >
      <div class="flex justify-between items-center p-3 text-base bg-gray-50 rounded-t-md md:p-5 md:text-lg">
        <p>Dirección de despacho</p>
        <button
          v-if="addresses?.length && addresses?.length > 0"
          class="text-sm hover:underline focus:outline-none"
          type="button"
          @click="showEditAddressDialog = !showEditAddressDialog"
        >
          Cambiar
        </button>
      </div>
      <div class="p-3 text-sm md:p-5 md:text-base">
        <lokal-loading
          v-if="isLoading"
          :loading="isLoading"
        />
        <template v-else-if="selectedAddress?.id">
          <p class="flex flex-col mb-2">
            <span class="font-semibold capitalize">{{ selectedAddress.nameReference }}</span>
            <span>{{ selectedAddress.address }}</span>
            <span>{{ selectedAddress.communeName }}, {{ selectedAddress.regionName }}</span>
            <span
              v-if="selectedAddress.observations"
              class="font-light"
            >
              Observaciones: {{ selectedAddress.observations }}
            </span>
          </p>
          <button
            v-if="!selectedAddressConfirmed"
            class="p-2 w-full text-white bg-lk-green hover:bg-lk-green-deep rounded-md"
            @click.prevent.stop="emit('update:selectedAddressConfirmed', true)"
          >
            Confirmar dirección de despacho
          </button>
        </template>
        <p
          v-else
        >
          Debes agregar una dirección.
        </p>
      </div>
      <address-new-dialog
        v-if="!!shop && showNewAddressDialog"
        v-model="showNewAddressDialog"
        :addressable="shop"
        :addressable-id="shop.id"
        addressable-type="Shop"
        :initial-region-id="initialRegionId"
        :initial-commune-id="initialCommuneId"
        @address-created="manageAddressCreated"
      />
      <order-checkout-addresses-edit-dialog
        v-if="!!shop && showEditAddressDialog"
        v-model="showEditAddressDialog"
        :addressable-id="shop.id"
        addressable-type="Shop"
        :addressable="shop"
        @address-selected="manageAddressCreated"
      />
    </div>
    <button
      v-if="!addresses?.length"
      class="flex justify-center items-center p-2 text-white bg-lk-green hover:bg-lk-green-deep rounded-md"
      type="button"
      @click="showNewAddressDialog = !showNewAddressDialog"
    >
      <PlusIcon class="shrink-0 mr-1 w-4 h-4" />
      <span>Agregar dirección</span>
    </button>
  </div>
</template>
<script lang="ts" setup>
import { ref, computed, watch, onMounted } from 'vue';
import { PlusIcon } from '@heroicons/vue/24/outline';
import AddressNewDialog from './address-new-dialog.vue';
import useAddressesQuery from './queries/addresses-query';
import useShopQuery from './queries/shop-query';
import OrderCheckoutAddressesEditDialog from './order-checkout-addresses-edit-dialog.vue';

const props = defineProps<{
  shopId: number
  modelValue: Address
  selectedAddressConfirmed: boolean
}>();

const computedShopId = computed(() => props.shopId);
const { shop } = useShopQuery(computed(() => props.shopId));
const { addresses, addressesQuery } = useAddressesQuery(computedShopId, computed(() => 'Shop'),
  computed(() => !!shop.value?.id));
const isLoading = computed(() => addressesQuery.isLoading.value);
const selectedAddress = ref({} as Address);
const initialRegionId = computed(() => {
  if (addresses.value?.length && addresses.value?.length > 0) return undefined;

  return shop.value?.referenceRegionId;
});
const initialCommuneId = computed(() => {
  if (addresses.value?.length && addresses.value?.length > 0) return undefined;

  return shop.value?.referenceCommuneId;
});

function setSelectedAddress() {
  if (shop.value?.defaultAddressId) {
    const searchedAddress = addresses.value?.find((address) => address.id === shop.value?.defaultAddressId);
    if (!!searchedAddress) {
      selectedAddress.value = searchedAddress;
    }
  } else if (!selectedAddress.value?.id && addresses.value?.length && addresses.value?.length > 0) {
    selectedAddress.value = addresses.value[0];
  }
}

watch(addresses, setSelectedAddress);
onMounted(setSelectedAddress);

const showNewAddressDialog = ref(false);
const showEditAddressDialog = ref(false);

async function manageAddressCreated(addressId: number) {
  await addressesQuery.refetch.value();
  const searchedAddress = addresses.value?.find((address) => address.id === addressId) || null;
  if (!!searchedAddress) {
    selectedAddress.value = searchedAddress;
  }
}

const emit = defineEmits<{(e: 'update:modelValue', value: Address): void,
  (e: 'update:selectedAddressConfirmed', value: boolean): void}>();
watch(selectedAddress, (newSelectedAddress) => {
  emit('update:modelValue', newSelectedAddress);
});
</script>
