import { computed, type ComputedRef } from 'vue';
import { useQuery } from 'vue-query';
import subOrdersApi from 'api/sub-orders';

function useDestinationAddressesQuery(
  makerId: ComputedRef<number>, enabled: ComputedRef<boolean>, queryParams: ComputedRef<string>,
) {
  const destinationAddressesQuery = useQuery(['destination-addresses', makerId, queryParams], () =>
    subOrdersApi.destinationAddresses(makerId.value, queryParams.value), { enabled });
  const destionationAddressesLoading = computed(() => destinationAddressesQuery.isLoading.value);
  const destinationAddresses = computed(() =>
  destinationAddressesQuery.data.value?.data.addresses as Address[]);

  return { destinationAddressesQuery, destinationAddresses, destionationAddressesLoading };
}

export default useDestinationAddressesQuery;
