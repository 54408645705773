<template>
  <div class="flex flex-col items-center py-8 px-2 w-full h-full bg-lk-light-gray md:py-12 md:pr-0 md:pl-[128px] xl:pr-0">
    <div class="flex flex-col items-center py-5 px-3 w-full max-w-6xl h-full text-gray-700 bg-white rounded-md shadow-sm md:p-8">
      <h1 class="mb-5 w-full font-serif text-xl text-left sm:text-2xl md:mb-12 lg:text-3xl">
        Preguntas frecuentes
      </h1>
      <TabGroup>
        <TabList class="flex self-start mb-5 space-x-2 w-full bg-white rounded-md">
          <Tab
            v-for="option in ['Tiendas', 'Marcas']"
            :key="option"
            v-slot="{ selected }"
            class="focus:outline-none"
          >
            <button
              class="p-2 focus:outline-none"
              :class="{ 'border-b-2 border-lk-green': selected }"
            >
              {{ option }}
            </button>
          </Tab>
        </TabList>
        <TabPanels class="w-full">
          <TabPanel
            v-for="
              selectedFrequentQuestions, index in [frequentQuestionsGroupedByShop, frequentQuestionsGroupedByMaker]
            "
            :key="index"
            class="w-full"
          >
            <Disclosure
              v-for="frequentQuestion in selectedFrequentQuestions"
              v-slot="{ open }"
              :key="frequentQuestion.id"
              as="div"
              class="flex flex-col py-2 mb-2 w-full bg-white rounded-md border border-lk-light-gray"
            >
              <DisclosureButton
                class="flex justify-between py-1 px-4 w-full text-sm text-left rounded-md md:py-2 md:text-base"
                :open="{ 'font-semibold': open }"
              >
                <span>
                  {{ frequentQuestion.question }}
                </span>
                <ChevronUpIcon
                  class="w-5 h-5 text-lk-light-gray"
                  :class="{ 'transform rotate-180 duration-150': open, 'transform duration-150': !open }"
                />
              </DisclosureButton>
              <transition
                enter-active-class="transition duration-150"
                enter-from-class="-translate-y-2 opacity-50"
                enter-to-class="translate-y-0 transform opacity-100"
                leave-active-class="transition duration-150"
                leave-from-class="translate-y-0"
                leave-to-class="-translate-y-2 transform opacity-0"
              >
                <DisclosurePanel
                  class="py-2 px-4 w-full text-sm text-gray-700 md:text-base"
                  v-html="frequentQuestion.answer"
                />
              </transition>
            </Disclosure>
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import {
  Disclosure, DisclosureButton, DisclosurePanel, TabGroup, TabList, Tab, TabPanels, TabPanel,
} from '@headlessui/vue';
import { ChevronUpIcon } from '@heroicons/vue/24/solid';
import { orderBy, groupBy } from 'lodash';

const props = defineProps<{
  frequentQuestions: FrequentQuestion[],
}>();

const frequentQuestionsGroupedByShop = computed(() => {
  const questions = props.frequentQuestions.filter((frequentQuestion) => frequentQuestion.kind === 'shop');

  return orderBy(questions, 'priority', 'asc');
});
const frequentQuestionsGroupedByMaker = computed(() => {
  const questions = props.frequentQuestions.filter((frequentQuestion) => frequentQuestion.kind === 'maker');

  return orderBy(questions, 'priority', 'asc');
});
</script>
