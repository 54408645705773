import { computed } from 'vue';
import { useQuery } from 'vue-query';
import communesApi from 'api/communes';

export default function useCommunesQuery(queryParams = computed(() => ''), enabled = computed(() => true)) {
  const communesQuery = useQuery(['communes', queryParams], () => communesApi.index(queryParams.value), { enabled });
  const communes = computed(() => communesQuery.data.value?.data.communes as Commune[]);

  return { communes, communesQuery };
}
