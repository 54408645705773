import { computed, type ComputedRef } from 'vue';
import { useQuery } from 'vue-query';
import makersApi from 'api/makers';

export default function useMakerQuery(makerId: ComputedRef<number>, enabled?: ComputedRef<boolean>) {
  const internalEnabled = computed(() => !!makerId.value);
  const makerQuery = useQuery(['maker', makerId],
    () => makersApi.get(makerId.value), { enabled: enabled || internalEnabled });
  const maker = computed(() => makerQuery.data.value?.data.maker as Maker);

  return { maker, makerQuery };
}
