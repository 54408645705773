<template>
  <lokal-multiple-selector
    v-if="multiple"
    v-model="selectedArray"
    :options="options"
    :options-label="optionsLabel"
    :option-label="optionLabel"
    :key-option="keyOption"
  />
  <lokal-single-selector
    v-else
    v-model="selectedOption"
    :options="options"
    :options-label="optionsLabel"
    :option-label="optionLabel"
    :key-option="keyOption"
  />
</template>
<script lang="ts" setup>
import { ref, watch } from 'vue';
import LokalMultipleSelector from './lokal-multiple-selector.vue';
import LokalSingleSelector from './lokal-single-selector.vue';

export type OptionRecord = Record<string | number, string | number>;

interface LokalSelectorProps {
  options: OptionRecord[],
  optionsLabel: string,
  optionLabel: string,
  keyOption: string,
  multiple?: boolean,
  modelValue: OptionRecord | OptionRecord[],
}

withDefaults(defineProps<LokalSelectorProps>(), {
  multiple: true,
});

const emit = defineEmits<{(e: 'update:modelValue', value: OptionRecord | OptionRecord[]): void }>();

const selectedArray = ref([] as OptionRecord[]);
const selectedOption = ref({} as OptionRecord);

watch(selectedArray, (newSelectedArray) => {
  emit('update:modelValue', newSelectedArray);
});

watch(selectedOption, (newSelectedOption) => {
  emit('update:modelValue', newSelectedOption);
});
</script>
