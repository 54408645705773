<template>
  <div class="flex flex-col">
    <input
      v-bind="$attrs"
      :value="inputValue"
      class="p-2 mb-0 text-xs rounded-md border border-lk-light-gray focus:border-lk-green focus:outline-none md:text-sm"
      :class="{ 'border-red-500': !meta.valid && meta.touched, 'bg-gray-100 opacity-50': disabled }"
      :name="name"
      :disabled="disabled"
      @change="handleChange"
    >
    <ErrorMessage
      v-if="showErrors && customErrorMessage.length === 0"
      :name="name"
      class="text-xs text-red-500 md:text-sm"
    />
    <ErrorMessage
      v-else-if="showErrors"
      :name="name"
      class="text-xs text-red-500 md:text-sm"
      as="p"
    >
      {{ customErrorMessage }}
    </ErrorMessage>
  </div>
</template>
<script lang="ts" setup>
import { useField, ErrorMessage } from 'vee-validate';
import { onMounted, watch } from 'vue';
import { rutFormat } from 'rut-helpers';

interface Props {
  name: string,
  customErrorMessage?: string,
  rules: Record<string, boolean | string | number | (string | number | undefined)[]>,
  modelValue: string | number,
  labelName?: string,
  showErrors?: boolean,
  disabled?: boolean,
}

const props = withDefaults(defineProps<Props>(), {
  customErrorMessage: '',
  showErrors: true,
  labelName: '',
  disabled: false,
});

const { value: inputValue, meta, handleChange } = useField(props.name, props.rules, {
  initialValue: props.modelValue,
  label: props.labelName || props.name,
});

const emit = defineEmits<{(e: 'update:modelValue', value: number | string): void}>();
watch(inputValue, (newValue) => {
  if (newValue === props.modelValue) return;

  if (props.rules.rut) {
    inputValue.value = rutFormat(`${newValue}`);
    emit('update:modelValue', rutFormat(`${newValue}`));
  } else {
    emit('update:modelValue', newValue);
  }
});

watch(() => props.modelValue, (newModelValue) => {
  if (newModelValue === inputValue.value) return;

  inputValue.value = newModelValue;
});

onMounted(() => {
  if (props.rules.rut) {
    inputValue.value = rutFormat(`${inputValue.value}`);
    emit('update:modelValue', rutFormat(`${inputValue.value}`));
  }
});
</script>
