import { csrfToken } from '@rails/ujs';
import { ref } from 'vue';
import { serialize } from 'object-to-formdata';
import api from './index';

const token = ref(csrfToken());
let headers = {};
if (token.value) {
  headers = { 'X-CSRF-Token': token.value };
}

export default {
  bulkCreate(documentsPartialData: Partial<Document>[]) {
    const path = '/api/internal/documents/bulk_create';
    const serializeData = serialize({ documents: documentsPartialData });

    return api({
      method: 'post',
      url: path,
      headers,
      data: serializeData,
    });
  },
};
