import { csrfToken } from '@rails/ujs';
import { ref } from 'vue';
import api from './index';

const token = ref(csrfToken());
let headers = {};
if (token.value) {
  headers = { 'X-CSRF-Token': token.value };
}
interface PartialProduct {
  product: Partial<Product>,
  imagesDataAndPriority?: { priority: number, imageData: ImageData }[],
  labelIds?: number[]
}

const PAGE_SIZE = 24;

export default {
  // eslint-disable-next-line max-params
  indexNames(pageNumber: number, makerIds = [] as string[], categoryIds = [] as number[],
    subCategoryIds = [] as number[], searchText?: string) {
    let queryParams = `page[number]=${pageNumber}`;
    if (makerIds.length > 0) {
      queryParams = `${queryParams}&maker_ids=${makerIds}`;
    }
    if (categoryIds.length > 0) {
      queryParams = `${queryParams}&category_ids=${categoryIds}`;
    }
    if (subCategoryIds.length > 0) {
      queryParams = `${queryParams}&sub_category_ids=${subCategoryIds}`;
    }
    if (searchText && searchText.length > 0) {
      queryParams = `${queryParams}&search_text=${searchText}`;
    }

    return api({
      method: 'get',
      url: `/api/internal/products/names?page[size]=24&${queryParams}`,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },
  newIndex(pageNumber: number, queryParams?: string, pageSize = PAGE_SIZE) {
    return api({
      method: 'get',
      url: `/api/internal/products/new_index?page[number]=${pageNumber}&page[size]=${pageSize}&${queryParams}`,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },
  active(pageNumber: number, queryParams?: string, pageSize?: number) {
    const internalPageSize = pageSize || PAGE_SIZE;

    return api({
      method: 'get',
      url: `/api/internal/products/active?page[number]=${pageNumber}&page[size]=${internalPageSize}&${queryParams}`,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },
  get(productId: number) {
    const path = `/api/internal/products/${productId}`;

    return api({
      method: 'get',
      url: path,
      headers,
    });
  },
  create(data: PartialProduct) {
    const path = '/api/internal/products';

    return api({
      method: 'post',
      url: path,
      data,
      headers,
    });
  },
  update(productId: number, data: PartialProduct) {
    const path = `/api/internal/products/${productId}`;

    return api({
      method: 'patch',
      url: path,
      data,
      headers,
    });
  },
  validateSku(data: { sku: string, makerId: number, productId: number }) {
    let path = `/api/internal/products/validate_sku?sku=${data.sku}&maker_id=${data.makerId}`;
    if (!!data.productId) {
      path = `${path}&product_id=${data.productId}`;
    }

    return api({
      method: 'get',
      url: path,
      headers,
    });
  },
  uploadProductsFromFile(filePath: string, makerSlug: string) {
    const path = '/api/internal/products/upload_products_from_file';
    const data = { filePath, makerSlug };

    return api({
      method: 'post',
      url: path,
      data,
      headers,
    });
  },
};
