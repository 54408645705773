<template>
  <div class="flex flex-col items-center mb-10 w-full h-full md:px-5 md:max-w-7xl lg:px-10">
    <div class="flex items-center px-2 mb-3 w-full md:px-0">
      <p class="flex flex-col self-end text-xl text-gray-700 md:text-2xl lg:text-3xl">
        <span>Despachos gratis</span>
        <a
          :href="`/makers/${maker.slug}`"
          :taget="`_blank`"
          class="mt-1 text-sm font-light hover:underline md:text-base"
        >
          Revisar despachos gratis
        </a>
      </p>
      <button
        class="flex items-center p-2 ml-auto text-sm text-lk-green hover:text-white bg-white hover:bg-lk-green rounded-md border border-lk-green md:p-3 md:text-base"
        @click="showFreeShippingDialog = !showFreeShippingDialog"
      >
        <PlusIcon class="shrink-0 mr-2 w-5 h-5" />
        <span>Agregar despacho gratis</span>
      </button>
    </div>
    <div class="flex flex-col py-3 px-2 w-full h-full text-gray-700 bg-white rounded-md md:py-5 md:px-4 lg:px-10">
      <div class="flex items-center self-start mb-5 w-full text-sm md:text-base">
        <template
          v-for="value, key in statusHash"
          :key="key"
        >
          <button
            class="shrink-0 py-3 border-b-2"
            :class="{ 'border-gray-300' : selectedStatus !== key, 'border-lk-green' : selectedStatus === key }"
            @click="updateSelectedStatus(key)"
          >
            {{ value }}
          </button>
          <div
            class="self-end w-10 last:w-full border-b-2 border-gray-300"
          />
        </template>
      </div>
      <lokal-loading
        v-if="isLoading"
        :loading="isLoading"
      />
      <template v-else>
        <p
          v-if="orderedFreeShippings && orderedFreeShippings?.length === 0"
          class="text-sm md:text-base"
        >
          No hay despachos gratis
          <span class="lowercase">{{ statusHash[selectedStatus] === 'Todos' ? 'aún' : statusHash[selectedStatus] }}</span>.
        </p>
        <makers-free-shipping-list-card
          v-for="freeShipping in orderedFreeShippings"
          :key="freeShipping.id"
          :free-shipping="freeShipping"
          @reload-free-shippings="async () => await freeShippingsQuery.refetch.value()"
        />
      </template>
    </div>
    <makers-free-shipping-new-dialog
      v-if="showFreeShippingDialog"
      v-model="showFreeShippingDialog"
      :maker-id="props.maker.id"
      @reload-free-shippings="async () => await freeShippingsQuery.refetch.value()"
    />
  </div>
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue';
import { PlusIcon } from '@heroicons/vue/24/outline';
import MakersFreeShippingNewDialog from './makers-free-shipping-new-dialog.vue';
import MakersFreeShippingListCard from './makers-free-shipping-list-card.vue';
import useFreeShippingsQuery from './queries/free-shippings-query';

const props = defineProps<{
  maker: Maker;
}>();

const STATUSES_HASH = {
  all: 'all',
  active: 'active',
  inactive: 'inactive',
} as const;
const selectedStatus = ref<keyof typeof STATUSES_HASH>('all');
const statusHash = ref({ all: 'Todos', active: 'Activos', inactive: 'Desactivados' });
function updateSelectedStatus(status: keyof typeof STATUSES_HASH) {
  selectedStatus.value = status;
}

const showFreeShippingDialog = ref(false);
const freeShippingQueryParams = computed(() => {
  if (selectedStatus.value === 'active') return 'q[active_eq]=true';
  else if (selectedStatus.value === 'inactive') return 'q[active_eq]=false';

  return '';
});
const {
  isLoading, orderedFreeShippings, freeShippingsQuery,
} = useFreeShippingsQuery(computed(() => props.maker.id), freeShippingQueryParams);
</script>
