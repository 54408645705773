<template>
  <div class="flex flex-col w-full">
    <lokal-loading
      v-if="isLoading"
      :loading="isLoading"
    />
    <div
      v-else
      class="flex flex-col w-full"
    >
      <div class="flex justify-between items-center mb-3 w-full">
        <p class="text-base md:text-lg">
          Historial
        </p>
        <button
          class="flex justify-center items-center py-1 px-2 text-sm text-lk-green hover:text-white hover:bg-lk-green rounded-md border border-lk-green md:text-base"
          @click="showMakerNewFollowUpDialog = !showMakerNewFollowUpDialog"
        >
          <PlusIcon class="mr-2 w-4 h-4" />
          <span class="">Agregar seguimiento</span>
        </button>
      </div>
      <p
        v-if="sortedContainers.length === 0"
        class="mb-3 text-sm font-light md:text-base"
      >
        Aún no tienes seguimientos a esta persona.
      </p>
      <template
        v-else
      >
        <maker-follow-up-shop-card
          v-for="container in sortedContainers"
          :key="container.id"
          :follow-up="(container.followUp as FollowUp)"
          :show-followable-name="false"
          class="mb-2"
        />
      </template>
      <maker-new-follow-up-dialog
        v-model="showMakerNewFollowUpDialog"
        :maker="maker"
        follow-up-type="User"
        :follow-up-id="userId"
        :followable-name="userName"
        @reload-follow-ups="async () => await followUpsQuery.refetch.value()"
      />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue';
import { PlusIcon } from '@heroicons/vue/24/solid';
import { orderBy } from 'lodash';
import { useQuery } from 'vue-query';
import followUpsApi from 'api/follow-ups';
import makerFollowUpShopCard from './maker-follow-up-shop-card.vue';
import makerNewFollowUpDialog from './maker-new-follow-up-dialog.vue';

interface MakerShopProps {
  maker: Maker
  userId: number
  userName: string
}

const props = defineProps<MakerShopProps>();
const showMakerNewFollowUpDialog = ref(false);

const followUpQueryParam = computed(() =>
  `q[followable_type_eq]=User&q[followable_id_eq]=${props.userId}&q[maker_id_eq]=${props.maker.id}`);
const followUpsQuery = useQuery(['followUps', followUpQueryParam], () => followUpsApi.index(followUpQueryParam.value));
const isLoadingFollowUp = computed(() => followUpsQuery.isLoading.value);
const followUps = computed(() => followUpsQuery.data.value?.data.followUps as FollowUp[]);
const isLoading = computed(() => isLoadingFollowUp.value);

interface Container {
  id: string
  followUp?: FollowUp
  subOrder?: SubOrder
  type: 'followUp' | 'subOrder'
  createdAt: Date
}

const sortedContainers = computed(() => {
  const containers = followUps.value?.map((followUp) =>
    ({ followUp, type: 'followUp', createdAt: followUp.createdAt, id: `follow-up-${followUp.id}` }) as Container);

  return orderBy(containers, 'createdAt', 'desc') as Container[];
});

</script>
