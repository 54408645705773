<template>
  <Dialog
    :open="modelValue"
    as="div"
    class="relative z-50"
    @close="emit('update:modelValue', false)"
  >
    <div
      class="fixed inset-0 bg-black/30"
      aria-hidden="true"
    />
    <div class="flex fixed inset-0 justify-center items-center p-2 md:p-0">
      <DialogPanel class="flex overflow-y-auto flex-col p-2 w-full max-w-md text-gray-700 bg-gray-50 rounded md:p-4">
        <button
          class="mb-2 ml-auto focus:outline-none"
          @click="emit('update:modelValue', false)"
        >
          <XMarkIcon class="w-6 h-6 fill-gray-700" />
        </button>
        <template v-if="!messageSent">
          <DialogTitle class="mb-3 text-xl text-left md:text-2xl">
            Envía un mensaje a
            <span class="text-lk-green">{{ maker.name }}</span>
          </DialogTitle>
          <DialogDescription class="flex flex-col mb-3">
            Escribe cualquier duda que tengas y te responderemos a la brevedad.
          </DialogDescription>
          <form
            class="flex flex-col mb-2 md:mb-0"
            @submit="onSubmit"
          >
            <div class="flex flex-col mb-3">
              <Field
                v-model="content"
                as="textarea"
                class="p-2 h-32 text-sm focus:outline-none md:text-base"
                :rules="{ required: true }"
                name="text"
              />
              <ErrorMessage
                name="text"
                class="text-sm text-red-500"
              />
            </div>
            <lokal-loading
              v-if="isLoading"
              :loading="isLoading"
              class="self-center"
            />
            <lokal-button
              v-else
              action-name="Enviar"
              class="self-center w-full md:w-3/4"
            />
          </form>
        </template>
        <div
          v-else
          class="flex flex-col items-center"
        >
          <CheckBadgeIcon class="w-52 h-52 fill-lk-green" />
          <DialogTitle class="flex items-center mb-1 text-xl text-left md:text-2xl">
            ¡Hemos recibido tu mensaje!
          </DialogTitle>
          <p class="mb-5 font-light">
            Nos contactaremos contigo lo antes posible.
          </p>
          <lokal-button
            action-name="Aceptar"
            class="self-center w-full md:w-3/4"
            @click="emit('update:modelValue', false)"
          />
        </div>
      </DialogPanel>
    </div>
  </dialog>
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue';
import { XMarkIcon, CheckBadgeIcon } from '@heroicons/vue/24/solid';
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  DialogDescription,
} from '@headlessui/vue';
import { Field, useForm, ErrorMessage } from 'vee-validate';
import { useMutation } from 'vue-query';
import makerQuestionsApi from 'api/maker-questions';

interface LokalMessageDialogProps {
  modelValue: boolean
  maker: Maker
}
const props = withDefaults(defineProps<LokalMessageDialogProps>(), {
  modelValue: false,
});

const emit = defineEmits<{(e: 'update:modelValue', value: boolean): void}>();

const content = ref('');
const messageSent = ref(false);

const makerQuestionMutation = useMutation(
  () => makerQuestionsApi.create({ makerId: props.maker.id, content: content.value }),
  {
    onSuccess: () => {
      messageSent.value = true;
    },
  },
);
const isLoading = computed(() => makerQuestionMutation.isLoading.value);
const { handleSubmit } = useForm();
const onSubmit = handleSubmit(() => {
  makerQuestionMutation.mutate();
});
</script>
