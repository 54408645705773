import { computed, type ComputedRef } from 'vue';
import { useQuery } from 'vue-query';
import addressesApi from 'api/addresses';

export default function useAddressQuery(addressId: ComputedRef<number>, enabled?: ComputedRef<boolean>) {
  const internalEnabled = computed(() => !!addressId.value);
  const addressQuery = useQuery(
    ['address', addressId],
    () => addressesApi.get(addressId.value),
    { enabled: enabled || internalEnabled },
  );
  const addressIsLoading = computed(() => addressQuery.isLoading.value);
  const address = computed(() => addressQuery.data.value?.data?.address as Address);

  return { addressQuery, addressIsLoading, address };
}
