<template>
  <div
    v-if="!isLoading && shippingConditiossPresent"
    class="flex flex-col shrink-0 self-start"
  >
    <div
      v-if="!isLoading && shippingConditiossPresent"
      class="flex flex-col shrink-0 w-full text-gray-700 bg-lk-light-purple rounded-md divide-y divide-white"
    >
      <button
        class="flex items-center py-2 px-3 sm:py-3 sm:px-4"
        @click="showSubsidizeShippingCards = !showSubsidizeShippingCards"
      >
        <div class="flex justify-center items-center p-1 mr-3 bg-white rounded-full">
          <TruckIcon class="w-5 h-5 text-lk-light-purple" />
        </div>
        <p class="text-sm text-left text-white sm:text-base md:flex-row md:mr-2">
          {{ mainMessage }}
        </p>
        <ChevronDownIcon
          class="shrink-0 ml-auto w-4 h-4 text-white md:w-5 md:h-5"
          :class="{ 'rotate-180 transform duration-150 ease-in': showSubsidizeShippingCards,
                    'transform duration-150 ease-out': !showSubsidizeShippingCards }"
        />
      </button>
      <transition
        enter-active-class="transition duration-100"
        enter-from-class="-translate-y-2 opacity-50"
        enter-to-class="translate-y-0 transform opacity-100"
        leave-active-class="transition duration-100"
        leave-from-class="translate-y-0"
        leave-to-class="-translate-y-2 transform opacity-0"
      >
        <div
          v-if="showSubsidizeShippingCards"
          class="flex flex-col py-1 w-full text-sm font-light text-white md:text-base"
        >
          <maker-show-free-shipping-card
            v-for="freeShipping in orderedFreeShippings"
            :key="freeShipping.id"
            :free-shipping="freeShipping"
            :start-open="orderedFreeShippings.length === 1"
          />
          <p
            v-for="subsidizedShipping in orderedSubsidizedShippings"
            :key="`subsidized-shipping-${subsidizedShipping.id}`"
            class="py-1 px-3 text-left sm:px-5"
          >
            - Despachos con descuento de hasta {{ useFormatCurrency(subsidizeAmount(subsidizedShipping)) }}
            para pedidos sobre {{ useFormatCurrency(subsidizedShipping.minimumAmountCents) }}
          </p>
        </div>
      </transition>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue';
import { TruckIcon, ChevronDownIcon } from '@heroicons/vue/24/outline';
import useSessionStore from 'stores/user-store';
import useFormatCurrency from './use/format-currency';
import useSubsidizedShippings from './use/subsidized-shippings';
import useFreeShippingsQuery from './queries/free-shippings-query';
import makerShowFreeShippingCard from './maker-show-free-shipping-card.vue';

const SUBSIDIZED_FACTOR = 2;

interface MakerShowDiscountsProps {
  maker: Maker;
}

const props = defineProps<MakerShowDiscountsProps>();

const sessionStore = useSessionStore();

const computedMakerId = computed(() => props.maker.id);

const freeShippingsQueryParams = computed(() => 'q[active_eq]=true');
const {
  isLoading: freeShippingsIsLoading, orderedFreeShippings,
} = useFreeShippingsQuery(computedMakerId, freeShippingsQueryParams);
const includesCurrentAddress = computed(() => {
  let isIt = false;
  orderedFreeShippings?.value.forEach(freeShipping => {
    freeShipping.regions.forEach(region => {
      if (region.id === sessionStore.currentRegionId) isIt = true;
    });
    freeShipping.communes.forEach(commune => {
      if (commune.id === sessionStore.currentCommuneId) isIt = true;
    });
  });

  return isIt;
});
const currentFreeShipping = computed(() => {
  let internalFreeShipping: FreeShipping | undefined;
  orderedFreeShippings?.value.forEach(freeShipping => {
    const regionFreeShipping = freeShipping.regions.find((region) => region.id === sessionStore.currentRegionId);
    if (regionFreeShipping) {
      internalFreeShipping = freeShipping;
    }

    const communeFreeShipping = freeShipping.communes.find(
      (commune) => commune.id === sessionStore.currentCommuneId);
    if (communeFreeShipping) internalFreeShipping = freeShipping;
  });

  return internalFreeShipping;
});

const subsidizeShippingsQueryParams = computed(() => 'q[active_eq]=true');
const { subsidizedShippingsIsLoading, orderedSubsidizedShippings } = useSubsidizedShippings(
  computedMakerId, subsidizeShippingsQueryParams, computed(() => true),
);
const maxSubsidizedShipping = computed(() =>
  orderedSubsidizedShippings.value?.[orderedSubsidizedShippings.value.length - 1]);

const isLoading = computed(() => freeShippingsIsLoading.value || subsidizedShippingsIsLoading.value);
const shippingConditiossPresent = computed(() =>
  (orderedSubsidizedShippings.value && orderedSubsidizedShippings.value?.length > 0) ||
  (orderedFreeShippings.value && orderedFreeShippings.value?.length > 0),
);

const showSubsidizeShippingCards = ref(false);

function subsidizeAmount(subsidizeShipping: SubsidizedShipping) {
  if (subsidizeShipping.kind === 'with_lokal') {
    return subsidizeShipping.subsidizedAmountCents * SUBSIDIZED_FACTOR;
  }

  return subsidizeShipping.subsidizedAmountCents;
}

const mainMessage = computed(() => {
  if (includesCurrentAddress.value && currentFreeShipping.value) {
    return `Despacho gratis a tu dirección en compras desde
    ${useFormatCurrency(currentFreeShipping.value?.minimumAmountCents)}`;
  } else if (orderedSubsidizedShippings.value?.length) {
    return `Descuento en despachos de hasta
    ${useFormatCurrency(subsidizeAmount(maxSubsidizedShipping.value))}`;
  }

  return 'Despacho gratis no disponible. Revisa otras zonas.';
});
</script>
