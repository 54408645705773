<template>
  <sign-in-email
    v-if="signInEmailStep"
    v-model:signInEmail="signInEmailStep"
    v-model:email="email"
    @create-account="emit('update:modelValue', false)"
  />
  <sign-in-password
    v-else
    v-model:signInEmail="signInEmailStep"
    v-model:initialEmail="email"
    :email="email"
  />
</template>
<script lang="ts" setup>
import { ref } from 'vue';
import SignInEmail from './sign-in-email.vue';
import SignInPassword from './sign-in-password.vue';

const props = defineProps<{
  modelValue: boolean
  userEmail?: string
}>();

const signInEmailStep = ref(!props.userEmail);
// const email = ref('');
const email = ref(props.userEmail || '');

const emit = defineEmits<{(e: 'update:modelValue', value: boolean): void}>();
</script>
