<template>
  <div class="flex flex-col w-full h-full">
    <template
      v-for="message, index in messages"
      :key="message"
    >
      <lokal-banner-message-dialog
        v-if="message[1]"
        v-model="showBanners[index]"
        :show-dialog="message[0].includes('dialog')"
        :color-key="(message[0] as ColorKeyType)"
        :message="message[1]"
        title="Información importante"
      />
    </template>
    <lokal-banner
      v-model="showPontetialMakerContactBanner"
      :message="potentialMakerContactBannerMessage"
      :color-key="('notice' as ColorKeyType)"
      class=""
    />
    <div class="flex flex-col items-center w-full h-full">
      <!-- lokal -->
      <div class="flex flex-col items-center w-full h-full bg-texture shadow-md">
        <div class="flex flex-col justify-between items-center w-full max-w-6xl h-full md:flex-row md:pl-8 lg:pl-[128px]">
          <div class="flex flex-col items-center mb-8 md:items-start md:mb-0 md:w-7/12">
            <inline-svg-wrapper
              name="lokal-logo"
              path="images"
              class="w-72 max-h-44 fill-lk-green md:-ml-12 md:w-96"
            />
            <p class="flex flex-col px-3 mb-8 text-xl font-semibold text-center text-lk-green md:pl-0 md:mb-6 md:text-left lg:pr-10 lg:mb-12 lg:text-3xl xl:text-4xl">
              Encuentra productos únicos con financiamiento para tu tienda física
            </p>
            <div class="flex flex-col space-y-2 text-base md:flex-row md:space-y-0 md:space-x-3">
              <a
                v-if="sessionStore.user?.id"
                class="py-2 px-5 text-white bg-lk-green rounded-md sm:py-2 md:px-6"
                href="/makers"
              >
                Revisar catálogo
              </a>
              <button
                v-else
                class="py-2 px-5 text-white bg-lk-green rounded-md sm:py-2 md:px-6"
                @click="openRegisterDialog"
              >
                Revisar catálogo
              </button>
              <button
                class="py-2 px-5 text-lk-green bg-white rounded-md border border-lk-green sm:py-2 md:px-6"
                @click="showPotentialMakerContactDialog = !showPotentialMakerContactDialog"
              >
                Vender en Lokal
              </button>
            </div>
          </div>
          <div class="grid shrink-0 grid-cols-3 ml-auto w-full sm:grid-cols-4 md:grid-cols-2 md:w-auto">
            <img
              src="assets/images/landing-first-image.svg"
              class="object-cover w-full h-32 sm:h-32 md:w-44 md:h-44 lg:w-60 lg:h-60"
            >
            <img
              src="assets/images/landing-second-image.svg"
              class="object-cover w-full h-32 sm:h-32 md:w-44 md:h-44 lg:w-60 lg:h-60"
            >
            <img
              v-if="!isMobile"
              src="assets/images/landing-third-image.svg"
              class="object-cover w-full h-32 sm:h-32 md:w-44 md:h-44 lg:w-60 lg:h-60"
            >
            <img
              src="assets/images/landing-fourth-image.svg"
              class="object-cover w-full h-32 sm:h-32 md:w-44 md:h-44 lg:w-60 lg:h-60"
            >
          </div>
        </div>
      </div>
      <!-- waranty -->
      <transition
        enter-from-class="opacity-0 translate-y-10"
        enter-to-class="opacity-100"
        enter-active-class="transition duration-150"
      >
        <div class="flex flex-col items-center py-8 px-4 w-full h-full bg-lk-green-deep sm:px-0 md:py-12 lg:pr-0 lg:pl-[128px] xl:pr-0">
          <div class="flex flex-col items-center p-5 w-full max-w-6xl h-full rounded-md lg:pr-[96px]">
            <CheckBadgeIcon class="shrink-0 mb-1 w-16 h-16 fill-white md:w-20 md:h-20" />
            <p class="font-serif-noto text-xl font-light text-center text-white md:text-3xl">
              Te garantizamos
              los <span class="font-semibold">mismos precios</span> <br>
              que tiene la marca en su canal mayorista.
            </p>
          </div>
        </div>
      </transition>
      <!-- best makers by categories -->
      <the-landing-best-makers-by-categories :categories="categories" />
      <!-- benefits -->
      <the-landing-benefits />
      <!-- testimonials -->
      <the-landing-testimonials :testimonials="testimonials" />
      <!-- how to buy -->
      <the-landing-buying-steps />
      <!-- Q&A -->
      <the-landing-q-and-a
        v-if="frequentQuestions.length > 0"
        id="Q&A"
        :frequent-questions="frequentQuestions"
      />
      <PotentialMakerContactDialog
        v-model="showPotentialMakerContactDialog"
        @pontential-maker-contact-created="displayPotentialMakerContactBanner"
      />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { inject, ref } from 'vue';
import { CheckBadgeIcon } from '@heroicons/vue/24/solid';
import useGeneralMenuStore from 'stores/general-menu-store';
import useSessionStore from 'stores/user-store';
import LokalBanner, { type ColorKeyType } from './shared/lokal-banner.vue';
import PotentialMakerContactDialog from './potential-maker-contact-dialog.vue';
import theLandingBuyingSteps from './the-landing-buying-steps.vue';
import theLandingQAndA from './the-landing-q-and-a.vue';
import theLandingBenefits from './the-landing-benefits.vue';
import theLandingBestMakersByCategories from './the-landing-best-makers-by-categories.vue';
import theLandingTestimonials from './the-landing-testimonials.vue';
import InlineSvgWrapper from './inline-svg-wrapper.vue';

interface LandingProps {
  testimonials: Testimonial[]
  categories: Category[],
  frequentQuestions: FrequentQuestion[],
  messages?: string[][]
}

const props = withDefaults(defineProps<LandingProps>(), {
  messages: () => [['', '']],
});

const sessionStore = useSessionStore();

const showBanners = ref({} as { [string: number]: boolean });
props.messages.forEach((_, index) => {
  showBanners.value[index] = true;
});
const isMobile = inject('isMobile');
const showPotentialMakerContactDialog = ref(false);
const showPontetialMakerContactBanner = ref(false);
const potentialMakerContactBannerMessage = ref('');
function displayPotentialMakerContactBanner() {
  potentialMakerContactBannerMessage.value = 'Se ha creado correctamente la solicitud.';
  showPontetialMakerContactBanner.value = true;
}

const menuStore = useGeneralMenuStore();
function openRegisterDialog() {
  menuStore.showUserRegisterDialog = true;
  menuStore.initialValueSignIn = false;
}
</script>
