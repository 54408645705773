<template>
  <div
    v-if="!signUpStore.currentPathIncludesShopsSignUp"
    class="grid grid-cols-2 gap-y-8 place-items-center py-20 px-5 text-sm text-white bg-lk-green md:grid-cols-3 md:px-10"
  >
    <div class="flex flex-col space-y-1 h-full text-xs md:place-self-center md:text-sm">
      <p class="text-base md:text-lg">
        Más Información:
      </p>
      <a
        :href="`https://wa.me/${contactNumber}?text=¡Hola%20equipo%20Lokal!`"
        target="_blank"
        noopener
      >Contáctanos</a>
      <a
        href="/about_us"
        class="hover:underline"
      >
        Sobre nosotros
      </a>
      <a
        href="/frequent_questions"
        class="hover:underline"
      >
        Preguntas frecuentes
      </a>
      <a
        href="/return_policy"
        class="hover:underline"
      >
        Políticas de devolución
      </a>
      <a
        href="/shipping_policy"
        class="hover:underline"
      >
        Políticas de despacho
      </a>
      <a
        href="/terms_of_service"
        class="hover:underline"
      >
        Términos y condiciones
      </a>
    </div>
    <div class="flex flex-col col-span-2 row-start-1 justify-center items-center md:col-span-1 md:row-start-auto">
      <img
        src="assets/images/lokal-logo-white.svg"
        class="mb-1 w-52 md:w-40"
      >
      <p class="text-center">
        Conectando comunidades con marcas únicas
      </p>
    </div>
    <div class="flex flex-col space-y-1 text-xs md:justify-self-center md:place-self-start md:text-sm">
      <p class="text-base md:text-lg">
        ¡Hablemos!
      </p>
      <a :href="`mailto:${contactEmail}`">{{ contactEmail }}</a>
      <a
        v-if="contactNumber"
        class="flex items-center space-x-2"
        :href="`https://wa.me/${contactNumber}?text=¡Hola%20equipo%20Lokal!`"
        target="_blank"
        noopener
      >
        <inline-svg-wrapper
          name="whatsapp-logo"
          class="w-5 h-5 fill-white"
        />
        <p>
          {{ contactNumber }}
        </p>
      </a>
      <p class="mb-1">
        {{ address }}
      </p>
      <div class="flex space-x-3">
        <a
          :href="instagramLink"
          target="_blank"
          noopener
          class="w-6 h-6"
        >
          <inline-svg-wrapper
            name="instagram-logo"
            class="w-full h-full fill-white"
          />
        </a>
        <a
          :href="facebookLink"
          target="_blank"
          noopener
          class="w-6 h-6"
        >
          <inline-svg-wrapper
            name="facebook-logo"
            class="w-full h-full rounded-md fill-white"
          />
        </a>
        <a
          :href="linkedinLink"
          target="_blank"
          noopener
          class="w-6 h-6"
        >
          <inline-svg-wrapper
            name="linkedin-logo"
            class="w-full h-full fill-white"
          />
        </a>
      </div>
    </div>
    <div class="flex col-span-2 justify-between w-full md:col-span-1 md:col-start-2">
      <inline-svg-wrapper
        name="corfo-logo"
        class="place-self-center pl-4 w-full h-10 text-white fill-white md:w-60"
      />
      <inline-svg-wrapper
        v-if="!isMobile"
        name="pv-logo"
        class="col-span-2 place-self-center px-4 w-full h-10 fill-white md:w-60"
      />
      <inline-svg-wrapper
        name="marca-chile"
        class="place-self-center pr-4 w-full h-20 text-white fill-white md:w-60"
      />
    </div>
    <inline-svg-wrapper
      v-if="isMobile"
      name="pv-logo"
      class="col-span-2 place-self-center px-4 w-full h-10 fill-white md:w-60"
    />
  </div>
</template>
<script lang="ts" setup>
import { inject } from 'vue';
import useSignUpStore from 'stores/sign-up-store';
import inlineSvgWrapper from './inline-svg-wrapper.vue';

defineProps<{
  contactEmail: string,
  contactNumber: string,
  address: string,
  instagramLink: string,
  facebookLink: string,
  linkedinLink: string,
}>();

const signUpStore = useSignUpStore();

const isMobile = inject('isMobile');
</script>
