<template>
  <Popover
    class="relative"
  >
    <PopoverButton
      id="popoverButton"
      class="flex z-0 items-center focus:outline-none"
      :class="buttonClasses"
      type="button"
    >
      <slot name="custom-content">
        <p>
          {{ mainLabel }}
        </p>
        <QuestionMarkCircleIcon class="w-4 h-4" />
      </slot>
    </PopoverButton>
    <PopoverPanel
      v-slot="{ close }"
      class="flex overflow-y-auto absolute z-10 flex-col p-2 mt-2 max-h-96 text-sm text-white bg-lk-light-purple rounded-md divide-gray-100 focus:outline-none origin-top-right"
      :class="positionClasses"
    >
      <button @click="close()">
        <XMarkIcon class="mb-1 ml-auto w-4 h-4" />
      </button>
      <p class="flex flex-col p-2">
        <span
          v-for="info, index in arrayOfIntormation"
          :key="index"
        >
          {{ info }}
        </span>
      </p>
    </PopoverPanel>
  </Popover>
</template>
<script lang="ts" setup>
import { ref, watch } from 'vue';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import { QuestionMarkCircleIcon, XMarkIcon } from '@heroicons/vue/24/outline';

interface LokalDropDownProps {
  information: string
  mainLabel?: string
  positionClasses?: string
  buttonClasses?: string
}

const props = withDefaults(defineProps<LokalDropDownProps>(), {
  mainLabel: '',
  positionClasses: 'right-0 w-52',
  buttonClasses: 'flex-col md:flex-row space-x-1 md:space-x-2',
});

const arrayOfIntormation = ref(props.information.split('<br>'));

watch(() => props.information, () => {
  arrayOfIntormation.value = props.information.split('<br>');
});
</script>
