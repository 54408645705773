<template>
  <div class="flex flex-col items-center mb-10 w-full h-full md:px-5 md:max-w-7xl lg:px-10">
    <div class="flex items-center px-2 mb-3 w-full md:px-0">
      <p class="flex flex-col self-end text-xl text-gray-700 md:text-2xl lg:text-3xl">
        Usuarios
      </p>
    </div>
    <div class="flex flex-col py-3 px-2 w-full bg-white rounded-md md:py-5 md:px-4 lg:px-10">
      <lokal-loading
        v-if="isLoading"
        :loading="isLoading"
      />
      <template v-else>
        <div
          v-for="user, index in users"
          :key="index"
          class="flex flex-col items-center p-5 mb-3 bg-gray-50 rounded-md md:flex-row"
        >
          <UserCircleIcon class="w-10 h-10 fill-lk-green md:mr-5" />
          <p class="flex flex-col items-center md:items-start">
            <span class="mb-1 font-semibold">{{ user.firstName }} {{ user.lastName }}</span>
            <span class="text-sm text-gray-500">{{ user.email }}</span>
          </p>
        </div>
      </template>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import { useQuery } from 'vue-query';
import usersApi from 'api/users';
import { UserCircleIcon } from '@heroicons/vue/24/solid';

const props = defineProps<{
  shopId: number
}>();

const { isLoading, data } = useQuery('users', () => usersApi.index(`shop_id=${props.shopId}`));
const users = computed(() => data.value?.data.users as User[]);
</script>
