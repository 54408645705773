<template>
  <VueDatePicker
    v-model="internalModelValue"
    :teleport="teleport"
    :inline="inline"
    :range="range"
    :auto-apply="autoApply"
    :min-date="minDate"
    :enable-time-picker="enableTimePicker"
    class="text-sm font-light"
    :month-change-on-scroll="monthChangeOnScroll"
    :locale="es"
    :month-picker="monthPicker"
    select-text="Elegir"
    cancel-text="Cancelar"
    @update:model-value="emit('update:modelValue', $event)"
  />
</template>
<script lang="ts" setup>
import { ref } from 'vue';
import { es } from 'date-fns/locale';

interface LokalDatepicker {
  modelValue: Date | Date[] | { month: number | string; year: number | string }
  teleport?: boolean
  inline?: boolean | { [input: string]: boolean }
  range?: boolean
  autoApply?: boolean
  enableTimePicker?: boolean
  minDate?: Date | null
  monthChangeOnScroll?: boolean
  monthPicker?: boolean
}

const props = withDefaults(defineProps<LokalDatepicker>(), {
  teleport: true,
  inline: false,
  range: true,
  autoApply: false,
  enableTimePicker: false,
  minDate: null,
  monthChangeOnScroll: false,
  monthPicker: false,
});

const internalModelValue = ref(props.modelValue);
const emit = defineEmits<{(e: 'update:modelValue', value: Date | Date[]): void}>();
</script>
<style lang="scss">
.dp__theme_light {
    --dp-background-color: #fff;
    --dp-text-color: #1E2933;
    --dp-hover-color: #f3f3f3;
    --dp-hover-text-color: #212121;
    --dp-hover-icon-color: #959595;
    --dp-primary-color: #00594C;
    --dp-primary-disabled-color: #6bacea;
    --dp-primary-text-color: #f8f5f5;
    --dp-secondary-color: #c0c4cc;
    --dp-border-color: #ddd;
    --dp-menu-border-color: #ddd;
    --dp-border-color-hover: #aaaeb7;
    --dp-disabled-color: #f6f6f6;
    --dp-scroll-bar-background: #f3f3f3;
    --dp-scroll-bar-color: #959595;
    --dp-success-color: #76d275;
    --dp-success-color-disabled: #a3d9b1;
    --dp-icon-color: #959595;
    --dp-danger-color: #ff6f60;
    --dp-marker-color: #ff6f60;
    --dp-tooltip-color: #fafafa;
    --dp-disabled-color-text: #8e8e8e;
    --dp-highlight-color: rgb(25 118 210 / 10%);
    --dp-range-between-dates-background-color: var(--dp-hover-color, #f3f3f3);
    --dp-range-between-dates-text-color: var(--dp-hover-text-color, #212121);
    --dp-range-between-border-color: var(--dp-hover-color, #f3f3f3);
}

.dp__action_select {
  background-color: #00594C;
}

.dp__action_select:hover {
  background-color: #003D34;
}

.dp__action_row {
  flex-direction: column;
}

.dp__action_buttons {
  justify-content: center;
  margin-inline-start: 0;
  margin-top: 4px;
}
</style>

