<template>
  <inline-svg :src="sourceUrl" />
</template>
<script lang="ts" setup>
interface InlineSvgWrapperProps {
  name: string
  ext?: string
  path?: string
}
const props = withDefaults(defineProps<InlineSvgWrapperProps>(), {
  ext: 'svg',
  path: 'images/icons',
});

const sourceUrl = new URL(`../../assets/${props.path}/${props.name}.${props.ext}`, import.meta.url).href;
</script>
