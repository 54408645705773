import { computed, type Ref } from 'vue';
import { useQuery } from 'vue-query';
import communesApi from 'api/communes';

export default function useCommuneQuery(communeId: Ref<number>, queryParams = computed(() => ''),
  enabled = computed(() => true)) {
  const communeQuery = useQuery(
    ['commune', communeId, queryParams],
    () => communesApi.get(communeId.value, queryParams.value),
    { enabled },
  );
  const commune = computed(() => communeQuery.data.value?.data.commune as Commune);

  return { commune, communeQuery };
}
