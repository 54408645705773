import { computed, type ComputedRef } from 'vue';
import { useQuery } from 'vue-query';
import makersApi from 'api/makers';

export default function useReferredMakersQuery(shopId: ComputedRef<number>, queryParams: ComputedRef<string>,
  enabled = computed(() => false)) {
  const referredMakersQuery = useQuery(['referredMakers', shopId, queryParams],
    () => makersApi.activeReferred(shopId.value, queryParams.value),
    { enabled, refetchOnWindowFocus: false },
  );
  const referredMakers = computed(() => referredMakersQuery.data.value?.data.makers as Maker[]);

  return { referredMakers, referredMakersQuery };
}
