<template>
  <div class="flex flex-col w-full h-full">
    <div
      v-if="step !== 'email' && step !== 'success'"
      class="flex items-center px-10 mb-5 w-full"
    >
      <div class="flex shrink-0 justify-center items-center p-2 w-8 h-8 text-sm text-white bg-lk-light-green rounded-full">
        1
      </div>
      <div
        class="w-full border-b-4"
        :class="{'border-lk-light-green': step === 'user' || step === 'shop', 'border-gray-300': step === 'email' }"
      />
      <div
        class="flex shrink-0 justify-center items-center p-2 w-8 h-8 text-sm rounded-full"
        :class="{'text-white bg-lk-light-green': step === 'user' || step === 'shop',
                 'text-white bg-gray-300': step === 'email'}"
      >
        2
      </div>
      <div
        class="w-full border-b-4"
        :class="{'border-lk-light-green': step === 'shop', 'border-gray-300': step !== 'shop' }"
      />
      <div
        class="flex shrink-0 justify-center items-center p-2 w-8 h-8 text-sm rounded-full"
        :class="{'text-white bg-lk-light-green': step === 'shop', 'text-white bg-gray-300': step !== 'shop'}"
      >
        3
      </div>
    </div>
    <sign-up-email
      v-if="step === 'email'"
      @already-has-account="emit('update:modelValue', true)"
    />
    <sign-up-user v-else-if="step === 'user'" />
    <sign-up-shop v-else-if="step === 'shop'" />
    <sign-up-success
      v-else-if="step === 'success'"
      @close-dialog="emit('close-dialog')"
    />
  </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import useSignUpStore from 'stores/sign-up-store';
import signUpEmail from './sign-up-email.vue';
import signUpUser from './sign-up-user.vue';
import signUpShop from './sign-up-shop.vue';
import signUpSuccess from './sign-up-success.vue';

defineProps<{
  modelValue: boolean
}>();

const signUpStore = useSignUpStore();
const emit = defineEmits<{(e: 'update:modelValue', value: boolean): void, (e: 'close-dialog'): void}>();
const step = computed(() => signUpStore.currentStep);
</script>
