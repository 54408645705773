import { computed, type ComputedRef } from 'vue';
import { useQuery } from 'vue-query';
import balanceAccountsApi from 'api/balance-accounts';

export default function useBalanceAccountQuery(shopId: ComputedRef<number | undefined>,
  enabled = computed(() => true),
) {
  const internalEnabled = computed(() => !!shopId.value && enabled.value);
  const balanceAccountQuery = useQuery(
    ['balance-account', shopId], () => balanceAccountsApi.get(shopId.value || 0), { enabled: internalEnabled });
  const balanceAccount = computed(() => balanceAccountQuery.data.value?.data.balanceAccount as BalanceAccount);

  return { balanceAccount, balanceAccountQuery, isLoading: balanceAccountQuery.isLoading };
}
