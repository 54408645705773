import { computed, type ComputedRef } from 'vue';
import { useQuery } from 'vue-query';
import subOrdersApi from 'api/sub-orders';

export default function useSubOrderQuery(subOrderId: ComputedRef<number>, enabled?: ComputedRef<boolean>) {
  const internalEnabled = computed(() => !!subOrderId.value);
  const subOrderQuery = useQuery(
    ['sub-order', subOrderId],
    () => subOrdersApi.get(subOrderId.value),
    { enabled: enabled || internalEnabled },
  );
  const subOrder = computed(() => subOrderQuery.data.value?.data.subOrder as SubOrder);
  const subOrderQueryisLoading = computed(() => subOrderQuery.isLoading.value);

  return { subOrder, subOrderQuery, subOrderQueryisLoading };
}
