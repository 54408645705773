<template>
  <div class="flex flex-col w-full">
    <lokal-loading
      v-if="isLoading"
      :loading="isLoading"
    />
    <template v-else>
      <div
        class="flex justify-between items-center"
        :class="[titleContainerClasses]"
      >
        <slot
          :edit-shop="editShop"
          name="title"
        >
          <p class="text-base md:text-lg">
            {{ shop?.id ? 'Datos de tu tienda' : 'Registra tu tienda' }}
          </p>
        </slot>
        <button
          v-if="shop?.id && !editShop"
          class="text-sm hover:underline"
          @click="editShop = !editShop"
        >
          Editar
        </button>
        <button
          v-else-if="editShop"
          class="text-sm hover:underline"
          :disabled="!shop?.id"
          @click="editShop = !editShop"
        >
          Volver
        </button>
      </div>
      <shop-information-display
        v-if="!editShop && shop?.id"
        :shop="shop"
        :class="[contentContainerClasses]"
        :summary="summary"
        class="mb-3"
      />
      <shop-new
        v-if="editShop"
        :shop="shop"
        :go-back-option="true"
        :class="[contentContainerClasses]"
        class="mb-3"
        @edit-shop="manageEditShopEventForShopNew"
        @shop-updated="async () => await shopQuery.refetch.value()"
      />
    </template>
  </div>
</template>
<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import useShopQuery from './queries/shop-query';
import ShopNew from './shop-new.vue';
import ShopInformationDisplay from './shop-information-display.vue';
import LokalLoading from './shared/lokal-loading.vue';

interface ShopShowProps {
  showAddresses?: boolean
  shopId?: number
  titleContainerClasses?: string
  contentContainerClasses?: string
  summary?: boolean
}

const props = withDefaults(defineProps<ShopShowProps>(), {
  showAddresses: true,
  titleContainerClasses: 'mb-2',
  contentContainerClasses: '',
  shopId: undefined,
  summary: false,
});

const computedShopId = computed(() => props.shopId);
const { shop, shopQuery } = useShopQuery(computedShopId);

const editShop = ref(!props.shopId);

watch(shop, () => {
  editShop.value = !shop.value?.id || !shop.value?.businessRut;
});

const isLoading = computed(() => shopQuery.isLoading.value);

function manageEditShopEventForShopNew() {
  editShop.value = !editShop.value;
  shopQuery.refetch.value();
}
</script>
