<template>
  <div class="relative">
    <Bar
      :id="chartId"
      :data="data"
      :options="options"
      width="400"
      height="400"
    />
  </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import { Bar } from 'vue-chartjs';
import {
  Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, type ChartData,
} from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

declare interface BarChartProps {
  chartId?: string;
  labels: number[] | string[];
  datasets: unknown[];
  options: Record<string, unknown>;
}

const props = withDefaults(defineProps<BarChartProps>(), {
  chartId: 'bar-chart',
});

const data = computed(() => ({
  labels: props.labels,
  datasets: props.datasets,
} as ChartData<'bar', number[], unknown>));
</script>
