<template>
  <div class="flex flex-col items-center w-full">
    <template
      v-for="message, index in messages"
      :key="message"
    >
      <lokal-banner-message-dialog
        v-if="message[1]"
        v-model="showBanners[index]"
        :show-dialog="message[0].includes('dialog')"
        :color-key="(message[0] as ColorKeyType)"
        :message="message[1]"
        title="Información importante"
      />
    </template>
    <product-list-trends />
    <div class="flex w-full max-w-7xl">
      <div
        v-if="menuStore.showProductFilterSidebar && !isMobile"
        class="overflow-y-hidden fixed inset-0 z-20 bg-gray-800/50"
        aria-hidden="true"
        @click="menuStore.showProductFilterSidebar = false"
      />
      <product-list-aside-filters
        v-if="menuStore.showProductFilterSidebar || !isMobile"
        :categories="categories"
        :cross-enabled="isMobile"
        :sub-categories="subCategories"
        class="overflow-y-auto fixed left-0 z-20 w-3/5 h-screen text-sm text-gray-700 bg-gray-50 sm:static sm:left-auto sm:z-0 md:overflow-hidden md:w-80 md:h-auto md:text-base xl:bg-white"
        :style="{ top: menuStore.headerHeight + 'px' }"
        :class="{ 'border-r border-gray-300': isMobile }"
      />
      <div class="flex flex-col py-6 px-3 w-full md:p-10 xl:px-0">
        <product-list-display
          :categories="categories"
          :sub-categories="subCategories"
          :total-product-count="totalProductCount"
        />
      </div>
    </div>
    <shipping-discounts-dialog
      v-if="showShippingDiscountsDialog"
      v-model="showShippingDiscountsDialog"
    />
  </div>
</template>
<script setup lang="ts">
import { ref, inject } from 'vue';
import useGeneralMenuStore from 'stores/general-menu-store';
import ProductListDisplay from './product-list-display.vue';
import type { ColorKeyType } from './shared/lokal-banner.vue';
import productListAsideFilters from './product-list-aside-filters.vue';
import ProductListTrends from './product-list-trends.vue';
import ShippingDiscountsDialog from './shared/shipping-discounts-dialog.vue';

interface ProductListProps {
  categories: Category[],
  subCategories: SubCategory[],
  totalProductCount?: number,
  messages?: string[][]
}

const props = withDefaults(defineProps<ProductListProps>(), {
  messages: () => [['', '']],
  totalProductCount: 0,
});

const showShippingDiscountsDialog = ref(false);

const menuStore = useGeneralMenuStore();
const isMobile = inject('isMobile') as boolean;
const showBanners = ref({} as { [string: number]: boolean });
props.messages.forEach((_, index) => {
  showBanners.value[index] = true;
});
</script>
