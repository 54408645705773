<template>
  <div class="flex flex-col items-center py-6 w-full md:py-10">
    <form
      class="grid relative grid-cols-1 gap-x-5 mb-10 w-full max-w-7xl text-gray-700 md:grid-cols-12 md:px-5 xl:px-0"
      @submit="onSubmit"
    >
      <div class="flex flex-col px-5 w-full md:col-span-12 md:px-0">
        <h1 class="mb-3 font-serif text-xl md:mb-5 md:text-3xl">
          Nueva Orden de Compra
        </h1>
        <div
          v-for="(items, index) in cartStore.groupedItems"
          :key="index"
          class="mb-5"
        >
          <order-products-by-maker
            :items="items"
            :maker-name="`${index}`"
            :error-message="errors[index]"
            :order-in-progress="true"
            :show-minimum="!!(errors && errors[index])"
          />
        </div>
      </div>
      <div class="flex flex-col md:col-span-12">
        <div class="flex flex-col-reverse items-center px-5 mb-2 w-full md:flex-row md:col-span-12 md:justify-between md:px-0">
          <a
            href="/makers"
            class="text-xs text-center hover:text-lk-green underline hover:underline md:text-sm md:no-underline"
          >
            Seguir Comprando
          </a>
          <button
            class="p-1.5 mb-1 w-full text-sm text-white bg-lk-green rounded-md md:p-2 md:mb-0 md:w-auto md:text-base"
            :class="{ 'hover:bg-lk-green-deep': Object.keys(errors).length === 0,
                      'opacity-50': Object.keys(errors).length > 0 }"
            :disabled="Object.keys(errors).length > 0"
            type="submit"
          >
            Continuar con Orden
          </button>
        </div>
        <div
          v-if="Object.keys(errors).length > 0"
          class="flex flex-col items-center"
        >
          <template
            v-for="(error, index) in errors"
            :key="index"
          >
            <p
              v-if="'shopId' === index"
              class="mb-2 text-xs text-center text-red-500 md:text-sm"
            >
              Debes registrar tu tienda antes de continuar.
            </p>
            <p
              v-else
              class="mb-2 text-xs text-center text-red-500 md:text-sm"
            >
              {{ error }}
            </p>
          </template>
        </div>
      </div>
    </form>
  </div>
</template>
<script setup lang="ts">
import { computed, watch } from 'vue';
import { useForm, configure } from 'vee-validate';
import useCartStore from 'stores/cart';
import useSessionStore from 'stores/user-store';
import OrderProductsByMaker from './order-products-by-maker.vue';
import useShopQuery from './queries/shop-query';
import useDiscountCodeQuery from './queries/discount-code-query';

const cartStore = useCartStore();
const { errors, validate, handleSubmit } = useForm();

const onSubmit = handleSubmit(async () => {
  const { valid } = await validate();
  if (valid) window.location.href = '/orders/checkout';
});

configure({
  validateOnModelUpdate: false,
});

const sessionStore = useSessionStore();
const { shop } = useShopQuery(computed(() => sessionStore.user?.shopId), computed(() => !!sessionStore.user?.shopId));
const discountToken = computed(() => shop.value?.registrationDiscountCodeToken || '');
const discountCodeQueryEnabled = computed(() => !!shop.value?.registrationDiscountCodeToken &&
  !shop.value?.registrationDiscountCodeUsed);
const { discountCode: discountCodeFromToken } = useDiscountCodeQuery(discountToken, discountCodeQueryEnabled);

watch(discountCodeFromToken, (newValue, oldValue) => {
  if (newValue?.id && !oldValue?.id) {
    cartStore.discountCodes[newValue.makerId] = newValue;
  }
});

watch(() => cartStore.groupedItems, () => {
  if (Object.keys(cartStore.groupedItems).length === 0) {
    window.location.href = '/makers';
  }
});
</script>
