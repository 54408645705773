<template>
  <div class="flex flex-row py-1 w-full text-sm">
    <a
      :style="{ 'background-image': `url(${productImageToShow})` }"
      class="flex object-cover shrink-0 mr-2 w-16 h-16 bg-center bg-cover rounded-md"
      :href="`/products/${item.product.id}`"
    />
    <div class="flex flex-col truncate">
      <p class="truncate">
        <span class="">{{ item.product.name }}</span>
      </p>
      <p class="flex items-center">
        <span :class="{ 'line-through mr-1': showDiscountedAmount }">
          {{ useFormatCurrency(totalProductPriceWithoutDiscountForItem(item)) }}
        </span>
        <span v-if="showDiscountedAmount">{{ useFormatCurrency(totalProductPriceForItem(item)) }}</span>
      </p>
      <div
        v-if="item.quantity > 0"
        class="flex items-center"
      >
        <button
          class="flex justify-center items-center p-1 mr-1 w-5 h-5 hover:text-white hover:bg-lk-green rounded-sm border hover:border-0"
          :class="{ 'opacity-30': cartProductMutationIsLoading }"
          :disabled="quantity === 0 || cartProductMutationIsLoading"
          @click="removeOneUnit"
        >
          -
        </button>
        <Field
          v-if="item.product.packUnits === 1"
          v-model.number="quantityField"
          :name="`cart-quantity-${item.product.id}`"
          class="p-1 mr-1 w-8 h-5 text-center bg-white focus:outline-none"
          @change="manageQuantityChange"
        />
        <lokal-tooltip
          v-else
          class="px-2 h-5 bg-white"
          :main-label="`${quantity}`"
          :information="`Este producto viene en packs de ${item.product.packUnits} unidades.`"
          button-classes="flex items-center justify-center space-x-1"
          information-classes="w-40 md:w-52"
          information-position="center"
          arrow-color-classes="border-lk-light-gray"
          information-color-classes="bg-lk-light-gray"
          position-options="center"
        />
        <button
          class="flex justify-center items-center p-1 w-5 h-5 rounded-sm border hover:border-0 sm:mr-3"
          :class="{ 'opacity-30': increaseButtonDisabled || cartProductMutationIsLoading,
                    'hover:text-white hover:bg-lk-green': !increaseButtonDisabled }"
          :disabled="increaseButtonDisabled || cartProductMutationIsLoading"
          @click="increaseOneUnit"
        >
          +
        </button>
        <button
          v-if="!isMobile"
          class="hover:text-red-500"
          @click="showDeleteConfirmationDialog = !showDeleteConfirmationDialog"
        >
          <TrashIcon class="shrink-0 mr-2 w-4 h-4" />
        </button>
      </div>
      <button
        v-if="isMobile"
        class="flex items-center mt-1 hover:text-red-500"
        @click="showDeleteConfirmationDialog = !showDeleteConfirmationDialog"
      >
        <TrashIcon class="shrink-0 mr-2 w-4 h-4" />
        <span class="text-sm">Eliminar</span>
      </button>
    </div>
    <lokal-confirmation-dialog
      v-model="showDeleteConfirmationDialog"
      :title="`¿Estás seguro de eliminar ${item.product.name} del carrito?`"
      confirm-action-text="Eliminar"
      reject-action-text="Cancelar"
      event-name="delete-product-from-cart"
      :positive-action="false"
      @delete-product-from-cart="() => removeProductFromCart()"
    />
  </div>
</template>
<script lang="ts" setup>
import { computed, ref, inject } from 'vue';
import { Field } from 'vee-validate';
import { TrashIcon } from '@heroicons/vue/24/outline';
import useProductImage from './use/product-image';
import useFormatCurrency from './use/format-currency';
import useCartManager from './use/cart-manager';
import useTotalItemPriceMethods from './use/total-item-price-methods';
import lokalConfirmationDialog from './shared/lokal-confirmation-dialog.vue';
import lokalTooltip from './shared/lokal-tooltip.vue';

const props = defineProps<{
  item: Item
}>();

const isMobile = inject('isMobile');

const computedProduct = computed(() => props.item.product);
const {
  quantity, quantityField, increaseOneUnit, removeOneUnit, manageQuantityChange, increaseButtonDisabled,
  cartProductMutationIsLoading, removeProductFromCart,
} = useCartManager(computedProduct, props.item.variant);

const { productImageToShow } = useProductImage(props.item.product);
const showDiscountedAmount = computed(() => props.item.wholesalePriceCents !== props.item.product.wholesalePriceCents);
const { totalProductPriceForItem, totalProductPriceWithoutDiscountForItem } = useTotalItemPriceMethods();

const showDeleteConfirmationDialog = ref(false);
</script>
