<template>
  <div
    class="grid grid-cols-5 place-items-center p-2 mb-2 text-sm rounded-md border md:text-base"
  >
    <img
      :src="productImageToShow"
      class="shrink-0 w-14 h-14 rounded-md md:w-20 md:h-20"
    >
    <p class="flex flex-col col-span-2 items-center text-center">
      <span>{{ product.name }}</span>
      <span class="text-xs text-gray-500 lg:text-sm">{{ product.sku }}</span>
    </p>
    <p class="flex flex-col items-center">
      <span>{{ useFormatCurrency(product.unitaryPriceCents) }}</span>
      <span>{{ useFormatCurrency(product.wholesalePriceCents) }}</span>
    </p>
    <p class="">
      {{ product.unitsSold }}
    </p>
  </div>
</template>
<script lang="ts" setup>
import useFormatCurrency from './use/format-currency';
import useProductImage from './use/product-image';

const props = defineProps<{
  product: Product
}>();

const { productImageToShow } = useProductImage(props.product);
</script>
