<template>
  <label
    :for="id"
    :class="customLabelClasses"
  >
    <input
      :id="id"
      v-bind="$attrs"
      type="radio"
      :value="value"
      :checked="checked"
      :name="name"
      hidden
      class="self-start mt-[6px]"
      @change="emit('update:modelValue', $event?.target?.value)"
    >
    <div class="flex shrink-0 justify-center items-center self-start mt-1 w-[14px] h-[14px] rounded-full border-2 border-gray-700 cursor-pointer md:mt-[6px] md:w-4 md:h-4">
      <div
        v-if="checked"
        class="w-[6px] h-[6px] bg-gray-700 rounded-full md:w-2 md:h-2"
      />
    </div>
    <temaplate v-html="label" />
  </label>
</template>
<script lang="ts" setup>
import { ref, computed, watch } from 'vue';

interface FormRadioInputProps {
  modelValue: string | boolean | number
  name: string
  value: string | boolean | number
  label: string
  customLabelClasses?: string
}

const props = withDefaults(defineProps<FormRadioInputProps>(), {
  customLabelClasses: '',
});

const emit = defineEmits<{(e: 'update:modelValue', value: string | boolean | number): void}>();
const id = ref(`${props.name}-${props.value}`);
const checked = computed(() => props.modelValue === props.value);

watch(() => props.modelValue, () => {
  id.value = `${props.name}-${props.value}`;
});
</script>
