<template>
  <Dialog
    :open="modelValue"
    as="div"
    class="relative z-50 text-gray-700"
    @close="emit('update:modelValue', false)"
  >
    <div
      class="fixed inset-0 bg-black/30"
      aria-hidden="true"
    />
    <div class="flex fixed inset-0 justify-center items-center p-4">
      <DialogPanel class="flex flex-col p-5 w-full max-w-md bg-gray-50 rounded">
        <div class="flex justify-between mb-3 w-full">
          <DialogTitle class="font-serif text-xl md:text-2xl">
            Quiero vender en Lokal
          </DialogTitle>
          <button
            class="ml-auto focus:outline-none"
            @click="emit('update:modelValue', false)"
          >
            <XMarkIcon class="w-5 h-5 fill-gray-700 md:w-6 md:h-6" />
          </button>
        </div>
        <DialogDescription
          class="mb-3 text-sm text-gray-700 md:text-base"
        >
          Completa el siguiente formulario y nos pondremos en contacto contigo
        </DialogDescription>
        <potential-maker-contact @pontential-maker-contact-created="managePotentialMakerContact" />
      </DialogPanel>
    </div>
  </Dialog>
</template>
<script lang="ts" setup>
import { XMarkIcon } from '@heroicons/vue/24/outline';
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  DialogDescription,
} from '@headlessui/vue';
import potentialMakerContact from './potential-maker-contact.vue';

interface PotencialMakerContactProps {
  modelValue: boolean
}
withDefaults(defineProps<PotencialMakerContactProps>(), {
  modelValue: false,
});
const emit = defineEmits<{(e: 'update:modelValue', value: boolean): void,
  (e: 'pontential-maker-contact-created'): void,
}>();

function managePotentialMakerContact() {
  emit('pontential-maker-contact-created');
  emit('update:modelValue', false);
}
</script>
