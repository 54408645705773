<template>
  <Dialog
    :open="modelValue"
    as="div"
    class="relative z-50"
    @close="emit('update:modelValue', false)"
  >
    <div
      class="fixed inset-0 bg-black/30"
      aria-hidden="true"
    />
    <div class="flex fixed inset-0 justify-center items-center p-2 md:p-0">
      <DialogPanel class="flex overflow-y-auto flex-col p-2 w-full max-w-md text-gray-700 bg-gray-50 rounded md:p-4">
        <button
          class="mb-2 ml-auto focus:outline-none"
          @click="emit('update:modelValue', false)"
        >
          <XMarkIcon class="w-4 h-4 fill-gray-700 md:w-5 md:h-5" />
        </button>
        <template v-if="!messageSent">
          <DialogTitle class="mb-3 text-xl text-left md:text-2xl">
            Solicita una muestra de
            <span class="text-lk-green">{{ maker.name }}</span>
          </DialogTitle>
          <DialogDescription class="flex flex-col mb-3 text-sm md:text-base">
            Escribe el nombre de los productos que quieres muestras para coordinar el envío con la Marca.
          </DialogDescription>
          <form
            class="flex flex-col mb-2 md:mb-0"
            @submit="onSubmit"
          >
            <lokal-loading
              v-if="productsQueryIsLoading"
              size="small"
              :loading="productsQueryIsLoading"
            />
            <lokal-generic-combobox
              v-else
              v-model="selectedProducts"
              class="mb-2"
              :options="products"
              :get-option-label="(option) => option.name"
              :get-option-key="(option) => `${option.id}`"
              :placeholder="'Escribe el nombre del producto'"
              :multiple="true"
              :options-label="'Productos'"
            >
              <template
                v-for="product in products"
                :key="product.id"
                #[`custom-option-${product.id}`]="{ active, selected, option, getOptionLabel}"
              >
                <li
                  class="flex items-center p-2 space-x-1 cursor-pointer md:space-x-2"
                  :class="{ 'bg-lk-green text-white': active | selected }"
                >
                  <CheckIcon
                    class="shrink-0 w-4 h-4 md:w-5 md:h-5"
                    :class="{ 'hidden': !selected, 'text-white': selected }"
                  />
                  <img
                    :src="product.images?.[0]?.derivatives?.jpgSm?.url"
                    class="shrink-0 w-8 h-8"
                  >
                  <span>{{ getOptionLabel(option) }}</span>
                </li>
              </template>
            </lokal-generic-combobox>
            <div class="flex overflow-y-auto flex-col p-1 mb-4 w-full h-44 rounded-md border border-lk-light-gray">
              <div
                v-for="product in selectedProducts"
                :key="`selected-product-${product.id}`"
                class="flex items-center p-1 space-x-1 text-sm cursor-pointer md:space-x-2 md:text-base"
              >
                <img
                  :src="product.images?.[0]?.derivatives?.jpgSm?.url"
                  class="shrink-0 w-6 h-6 md:w-8 md:h-8"
                >
                <span>{{ product.name }}</span>
              </div>
            </div>
            <Field
              v-model="selectedProductsLength"
              name="selectedProductsLength"
              label="Número de productos"
              hidden
              :rules="{ min_value: 1, required: true }"
            />
            <ErrorMessage
              name="selectedProductsLength"
              class="mt-1 mb-2 text-sm text-red-500"
            />
            <span class="mb-3 text-sm font-light">*Todos los productos deben ser canceladas con antelación y no está sujetos a devolución.</span>
            <lokal-loading
              v-if="isLoading"
              :loading="isLoading"
              class="self-center"
            />
            <button
              v-else
              class="self-center p-2 w-full text-sm text-white bg-lk-green hover:bg-lk-green-deep rounded-md md:text-base"
            >
              Enviar
            </button>
          </form>
        </template>
        <div
          v-else
          class="flex flex-col items-center"
        >
          <CheckBadgeIcon class="w-52 h-52 fill-lk-green" />
          <DialogTitle class="flex items-center mb-1 text-xl text-left md:text-2xl">
            ¡Hemos recibido tu solicitud!
          </DialogTitle>
          <p class="mb-5 font-light">
            Nos contactaremos contigo lo antes posible.
          </p>
          <button
            class="self-center p-2 w-full text-sm text-white bg-lk-green hover:bg-lk-green-deep rounded-md md:w-3/4 md:text-base"
            @click="emit('update:modelValue', false)"
          >
            Aceptar
          </button>
        </div>
      </DialogPanel>
    </div>
  </dialog>
</template>
<script lang="ts" setup generic="T extends string | number | boolean | object | null | undefined">
import { computed, ref } from 'vue';
import { XMarkIcon, CheckBadgeIcon } from '@heroicons/vue/24/solid';
import { CheckIcon } from '@heroicons/vue/24/outline';
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  DialogDescription,
} from '@headlessui/vue';
import { Field, useForm, ErrorMessage } from 'vee-validate';
import { useMutation, useQuery } from 'vue-query';
import sampleRequestsApi from 'api/sample-requests';
import productsApi from 'api/products';
import lokalGenericCombobox from './shared/lokal-generic-combobox.vue';

const MAX_PRODUCTS = 150;

interface LokalMessageDialogProps {
  modelValue: boolean
  maker: Maker
}
const props = withDefaults(defineProps<LokalMessageDialogProps>(), {
  modelValue: false,
});

const emit = defineEmits<{(e: 'update:modelValue', value: boolean): void}>();

const selectedProducts = ref([] as Product[]);
const selectedProductsIds = computed(() => selectedProducts.value.map((product) => product.id));
const selectedProductsLength = computed(() => selectedProducts.value.length);
const messageSent = ref(false);

const makerQuestionMutation = useMutation(
  () => sampleRequestsApi.create({ makerId: props.maker.id }, selectedProductsIds.value),
  {
    onSuccess: () => {
      messageSent.value = true;
    },
  },
);
const isLoading = computed(() => makerQuestionMutation.isLoading.value);
const { handleSubmit } = useForm();
const onSubmit = handleSubmit(() => {
  makerQuestionMutation.mutate();
});

const productsQueryParams = computed(() => `q[maker_id_eq]=${props.maker.id}&q[s]=maker_priority asc`);
const productsQuery = useQuery(['products', props.maker.id],
  () => productsApi.active(1, productsQueryParams.value, MAX_PRODUCTS),
  { enabled: computed(() => !!props.maker?.id), keepPreviousData: true });
const productsQueryIsLoading = computed(() => productsQuery.isLoading.value);
const products = computed(() => productsQuery.data.value?.data.products as Product[]);
</script>
