<template>
  <Dialog
    :open="modelValue"
    as="div"
    class="relative z-50 text-gray-700"
    @close="emit('update:modelValue', false)"
  >
    <div
      class="fixed inset-0 bg-black/30"
      aria-hidden="true"
    />
    <div class="flex fixed inset-0 justify-center items-center p-2">
      <DialogPanel class="flex overflow-y-scroll flex-col p-3 w-full max-w-md max-h-[40rem] bg-white rounded-md md:p-5">
        <div class="flex justify-between mb-3 w-full">
          <DialogTitle class="font-serif text-xl md:text-2xl">
            Subir manifiesto firmado
          </DialogTitle>
          <button
            class="ml-auto focus:outline-none"
            @click="emit('update:modelValue', false)"
          >
            <XMarkIcon class="w-5 h-5 fill-gray-700 md:w-6 md:h-6" />
          </button>
        </div>
        <DialogDescription
          class="mb-5 text-sm text-gray-700 md:text-base"
        >
          <p class="mb-1 md:mb-2">
            <span>
              El manifiesto firmado es un respaldo en caso de que el pedido sufra algún daño o se pierda.
            </span>
          </p>
        </DialogDescription>
        <form
          class="flex flex-col"
          @submit.prevent="onSubmit"
        >
          <p class="text-sm md:text-base">
            Manifiesto firmado
          </p>
          <FileUploadInput
            v-model="fileValue"
            label-name="Manifiesto firmado"
            class=""
            accept-input-attributs="image/jpeg,image/jpg,image/png"
          />
          <span class="mb-3 text-sm">Debes subir una imagen .jpg, .jpeg o .png</span>
          <lokal-loading
            v-if="imageMutationIsLoading"
            :loading="imageMutationIsLoading"
            class="self-center"
          />
          <button
            v-else
            class="self-center p-2 mb-2 w-full text-sm text-white bg-lk-green hover:bg-lk-green-deep rounded-md md:text-base"
          >
            Guardar
          </button>
        </form>
      </DialogPanel>
    </div>
  </Dialog>
</template>
<script setup lang="ts">
import { XMarkIcon } from '@heroicons/vue/24/solid';
import { computed, ref, type Ref } from 'vue';
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  DialogDescription,
} from '@headlessui/vue';
import { useForm } from 'vee-validate';
import { useMutation } from 'vue-query';
import imagesApi from 'api/images';
import FileUploadInput from './shared/file-upload-input.vue';

interface UploadBillDialogProps {
  modelValue: boolean
  subOrder: SubOrder
}

const props = withDefaults(defineProps<UploadBillDialogProps>(), {
  modelValue: false,
});

const fileValue: Ref<File | undefined> = ref(undefined);
const emit = defineEmits<{(e: 'update:modelValue', value: boolean): void,
  (e: 'reload:sub-order'): void
}>();

const imageMutation = useMutation(
  () => {
    const data = { image: fileValue.value, imagableType: 'SubOrder', imagableId: props.subOrder.id };

    return imagesApi.create(data);
  },
  {
    onSuccess: () => {
      emit('reload:sub-order');
      emit('update:modelValue', false);
    },
  },
);
const imageMutationIsLoading = computed(() => imageMutation.isLoading.value);

const { handleSubmit } = useForm();
const onSubmit = handleSubmit(() => {
  imageMutation.mutate();
});
</script>
