<template>
  <p
    v-if="totalVolumeDiscount > 0"
    class="flex justify-between mb-1"
  >
    <span>
      {{ cartStore.makersNameHash[makerId] }}
      ({{ currentVolumeDiscount?.discount }}%)
    </span>
    <lokal-loading
      v-if="volumeDiscountIsLoading"
      size="xs"
      :loading="volumeDiscountIsLoading"
    />
    <span
      v-else
      class="shrink-0"
    >
      -{{ useFormatCurrency(totalVolumeDiscount) }}
    </span>
  </p>
</template>
<script lang="ts" setup>
import { computed, onMounted, watch } from 'vue';
import useCartStore from 'stores/cart';
import useMakerVolumeDiscounts from './use/maker-volume-discounts';
import useFormatCurrency from './use/format-currency';

const props = defineProps<{
  makerId: number
}>();

const cartStore = useCartStore();

const computedMakerId = computed(() => props.makerId);
const {
  currentVolumeDiscount,
  totalVolumeDiscount,
  volumeDiscountIsLoading,
} = useMakerVolumeDiscounts(computedMakerId);

onMounted(() => {
  cartStore.loadingVolumeDiscountPerMaker[computedMakerId.value] = volumeDiscountIsLoading.value;
  cartStore.volumeDiscountPerMaker[computedMakerId.value] = totalVolumeDiscount.value;
});

watch(volumeDiscountIsLoading, () => {
  cartStore.loadingVolumeDiscountPerMaker[computedMakerId.value] = volumeDiscountIsLoading.value;
});

watch(totalVolumeDiscount, () => {
  cartStore.volumeDiscounts[computedMakerId.value] = currentVolumeDiscount.value;
  cartStore.volumeDiscountPerMaker[computedMakerId.value] = totalVolumeDiscount.value;
});
</script>
