import { computed, type ComputedRef } from 'vue';
import { useQuery } from 'vue-query';
import { orderBy } from 'lodash';
import subsidizedShippingsApi from 'api/subsidized-shippings';

export default function useSubsidizedShippings(makerId: ComputedRef<number>, queryParams: ComputedRef<string>,
  enabled = computed(() => false),
) {
  const subsidizedShippingsQuery = useQuery(
    ['subsidizedShippings', makerId, queryParams],
    () => subsidizedShippingsApi.index(makerId.value, queryParams.value),
    { enabled },
  );
  const subsidizedShippingsIsLoading = computed(() => subsidizedShippingsQuery.isLoading.value);
  const subsidizedShippings = computed(() =>
    subsidizedShippingsQuery.data.value?.data.subsidizedShippings as SubsidizedShipping[]);
  const orderedSubsidizedShippings = computed(() => orderBy(subsidizedShippings.value, 'minimumAmountCents', 'asc'));

  return {
    subsidizedShippingsQuery,
    subsidizedShippings,
    subsidizedShippingsIsLoading,
    orderedSubsidizedShippings,
  };
}
