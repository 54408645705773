<template>
  <TransitionRoot
    :show="modelValue"
    as="template"
    enter="duration-300 ease-out"
    enter-from="opacity-0"
    enter-to="opacity-100"
  >
    <Dialog
      :open="modelValue"
      as="div"
      class="relative z-50 text-gray-700"
      @close="emit('update:modelValue', false)"
    >
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
      >
        <div
          class="fixed inset-0 bg-black/30"
          aria-hidden="true"
        />
      </TransitionChild>
      <div class="flex fixed inset-0 justify-center items-center p-2 md:px-0">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
        >
          <DialogPanel class="flex overflow-y-scroll flex-col p-3 w-full max-w-md h-[420px] max-h-[40rem] bg-white rounded-md md:p-5 md:h-[500px]">
            <button
              class="ml-auto focus:outline-none"
              @click="emit('update:modelValue', false)"
            >
              <XMarkIcon class="w-5 h-5 fill-gray-700 md:w-6 md:h-6" />
            </button>
            <sub-order-advance-payment-dialog-form
              v-if="showAdvancePaymentForm"
              v-model="showAdvancePaymentForm"
              :sub-order="subOrder"
              :maker="maker"
              @update:sub-order="() => emit('update:subOrder')"
            />
            <sub-order-show-upload-bill-dialog-form
              v-else
              :model-value="modelValue"
              :sub-order="subOrder"
              :sub-order-id="subOrderId"
              @update:model-value="(value: boolean) => emit('update:modelValue', value)"
              @invoice-created="() => emit('invoice-created')"
            />
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<script setup lang="ts">
import { XMarkIcon } from '@heroicons/vue/24/solid';
import { ref, computed } from 'vue';
import {
  Dialog,
  DialogPanel,
  TransitionRoot,
  TransitionChild,
} from '@headlessui/vue';
import subOrderShowUploadBillDialogForm from './sub-order-show-upload-bill-dialog-form.vue';
import subOrderAdvancePaymentDialogForm from './sub-order-advance-payment-dialog-form.vue';
import useDateTools from './use/date-tools';

interface UploadBillDialogProps {
  modelValue: boolean
  subOrderId: number
  subOrder: SubOrder
  maker: Maker
}

const props = withDefaults(defineProps<UploadBillDialogProps>(), {
  modelValue: false,
});

const emit = defineEmits<{(e: 'update:modelValue', value: boolean): void,
  (e: 'invoice-created'): void, (e: 'update:subOrder'): void
}>();

const { differenceBetweenDates } = useDateTools();
const lessTimeThatMaximumAllowed = computed(() => {
  if (!props.maker.specialPaymentConditions) {
    if (props.maker.paymentConditions === 'from_delivered') {
      if (props.subOrder.deliveredAt) {
        return differenceBetweenDates(new Date(), new Date(props.subOrder.deliveredAt)) <= props.maker.maxPaymentDays;
      }

      return true;
    } else if (props.subOrder.deliveryInProgressAt) {
      return differenceBetweenDates(
        new Date(), new Date(props.subOrder.deliveryInProgressAt)) <= props.maker.maxPaymentDays;
    }
  }

  return true;
});

const showAdvancePaymentForm = ref(props.maker.advancePaymentEnabled && !props.subOrder.paymentConditions &&
  ((props.subOrder.paymentDate && new Date(props.subOrder.paymentDate) > new Date()) || !props.subOrder.paymentDate) &&
  lessTimeThatMaximumAllowed.value,
);
</script>
