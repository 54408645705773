<template>
  <td class="p-2 border border-slate-300">
    <lokal-loading
      v-if="isLoading || isRefetching"
      :loading="isLoading || isRefetching"
      size="xs"
    />
    <span v-else>{{ selectedShippingQuotation.carrierName }}</span>
  </td>
  <td class="p-2 border border-slate-300">
    <lokal-loading
      v-if="isLoading || isRefetching"
      :loading="isLoading || isRefetching"
      size="xs"
    />
    <span v-else>{{ selectedShippingQuotation.priceFormatted }}</span>
  </td>
  <td class="p-2 border border-slate-300">
    <lokal-loading
      v-if="isLoading || isRefetching"
      :loading="isLoading || isRefetching"
      size="xs"
    />
    <span v-else>{{ selectedShippingQuotation.name }}</span>
  </td>
  <td class="p-2 border border-slate-300">
    <lokal-loading
      v-if="isLoading || isRefetching"
      :loading="isLoading || isRefetching"
      size="xs"
    />
    <span v-else>{{ selectedShippingQuotation.code }}</span>
  </td>
  <td class="p-2 border border-slate-300">
    <lokal-loading
      v-if="isLoading || isRefetching"
      :loading="isLoading || isRefetching"
      size="xs"
    />
    <span v-else>{{ selectedShippingQuotation.serviceTypes }}</span>
  </td>
  <td class="p-2 border border-slate-300">
    <lokal-loading
      v-if="isLoading || isRefetching"
      :loading="isLoading || isRefetching"
      size="xs"
    />
    <span v-else>{{ selectedShippingQuotation.transitDays }}</span>
  </td>
</template>
<script setup lang="ts">
import { computed, type ComputedRef } from 'vue';
import { useQuery } from 'vue-query';
import makersApi from 'api/makers';
import useMakerShippingQuotations from 'components/use/maker-shipping-quotations';
import useShippingQuotationsQuery from 'components/queries/shipping-quotations-query';

const props = withDefaults(defineProps<{
  makerId: number,
  origineAddress: Partial<Address>,
  destinationAddress: Partial<Address>,
  carrierCodes: ComputedRef<string[]>,
  weight?: number,
}>(), {
  weight: 3,
});

const destinationCommune = computed(() => props.destinationAddress.communeName as string);
const originCommune = computed(() => props.origineAddress.communeName as string);
const makerQuery = useQuery(['maker', props.makerId], () => makersApi.get(props.makerId));
const maker = computed(() => makerQuery.data.value?.data.maker as Maker);

const enabledShippingQuotationsQuery = computed(() => !!originCommune.value && !!destinationCommune.value &&
  !!maker.value?.id);

const { sortedShippingQuotations, shippingQuotationsQuery } = useShippingQuotationsQuery(
  originCommune, destinationCommune, computed(() => props.weight), enabledShippingQuotationsQuery,
);
const isLoading = computed(() => shippingQuotationsQuery.isLoading.value);
const isRefetching = computed(() => shippingQuotationsQuery.isRefetching.value);
const { selectedShippingQuotation } = useMakerShippingQuotations(sortedShippingQuotations, props.carrierCodes);
</script>
