import { computed, type ComputedRef } from 'vue';
import { orderBy } from 'lodash';
import useVolumeDiscountsQuery from 'components/queries/volume-discounts-query';
import useCartStore from 'stores/cart';
import useTotalItemPriceMethods from './total-item-price-methods';

const TOTAL_PORCENTAGE = 100;

// eslint-disable-next-line max-statements
export default function useMakerVolumeDiscounts(makerId: ComputedRef<number>) {
  const { totalProductPriceForItems } = useTotalItemPriceMethods();
  const cartStore = useCartStore();
  const items = computed(() => cartStore.groupedItems[cartStore.makersNameHash[makerId.value]]);
  const totalProductsPricesValue = computed(() => totalProductPriceForItems(items.value));
  const volumeDiscountQueryParams = computed(() => 'q[active_eq]=true');
  const { volumeDiscounts, volumeDiscountIsLoading } = useVolumeDiscountsQuery(makerId, volumeDiscountQueryParams);
  const currentVolumeDiscount = computed(() => {
    const filteredVolumeDiscounts = volumeDiscounts.value?.filter((volumeDiscount) =>
      volumeDiscount.minimumAmountCents <= totalProductsPricesValue.value);

    return orderBy(filteredVolumeDiscounts, 'minimumAmountCents', 'desc')[0];
  });
  const totalVolumeDiscount = computed(() => {
    if (!currentVolumeDiscount.value) return 0;

    return totalProductsPricesValue.value * (currentVolumeDiscount.value.discount / TOTAL_PORCENTAGE);
  });
  const totalProductsPriceWithVolumeDiscount = computed(() =>
    totalProductsPricesValue.value - totalVolumeDiscount.value);
  const nextVolumeDiscount = computed(() => {
    if (!volumeDiscounts.value || volumeDiscounts.value.length === 0) return null;

    return orderBy(volumeDiscounts.value, 'minimumAmountCents', 'asc').find((volumeDiscount) =>
      volumeDiscount.minimumAmountCents > totalProductsPricesValue.value);
  });

  return {
    volumeDiscounts,
    volumeDiscountIsLoading,
    currentVolumeDiscount,
    totalVolumeDiscount,
    totalProductsPriceWithVolumeDiscount,
    nextVolumeDiscount,
  };
}
