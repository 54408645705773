<template>
  <div class="flex flex-col p-5 w-full">
    <div class="flex flex-col">
      <p>
        Selecciona una marca:
      </p>
      <lokal-single-combobox
        v-model="makerOption"
        key-option="id"
        :options="makerOptionRecords"
        option-label="name"
        options-label="Marca"
      />
      <p>
        Selecciona una comuna de destino:
      </p>
      <lokal-loading
        v-if="communesQuery.isLoading.value"
        :loading="communesQuery.isLoading.value"
        size="small"
      />
      <lokal-single-combobox
        v-else
        v-model="communeRecord"
        key-option="id"
        :options="communeRecords"
        option-label="name"
        options-label="Comuna"
      />
    </div>
    <shipping-quotation
      v-if="selectedMaker?.id && selectedCommune?.id"
      :maker="selectedMaker"
      :commune="selectedCommune"
    />
  </div>
</template>
<script setup lang="ts">
import { computed, ref } from 'vue';
import { orderBy } from 'lodash';
import useCommunesQuery from './queries/communes-query';
import type { OptionRecord } from './shared/lokal-single-selector.vue';
import LokalSingleCombobox from './shared/lokal-single-combobox.vue';
import shippingQuotation from './shipping-quotation.vue';

const props = defineProps<{
  makers: Maker[],
}>();

const { communes, communesQuery } = useCommunesQuery();
const makerOption = ref({} as OptionRecord);
const selectedMaker = computed(() => props.makers.find((maker) => maker.id === makerOption.value?.id));
const sortedMakers = computed(() => orderBy(props.makers, 'name'));
const makerOptionRecords = computed(() => sortedMakers.value.map((maker) => ({ id: maker.id, name: maker.name })));
const communeRecord = ref({} as OptionRecord);
const selectedCommune = computed(() => communes.value?.find((commune) => commune.id === communeRecord.value?.id));
const communeRecords = computed(() => communes.value?.map((commune) => ({ id: commune.id, name: commune.name })));
</script>
