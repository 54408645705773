import { csrfToken } from '@rails/ujs';
import { ref } from 'vue';
import api from './index';

const token = ref(csrfToken());
let headers = {};
if (token.value) {
  headers = { 'X-CSRF-Token': token.value };
}

const PAGE_SIZE = 20;

export default {
  index(shopId: number, pageNumber: number, queryParams?: string) {
    const params = `shop_id=${shopId}&${queryParams}`;
    const path = `/api/internal/order_payments?page[number]=${pageNumber}&page[size]=${PAGE_SIZE}`;

    return api({
      method: 'get',
      url: `${path}&&${params}`,
      headers,
    });
  },
};
