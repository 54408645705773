<template>
  <div class="flex flex-col items-center mb-10 w-full h-full md:px-5 md:max-w-7xl lg:px-10">
    <div class="flex items-center px-2 mb-3 w-full md:px-0">
      <p class="text-xl text-gray-700 md:text-2xl lg:text-3xl">
        Marcas compradas
        <span v-if="totalResources">
          ({{ totalResources }})
        </span>
      </p>
    </div>
    <div class="flex flex-col py-3 px-2 w-full bg-white rounded-md md:py-5 md:px-4 lg:px-10">
      <lokal-loading
        v-if="isLoading"
        :loading="isLoading"
        class="self-center"
      />
      <div
        v-else
        class="flex flex-col mb-10 w-full text-gray-700"
      >
        <div class="flex justify-between items-center mb-3 w-full md:mb-5">
          <p class="text-lg md:text-xl">
            Tienda: {{ shop.name }}
          </p>
          <p class="text-sm md:text-base">
            <span>{{ totalResources }}</span>
          </p>
        </div>
        <p
          v-if="shopMakers?.length === 0"
          class="text-base md:text-lg"
        >
          Aún no tienes marcas compradas.
        </p>
        <template v-else>
          <div class="grid grid-cols-3 gap-4 md:grid-cols-4 md:gap-y-6 lg:grid-cols-6">
            <shop-maker-list-card
              v-for="maker in shopMakers"
              :key="`shop-maker-${maker.id}`"
              :maker="maker"
            />
          </div>
          <pagination-component
            :total-pages="totalPages"
            :current-page="currentPage"
            @set-new-page="updatePage"
          />
        </template>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useQuery } from 'vue-query';
import shopsApi from 'api/shops';
import useCustomParseInt from 'components/use/custom-parse-int';
import useShopQuery from './queries/shop-query';
import shopMakerListCard from './shop-maker-list-card.vue';
import paginationComponent from './shared/pagination-component.vue';

interface ShopMakerListProps {
  shopId: number
  withTitle?: boolean
}

const props = withDefaults(defineProps<ShopMakerListProps>(), {
  withTitle: true,
});

const currentPage = ref(1);
const computedShopId = computed(() => props.shopId);
const { shop, shopQuery } = useShopQuery(computedShopId);
const queryParams = computed(() => `q[sub_orders_order_shop_id_eq]=${props.shopId}`);

const shopMakersQuery = useQuery(
  ['shop-makers', props.shopId, currentPage],
  () => shopsApi.makers(props.shopId, currentPage.value, queryParams.value),
);
const shopMakers = computed(() => shopMakersQuery.data?.value?.data?.makers as Maker[]);
const shopMakersIsLoading = computed(() => shopMakersQuery.isLoading.value);
const isLoading = computed(() => shopQuery.isLoading.value || shopMakersIsLoading.value);
const totalResources = computed(() => useCustomParseInt(shopMakersQuery.data.value?.headers['x-total']));
const resourcesPerPage = computed(() => useCustomParseInt(shopMakersQuery.data.value?.headers['x-per-page']));
const totalPages = computed(() => Math.ceil(totalResources.value / resourcesPerPage.value));

function updatePage(newPage: number) {
  currentPage.value = newPage;
}
</script>
