<template>
  <div class="flex flex-col items-center mb-10 w-full h-full md:px-5 md:max-w-7xl lg:px-10">
    <div class="flex items-center px-2 mb-3 w-full md:px-0">
      <p class="flex flex-col self-end text-xl text-gray-700 md:text-2xl lg:text-3xl">
        Pagos
      </p>
    </div>
    <div class="flex flex-col py-3 px-2 w-full bg-white rounded-md md:py-5 md:px-4 lg:px-10">
      <lokal-loading
        v-if="balanceAccountLoading"
        :loading="balanceAccountLoading"
      />
      <div
        v-else
        class="flex flex-col"
      >
        <div
          v-if="balanceAccount.maximumCreditsCents > 0"
          class="flex flex-col p-5 mb-5 bg-gray-50 rounded-md"
        >
          <div class="flex flex-col justify-around space-y-5 md:flex-row md:space-y-0">
            <div class="flex flex-col items-center p-5 px-10 mb-1 bg-white rounded-md">
              <span class="text-2xl">{{ useFormatCurrency(balanceAccount.maximumCreditsCents) }}</span>
              <span class="font-light">aprobados</span>
            </div>
            <div class="flex flex-col items-center p-5 px-10 mb-1 bg-white rounded-md">
              <span class="text-2xl">{{ useFormatCurrency(balanceAccount.totalDebt) }}</span>
              <span class="font-light">utilizados</span>
            </div>
            <div class="flex flex-col items-center p-5 px-10 mb-1 bg-white rounded-md">
              <span class="text-2xl">{{ useFormatCurrency(balanceAccount.creditsLeft) }}</span>
              <span class="font-light">disponibles</span>
            </div>
          </div>
        </div>
        <lokal-loading
          v-if="shopPaymentsLoading"
          :loading="shopPaymentsLoading"
        />
        <shop-payment-list-card
          v-for="shopPayment, index in shopPayments"
          v-else
          :key="index"
          :shop-payment="shopPayment"
        />
        <p v-if="shopPayments?.length === 0">
          No has hecho ningún pago aún.
        </p>
        <pagination-component
          v-else-if="shopPayments?.length && shopPayments?.length > 0"
          :current-page="currentPage"
          :total-pages="totalPages"
          @set-new-page="updatePage"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useQuery } from 'vue-query';
import balanceAccountsApi from 'api/balance-accounts';
import paginationComponent from './shared/pagination-component.vue';
import useFormatCurrency from './use/format-currency';
import useShopPaymentsQuery from './queries/shop-payments-query';
import ShopPaymentListCard from './shop-payment-list-card.vue';

const props = defineProps<{
  shopId: number
}>();

const currentPage = ref(1);
const queryParams = computed(() => 'q[s]=created_at+desc');
const { shopPayments, shopPaymentsQuery, totalPages } = useShopPaymentsQuery(
  computed(() => props.shopId), currentPage, queryParams);
const balanceAccountQuery = useQuery(['balance-account', props.shopId], () => balanceAccountsApi.get(props.shopId));
const balanceAccount = computed(() => balanceAccountQuery.data.value?.data.balanceAccount as BalanceAccount);
const shopPaymentsLoading = computed(() => shopPaymentsQuery.isLoading.value ||
  shopPaymentsQuery.isPreviousData.value);
const balanceAccountLoading = computed(() => balanceAccountQuery.isLoading.value);

function updatePage(newPage: number) {
  currentPage.value = newPage;
}
</script>
