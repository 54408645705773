<template>
  <Dialog
    :open="modelValue"
    as="div"
    class="relative z-50"
    @close="emit('update:modelValue', false)"
  >
    <div
      class="fixed inset-0 bg-black/30"
      aria-hidden="true"
    />
    <div class="flex fixed inset-0 justify-center items-center px-2 md:px-0">
      <DialogPanel class="flex overflow-y-auto flex-col py-2 px-3 w-full max-w-md bg-gray-50 rounded md:p-4">
        <button
          class="mb-2 ml-auto focus:outline-none"
          @click="emit('update:modelValue', false)"
        >
          <XMarkIcon class="w-5 h-5 fill-gray-700 md:w-6 md:h-6" />
        </button>
        <DialogTitle class="flex flex-col mb-3 font-serif text-xl text-center text-gray-700 md:text-2xl">
          <span>Agregar stock de</span>
          <span>{{ product.name }}</span>
        </DialogTitle>
        <DialogDescription class="mb-4 text-sm font-light text-gray-700 md:mb-5 md:text-base">
          Al agregar stock se
          <span class="font-semibold">notificará</span>
          a todas las tiendas que estaban esperando la disponibilidad de este producto.
        </DialogDescription>
        <form
          class="mb-5 text-sm font-light md:text-base"
          @submit="sumbitForm"
        >
          <p class="text-sm md:text-base">
            Agregar unidades disponibles para el producto:
          </p>
          <form-input
            v-model.number="inventoryQuantity"
            class="mb-2 w-full text-left"
            type="number"
            label-name="Unidades disponibles"
            placeholder="Unidades disponibles"
            :name="`inventory-quantity-${product.id}`"
            :rules="{ required: true, min_value: 1 }"
          />
          <button
            class="self-center p-1 mb-1 w-full text-sm text-white bg-lk-green hover:bg-lk-green-deep rounded-md md:p-2 md:text-base"
          >
            Agregar
          </button>
          <button
            class="w-full text-xs text-center hover:underline md:text-sm"
            @click="emit('update:modelValue', false)"
          >
            Cancelar
          </button>
        </form>
      </DialogPanel>
    </div>
  </dialog>
</template>
<script lang="ts" setup>
import { ref } from 'vue';
import { XMarkIcon } from '@heroicons/vue/24/solid';
import { useForm } from 'vee-validate';
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  DialogDescription,
} from '@headlessui/vue';
import FormInput from './shared/form-input.vue';

interface MakerProductActiveInventoryProps {
  modelValue: boolean
  product: Product
}
withDefaults(defineProps<MakerProductActiveInventoryProps>(), {
  modelValue: false,
});

const emit = defineEmits<{(e: 'update:modelValue', value: boolean): void,
  (e: 'update:inventoryQuantity', value: number): void
}>();

const inventoryQuantity = ref(0);

const { handleSubmit } = useForm();

const sumbitForm = handleSubmit(() => {
  emit('update:inventoryQuantity', inventoryQuantity.value);
  emit('update:modelValue', false);
});
</script>
