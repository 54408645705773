<template>
  <div class="flex flex-col items-center py-8 px-4 w-full h-full bg-stone-100 md:py-12 md:pl-[128px] xl:pr-0">
    <div class="flex flex-col items-center w-full max-w-6xl h-full md:pr-[96px]">
      <p class="mb-5 w-full font-serif-noto text-lg text-left text-gray-700 sm:text-xl md:mb-12 lg:text-2xl">
        Testimonios
      </p>
      <div class="grid grid-cols-6 place-items-center mb-3 w-full md:grid-cols-8 md:mb-0">
        <button
          v-if="!isMobileOrTablet"
          @click="updateCurrentTestimonial(false)"
        >
          <ArrowLeftIcon
            class="p-3 mr-auto -ml-12 w-12 h-12 text-lk-light-gray bg-white rounded-full"
            :class="{ 'hover:text-lk-green': currentTestimonialIndex > 0,
                      'opacity-50 cursor-default': currentTestimonialIndex === 0 }"
          />
        </button>
        <inline-svg-wrapper
          name="quote-icon"
          class="object-cover mr-1 ml-auto w-8 h-8 fill-gray-700 md:w-16 md:h-16"
        />
        <transition
          enter-active-class="transform duration-300"
          enter-from-class="-translate-x-10 opacity-0"
          enter-to-class="translate-x-0 transform opacity-100"
          leave-active-class="transform duration-300"
          leave-from-class="translate-x-0 transform opacity-100"
          leave-to-class="translate-x-10 transform opacity-0"
        >
          <div
            v-if="currentTestimonial?.id && !changedClicked"
            class="flex flex-col col-span-4 items-center w-full"
          >
            <img
              :src="currentTestimonial.image?.derivatives?.jpgMd?.url"
              class="object-cover mb-5 w-32 h-32 rounded-full md:mb-8 md:w-40 md:h-40"
            >
            <p class="mb-3 text-sm font-light text-center text-gray-700 md:mb-5 md:text-lg">
              {{ currentTestimonial.description }}
            </p>
            <p class="flex flex-col text-base font-semibold text-center text-gray-700 md:text-xl">
              <span>{{ currentTestimonial.name }}</span>
            </p>
            <p class="flex flex-col text-sm font-light text-center text-gray-700 md:text-lg">
              <span>
                {{ $t(`landing.testimonials.whitnessType.${currentTestimonial.whitnessType.toLocaleLowerCase()}`) }}
                {{ currentTestimonial.whitnessName }}
              </span>
            </p>
          </div>
        </transition>
        <div class="w-full" />
        <button
          v-if="!isMobileOrTablet"
          @click="updateCurrentTestimonial(true)"
        >
          <ArrowLeftIcon
            class="p-3 -mr-12 ml-auto w-12 h-12 text-lk-light-gray bg-white rounded-full rotate-180"
            :class="{
              'hover:text-lk-green': currentTestimonialIndex < testimonials.length - 1,
              'opacity-50 cursor-default': currentTestimonialIndex === testimonials.length - 1
            }"
          />
        </button>
      </div>
      <div
        v-if="isMobileOrTablet"
        class="flex justify-center items-center space-x-3 w-full md:space-x-4"
      >
        <button @click="updateCurrentTestimonial(false)">
          <ArrowLeftIcon
            class="p-3 w-10 h-10 text-lk-light-gray bg-white rounded-full"
            :class="{ 'hover:text-lk-green': currentTestimonialIndex > 0 }"
          />
        </button>
        <button @click="updateCurrentTestimonial(true)">
          <ArrowLeftIcon
            class="p-3 w-10 h-10 text-lk-light-gray bg-white rounded-full rotate-180"
            :class="{ 'hover:text-lk-green': currentTestimonialIndex < testimonials.length - 1 }"
          />
        </button>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, computed, inject, watch } from 'vue';
import { ArrowLeftIcon } from '@heroicons/vue/24/outline';
import inlineSvgWrapper from './inline-svg-wrapper.vue';

const TIMEOUT = 300;

interface TheLandingTestimonialsProps {
  testimonials: Testimonial[]
}

const props = defineProps<TheLandingTestimonialsProps>();

const isMobileOrTablet = inject('isMobileOrTablet') as boolean;

const changedClicked = ref(false);
const currentTestimonialIndex = ref(0);
const currentTestimonial = computed(() =>
  props.testimonials[currentTestimonialIndex.value]);
function updateCurrentTestimonial(increase: boolean) {
  if (increase && currentTestimonialIndex.value < props.testimonials.length - 1) {
    changedClicked.value = true;
    currentTestimonialIndex.value += 1;
  } else if (!increase && currentTestimonialIndex.value > 0) {
    changedClicked.value = true;
    currentTestimonialIndex.value -= 1;
  }
}

watch(changedClicked, () => {
  setTimeout(() => {
    changedClicked.value = false;
  }, TIMEOUT);
});
</script>
