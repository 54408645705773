<template>
  <div class="flex mb-2 w-full">
    <div
      v-if="isMobile"
      class="flex items-center p-3 py-2 w-full text-sm text-gray-700 rounded-md border"
    >
      <a
        :href="`/makers/${currentProduct.makerId}/products/${currentProduct.id}/edit`"
        :style="{ 'background-image': `url(${productImageToShow})` }"
        class="flex object-cover justify-center items-center mr-2 w-28 h-28 bg-center bg-cover rounded-sm"
        @mouseover="showEdit = true"
        @mouseleave="showEdit = false"
      >
        <span
          v-if="showEdit"
          class="p-1 px-2 font-semibold text-gray-700 bg-gray-50 rounded-md"
        >
          EDITAR
        </span>
      </a>
      <p class="flex flex-col">
        <span class="font-semibold">{{ currentProduct.name }}</span>
        <span>{{ currentProduct.sku }}</span>
        <span>
          Precio público: {{ useFormatCurrency(currentProduct.unitaryPriceCents) }}
        </span>
        <span>Precio mayorista: {{ useFormatCurrency(currentProduct.wholesalePriceCents) }}</span>
        <span>Prioridad: {{ currentProduct.makerPriority }}</span>
        <template v-if="maker.inventoryEnabled">
          <span>
            <span class="mr-2">Inventario activo:</span>
            <lokal-switch v-model="inventoryEnabled" />
          </span>
          <span class="flex flex-col">
            <template v-if="currentProduct.inventory?.enable">
              <span class="mr-2">Unidades disponibles:</span>
              <form-input
                v-if="currentProduct.inventory?.quantity > 0"
                v-model="inventoryQuantity"
                class="p-0.5 text-center focus:outline-none"
                type="number"
                label-name="Unidades disponibles"
                placeholder="Unidades disponibles"
                :name="`inventory-quantity-${currentProduct.id}`"
                :rules="{ required: true, min_value: 0 }"
              />
              <button
                v-else
                class="p-0.5 text-xs text-lk-green hover:text-white bg-white hover:bg-lk-green rounded-md border border-lk-green md:text-sm"
                @click="showAddInventoryDialog = !showAddInventoryDialog"
              >
                Agregar unidades
              </button>
            </template>
          </span>
        </template>
        <span v-else>Unidades vendidas: {{ currentProduct.unitsSold }}</span>
      </p>
    </div>
    <div
      v-if="!isMobile"
      class="grid grid-cols-7 gap-x-2 items-center place-items-center py-2 px-1 text-xs text-center text-gray-700 rounded-md border lg:grid-cols-8 lg:gap-x-4 lg:p-3 lg:text-base"
    >
      <a
        :style="{ 'background-image': `url(${productImageToShow})` }"
        class="flex object-cover justify-center items-center w-10 h-10 bg-center bg-cover rounded-sm cursor-pointer lg:w-20 lg:h-20"
        :href="`/makers/${currentProduct.makerId}/products/${currentProduct.id}/edit`"
        @mouseover="showEdit = true"
        @mouseleave="showEdit = false"
      >
        <span
          v-if="showEdit"
          class="p-1 text-gray-700 bg-gray-50 rounded-md lg:top-6 lg:left-3.5 lg:px-2"
        >
          EDITAR
        </span>
      </a>
      <p
        class="flex flex-col w-full lg:col-span-2"
      >
        <span>{{ currentProduct.name }}</span>
        <span class="text-xs text-gray-500 lg:text-sm">{{ currentProduct.sku }}</span>
      </p>
      <p class="col-span-1">
        {{ useFormatCurrency(currentProduct.unitaryPriceCents) }} <br>
        {{ useFormatCurrency(currentProduct.wholesalePriceCents) }}
      </p>
      <form-input
        v-model="productPriority"
        class="col-span-1 w-full text-center border-0 focus:outline-none"
        type="number"
        label-name="Prioridad"
        placeholder="Prioridad"
        :name="`priority-${currentProduct.id}`"
        :rules="{ required: true, min_value: 1 }"
      />
      <div class="flex flex-col items-center">
        <template v-if="maker.inventoryEnabled">
          <lokal-switch
            v-model="inventoryEnabled"
            class="mb-1"
          />
          <template v-if="currentProduct.inventory?.enable">
            <form-input
              v-if="currentProduct.inventory?.quantity > 0"
              v-model="inventoryQuantity"
              class="col-span-1 w-full text-center border-0 focus:outline-none"
              type="number"
              label-name="Unidades disponibles"
              placeholder="Unidades disponibles"
              :name="`inventory-quantity-${currentProduct.id}`"
              :rules="{ required: true, min_value: 0 }"
            />
            <button
              v-else
              class="p-1 text-xs text-lk-green hover:text-white bg-white hover:bg-lk-green rounded-md border border-lk-green md:text-sm"
              @click="showAddInventoryDialog = !showAddInventoryDialog"
            >
              Agregar unidades
            </button>
          </template>
        </template>
        <p
          v-else
          class="col-span-1"
        >
          {{ currentProduct.unitsSold }}
        </p>
      </div>
      <lokal-single-selector
        v-model="selectedActivationOption"
        key-option="value"
        class="w-full"
        :options="activationOptions"
        option-label="label"
        options-label="Seleccionar estado"
        color-key="colorKey"
        size="sm"
        @update:model-value="(newOption) => statusMutation.mutate(snakeCase(`${newOption.value}`))"
      />
      <a
        :href="`/makers/${currentProduct.makerId}/products/${currentProduct.id}/edit`"
        class="hover:underline"
      >
        editar
      </a>
      <CsrfInput />
    </div>
    <makers-product-add-inventory
      v-if="showAddInventoryDialog"
      v-model="showAddInventoryDialog"
      :product="product"
      @update:inventory-quantity="(newInventoryQuantity) => inventoryQuantity = newInventoryQuantity"
    />
  </div>
</template>
<script lang="ts" setup>
import { ref, watch, inject } from 'vue';
import { snakeCase, camelCase } from 'lodash';
import { useForm } from 'vee-validate';
import { useMutation } from 'vue-query';
import { useI18n } from 'vue-i18n';
import useMessageStore from 'stores/message-store';
import inventoriesApi from '../api/inventories';
import useFormatCurrency from './use/format-currency';
import LokalSingleSelector, { type OptionRecord } from './shared/lokal-single-selector.vue';
import productsApi from '../api/products';
import CsrfInput from './shared/csrf-input.vue';
import formInput from './shared/form-input.vue';
import useProductImage from './use/product-image';
import MakersProductAddInventory from './makers-product-add-inventory.vue';
import LokalSwitch from './shared/lokal-switch.vue';

const OPTIONS = [
  { value: 'inactive', label: 'Inactivo', colorKey: 'bg-red-500 text-white' },
  { value: 'outOfStock', label: 'Sin Stock', colorKey: 'bg-lk-light-purple text-white' },
  { value: 'active', label: 'Activo', colorKey: 'bg-lk-green text-white' },
];
const INACTIVE_POSITION = 0;
const OUT_OF_STOCK_POSITION = 1;
const ACTIVE_POSITION = 2;

const props = defineProps<{
  product: Product,
  maker: Maker
}>();

const { t } = useI18n();

const currentProduct = ref(props.product);

const messageStore = useMessageStore();

const { productImageToShow } = useProductImage(currentProduct.value);
const isMobile = inject('isMobile');
const showEdit = ref(false);
const activationOptions = ref(OPTIONS as OptionRecord[]);
function getSelectedActivationOption() {
  if (currentProduct.value.status === 'inactive') {
    return activationOptions.value[INACTIVE_POSITION];
  } else if (currentProduct.value.status === 'out_of_stock') {
    return activationOptions.value[OUT_OF_STOCK_POSITION];
  }

  return activationOptions.value[ACTIVE_POSITION];
}
const selectedActivationOption = ref(getSelectedActivationOption());

const productPriority = ref(currentProduct.value.makerPriority);
const { mutate } = useMutation((priority: number) =>
  productsApi.update(currentProduct.value.id, { product: { makerPriority: priority } }));
const { validate } = useForm();

watch(productPriority, async (newPriority: number) => {
  const validationResult = await validate();
  if (validationResult.valid) mutate(newPriority);
});

const statusMutation = useMutation((status: string) => {
  if (status === 'active') {
    return productsApi.update(currentProduct.value.id, { product: { status } });
  }

  const data = { product: { status, inventoryAttributes: { id: currentProduct.value.inventoryId, quantity: 0 } } };

  return productsApi.update(currentProduct.value.id, data);
},
{
  onSuccess: (data, variables) => {
    messageStore.kind = 'success';
    const status = t(`product.status.${camelCase(variables)}`);
    const message = `El estado del producto ${currentProduct.value.name} ha cambiado a ${status}.`;
    messageStore.message = message;
    currentProduct.value = data.data.product as Product;
  },
});

const inventoryEnabled = ref(currentProduct.value.inventory?.enable || false);
const showAddInventoryDialog = ref(false);
const inventoryQuantity = ref(currentProduct.value.inventory?.quantity || 0);
const inventoryQuantityMutation = useMutation(() => {
  const status = inventoryQuantity.value > 0 ? 'active' : 'out_of_stock';
  const newData = { product: {
    status,
    inventoryAttributes: { id: currentProduct.value.inventoryId, quantity: inventoryQuantity.value },
  } };

  return productsApi.update(currentProduct.value.id, newData);
}
, {
  onSuccess: (data) => {
    messageStore.kind = 'success';
    const message = `Inventario de ${currentProduct.value.name} actualizado a ${inventoryQuantity.value} unidades.`;
    messageStore.message = message;
    currentProduct.value = data.data.product as Product;
    selectedActivationOption.value = getSelectedActivationOption();
  },
});

const inventoryEnableMutation = useMutation((enable: boolean) =>
  inventoriesApi.update(currentProduct.value.id, { id: currentProduct.value.inventoryId, enable }),
{
  onSuccess: (data) => {
    messageStore.kind = 'success';
    const enabledMessage = inventoryEnabled.value ? 'activado' : 'desactivado';
    messageStore.message = `Se ha ${enabledMessage} el inventario de ${currentProduct.value.name}.`;
    const inventory = data.data.inventory as Inventory;
    currentProduct.value = { ...currentProduct.value, inventory };
    inventoryQuantity.value = inventory.quantity;
  },
});

watch(inventoryEnabled, async (newValue) => {
  const validationResult = await validate();
  if (validationResult.valid) inventoryEnableMutation.mutate(newValue);
});

watch(inventoryQuantity, async () => {
  const validationResult = await validate();
  if (validationResult.valid) inventoryQuantityMutation.mutate();
});

watch(() => props.product, () => {
  currentProduct.value = props.product;
  selectedActivationOption.value = getSelectedActivationOption();
});
</script>
