<template>
  <div class="">
    <div
      v-if="editor"
      class="flex flex-wrap gap-1 p-2 mb-1 text-xs rounded-md border md:gap-1.5 md:mb-1.5 md:text-sm"
    >
      <button
        type="button"
        :disabled="!editor.can().chain().focus().toggleBold().run()"
        :class="{ 'bg-lk-light-green border-0 text-white': editor.isActive('bold') }"
        class="p-0.5 rounded-md border md:p-1"
        @click="editor && editor.chain().focus().toggleBold().run()"
      >
        bold
      </button>
      <button
        type="button"
        class="p-0.5 rounded-md border md:p-1"
        :disabled="!editor.can().chain().focus().toggleItalic().run()"
        :class="{ 'bg-lk-light-green border-0 text-white': editor.isActive('italic') }"
        @click="editor && editor.chain().focus().toggleItalic().run()"
      >
        italic
      </button>
      <button
        type="button"
        class="p-0.5 rounded-md border md:p-1"
        :disabled="!editor.can().chain().focus().toggleStrike().run()"
        :class="{ 'bg-lk-light-green border-0 text-white': editor.isActive('strike') }"
        @click="editor && editor.chain().focus().toggleStrike().run()"
      >
        línea
      </button>
      <!-- <button
        type="button"
        class="p-0.5 md:p-1 rounded-md border"
        :disabled="!editor.can().chain().focus().toggleCode().run()"
        :class="{ 'bg-lk-light-green border-0 text-white': editor.isActive('code') }"
        @click="editor.chain().focus().toggleCode().run()"
      >
        code
      </button>
      <button
        type="button"
        class="p-0.5 md:p-1 rounded-md border"
        @click="editor.chain().focus().unsetAllMarks().run()"
      >
        clear marks
      </button>
      <button
        type="button"
        class="p-0.5 md:p-1 rounded-md border"
        @click="editor.chain().focus().clearNodes().run()"
      >
        clear nodes
      </button>
      <button
        type="button"
        class="p-0.5 md:p-1 rounded-md border"
        :class="{ 'bg-lk-light-green border-0 text-white': editor.isActive('paragraph') }"
        @click="editor.chain().focus().setParagraph().run()"
      >
        paragraph
      </button>
      <button
        type="button"
        class="p-0.5 md:p-1 rounded-md border"
        :class="{ 'bg-lk-light-green border-0 text-white': editor.isActive('heading', { level: 1 }) }"
        @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
      >
        h1
      </button>
      <button
        type="button"
        class="p-0.5 md:p-1 rounded-md border"
        :class="{ 'bg-lk-light-green border-0 text-white': editor.isActive('heading', { level: 2 }) }"
        @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
      >
        h2
      </button>
      <button
        type="button"
        class="p-0.5 md:p-1 rounded-md border"
        :class="{ 'bg-lk-light-green border-0 text-white': editor.isActive('heading', { level: 3 }) }"
        @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
      >
        h3
      </button>
      <button
        type="button"
        class="p-0.5 md:p-1 rounded-md border"
        :class="{ 'bg-lk-light-green border-0 text-white': editor.isActive('heading', { level: 4 }) }"
        @click="editor.chain().focus().toggleHeading({ level: 4 }).run()"
      >
        h4
      </button>
      <button
        type="button"
        class="p-0.5 md:p-1 rounded-md border"
        :class="{ 'bg-lk-light-green border-0 text-white': editor.isActive('heading', { level: 5 }) }"
        @click="editor.chain().focus().toggleHeading({ level: 5 }).run()"
      >
        h5
      </button>
      <button
        type="button"
        class="p-0.5 md:p-1 rounded-md border"
        :class="{ 'bg-lk-light-green border-0 text-white': editor.isActive('heading', { level: 6 }) }"
        @click="editor.chain().focus().toggleHeading({ level: 6 }).run()"
      >
        h6
      </button> -->
      <button
        type="button"
        class="p-0.5 rounded-md border md:p-1"
        :class="{ 'bg-lk-light-green border-0 text-white': editor.isActive('bulletList') }"
        @click="editor && editor.chain().focus().toggleBulletList().run()"
      >
        lista
      </button>
      <button
        type="button"
        class="p-0.5 rounded-md border md:p-1"
        :class="{ 'bg-lk-light-green border-0 text-white': editor.isActive('orderedList') }"
        @click="editor && editor.chain().focus().toggleOrderedList().run()"
      >
        lista numérica
      </button>
      <!-- <button
        type="button"
        class="p-0.5 md:p-1 rounded-md border"
        :class="{ 'bg-lk-light-green border-0 text-white': editor.isActive('blockquote') }"
        @click="editor && editor.chain().focus().toggleBlockquote().run()"
      >
        quota
      </button>
      <button
        type="button"
        class="p-0.5 md:p-1 rounded-md border"
        :class="{ 'bg-lk-light-green border-0 text-white': editor.isActive('codeBlock') }"
        @click="editor.chain().focus().toggleCodeBlock().run()"
      >
        code block
      </button> -->
      <button
        type="button"
        class="p-0.5 rounded-md border md:p-1"
        @click="editor.chain().focus().setHorizontalRule().run()"
      >
        línea horizontal
      </button>
      <button
        type="button"
        class="p-0.5 rounded-md border md:p-1"
        @click="editor.chain().focus().setHardBreak().run()"
      >
        salto de línea
      </button>
      <!-- <button
        type="button"
        class="p-0.5 md:p-1 rounded-md border"
        :disabled="!editor.can().chain().focus().undo().run()"
        @click="editor.chain().focus().undo().run()"
      >
        undo
      </button>
      <button
        type="button"
        class="p-0.5 md:p-1 rounded-md border"
        :disabled="!editor.can().chain().focus().redo().run()"
        @click="editor.chain().focus().redo().run()"
      >
        redo
      </button> -->
    </div>
    <EditorContent
      class="text-xs font-light md:text-sm"
      :editor="editor"
    />
  </div>
</template>

<script setup lang="ts">
import { onBeforeUnmount } from 'vue';
import { useEditor, EditorContent } from '@tiptap/vue-3';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';

const props = defineProps<{
  modelValue: string
  placeholder?: string
  style?: string
}>();

const emit = defineEmits<{(e: 'update:modelValue', value: string): void}>();

const editor = useEditor({
  extensions: [
    StarterKit,
    Placeholder.configure({
      placeholder: props.placeholder || 'Escribe aquí...',
      showOnlyWhenEditable: true,
      emptyEditorClass: 'is-editor-empty',
    }),
  ],
  content: props.modelValue,
  onUpdate: () => {
    emit('update:modelValue', editor.value?.getHTML() || '');
  },
  editorProps: {
    attributes: {
      class: 'h-full border rounded-md p-2 border focus:outline-none',
      style: props.style || 'min-height: 4rem',
    },
  },
});

onBeforeUnmount(() => {
  editor.value?.destroy();
});
</script>
<style lang="scss">
/* Basic editor styles */
// .ProseMirror {
//   > * + * {
//     margin-top: 0.75em;
//   }

//   ul,
//   ol {
//     padding: 0 1rem;
//   }

//   h1,
//   h2,
//   h3,
//   h4,
//   h5,
//   h6 {
//     line-height: 1.1;
//   }

//   code {
//     background-color: rgba(#616161, 0.1);
//     color: #616161;
//   }

//   pre {
//     background: #0D0D0D;
//     color: #FFF;
//     font-family: 'JetBrainsMono', monospace;
//     padding: 0.75rem 1rem;
//     border-radius: 0.5rem;

//     code {
//       color: inherit;
//       padding: 0;
//       background: none;
//       font-size: 0.8rem;
//     }
//   }

//   img {
//     max-width: 100%;
//     height: auto;
//   }

//   blockquote {
//     padding-left: 1rem;
//     border-left: 2px solid rgba(#0D0D0D, 0.1);
//   }

//   hr {
//     border: none;
//     border-top: 2px solid rgba(#0D0D0D, 0.1);
//     margin: 2rem 0;
//   }
// }

.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}
</style>
