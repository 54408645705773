import { computed } from 'vue';
import { sumBy } from 'lodash';
import useProductWholesalePrice from './product-wholesale-price';

// this should be modify so that the totalProducPriceForItems reflects the original price, as in the backend

function totalProductPriceWithoutDiscountForItem(item: Item) {
  return item.quantity * item.product.wholesalePriceCents;
}

function totalProductDiscountPriceForItem(item: Item) {
  const quantity = computed(() => item.quantity);
  const { discountAmount } = useProductWholesalePrice(item.product, quantity);

  return item.quantity * discountAmount.value;
}

export default function useTotalItemPriceMethods() {
  function totalProductPriceForItem(item: Item) {
    return item.quantity * item.wholesalePriceCents;
  }

  function totalProductsDiscountForItems(items: Item[]) {
    return sumBy(items, (item) => totalProductDiscountPriceForItem(item));
  }

  function totalProductsPriceWithoutDiscountForItems(items: Item[]) {
    return sumBy(items, (item) => totalProductPriceWithoutDiscountForItem(item));
  }

  function totalProductPriceForItems(items: Item[]) {
    return sumBy(items, (item) => totalProductPriceForItem(item));
  }

  function anyDiscountActiveForItems(items: Item[]) {
    return items.some((item) => !!item.product.activeDiscounts?.length);
  }

  return {
    totalProductPriceForItem,
    totalProductsDiscountForItems,
    totalProductPriceWithoutDiscountForItem,
    totalProductsPriceWithoutDiscountForItems,
    totalProductPriceForItems,
    anyDiscountActiveForItems,
  };
}
