<template>
  <floating-chatbot v-if="currentPathIsKunaFoods" />
  <floating-chatbot
    v-else-if="currentPathIsNotAMaker && currentPathIsNotAProduct"
    :assistant-id="assistantId"
  />
  <whatsapp-floating-contact
    v-else
    :contact-number="contactNumber"
  />
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import whatsappFloatingContact from './whatsapp-floating-contact.vue';
import floatingChatbot from './floating-chatbot.vue';

defineProps<{
  assistantId?: string
  contactNumber: string
}>();

const currentPathIsKunaFoods = computed(() =>
  /^\/makers\/kuna-foods(\/(?!admin).*)?$/.test(window.location.pathname),
);
const currentPathIsNotAMaker = computed(() => {
  const makerPathRegex = /^\/makers\/[^/]+(\/.*)?$/;

  return !makerPathRegex.test(window.location.pathname);
});
const currentPathIsNotAProduct = computed(() => {
  const productPathRegex = /^\/products\/[^/]+(\/.*)?$/;

  return !productPathRegex.test(window.location.pathname);
});
</script>

