import { csrfToken } from '@rails/ujs';
import { ref } from 'vue';
import api from './index';

const token = ref(csrfToken());
let headers = {};
if (token.value) {
  headers = { 'X-CSRF-Token': token.value };
}

export default {
  get(userId: number, makerId: number) {
    const path = `/api/internal/maker_users/show?user_id=${userId}&maker_id=${makerId}`;

    return api({
      method: 'get',
      url: path,
      headers,
    });
  },
  update(userId: number, makerId: number, active: boolean) {
    const path = `/api/internal/maker_users/update?user_id=${userId}&maker_id=${makerId}`;

    return api({
      method: 'put',
      url: path,
      data: { emailNotifications: active },
      headers,
    });
  },
};
