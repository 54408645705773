
import { computed } from 'vue';
import { useQuery } from 'vue-query';
import categoriesApi from 'api/categories';

export default function useCategoriesQuery(queryParams = computed(() => '')) {
  const categoryQuery = useQuery(
    ['categories', queryParams],
    () => categoriesApi.index(queryParams.value),
  );
  const categories = computed(() => categoryQuery.data.value?.data.categories as Category[]);

  return { categoryQuery, categories, isLoading: categoryQuery.isLoading };
}
