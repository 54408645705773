<template>
  <div class="flex flex-col items-center py-6 px-4 mb-10 w-full max-w-lg md:py-10 md:px-0">
    <h2 class="mb-5 font-serif text-2xl text-center text-gray-700">
      Volver a enviar instrucciones para confirmar cuenta
    </h2>
    <template
      v-for="errorMessage in errorMessages"
      :key="errorMessage"
    >
      <lokal-banner
        v-if="errorMessage"
        v-model="showBanner"
        :message="errorMessage"
        :color-key="('alert' as ColorKeyType)"
      />
    </template>
    <form
      class="flex flex-col mb-2 space-y-2 w-full text-gray-700"
      action="/users/confirmation"
      method="post"
      accept-charset="UTF-8"
      @submit.prevent="submitForm"
    >
      <Field
        v-model="form.email"
        name="user[email]"
        label="email"
        type="text"
        class="p-2 w-full text-sm rounded-md border border-gray-200"
        placeholder="Ingresa tu email"
        :rules="{ required: true, email: true }"
      />
      <ErrorMessage name="user[email]" />
      <lokal-button
        action-name="Enviar instrucciones"
        size="normal"
        type="submit"
      />
      <csrf-token />
    </form>
    <div class="flex justify-between w-full">
      <a
        href="/users/sign_in"
        class="text-sm text-gray-700 hover:text-lk-light-green hover:underline"
      >
        Ingresar
      </a>
      <a
        href="/users/sign_up"
        class="text-sm text-gray-700 hover:text-lk-light-green hover:underline"
      >
        Crear cuenta
      </a>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref } from 'vue';
import { useForm, Field, ErrorMessage } from 'vee-validate';
import CsrfToken from './shared/csrf-input.vue';
import LokalBanner, { type ColorKeyType } from './shared/lokal-banner.vue';

const showBanner = ref(true);

const form = ref({ email: '' });

const { submitForm } = useForm();

interface ConfirmationNewProps {
  errorMessages?: string[]
}

withDefaults(defineProps<ConfirmationNewProps>(), {
  errorMessages: () => [''],
});
</script>
