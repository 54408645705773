<template>
  <div class="flex flex-col w-full">
    <div class="flex items-center mb-3 space-x-2">
      <button
        class="flex items-center p-2 space-x-2 hover:bg-gray-50 rounded-md border"
        @click="menuStore.showProductFilterSidebar = !menuStore.showProductFilterSidebar"
      >
        <FunnelIcon
          class="w-5 h-5 fill-lk-green"
        />
        <p class="text-sm text-gray-700 md:text-base">
          Mostrar Filtros
        </p>
      </button>
      <filter-content
        v-for="category in selectedCategories"
        :key="`cateogory-${category.id}`"
        :content-id="category.id"
        :display-name="category.name"
        @remove="removeSelectedCategory(category.id)"
      />
    </div>
    <div
      v-if="makerSlug && collections?.length"
      class="flex flex-col mb-3"
    >
      <p class="mb-2 text-base text-gray-700 md:text-lg">
        Colecciones
      </p>
      <div
        class="flex overflow-x-auto relative items-center self-start pb-4 w-full text-sm snap-x snap-proximity md:text-base"
      >
        <button
          class="shrink-0 px-0.5 pb-2 border-b-2 md:px-1"
          :class="{ 'border-gray-200' : selectedCollectionIds.length > 0,
                    'border-lk-green' : selectedCollectionIds.length === 0 }"
          @click="clearCollections"
        >
          Todas
        </button>
        <div
          class="shrink-0 self-end w-3 border-b-2 border-gray-200 md:shrink md:w-10"
        />
        <template
          v-for="collection in collections"
          :key="`maker-show-collection-${collection.id}`"
        >
          <button
            class="shrink-0 px-1.5 pb-2 border-b-2 md:px-3"
            :class="{ 'border-gray-200' : !selectedCollectionIds.includes(collection.id),
                      'border-lk-green' : selectedCollectionIds.includes(collection.id) }"
            @click="updateCollections(collection)"
          >
            {{ collection.name }}
          </button>
          <div
            class="shrink-0 self-end w-3 last:md:w-full border-b-2 border-gray-200 md:shrink md:w-10"
          />
        </template>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { FunnelIcon } from '@heroicons/vue/24/solid';
import useGeneralMenuStore from 'stores/general-menu-store';
import useProductFilterStore from 'stores/product-filter-store';
import useCollectionsQuery from './queries/collections-query';
import filterContent from './shared/filter-content.vue';

interface ProductListDisplayFiltersInterface {
  totalProductCount: number,
  makerSlug?: string,
}

const props = withDefaults(defineProps<ProductListDisplayFiltersInterface>(), {
  makerSlug: '',
});

const menuStore = useGeneralMenuStore();
const productFilterStore = useProductFilterStore();
const { selectedCategories, selectedCollections, selectedCollectionIds } = storeToRefs(productFilterStore);

function removeSelectedCategory(categoryIdToRemove: number | string) {
  selectedCategories.value = selectedCategories.value.filter((record) => record.id !== categoryIdToRemove);
}

const collectionsQueryParams = computed(() => `q[maker_slug_eq]=${props.makerSlug}&q[s][]=maker_priority+asc`);
const { collections } = useCollectionsQuery(collectionsQueryParams, computed(() => !!props.makerSlug));

function updateCollections(collection: Collection) {
  if (selectedCollectionIds.value.includes(collection.id)) {
    selectedCollections.value = selectedCollections.value.filter((record) => record.id !== collection.id);
  } else {
    selectedCollections.value = [collection];
  }
}

function clearCollections() {
  selectedCollections.value = [];
}
</script>
