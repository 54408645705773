import { computed, type ComputedRef, type Ref } from 'vue';
import { useQuery } from 'vue-query';
import subOrdersApi from 'api/sub-orders';
import useCustomParseInt from 'components/use/custom-parse-int';

export default function useSubOrdersQuery(makerId: ComputedRef<number>, page: Ref<number>,
  queryParams = computed(() => ''), enabled = computed(() => true),
) {
  const subOrdersQuery = useQuery(
    ['sub-orders', makerId, page, queryParams],
    () => subOrdersApi.index(makerId.value, page.value, queryParams.value),
    { keepPreviousData: true, enabled },
  );
  const isSubOrderQueryLoading = computed(() => subOrdersQuery.isLoading.value || subOrdersQuery.isPreviousData.value);
  const subOrders = computed(() => subOrdersQuery.data.value?.data.subOrders as SubOrder[]);
  const totalResources = computed(() => useCustomParseInt(subOrdersQuery.data.value?.headers['x-total']));
  const resourcesPerPage = computed(() => useCustomParseInt(subOrdersQuery.data.value?.headers['x-per-page']));
  const totalPages = computed(() => Math.ceil(totalResources.value / resourcesPerPage.value));

  return { subOrders, subOrdersQuery, isSubOrderQueryLoading, totalResources, resourcesPerPage, totalPages };
}

