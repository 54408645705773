<template>
  <tr
    class="text-center"
  >
    <td class="p-1">
      <a
        :href="`/orders/${order.id}`"
        class="hover:underline"
      >
        #{{ order.id }}
      </a>
    </td>
    <td class="p-1">
      <a
        v-if="order.invoiceNumber"
        :href="`${order.invoiceUrl}`"
        class="hover:underline"
        target="_blank"
      >
        #{{ order.invoiceNumber }}
      </a>
      <span v-else>
        No emitida
      </span>
    </td>
    <td class="p-1">
      <lokal-loading
        v-if="isLoading"
        :loading="isLoading"
        size="xs"
      />
      <span v-else>{{ useFormatCurrency(orderTotalAmountWithVat) }}</span>
    </td>
    <td :class="{ 'text-red-500': isLateOnPayment }">
      {{ !!order.paymentDate ? useTimeToDate(order.paymentDate) : '-' }}
    </td>
    <td :class="{ 'text-red-500': isLateOnPayment }">
      {{ (!!order.paymentDate && isLateOnPayment) ? daysLateOnPayment : '-' }}
    </td>
    <td class="p-1">
      <lokal-loading
        v-if="isLoading"
        :loading="isLoading"
        size="xs"
      />
      <span v-else>{{ useFormatCurrency(totalPayments) }}</span>
    </td>
    <td
      class="p-1"
      :class="{ 'text-red-500': isLateOnPayment }"
    >
      <lokal-loading
        v-if="isLoading"
        :loading="isLoading"
        size="xs"
      />
      <span v-else>{{ useFormatCurrency(totalDebt) }}</span>
    </td>
  </tr>
</template>
<script lang="ts" setup>
import { computed, inject, onMounted, watch } from 'vue';
import { useQuery } from 'vue-query';
import orderPaymentsApi from 'api/order-payments';
import { billToolsKey, type BillTools } from '../symbols';
import useFormatCurrency from './use/format-currency';
import useTimeToDate from './use/time-to-date';

// eslint-disable-next-line no-magic-numbers
const DAYS_IN_MILLISECONDS = 1000 * 60 * 60 * 24;

const props = defineProps<{
  shopId: number
  order: Order
}>();

const billTools = inject(billToolsKey) as BillTools;

const queryParams = computed(() => `q[order_id_eq]=${props.order.id}`);
const orderPaymentsQuery = useQuery(
  ['order-payments', props.shopId, queryParams],
  () => orderPaymentsApi.index(props.shopId, 1, queryParams.value),
  { keepPreviousData: true },
);
const orderPayments = computed(() => orderPaymentsQuery.data.value?.data.orderPayments as OrderPayment[]);
const isLoading = computed(() => orderPaymentsQuery.isLoading.value);
const orderTotalAmount = computed(() =>
  props.order.totalWholesaleAmountCents + props.order.shippingCostAmountCents -
  props.order.totalDiscountAmount - props.order.totalVolumeDiscountAmountCents - props.order.totalDiscountCodeAmount);
const orderTotalAmountWithVat = computed(() => orderTotalAmount.value * billTools.ivaValue);
const totalPayments = computed(() => orderPayments.value?.reduce((acc, payment) => acc + payment.amountCents, 0));
const totalDebt = computed(() => orderTotalAmountWithVat.value - totalPayments.value);
const isLateOnPayment = computed(() => {
  if (!!props.order.paymentDate) {
    return new Date(props.order.paymentDate) < new Date();
  }

  return false;
});
const daysLateOnPayment = computed(() => {
  if (!!props.order.paymentDate) {
    const paymentDate = new Date(props.order.paymentDate);
    const currentDate = new Date();
    const diffTime = Math.abs(currentDate.getTime() - paymentDate.getTime());

    return Math.ceil(diffTime / DAYS_IN_MILLISECONDS);
  }

  return 0;
});

const emit = defineEmits<{(e: 'total-amount-debt', value: number): void}>();
watch(totalDebt, () => {
  if (isLateOnPayment.value) {
    emit('total-amount-debt', totalDebt.value);
  }
});

onMounted(() => {
  if (totalDebt.value && isLateOnPayment.value) {
    emit('total-amount-debt', totalDebt.value);
  }
});
</script>
