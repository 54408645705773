import { computed } from 'vue';
import { snakeCase } from 'lodash';

const IVA = 0.19;
const IVA_VALUE = 1 + IVA;
const TOTAL_PORCENTAGE = 100;
const LOW_IABA = 0.10;
const LOW_IABA_PERCENTAGE = LOW_IABA * TOTAL_PORCENTAGE;
const HIGH_IABA = 0.18;
const HIGH_IABA_PERCENTAGE = HIGH_IABA * TOTAL_PORCENTAGE;
const LIQUEUR_ILA = 0.315;
const LIQUEUR_ILA_PERCENTAGE = LIQUEUR_ILA * TOTAL_PORCENTAGE;
const WINE_ILA = 0.205;
const WINE_ILA_PERCENTAGE = WINE_ILA * TOTAL_PORCENTAGE;

export default function useBillingTools() {
  const billTools = computed(() => ({
    iva: IVA,
    ivaValue: IVA_VALUE,
    totalPorcentage: TOTAL_PORCENTAGE,
    lowIaba: LOW_IABA,
    highIaba: HIGH_IABA,
    lowIabaPercentage: LOW_IABA_PERCENTAGE,
    highIabaPercentage: HIGH_IABA_PERCENTAGE,
    liqueurIla: LIQUEUR_ILA,
    wineIla: WINE_ILA,
    liqueurIlaPercentage: LIQUEUR_ILA_PERCENTAGE,
    wineIlaPercentage: WINE_ILA_PERCENTAGE,
  }));

  function convertAdditionalTax(additionalTax: string) {
    switch (additionalTax) {
    case 'low_iaba':
      return LOW_IABA;
    case 'high_iaba':
      return HIGH_IABA;
    case 'liqueur_ila':
      return LIQUEUR_ILA;
    case 'wine_ila':
      return WINE_ILA;
    default:
      return 0;
    }
  }

  function additionalTaxToText(additionalTax: string) {
    switch (snakeCase(additionalTax)) {
    case 'low_iaba':
      return `Impuesto a las bebidas no alcohólicas (${LOW_IABA_PERCENTAGE}%)`;
    case 'high_iaba':
      return `Impuesto a las bebidas no alcohólicas (${HIGH_IABA_PERCENTAGE}%)`;
    case 'liqueur_ila':
      return `Impuesto a licores y otros (${LIQUEUR_ILA_PERCENTAGE}%)`;
    case 'wine_ila':
      return `Impuesto a vinos, cervezas y otros (${WINE_ILA_PERCENTAGE}%)`;
    default:
      return 'Sin impuesto adicional';
    }
  }

  function additionalTaxInitials(additionalTax: string) {
    switch (snakeCase(additionalTax)) {
    case 'low_iaba':
      return 'IABA 10%';
    case 'high_iaba':
      return 'IABA 18%';
    case 'liqueur_ila':
      return 'ILA 31,5%';
    case 'wine_ila':
      return 'ILA 20,5%';
    default:
      return '';
    }
  }

  return { billTools, convertAdditionalTax, additionalTaxToText, additionalTaxInitials };
}
