<template>
  <input
    type="hidden"
    name="authenticity_token"
    :value="token"
  >
</template>

<script lang="ts" setup>
import { csrfToken } from '@rails/ujs';
import { ref } from 'vue';

const token = ref(csrfToken());
</script>
